import * as angular from 'angular';
import { IHttpProvider, ILocationProvider, IQProvider, IQService } from 'angular';
import './services/users/current-user.service';
import './translations/english-translation';
import './translations/swedish-translation';
import './translations/translations.loader';
import './modules/layout/layout.component';
import './interceptors/http-interceptor';
import { TOASTR_CONFIG } from './app.constant';
import { LanguageCode } from './models/language.model';

export class Config {
  constructor(
    $stateProvider: any,
    $urlRouterProvider: any,
    $locationProvider: ILocationProvider,
    $qProvider: IQProvider,
    $translateProvider: angular.translate.ITranslateProvider,
    cfpLoadingBarProvider: any,
    translationEnglish: any,
    toastrConfig: any,
    translationSwedish: any,
    usSpinnerConfigProvider: any,
    tmhDynamicLocaleProvider: any,
    $httpProvider: IHttpProvider
  ) {
    angular.extend(toastrConfig, TOASTR_CONFIG);

    // remove cache in IE for get updated result
    $httpProvider.defaults.cache = false;
    if (!$httpProvider.defaults.headers.get) {
      $httpProvider.defaults.headers.get = {};
    }
    // disable IE ajax request caching
    $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';

    // Set Spinner width, radius and length
    usSpinnerConfigProvider.setDefaults({ radius: 20, width: 8, length: 16 });

    $qProvider.errorOnUnhandledRejections(true);

    // Default Url
    $urlRouterProvider.otherwise('/login');

    // to load custom translations
    $translateProvider.useLoader('translationsLoader');

    // English translation service for english translations in front End
    $translateProvider.translations('en', translationEnglish);

    // Swedish Translation service for Swedish translations in front End
    $translateProvider.translations('se', translationSwedish);

    // Default translation is set to Swedish
    $translateProvider.preferredLanguage(LanguageCode.SE);

    $translateProvider.useSanitizeValueStrategy(null);

    cfpLoadingBarProvider.latencyThreshold = 3000;

    tmhDynamicLocaleProvider.defaultLocale(LanguageCode.SE);
    tmhDynamicLocaleProvider.localeLocationPattern('../language/{{locale}}.js');

    $stateProvider
      .state('login', {
        url: '/login',
        views: {
          mainView: {
            template: '<div></div>'
          }
        },
        data: {
          authenticate: false
        }
      })
      .state('register-whitelabel-account', {
        url: '/register-whitelabel-account/:labId?framed',
        template: '<limited-dentist-form></limited-dentist-form>',
        data: {
          authenticate: false
        },
        params: {
          framed: { value: undefined, raw: true }
        }
      })
      .state('dentist-signup-iframe', {
        url: '/dentist-signup-iframe',
        template: '<dentist-signup-form></dentist-signup-form>',
        data: {
          authenticate: false
        }
      })
      .state('app', {
        abstract: true,
        url: '/app',
        template:
          '<layout style="margin-left: 60px;" current-user="$resolve.currentUser" default-customer-settings="$resolve.defaultCustomerSettings"></layout>',
        resolve: sessionStateResolver
      })
      .state('app.order-new', {
        url:
          '/order-new?orderId&isResend&personal_number&first_name&last_name&patient_category&clinic_extid_1&clinic_extid_2&clinic_extid_3',
        views: {
          mainView: {
            template: '<order-new current-user="$resolve.currentUser"></order-new>'
          }
        },
        data: {
          authorize: {
            role: ['super_admin', 'user'],
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true,
          orderId: { value: undefined, raw: true },
          isResend: { value: undefined, raw: true }
        },
        resolve: sessionStateResolver
      })
      .state('app.order-new-2', {
        url:
          '/order-new-2?orderId&isResend&personal_number&first_name&last_name&patient_category&clinic_extid_1&clinic_extid_2&clinic_extid_3&token',
        views: {
          mainView: {
            template:
              '<order-new-2 current-user="$resolve.currentUser" default-customer-settings="$resolve.defaultCustomerSettings"></order-new-2>'
          }
        },
        data: {
          authorize: {
            role: ['super_admin', 'user'],
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          orderId: { value: undefined, raw: true },
          isResend: { value: undefined, raw: true },
          token: { value: undefined, raw: true }
        },
        resolve: sessionStateResolver
      })
      .state('app.draft-order', {
        url: '/orders/draft/:orderId',
        views: {
          mainView: {
            template:
              '<order-new-2 current-user="$resolve.currentUser" default-customer-settings="$resolve.defaultCustomerSettings"></order-new-2>'
          }
        },
        data: {
          authorize: {
            role: ['super_admin', 'user'],
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          orderId: { value: undefined, raw: true },
          token: { value: undefined, raw: true }
        },
        resolve: sessionStateResolver
      })
      .state('app.order-new-2-confirm', {
        url: '/order-new-2-confirm/:orderId',
        views: {
          mainView: {
            template:
              '<order-new-2-confirm current-user="$resolve.currentUser"></order-new-2-confirm>'
          }
        },
        data: {
          authorize: {
            role: ['super_admin', 'user'],
            type: ['dentist']
          },
          authenticate: true
        },
        resolve: sessionStateResolver
      })
      .state('app.order-list', {
        url: '/order-list?token',
        views: {
          mainView: {
            template:
              '<order-list [current-user]="$resolve.currentUser"></order-list>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_ORDERS',
            type: ['dentist', 'lab_technician']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true,
          token: { value: undefined, raw: true }
        },
        resolve: sessionStateResolver
      })
      .state('app.clinics', {
        url: '/clinics',
        views: {
          mainView: {
            template: '<clinics [current-user]="$resolve.currentUser"></clinics>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_CLINICS',
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: false
        },
        resolve: sessionStateResolver
      })
      .state('app.clinics-card', {
        url: '/clinics-card',
        views: {
          mainView: {
            template:
              '<clinics-card [current-user]="$resolve.currentUser"></clinics-card>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_CLINICS',
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: false
        },
        resolve: sessionStateResolver
      })
      .state('app.users', {
        url: '/users',
        views: {
          mainView: {
            template: '<users [current-user]="$resolve.currentUser"></users>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_USERS',
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: false
        },
        resolve: sessionStateResolver
      })
      .state('app.users-card', {
        url: '/users-card',
        views: {
          mainView: {
            template:
              '<users-card [current-user]="$resolve.currentUser"></users-card>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_USERS',
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: false
        },
        resolve: sessionStateResolver
      })
      .state('app.lab-technician', {
        url: '/lab-technicians',
        views: {
          mainView: {
            template:
              '<lab-technician [current-user]="$resolve.currentUser"></lab-technician>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_USERS',
            type: ['lab_technician']
          },
          authenticate: true
        },
        resolve: sessionStateResolver
      })
      .state('app.lab-technician-card', {
        url: '/lab-technicians-card',
        views: {
          mainView: {
            template:
              '<lab-technician-card [current-user]="$resolve.currentUser"></lab-technician-card>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_USERS',
            type: ['lab_technician']
          },
          authenticate: true
        },
        resolve: sessionStateResolver
      })
      .state('app.labs', {
        url: '/labs',
        views: {
          mainView: {
            template: '<lab current-user="$resolve.currentUser"></lab>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_LABS',
            type: ['dentist', 'lab_technician']
          },
          authenticate: true
        },
        resolve: sessionStateResolver
      })
      .state('app.labs-card', {
        url: '/labs-card',
        views: {
          mainView: {
            template: '<lab-card current-user="$resolve.currentUser"></lab-card>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_LABS',
            type: ['dentist', 'lab_technician']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.profile', {
        url: '/profile?token',
        views: {
          mainView: {
            template: '<profile [current-user]="$resolve.currentUser"></profile>'
          }
        },
        data: {
          authorize: {
            type: ['lab_technician', 'dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.settings', {
        url: '/settings?token&tab',
        views: {
          mainView: {
            template: '<settings [current-user]="$resolve.currentUser"></settings>'
          }
        },
        data: {
          authorize: {
            type: ['lab_technician', 'dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true,
          tab: { value: undefined, raw: true }
        },
        resolve: sessionStateResolver
      })
      .state('app.lab-details', {
        url: '/lab-details/:labId',
        views: {
          mainView: {
            template:
              '<lab-details current-user="$resolve.currentUser"></lab-details>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_LABS',
            type: ['lab_technician', 'dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.find-lab', {
        url: '/find-lab',
        views: {
          mainView: {
            template: '<find-lab current-user="$resolve.currentUser"></find-lab>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_LABS',
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.show-lab', {
        url: '/connect-to-lab',
        views: {
          mainView: {
            template: '<show-labs current-user="$resolve.currentUser"></show-labs>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_LABS',
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.order-status-view', {
        url: '/order-status-view',
        views: {
          mainView: {
            template:
              '<order-status-view [current-user]="$resolve.currentUser"></order-status-view>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_ORDERS',
            type: ['dentist', 'lab_technician']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.order-confirm', {
        url: '/order-confirm/:orderId',
        views: {
          mainView: {
            template: '<order-confirm></order-confirm>'
          }
        },
        data: {
          authorize: {
            permission: 'CREATE_ORDER',
            type: ['dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.order-details-2', {
        url: '/order-details-2/:orderId',
        views: {
          mainView: {
            template:
              '<order-details-2 is-visible-in-print="false" current-user="$resolve.currentUser" default-customer-settings="$resolve.defaultCustomerSettings"></order-details-2>'
          }
        },
        data: {
          authorize: {
            permission: 'GET_ORDERS',
            type: ['lab_technician', 'dentist']
          },
          authenticate: true
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.reports', {
        url: '/reports',
        views: {
          mainView: {
            template: '<reports current-user="$resolve.currentUser"></reports>'
          }
        },
        data: {
          authenticate: true,
          authorize: {
            permission: 'REPORTS',
            type: ['lab_technician', 'dentist']
          }
        },
        resolve: sessionStateResolver
      })
      .state('app.invoicing', {
        url: '/invoicing',
        views: {
          mainView: {
            template:
              '<invoice-list current-user="$resolve.currentUser"></invoice-list>'
          }
        },
        data: {
          authenticate: true,
          authorize: {}
        },
        resolve: sessionStateResolver
      })
      .state('app.transport-order-wizard', {
        url: '/transport-order-wizard/:transportType',
        views: {
          mainView: {
            template: `
            <div id="transport-app">
              <transport-order-wizard [current-user]="$resolve.currentUser"></transport-order-wizard>
            </div>`
          }
        },
        data: {
          authenticate: true,
          authorize: {
            role: ['lab_technician']
          }
        },
        resolve: sessionStateResolver
      })
      .state('app.scheduled-transports', {
        url: '/scheduled-transports?date&editTransportId',
        views: {
          mainView: {
            template: `
            <div id="transport-app">
              <scheduled-transports [current-user]="$resolve.currentUser"></scheduled-transports>
            </div>`
          }
        },
        data: {
          authenticate: true,
          authorize: {
            role: ['lab_technician']
          }
        },
        params: {
          date: { value: undefined, raw: true },
          editTransportId: { value: undefined, raw: true }
        },
        resolve: sessionStateResolver
      })
      .state('app.transport-settings', {
        url: '/logistics/settings',
        views: {
          mainView: {
            template: `
            <div id="transport-app">
              <ta-settings [current-user]="$resolve.currentUser"></ta-settings>
            </div>`
          }
        },
        data: {
          authenticate: true,
          authorize: {
            role: ['lab_technician']
          }
        },
        resolve: sessionStateResolver
      })
      .state('app.manage-actions', {
        url: '/manage-actions',
        views: {
          mainView: {
            template:
              '<manage-actions [current-user]="$resolve.currentUser"></manage-actions>'
          }
        },
        data: {
          authenticate: true,
          authorize: {
            permission: '',
            type: ['lab_technician', 'dentist']
          }
        },
        resolve: sessionStateResolver
      })
      .state('app.manage-labs', {
        url: '/manage-labs',
        views: {
          mainView: {
            template:
              '<manage-labs [current-user]="$resolve.currentUser"</manage-labs>'
          }
        },
        data: {
          authenticate: true,
          authorize: {
            permission: '',
            type: ['lab_technician', 'dentist']
          }
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.edit-lab', {
        url: '/edit-lab/:labId',
        views: {
          mainView: {
            template: '<edit-lab [current-user]="$resolve.currentUser"></edit-lab>'
          }
        },
        data: {
          authenticate: true,
          authorize: {
            permission: '',
            type: ['lab_technician', 'dentist']
          }
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.labs-availability', {
        url: '/labs-availability/:labId',
        views: {
          mainView: {
            template:
              '<labs-availability [current-user]="$resolve.currentUser"></labs-availability>'
          }
        },
        data: {
          authenticate: true,
          authorize: {
            permission: '',
            type: ['lab_technician', 'dentist']
          }
        },
        params: {
          showNewOrder: true
        },
        resolve: sessionStateResolver
      })
      .state('app.permissions', {
        url: '/permissions',
        views: {
          mainView: {
            template:
              '<permissions [current-user]="$resolve.currentUser"></permissions>'
          }
        },
        data: {
          authorize: {
            role: ['super_admin']
          },
          authenticate: true
        },
        resolve: sessionStateResolver
      })
      .state('app.customer-settings', {
        url: '/customer-settings/:id',
        views: {
          mainView: {
            template: '<customer-settings></customer-settings>'
          }
        },
        data: {
          authenticate: true,
          authorize: {
            permission: '',
            type: ['dentist_admin', 'technician_admin']
          }
        }
      })
      .state('app.logistics-customer', {
        url: '/logistics-customer',
        views: {
          mainView: {
            template:
              '<logistics-customer [current-user]="$resolve.currentUser"></logistics-customer>'
          }
        },
        data: {
          authorize: {
            role: ['super_admin']
          },
          authenticate: true
        },
        resolve: sessionStateResolver
      })
      .state('app.transport-Clinics', {
        url: '/transport-Clinics',
        views: {
          mainView: {
            template:
              '<transport-clinics [current-user]="$resolve.currentUser"></transport-clinics>'
          }
        },
        data: {
          authorize: {
            role: ['super_admin']
          },
          authenticate: true
        },
        resolve: sessionStateResolver
      })
      .state('logout', {
        url: '/logout',
        template: '<logged-out></logged-out>',
        data: {
          authenticate: false
        }
      });

    $locationProvider.html5Mode(true); //For Remove #
  }
}

const sessionStateResolver = {
  currentUser: [
    'currentUserService',
    '$q',
    function (currentUserService: any, $q: IQService) {
      return currentUserService.getUserData().catch((error: any) => {
        console.log('ERROR GETTING USER', error);
      });
    }
  ]
};

angular.module('app').config([
  '$stateProvider',
  '$urlRouterProvider',
  '$locationProvider',
  '$qProvider',
  '$translateProvider',
  'cfpLoadingBarProvider',
  'translationEnglish',
  'toastrConfig',
  'translationSwedish',
  'usSpinnerConfigProvider',
  'tmhDynamicLocaleProvider',
  '$httpProvider',
  (
    $stateProvider,
    $urlRouterProvider,
    $locationProvider: ILocationProvider,
    $qProvider: IQProvider,
    $translateProvider: angular.translate.ITranslateProvider,
    cfpLoadingBarProvider: any,
    translationEnglish: any,
    toastrConfig: any,
    translationSwedish: any,
    usSpinnerConfigProvider: any,
    tmhDynamicLocaleProvider: any,
    $httpProvider: IHttpProvider
  ) => {
    return new Config(
      $stateProvider,
      $urlRouterProvider,
      $locationProvider,
      $qProvider,
      $translateProvider,
      cfpLoadingBarProvider,
      translationEnglish,
      toastrConfig,
      translationSwedish,
      usSpinnerConfigProvider,
      tmhDynamicLocaleProvider,
      $httpProvider
    );
  }
]);

angular.module('app').factory('$exceptionHandler', [
  '$log',
  function ($log: any) {
    return function myExceptionHandler(exception: any, cause: any) {
      console.error('UNCAUGHT EXCEPTION', exception, cause);
    };
  }
]);
