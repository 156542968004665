import { OnInit, forwardRef, Inject, Input, Component } from '@angular/core';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import * as moment from 'moment';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { StateService } from '@uirouter/angularjs';
import { User } from '../../models/user.model';
import { ConnectedLab } from '../../models/organization.model';
import { orderBy } from 'lodash';
import template from './labs-availability.html';
@Component({
  selector: 'labs-availability',
  template: template
})
export class LabsAvailabilityComponent implements OnInit {
  // TODO:- Initialize these
  @Input() currentUser: User;
  selectedLab: ConnectedLab;
  isAllMonthSelected = true;
  availabilityObj: any = {};
  monthDays: { id: number; label: string; days: number[]; selected: boolean }[] = [];
  unavailableDates: string[] = [];
  currentDate: moment.MomentInput = new Date().setHours(0, 0, 0, 0);
  selectedYear: number = new Date().getFullYear();
  currentYear: number = new Date().getFullYear();
  currentMonth: number = new Date().getMonth() + 1;
  years: number[] = [];
  number = 31;
  showMonthsDropdown = false;
  monthData: { id: number; label: string; days: number[]; selected: boolean }[] = [
    { id: 1, label: 'Jan', days: [], selected: true },
    { id: 2, label: 'Feb', days: [], selected: true },
    { id: 3, label: 'Mar', days: [], selected: true },
    { id: 4, label: 'Apr', days: [], selected: true },
    { id: 5, label: 'May', days: [], selected: true },
    { id: 6, label: 'Jun', days: [], selected: true },
    { id: 7, label: 'Jul', days: [], selected: true },
    { id: 8, label: 'Aug', days: [], selected: true },
    { id: 9, label: 'Sep', days: [], selected: true },
    { id: 10, label: 'Oct', days: [], selected: true },
    { id: 11, label: 'Nov', days: [], selected: true },
    { id: 12, label: 'Dec', days: [], selected: true }
  ];

  selectDeSelectItem(value: boolean, id: number): void {
    if (value) {
      this.monthData[id - 1].selected = true;
      const insertData: {
        id: number;
        label: string;
        days: number[];
        selected: boolean;
      } = this.monthData[id - 1];
      insertData.days = new Array(this.getDaysInMonth(id, this.selectedYear)).fill(
        0
      );
      this.monthDays.splice(id - 1, 0, insertData);
    } else {
      this.monthData[id - 1].selected = false;
      this.monthDays.splice(id - 1, 1);
    }

    this.monthDays = orderBy(this.monthDays, ['id'], ['asc']);
  }

  getNumberOfSelectedMonths(): string {
    return `${this.monthDays.filter((day) => day.selected).length} checked`;
  }

  constructor(
    private readonly subNavService: SubNavService,
    private readonly currentUserService: CurrentUserService,
    private readonly translationsService: TranslationsService,
    private readonly logService: LogService,
    private readonly toastr: ToastrService,
    @Inject(forwardRef(() => '$state')) private readonly $state: StateService,
    private readonly organizationService: OrganizationService
  ) {}

  async ngOnInit(): Promise<void> {
    for (let i = 2018; i <= new Date().getFullYear(); i++) {
      this.years.push(i);
    }
    this.subNavService.addMenu({
      name: await this.translationsService.get('BACK_HANDLING_LABS'),
      state: 'app.manage-labs',
      isActive: true,
      isScrollSpy: false,
      icon: 'fa-angle-left'
    });
    if (this.$state.params.labId) {
      await this.currentUserService.getUserData();
      await this.getSelectedLab(this.$state.params.labId);
    }
  }

  disableDay(date: string): boolean {
    const dateParts = date.split('/');
    const currentDate = moment({
      years: parseInt(dateParts[2]),
      months: parseInt(dateParts[0]) - 1,
      days: parseInt(dateParts[1])
    });
    return !currentDate.isBetween(
      this.selectedLab.connectedDate,
      this.currentDate,
      null,
      '[]'
    );
  }

  updateAvailability(): void {
    this.availabilityObj = {};
    this.monthDays.forEach((month) => {
      let availabilityPercentage = 0;

      // find unavailable days in month according to the date of when lab is connected
      const unavailableDays = month.days.filter((day, index) => {
        const currentDate = moment({
          years: this.selectedYear,
          months: month.id - 1,
          days: index + 1
        });
        const formattedDate = currentDate.format('M/D/YYYY');
        return (
          this.unavailableDates.indexOf(formattedDate) > -1 &&
          currentDate.isBetween(
            this.selectedLab.connectedDate,
            this.currentDate,
            null,
            '[]'
          )
        );
      }).length;

      // find days in month till today according to the date of when lab is connected
      const daysTillDate = month.days.filter((day, index) => {
        const currentDate = moment({
          years: this.selectedYear,
          months: month.id - 1,
          days: index + 1
        });
        return currentDate.isBetween(
          this.selectedLab.connectedDate,
          this.currentDate,
          null,
          '[]'
        );
      }).length;

      // calculate actual available days
      const availableDays = daysTillDate - unavailableDays;

      if (availableDays === 0) {
        availabilityPercentage = 0;
      } else {
        // Availability Percentage of current year and month
        if (
          this.selectedYear === this.currentYear &&
          month.id === this.currentMonth
        ) {
          availabilityPercentage =
            (1 - unavailableDays / new Date().getDate()) * 100;
        } else {
          // Availability Percentage of past year
          availabilityPercentage = (1 - unavailableDays / month.days.length) * 100;
        }
      }
      this.availabilityObj[month.id] = {
        availabilityPercentage: availabilityPercentage
      };
    });
  }

  changeYear(): void {
    this.monthData.forEach((data) => {
      data.selected = true;
    });
    this.monthDays = this.getMonthDays(this.selectedYear);
    this.isAllMonthSelected = true;
    this.updateAvailability();
  }

  getNumber(num: number): number[] {
    return new Array(num);
  }

  getSelectedLab(paramsId: string): Promise<void> {
    return this.organizationService
      .getConnectedLabsForClinic({
        organizationId: this.currentUser.organization._id
      })
      .then((result) => {
        this.selectedLab = result.data.connectedLabs.find(
          (connectedLab) => connectedLab.lab._id === paramsId
        );

        this.monthDays = this.getMonthDays(this.selectedYear);

        this.unavailableDates = this.selectedLab.lab.unavailableLabDates.map(
          (date) => {
            const newDate = new Date(date);
            const month = newDate.getMonth() + 1;
            return `${month}/${newDate.getDate()}/${newDate.getFullYear()}`;
          }
        );
        this.updateAvailability();
      })
      .catch(async (err) => {
        this.handleError('getSelectedLab', 'ERROR_IN_GETTING_LABS', err);
      });
  }

  selectAllItems(value: any): void {
    this.isAllMonthSelected = value;
    this.monthData.forEach((data) => {
      data.selected = true;
    });
    this.monthDays = this.getMonthDays(this.selectedYear);
  }

  deSelectAllItems(value: any): void {
    this.isAllMonthSelected = value;
    this.monthData.forEach((data) => {
      data.selected = false;
    });
    this.monthDays = [];
  }

  getMonthDays(
    selectedYear: number
  ): { id: number; label: string; days: number[]; selected: boolean }[] {
    return [
      {
        id: 1,
        label: 'Jan',
        days: new Array(this.getDaysInMonth(1, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 2,
        label: 'Feb',
        days: new Array(this.getDaysInMonth(2, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 3,
        label: 'Mar',
        days: new Array(this.getDaysInMonth(3, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 4,
        label: 'Apr',
        days: new Array(this.getDaysInMonth(4, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 5,
        label: 'May',
        days: new Array(this.getDaysInMonth(5, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 6,
        label: 'Jun',
        days: new Array(this.getDaysInMonth(6, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 7,
        label: 'Jul',
        days: new Array(this.getDaysInMonth(7, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 8,
        label: 'Aug',
        days: new Array(this.getDaysInMonth(8, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 9,
        label: 'Sep',
        days: new Array(this.getDaysInMonth(9, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 10,
        label: 'Oct',
        days: new Array(this.getDaysInMonth(10, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 11,
        label: 'Nov',
        days: new Array(this.getDaysInMonth(11, selectedYear)).fill(0),
        selected: true
      },
      {
        id: 12,
        label: 'Dec',
        days: new Array(this.getDaysInMonth(12, selectedYear)).fill(0),
        selected: true
      }
    ];
  }

  getDaysInMonth(month: number, year: number): number {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    return new Date(year, month, 0).getDate();
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(
      LabsAvailabilityComponent.name,
      method,
      `${message} ${err}`
    );
    this.toastr.error(await this.translationsService.get(message));
  }
}
