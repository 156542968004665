import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './number-of-units.html';

@Component({
  selector: 'number-of-units',
  template
})
export class NumberOfUnitsComponent implements OnChanges {
  @Input() isMandatory: boolean;
  @Input() actionIds: string[];
  @Input() numberOfUnits: {
    [key: string]: {
      header?: string;
      numberOfUnits?: number;
      isInteger?: boolean;
      numberOfDecimals?: number;
    };
  } = {};

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.numberOfUnits.currentValue) {
      const numberOfUnits = this.constructionSettingsService.getSetting(
        'numberOfUnits'
      );
      if (numberOfUnits) {
        Object.keys(numberOfUnits).forEach((actionId: string) => {
          this.numberOfUnits[
            actionId
          ].numberOfUnits = this.constructionSettingsService.getUnits(actionId);
        });
      }
    }
  }

  private checkAndSetUnits(actionId: string) {
    if (!this.numberOfUnits) {
      this.numberOfUnits = {};
    }

    if (!this.numberOfUnits[actionId]) {
      this.numberOfUnits[actionId] = {};
    }
  }

  setNumberOfUnits(actionId: string, value: string) {
    this.checkAndSetUnits(actionId);
    this.numberOfUnits[actionId].numberOfUnits = parseInt(value, 10);
    this.constructionSettingsService.setNumberOfUnits(
      actionId,
      Object.assign({}, this.numberOfUnits)
    );
  }

  decrementValue(actionId: string) {
    const lastValue = this.constructionSettingsService.getUnits(actionId);
    if (lastValue && lastValue > 0) {
      this.numberOfUnits[actionId].numberOfUnits = lastValue - 1;
      this.constructionSettingsService.setNumberOfUnits(
        actionId,
        Object.assign({}, this.numberOfUnits)
      );
    }
  }

  incrementValue(actionId: string) {
    const lastValue = this.constructionSettingsService.getUnits(actionId);
    this.checkAndSetUnits(actionId);
    this.numberOfUnits[actionId].numberOfUnits =
      lastValue || lastValue === 0 ? lastValue + 1 : 0;
    this.constructionSettingsService.setNumberOfUnits(
      actionId,
      Object.assign({}, this.numberOfUnits)
    );
  }
}
