import gql from 'graphql-tag';

export const createDraftOrderTemplate = gql`
  mutation createDraftOrder(
    $patient: PatientInfoInput!
    $dentist: ID!
    $linkedDentists: [ID]!
    $linkedClinics: [ID]!
    $customParticipantEmails: [String]!
  ) {
    createDraftOrder(
      patient: $patient
      dentist: $dentist
      linkedDentists: $linkedDentists
      linkedClinics: $linkedClinics
      customParticipantEmails: $customParticipantEmails
    ) {
      _id
      linkedDentists {
        user
        dateAdded
      }
      linkedClinics {
        clinic
        dateAdded
      }
      customParticipantEmails
    }
  }
`;

export const savePatientDataTemplate = gql`
  mutation savePatientData(
    $_id: ID!
    $patient: PatientInfoInput!
    $dentist: ID!
    $linkedDentists: [ID]!
    $linkedClinics: [ID]!
    $customParticipantEmails: [String]!
  ) {
    savePatientData(
      _id: $_id
      patient: $patient
      dentist: $dentist
      linkedDentists: $linkedDentists
      linkedClinics: $linkedClinics
      customParticipantEmails: $customParticipantEmails
    ) {
      _id
      linkedDentists {
        user
        dateAdded
      }
      linkedClinics {
        clinic
        dateAdded
      }
      customParticipantEmails
    }
  }
`;

export const saveConstructionsTemplate = gql`
  mutation saveConstructions($_id: ID!, $constructionDraft: ConstructionDraft!) {
    saveConstructions(_id: $_id, constructionDraft: $constructionDraft)
  }
`;

export const saveDeliveryTemplate = gql`
  mutation saveDelivery($_id: ID!, $lab: ID!, $deliveryDetails: DeliveryDetails!) {
    saveDelivery(_id: $_id, lab: $lab, deliveryDetails: $deliveryDetails)
  }
`;

export const saveAndSendOrderTemplate = gql`
  mutation saveAndSendOrder(
    $_id: ID!
    $price: Float!
    $priceSpecification: [PriceSpecification]
  ) {
    saveAndSendOrder(
      _id: $_id
      price: $price
      priceSpecification: $priceSpecification
    )
  }
`;

export const changeStatusToAcceptedByLabTemplate = gql`
  mutation changeStatusToAcceptedByLab($_id: ID!) {
    changeStatusToAcceptedByLab(_id: $_id)
  }
`;

export const deleteDraftOrderTemplate = gql`
  mutation deleteDraftOrder($_id: ID!) {
    deleteDraftOrder(_id: $_id)
  }
`;

export const changeStatusToDraftTemplate = gql`
  mutation changeStatusToDraft($_id: ID!) {
    changeStatusToDraft(_id: $_id)
  }
`;

export const saveCompletedDraftTemplate = gql`
  mutation saveCompletedDraft($_id: ID!, $dentist: ID) {
    saveCompletedDraft(_id: $_id, dentist: $dentist)
  }
`;

export const orderNewStatusChangeTemplate = gql`
  mutation orderNewStatusChange(
    $_id: ID!
    $status: String!
    $isPriceConfirmed: Boolean
  ) {
    orderNewStatusChange(
      _id: $_id
      status: $status
      isPriceConfirmed: $isPriceConfirmed
    )
  }
`;

export const certifyUnCertifyOrderTemplate = gql`
  mutation certifyUnCertifyOrder($orderId: ID!) {
    certifyUnCertifyOrder(orderId: $orderId) {
      _id
      isCertified
    }
  }
`;

export const pdfGeneratedSubscriptionTemplate = gql`
  subscription pdfGenerated($orderId: ID!) {
    pdfGenerated(orderId: $orderId) {
      error
      signedUrl
      orderId
      _id
    }
  }
`;

export const doNotInvoiceTemplate = gql`
  mutation doNotInvoice($orderId: ID!) {
    doNotInvoice(orderId: $orderId) {
      _id
      invoicingState
      tempInvoiceNumber
      invoice {
        _id
        status
        InvoiceNumber
      }
    }
  }
`;

export const markOrderProductionFinishedTemplate = gql`
  mutation markOrderProductionFinished($orderId: ID!) {
    markOrderProductionFinished(orderId: $orderId) {
      _id
      isProductionFinished
    }
  }
`;

export const markOrderProductionNotFinishedTemplate = gql`
  mutation markOrderProductionNotFinished($orderId: ID!) {
    markOrderProductionNotFinished(orderId: $orderId) {
      _id
      isProductionFinished
      tempInvoiceNumber
      invoice {
        _id
      }
    }
  }
`;

export const transportTrackingLinkTemplate = gql`
  mutation transportTrackingLink($orderId: ID!, $link: String!) {
    transportTrackingLink(orderId: $orderId, link: $link)
  }
`;

export const saveInternalOrderIdTemplate = gql`
  mutation saveInternalOrderId($orderId: ID!, $internalOrderId: String!) {
    saveInternalOrderId(orderId: $orderId, internalOrderId: $internalOrderId)
  }
`;
