import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { Language } from '../../models/language.model';
import { SessionService } from '../../services/core/session.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { UsersService } from '../../services/users/users.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { ResetPassword } from '../../models/reset-password.model';
import template from './reset-password.html';

@Component({
  selector: 'reset-password',
  template: template
})
export class ResetPasswordComponent implements OnInit {
  user: ResetPassword = {};
  successCheck: boolean;

  constructor(
    private sessionService: SessionService,
    private logService: LogService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private usersService: UsersService,
    private currentUserService: CurrentUserService,
    @Inject(forwardRef(() => '$stateParams')) private $stateParams: any
  ) {}

  ngOnInit(): void {
    const decodedToken = atob(this.$stateParams.token);
    this.user._id = decodedToken.split('_')[0];
  }

  userReset(): Promise<void> {
    return this.usersService
      .resetUserPassword({
        id: this.user._id,
        password: this.user.password,
        repeatPassword: this.user.confirmPassword
      })
      .then(async (user) => {
        if (user) {
          this.toastr.success(
            await this.translationsService.get('PASSWORD_CHANGE_SUCCESS')
          );
          this.sessionService.clearLocalStorage();
          this.currentUserService.goToLogin();
        }
      })
      .catch(async (err) => {
        this.logService.error(
          'reset-password.component',
          'userReset',
          (await this.translationsService.get(
            'ERROR_IN_UPDATE_USER_PASSWORD'
          )) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_UPDATE_USER_PASSWORD')
        );
      });
  }

  checkPasswordMatch(): boolean {
    if (this.user && this.user.password) {
      if (this.user.password !== this.user.confirmPassword) {
        this.successCheck = false;
        return true;
      }
      this.successCheck = true;
      return false;
    }
  }

  setLanguage(lang: Language): Promise<void> {
    return this.translationsService.use(lang.value);
  }
}
