import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import * as _ from 'lodash';

export interface ResourceInterceptor<T> {
  headers(url: string, headers?: HttpHeaders): Promise<HttpHeaders>;
  request(url: string, data: any): Promise<any>;
  response(url: string, res: T): Promise<T>;
  responseError(url: string, err: HttpErrorResponse): Promise<T>;
}

export interface HttpOptions {
  params: HttpParams;
  responseType: string;
  headers: HttpHeaders;
  body: any;
}

export abstract class ResourceService {
  private baseUrl: string;
  constructor(private httpClient: HttpClient, baseUrl: string = '') {
    this.baseUrl = baseUrl;
  }

  private async execute<T>(
    method: string,
    slug?: string,
    data?: any,
    params?: HttpParams,
    i?: ResourceInterceptor<T>,
    options?: object
  ): Promise<T> {
    const stack = new Error().stack;
    let url = this.baseUrl;

    const paramReplacer = (match: string, param: string): string => {
      if (!param) {
        return;
      }

      const value = params.get(param);

      if (typeof value !== 'string') {
        throw new Error(
          "Can't replace '" +
            param +
            "' in '" +
            url +
            "'! Value is not a string: " +
            JSON.stringify(value)
        );
      }

      params = params.delete(param);
      return value;
    };

    if (params) {
      if (slug) {
        url += slug.replace(/:([a-zA-Z]+)/g, paramReplacer);
      }
      params.keys().forEach((paramKey: string) => {
        if (params.get(paramKey) === undefined) {
          params = params.delete(paramKey);
        }
      });
    } else {
      url += slug;
    }

    const httpOptions: HttpOptions = {
      params: params,
      responseType: 'json',
      headers: i ? await i.headers(url) : undefined,
      body: i ? await i.request(url, data) : data
    };

    return this.httpClient
      .request<T>(method, url, _.merge(httpOptions, options))
      .toPromise()
      .then((result: any) => {
        if (httpOptions.responseType === 'arraybuffer') {
          return (new Blob([result]) as any) as T;
        } else {
          return i ? i.response(url, result) : result;
        }
      })
      .catch((err: any) => {
        if (i && err instanceof HttpErrorResponse) {
          return i.responseError(url, err);
        } else {
          throw err;
        }
      });
  }

  get<T>(
    slug: string,
    params: HttpParams,
    interceptor?: ResourceInterceptor<T>
  ): Promise<T> {
    return this.execute<T>('GET', slug, undefined, params, interceptor);
  }

  getBinary(
    slug: string,
    params: HttpParams,
    interceptor?: ResourceInterceptor<Blob>
  ): Promise<Blob> {
    return this.execute<Blob>('GET', slug, undefined, params, interceptor, {
      responseType: 'arraybuffer'
    });
  }

  getText(
    slug: string,
    params: HttpParams,
    interceptor?: ResourceInterceptor<string>
  ): Promise<string> {
    return this.execute<string>('GET', slug, undefined, params, interceptor, {
      responseType: 'text'
    });
  }

  post<T>(
    slug: string,
    data: any,
    params: HttpParams,
    interceptor?: ResourceInterceptor<T>
  ): Promise<T> {
    return this.execute<T>('POST', slug, data, params, interceptor);
  }

  postBinary(
    slug: string,
    data: any,
    params: HttpParams,
    interceptor?: ResourceInterceptor<Blob>
  ): Promise<Blob> {
    return this.execute<Blob>('POST', slug, data, params, interceptor, {
      responseType: 'arraybuffer'
    });
  }

  postText(
    slug: string,
    data: any,
    params: HttpParams,
    interceptor?: ResourceInterceptor<string>
  ): Promise<string> {
    return this.execute<string>('POST', slug, data, params, interceptor, {
      responseType: 'text'
    });
  }

  put<T>(
    slug: string,
    data: any,
    params: HttpParams,
    interceptor?: ResourceInterceptor<T>
  ): Promise<T> {
    return this.execute<T>('PUT', slug, data, params, interceptor);
  }

  delete<T>(
    slug: string,
    params: HttpParams,
    interceptor?: ResourceInterceptor<T>
  ): Promise<T> {
    return this.execute<T>('DELETE', slug, undefined, params, interceptor);
  }

  deleteWithBody<T>(
    slug: string,
    data: any,
    params: HttpParams,
    interceptor?: ResourceInterceptor<T>
  ): Promise<T> {
    return this.execute<T>('DELETE', slug, data, params, interceptor);
  }
}
