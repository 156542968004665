import { ResourceService } from '../core/resource.service';
import { HttpClient } from '@angular/common/http';
import { API } from '../../app.constant';
import { Organization } from '../../models/organization.model';
import { Injectable } from '@angular/core';
import { ApiInterceptor } from '../../interceptors/api-interceptor';

@Injectable()
export class LogisticsService extends ResourceService {
  constructor(
    httpClient: HttpClient,
    private readonly apiInterceptor: ApiInterceptor
  ) {
    super(httpClient, '');
  }

  getConnectedAllClinics(): Promise<{ data: Organization[] }> {
    return this.get(
      API.API_V2_BASE + 'logistics/connected-all-clinics',
      undefined,
      this.apiInterceptor
    );
  }
}
