import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import template from './existing-transport-dialog.html';
import { TranslationsService } from '../../../../../services/translations/translations.service';
import { Logistic } from '../../../../../models/logistic.model';
import { Moment } from 'moment';
import { LogService } from '../../../../../services/core/log.service';

@Component({
  selector: 'existing-transport-dialog',
  template: template
})
export class ExistingTransportDialogComponent implements OnInit {
  title: string;
  text: string;
  clinicName: string;
  transportDate: Moment;

  constructor(
    public dialogRef: MatDialogRef<ExistingTransportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExistingTransportDialogData,
    private readonly translationsService: TranslationsService,
    private readonly logService: LogService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.clinicName = this.data.clinicName;
      this.transportDate = this.data.transportDate;
      const transportOrder = this.data.transportOrder;
      if (transportOrder) {
        if (transportOrder.type === 'PICKUP') {
          this.title = await this.translationsService.get(
            'EXISTING_TRANSPORT_ORDER_TITLE_PICKUP'
          );
        } else {
          this.title = await this.translationsService.get(
            'EXISTING_TRANSPORT_ORDER_TITLE_DELIVERY'
          );
        }
      }
    } catch (e) {
      this.logService.newError(e);
    }
  }

  onEdit(): void {
    this.dialogRef.close(true);
  }
}

export type ExistingTransportDialogData = {
  transportOrder: Logistic;
  clinicName: string;
  transportDate: Moment;
};
