import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import template from './materials.html';
import { Material, MaterialList } from '../../../../models/organization.model';
import * as _ from "lodash";

interface MaterialStatusOption {
  name: string;
  value: boolean;
  color?: string;
}

@Component({
  selector: 'materials',
  template: template
})
export class MaterialsComponent implements OnChanges {
  filter = '';
  @Input() selectedList: MaterialList;
  @Input() currency: string;
  @Output() selectedListUpdated: EventEmitter<MaterialList> = new EventEmitter<
    MaterialList
  >();
  readonly modalId = '#add-material-modal';
  materialStatusOptions: MaterialStatusOption[] = [
    {
      name: 'All Materials',
      value: null
    },
    {
      name: 'Active Materials',
      value: false,
      color: 'black'
    },
    {
      name: 'Inactive Materials',
      value: true,
      color: 'gray'
    }
  ];
  selectedStatus = 'All Materials';
  material = {};
  materials: any = [];
  filteredMaterials: Material[] = [];

  ngOnChanges() {
    if (this.selectedList) {
      this.materials = this.selectedList.materials;
      this.filteredMaterials = Object.assign([], this.materials);
    }
  }

  openModal() {
    $(this.modalId).modal('show');
  }

  editMaterial(material: Material) {
    this.material = _.cloneDeep(material);
    $(this.modalId).modal('show');
  }

  modalClosed(materialLists?: MaterialList) {
    this.material = {};
    this.selectedListUpdated.emit(materialLists);
    $(this.modalId).modal('hide');
  }

  filterMaterialsByStatus(selectedStatusOption: MaterialStatusOption) {
    if (selectedStatusOption.value === null) {
      this.filteredMaterials = this.materials;
      return;
    }
    this.filteredMaterials = this.materials.filter(
      (material) => material.inactive === selectedStatusOption.value
    );
  }
}
