export class Status extends Error {
  static readonly OK: number = 0;
  static readonly TIMEOUT: number = 1;
  static readonly IN_PROGRESS: number = 2;
  static readonly ERROR: number = -1;
  static readonly AUTH_FAILED: number = -2;
  static readonly PERM_DENIED: number = -3;
  static readonly NOT_FOUND: number = -4;
  static readonly DUPLICATE: number = -5;
  static readonly MISSING_PARAM: number = -6;
  static readonly INVALID_DATA: number = -7;
  static readonly ORGANIZATION_MIS_MATCH: number = -8;
  static readonly ZERO_PRICE: number = -9;
  static readonly ALREADY_SENT: number = -10;
  static readonly DUPLICATE_EMAIL: number = 409;

  constructor(
    public code: number,
    public message: string,
    public err?: string,
    public data?: any
  ) {
    super(message);
    this.code = code;
    this.message = message;
    this.data = data;
  }
}
