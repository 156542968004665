/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './show-public-lab-item.html'
/**
 * public lab from admin add
 */
(function () {
    'use strict';
    angular.module('app').component('showPublicLabItem', {
        template: theTemplate,
        controller: ShowPublicLabItemController,
        controllerAs: 'vm',
        bindings: {
            lab: "="
        }
    });

    // Inject connect lab dependencies here
    ShowPublicLabItemController.$inject = [];

    // Controller function fo connect lab module.
    function ShowPublicLabItemController() {

        // Scope and other variables
        var vm = this;

    }

})();
