import { Action } from './action.model';
import { Category } from './category.model';
import { Invoice } from './invoice.model';
import { Organization } from './organization.model';
import { ScanCase } from './scan-case.model';
import { User } from './user.model';

export interface OrderSections {
  showPatientSections: boolean;
  showConstructionSections: boolean;
  showDeliverySections: boolean;
  showSignSendSections: boolean;
}

export interface PriceSpecification {
  materialName?: string;
  numberOfUnits?: number;
  teethRange?: any;
  teethNo?: any;
  costPerTooth?: any;
  warranty?: any;
  actionId?: any;
  material: string;
  action: string;
  actionObj: {
    name: string;
    number: string;
  };
  quantity: number;
  price: number;
  vat: number;
  priceDiff: number;
}

interface NumberOfUnit {
  notSaved?: boolean;
  actionId: string;
  header: string;
  numberOfUnits: number;
  vat: number;
}

interface AddedMaterials {
  warranty?: boolean;
  material: string;
  quantity: number;
  price: number;
  vat: number;
}

export interface Rating {
  satisfied: boolean;
  notSatisfied: boolean;
  reasons: [
    {
      name: string;
      value: boolean;
    }
  ];
  comments: string;
  by: string;
}

export interface ActionBasedConstruction {
  materialName?: string;
  category: string;
  createdAt: number;
  subCategory: Category;
  actions: Action[];
  teethDetails: {
    isRange: boolean;
    teethRange: {
      start: number;
      end: number;
      isUpper: boolean;
    };
    toothNumber: number[];
  };
  material: string;
  color: string;
  shade: string;
  colorSampling: boolean;
  design: string;
  contactType: string;
  occlusion: string;
  ponticDesign: string;
  retainment: string;
  implantSteps: string;
  implantDates: any;
  implantTimes: any;
  dentureSteps: string;
  dentureDates: any;
  dentureTimes: any;
  testingSteps: string;
  testingDates: any;
  testingTimes: any;
  numberOfUnits: NumberOfUnit[];
  comment: string;
  claspTeeth: [
    {
      id: number;
    }
  ];
  claspMaterial: string;
  warranty: boolean;
  orthColor: string;
  facialLoop: string;
  claspType: string;
  springType: string;
  springTeeth: [
    {
      id: number;
    }
  ];
  expansionScrew: boolean;
  protrusion: number;
  addedMaterials: AddedMaterials[];
}

export interface Message {
  message: string;
  orderId?: string;
  type: string;
  deliveryDate: number;
  readBy: string[];
  orderVersion: number;
  by: string;
  messageOn: number;
  hasManualDateEntry?: boolean;
  manualSelectedDate?: number;
}

export interface OrderMessage {
  order: string;
  orderDisplayId: string;
  patient: Patient;
  message: number;
  messagesData: Message[];
}

export interface SimpleConstructions {
  singleCrowns: any;
  bridges: any;
  posts: any;
  implantCrowns: any;
  implantBridges: any;
  completeDentures: any;
  partialDentures: any;
  temporaryDentures: any;
  bitetrays: any;
  otherRemovables: any;
  others: any;
}

export interface SimpleTypeConstruction {
  type: string;
  abutmentTeeth: SimpleConstructions;
}

export interface Patient {
  personalIdentity: string;
  personalIdentityNumber: string;
  firstName: string;
  lastName: string;
  gender?: string;
  patientCategory:
    | string
    | {
        minage: number;
        maxage: number;
        name: string;
        translatedName?: string;
      };
  noPersonalIdentityNumber: boolean;
}

export interface SignedUrl {
  _id: string;
  contentType: string;
  name: string;
  url: string;
}

export interface SampleMethods {
  PHYSICAL: string;
  DIGITAL: string;
}

export interface Sample {
  type: string;
  content: [
    {
      name: string;
      value: boolean;
    }
  ];
  otherContent: string;
  comments: string;
  created: {
    at: number;
    by: {
      firstName: string;
      lastName: string;
    };
  };
}

export type LinkedOrder = {
  order: string;
  orderId: string;
  type: string;
};

export const enum InvoicingState {
  'NOT_READY' = 'NOT_READY',
  'READY_FOR_INVOICE' = 'READY_FOR_INVOICE',
  'SHOULD_NOT_INVOICE' = 'SHOULD_NOT_INVOICE',
  'INVOICED' = 'INVOICED'
}

export interface Order {
  selectedConstructions: any;
  _id?: string;
  orderId: string;
  scanCase?: string | ScanCase;
  isRated: boolean;
  isNewOrder?: boolean;
  ratings: any;
  unreadMessages: number;
  signedUrl: SignedUrl[];
  isRatedByDenthub: boolean;
  type: string;
  patient: Patient;
  lab: Organization;
  files: string[];
  constructions: SimpleTypeConstruction;
  deliveryDetails: {
    date: number;
    destination: Organization;
    pickUpLocation: Organization;
    status: string;
    exportOrder: boolean;
    isPickUpAtClinic: boolean;
    isLabHandleRequest: boolean;
    price: number;
    reason: any;
    time: any;
    sample: Sample;
    isLabHaveOwnResource?: boolean;
    sampleType?: SampleType;
  };
  additionalInfo: string;
  draftCreatedAt?: number;
  draftUpdatedAt?: number;
  status: string;
  rejectStatus: string;
  sampleDisinfected: boolean;
  accept: {
    status: boolean;
    on: number;
    by: string;
  };
  decline: [
    {
      by: string;
      message: string;
      declineOn: number;
    }
  ];
  cancel: {
    by: string;
    message: string;
    on: number;
  };
  messages: Message[];
  created: {
    on: number;
    by: {
      email: string;
      name: {
        firstName: string;
        lastname: string;
      };
      _id?: string;
    };
  };
  dentist: {
    email: string;
    name: {
      firstName: string;
      lastname: string;
    };
    _id?: string;
  };
  updated: {
    on: number;
    by: string;
  };
  archived: {
    by: string[];
    byClinic: boolean;
    byLab: boolean;
  };
  isModify: boolean;
  settings: any;
  newConstructions: {
    constructions: ActionBasedConstruction[];
  };
  clinic: Organization;
  cancelObj: {
    message: string;
  };
  internalOrderId: string;
  tempInvoiceNumber: string;
  rating: Rating;
  invoice: Invoice;
  rankOneLab: string;
  rankType: string;
  labFlag: boolean;
  isStatusChanged: boolean;
  isArchiveForAllByApproved: boolean;
  isRatedStatus: boolean;
  priceSpecification?: PriceSpecification[];
  isArchive?: boolean;
  sentMail?: boolean;
  id?: string;
  sample?: Sample;
  declineObj: {
    message: string;
  };
  allAdditionalInfo?: AllAdditionalInfo[];
  allSampleComment?: AllSampleComment[];
  transport?: Transport;
  approvedBy?: {
    fullName: string;
    id: string;
  };
  labHandleReq?: boolean;
  isArchiveForAllBySentLab?: boolean;
  orderVersionType?: string;
  isCreatedByLab?: boolean;
  isPriceConfirmed?: boolean;
  invoicingState?: InvoicingState;
  invoiceActionTime?: Date | null;
  invoiceCreatedTime?: Date;
  invoicingStateChangedAt?: Date;
  invoicingStateChangedBy?: string | User;
  isProductionFinished?: boolean;
  deliveryNotePdfSignedUrl?: string;
  transportTrackingLink?: string;
  isWarrantyOrder?: boolean;
  linkedOrders?: LinkedOrder[];
  orderRef?: LinkedOrder;
  isCopyOrder?: boolean;
}

interface Transport {
  _id?: string;
  noOfRequestedPackages: number;
  noOfActualPackages?: number;
  comments?: string;
  clinic: string;
  orderId?: string;
  lab?: string;
  transportDay: Date;
  type?: string;
  status?: string;
  isTransportOrderChanged?: boolean;
  deadline?: string;
  opterOrderId?: string;
  clinicName?: string;
  orders?: [Order];
  local?: boolean;
  additionalPackages?: number;
  derivedPackages?: number;
}

interface AllAdditionalInfo {
  additionalInfo: string;
  createdAt: number;
  updatedBy: string;
  version: number;
}

interface AllSampleComment {
  comment: string;
  createdDate: number;
  updatedBy: string;
  version: number;
}

export enum SampleType {
  NO_SAMPLE = 'NO_SAMPLE',
  PHYSICAL = 'PHYSICAL',
  DIGITAL = 'DIGITAL'
}
