import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import template from './dentist-signup-form.html';
import { FormBuilder, Validators } from '@angular/forms';
import { fieldsMatchValidator } from '../../../../directives/fields-match-validator.directive';
import { isValidOrganizationNumber } from '../../../../directives/organization-number-validator.directive';
import { isValidSePostalCode } from '../../../../directives/se-postal-code-validator.directive';
import { UsersGraphqlService } from '../../../../graphql-services/users/users.graphql.service';
import { LimitedDentist } from '../../../../models/user.model';

@Component({
  template,
  selector: 'dentist-signup-form',
  providers: [{ provide: Window, useValue: window }]
})
export class DentistSignupFormComponent implements OnInit, OnDestroy {
  userForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', [Validators.required, fieldsMatchValidator('password')]]
  });
  clinicForm = this.fb.group({
    clinicName: ['', Validators.required],
    organizationNumber: ['', [Validators.required, isValidOrganizationNumber]],
    phone: ['', Validators.required],
    street: ['', Validators.required],
    postalCode: ['', [Validators.required, isValidSePostalCode]],
    city: ['', Validators.required]
  });
  invoiceForm = this.fb.group({
    email: ['', [Validators.email]],
    street: [''],
    postalCode: ['', [isValidSePostalCode]],
    city: ['']
  });

  errorMessage: string;
  isLoading = false;

  constructor(
    private readonly window: Window,
    private readonly fb: FormBuilder,
    private readonly renderer: Renderer2,
    private readonly usersGraphqlService: UsersGraphqlService
  ) {}

  async ngOnInit() {
    this.renderer.addClass(document.body, 'framed-dentist-signup-form');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'framed-dentist-signup-form');
  }

  async onSubmit() {
    try {
      this.triggerFormValidation();
      if (this.isFormValid()) {
        this.isLoading = true;
        const accountData = this.createAccountData();
        await this.usersGraphqlService.createDentistAccount(accountData);
        this.window.top.location.href =
          'https://www.denthub.com/komigang-registrera/tack';
      }
    } catch (error) {
      this.isLoading = false;
      this.errorMessage = error.message;
    }
  }

  triggerFormValidation() {
    this.userForm.markAllAsTouched();
    this.clinicForm.markAllAsTouched();
    this.invoiceForm.markAllAsTouched();
  }

  isFormValid() {
    return (
      !this.userForm.invalid && !this.clinicForm.invalid && !this.invoiceForm.invalid
    );
  }

  private createAccountData(): Partial<LimitedDentist> {
    const user = this.userForm.value;
    const clinic = this.clinicForm.value;
    const invoice = this.invoiceForm.value;
    return {
      name: {
        firstName: user.firstName,
        lastName: user.lastName
      },
      password: user.password,
      confirmPassword: user.confirmPassword,
      email: user.email,
      clinicName: clinic.clinicName,
      organizationNumber: clinic.organizationNumber,
      contact: {
        address: {
          street: clinic.street,
          city: clinic.city,
          postal_code: parseInt(clinic.postalCode.replace(/ /g, '')), // NUMBER!!
          country: 'SE'
        }
      },
      invoice: {
        street: invoice.street || clinic.street,
        city: invoice.city || clinic.city,
        postal_code: (invoice.city || clinic.postalCode).replace(/ /g, ''),
        costCenter: ''
      },
      contactInfo: {
        phone: clinic.phone,
        web: '',
        email: invoice.email || user.email
      }
    };
  }
}
