/* start: imports-loader */
import '../../../app.upgrade';
import '../../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-2-lab-list-item.html'
(function () {
    'use strict';
    angular.module('app').component('orderNew2LabListItem', {
        template: theTemplate,
        controller: orderNew2LabListItemController,
        controllerAs: 'vm',
        bindings: {
            lab: "=",
            selectedLab: "=",
            orderIdToModify: "=",
            currency: "=",
            selectedLabRank: "=",
            selectedReason: "=",
            newOrderObj: '=',
            isRatingColumnEnabled: '=',
            isRecentList: '=',
            isSelectedLabRqeuireException: '='
        }
    });

    orderNew2LabListItemController.$inject = [ '$scope', 'orderNew2DeliveryService' ];

    function orderNew2LabListItemController($scope, orderNew2DeliveryService) {
        // Variables for this controller
        var vm = this;
        vm.checkedLab = checkedLab;

        // Get selected lab and rank
        function checkedLab(labId, rank, isRequireException) {
            vm.selectedLab = labId;
            vm.selectedLabRank = rank;
            vm.newOrderObj.labRank = rank;
            vm.newOrderObj.deliveryInfo.reason = {};
            vm.newOrderObj.deliveryInfo.exception = false;
            vm.selectedReason = {};
            vm.isSelectedLabRqeuireException = isRequireException;
        }

        vm.$onInit = function() {

        };

    }

})();