import {
  Injectable,
  Input,
  Component,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Hours, Organization } from '../../models/organization.model';
import { CurrentYearDayService } from '../../services/core/current-year-day.service';
import { CustomerService } from '../../services/customer/customer.service';
import { User } from '../../models/user.model';
import template from './lab-contact.html';

@Component({
  selector: 'lab-contact',
  template: template
})
export class LabContactComponent implements OnInit {
  @Input() currentUser: User;
  @Input() lab: Organization;
  validEmail: boolean = false;
  disabledWeekDays: number[] = [];
  private type: string = 'individual';
  private markDisabled: any;
  private hour: Hours;

  constructor(
    private currentYearDayService: CurrentYearDayService,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    if (!this.lab.holidayDays.length) {
      this.lab.holidayDays = [new Date().setHours(0, 0, 0, 0)];
    }
    this.lab.activeDates = this.lab.holidayDays.map((day) => new Date(day));
    if (this.lab.openingHours && this.lab.openingHours.length) {
      this.disabledWeekDays = this.lab.openingHours
        .filter((dayObj, index) => {
          let day = index + 1;
          day = day === 7 ? 0 : day;
          // TODO: Add dayNum to object?
          // @ts-ignore
          dayObj.dayNum = day;
          return !dayObj.active;
        })
        .map((dayObj) => {
          // @ts-ignore
          return dayObj.dayNum;
        });
    }
    if (this.lab.contact) {
      this.checkEmail(this.lab.contact.email);
    }
  }

  selectAllDays(isChecked: boolean): void {
    this.lab.defaultHours.forEach((defaultHour) => {
      defaultHour.active = isChecked;
    });
    this.markDisabled = () => !isChecked;
  }

  // enable/disable not selected days as holiday in calender
  setDay(hour: Hours): void {
    const weekday = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    const day = weekday.indexOf(hour.day);
    if (!hour.active) {
      this.disabledWeekDays.push(day);
    } else {
      const index = this.disabledWeekDays.indexOf(day);
      this.disabledWeekDays.splice(index, 1);
    }
  }

  /*
    check email validity
    */
  checkEmail(email: string): void {
    this.validEmail = this.customerService.validateEmail(email);
  }

  isValid(event: boolean): void {
    this.lab.isvalidOpeningHoursTime = event;
  }
}
