import {
  Input,
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS, GENDERS } from '../../../../../app.constant';
import { Order, OrderSections, Patient } from '../../../../../models/order.model';
import { User } from '../../../../../models/user.model';
import { OrderService } from '../../../../../services/order-new/order-new.service';
import { OrganizationSettingService } from '../../../../../services/organization-setting/organization-setting.service';
import { PatientCategoryService } from '../../../services/patient-category-service';
import { CustomerYearPrefixService } from '../../../services/customer-year-prefix-service';
import { OrdersGraphqlService } from '../../../../../graphql-services/orders/orders.graphql.service';
import { CurrentUserService } from '../../../../../services/users/current-user.service';
import template from './patient.html';
import { TranslationsService } from '../../../../../services/translations/translations.service';
import { RegexService } from '../../../../../services/regex/regex';
import { Organization } from '../../../../../models/organization.model';

@Component({
  selector: 'patient',
  template
})
export class PatientComponent implements OnInit, OnChanges {
  @Input() createdBy: User;
  @Input() patientInfo: Patient;
  @Input() currentUser: User;
  @Input() orderIdToModify: string;
  @Input() orderDbId: string;
  @Input() isDraft: boolean;
  @Input() isWarrantyOrder: boolean;
  @Input() sections: OrderSections;
  @Input() dentist: User;
  @Input() isCopyOrder: boolean;
  @Input() linkedDentists = [];
  @Input() linkedClinics = [];
  @Input() customParticipantEmails = [];
  @Output() patientInfoChanged: EventEmitter<Partial<Patient>> = new EventEmitter<
    Partial<Patient>
  >();
  @Output() sectionChanged: EventEmitter<OrderSections> = new EventEmitter<
    OrderSections
  >();
  @Output() additionalDentistAdded: EventEmitter<User> = new EventEmitter<User>();
  @Output() draftOrderSaved: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() linkedUsersChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() linkedClinicsChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() customEmailsChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Input() isNewOrder: boolean;

  isPatientCategoryEnabled: boolean;
  isGenderEnabled: boolean;
  genders = [];
  patient: Partial<Patient> = {};
  isPinValid = false;
  error = false;
  isResend = false;
  patientCategories: {
    minage?: number;
    maxage?: number;
    name: string;
    translatedName?: string;
  }[];
  getOrderTimeOut: ReturnType<typeof setTimeout>;
  ordersForPatient: Order[];
  patientCategorySettingName =
    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORIES;
  userPermissions: { [key: string]: boolean } = {};
  isAdditionalDentistRequired = false;
  dentistWord = 'LÄKARE';
  isFirstNameValid = false;
  isLastNameValid = false;

  constructor(
    private readonly settingService: OrganizationSettingService,
    private readonly customerYearPrefixService: CustomerYearPrefixService,
    private readonly patientService: PatientCategoryService,
    private readonly orderService: OrderService,
    private readonly translationsService: TranslationsService,
    private readonly ordersGraphqlService: OrdersGraphqlService,
    private readonly currentUserService: CurrentUserService,
    private readonly regexService: RegexService
  ) {}

  newOrderChecked(isNewOrder: boolean) {
    this.isNewOrder = isNewOrder;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patientInfo?.currentValue) {
      this.patient = Object.assign({}, changes.patientInfo?.currentValue);
      this.validatePin(this.patient.personalIdentity);
    }
  }

  async ngOnInit() {
    this.isPatientCategoryEnabled = this.settingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORY
    );
    this.isGenderEnabled = this.settingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_GENDER
    );
    if (this.isPatientCategoryEnabled) {
      const patientCategories = this.settingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORIES
      );
      for (let i = 0; i < patientCategories.length; i++) {
        patientCategories[i].translatedName = await this.translationsService.get(
          patientCategories[i].name
        );
      }
      this.patientCategories = patientCategories;
    }
    if (
      this.currentUser.title &&
      !this.currentUser.title.toUpperCase().includes(this.dentistWord)
    ) {
      this.isAdditionalDentistRequired = true;
    }

    for (const gender of GENDERS) {
      this.genders.push({
        gender: gender,
        label: await this.translationsService.get(gender)
      });
    }
  }

  getOrdersForPatient() {
    //cancel timeout if function called again. This will help prevent calling extra calls while typing.
    if (this.getOrderTimeOut && this.isPinValid) {
      clearTimeout(this.getOrderTimeOut);
    }
    // call api function after 300 milliseconds
    this.getOrderTimeOut = setTimeout(async () => {
      const response = await this.orderService.getOrdersForPatient(
        this.patient.personalIdentity
      );
      this.ordersForPatient = response.data;
      this.isNewOrder = this.isNewOrder || this.ordersForPatient.length <= 0;
    }, 300);
  }

  validatePin(pin) {
    this.patient.personalIdentity = pin;
    this.isPinValid = this.customerYearPrefixService.customerYearPrefix(
      pin,
      this.patient
    );
    if (
      this.isPatientCategoryEnabled &&
      !this.patient.patientCategory &&
      this.isPinValid
    ) {
      this.patient.patientCategory = this.patientService.getPatientCategoryByPin(
        this.patient.personalIdentity,
        this.patientCategories
      );
    } else if (!this.isPinValid && !this.patient.noPersonalIdentityNumber) {
      this.patient.patientCategory = '';
    } else {
      // do nothing
    }

    if (!this.orderIdToModify && !this.isWarrantyOrder && !this.isCopyOrder) {
      this.getOrdersForPatient();
    } else {
      this.ordersForPatient = [];
      this.isNewOrder = false;
    }
  }

  checkPin(event) {
    const pin = event.target.value;
    this.validatePin(pin);
  }

  checkIfPatientHaveOrder() {
    return !this.orderIdToModify && this.ordersForPatient?.length > 0;
  }

  checkForPinOnModify(patient: Partial<Patient>) {
    return (
      this.orderIdToModify && !patient?.noPersonalIdentityNumber && !this.isPinValid
    );
  }

  getConditionWhenPidSet(patient: Partial<Patient>, patientCondition: boolean) {
    let condition;
    if (this.checkIfPatientHaveOrder()) {
      condition = patientCondition && this.isPinValid && this.isNewOrder;
    } else if (this.checkForPinOnModify(patient)) {
      condition =
        patientCondition && (patient?.noPersonalIdentityNumber || this.isPinValid);
    } else {
      condition = patientCondition && this.isPinValid;
    }
    return condition;
  }

  getPidCondition(patient: Partial<Patient>) {
    let condition;
    let patientCondition = Boolean(
      patient?.firstName && patient?.lastName && patient?.personalIdentity
    );
    if (patient?.firstName) {
      this.isFirstNameValid = this.regexService.nameRegExp(patient?.firstName as string);
      patientCondition = patientCondition && this.isFirstNameValid;
    }
    if (patient?.lastName) {
      this.isLastNameValid = this.regexService.nameRegExp(patient?.lastName as string);
      patientCondition = patientCondition && this.isLastNameValid;
    }
    if (
      !this.isPinValid &&
      patient?.noPersonalIdentityNumber &&
      patient?.personalIdentity?.length >= 3
    ) {
      condition = patientCondition && patient?.noPersonalIdentityNumber;
    } else {
      condition = this.getConditionWhenPidSet(patient, patientCondition);
    }
    return condition;
  }

  getClass() {
    const condition = this.getPidCondition(this.patient);
    if (this.isAdditionalDentistRequired && !this.dentist?._id) {
      return 'disabled';
    }
    if (this.isPatientCategoryEnabled) {
      return condition && this.patient?.patientCategory ? '' : 'disabled';
    }
    return condition ? '' : 'disabled';
  }

  moveToNextStep() {
    this.sections.showPatientSections = false;
    this.sections.showConstructionSections = true;
    this.patientInfoChanged.emit(this.patient);
    this.sectionChanged.emit(this.sections);
    window.scrollTo(1, 1);
  }

  async saveDraft(patientData) {
    if (this.orderDbId) {
      const order = await this.ordersGraphqlService.savePatientData(
        this.orderDbId,
        patientData,
        this.dentist?._id || this.currentUser._id,
        this.linkedDentists.map((dentist) => dentist.user._id || dentist.user),
        this.linkedClinics.map(
          (linkedClinic) => linkedClinic.clinic._id || linkedClinic.clinic
        ),
        this.customParticipantEmails
      );
      this.draftOrderSaved.emit(order);
    } else {
      const draftOrder = await this.ordersGraphqlService.createDraftOrder({
        patient: patientData,
        dentist: this.dentist?._id || this.currentUser._id,
        linkedDentists: this.linkedDentists.map((dentist) => dentist.user._id),
        linkedClinics: this.linkedClinics.map(
          (linkedClinic) => linkedClinic.clinic._id
        ),
        customParticipantEmails: this.customParticipantEmails
      });
      this.draftOrderSaved.emit(draftOrder);
    }
  }

  async nextConstruction() {
    const condition = this.getPidCondition(this.patient);
    const patientData = {
      firstName: this.patient.firstName,
      lastName: this.patient.lastName,
      gender: this.patient.gender,
      patientCategory: this.patient.patientCategory || {},
      personalIdentityNumber: this.patient.personalIdentity,
      noPersonalIdentityNumber: this.patient.noPersonalIdentityNumber || false
    };
    if (this.isAdditionalDentistRequired && !this.dentist?._id) {
      return;
    }
    if (this.isPatientCategoryEnabled) {
      if (condition && this.patient.patientCategory) {
        if (!this.orderIdToModify) {
          await this.saveDraft(patientData);
        }
        this.moveToNextStep();
      }
    } else if (condition) {
      if (!this.orderIdToModify) {
        await this.saveDraft(patientData);
      }
      this.moveToNextStep();
    }
    if (this.dentist) {
      this.additionalDentistAdded.emit(this.dentist);
    }
  }

  dentistChanged(dentist: User) {
    this.dentist = dentist;
  }

  linkedDentistsChanged(linkedDentists: User[]) {
    this.linkedDentists = linkedDentists;
    this.linkedUsersChanged.emit(this.linkedDentists);
  }

  linkedOrgClinicsChanged(linkedClinics: Organization[]) {
    this.linkedClinics = linkedClinics;
    this.linkedClinicsChanged.emit(this.linkedClinics);
  }

  linkedEmailsChanged(emails: string[]) {
    this.customParticipantEmails = emails;
    this.customEmailsChanged.emit(this.customParticipantEmails);
  }

  async patientCategoryChanged(patientCategory) {
    this.patient.patientCategory = patientCategory;
  }
}
