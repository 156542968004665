import { Injectable } from '@angular/core';
import { Menu } from '../../models/menu.model';

@Injectable()
export class SubNavService {
  private list: Menu[] = [];

  getList(): Menu[] {
    return this.list;
  }

  addMenu(menu: Menu): void {
    this.list.push(menu);
  }

  addMenus(menus: Menu[]): void {
    if (menus) {
      menus.forEach((menu) => {
        this.list.push(menu);
      });
    }
  }

  removeMenu(menu: Menu): void {
    const index = this.list.indexOf(menu);
    this.list.splice(index, 1);
  }

  resetList(): void {
    this.list.splice(0, this.list.length);
  }
}
