import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import template from './transport-clinics.html';
import { LogService } from '../../../../services/core/log.service';
import { SubNavService } from '../../../../services/sub-nav/sub-nav.service';
import { TranslationsService } from '../../../../services/translations/translations.service';
import { LogisticsCustomerGraphqlService } from '../../../../graphql-services/logistics-customer/logistics-customer.graphql.service';
import { TransportClinic } from '../../../../models/transport-clinic.model';
import * as _ from 'lodash';
import { SpinnerComponent } from '../../../../newmodules/spinner/spinner.component';

@Component({
  selector: 'transport-clinics',
  template: template
})
export class TransportClinicsComponents implements OnInit, AfterViewInit {
  @ViewChild('spinner') spinner: SpinnerComponent;
  transportClinics: TransportClinic[];
  transportClinic = {};

  constructor(
    private readonly logService: LogService,
    private readonly translationsService: TranslationsService,
    private readonly subNavService: SubNavService,
    private readonly logisticsCustomerGraphqlService: LogisticsCustomerGraphqlService,) {
  }

  ngAfterViewInit(): void {
    this.spinner.show();
  }

  async ngOnInit(): Promise<void> {
    await this.addMenu();
    this.transportClinics = await this.logisticsCustomerGraphqlService.getTransportClinics();
    this.spinner.hide();
  }

  async addMenu(): Promise<void> {
    this.subNavService.resetList();
    this.subNavService.addMenu({
      name: await this.translationsService.get('CONNECT_CLINICS'),
      state: 'app.logistics-customer',
      icon: 'fa-share-alt',
      isActive: false,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('TRANSPORT_CLINICS'),
      state: 'app.transport-Clinics',
      icon: 'fa-ambulance',
      isActive: true,
      isScrollSpy: false
    });
  }

  openTransportClinicModal() {
    $('#add-transport-clinic-modal').modal('show');
  }

  OnTransportClinicAdded(transportClinic: TransportClinic) {
    if (transportClinic) {
      this.transportClinics.push(transportClinic);
    }
  }

  // open transport clinic edit model and get transport clinic data for edit
  onTransportClinicEdit(transportClinic: TransportClinic): void {
    this.transportClinic = _.cloneDeep(transportClinic);
    $('#add-transport-clinic-modal').modal('show');
  }

  onTransportClinicChanged(transportClinic: TransportClinic): void {
    const index = this.transportClinics.findIndex(
      (tClinic) => tClinic._id === transportClinic._id
    );
    if (index !== -1) {
      const clinics = Object.assign([], this.transportClinics);
      clinics.splice(index, 1, transportClinic);
      this.transportClinics = clinics;
    }
  }

  onTransportClinicDeleted(transportClinic: TransportClinic): void {
    const index = this.transportClinics.findIndex(
      (tClinic) => tClinic._id === transportClinic._id
    );
    if (index !== -1) {
      const clinics = Object.assign([], this.transportClinics);
      clinics.splice(index, 1);
      this.transportClinics = clinics;
    }
  }
}