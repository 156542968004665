import { Component, Input } from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../../../../app.constant';
import { SubCategory } from '../../../../../../../../models/organization.model';
import template from './clasp-type.html';

@Component({
  selector: 'clasp-type',
  template
})
export class ClaspTypeComponent {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedClaspType: string;
  @Input() claspTypeOptions: string[];
  claspTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_TYPES;
}
