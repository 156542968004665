/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './shades-colors.html'
(function () {
    'use strict';
    angular.module('app').component('shadesColors', {
        template: theTemplate,
        controller: shadesColorsController,
        controllerAs: 'vm',
        bindings: {
            shade: '=',
            color: '=',
            colorSampling: '=',
            tempShade: '=',
            tempColor: '=',
            isMandatory: '<',
            tempColorSampling: '='

        }
    });
    shadesColorsController.$inject = [ 'shadesColorService','$scope' ];
    function shadesColorsController(shadesColorService, $scope) {
        var vm = this;

        // Variables
        vm.shades = [];
        vm.showColors = false;
        vm.isMandatory = true;
        vm.selectedShade = {};

        /**
         * Function to get Particular color
         */
        vm.getColors = getColors;

        /**
         *  Method to get Shades
         */
        function getShades() {
            vm.shades = shadesColorService;
        }

        /**
         *  Method to get Particular Shade colors
         */
        function getColors() {
            if (vm.shade) {
                for (var i in vm.shades) {
                    if (vm.shades[i].name === vm.shade) {
                        vm.colors = vm.shades[i].colors;
                        vm.showColors = true;
                    }
                }
            }

        }

        $scope.$watch('vm.shade', function(newVal, oldVal) {
            if (!vm.shade) {
                vm.shade = vm.shades[1].name;
            }
            getColors();
        });

        /**
         *   Init Method
         */
        function init() {
            getShades();
            getColors();
        }

        init();
    }
})();