import * as Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';

const isDev = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging';

const rollbarConfig = {
  enabled: isDev,
  environment: process.env.NODE_ENV,
  accessToken: '9ec497463e1b402f99fcaf3c7666bea4',
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: ignoredMessages(),
  addErrorContext: true,
  verbose: false,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: '2.0.0', // TODO: Better way of doing this
        guess_uncaught_frames: true
      }
    }
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  if (isDev) {
    console.log('WARNING: Running in dev mode, rollbar logging is disabled.');
  }
  return new Rollbar(rollbarConfig);
}

function ignoredMessages(): string[] {
  return [
    'ERROR_IN_GETTING_NOTIFICATIONS Error: Invalid or expired token!',
    'ERROR_IN_GETTING_NOTIFICATIONS Error: Invalid token payload!',
    'Fel vid hämtning av ordrar.: Error: Invalid token payload!',
    'Invalid token payload!',
    'Error: app.run-stateChange'
  ];
}
