import { Output, Input, Component, EventEmitter, OnChanges } from '@angular/core';
import template from './warranty-type-modal.html';

@Component({
  selector: 'warranty-type-modal',
  template: template
})
export class WarrantyTypeModalComponent implements OnChanges {
  @Output() onConfirm: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Input() warrantyType: string;

  ngOnChanges() {
    if (!this.warrantyType || this.warrantyType === 'NONE') {
      this.warrantyType = 'NOT_CEMENTED';
    }
  }

  setValue(value: string) {
    this.warrantyType = value;
  }

  handleConfirm() {
    this.onConfirm.emit(this.warrantyType);
    $('#warranty-type-modal').modal('hide');
  }

  cancel() {
    this.onCancel.emit();
    $('#warranty-type-modal').modal('hide');
  }
}
