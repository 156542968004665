import { Organization } from '../../models/organization.model';
import { Input, OnChanges, Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import template from './lab-availability.html';

@Component({
  selector: 'lab-availability',
  template: template
})
export class LabAvailabilityComponent implements OnInit {
  @Input() currentUser: User;
  @Input() lab: Organization;

  ngOnInit(): void {
    this.lab.unavailableDates = this.lab.unavailableLabDates.map(
      (day) => new Date(day)
    );
  }
}
