import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.constant';
import { ActionNew, CategoryNew, Organization, ActionList } from '../../models/organization.model';
import { Injectable } from '@angular/core';
import { ApiInterceptor } from '../../interceptors/api-interceptor';

@Injectable()
export class ActionListService extends ResourceService {
  constructor(
    httpClient: HttpClient,
    private readonly apiInterceptor: ApiInterceptor
  ) {
    super(httpClient, '');
  }

  addActionListForLab(actionLists: Partial<Organization>) {
    return this.put(
      `${API.API_V2_BASE}organizations/action-list`,
      actionLists,
      undefined,
      this.apiInterceptor
    );
  }

  updateActionListForLab(actionLists: Partial<ActionList>) {
    const params = new HttpParams().set('id', actionLists._id);
    return this.put(
      `${API.API_V2_BASE}organizations/action-list/:id`,
      actionLists,
      params,
      this.apiInterceptor
    );
  }

  saveDefaultActionList(data: { actionListId: string }) {
    return this.put(
      `${API.API_V2_BASE}organizations/defaultActionList`,
      data,
      undefined,
      this.apiInterceptor
    );
  }

  addCategoryToList(actionListId: string, category: CategoryNew) {
    const params = new HttpParams().set('id', actionListId);
    return this.put(
      `${API.API_V2_BASE}action-list/:id/categories`,
      category,
      params,
      this.apiInterceptor
    );
  }

  updateCategoryOnList(listId: string, category: CategoryNew) {
    const params = new HttpParams()
      .set('id', category._id)
      .set('listId', listId);
    return this.put(
      `${API.API_V2_BASE}action-list/:listId/categories/:id`,
      category,
      params,
      this.apiInterceptor
    );
  }

  addActionToList(actionListId: string, action: ActionNew) {
    const params = new HttpParams().set('id', actionListId);
    return this.put(
      `${API.API_V2_BASE}action-list/:id/actions`,
      action,
      params,
      this.apiInterceptor
    );
  }

  updateActionOnList(listId: string, action: ActionNew) {
    const params = new HttpParams()
      .set('id', action._id)
      .set('listId', listId);
    return this.put(
      `${API.API_V2_BASE}action-list/:listId/actions/:id`,
      action,
      params,
      this.apiInterceptor
    );
  }
}