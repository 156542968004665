import { Component, Input } from '@angular/core';
import { SubCategory } from '../../../../../../../../models/organization.model';
import template from './pontic-design.html';

@Component({
  selector: 'pontic-design',
  template
})
export class PonticDesignComponent {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedPonticDesign: string;
  @Input() ponticDesigns: { name: string; imageUrl: string }[];
}
