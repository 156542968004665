import { Component, Input, OnInit } from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../../../../app.constant';
import { SubCategory } from '../../../../../../../../models/organization.model';
import { OrganizationSettingService } from '../../../../../../../../services/organization-setting/organization-setting.service';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './ortho-color.html';

@Component({
  selector: 'ortho-color',
  template
})
export class OrthoColorComponent implements OnInit {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;

  orthoSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORTHODONTICS_COLORS;

  orthodonticColors: string[];
  @Input() orthColor: string;
  key = 'orthColor';

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService,
    private readonly organizationSettingService: OrganizationSettingService
  ) {}

  ngOnInit(): void {
    this.orthodonticColors = this.organizationSettingService.getCustomerSetting(
      this.orthoSettingName
    );
  }

  setOrthoColor(value) {
    this.orthColor = value;
    this.constructionSettingsService.setSetting(this.key, value);
  }
}
