import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges
} from '@angular/core';
import { CategoryService } from '../../../services/manage-actions/category.service';
import template from './main-category-modal.html';
import { TranslationsService } from '../../../services/translations/translations.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../../services/core/log.service';
import { Category } from '../../../models/organization.model';

@Component({
  selector: 'main-category-modal',
  template: template
})
export class MainCategoryModalComponent implements OnChanges {
  @Output() getCategoryTree: EventEmitter<void> = new EventEmitter<void>();
  @Input() category: Partial<Category> = {};

  constructor(
    private categoryService: CategoryService,
    private translationsService: TranslationsService,
    private logService: LogService,
    private toastr: ToastrService
  ) {}

  ngOnChanges(): void {
    if (!this.category) {
      this.category = {};
    }
  }

  deleteCategory(): Promise<void> {
    return this.categoryService
      .deleteCategory(this.category._id)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('CATEGORY_DELETED_SUCCESSFULLY')
        );
        this.getCategoryTree.emit();
      })
      .catch(async (err) => {
        this.logService.error(
          'main-category-modal.component',
          'deleteCategory',
          (await this.translationsService.get('ERROR_IN_DELETING_CATEGORY')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_DELETING_CATEGORY')
        );
      });
  }

  addUpdateCategory(): Promise<void> {
    if (this.category._id) {
      return this.categoryService
        .updateCategory(this.category)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('CATEGORY_UPDATED_SUCCESSFULLY')
          );
          this.getCategoryTree.emit();
        })
        .catch(async (err) => {
          this.logService.error(
            'main-category-modal.component',
            'addUpdateCategory',
            (await this.translationsService.get('ERROR_IN_UPDATING_CATEGORY')) +
              ': ' +
              err
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_UPDATING_CATEGORY')
          );
        });
    }
    return this.categoryService
      .saveCategory(this.category)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('CATEGORY_CREATED_SUCCESSFULLY')
        );
        this.getCategoryTree.emit();
      })
      .catch(async (err) => {
        this.logService.error(
          'main-category-modal.component',
          'addUpdateCategory',
          (await this.translationsService.get('ERROR_IN_CREATING_CATEGORY')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_CREATING_CATEGORY')
        );
      });
  }

  closeMainCategoryModal(): void {
    this.category = {};
    $('#main-category-modal').modal('hide');
  }
}
