import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HOLIDAYS } from '../../app.constant';

@Injectable()
export class TransportDateService {
  private dateFormat = 'YYYY-MM-DD';
  private holidays = HOLIDAYS;

  nextWorkingDay(selectedDate, searchForward = true) {
    let day,
      holidayIndex,
      nextDate = moment(selectedDate);
    do {
      if (searchForward) {
        const todayDateString = moment().format(this.dateFormat);
        const pickupDateString = moment(selectedDate).format(this.dateFormat);
        if (todayDateString === pickupDateString) {
          nextDate = moment(nextDate).add(1, 'days');
        }
      } else {
        nextDate = moment(nextDate).subtract(1, 'days');
      }
      day = nextDate.toDate().getDay();
      const latestDeliveryDateMilliseconds = nextDate.toDate().setHours(0, 0, 0, 0);
      holidayIndex = this.holidays.indexOf(latestDeliveryDateMilliseconds);
    } while (day === 0 || day === 6 || holidayIndex > -1);
    return nextDate.format(this.dateFormat);
  }

  transportServiceAvailability(selectedDate) {
    const day = new Date(selectedDate).getDay();
    const selectedDateMillisecond = new Date(selectedDate).setHours(0, 0, 0, 0);
    const holidayIndex = this.holidays.indexOf(selectedDateMillisecond);
    return !(day === 0 || day === 6 || holidayIndex > -1);
  }
}
