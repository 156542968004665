import { forwardRef, Inject, Injectable } from '@angular/core';
import { translationEnglish } from '../../translations/english-translation';
import { translationSwedish } from '../../translations/swedish-translation';
import { translationTransportEnglish } from '../../feature/transport/translations/english-transport-translation';
import { translationTransportSwedish } from '../../feature/transport/translations/swedish-transport-translation';
import { translationAccountEnglish } from '../../feature/account/translations/english-account-translation';
import { translationAccountSwedish } from '../../feature/account/translations/swedish-account-translation';
import { LanguageCode } from '../../models/language.model';
import { EventService } from '../core/event.service';

import * as _ from 'lodash';

@Injectable()
export class TranslationsService {
  private currentLanguage: LanguageCode = LanguageCode.SE;
  private translations: { [key: string]: { [key: string]: string } } = {};

  constructor(
    @Inject(forwardRef(() => '$translate')) private $translate: any,
    @Inject(forwardRef(() => 'tmhDynamicLocale')) private tmhDynamicLocale: any,
    private eventService: EventService
  ) {}

  initLanguage(lang: LanguageCode, translations: { [key: string]: string }): void {
    this.translations[lang] = _.clone(translations);
  }

  init(): void {
    this.initLanguage(LanguageCode.EN, this.loadEnglishTranslations());
    this.initLanguage(LanguageCode.SE, this.loadSwedishTranslations());
  }

  async use(lang: LanguageCode): Promise<void> {
    this.$translate.use(lang);
    this.tmhDynamicLocale.set(lang);
    this.currentLanguage = lang;
    await this.refresh(lang);
  }

  transform(key: string, translation: string): string {
    if (!translation) {
      return key;
    }

    return translation;
  }

  translate(key: string): string {
    const translations = this.translations[this.currentLanguage];
    return this.transform(key, translations[key]);
  }

  async get(key: string): Promise<string> {
    return Promise.resolve(this.translate(key));
  }

  getTranslations(language: string): { [key: string]: string } {
    return this.translations ? this.translations[language] : {};
  }

  removeTranslations(language: string): void {
    this.translations = {};
    this.refresh(language);
  }

  refresh(language: string): void {
    const self = this;
    this.eventService.emit('translationsExpired');
    setTimeout(() => {
      self.$translate.refresh();
    });
  }

  async setTranslations(
    translations: { [key: string]: string },
    language: string,
    refresh: boolean = true
  ): Promise<void> {
    if (!translations) {
      return;
    }
    if (!translations[language]) {
      return;
    }

    this.translations = _.merge(this.translations, translations);
    if (refresh) {
      await this.refresh(language);
    }
  }

  private loadSwedishTranslations(): { [key: string]: string } {
    return {
      ...translationSwedish,
      ...translationTransportSwedish,
      ...translationAccountSwedish
    };
  }

  private loadEnglishTranslations(): { [key: string]: string } {
    return {
      ...translationEnglish,
      ...translationTransportEnglish,
      ...translationAccountEnglish
    };
  }
}
