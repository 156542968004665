import { Component, OnInit } from '@angular/core';
import { RoleService } from '../../services/roles/role.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { Role, RoleDefaults } from '../../models/roles.model';
import * as _ from 'lodash';
import template from './permissions.html';
import {
  CategorizedPermissions,
  PermissionProperties
} from '../../models/permissions.model';

@Component({
  selector: 'permissions',
  template: template
})
export class PermissionsComponent implements OnInit {
  private _roleId = '';
  permissions: CategorizedPermissions = {};
  roles: Role[] = [];
  roleForModal = RoleDefaults;
  selectedRole = RoleDefaults;

  set roleId(newRoleId: string) {
    this.updateSelectedRole(newRoleId);
    this._roleId = newRoleId;
  }

  get roleId(): string {
    return this._roleId;
  }

  constructor(
    private roleService: RoleService,
    private translationsService: TranslationsService,
    private logService: LogService,
    private toastr: ToastrService,
    private subNavService: SubNavService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initSubNav();
    await this.populateAllRoles();
  }

  onAddRoleClick(): void {
    this.roleForModal = RoleDefaults;
    $('#add-role-modal').modal('show');
  }

  onEditRoleClick(): void {
    this.roleForModal = this.selectedRole;
    $('#add-role-modal').modal('show');
  }

  async populateAllRoles(): Promise<void> {
    try {
      this.roleForModal = RoleDefaults;
      this.roles = await this.roleService.getAllRoles();
    } catch (err) {
      this.handleError('populateAllRoles', 'ERROR_IN_GETTING_ROLES', err);
    }
  }

  shouldShowPermission(permissionProperties: PermissionProperties): boolean {
    if (permissionProperties.forLabOnly) {
      return (
        this.selectedRole.role === 'TECHNICIAN_ADMIN' ||
        this.selectedRole.role === 'TECHNICIAN_USER'
      );
    } else if (permissionProperties.forDentistOnly) {
      return (
        this.selectedRole.role === 'DENTIST_ADMIN' ||
        this.selectedRole.role === 'DENTIST_USER' ||
        this.selectedRole.role === 'DENTIST_CLINIC_MANAGER'
      );
    }
    return true;
  }

  async onRolesUpdate(): Promise<void> {
    this.populateAllRoles();
  }

  async updatePermissions(): Promise<void> {
    try {
      this.updateRolePermissions();
      await this.roleService.updateRole(this.selectedRole);
      this.toastr.success(
        await this.translationsService.get('SUCCESSFULLY_UPDATED')
      );
      await this.populateAllRoles();
    } catch (err) {
      this.handleError('updatePermissions', 'ERROR_IN_UPDATING_ROLE', err);
    }
  }

  private updateRolePermissions() {
    this.selectedRole.permissions = {};
    _.keys(this.permissions)
      .filter((key) => this.permissions[key].length)
      .forEach((key) => {
        this.permissions[key].forEach((permission) => {
          this.selectedRole.permissions[permission.permissionName] =
            permission.isActive;
        });
      });
  }

  private async updateSelectedRole(roleId: string): Promise<void> {
    try {
      this.selectedRole = await this.roleService.getRole(roleId);
      await this.setPermissions();
    } catch (err) {
      this.handleError(
        'populateRolePermissions',
        'ERROR_IN_GETTING_ROLE_PERMISSIONS',
        err
      );
    }
  }

  private async setPermissions(): Promise<void> {
    this.permissions = await this.roleService.getAllPermissions();
    _.keys(this.permissions).forEach((key) => {
      this.permissions[key].forEach((permission) => {
        if (this.selectedRole.permissions) {
          permission.isActive = !!this.selectedRole.permissions[
            permission.permissionName
          ];
        } else {
          permission.isActive = false;
        }
      });
    });
  }

  private async initSubNav(): Promise<void> {
    this.subNavService.addMenu({
      name: await this.translationsService.get('MANAGE_PERMISSIONS'),
      state: 'app.permissions',
      icon: '',
      isActive: true,
      isScrollSpy: false
    });
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(PermissionsComponent.name, method, `${message} ${err}`);
    this.toastr.error(await this.translationsService.get(message));
  }
}
