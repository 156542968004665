import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { Organization } from '../../models/organization.model';
import { INVOICE_NUMBER_TYPES } from '../../app.constant';
import template from './configuration.html';

@Component({
  selector: 'configuration',
  template: template
})
export class ConfigurationComponent implements OnInit {
  @Input() lab: Organization;
  @Input() isInvoicingEnabled: boolean;
  readonly incremental: string = INVOICE_NUMBER_TYPES.INCREMENTAL;
  fortnoxEnabled = false;

  ngOnInit(): void {
    if (_.isEmpty(this.lab.invoicing.configuration)) {
      this.lab.invoicing.configuration = {};
    }
    if (this.lab) {
      const invoicingSettingIndex = this.lab.customerSettings.findIndex(
        (setting) => {
          return setting.name === 'FORTNOX';
        }
      );
      if (invoicingSettingIndex > -1) {
        this.fortnoxEnabled =
          this.lab.customerSettings[invoicingSettingIndex].value === 'true' ||
          ((this.lab.customerSettings[invoicingSettingIndex]
            .value as any) as boolean) === true;
      }
    }
  }

  checkInvoiceStartingNumber(event: KeyboardEvent): void {
    const key = event.which;
    if (!((key >= 48 && key <= 57) || (key >= 96 && key <= 105))) {
      event.preventDefault();
    }
  }

  changeInvoiceNumberType(): void {
    if (this.lab.invoicing.configuration.invoiceNumberType !== this.incremental) {
      this.lab.invoicing.configuration.startNumber = null;
      this.lab.invoicing.configuration.currentStartingNumber = null;
    }
  }

  checkDayValidity(): void {
    const value = this.lab.invoicing.configuration.monthlyInvoiceDay;
    if (value > 28) {
      this.lab.invoicing.configuration.monthlyInvoiceDay = 28;
    } else if (value < 1) {
      this.lab.invoicing.configuration.monthlyInvoiceDay = 1;
    }
  }

  onChangeStartingNumber() {
    this.lab.invoicing.configuration.currentStartingNumber = this.lab.invoicing.configuration.startNumber;
    console.log(this.lab.invoicing.configuration.currentStartingNumber);
  }
}
