import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Organization } from '../../models/organization.model';
import { OrganizationService } from '../../services/clinic/organization.service';
import { LogService } from '../../services/core/log.service';
import { LogisticGraphqlService } from '../../graphql-services/logistics/logistics.graphql.service';
import { NgxSpinnerService } from 'ngx-spinner';
import template from './lab-pick-up-at-clinics.html';
import * as _ from 'lodash';
const componentName = 'lab-pick-up-at-clinics';
@Component({
  selector: componentName,
  template: template
})
export class LabPickUpAtClinicsComponent implements OnInit {
  @Input() lab: Organization;
  @Input() parentClinics: Organization[];
  clinics: Organization[];
  selectedParentClinicId: string;
  promiseSetBySomeAction;
  loadingClinics = true;
  readonly spinnerName = 'transport-clinics-spinner';

  constructor(
    private organizationService: OrganizationService,
    private logService: LogService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private readonly logisticGraphqlService: LogisticGraphqlService,
    private readonly spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.spinner.show(this.spinnerName);
    this.getClinics();
  }

  // TODO: Rename this method. It is not a getter.
  getClinics(): void {
    const self = this;
    this.lab.relatedClinics = {};
    this.organizationService
      .getAllOrganizations({ type: 'clinic', active: true })
      .then((clinics) => {
        self.clinics = clinics.data;
        this.parentClinics.forEach((parentClinic) => {
          const childClinics = this.clinics.filter((clinic) => {
            return (
              clinic.parentClinicId &&
              // @ts-ignore
              clinic.parentClinicId._id === parentClinic._id
            );
          });
          this.lab.relatedClinics[parentClinic._id] = [parentClinic].concat(childClinics);
          this.loadingClinics = false;
          this.spinner.hide(this.spinnerName);
        });
      })
      .catch(async (err) => {
        self.logService.error(
          componentName,
          'getClinics',
          (await self.translationsService.get('ERROR_IN_GETTING_CLINICS')) +
            ': ' +
            err
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_IN_GETTING_CLINICS')
        );
      });
  }

  /**
   * Get related clinics of selected parent clinic
   */
  // TODO: Rename this method. It is not a getter.
  getRelatedClinics(): void {}


  async showSuccessToastr(message) {
    const text = await this.translationsService.get(message);
    this.toastr.success(text);
  }

  async toggleTransport(event) {
    this.lab.isTransportEnable = event;
    try {
      await this.logisticGraphqlService.toggleTransport({ isTransportEnable: this.lab.isTransportEnable });
      await this.showSuccessToastr('DENTHUB_TRANSPORT_UPDATED');
      window.location.reload();
    } catch (error) {
      this.logService.error(componentName, 'toggleTransport', error);
      this.toastr.error(error.message);
    }
  }

  async savePickUpClinics(): Promise<void> {}
}
