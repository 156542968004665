import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UsersService } from '../../../services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../../services/core/log.service';
import { PermissionService } from '../../../services/core/permission.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { CurrentUserService } from '../../../services/users/current-user.service';
import { User } from '../../../models/user.model';

import template from './lab-technician-list-item.html';

@Component({
  selector: 'lab-technician-list-item',
  template: template
})
export class LabTechnicianListItemComponent {
  @Output() onLabTechnicianUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Input() labTechnician: User;
  @Input() currentUser: User;

  constructor(
    private userService: UsersService,
    private permissionService: PermissionService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private currentUserService: CurrentUserService,
    private logService: LogService
  ) {}

  /**
   * Update technician
   */
  updateLabTechnician(): void {
    if (this.onLabTechnicianUpdate) {
      this.onLabTechnicianUpdate.emit(this.labTechnician);
    }
  }

  // login as user functionality for super admin
  setUser(): void {
    this.userService
      .loginAs({ id: this.labTechnician._id })
      .then((result) => {
        if (result && result.user && result.token) {
          this.currentUserService.login(
            result.token,
            result.settings,
            result.user
          );
        }
      })
      .catch(async (err) => {
        this.logService.error(
          'lab-technician-list-item.component',
          'setUser',
          (await this.translationsService.get(
            'ERROR_IN_LOGIN_AS_SUPER_ADMIN'
          )) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_LOGIN_AS_SUPER_ADMIN')
        );
      });
  }

  isSuperAdmin(user: User): boolean {
    return this.permissionService.isSuperAdmin(user);
  }

  /**
   * Set lab technician status
   * @param labTechnicianId
   * @param status
   */
  toggleLabTechnicianStatus(labTechnicianId: string, status: boolean): void {
    status = !status;
    const user = {
      id: labTechnicianId,
      active: status
    };
    // edit the user on backend
    this.userService
      .editUser(user)
      .then(async (result) => {
        this.labTechnician.active = status;
        this.toastr.success(
          await this.translationsService.get('LAB_TECHNICIAN_STATUS_UPDATED')
        );
      })
      .catch(async (err) => {
        this.logService.error(
          'lab-technician-list-item.component',
          'toggleLabTechnicianStatus',
          (await this.translationsService.get('ERROR_IN_UPDATE_USER')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_UPDATE_USER')
        );
      });
  }
}
