import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Component, forwardRef, Inject, OnInit, Input } from '@angular/core';
import { EventService } from '../../services/core/event.service';
import { UsersService } from '../../services/users/users.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { SearchResult } from '../../models/search-result.model';
import { sortBy } from 'lodash';
import { DenthubSettingsService } from '../../services/core/denthub-settings.service';
import { Order } from '../../models/order.model';
import template from './search.html';
import { NgxSpinnerService } from 'ngx-spinner';
import { CurrentUserService } from '../../services/users/current-user.service';
import { PermissionService } from '../../services/core/permission.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'search',
  template: template
})
export class SearchComponent implements OnInit {
  @Input() currentUser: User;
  readonly spinnerName = 'search-spinner';
  search: string;
  searchOpenBig: boolean;
  searchTimeOut: any;
  arrowkeyLocation = 0;
  searchData: SearchResult = {
    orders: []
  };
  currentUserPermissions: any = {};

  constructor(
    public permissionService: PermissionService,
    private readonly sanitizer: DomSanitizer,
    private readonly eventService: EventService,
    private readonly userService: UsersService,
    private readonly logService: LogService,
    private readonly toastr: ToastrService,
    private readonly denthubSettings: DenthubSettingsService,
    private readonly translationsService: TranslationsService,
    private readonly currentUserService: CurrentUserService,
    private readonly spinner: NgxSpinnerService,
    @Inject(forwardRef(() => '$state')) private readonly $state: any
  ) {
    eventService.on('pressEnter', (result, orderId) => {
      this.goToOrderDetail(orderId);
    });
  }

  highlight(text: string, search: string): SafeHtml {
    if (!search) {
      return this.sanitizer.bypassSecurityTrustHtml(text);
    }
    return this.sanitizer.bypassSecurityTrustHtml(
      text.replace(
        new RegExp(search, 'gi'),
        '<span class="highlighted-text">$&</span>'
      )
    );
  }

  ngOnInit(): void {
    this.currentUserPermissions = this.currentUserService.getPermissions();
    this.eventService.on('hideSearchBar', () => {
      if (this.searchOpenBig) {
        this.searchOpenBig = false;
        this.search = '';
      }
    });
  }

  getSearchedOrders(searchString: string): Promise<void> {
    return this.userService.getSearchData({ searchString }).then(async (result) => {
      // isLoading = true;
      this.searchData = result.data;
      this.searchData.orders = sortBy(this.searchData.orders, [
        'patient.firstName',
        'patient.lastName'
      ]);
      // getting construction data to display in search result
      for (const order of this.searchData.orders) {
        const data = [];
        this.handleSearchedOrder(order, data);
      }

      // show result panel
      this.denthubSettings.settings.showSearchBar = true;
      this.spinner.hide(this.spinnerName);
    });
  }

  onSearch(search: string): void {
    this.spinner.show(this.spinnerName);
    this.arrowkeyLocation = 0;
    this.search = search;
    if (this.search.length > 2) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut);
      }
      // cancel timeout if function called again. This will help prevent calling extra calls while typing.
      if (!search || search.length === 0) {
        this.denthubSettings.settings.showSearchBar = false;
        this.spinner.hide(this.spinnerName);
        return;
      }
      this.searchTimeOut = setTimeout(async () => {
        try {
          await this.getSearchedOrders(search);
        } catch (err) {
          this.spinner.hide(this.spinnerName);
          const text = await this.translationsService.get('ERROR_IN_SEARCH');
          this.toastr.error(text);
          this.logService.error('search.component', 'onSearch', `${text}: ${err}`);
        }
      }, 800);
    } else {
      this.spinner.hide(this.spinnerName);
    }
  }

  getConstructionType(order: Order): string {
    if (!order.newConstructions || !order.newConstructions.constructions.length) {
      return '';
    }
    // TODO: Is subCategory a string or an object?
    return order.newConstructions.constructions
      .map((construction) => construction.subCategory.name)
      .join(', ');
  }

  goToOrderDetail(orderId: string): void {
    this.arrowkeyLocation = 0;
    $('ul.main-search-bar-list')[0].scrollTop = 0;
    this.denthubSettings.settings.showSearchBar = false;
    this.$state.go(CUSTOMER_SETTINGS_CONSTANTS.ORDER_DETAIL_STATES.ORDER_DETAIL2, {
      orderId: orderId
    });
  }

  private async handleSearchedOrder(order, data): Promise<void> {
    if (this.isActionBased(order)) {
      // TODO: Make a better solution for this.
      (order as any).constructionType = this.getConstructionType(order);
    } else {
      if (order.constructions && order.constructions.abutmentTeeth) {
        this.handleFixedConstruction(order, data);
        this.handleRemovableConstruction(order, data);
        // TODO: Make a better solution for this
        (order as any).constructionData = data.join();
      }
    }
  }

  private isActionBased(order) {
    return (
      order.newConstructions &&
      order.newConstructions.constructions &&
      order.newConstructions.constructions.length
    );
  }

  private async handleFixedConstruction(order, data): Promise<void> {
    if (
      order.constructions.abutmentTeeth.bridges &&
      order.constructions.abutmentTeeth.bridges.length
    ) {
      data.push(await this.translationsService.get('BRIDGES'));
    }
    if (
      order.constructions.abutmentTeeth.implantBridges &&
      order.constructions.abutmentTeeth.implantBridges.length
    ) {
      data.push(await this.translationsService.get('IMPLANT_BRIDGES'));
    }
    if (
      order.constructions.abutmentTeeth.implantCrowns &&
      order.constructions.abutmentTeeth.implantCrowns.length
    ) {
      data.push(await this.translationsService.get('IMPLANT_CROWNS'));
    }
    if (
      order.constructions.abutmentTeeth.posts &&
      order.constructions.abutmentTeeth.posts.length
    ) {
      data.push(await this.translationsService.get('POSTS'));
    }
    if (
      order.constructions.abutmentTeeth.singleCrowns &&
      order.constructions.abutmentTeeth.singleCrowns.length
    ) {
      data.push(await this.translationsService.get('SINGLE_CROWNS'));
    }
  }

  private async handleRemovableConstruction(order, data): Promise<void> {
    if (
      order.constructions.abutmentTeeth.completeDentures &&
      order.constructions.abutmentTeeth.completeDentures.length
    ) {
      data.push(await this.translationsService.get('COMPLETE_DENTURE'));
    }
    if (
      order.constructions.abutmentTeeth.bitetrays &&
      order.constructions.abutmentTeeth.bitetrays.length
    ) {
      data.push(await this.translationsService.get('BITETRAY'));
    }
    if (
      order.constructions.abutmentTeeth.temporaryDentures &&
      order.constructions.abutmentTeeth.temporaryDentures.length
    ) {
      data.push(await this.translationsService.get('TEMPORARY_DENTURE'));
    }
    if (
      order.constructions.abutmentTeeth.partialDentures &&
      order.constructions.abutmentTeeth.partialDentures.length
    ) {
      data.push(await this.translationsService.get('PARTIAL_DENTURE'));
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
        if (this.arrowkeyLocation > 0) {
          this.arrowkeyLocation--;
        }
        break;
      case 'ArrowDown':
        if (this.arrowkeyLocation < this.searchData.orders.length) {
          this.arrowkeyLocation++;
        }
        break;
    }
  }
}
