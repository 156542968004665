import { Component, Output, EventEmitter } from "@angular/core";
import template from "./dentist-search-filter.html";

@Component({
    selector: "dentist-search-filter",
    template: template
})
export class DentistSearchFilterComponent {
    @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();
    searchText: string = "";
    timeOut: ReturnType<typeof setTimeout>;
    
    searchTextChange(value: string): void {
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        this.timeOut = setTimeout(() => {
            this.searchTextChanged.emit(value);
        }, 600);
    }
}