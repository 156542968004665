import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  OnInit
} from '@angular/core';
import template from './schedule-pick-up-from-truck-icon-modal.html';
import { User } from '../../../models/user.model';
import { Logistic } from '../../../models/logistic.model';
import { LogisticGraphqlService } from '../../../graphql-services/logistics/logistics.graphql.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../../services/core/log.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import style from './schedule-pick-up-from-truck-icon-modal.less';
import * as moment from 'moment';
import { TRANSPORT_TIME, HOLIDAYS } from '../../../app.constant';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, enGbLocale, svLocale } from 'ngx-bootstrap/chronos';
import { TransportDateService } from '../../../services/transport-date/transport-date.service';

const componentName = 'schedule-pick-up-from-truck-icon-modal';
@Component({
  selector: componentName,
  styles: [style],
  template
})
export class SchedulePickUpFromTruckIconModalComponent implements OnInit, OnChanges {
  @Input() selectedPickUpData: Logistic;
  @Input() currentUser: User;
  @Output() pickupScheduled: EventEmitter<Logistic> = new EventEmitter<Logistic>();
  @Output() onPickupUpdated: EventEmitter<Logistic> = new EventEmitter<Logistic>();
  @Output() labHandled: EventEmitter<void> = new EventEmitter<void>();
  @Output() onModalClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onTruckChange: EventEmitter<void> = new EventEmitter<void>();
  dateFormat = 'YYYY-MM-DD';
  minDate: Date = moment().startOf('day').toDate();
  isLabHaveOwnResource = false;
  promiseSetBySomeAction;
  latestDeliveryDate = moment().add(1, 'days').format(this.dateFormat);
  disabledDates: Date[];

  private readonly modalId = '#schedule-pick-up-from-truck-icon-modal';

  constructor(
    private readonly localeService: BsLocaleService,
    private readonly transportDateService: TransportDateService,
    private readonly logisticGraphqlService: LogisticGraphqlService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService,
    private readonly translationsService: TranslationsService
  ) {
    defineLocale('en-gb', enGbLocale);
    defineLocale('sv', svLocale);
  }

  ngOnInit() {
    this.disabledDates = HOLIDAYS.map((holiday) => new Date(holiday));
    let locale = this.currentUser?.systemSetting.locale;
    //Split locale because datepicker don't have 'sv-SE' locale
    if (locale === 'sv-SE') {
      locale = locale.split('-')[0];
    }
    if (locale === 'en-US') {
      locale = 'en-gb';
    }
    this.localeService.use(locale);
  }

  ngOnChanges() {
    let date;
    const currentHour = new Date().getHours();
    date =
      currentHour < TRANSPORT_TIME.PICKUP
        ? new Date()
        : moment().add(1, 'days').toDate();
    date = moment(date);
    this.minDate = date.startOf('day').toDate();
    if (this.selectedPickUpData && this.selectedPickUpData.transportDate) {
      this.latestDeliveryDate = this.transportDateService.nextWorkingDay(
        this.selectedPickUpData.transportDate
      );
    }
  }

  changeDate(event) {
    this.latestDeliveryDate = this.transportDateService.nextWorkingDay(event);
  }

  cancel() {
    this.onModalClosed.emit();
  }

  save() {
    this.schedulePickupFromTruck();
    this.promiseSetBySomeAction = new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
  }

  async showSuccessToastr(message) {
    const text = await this.translationsService.get(message);
    this.toastr.success(text);
  }

  checkOwnResource(event) {
    this.isLabHaveOwnResource = event;
    if (this.isLabHaveOwnResource) {
      this.selectedPickUpData.noOfRequestedPackages = null;
    } else {
      this.selectedPickUpData.noOfRequestedPackages = 1;
    }
  }

  async schedulePickupFromTruck() {
    try {
      if (this.selectedPickUpData._id) {
        const logistic = await this.logisticGraphqlService.updatePickupFromTruck(
          this.selectedPickUpData
        );
        await this.showSuccessToastr('PICK_UP_DELETED');
        this.onPickupUpdated.emit(logistic);
      } else {
        const logistic = await this.logisticGraphqlService.schedulePickupFromTruck(
          this.selectedPickUpData
        );
        await this.showSuccessToastr('PICK_UP_SCHEDULED');
        this.pickupScheduled.emit(logistic);
      }
      this.onTruckChange.emit();
      $(this.modalId).modal('hide');
    } catch (error) {
      this.logService.error(componentName, 'schedulePickupFromTruck', error);
      this.toastr.error(error.message);
    }
  }

  saveLabHaveOwnResource() {
    this.labHandled.emit();
    this.isLabHaveOwnResource = false;
    $(this.modalId).modal('hide');
  }
}
