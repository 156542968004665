import { NgModule } from '@angular/core';
import { ActionFilterPipe } from './action-filter.pipe';
import { ActionSearchPipe } from './action-search.pipe';
import { ConnectedLabSearchPipe } from './connected-lab-search.pipe';
import { CurrencyLocalePipe } from './currency-locale.pipe';
import { DateLocalePipe } from './date-locale.pipe';
import { LabSearchPipe } from './lab-search.pipe';
import { LogisticsClinicFilterPipe } from './logistics-clinic-filter.pipe';
import { MaterialFilterPipe } from './material-filter.pipe';
import { PlannedTransportFilterPipe } from './planned-transport-filter.pipe';
import { TransportDatePipe } from './transport-date.pipe';
import { TranslatePipe } from '../translations/translate.pipe';
import { OrganizationNumberPipe } from './organization-number.pipe';

@NgModule({
  declarations: [
    ActionFilterPipe,
    ActionSearchPipe,
    ConnectedLabSearchPipe,
    CurrencyLocalePipe,
    DateLocalePipe,
    LabSearchPipe,
    LogisticsClinicFilterPipe,
    MaterialFilterPipe,
    PlannedTransportFilterPipe,
    TransportDatePipe,
    TranslatePipe,
    OrganizationNumberPipe
  ],
  exports: [
    ActionFilterPipe,
    ActionSearchPipe,
    ConnectedLabSearchPipe,
    CurrencyLocalePipe,
    DateLocalePipe,
    LabSearchPipe,
    LogisticsClinicFilterPipe,
    MaterialFilterPipe,
    PlannedTransportFilterPipe,
    TransportDatePipe,
    TranslatePipe,
    OrganizationNumberPipe
  ]
})
export class PipesModule {}
