import { Input, Output, Component, EventEmitter, OnInit } from '@angular/core';
import { ActionListService } from '../../../../../services/action-list/action-list.service';
import { TranslationsService } from '../../../../../services/translations/translations.service';
import { LogService } from '../../../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import {
  ActionList,
  Category
} from '../../../../../models/organization.model';
import template from './add-category-modal.html';

@Component({
  selector: 'add-category-modal',
  template
})
export class AddCategoryModalComponent {
  @Input() category: Category;
  @Input() selectedList: ActionList;
  @Output()
  onModalClosed: EventEmitter<ActionList | void> = new EventEmitter<ActionList | void>();
  constructor(
    private readonly actionListService: ActionListService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService
  ) {}

  cancel() {
    this.onModalClosed.emit();
  }

  addCategory(): Promise<void> {
    return this.actionListService
      .addCategoryToList(this.selectedList._id, this.category)
      .then(async (result) => {
        this.toastr.success(
          await this.translationsService.get('CATEGORY_CREATED')
        );
        this.onModalClosed.emit(result.data.actionLists);
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_CREATING_CATEGORY'
        );
        this.logService.error(
          'add-category-modal.component',
          'addCategory',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  updateCategory(): Promise<void> {
    return this.actionListService
      .updateCategoryOnList(this.selectedList._id, this.category)
      .then(async (result) => {
        this.toastr.success(
          await this.translationsService.get('CATEGORY_UPDATED')
        );
        this.onModalClosed.emit(result.data.actionLists);
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_UPDATING_CATEGORY'
        );
        this.logService.error(
          'add-category-modal.component',
          'updateCategory',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }
}
