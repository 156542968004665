import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { Order } from '../../models/order.model';
import { TranslationsService } from '../../services/translations/translations.service';
import {
  createDraftOrderTemplate,
  saveConstructionsTemplate,
  saveDeliveryTemplate,
  saveAndSendOrderTemplate,
  saveCompletedDraftTemplate,
  changeStatusToAcceptedByLabTemplate,
  deleteDraftOrderTemplate,
  savePatientDataTemplate,
  changeStatusToDraftTemplate,
  orderNewStatusChangeTemplate,
  certifyUnCertifyOrderTemplate,
  pdfGeneratedSubscriptionTemplate,
  doNotInvoiceTemplate,
  markOrderProductionFinishedTemplate,
  markOrderProductionNotFinishedTemplate,
  transportTrackingLinkTemplate,
  saveInternalOrderIdTemplate
} from './orders.graphql';
import { User } from '../../models/user.model';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersGraphqlService {
  constructor(
    private readonly apollo: Apollo,
    private readonly translationsService: TranslationsService
  ) {}

  createDraftOrder(order: any): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: createDraftOrderTemplate,
          variables: order,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['createDraftOrder']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  savePatientData(
    _id: string,
    patient: any,
    dentist: string,
    linkedDentists: string[],
    linkedClinics: string[],
    customParticipantEmails: string[]
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: savePatientDataTemplate,
          variables: {
            _id,
            dentist,
            patient,
            linkedDentists,
            linkedClinics,
            customParticipantEmails
          },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['savePatientData']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveConstructionsToDraft(_id: string, constructionDraft: any): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: saveConstructionsTemplate,
          variables: {
            _id,
            constructionDraft
          },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['saveConstructions']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveDeliveryToDraft(
    _id: string,
    lab: string,
    deliveryDetails: any
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: saveDeliveryTemplate,
          variables: {
            _id,
            lab,
            deliveryDetails
          },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['saveDelivery']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveAndSendOrder(
    _id: string,
    price: number,
    priceSpecification: any
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: saveAndSendOrderTemplate,
          variables: { _id, priceSpecification, price },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['saveAndSendOrder']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  changeStatusToAcceptedByLab(_id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: changeStatusToAcceptedByLabTemplate,
          variables: { _id },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['changeStatusToAcceptedByLab']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  changeStatusToDraft(_id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: changeStatusToDraftTemplate,
          variables: { _id },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['changeStatusToDraft']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  deleteDraftOrder(_id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: deleteDraftOrderTemplate,
          variables: { _id },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['deleteDraftOrder']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveCompletedDraft(_id: string, dentist: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: saveCompletedDraftTemplate,
          variables: { _id, dentist },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['saveCompletedDraft']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  orderNewStatusChange(
    _id: string,
    status: string,
    isPriceConfirmed: boolean = false
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: orderNewStatusChangeTemplate,
          variables: { _id, status, isPriceConfirmed },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['orderNewStatusChange']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  certifyUnCertifyOrder(orderId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: certifyUnCertifyOrderTemplate,
          variables: { orderId },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['certifyUnCertifyOrder']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  pdfGenerated(orderId: string) {
    return this.apollo
      .subscribe({
        query: pdfGeneratedSubscriptionTemplate,
        variables: { orderId },
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      })
      .pipe(
        map((res) => {
          const data = JSON.parse(JSON.stringify(res.data['pdfGenerated']));
          if (data._id === orderId) {
            return data;
          }
        }),
        catchError((error) => {
          console.log(error, 'error');
          return of(null);
        })
      );
  }

  doNotInvoice(orderId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: doNotInvoiceTemplate,
          variables: { orderId },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['doNotInvoice']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  markOrderProductionFinished(orderId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: markOrderProductionFinishedTemplate,
          variables: { orderId },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['markOrderProductionFinished']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  markOrderProductionNotFinished(orderId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: markOrderProductionNotFinishedTemplate,
          variables: { orderId },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['markOrderProductionNotFinished']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  transportTrackingLink(orderId: string, link: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: transportTrackingLinkTemplate,
          variables: { orderId, link },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['transportTrackingLink']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveInternalOrderId(orderId: string, internalOrderId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: saveInternalOrderIdTemplate,
          variables: { orderId, internalOrderId },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['saveInternalOrderId']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

}
