import { Component, Input } from '@angular/core';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './expansion-screw.html';

@Component({
  selector: 'expansion-screw',
  template
})
export class ExpansionScrewComponent {
  @Input() isMandatory: boolean;

  @Input() expansionScrew: boolean;
  key = 'expansionScrew';

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  setExpansionScrew(value) {
    this.expansionScrew = value;
    this.constructionSettingsService.setSetting(this.key, value);
  }
}
