import { Injectable } from '@angular/core';
import { TransportAppModule } from '../transport-app.module';
import { Moment } from 'moment';
import { TransportDateService } from '../../../services/transport-date/transport-date.service';
import * as moment from 'moment';
import { TRANSPORT_TIME } from '../../../app.constant';

@Injectable({
  providedIn: TransportAppModule
})
export class TaDatesService {
  constructor(private readonly transportDateService: TransportDateService) {}
  isTransportServiceAvailable(date: Moment): boolean {
    return this.transportDateService.transportServiceAvailability(date);
  }

  isPickupAllowed(selectedDate: Moment): boolean {
    return !this.isTransportDisabled(selectedDate, TRANSPORT_TIME.PICKUP, false);
  }

  isDeliveryAllowed(selectedDate: Moment): boolean {
    const transportDate = this.pickupDateForDelivery(selectedDate);
    return !this.isTransportDisabled(transportDate, TRANSPORT_TIME.DELIVERY, true);
  }

  pickupDateForDelivery(date: Moment): Moment {
    return moment(this.transportDateService.nextWorkingDay(date, false));
  }

  dropoffDateForPickup(date: Moment): Moment {
    return moment(this.transportDateService.nextWorkingDay(date));
  }

  isOneDayTransport(pickupDate: Moment, dropoffDate: Moment): boolean {
    const diffDays = pickupDate.diff(dropoffDate, 'days');
    return Math.abs(diffDays) === 1;
  }

  // TODO: This code is duplicated, see logistics.compoment.ts
  private isTransportDisabled(
    selectedDate: Moment,
    deadlineHour: number,
    isDelivery: boolean
  ): boolean {
    if (selectedDate.startOf('day').isBefore(moment().startOf('day'))) {
      return true;
    }
    if (selectedDate.startOf('day').isSame(moment().startOf('day'))) {
      // selected date is today, check for time
      if (isDelivery) {
        const time = parseFloat(
          `${new Date().getHours()}.${new Date().getMinutes()}`
        );
        return time >= deadlineHour;
      }
      return new Date().getHours() >= deadlineHour;
    }
    return false;
  }
}
