import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { Report, ReportOptions } from '../../models/report.model';
import { API } from '../../app.constant';
import { Injectable } from '@angular/core';

@Injectable()
export class ReportsService extends ResourceService {
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
  }

  getReports(): Promise<{ data: Report[] }> {
    return this.get(
      API.API_V2_BASE + 'reports',
      undefined,
      this.apiInterceptor
    );
  }

  createReport(reportObj: {
    id: string;
    options: ReportOptions;
  }): Promise<any> {
    const params = new HttpParams().set('id', reportObj.id);

    return this.post(
      API.API_V2_BASE + 'reports/:id',
      reportObj,
      params,
      this.apiInterceptor
    );
  }

  getReportDescriptionTemplate(reportObj: {
    reportName: string;
    language: string;
  }): Promise<string> {
    const params = new HttpParams()
      .set('reportName', reportObj.reportName)
      .set('language', reportObj.language);

    return this.getText(
      API.API_V2_BASE + 'reports/description/:reportName/:language',
      params,
      this.apiInterceptor
    );
  }
}
