import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { User } from '../../models/user.model';
import { CurrentUserService } from '../../services/users/current-user.service';
import { ROLE, STATUS } from '../../app.constant';
import { UsersService } from '../../services/users/users.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../services/customer/customer.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { Organization } from '../../models/organization.model';
import { PermissionService } from '../../services/core/permission.service';
import template from './add-dentist-modal.html';
import { Role } from '../../models/roles.model';

@Component({
  selector: 'add-dentist-modal',
  template
})
export class AddDentistModalComponent implements OnInit, OnChanges {
  @Output() onUserChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  // ToDo: This should not need to be a partial user.
  // But another component is inputing a partial user, forcing this to be partial.
  // Refactoring: Make sure the other components are using real user instances.
  @Input() user: Partial<User>;
  @Input() currentUser: User;
  @Input() roles: Role[];
  dentistAdmin: string = ROLE.DENTIST_ADMIN;
  validEmail = false;
  isSuperAdmin = false;
  clinics: Organization[] = [];

  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly userService: UsersService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly customerService: CustomerService,
    private readonly organizationService: OrganizationService,
    private readonly permissionService: PermissionService,
    private readonly logService: LogService
  ) {}

  ngOnInit(): void {
    this.initParameters();
    if (this.isSuperAdmin) {
      this.fetchClinics();
    } else {
      if (
        this.currentUser.isAdmin ||
        this.permissionService.isDentist(this.currentUser)
      ) {
        this.fetchDentistClinics();
      }
    }
  }

  ngOnChanges(): void {
    // ToDo: Collabsible if statement, should refactor this.
    if (this.user) {
      this.validEmail = this.customerService.validateEmail(this.user.email);
      if (this.shouldAssignClinic()) {
        this.user.systemSetting.clinic = this.clinics[0]._id;
      }
    }
  }

  async saveUser(): Promise<void> {
    const currentUser = await this.currentUserService.getUserData();
    const userObj: User = Object.assign({}, this.user as User);
    if (this.user.organization && this.user.organization._id) {
      userObj.organization = this.user.organization._id;
    } else {
      userObj.organization = currentUser.organization._id;
    }
    userObj.isDefaultClinic = this.isDefaultClinic(currentUser, this.user);
    userObj.type = 'dentist';
    userObj.isAdmin = userObj.role === this.dentistAdmin;
    await this.addUser(userObj);
  }

  async addUser(userObj: User): Promise<void> {
    try {
      await this.userService.saveDetail(userObj);
      this.toastr.success(await this.translationsService.get('USER_CREATED'));
      this.validEmail = false;
      this.onUserChange.emit();
      this.closeModal.emit();
    } catch (err) {
      if (err.code === STATUS.DUPLICATE_EMAIL) {
        this.handleError('addUser', 'DUPLICATE_EMAIL', err);
      } else {
        this.handleError('addUser', 'ERROR_IN_CREATING_USER', err);
      }
    }
  }

  checkEmail(email: string): void {
    this.user.email = email;
    this.validEmail = this.customerService.validateEmail(this.user.email);
  }

  cancel(): void {
    this.closeModal.emit();
  }

  async updateUser(userId: string): Promise<void> {
    const userObj = {
      id: userId,
      isAdmin: this.user.role === this.dentistAdmin,
      organization: this.user.organization._id,
      active: this.user.active
    };
    const updates = Object.assign(userObj, this.user);
    try {
      await this.userService.editUser(updates);
      this.toastr.success(
        await this.translationsService.get('SUCCESSFULLY_UPDATED')
      );
      this.validEmail = false;
      this.onUserChange.emit();
      this.closeModal.emit();
    } catch (err) {
      if (err.code === STATUS.DUPLICATE_EMAIL) {
        this.handleError('updateUser', 'DUPLICATE_EMAIL', err);
      } else {
        this.handleError('updateUser', 'ERROR_IN_UPDATE_USER', err);
      }
    }
  }

  // TODO: This method runs all the time, even when modal is not visible.
  // Should use a property for [disabled] in markup, and run
  // this only on change.
  isSaveDisabled(): boolean {
    if (
      this.user &&
      this.user.systemSetting &&
      this.user.organization &&
      this.isValidOrganization()
    ) {
      const requiredFields = [
        this.user.name.firstName,
        this.user.name.lastName,
        this.user.email,
        this.validEmail,
        this.user.role
      ];

      for (const field of requiredFields) {
        if (!field) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  private isValidOrganization(): boolean {
    return (
      (this.user.organization && this.user.organization._id) ||
      (this.user.systemSetting && this.user.systemSetting.clinic)
    );
  }

  private isDefaultClinic(currentUser: User, user: Partial<User>): boolean {
    if (currentUser && user.systemSetting) {
      return (
        currentUser.isAdmin &&
        currentUser.type === 'dentist' &&
        user.systemSetting.clinic
      );
    }
    return false;
  }

  private fetchDentistClinics(): void {
    this.organizationService
      .getDentistClinics({ id: this.currentUser.organization._id })
      .then((result) => {
        this.clinics = result.data;
      })
      .catch(async (err) => {
        this.handleError(
          'getDentistClinics',
          'ERROR_IN_GETTING_CLINIC_DETAILS',
          err
        );
      });
  }

  private fetchClinics(): void {
    this.organizationService
      .getAllOrganizations({ type: 'clinic', active: true })
      .then((clinics) => {
        this.clinics = clinics.data.filter((clinic) => !clinic.parentClinicId);
      })
      .catch(async (err) => {
        this.handleError('getClinics', 'ERROR_IN_GETTING_CLINICS', err);
      });
  }

  private shouldAssignClinic(): boolean {
    if (
      !this.currentUser ||
      !this.clinics ||
      !this.user ||
      !this.user.systemSetting
    ) {
      return false;
    }

    return (
      (this.currentUser.isAdmin ||
        this.permissionService.isDentist(this.currentUser)) &&
      !this.user.systemSetting.clinic &&
      this.clinics.length > 0
    );
  }

  private initParameters() {
    this.user = this.user || {};
    this.user.systemSetting = this.user.systemSetting || {};
    this.user.name = this.user.name || {};
    this.user.organization = this.user.organization || {};
    this.isSuperAdmin = this.permissionService.isSuperAdmin(this.currentUser);
    if (this.user.email) {
      this.validEmail = this.customerService.validateEmail(this.user.email);
    }
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(
      AddDentistModalComponent.name,
      method,
      `${message} ${err}`
    );
    this.toastr.error(await this.translationsService.get(message));
  }
}
