export enum CustomerSettingType {
  List = 'List',
  Object = 'Object',
  String = 'String',
  Password = 'Password',
  Boolean = 'Boolean',
  Number = 'Number'
}

export interface CustomerSetting {
  name: string;
  value: any;
  category?: string;
  description?: string;
  type?: CustomerSettingType;
  values?: any[];
  selectedValue?: any;
  defaultValue?: any;
}
