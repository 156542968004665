import { Component, Input, Output, EventEmitter } from '@angular/core';
import template from './transport-row.html';
import { MappedTransportClinic } from '../../../services/transport-app.service';
import { Logistic } from '../../../../../models/logistic.model';
import { Organization } from '../../../../../models/organization.model';

@Component({
  selector: 'transport-row',
  template: template
})
export class TransportRowComponent {
  @Input() mappedClinic: MappedTransportClinic;
  @Output() selectedTransportOrder = new EventEmitter<{
    transportOrder: Logistic;
    clinic: Organization;
  }>();
  constructor() {}

  selectTransportOrder(transportOrder: Logistic, clinic: Organization) {
    this.selectedTransportOrder.emit({
      transportOrder,
      clinic
    });
  }
}
