export interface Role {
  _id?: string;
  active?: boolean;
  description?: string;
  permissions?: any;
  removed?: boolean;
  role: string;
  type: string;
}

export const RoleDefaults: Role = {
  role: '',
  type: ''
};
