/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-removable-constructions.html'
/**
 * order removable construction component
 */
(function () {
  'use strict';
  angular.module('app').component('orderRemovableConstructions', {
    template: theTemplate,
    controller: removableConstructionController,
    controllerAs: 'vm',
    bindings: {
      selectedConstructionObj: '=',
      selectedLab: '=',
      newOrderObj: '=',
      orderIdToModify: '=',
      commentsArray: '='
    }
  });

  /**
   * Inject the dependencies here
   * @type {string[]}
   */
  removableConstructionController.$inject = [
    'REMOVABLE_DESIGNS',
    '$scope',
    'organizationService',
    'CONSTRUCTIONS',
    'logService',
    'toastr',
    '$filter'
  ];

  /**
   * Controller function for the removable construction
   * @param REMOVABLE_DESIGNS
   */
  function removableConstructionController(
    REMOVABLE_DESIGNS,
    $scope,
    organizationService,
    CONSTRUCTIONS,
    logService,
    toastr,
    $filter
  ) {
    var vm = this;
    vm.selectedTeeth = '';
    vm.selectedTeethRange = {};
    vm.tempPartialDentureData = {};
    vm.tempTemporaryDentureData = {};
    vm.tempCompleteDentureData = {};
    vm.tempBitetraysData = {};
    vm.tempOtherRemovableData = {};
    vm.showSpinner = false;
    vm.selectedConstruction = {
      selectedType: ''
    };
    vm.constructionName = {
      temporaryDenture: CONSTRUCTIONS.TEMPORARY_DENTURE,
      partialDenture: CONSTRUCTIONS.PARTIAL_DENTURE,
      completeDenture: CONSTRUCTIONS.COMPLETE_DENTURE,
      bitetray: CONSTRUCTIONS.BITETRAY,
      otherRemovable: CONSTRUCTIONS.OTHER_REMOVABLE
    };
    vm.completeDentureCommentsArray = [];
    vm.partialDentureCommentsArray = [];
    vm.otherRemovableCommentsArray = [];
    //methods
    vm.selectType = selectType;
    vm.teethSelected = teethSelected;
    vm.teethRangeSelected = teethRangeSelected;
    vm.constructionTypeClass = constructionTypeClass;
    vm.selectConstruction = selectConstruction;
    vm.removeConstruction = removeConstruction;
    vm.removePartialAndTemporaryDenture = removePartialAndTemporaryDenture;
    vm.removeCompleteDentureAndBitetray = removeCompleteDentureAndBitetray;
    vm.addUpdatePartialDenture = addUpdatePartialDenture;
    vm.addUpdateCompleteDenture = addUpdateCompleteDenture;
    vm.removeOtherRemovable = removeOtherRemovable;
    vm.addUpdateOtherRemovable = addUpdateOtherRemovable;

    /**
     * Construction Settings
     * @type {{types: (Array|*), materials: Array}}
     */
    vm.constructionSettings = {
      types: REMOVABLE_DESIGNS.TYPES,
      materials: REMOVABLE_DESIGNS.MATERIALS
    };

    /**
     * Select new type button click
     * @param type
     */
    function selectType(type) {
      vm.selectedConstruction.selectedType = type;
      vm.selectedTeeth = '';
      vm.selectedTeethRange = {};
    }

    /**
     * removable construction classes
     * @param type
     */
    function constructionTypeClass(type, selectedType) {
      if (type !== selectedType) {
        return '';
      }
      var colorClass = '';
      switch (type) {
        case 'complete denture':
          colorClass = 'complete-denture';
          break;
        case 'partial denture':
          colorClass = 'partial-denture';
          break;
        case 'temporary denture':
          colorClass = 'temporary-denture';
          break;
        case 'bitetray':
          colorClass = 'bitetray';
          break;
        case 'other removable':
          colorClass = 'other-removable';
          break;
      }
      return colorClass;
    }

    /**
     * Function to decide which construction to be created on this teeth
     * @param teeth
     */
    function selectConstruction(teeth) {
      vm.selectedTeeth = teeth;
    }

    /**
     * remove construction from here if any
     * @param teeth
     */
    function removeConstruction(teeth) {
      if (vm.selectedConstruction.selectedType === 'complete denture') {
        removeCompleteDentureAndBitetray(teeth);
      } else if (vm.selectedConstruction.selectedType === 'partial denture') {
        removePartialAndTemporaryDenture(teeth);
      } else if (vm.selectedConstruction.selectedType === 'temporary denture') {
        removePartialAndTemporaryDenture(teeth);
      } else if (vm.selectedConstruction.selectedType === 'bitetray') {
        removeCompleteDentureAndBitetray(teeth);
      } else if (vm.selectedConstruction.selectedType === 'other removable') {
        removeOtherRemovable(teeth);
      }
    }

    /**
     * Add or update a partial denture on a teeth
     * @param partialDenture
     */
    function addUpdatePartialDenture(partialDenture, type) {
      vm.tempPartialDentureData = {};
      vm.tempTemporaryDentureData = {};
      var isUpdated = false;
      if (type === 'temporary denture') {
        //find
        vm.selectedConstructionObj.temporaryDentures.forEach(function (
          denture,
          index
        ) {
          if (partialDenture.teethNo === denture.teethNo) {
            vm.selectedConstructionObj.temporaryDentures[index] = partialDenture;
            isUpdated = true;
          }
        });
        if (!isUpdated) {
          vm.selectedConstructionObj.temporaryDentures.push(partialDenture);
        }
      } else {
        //find
        vm.selectedConstructionObj.partialDentures.forEach(function (
          denture,
          index
        ) {
          if (partialDenture.teethNo === denture.teethNo) {
            vm.selectedConstructionObj.partialDentures[index] = partialDenture;
            isUpdated = true;
          }
        });
        //if not found then add
        /* vm.selectedConstructionObj.partialDentures.push(partialDenture);*/
        if (!isUpdated) {
          vm.selectedConstructionObj.partialDentures.push(partialDenture);
        }
      }
    }

    /**
     * Add or update a complete denture on a teeth
     * @param partialDenture
     */
    function addUpdateCompleteDenture(completeDenture, type) {
      vm.tempCompleteDentureData = {};
      var ind = 0;
      vm.tempBitetraysData = {};
      var isUpdated = false;
      if (type === 'bitetray') {
        if (
          vm.selectedConstructionObj.bitetrays &&
          vm.selectedConstructionObj.bitetrays.length
        ) {
          for (var ib in vm.selectedConstructionObj.bitetrays) {
            if (
              vm.selectedConstructionObj.bitetrays[ib].teethRange &&
              vm.selectedConstructionObj.bitetrays[ib].teethRange.start ===
                completeDenture.teethRange.start &&
              vm.selectedConstructionObj.bitetrays[ib].teethRange.end ===
                completeDenture.teethRange.end
            ) {
              isUpdated = true;
              ind = ib;
            }
          }

          //if not found then add
          if (!isUpdated) {
            vm.selectedConstructionObj.bitetrays.push(completeDenture);
          } else {
            if (ib) {
              vm.selectedConstructionObj.bitetrays.splice(ind, 1);
              vm.selectedConstructionObj.bitetrays.splice(ind, 0, completeDenture);
            }
          }
        } else {
          vm.selectedConstructionObj.bitetrays.push(completeDenture);
        }
      } else {
        if (
          vm.selectedConstructionObj.completeDentures &&
          vm.selectedConstructionObj.completeDentures.length
        ) {
          for (var br in vm.selectedConstructionObj.completeDentures) {
            if (
              vm.selectedConstructionObj.completeDentures[br].teethRange.start ===
                completeDenture.teethRange.start &&
              vm.selectedConstructionObj.completeDentures[br].teethRange.end ===
                completeDenture.teethRange.end
            ) {
              isUpdated = true;
              ind = br;
            }
          }

          //if not found then add
          if (!isUpdated) {
            vm.selectedConstructionObj.completeDentures.push(completeDenture);
          } else {
            if (br) {
              vm.selectedConstructionObj.completeDentures.splice(ind, 1);
              vm.selectedConstructionObj.completeDentures.splice(
                ind,
                0,
                completeDenture
              );
            }
          }
        } else {
          vm.selectedConstructionObj.completeDentures.push(completeDenture);
        }
      }
    }

    /**
     * Add or update a other removable teeth
     * @param partialDenture
     */
    function addUpdateOtherRemovable(otherRemovable, type) {
      vm.tempOtherRemovable = {};
      var isUpdated = false;
      //find
      if (type === vm.constructionName.otherRemovable) {
        vm.selectedConstructionObj.otherRemovables.forEach(function (other, index) {
          if (otherRemovable.teethNo === other.teethNo) {
            vm.selectedConstructionObj.otherRemovables[index] = otherRemovable;
            isUpdated = true;
          }
        });
        if (!isUpdated) {
          vm.selectedConstructionObj.otherRemovables.push(otherRemovable);
        }
      }
    }

    /**
     * remove already created complete Denture and Bitetray
     * @param teeth
     */
    function removeCompleteDentureAndBitetray(teeth) {
      var index = -1;
      if (vm.selectedConstruction.selectedType === 'complete denture') {
        for (
          var i = 0;
          i < vm.selectedConstructionObj.completeDentures.length;
          i++
        ) {
          if (
            vm.selectedConstructionObj.completeDentures[i].teethRange.end === teeth
          ) {
            index = i;
          }
        }
        if (index > -1) {
          vm.selectedConstructionObj.completeDentures.splice(index, 1);
        }
      }
      if (vm.selectedConstruction.selectedType === 'bitetray') {
        for (var j = 0; j < vm.selectedConstructionObj.bitetrays.length; j++) {
          if (vm.selectedConstructionObj.bitetrays[j].teethRange.end === teeth) {
            index = j;
          }
        }
        if (index > -1) {
          vm.selectedConstructionObj.bitetrays.splice(index, 1);
        }
      }
      vm.selectedTeethRange = {};
    }

    /**
     * remove already created partial denture and temporary
     * @param teeth
     */
    function removePartialAndTemporaryDenture(denture) {
      var index = -1;
      if (vm.selectedConstruction.selectedType === 'partial denture') {
        for (var i = 0; i < vm.selectedConstructionObj.partialDentures.length; i++) {
          if (vm.selectedConstructionObj.partialDentures[i].teethNo === denture) {
            index = i;
          }
        }
        if (index > -1) {
          vm.selectedConstructionObj.partialDentures.splice(index, 1);
        }
      }
      if (vm.selectedConstruction.selectedType === 'temporary denture') {
        for (
          var j = 0;
          j < vm.selectedConstructionObj.temporaryDentures.length;
          j++
        ) {
          if (vm.selectedConstructionObj.temporaryDentures[j].teethNo === denture) {
            index = j;
          }
        }
        if (index > -1) {
          vm.selectedConstructionObj.temporaryDentures.splice(index, 1);
        }
      }

      vm.selectedTeeth = '';
    }

    function removeDenture(teethRange) {
      var index = -1;
      for (var i = 0; i < vm.selectedConstructionObj.completeDentures.length; i++) {
        if (
          vm.selectedConstructionObj.completeDentures[i].teethRange.start ===
            teethRange.start &&
          vm.selectedConstructionObj.completeDentures[i].teethRange.end ===
            teethRange.end
        ) {
          index = i;
        }
      }
      if (index > -1) {
        vm.selectedConstructionObj.completeDentures.splice(index, 1);
      }

      var indexImplant = -1;
      for (var j = 0; j < vm.selectedConstructionObj.bitetrays.length; j++) {
        if (
          vm.selectedConstructionObj.bitetrays[i].teethRange.start ===
            teethRange.start &&
          vm.selectedConstructionObj.bitetrays[i].teethRange.end === teethRange.end
        ) {
          indexImplant = j;
          break;
        }
      }
      if (indexImplant > -1) {
        vm.selectedConstructionObj.bitetrays.splice(indexImplant, 1);
      }
    }

    function removeOtherRemovable(other) {
      var index = -1;
      if (
        vm.selectedConstruction.selectedType === vm.constructionName.otherRemovable
      ) {
        for (var i = 0; i < vm.selectedConstructionObj.otherRemovables.length; i++) {
          if (vm.selectedConstructionObj.otherRemovables[i].teethNo === other) {
            index = i;
          }
        }
        if (index > -1) {
          vm.selectedConstructionObj.otherRemovables.splice(index, 1);
        }
      }
      vm.selectedTeeth = '';
    }

    // Teeth selected method
    function teethSelected(teeth) {
      //vm.teethSelected
      if (
        vm.selectedConstruction.selectedType === 'partial denture' ||
        vm.selectedConstruction.selectedType === 'temporary denture'
      ) {
        vm.partialDentureObj = {};
        vm.selectedConstructionObj.partialDentures.forEach(function (crown) {
          if (crown.teethNo === teeth) {
            // it is update case
            vm.partialDentureObj = angular.copy(crown);
          }
        });
        vm.selectedConstructionObj.temporaryDentures.forEach(function (crown) {
          if (crown.teethNo === teeth) {
            // it is update case
            vm.partialDentureObj = angular.copy(crown);
          }
        });
      } else if (
        vm.selectedConstruction.selectedType === vm.constructionName.otherRemovable
      ) {
        vm.otherRemovableObj = {};
        vm.selectedConstructionObj.otherRemovables.forEach(function (other) {
          if (other.teethNo === teeth) {
            // it is update case
            vm.otherRemovable = angular.copy(other);
          }
        });
      }
    }

    // teeth range selected method
    function teethRangeSelected(teethRange) {
      if (vm.selectedConstruction.selectedType === 'complete denture') {
        vm.completeDentureData = {};
        vm.selectedConstructionObj.completeDentures.forEach(function (bridge) {
          if (
            bridge.teethRange &&
            bridge.teethRange.start === teethRange.start &&
            bridge.teethRange.end === teethRange.end
          ) {
            // it is update case
            vm.completeDentureData = angular.copy(bridge);
          }
        });
      } else if (vm.selectedConstruction.selectedType === 'bitetray') {
        vm.completeDentureData = {};
        vm.selectedConstructionObj.bitetrays.forEach(function (bridge) {
          if (
            bridge.teethRange &&
            bridge.teethRange.start === teethRange.start &&
            bridge.teethRange.end === teethRange.end
          ) {
            // it is update case
            vm.completeDentureData = angular.copy(bridge);
          }
        });
      }
    }

    // Method to check the current lab have the type of constructions
    $scope.$watch(
      'vm.selectedLab',
      function () {
        vm.showSpinner = true;
        if (vm.selectedLab) {
          organizationService
            .getOrganization({ id: vm.selectedLab })
            .then(function (result) {
              vm.currentSelectedLab = result.data;
              vm.newOrderObj.labData = result.data;
              if (vm.currentSelectedLab.currentJobs.length) {
                for (var i in vm.currentSelectedLab.currentJobs) {
                  for (var j in vm.constructionSettings.types) {
                    if (
                      vm.currentSelectedLab.currentJobs[i].name ===
                      vm.constructionSettings.types[j].value
                    ) {
                      vm.constructionSettings.types[j].active =
                        vm.currentSelectedLab.currentJobs[i].active;
                    }
                  }
                }
              } else {
                vm.constructionSettings.types.forEach(function (type) {
                  if (type.value !== 'other_removable') {
                    type.active = false;
                  }
                });
              }
              vm.showSpinner = false;
            })
            .catch(function (err) {
              logService.error(
                'order-removable-constructions.component',
                'vm.selectedLab',
                $filter('translate')('ERROR_IN_GETTING_LAB') + ': ' + err
              );
              toastr.error($filter('translate')('ERROR_IN_GETTING_LAB'));
            });
        }
      },
      true
    );

    $scope.$watch(
      'vm.commentsArray',
      function () {
        // check if there is array of fixed constructions
        if (vm.commentsArray && vm.commentsArray.length) {
          vm.completeDentureCommentsArray = vm.commentsArray.filter(function (
            comment
          ) {
            return (
              comment.constructionType === 'bitetrays' ||
              comment.constructionType === 'completeDentures'
            );
          });
          vm.partialDentureCommentsArray = vm.commentsArray.filter(function (
            comment
          ) {
            return (
              comment.constructionType === 'partialDentures' ||
              comment.constructionType === 'temporaryDentures'
            );
          });
          vm.otherRemovableCommentsArray = vm.commentsArray.filter(function (
            comment
          ) {
            return comment.constructionType === 'otherRemovables';
          });
        }
      },
      true
    );
  }
})();
