import { Component, Output, Input, EventEmitter } from '@angular/core';
import { TransportClinic } from '../../../../../models/transport-clinic.model';
import template from './transport-clinics-list.html';
@Component({
  selector: 'transport-clinics-list',
  template: template
})
export class TransportClinicsListComponent {
  @Input() transportClinic: TransportClinic;
  @Output() onTransportClinicEdit: EventEmitter<TransportClinic> = new EventEmitter<TransportClinic>();

  // get data for update
  editTransportClinic(): void {
    this.onTransportClinicEdit.emit(this.transportClinic);
  }

}