import { Component, EventEmitter, Output } from '@angular/core';
import template from './change-password-modal.html';
import { UsersService } from '../../services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../services/core/log.service';
import { TranslationsService } from '../../services/translations/translations.service';

@Component({
  selector: 'change-password',
  template: template
})
export class ChangePasswordComponent {
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  successCheck: boolean = false;

  passwordObj: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  } = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  constructor(
    private userService: UsersService,
    private toastr: ToastrService,
    private logService: LogService,
    private translationsService: TranslationsService
  ) {}

  async changePassword(): Promise<void> {
    const self = this;
    this.userService
      .changePassword(this.passwordObj)
      .then(async () => {
        self.cancel();
      })
      .catch(async (err) => {
        self.cancel();
        const text = await self.translationsService.get(
          'ERROR_IN_CHANGE_PASSWORD'
        );
        self.logService.error(
          'change-password-modal.component',
          'changePassword',
          text + ': ' + err
        );
        self.toastr.error(text);
      });
  }

  cancel(): void {
    this.closeModal.emit();
  }

  checkPasswordMatch(): boolean {
    if (!this.passwordObj || !this.passwordObj.newPassword) {
      return true;
    }
    if (this.passwordObj.newPassword !== this.passwordObj.confirmPassword) {
      this.successCheck = false;
      return true;
    } else {
      this.successCheck = true;
      return false;
    }
  }
}
