import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { CLASP_TEETH } from '../../../../../../../../app.constant';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './spring-teeth.html';

@Component({
  selector: 'spring-teeth',
  template
})
export class SpringTeethComponent implements OnChanges {
  @Input() isMandatory: boolean;
  @Input() selectedSpringTeeth: { id: number }[] = [];
  showDropdown = false;
  teethList = cloneDeep(CLASP_TEETH.TEETH);

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSpringTeeth?.currentValue?.length) {
      this.selectedSpringTeeth.forEach((selectedTooth) => {
        const teeth = this.teethList?.find((teeth) => teeth.id === selectedTooth.id);
        if (teeth) {
          teeth.selected = true;
        }
      });
    }
  }

  getTeethStr() {
    if (!this.selectedSpringTeeth) {
      return [];
    }
    return this.selectedSpringTeeth.map((teeth) => teeth.id);
  }

  teethSelected(event, teeth: { id: number; label: string; selected: boolean }) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.selectedSpringTeeth) {
      this.selectedSpringTeeth = [];
    }
    // If teeth is already selected
    if (teeth.selected) {
      teeth.selected = false;
      const index = this.selectedSpringTeeth.findIndex((t) => t.id === teeth.id);
      if (index >= 0) {
        this.selectedSpringTeeth.splice(index, 1);
      }
    } else {
      teeth.selected = true;
      this.selectedSpringTeeth.push({ id: teeth.id });
    }
    this.constructionSettingsService.setMultiSelectSetting(
      'springTeeth',
      this.selectedSpringTeeth
    );
  }
}
