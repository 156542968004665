import template from './select-clinic.html';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Organization } from '../../../../../models/organization.model';

@Component({
  selector: 'ta-select-clinic',
  template: template
})
export class TaSelectClinicComponent {
  @Input() clinics: Organization[];
  @Output() clinicSelected = new EventEmitter<Organization>();

  isClinicSelected = false;
  clinicInputControl = new FormControl();
  filteredClincs: Observable<Organization[]>;

  ngOnInit() {
    this.filteredClincs = this.clinicInputControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    );
  }

  displayClinicName(clinic: Organization): string {
    return clinic && clinic.name ? clinic.name : '';
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) {
      this.isClinicSelected = true;
      this.clinicSelected.emit(event.option.value);
    }
  }

  private filter(value: string): Organization[] {
    if (value && typeof value !== 'object') {
      const filterValue = value.toLowerCase();
      return this.clinics.filter(
        (clinic) => clinic.name.toLowerCase().search(filterValue) !== -1
      );
    }
    return this.clinics;
  }
}
