import { Component, EventEmitter, Input, Output } from '@angular/core';
import template from './add-role-modal.html';
import { RoleService } from '../../../services/roles/role.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { Role } from '../../../models/roles.model';
import { ROLE_TYPE_CONSTANTS } from '../../../app.constant';
@Component({
  selector: 'add-role-modal',
  template: template
})
export class AddRoleModalComponent {
  @Input() role: Role;
  @Input() rolePermission: Role;
  @Output() rolesUpdate: EventEmitter<void> = new EventEmitter<void>();

  roleTypes: string[] = ROLE_TYPE_CONSTANTS;

  constructor(
    private roleService: RoleService,
    private translationsService: TranslationsService,
    private logService: LogService,
    private toastr: ToastrService
  ) {}

  closeModal(): void {
    $('#add-role-modal').modal('hide');
    this.rolesUpdate.emit();
  }

  addUpdateRole(): Promise<void> {
    if (this.role._id) {
      return this.roleService
        .updateRole(this.role)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('SUCCESSFULLY_UPDATED')
          );
          this.closeModal();
        })
        .catch(async (err: any) => {
          this.handleError('addUpdateRole', 'ERROR_IN_UPDATING_ROLE', err);
        });
    }
    return this.roleService
      .saveRole(this.role)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_ADDED')
        );
        window.location.reload();
        this.closeModal();
      })
      .catch(async (err: any) => {
        this.handleError('addUpdateRole', 'ERROR_IN_ADDING_ROLE', err);
      });
  }

  deleteRole(): Promise<void> {
    return this.roleService
      .deleteRole(this.role._id)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_DELETED')
        );
        this.rolePermission = {} as Role;
        this.closeModal();
      })
      .catch(async (err: any) => {
        this.handleError('deleteRole', 'ERROR_IN_DELETING_ROLE', err);
      });
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(AddRoleModalComponent.name, method, `${message} ${err}`);
    this.toastr.error(await this.translationsService.get(message));
  }
}
