import { Input, Inject, Component, OnInit } from '@angular/core';
import template from './invite-lab-modal.html';
import { OrganizationService } from '../../services/clinic/organization.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { Organization } from '../../models/organization.model';

@Component({
  selector: 'invite-lab-modal',
  template: template
})
export class InviteLabModalComponent {
  @Input() lab: Organization;
  currentUserPermissions: any = {};

  constructor(
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    private logService: LogService,
    private translationsService: TranslationsService
  ) {}

  sendInvite(): Promise<void> {
    return this.organizationService
      .inviteLab(this.lab)
      .then(async (result) => {
        $('#invite-lab').modal('hide');
        this.lab.companyEmail = '';
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_SENT')
        );
      })
      .catch(async (err) => {
        this.logService.error(
          'invite-lab-modal.component',
          'sendInvite',
          (await this.translationsService.get('ERROR_IN_SENT_INVITATION')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_SENT_INVITATION')
        );
      });
  }
}
