import { Input, Component } from '@angular/core';
import template from './added-actions-table-header.html';

@Component({
  selector: 'added-actions-table-header',
  template: template
})
export class AddedActionsTableHeaderComponent {
  @Input() isVisibleInPrint: boolean = false;
}
