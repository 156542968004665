import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.constant';
import { User } from '../../models/user.model';
import { Injectable } from '@angular/core';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { Status } from '../../models/status.model';
import { TokenService } from '../core/token.service';
import { TokenLoginResponse } from '../../models/token-payload.model';
import { isUndefined } from 'lodash';
import { SearchResult } from '../../models/search-result.model';
import { CacheFactory, Cache } from 'cachefactory';

@Injectable()
export class UsersService extends ResourceService {
  private readonly searchCache: Cache;

  constructor(
    httpClient: HttpClient,
    private readonly tokenService: TokenService,
    private readonly apiInterceptor: ApiInterceptor
  ) {
    super(httpClient, '');
    const cacheFactory = new CacheFactory();
    this.searchCache = cacheFactory.createCache('UsersService.search', {
      deleteOnExpire: 'aggressive',
      maxAge: 5 * 60 * 1000
    });
  }

  // TODO: Rename this function, it means "add user"
  saveDetail(user: User): Promise<Partial<Status>> {
    return this.post(`${API.API_BASE}users`, user, undefined, this.apiInterceptor);
  }

  getUsers(): Promise<{ data: User[] }> {
    return this.get(`${API.API_BASE}users`, undefined, this.apiInterceptor);
  }

  getAllUsersForSuperAdmin(data: {
    type: string;
    pageNo?: number;
    skip?: string;
    limit?: string;
    labIds?: string[];
    clinicIds?: string[];
    filter?: string;
  }): Promise<{ data: User[] }> {
    let params = new HttpParams()
      .set('type', data.type)
      .set('filter', data.filter)
      .set('skip', data.skip)
      .set('limit', data.limit);

    if (!isUndefined(data.pageNo)) {
      params = params.append('pageNo', data.pageNo.toString());
    }

    if (data.labIds) {
      data.labIds.forEach((labId) => (params = params.append('labIds[]', labId)));
    }

    if (data.clinicIds) {
      data.clinicIds.forEach(
        (clinicId) => (params = params.append('clinicIds[]', clinicId))
      );
    }

    return this.get(
      `${API.API_V2_BASE}organizations/users`,
      params,
      this.apiInterceptor
    );
  }

  getAllUsers(data: {
    type?: string;
    organizationId: string;
    pageNo?: number;
    skip?: string;
    limit?: string;
    filter?: string;
  }): Promise<{ data: { users: User[]; length: number } }> {
    let params = new HttpParams()
      .set('type', data.type)
      .set('organizationId', data.organizationId)
      .set('skip', data.skip)
      .set('limit', data.limit)
      .set('filter', data.filter);

    if (!isUndefined(data.pageNo)) {
      params = params.append('pageNo', data.pageNo.toString());
    }

    return this.get(
      `${API.API_V2_BASE}organizations/:organizationId/users`,
      params,
      this.apiInterceptor
    );
  }

  editUser(user: {
    id: string;
    active: boolean;
    isAdmin?: boolean;
    organization?: string;
  }): Promise<Partial<Status>> {
    const params = new HttpParams().set('id', user.id);

    return this.put(`${API.API_BASE}users/:id`, user, params, this.apiInterceptor);
  }

  getUserById(id: string): Promise<{ data: User }> {
    const params = new HttpParams().set('id', id);

    return this.get(`${API.API_BASE}users/:id`, params, this.apiInterceptor);
  }

  removeUser(id: string): Promise<Partial<Status>> {
    const params = new HttpParams().set('id', id);

    return this.delete(`${API.API_BASE}users/:id`, params, this.apiInterceptor);
  }

  getCurrentUser(): Promise<{ user: User }> {
    return this.get(`${API.API_BASE}user/by/token`, undefined, this.apiInterceptor);
  }

  changePassword(changePasswordData: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }): Promise<Partial<Status>> {
    return this.put(
      `${API.API_BASE}changePassword`,
      changePasswordData,
      undefined,
      this.apiInterceptor
    );
  }

  editProfile(user: User): Promise<Partial<Status>> {
    const params = new HttpParams().set('id', user._id);

    return this.put(
      `${API.API_BASE}user/setting/:id`,
      user,
      params,
      this.apiInterceptor
    );
  }

  async getSearchData(searchObj: {
    searchString: string;
  }): Promise<{ data: SearchResult }> {
    if (!this.searchCache.get(searchObj.searchString)) {
      this.searchCache.put(
        searchObj.searchString,
        await this.searchBackend(searchObj.searchString)
      );
    }
    return this.searchCache.get(searchObj.searchString);
  }

  loginAsSuperAdmin(): Promise<TokenLoginResponse> {
    // TODO: Why is this a POST request? It doesn't take any data, should be GET.
    return this.post(
      `${API.API_BASE}login-as-super-admin`,
      undefined,
      undefined,
      this.apiInterceptor
    ).then((response) => {
      return response;
    });
  }

  loginAs(userObj: { id: string }): Promise<TokenLoginResponse> {
    const params = new HttpParams().set('userId', userObj.id);

    // TODO: Why is this a POST request? It doesn't take any data, should be GET.
    return this.post(
      `${API.API_BASE}login-as/:userId`,
      undefined,
      params,
      this.apiInterceptor
    ).then((response) => {
      return response;
    });
  }

  login(email: string, password: string): Promise<TokenLoginResponse> {
    const loginData = { email, password };
    return this.post(
      `${API.API_BASE}user/login`,
      loginData,
      undefined,
      this.apiInterceptor
    ).then((response) => {
      return response;
    });
  }

  resetUserPassword(resetPasswordData: {
    id: string;
    password: string;
    repeatPassword: string;
  }): Promise<Partial<Status>> {
    return this.post(
      `${API.API_BASE}user/reset-password`,
      resetPasswordData,
      undefined,
      this.apiInterceptor
    );
  }

  sendEmailUser(emailObject: { email: string }): Promise<Partial<Status>> {
    return this.post(
      `${API.API_BASE}user/forgot-password`,
      emailObject,
      undefined,
      this.apiInterceptor
    );
  }

  getLabTechnicians(): Promise<{ data: User[] }> {
    return this.get(
      `${API.API_BASE}lab/technicians`,
      undefined,
      this.apiInterceptor
    );
  }

  private async searchBackend(
    searchString: string
  ): Promise<{ data: SearchResult }> {
    const params = new HttpParams().set('searchString', searchString);
    return this.get(`${API.API_BASE}search`, params, this.apiInterceptor);
  }
}
