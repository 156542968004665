import { Input, Component, OnChanges, SimpleChanges } from '@angular/core';
import _ = require('lodash');
import {
  ActionBasedConstruction,
  Order,
  PriceSpecification
} from '../../../models/order.model';
import { User } from '../../../models/user.model';
import template from './price-spec-list.html';
import { PriceSpecService } from './price-spec.service';

@Component({
  selector: 'price-spec-list',
  template
})
export class PriceSpecListComponent implements OnChanges {
  @Input() orderDetail: Order;
  @Input() currency: string;
  @Input() currentUser: User;
  @Input() totalVat: number;
  @Input() totalPrice: number;
  actionsAndMaterials: PriceSpecification[];
  priceDiff: PriceSpecification[];

  private readonly percent = 100;

  constructor(private readonly priceSpecService: PriceSpecService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderDetail?.currentValue) {
      this.actionsAndMaterials = [];
      this.priceDiff = [];
      if (this.orderDetail?.priceSpecification?.length) {
        this.showPriceSpecFromDB();
      } else {
        if (this.orderDetail?.type === 'Order_2') {
          this.calculatePriceSpec();
        }
      }
      this.orderDetail.deliveryDetails.price = this.totalPrice;
    }
  }

  getUnits(action, construction, unit) {
    const mats = _.cloneDeep(action.materialGroup.materials);
    return mats.map((material) => {
      if (this.priceSpecService.checkIfTeethExists(action, construction.teethNo)) {
        material.quantity = construction.teethNo.length;
      } else if (
        this.priceSpecService.checkIfTeethRangeExists(
          action,
          construction.teethRange
        )
      ) {
        material.quantity = 2;
      } else {
        material.quantity = unit ? unit.numberOfUnits : 1;
      }
      material.vat = action.vat || 0;
      material.actionId = action._id;
      return material;
    });
  }

  getMaterials(construction) {
    return construction.actions.reduce((res, action) => {
      action.teethNo = construction.teethDetails.toothNumber;
      action.teethRange = construction.teethDetails.teethRange;
      const unit = construction.numberOfUnits.find((numberOfUnit) => {
        return action._id === numberOfUnit.actionId;
      });
      if (action.materialGroup?.materials) {
        return res.concat(this.getUnits(action, construction, unit));
      }
      return res;
    }, []);
  }

  getMaterialPriceDiff(
    constructions: ActionBasedConstruction[],
    priceDiff: PriceSpecification[]
  ) {
    return constructions.reduce((res, construction) => {
      construction.numberOfUnits = this.priceSpecService.getNumOfUnits(
        construction.numberOfUnits
      );
      const materials = this.getMaterials(construction);

      const mats = materials.filter((mat) => {
        return construction.materialName === mat.material;
      });

      mats.forEach((mat) => {
        const diff = priceDiff.find((pDiff) => {
          return mat._id === pDiff.material;
        });
        if (diff) {
          mat.priceDiff = diff.priceDiff;
        }
      });

      return res.concat(mats);
    }, []);
  }

  getMaterialWithName(materials, matGroups) {
    materials.forEach((material) => {
      const mg = matGroups.find((matGroup) => matGroup._id === material.material);
      if (mg) {
        material.materialName = mg.material;
      }
    });
    return materials;
  }

  getFormattedActionsAndMaterials(actionsAndMaterials) {
    return actionsAndMaterials.map((elem) => {
      const priceSpec = {
        quantity: elem.quantity,
        vat: elem.vat || 0,
        price: elem.price
      };
      if (elem.material) {
        return Object.assign({ material: elem.material }, priceSpec);
      }
      if (!elem.actionObj) {
        return {};
      }
      return Object.assign(
        {
          number: elem.actionObj.number,
          name: elem.actionObj.name
        },
        priceSpec
      );
    });
  }

  showPriceSpecFromDB() {
    this.orderDetail.priceSpecification.forEach((spec) => {
      if (!spec.priceDiff) {
        this.actionsAndMaterials.push(spec);
      } else {
        this.priceDiff.push(spec);
      }
    });
    this.priceDiff.forEach((elem) => {
      elem.materialName = elem.material;
    });
    this.actionsAndMaterials = this.getFormattedActionsAndMaterials(
      this.actionsAndMaterials
    );
    this.totalPrice = 0;
    this.totalVat = 0;
    this.actionsAndMaterials.forEach((elem) => {
      if (elem.price) {
        const tempPrice = elem.price * elem.quantity;
        const vatPrice = (tempPrice * (elem.vat || 0)) / this.percent;
        this.totalVat += vatPrice;
        this.totalPrice += tempPrice + vatPrice;
      }
    });

    this.priceDiff.forEach((elem) => {
      const tempPrice = elem.priceDiff * elem.quantity;
      const vatPrice = (tempPrice * (elem.vat || 0)) / this.percent;
      this.totalVat += vatPrice;
      this.totalPrice += tempPrice + vatPrice;
    });
  }

  getConnectedLab() {
    return this.orderDetail.clinic.connectedLabs.find((lab) => {
      if (lab.lab) {
        return lab.lab.toString() === this.orderDetail.lab._id.toString();
      }
      return false;
    });
  }

  getConstructionMaterials() {
    return this.orderDetail.newConstructions.constructions.reduce(
      (result, construction) => {
        if (construction.material) {
          result.push({
            material: construction.material
          });
        }
        return result;
      },
      []
    );
  }

  setActionMaterialPrice(priceActions: PriceSpecification[]) {
    this.actionsAndMaterials.forEach((action) => {
      let tempPrice = 0;
      let vatPrice = 0;
      if (action.quantity) {
        // for materials
        tempPrice = action.price * action.quantity;
        vatPrice = (tempPrice * (action.vat || 0)) / this.percent;
        this.totalVat += vatPrice;
        this.totalPrice += tempPrice + vatPrice;
      } else {
        // for actions
        const actionPrices = priceActions.filter((priceAction) => {
          return action.actionId === priceAction.action;
        });
        const { totalVat, totalPrice } = this.priceSpecService.addToPriceAndVat(
          action,
          actionPrices,
          tempPrice,
          vatPrice
        );
        this.totalVat = totalVat;
        this.totalPrice = totalPrice;
      }
    });
  }

  setTotalPrice() {
    this.totalPrice = this.priceDiff.reduce((total, mat) => {
      let matTempPrice = 0;
      let matVatPrice = 0;
      matTempPrice = total + (mat.priceDiff || 0) * mat.quantity;
      matVatPrice =
        ((mat.priceDiff || 0) * mat.quantity * (mat.vat || 0)) / this.percent;
      this.totalVat += matVatPrice;
      return matTempPrice + matVatPrice;
    }, this.totalPrice);
  }

  calculatePriceSpec() {
    //get actions from constructions
    const constructions = this.orderDetail.newConstructions.constructions;
    this.actionsAndMaterials = this.priceSpecService.getActionsAndMaterials(
      constructions
    );

    //get lab from clinic connected labs.
    const lab = this.getConnectedLab();

    let materials = this.getConstructionMaterials();

    this.priceDiff = lab.materialPriceDifference.reduce((res, matPriceDiff) => {
      return res.concat(matPriceDiff.materials);
    }, []);

    const matGroups = this.orderDetail.clinic.materialGroups.reduce((res, mg) => {
      return res.concat(mg.materials);
    }, []);

    materials = this.getMaterialWithName(materials, matGroups);
    this.priceDiff = this.getMaterialWithName(this.priceDiff, matGroups).filter(
      (price) => {
        return materials.some((mat) => {
          return mat.materialName === price.materialName;
        });
      }
    );

    this.priceDiff = this.getMaterialPriceDiff(
      this.orderDetail.newConstructions.constructions,
      this.priceDiff
    );

    //get all actions from lab pricelist array.
    const priceActions = lab.priceList.reduce((result, priceList) => {
      return result.concat(priceList.actions);
    }, []);

    //get price from matched lab priceList actions price.
    this.totalPrice = 0;
    this.totalVat = 0;
    this.setActionMaterialPrice(priceActions);
    this.setTotalPrice();
  }
}
