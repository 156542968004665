import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { clone, difference, isEmpty, isEqual, sortBy } from 'lodash';
import {
  BOTH_JAW_TEETH,
  DEFAULT_COLOR_CODE,
  LOWER_JAW_TEETH,
  ORDER_2_CONSTRUCTION,
  UPPER_JAW_TEETH
} from '../../../../../../app.constant';
import { Action } from '../../../../../../models/action.model';
import { Category, SubCategory } from '../../../../../../models/organization.model';
import { ConstructionPageService } from '../../../../services/construction-page-service';
import { ConstructionService } from '../../../../services/construction-service';
import template from './teeth-structure.html';
import style from './teeth-structure.less';

@Component({
  selector: 'teeth-structure',
  template,
  styles: [style]
})
export class TeethStructureComponent implements OnChanges {
  private readonly backgroundColorCode = DEFAULT_COLOR_CODE.backgroundColorCode;

  @Input() selectedActions: Action[] = [];
  @Input() isConsBlockOpen: any;
  @Input() clickedJawType: string[] = [];
  @Output() teethStructureSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() showHideConstructionSettings: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  @Input() allConstructions: any = [];
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedMainCategory: Category;
  abutmentTeethFirstRange = UPPER_JAW_TEETH;
  abutmentTeethSecondRange = LOWER_JAW_TEETH;
  abutmentTeethBothRange = BOTH_JAW_TEETH;
  selectedTeethRange: { end?: number; start?: number } = {};
  selectedTeeth: string[] = [];

  constructor(
    private readonly constructionService: ConstructionService,
    private readonly constructionPageService: ConstructionPageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !isEqual(
        changes.selectedActions?.currentValue,
        changes.selectedActions?.previousValue
      )
    ) {
      this.selectedTeethRange = {};
      this.selectedTeeth = [];
    }
    this.selectedTeeth = this.constructionPageService.selectedTeeth;
    this.selectedTeethRange = this.constructionPageService.selectedTeethRange;
  }

  toothClick(tooth, isUpper) {
    try {
      this.setSelectedTeeth(tooth, isUpper);
    } catch (error) {
      console.error(error, 'error');
    }
  }

  setSelectedTeeth(tooth, isUpper) {
    const constructionType = this.constructionPageService.getToothOption(
      this.selectedActions
    );
    let startIndex, endIndex;
    const teethRow = this.getTeethRow(isUpper);
    switch (constructionType) {
      case ORDER_2_CONSTRUCTION.TYPE.SINGLE_TOOTH:
        if (!this.selectedTeeth?.length) {
          this.selectedTeeth = [];
        }
        if (this.selectedTeeth.indexOf(tooth) === -1) {
          this.selectedTeeth.push(tooth);
          this.addNewDummyConstruction(constructionType);
          this.addToConstructions(this.constructionService.selectedConstructions);
          this.teethStructureSelected.emit({
            constructionType
          });
          this.showHideConstructionSettings.emit(true);
        }
        this.constructionPageService.selectedTeeth = this.selectedTeeth;
        this.constructionPageService.selectedTeethRange = {};
        break;
      case ORDER_2_CONSTRUCTION.TYPE.RANGE:
        if (!this.selectedTeethRange) {
          this.selectedTeethRange = {};
        }
        if (this.selectedTeethRange.start && this.selectedTeethRange.end) {
          delete this.selectedTeethRange.end;
          this.showHideConstructionSettings.emit(false);
          this.selectedTeethRange.start = tooth;
        } else {
          if (!this.selectedTeethRange.start) {
            this.selectedTeethRange.start = tooth;
          } else {
            if (this.selectedTeethRange.start === tooth) {
              this.showHideConstructionSettings.emit(false);
              delete this.selectedTeethRange.start;
            }
            startIndex = teethRow.indexOf(this.selectedTeethRange.start);
            if (startIndex > -1) {
              this.selectedTeethRange.end = tooth;
              this.addNewDummyConstruction(constructionType);
              this.addToConstructions(
                this.constructionService.selectedConstructions
              );
              this.teethStructureSelected.emit({
                constructionType
              });
              this.showHideConstructionSettings.emit(true);
            }
            endIndex = teethRow.indexOf(this.selectedTeethRange.end);
            if (startIndex > endIndex) {
              const temp = this.selectedTeethRange.end;
              this.selectedTeethRange.end = this.selectedTeethRange.start;
              this.selectedTeethRange.start = temp;
            }
          }
        }
        this.constructionPageService.selectedTeethRange = this.selectedTeethRange;
        this.constructionPageService.selectedTeeth = [];
        break;
      case ORDER_2_CONSTRUCTION.TYPE.UP_LOW_JAW:
        isUpper = isUpper ? 'upper' : 'lower';
        if (this.clickedJawType.indexOf(isUpper) === -1) {
          this.clickedJawType.push(isUpper);
          if (!this.selectedTeethRange) {
            this.selectedTeethRange = {};
          }
          if (isUpper === 'upper') {
            this.selectedTeethRange.start = 18;
            this.selectedTeethRange.end =
              this.clickedJawType.indexOf('lower') !== -1 ? 38 : 28;
          }
          if (isUpper === 'lower') {
            this.selectedTeethRange.start =
              this.clickedJawType.indexOf('upper') !== -1 ? 18 : 48;
            this.selectedTeethRange.end = 38;
          }
        }
        this.addNewDummyConstruction(constructionType);
        this.addToConstructions(this.constructionService.selectedConstructions);
        this.teethStructureSelected.emit({
          constructionType
        });
        this.showHideConstructionSettings.emit(true);
        this.constructionPageService.selectedTeethRange = this.selectedTeethRange;
        this.constructionPageService.selectedTeeth = [];
        break;
    }
  }

  addDummyConstruction(constructionType, mainCategoryName, subCategoryName) {
    const dummyConstructions = {
      constructionType: constructionType,
      colorCode: this.selectedSubCategory.colorCode
    };
    if (constructionType === ORDER_2_CONSTRUCTION.TYPE.SINGLE_TOOTH) {
      dummyConstructions['teethNo'] = clone(this.selectedTeeth);
    } else {
      dummyConstructions['teethRange'] = this.selectedTeethRange;
    }
    this.constructionService.addSelectedConstructions(
      mainCategoryName,
      subCategoryName,
      dummyConstructions
    );
  }

  pushToConstruction(constructionCategoryObj) {
    Object.keys(constructionCategoryObj).forEach((key) => {
      this.allConstructions.push(constructionCategoryObj[key]);
    });
    this.allConstructions = sortBy(
      this.allConstructions.reduce((result, element) => result.concat(element), []),
      'createdAt'
    );
  }

  addToConstructions(selectedConstructions) {
    Object.keys(selectedConstructions)
      .filter((key) => key !== 'type')
      .forEach((key) => {
        this.pushToConstruction(selectedConstructions[key]);
      });
  }

  checkIConstructionIsCreated() {
    return this.allConstructions.some((construction) => {
      if (construction.subCategory?._id) {
        return construction.subCategory._id === this.selectedSubCategory._id;
      }
      if (!construction.createdAt) {
        return true;
      }
      return construction.subCategory === this.selectedSubCategory._id;
    });
  }

  showCrossButton(tooth) {
    if (!this.allConstructions || !this.selectedSubCategory) {
      return false;
    }
    const isConstructionCreated = this.checkIConstructionIsCreated();
    if (this.selectedTeeth?.length) {
      return isConstructionCreated && this.selectedTeeth.indexOf(tooth) > -1;
    }
    if (
      this.checkIfUpperJawSelected(this.selectedTeethRange) ||
      this.checkIfLowerJawSelected(this.selectedTeethRange)
    ) {
      return isConstructionCreated && this.selectedTeethRange.end === tooth;
    }
    if (this.checkIfBothJawSelected(this.selectedTeethRange)) {
      return (
        (isConstructionCreated && this.selectedTeethRange.end === tooth) ||
        tooth === 28
      );
    }
  }

  removeTooth(event, teeth, isUpper) {
    event.stopPropagation();
    const subCategoryName = this.selectedSubCategory.name;
    const mainCategoryName = this.selectedMainCategory.name;
    const constructionType = this.constructionPageService.getToothOption(
      this.selectedActions
    );
    if (this.selectedTeeth?.length) {
      const toothIndex = this.selectedTeeth.indexOf(teeth);
      this.selectedTeeth.splice(toothIndex, 1);
      this.constructionPageService.selectedTeeth = this.selectedTeeth;
      this.constructionService.deleteDummyConstructions();
      if (!this.isConsBlockOpen) {
        this.addDummyConstruction(
          constructionType,
          mainCategoryName,
          subCategoryName
        );
      }
    }
    if (!isEmpty(this.selectedTeethRange)) {
      let teethRow = this.getTeethRow(isUpper);
      if (this.checkIfBothJawSelected(this.selectedTeethRange)) {
        teethRow = this.abutmentTeethBothRange;
      }
      const rangeToothIndex = teethRow.indexOf(teeth);
      const startIndex = teethRow.indexOf(this.selectedTeethRange.start),
        endIndex = teethRow.indexOf(this.selectedTeethRange.end);
      if (rangeToothIndex >= startIndex && rangeToothIndex <= endIndex) {
        this.clickedJawType.splice(
          this.clickedJawType.indexOf(isUpper ? 'upper' : 'lower'),
          1
        );
        if (this.checkIfBothJawSelected(this.selectedTeethRange)) {
          if (isUpper) {
            this.selectedTeethRange = { start: 48, end: 38 };
          } else {
            this.selectedTeethRange = { start: 18, end: 28 };
          }
        } else {
          this.selectedTeethRange = {};
        }
      }
      this.constructionPageService.selectedTeethRange = this.selectedTeethRange;
      this.constructionService.deleteDummyConstructions();
      if (!this.isConsBlockOpen) {
        this.addDummyConstruction(
          constructionType,
          mainCategoryName,
          subCategoryName
        );
      }
    }
  }

  addNewDummyConstruction(constructionType) {
    const subCategoryName = this.selectedSubCategory.name;
    const mainCategoryName = this.selectedMainCategory.name;
    /**
     * we are creating dummy construction so that we can apply color class on teeth when they are clicked
     * instead of when construction is saved
     * These dummy constructions will be deleted afterwards
     * @type {{constructionType: *, colorCode: (*)}}
     */
    this.addDummyConstruction(constructionType, mainCategoryName, subCategoryName);
  }

  getTeethRow(isUpper) {
    return isUpper ? this.abutmentTeethFirstRange : this.abutmentTeethSecondRange;
  }

  checkIfBothJawSelected(range) {
    return range?.start === 18 && range?.end === 38;
  }

  checkIfUpperJawSelected(range) {
    return range?.start === 18 && range?.end === 28;
  }

  checkIfLowerJawSelected(range) {
    return range?.start === 48 && range?.end === 38;
  }

  getConstructionBackgroundStyle(construction) {
    if (construction.colorCode) {
      return { background: construction.colorCode };
    }
    if (construction.subCategory?.colorCode) {
      return { background: construction.subCategory.colorCode };
    }
    return { background: this.backgroundColorCode };
  }

  getColorOnTeeth(tooth, isUpper) {
    if (!this.allConstructions?.length) {
      return {};
    }
    let teethRow = this.getTeethRow(isUpper);
    const selectedTeethRange = this.constructionPageService.selectedTeethRange;
    if (this.checkIfBothJawSelected(selectedTeethRange)) {
      teethRow = this.abutmentTeethBothRange;
    }
    const toothIndex = teethRow.indexOf(tooth);
    let colorStyle = { background: this.backgroundColorCode };
    this.allConstructions.forEach((construction) => {
      const startIndex = teethRow.indexOf(construction.teethRange?.start),
        endIndex = teethRow.indexOf(construction.teethRange?.end);
      if (this.selectedTeeth?.length && construction.teethNo?.length) {
        const differenceOfTeeth = difference(
          this.selectedTeeth,
          construction.teethNo
        );
        if (
          differenceOfTeeth.length === 0 &&
          this.selectedTeeth.indexOf(tooth) !== -1
        ) {
          colorStyle = this.getConstructionBackgroundStyle(construction);
        }
      }
      if (
        this.checkForTeeth(
          selectedTeethRange,
          construction.teethRange,
          toothIndex,
          startIndex,
          endIndex
        )
      ) {
        colorStyle = this.getConstructionBackgroundStyle(construction);
      }
      if (this.checkIfBothJawSelected(construction.teethRange)) {
        const startTeethIndex = teethRow.indexOf(selectedTeethRange.start),
          endTeethIndex = teethRow.indexOf(selectedTeethRange.end);
        if (toothIndex >= startTeethIndex && toothIndex <= endTeethIndex) {
          colorStyle = this.getConstructionBackgroundStyle(construction);
        }
      }
    });
    return colorStyle;
  }

  checkForTeeth(selectedTeethRange, teethRange, toothIndex, startIndex, endIndex) {
    return (
      teethRange &&
      selectedTeethRange?.start === teethRange?.start &&
      selectedTeethRange?.end === teethRange?.end &&
      toothIndex >= startIndex &&
      toothIndex <= endIndex
    );
  }

  getClassOnTeethConstructions(toothIndex, teethRow) {
    if (!this.allConstructions?.length) {
      return {};
    }
    const classObj = {};
    const selectedTeethRange = this.constructionPageService.selectedTeethRange;
    this.allConstructions.forEach((construction) => {
      const startIndex = teethRow.indexOf(construction.teethRange?.start),
        endIndex = teethRow.indexOf(construction.teethRange?.end);
      if (
        this.checkForTeeth(
          selectedTeethRange,
          construction.teethRange,
          toothIndex,
          startIndex,
          endIndex
        )
      ) {
        if (toothIndex === startIndex) {
          classObj['bridge-selected-start'] = true;
        } else if (toothIndex === endIndex) {
          classObj['bridge-selected-end'] = true;
        } else if (toothIndex > startIndex && toothIndex < endIndex) {
          classObj['bridge-selected'] = true;
        } else {
          // do nothing
        }
      }
    });
    return classObj;
  }

  getClassForRange(startIndex, toothIndex, endIndex) {
    const selectedTeethRange = this.constructionPageService.selectedTeethRange;
    const BRIDGE_START = 'bridge-start';
    const BRIDGE_END = 'bridge-end';
    const BRIDGE_MIDDLE = 'bridge-middle';
    if (startIndex === toothIndex) {
      return selectedTeethRange.end
        ? { [BRIDGE_START]: true }
        : { 'bridge-start-temp': true };
    }
    if (endIndex === toothIndex) {
      return { [BRIDGE_END]: true };
    }
    if (toothIndex > startIndex && toothIndex < endIndex) {
      return { [BRIDGE_MIDDLE]: true };
    }
    return {};
  }

  getClassForJaw(startIndex, toothIndex, endIndex, tooth) {
    const classObj = {};
    const BRIDGE_START = 'bridge-start';
    const BRIDGE_END = 'bridge-end';
    const BRIDGE_MIDDLE = 'bridge-middle';
    if (startIndex === toothIndex) {
      classObj[BRIDGE_START] = true;
    } else if (endIndex === toothIndex) {
      classObj[BRIDGE_END] = true;
    } else {
      // do nothing
    }
    if (toothIndex > startIndex && toothIndex < endIndex) {
      classObj[BRIDGE_MIDDLE] = true;
    }
    const selectedTeethRange = this.constructionPageService.selectedTeethRange;
    if (this.checkIfBothJawSelected(selectedTeethRange)) {
      if (tooth === 28) {
        classObj[BRIDGE_END] = true;
      }
      if (tooth === 48) {
        classObj[BRIDGE_START] = true;
      }
    }
    return classObj;
  }

  getClass(teethRow, tooth) {
    const constructionType = this.constructionPageService.getToothOption(
      this.selectedActions
    );
    const selectedTeethRange = this.constructionPageService.selectedTeethRange;
    const startIndex = teethRow.indexOf(selectedTeethRange?.start);
    const endIndex = teethRow.indexOf(selectedTeethRange?.end);
    const toothIndex = teethRow.indexOf(tooth);
    switch (constructionType) {
      case ORDER_2_CONSTRUCTION.TYPE.SINGLE_TOOTH:
        if (this.selectedTeeth?.indexOf(tooth) > -1) {
          return { 'square-selected': true };
        }
        return {};
      case ORDER_2_CONSTRUCTION.TYPE.RANGE:
        return this.getClassForRange(startIndex, toothIndex, endIndex);
      case ORDER_2_CONSTRUCTION.TYPE.UP_LOW_JAW:
        return this.getClassForJaw(startIndex, toothIndex, endIndex, tooth);
      default:
        return {};
    }
  }

  getConstructionClass(tooth, isUpper) {
    let teethRow = this.getTeethRow(isUpper);
    const selectedTeethRange = this.constructionPageService.selectedTeethRange;
    if (this.checkIfBothJawSelected(selectedTeethRange)) {
      teethRow = this.abutmentTeethBothRange;
    }
    const toothIndex = teethRow.indexOf(tooth);

    return Object.assign(
      {},
      this.getClass(teethRow, tooth),
      this.getClassOnTeethConstructions(toothIndex, teethRow)
    );
  }
}
