import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import template from './add-clinic-modal.html';
import { Organization } from '../../models/organization.model';
import { User } from '../../models/user.model';
import { CustomerService } from '../../services/customer/customer.service';
import { PermissionService } from '../../services/core/permission.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';

@Component({
  selector: 'add-clinic-modal',
  template: template
})
export class AddClinicModalComponent implements OnInit, OnChanges {
  @Output('onClinicChange') onClinicChange: EventEmitter<
    void
  > = new EventEmitter<void>();
  @Input() currentUser: User;
  @Input() clinic: Partial<Organization>;
  isValidOrgNr: boolean = true;
  disableAddClinicButton: boolean = true;
  validEmail: boolean = false;
  validInvoiceEmail: boolean = false;
  isPostCodeValid: boolean = false;
  isInvoicePostCodeValid: boolean = false;
  clinics: Organization[] = [];

  constructor(
    private customerService: CustomerService,
    private organizationService: OrganizationService,
    private logService: LogService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private permissionService: PermissionService
  ) {}

  validatePostCode(postalCode: number): void {
    this.clinic.contact.address.postal_code = postalCode;
    this.isPostCodeValid = new RegExp('^\\d{5,5}$').test(`${postalCode}`);
  }

  validateInvoicePostalCode(postalCode: string): void {
    this.clinic.invoice.postal_code = postalCode;
    this.isInvoicePostCodeValid = new RegExp('^\\d{5,5}$').test(
      `${postalCode}`
    );
  }

  checkOrgNrValidation(organizationNumber: string): void {
    if (new RegExp('^\\d{6,6}\\d{4,4}$').test(organizationNumber)) {
      this.clinic.organizationNumber =
        organizationNumber.substring(0, 6) +
        '-' +
        organizationNumber.substring(6, 10);
      this.isValidOrgNr = true;
      return;
    }
    this.clinic.organizationNumber = organizationNumber;
    this.isValidOrgNr = new RegExp('^\\d{6,6}-\\d{4,4}$').test(
      organizationNumber
    );
  }

  updateClinic(clinicId: string): Promise<void> {
    this.disableAddClinicButton = false;
    this.clinic.type = 'clinic';
    this.clinic.active = true;
    return this.organizationService
      .updateOrganization(clinicId, this.clinic)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
        this.closeModal();
        this.validEmail = false;
        this.validInvoiceEmail = false;
        this.disableAddClinicButton = true;
        this.onClinicChange.emit();
      })
      .catch(async (err: any) => {
        const text = await this.translationsService.get(
          'ERROR_IN_UPDATE_CLINIC'
        );
        this.logService.error(
          'clinics-add-modal.component',
          'updateClinic',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  ngOnChanges(): void {
    if (!this.clinic) {
      this.clinic = {};
    }
    if (!this.clinic.invoice) {
      this.clinic.invoice = {};
    }
    if (!this.clinic.contactInfo) {
      this.clinic.contactInfo = {};
    }
    if (!this.clinic.externalInfo) {
      this.clinic.externalInfo = {};
    }
    if (!this.clinic.contact) {
      this.clinic.contact = {
        address: {}
      };
    }
    this.validatePostCode(this.clinic.contact.address.postal_code);
    this.validateInvoicePostalCode(this.clinic.invoice.postal_code);
    this.checkOrgNrValidation(this.clinic.organizationNumber);
    console.log(this.clinic, 'this.clinic', this.clinic.invoice.email);
    this.checkEmail(this.clinic.contactInfo.email);
    this.checkInvoiceEmail(this.clinic.invoice.email);
  }

  closeModal(): void {
    this.clinic = {
      invoice: {},
      contactInfo: {},
      externalInfo: {},
      contact: {
        address: {}
      }
    };
    $('#add-clinic-modal').modal('hide');
  }

  addClinic(): Promise<void> {
    this.disableAddClinicButton = false;
    this.clinic.type = 'clinic';
    if (!this.permissionService.isSuperAdmin(this.currentUser)) {
      this.clinic.parentClinicId = this.currentUser.organization;
    }
    return this.organizationService
      .saveOrganization(this.clinic as Organization)
      .then(async () => {
        this.clinic = {};
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_ADDED')
        );
        this.closeModal();
        this.validEmail = false;
        this.validInvoiceEmail = false;
        this.disableAddClinicButton = true;
        this.onClinicChange.emit();
      })
      .catch(async (err: any) => {
        const text = await this.translationsService.get('ERROR_IN_ADD_CLINIC');
        this.logService.error(
          'clinics-add-modal.component',
          'addClinic',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  async ngOnInit(): Promise<void> {
    if (this.permissionService.isSuperAdmin(this.currentUser)) {
      const clinics = await this.getClinics();
      this.clinics = clinics.filter((clinic) => !clinic.parentClinicId);
    }
  }

  getClinics(): Promise<Organization[]> {
    return this.organizationService
      .getAllOrganizations({ type: 'clinic' })
      .then((clinics) => {
        return clinics.data;
      })
      .catch(async (err: any) => {
        const text = await this.translationsService.get(
          'ERROR_IN_GETTING_CLINICS'
        );
        this.logService.error(
          'clinics-add-modal.component',
          'getClinics',
          text + ': ' + err
        );
        this.toastr.error(text);
        return [];
      });
  }

  checkEmail(email: string): void {
    this.clinic.contactInfo.email = email;
    this.validEmail = this.customerService.validateEmail(email);
  }

  checkInvoiceEmail(email: string): void {
    this.clinic.invoice.email = email;
    this.validInvoiceEmail = this.customerService.validateEmail(email);
  }

  isSuperAdmin(user: User): boolean {
    return this.permissionService.isSuperAdmin(user);
  }
}
