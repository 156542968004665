/**
 * Search Text  service service
 */
(function () {
    'use strict';
    angular.module('app').service('searchLabTextService', searchLabTextService);
    searchLabTextService.$inject = [ '$q' ];
    function searchLabTextService($q) {
        var searchText = '';
        return {
            setSearchLabText: setSearchLabText,
            getSearchLabText: getSearchLabText
        };

        // Set the search text to a variable
        function setSearchLabText(text) {
            searchText = text;
        }

        // Get the variable value and then return
        function getSearchLabText() {
            var deferred = $q.defer();
            deferred.resolve(searchText);
            return deferred.promise;
        }
    }
})();