import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[percentage]'
})
export class PercentageDirective {
  private readonly regex = /^\d{0,2}$/;
  // Allow key codes for special events
  // Backspace, tab, end, home
  private readonly specialKeys: string[] = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete'
  ];

  constructor(private readonly el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) > -1) {
      return;
    }

    const currentValue: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;

    const next: string = [
      currentValue.slice(0, position),
      event.key,
      currentValue.slice(position)
    ].join('');

    if (next === '00' || next.length > 2) {
      event.preventDefault();
    }
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
