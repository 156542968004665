import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

const validPostalCode = new RegExp(/^\d{3,3} \d{2,2}$/);

export const isValidSePostalCode: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (control.value && !validPostalCode.test(control.value)) {
    return {
      isValidFormat: false
    };
  }
  return null;
};
