import { Component, Input } from '@angular/core';
import template from './text-field.html';
import { CustomerSetting } from '../../../models/customer-setting.model';

@Component({
  selector: 'text-field',
  template: template
})
export class TextFieldComponent {
  @Input() setting: CustomerSetting;
  @Input() subtype: string = 'text';
}
