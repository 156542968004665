/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './other-removable.html'
/**
 * order removable OtherRemovable construction component
 */
(function () {
    'use strict';
    angular.module('app').component('otherRemovable', {
        template: theTemplate,
        controller: otherRemovableController,
        controllerAs: 'vm',
        bindings: {
            constructionSettings: '=',
            selectedTeeth: '=',
            orderIdToModify: '=',
            addUpdateOtherRemovable: '=',
            removeOtherRemovable: '=',
            selectedDentureType: '=',
            otherRemovableData: '<',
            selectedConstructionObj: '=', //currently selected constructions objects
            tempOtherRemovableData: "=",
            otherRemovableCommentsArray: "="
        }
    });

    /**
     * Inject the dependencies here
     * @type {string[]}
     */
    otherRemovableController.$inject = [ '$scope', 'userService', 'tokenService','CONSTRUCTIONS', 'DATE' ];

    /**
     * Controller function for the OtherRemovable construction
     */
    function otherRemovableController($scope, userService, tokenService,CONSTRUCTIONS, DATE) {

        var vm = this;
        vm.constructionName = {
            otherRemovable: CONSTRUCTIONS.OTHER_REMOVABLE
        };
        if (!vm.otherRemovableData) {
            //if null initialize. though this is not expected
            vm.otherRemovableData = {};
        }
        vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;

        $scope.$watch('vm.selectedTeeth',function() {
            //if we previously created any single crown then use that else create empty
            if (vm.selectedDentureType === vm.constructionName.otherRemovable) {
                if (vm.selectedConstructionObj && vm.selectedConstructionObj.otherRemovables && vm.selectedConstructionObj.otherRemovables.length > 0) {
                    var otherRemovable = vm.selectedConstructionObj.otherRemovables[vm.selectedConstructionObj.otherRemovables.length - 1];
                    //get old construction

                    for (var sc in vm.selectedConstructionObj.otherRemovables) {
                        if (vm.selectedTeeth === vm.selectedConstructionObj.otherRemovables[sc].teethNo) {
                            vm.otherRemovableData = {
                                allComments: vm.selectedConstructionObj.otherRemovables[sc].allComments
                            };
                        }
                    }

                    if (!vm.otherRemovableData) {
                        vm.otherRemovableData = {
                            allComments: otherRemovable.allComments
                        };
                    }

                } else {
                    userService.getCurrentUser().then(function (result) {
                        var userProfile = result.data;
                        if (userProfile.constructionSetting) {
                            //get default construction
                            if (vm.tempOtherRemovableData) {
                                vm.otherRemovableData = {
                                    allComments: vm.tempOtherRemovableData.allComments
                                };
                            }
                        }
                    });
                }
            }

        });

        /**
         * Save teeth construction
         */
        $scope.$watch('vm.otherRemovableData',function(newVal, oldval) {
            if (vm.selectedDentureType === vm.constructionName.otherRemovable) {
                //it is valid construction. lets save it
                vm.otherRemovableData.teethNo = vm.selectedTeeth;
                vm.otherRemovableData.type = 'other removable';
                vm.addUpdateOtherRemovable(vm.otherRemovableData,vm.selectedDentureType);
            }

        },true);
    }

})();