import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.constant';
import { Injectable } from '@angular/core';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { GetInvoiceParams } from '../../models/query.model';
import { Invoice } from '../../models/invoice.model';

@Injectable()
export class InvoiceService extends ResourceService {
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
  }

  getAllInvoicesForSuperAdmin(
    invoiceObj: GetInvoiceParams
  ): Promise<{ data: Invoice[] }> {
    const params = new HttpParams()
      .set('toDate', invoiceObj.toDate)
      .set('fromDate', invoiceObj.fromDate);

    return this.get(
      API.API_V2_BASE + 'invoices-for-super-admin',
      params,
      this.apiInterceptor
    );
  }

  invoicePdf(id: string): Promise<{ data: string }> {
    const params = new HttpParams().set('invoiceId', id);
    return this.get(
      API.API_V2_BASE + 'invoices/:invoiceId/pdf',
      params,
      this.apiInterceptor
    );
  }

  updateInvoiceStatus(invoice: { id: string; status: string }): Promise<void> {
    const params = new HttpParams().set('id', invoice.id);

    return this.put(
      API.API_V2_BASE + 'invoices/:id',
      invoice,
      params,
      this.apiInterceptor
    );
  }
}
