/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './layout.html'
/**
 * Layout component
 */
(function () {
  'use strict';
  angular.module('app').component('layout', {
    template: theTemplate,
    controller: layoutController,
    controllerAs: 'vm',
    bindings: sessionStateBindings
  });
  layoutController.$inject = [
    '$scope',
    'subNavService',
    'currentUserService',
    'eventService',
    'logService',
    'permissionService',
    '$state',
    'newOrderListRedirectService'
  ];
  /**
   * layout controller function
   */
  function layoutController(
    $scope,
    subNavService,
    currentUserService,
    eventService,
    logService,
    permissionService,
    $state,
    newOrderListRedirectService
  ) {
    var vm = this;
    vm.currentUserPermissions = {};
    vm.textColorCode = {};
    vm.isMenuOpen = true;
    vm.menuOpened = menuOpened;
    vm.inIframe = window !== window.parent;
    vm.isWhitelabel = false;

    vm.$onInit = function () {
      // this broadcast we get when user login as another user.
      eventService.on('user', () => {
        populateCurrentUser();
      });
      vm.currentUserPermissions = currentUserService.getPermissions();
      if (
        vm.currentUser.organization &&
        vm.currentUser.organization.isWhiteLabeled
      ) {
        console.log('WL TRUE');
        vm.isWhitelabel = true;
      }
    };

    $scope.$watch(function (newVal, oldVal) {
      vm.isOrderDetails = $state.$current.name === 'app.order-details-2';
    });

    function menuOpened(isMenuOpen) {
      vm.isMenuOpen = isMenuOpen;
    }

    function goToState() {
      if (!permissionService.isSuperAdmin(vm.currentUser)) {
        newOrderListRedirectService.newOrderList();
      } else {
        $state.go('app.users');
      }
    }

    /**
     * If user is authenticated then get the user payload.
     * this function change user to selected user.
     */
    function populateCurrentUser() {
      currentUserService
        .getUserData()
        .then((result) => {
          if (result) {
            vm.currentUser = result;
            goToState();
          } else {
            currentUserService.logout();
          }
        })
        .catch((err) => {
          currentUserService.logout();
          logService.error('layout.component', 'populateCurrentUser', err);
        });
    }
  }
})();
