import { Injectable } from '@angular/core';
import { Order } from '../../models/order.model';
import { User } from '../../models/user.model';

@Injectable()
export class StatusService {
  private statusObj = {};

  isOrderModified(order: Order, user: User): boolean {
    return order.isModify && user.viewSettings.orderModified;
  }

  getStatus(order: Order, user: User) {
    if (
      this.isOrderModified(order, user) &&
      order.invoicingState === 'READY_FOR_INVOICE'
    ) {
      this.statusObj = {
        status: 'READY_FOR_ORDER_INVOICE',
        isRedClass: false
      };
      return this.statusObj;
    }
    if (
      this.isOrderModified(order, user) &&
      order.invoice &&
      order.invoice.status === 'PROCESSING'
    ) {
      this.statusObj = {
        status: 'INVOICE_SENT',
        isRedClass: false
      };
      return this.statusObj;
    }
    if (
      this.isOrderModified(order, user) &&
      order.invoice &&
      order.invoice.status === 'SENT'
    ) {
      this.statusObj = {
        status: 'INVOICING_COMPLETED',
        isRedClass: false
      };
      return this.statusObj;
    }
    if (
      !this.isOrderModified(order, user) &&
      order.invoicingState === 'READY_FOR_INVOICE'
    ) {
      this.statusObj = {
        status: 'READY_FOR_ORDER_INVOICE',
        isRedClass: false
      };
      return this.statusObj;
    }
    if (
      !this.isOrderModified(order, user) &&
      order.invoice &&
      order.invoice.status === 'PROCESSING'
    ) {
      this.statusObj = {
        status: 'INVOICE_SENT',
        isRedClass: false
      };
      return this.statusObj;
    }
    if (
      !this.isOrderModified(order, user) &&
      order.invoice &&
      order.invoice.status === 'SENT'
    ) {
      this.statusObj = {
        status: 'INVOICING_COMPLETED',
        isRedClass: false
      };
      return this.statusObj;
    }
    if (this.isOrderModified(order, user)) {
      this.statusObj = {
        status: 'ORDER_MODIFIED',
        isRedClass: true
      };
      return this.statusObj;
    }

    return undefined;
  }
}
