import { Component, Input } from '@angular/core';
import { SubCategory } from '../../../../../../../../models/organization.model';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './retainment.html';

@Component({
  selector: 'retainment',
  template
})
export class RetainmentComponent {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() retainment: string;

  private readonly retainmentKey = 'retainment';

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  getRadioColorClass(type) {
    const radios: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      '.check-radio-color'
    );
    const noOfRadios = radios.length;
    /** different browsers return NodeList differently. so, forEach might not work in some browsers( IE in our case ).
     * below links recommend traditional for loop to iterate over DOM nodes.
     * @link https://css-tricks.com/snippets/javascript/loop-queryselectorall-matches/
     * @link https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/
     * */
    for (let i = 0; i < noOfRadios; i++) {
      radios[i].style.setProperty(
        '--radio-color',
        type && type.colorCode ? type.colorCode : '#000000',
        'important'
      );
    }
  }

  setRetainment(value: string) {
    this.retainment = value;
    this.constructionSettingsService.setSetting(this.retainmentKey, value);
  }
}
