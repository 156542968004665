import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[personal-id]'
})
export class PersonalIdDirective {
  @Input() isPinValid: boolean;
  @HostListener('keypress', ['$event'])
  keyPress(_event: KeyboardEvent): void {
    if (this.isPinValid) {
      _event.preventDefault();
    }
  }
}
