import template from './add-action-modal.html';
import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActionService } from '../../../services/manage-actions/action.service';
import { LogService } from '../../../services/core/log.service';
import { isEmpty, cloneDeep } from 'lodash';
import { TranslationsService } from '../../../services/translations/translations.service';
import { Category, SubCategory } from '../../../models/organization.model';
import { MaterialGroups } from '../../../models/material-group.model';
import { Action } from '../../../models/action.model';

@Component({
  selector: 'add-action-modal',
  template: template
})
export class AddActionModalComponent implements OnChanges, AfterViewInit {
  @Input() savedActions: Action[] = [];
  @Input() action: any;
  @Input() categories: Category[] = [];
  @Input() materials: MaterialGroups[];

  @Output() getAllActions: EventEmitter<void> = new EventEmitter<void>();

  modal: JQuery<HTMLElement>;

  decimalOptions = [1, 2, 3];

  selectedSubCategories: SubCategory[] = [];
  subCategoriesForAction: SubCategory[] = [];
  mainCatId: string;
  isSubCategoriesDropdownVisible = false;

  constructor(
    private readonly toastr: ToastrService,
    private readonly actionService: ActionService,
    private readonly logService: LogService,
    private readonly translationsService: TranslationsService
  ) {}

  ngAfterViewInit(): void {
    this.modal = $('#add-action-modal');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.action?.currentValue?._id) {
      this.setEmptyFields();
    } else {
      this.categories.forEach((category) => {
        if (
          this.action.mainCategory &&
          category &&
          this.action.mainCategory._id === category._id
        ) {
          this.action.mainCategory = category._id;
          this.mainCatId = category._id;
          category.subCategories.forEach((subCategory) => {
            subCategory.selected = false;
          });
          this.subCategoriesForAction = cloneDeep(category.subCategories);
        }
      });

      const settings = this.action.settings;

      if (
        settings &&
        settings.numberOfUnits &&
        settings.numberOfUnits.numberOfDecimals
      ) {
        // eslint-disable-next-line max-len
        settings.numberOfUnits.numberOfDecimals = settings.numberOfUnits.numberOfDecimals.toString();
      }
      if (
        this.action.subCategory &&
        this.action.subCategory.length > 0 &&
        this.mainCatId === this.action.mainCategory
      ) {
        this.selectedSubCategories = this.subCategoriesForAction.filter(
          (subCategory) =>
            this.action.subCategory.some((subCat) => subCat._id === subCategory._id)
        );
        this.selectedSubCategories.forEach((subCategory) => {
          subCategory.selected = true;
        });
      }

      if (this.action.materialGroup && this.action.materialGroup._id) {
        this.action.materialGroup = this.action.materialGroup._id;
      }
    }
  }

  setEmptyFields() {
    this.action = {
      actionSet: this.action.actionSet,
      settings: {
        material: {},
        colorOrShade: {},
        design: {},
        contactType: {},
        occlusion: {},
        retainment: {},
        implantSteps: {},
        dentureSteps: {},
        testingSteps: {},
        claspTeeth: {},
        claspMaterial: {},
        orthColor: {},
        facialLoop: {},
        claspType: {},
        springType: {},
        springTeeth: {},
        expansionScrew: {},
        protrusion: {},
        ponticDesign: {},
        numberOfUnits: {}
      }
    };
  }

  checkExistingAction() {
    let isActionExist;
    this.savedActions.forEach((savedAction) => {
      if (
        this.action.name === savedAction.name &&
        this.action.number === savedAction.number
      ) {
        isActionExist = true;
      }
    });
    return isActionExist;
  }

  getSubCategoryNames() {
    return `${this.selectedSubCategories.length} selected`;
  }

  subCategorySelectionChanged(isSelected: boolean, subCategory: SubCategory) {
    if (isSelected) {
      this.selectedSubCategories.push(subCategory);
    } else {
      const index = this.selectedSubCategories.findIndex(
        (subCat) => subCat._id === subCategory._id
      );
      if (index > -1) {
        this.selectedSubCategories.splice(index, 1);
      }
    }
  }

  handleCostPerTooth() {
    if (this.action.teethOption !== 'Single tooth') {
      this.action.costPerTooth = false;
    }
  }

  async addUpdateAction() {
    if (this.action.vat) {
      this.action.vat = this.action.vat.toString();
      this.action.vat =
        this.action.vat.indexOf(',') >= 0
          ? this.action.vat.replace(',', '.')
          : this.action.vat;
    }
    this.action.subCategory = this.selectedSubCategories.map(
      (subCategory) => subCategory._id
    );
    if (
      this.action &&
      this.action.settings &&
      this.action.settings.numberOfUnits &&
      this.action.settings.numberOfUnits.isInteger
    ) {
      this.action.settings.numberOfUnits.numberOfDecimals = null;
    }
    this.selectedSubCategories = [];
    if (this.action._id) {
      const actionIndex = this.savedActions.findIndex(
        (savedAction) => savedAction._id === this.action._id
      );
      this.savedActions.splice(actionIndex, 1);
      if (this.checkExistingAction()) {
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_UPDATING_ACTION')
        );
        return;
      }
      this.actionService
        .updateAction(this.action)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('ACTION_UPDATED_SUCCESSFULLY')
          );
          this.modal.modal('hide');
          this.getAllActions.emit();
        })
        .catch(async (err) => {
          this.handleError('addUpdateAction', 'ERROR_IN_UPDATING_ACTION', err);
        });
    } else {
      if (this.checkExistingAction()) {
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_CREATING_ACTION')
        );
        return;
      }
      this.actionService
        .saveAction(this.action)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('ACTION_CREATED_SUCCESSFULLY')
          );
          this.modal.modal('hide');
          this.getAllActions.emit();
        })
        .catch(async (err) => {
          this.handleError('addUpdateAction', 'ERROR_IN_CREATING_ACTION', err);
        });
    }
  }

  deleteAction() {
    this.actionService
      .deleteAction(this.action._id)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('CATEGORY_DELETED_SUCCESSFULLY')
        );
        this.modal.modal('hide');
        this.getAllActions.emit();
      })
      .catch(async (err) => {
        this.handleError('deleteAction', 'ERROR_IN_DELETING_ACTION', err);
      });
  }

  getSubCategories(id: string) {
    this.subCategoriesForAction = [];
    this.selectedSubCategories = [];
    this.action.mainCategory = id;
    const category = this.categories.find((findCategory) => {
      return this.action.mainCategory === findCategory._id;
    });
    if (category) {
      this.subCategoriesForAction = cloneDeep(category.subCategories);
    }
  }

  disableSaveButton() {
    if (isEmpty(this.action)) {
      return true;
    }
    var condition =
      !this.action.number ||
      !this.action.name ||
      !this.action.mainCategory ||
      this.selectedSubCategories.length === 0;
    if (
      this.action.settings &&
      this.action.settings.numberOfUnits &&
      (this.action.settings.numberOfUnits.include ||
        this.action.settings.numberOfUnits.mandatory)
    ) {
      return condition || !this.action.settings.numberOfUnits.header;
    }
    return condition;
  }

  closeActionModal() {
    this.setEmptyFields();
    this.selectedSubCategories = [];
    this.modal.modal('hide');
  }

  checkVatValidate() {
    if (this.action && this.action.vat) {
      var reg = new RegExp('^[0-9]*([0-9,.]{2,3})?$');
      if (!reg.test(this.action.vat)) {
        this.action.vat = this.action.vat.substr(0, this.action.vat.length - 1);
      }
    }
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(AddActionModalComponent.name, method, `${message} ${err}`);
    this.toastr.error(await this.translationsService.get(message));
  }
}
