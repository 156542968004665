/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './other-construction.html'
(function () {
    'use strict';
    angular.module('app').component('otherConstruction', {
        template: theTemplate,
        controller: otherConstructionController,
        controllerAs: 'vm',
        bindings: {
            constructionSettings: '=',
            selectedTeeth: '=',
            addUpdateOther: '=',
            removeOther: '=',
            orderIdToModify: '=',
            singleOtherData: '=',
            selectedConstructionObj: '=',//currently selected constructions objects
            tempOtherData: "=",
            otherCommentsArray: '='
        }
    });

    // Services to be injected here for this component
    otherConstructionController.$inject = [ '$scope', 'DATE' ];

    function otherConstructionController($scope, DATE) {
        // Scope variable
        var vm = this;

        // Variables used in this controllers
        if (!vm.singleOtherData) {
            vm.singleOtherData = {};
        }
        vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;

        //functions to be used in this controller
        vm.removeOtherTeethConstruction = removeOtherTeethConstruction;
        vm.saveOtherTeethConstruction = saveOtherTeethConstruction;

        /**
         * Method to remove other teeth construction
         */
        function removeOtherTeethConstruction() {
            if (vm.removeOther) {
                vm.removeOther(vm.selectedTeeth);
            }
            vm.selectedTeeth = '';
        }

        // Watch Selected Construction type object for the old construction data.
        $scope.$watch('vm.selectedTeeth',function() {
            //if we previously created any other then use that else create empty
            if (vm.selectedConstructionObj && vm.selectedConstructionObj.others && vm.selectedConstructionObj.others.length > 0) {
                var other = vm.selectedConstructionObj.others[vm.selectedConstructionObj.others.length - 1];
                //get old construction
                for (var p in vm.selectedConstructionObj.others) {
                    if (vm.selectedTeeth === vm.selectedConstructionObj.others[p].teethNo) {
                        vm.singleOtherData = {
                            allComments: vm.selectedConstructionObj.others[p].allComments
                        };
                    }
                }

                if (!vm.singleOtherData) {
                    vm.singleOtherData = {
                        allComments: vm.singleOtherData.allComments
                    };
                }

            } else {
                if (vm.tempOtherData) {
                    vm.singleOtherData = {
                        allComments: vm.tempOtherData.allComments
                    };
                }

            }
        });

        /**
         * Save Other construction
         */
        $scope.$watch('vm.singleOtherData',function(newVal, oldval) {
            //it is valid construction. lets save it
            vm.singleOtherData.teethNo = vm.selectedTeeth;
            vm.singleOtherData.type = 'other';
            vm.addUpdateOther(vm.singleOtherData);
        },true);

        /**
         * Method to add other teeth data
         */
        function saveOtherTeethConstruction() {
            //get whole data here. add validations as well.
            vm.singleOtherData.teethNo = vm.selectedTeeth;
            if (vm.addUpdateOther) {
                vm.addUpdateOther(vm.singleOtherData);
            }
            vm.selectedTeeth = '';
        }
    }

})();