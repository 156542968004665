import { Input, Output, Component, EventEmitter, OnInit } from '@angular/core';
import { ActionListService } from '../../../../services/action-list/action-list.service';
import { TranslationsService } from '../../../../services/translations/translations.service';
import { LogService } from '../../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import {
  ActionList,
  Organization
} from '../../../../models/organization.model';
import template from './add-action-list-modal.html';

@Component({
  selector: 'add-action-list-modal',
  template
})
export class AddActionListModalComponent implements OnInit {
  @Output() onModalClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCreatedNewList: EventEmitter<void> = new EventEmitter<void>();
  @Output() onActionListChanged: EventEmitter<
    Organization
    > = new EventEmitter<Organization>();
  @Input() actionList: Partial<ActionList>;

  constructor(
    private readonly actionListService: ActionListService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService
  ) {}

  ngOnInit() {
    if (!this.actionList) {
      this.actionList = {};
    }
  }

  cancel() {
    this.onModalClosed.emit();
  }

  async successCallback(organization: Organization, message: string, condition) {
    this.toastr.success(await this.translationsService.get(message));
    this.onActionListChanged.emit(organization);
    if (condition === 'created') {
      this.onCreatedNewList.emit();
    }
    this.onModalClosed.emit();
  }

  async errorCallback(message: string, method: string, err: Error){
    const text = await this.translationsService.get(message);
    this.logService.error(
      'add-action-list-modal.component',
      method,
      `${text}: ${err}`
    );
    this.toastr.error(text);
  }

  addActionList(): Promise<void> {
    const data = {
      name: this.actionList.name,
      description: this.actionList.description
    };
    return this.actionListService
      .addActionListForLab(data)
      .then(async (result) =>
        this.successCallback(result.data.actionLists, 'ACTION_LIST_CREATED', 'created')
      )
      .catch(async (err) =>
        this.errorCallback(
          'ERROR_IN_CREATING_ACTION_LIST',
          'addActionList',
          err
        )
      );
  }

  updateActionList(): Promise<void> {
    return this.actionListService
      .updateActionListForLab(this.actionList)
      .then(async (result) =>
        this.successCallback(result.data.actionLists, 'ACTION_LIST_UPDATED', null)
      )
      .catch(async (err) =>
        this.errorCallback(
          'ERROR_IN_UPDATING_ACTION_LIST',
          'updateActionList',
          err
        )
      );
  }
}
