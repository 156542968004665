import gql from 'graphql-tag';

const scheduleLogisticFragment = gql`
  fragment LogisticCreateFields on Logistic {
    _id
    transportDay
    status
    noOfRequestedPackages
    additionalPackages
    opterOrderId
    comments
    internalMessage
  }
`;

const logisticFragment = gql`
  fragment LogisticFields on Logistic {
    ...LogisticCreateFields
    clinic
    lab
    type
    noOfActualPackages
    additionalPackages
    deadline
    hasProblem
    orders {
      _id
      patient {
        firstName
        lastName
        personalIdentityNumber
      }
      deliveryDetails {
        isLabHandleRequest
      }
      orderId
    }
  }
  ${scheduleLogisticFragment}
`;

export const schedulePickupTemplate = gql`
  mutation schedulePickup(
    $clinic: ID!
    $transportDay: String!
    $noOfRequestedPackages: Int!
    $additionalPackages: Int
    $comments: String
    $internalMessage: String
  ) {
    schedulePickup(
      clinic: $clinic
      transportDay: $transportDay
      noOfRequestedPackages: $noOfRequestedPackages
      additionalPackages: $additionalPackages
      comments: $comments
      internalMessage: $internalMessage
    ) {
      ...LogisticCreateFields
    }
  }
  ${scheduleLogisticFragment}
`;

export const schedulePickupFromTruckTemplate = gql`
  mutation schedulePickupFromTruck(
    $clinic: ID!
    $transportDay: String!
    $noOfRequestedPackages: Int!
    $orderId: String!
    $comments: String
    $dentistName: NameInput
    $internalMessage: String
  ) {
    schedulePickupFromTruck(
      clinic: $clinic
      transportDay: $transportDay
      noOfRequestedPackages: $noOfRequestedPackages
      comments: $comments
      orderId: $orderId
      dentistName: $dentistName
      internalMessage: $internalMessage
    ) {
      ...LogisticFields
    }
  }
  ${logisticFragment}
`;

export const updatePickupFromTruckTemplate = gql`
  mutation updatePickupFromTruck(
    $_id: ID!
    $clinic: ID!
    $transportDay: String!
    $noOfRequestedPackages: Int!
    $orderId: String!
    $comments: String
    $dentistName: NameInput
    $internalMessage: String
  ) {
    updatePickupFromTruck(
      _id: $_id
      clinic: $clinic
      transportDay: $transportDay
      noOfRequestedPackages: $noOfRequestedPackages
      comments: $comments
      orderId: $orderId
      dentistName: $dentistName
      internalMessage: $internalMessage
    ) {
      ...LogisticFields
    }
  }
  ${logisticFragment}
`;

export const scheduleDeliveryTemplate = gql`
  mutation scheduleDelivery(
    $clinic: ID!
    $transportDay: String!
    $noOfRequestedPackages: Int!
    $comments: String
    $deadline: String
    $internalMessage: String
  ) {
    scheduleDelivery(
      clinic: $clinic
      transportDay: $transportDay
      noOfRequestedPackages: $noOfRequestedPackages
      comments: $comments
      deadline: $deadline
      internalMessage: $internalMessage
    ) {
      ...LogisticCreateFields
      deadline
    }
  }
  ${scheduleLogisticFragment}
`;

export const updatePickupTemplate = gql`
  mutation updatePickup(
    $_id: ID!
    $noOfRequestedPackages: Int!
    $additionalPackages: Int
    $comments: String
    $internalMessage: String
  ) {
    updatePickup(
      _id: $_id
      noOfRequestedPackages: $noOfRequestedPackages
      additionalPackages: $additionalPackages
      comments: $comments
      internalMessage: $internalMessage
    )
  }
`;
export const updateDeliveryTemplate = gql`
  mutation updateDelivery(
    $_id: ID!
    $noOfRequestedPackages: Int!
    $comments: String
    $deadline: String
    $internalMessage: String
  ) {
    updateDelivery(
      _id: $_id
      noOfRequestedPackages: $noOfRequestedPackages
      comments: $comments
      deadline: $deadline
      internalMessage: $internalMessage
    )
  }
`;

export const deleteTransportTemplate = gql`
  mutation deleteTransport($_id: ID!) {
    deleteTransport(_id: $_id)
  }
`;

export const getTransportsTemplate = gql`
  query logistics($transportDay: String!, $isPlanned: Boolean!) {
    logistics(transportDay: $transportDay, isPlanned: $isPlanned) {
      ...LogisticFields
    }
  }
  ${logisticFragment}
`;

export const getTransportStatusTemplate = gql`
  query transportStatuses($opterOrderId: Int!) {
    transportStatuses(opterOrderId: $opterOrderId) {
      DateAndTime
      Status
      POD_Descr
      DEA_FileName
      DEA_Guid
      DateTimeString
      OFF_Id
      hasImage
    }
  }
`;

export const getDeliveriesAndPickupsForDateTemplate = gql`
  query deliveriesAndPickupsForDate($date: String!, $isPlanned: Boolean!) {
    deliveriesAndPickupsForDate(date: $date, isPlanned: $isPlanned) {
      ...LogisticFields
    }
  }
  ${logisticFragment}
`;

export const getTransportTemplate = gql`
  query transport($_id: String!) {
    transport(_id: $_id) {
      ...LogisticFields
    }
  }
  ${logisticFragment}
`;

export const statusChangedSubscriptionTemplate = gql`
  subscription statusChanged($labId: ID!) {
    statusChanged(labId: $labId) {
      ...LogisticFields
    }
  }
  ${logisticFragment}
`;

export const toggleTransportTemplate = gql`
  mutation toggleTransport($isTransportEnable: Boolean!) {
    toggleTransport(isTransportEnable: $isTransportEnable)
  }
`;
