class Pubsub {
  private static _instance: Pubsub = new Pubsub();
  handlers: { [key: string]: { handler: Function } } = {};

  constructor() {
    if (Pubsub._instance) {
      throw new Error('Singleton class. Cannot instantiate using new');
    }
    Pubsub._instance = this;
  }

  public static get instance(): Pubsub {
    return Pubsub._instance;
  }

  subscribe(event: string, handler: Function, context?: Function) {
    if (typeof context === 'undefined') {
      context = handler;
    }
    this.handlers[event] = {
      handler: handler.bind(context)
    };
  }

  publish(event: string, args?: Object) {
    if (!this.handlers[event]) {
      throw new Error('Event not subscribed');
    }
    this.handlers[event].handler(args);
  }

  unsubscribe(event: string) {
    if (!this.handlers[event]) {
      throw new Error('Event not subscribed');
    }
    delete this.handlers[event];
  }

  unsubscribeAll() {
    this.handlers = {};
  }
}

export default Pubsub.instance;
