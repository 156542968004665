import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class SessionService {
  /**
   * Set data in local storage as key value pair
   * @param key
   * @param value
   */
  setItem(key: string, value: string | null): void {
    window.localStorage.setItem(key, value);
  }

  /**
   * Get Key value from local storage
   * @param key
   */
  getItem(key: string): string | null {
    return window.localStorage.getItem(key);
  }

  /**
   * Remove key from the local storage
   * @param key
   */
  removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }

  /**
   * Clear the local storage
   * @param key
   */
  clearLocalStorage(): void {
    return window.localStorage.clear();
  }

  /**
   * Set setting in the local storage
   * @param setting
   */
  setSetting(setting: any): void {
    this.setItem('setting', JSON.stringify(setting));
  }

  /**
   * Get setting from the local storage
   */
  getSetting(key: string): any {
    const setting = this.getItem('setting');
    return setting ? _.get(JSON.parse(setting), key) : '';
  }

  /**
   * Set object in the local storage
   * @param key
   * @param object
   */
  setObject(key: string, object: any): void {
    this.setItem(key, JSON.stringify(object));
  }

  /**
   * Get object from the local storage
   */
  getObject(key: string): any {
    return JSON.parse(this.getItem(key));
  }


  /**
   * Remove setting from the local storage
   */
  removeSetting(): void {
    this.removeItem('setting');
  }
}
