import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { GetAllOrdersService } from '../../../services/order-list/get-all-orders.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { OrdersGraphqlService } from '../../../graphql-services/orders/orders.graphql.service';
import { OrganizationSettingService } from '../../../services/organization-setting/organization-setting.service';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../app.constant';
import { Order } from '../../../models/order.model';
import { User } from '../../../models/user.model';
import template from './clinic-received-delivery-modal.html';

@Component({
  selector: 'clinic-received-delivery-modal',
  template: template
})
export class ClinicReceivedDeliveryModalComponent implements OnInit {
  @Input() orderDetail: Order;
  @Input() currentUser: User;
  @Input() totalVat: number = 0;
  @Input() totalPrice: number = 0;
  @Input() currency: string = '';
  isPriceConfirmed: boolean = false;
  isCertifyEnabled: boolean = false;

  constructor(
    private readonly ordersGraphqlService: OrdersGraphqlService,
    private readonly getAllOrdersService: GetAllOrdersService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService,
    private readonly organizationSettingService: OrganizationSettingService,
    @Inject(forwardRef(() => '$state')) private readonly $state: any
  ) {}

  ngOnInit() {
    this.isCertifyEnabled = this.organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CERTIFICATION
    );
  }

  async changeToClinicReceivedDelivery() {
    try {
      const orderId = this.$state.params.orderId;
      const status = 'clinic_received_delivery';
      await this.ordersGraphqlService.orderNewStatusChange(orderId, status, this.isPriceConfirmed);
      this.getAllOrdersService.clearData(); //to clear cached orders list data
      this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
      this.toastr.success(
        await this.translationsService.get('SUCCESSFULLY_UPDATED')
      );
      window.location.reload();
    } catch(error) {
      this.logService.error('clinic-received-delivery-modal.component', 'changeToClinicReceivedDelivery', error);
    }
  }
}