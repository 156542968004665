import { Component, EventEmitter, Input, Output } from '@angular/core';
import template from './add-material-modal.html';
import { MaterialListService } from '../../../../../services/material-list/material-list.service';
import {
  Material,
  MaterialList
} from '../../../../../models/organization.model';
import { LogService } from '../../../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../../../../services/translations/translations.service';
@Component({
  selector: 'add-material-modal',
  template: template
})
export class AddMaterialModalComponent {
  @Input() material: Material;
  @Input() selectedList: MaterialList;
  @Output()
  onModalClosed: EventEmitter<MaterialList | void> = new EventEmitter<MaterialList | void>();
  constructor(
    private materialListService: MaterialListService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private logService: LogService
  ) {}

  updateMaterial(): Promise<void> {
    if (this.material.price) {
      this.material.price = this.checkAndReplaceCommaFromPrice(this.material.price.toString());
    }
    return this.materialListService
      .updateMaterialOnList(this.selectedList._id, this.material)
      .then(async (result) => {
        this.toastr.success(
          await this.translationsService.get('MATERIAL_UPDATED')
        );
        this.onModalClosed.emit(result.data.materialLists);
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_UPDATING_MATERIAL'
        );
        this.logService.error(
          'add-material-list-modal.component',
          'updateMaterial',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  addMaterial(): Promise<void> {
    if (this.material.price) {
      this.material.price = this.checkAndReplaceCommaFromPrice(this.material.price.toString());
    }
    return this.materialListService
      .addMaterialToList(this.selectedList._id, this.material)
      .then(async (result) => {
        this.toastr.success(
          await this.translationsService.get('MATERIAL_CREATED')
        );
        this.onModalClosed.emit(result.data.materialLists);
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_CREATING_MATERIAL'
        );
        this.logService.error(
          'add-material-list-modal.component',
          'addMaterialList',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  cancel() {
    this.onModalClosed.emit();
  }

  checkAndReplaceCommaFromPrice(entity: string) {
    if (entity.includes(',')) {
      entity = entity.split(',').join('.');
    }
    return parseFloat(entity);
  }

  activeInactiveMaterial(value: boolean) {
    this.material.inactive = value;
    if (value) {
      this.material.inactiveDate = Date.now();
    } else {
      this.material.inactiveDate = null;
    }
  }

  disableSave(): boolean {
    if (
      !this.material ||
      !this.material.code ||
      !this.material.description ||
      !this.material.price
    ) {
      return true;
    }
    if (this.material.autoReduction && !this.material.quantity) {
      return true;
    }
  }
}
