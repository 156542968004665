/**
 * Directive to add dashes in numbers automatically
 */
(function () {
    'use strict';
    angular.module('app').filter('identity', function () {
        return function (number) {
            if (!number) {
                return '';
            }
            //if it is not a string then covert to string
            number = String(number);
            //if there is no dash then only add a dash
            if (number.indexOf('-') === -1) {
                if (number.length <= 10 && number.length > 6) {
                    number = number.slice(0, 6) + "-" + number.slice(6);
                } else if (number.length > 10) {
                    //add - at index 8
                    number = number.slice(0, 8) + "-" + number.slice(8);
                }
            } else if (number.length > 11) {
                //if there is already - then remove it and add new on index 8
                if (number.indexOf('-') !== -1 && number.indexOf('-') !== 8) {
                    var postion = number.indexOf('-');
                    number = number.substring(0,postion) + number.substring(postion + 1, number.length);
                }
                if (number.indexOf('-') === -1) {
                    //add - at index 8
                    number = number.slice(0, 8) + "-" + number.slice(8);
                }
                //trim if length is greater then 12
                if (number.length > 13) {
                    number = number.substring(0,13);
                }
            } else if (number.length === 11) {
                //if there is already - then remove it and add new on index 6
                if (number.indexOf('-') !== -1 && number.indexOf('-') === 8) {
                    var postions = number.indexOf('-');
                    number = number.substring(0,postions) + number.substring(postions + 1, number.length);
                }
                if (number.indexOf('-') === -1) {
                    //add - at index 6
                    number = number.slice(0, 6) + "-" + number.slice(6);
                }
            }
            return number;
        };
    });

})();