import template from './ta-deadline-picker.html';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { DEADLINES } from '../../../../app.constant';

@Component({
  selector: 'ta-deadline-picker',
  template: template
})
export class TaDeadlinePicker {
  private _deadline: string;
  @Input() readonly: boolean;
  @Input()
  get deadline(): string {
    return this._deadline;
  }
  set deadline(newDeadline: string) {
    this._deadline = newDeadline;
    this.deadlineChange.emit(newDeadline);
  }
  @Output() deadlineChange = new EventEmitter<string>();

  deadlines: string[] = DEADLINES;

  deadlineSelected(event) {
    this.deadlineChange.emit(event.value);
  }
}
