/* start: imports-loader */
import '../../../app.upgrade';
import '../../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './price-specification-modal.html'
/**
 * Order Details Component for the order details of any order.
 */
(function () {
    'use strict';
    angular.module('app').component('priceSpecificationModal', {
        template: theTemplate,
        controller: PriceSpecificationModalController,
        controllerAs: 'vm',
        bindings: {
            newOrderObj: '=',
            currency: '=',
            currentUser: '=',
            labs: '=',
            clinicObj: '='
        }
    });

    // Dependencies should be inject here.
    PriceSpecificationModalController.$inject = [ 'orderNew2DeliveryService' ];

    // Controller Function for the order details component
    function PriceSpecificationModalController(orderNew2DeliveryService) {
        var vm = this;
        vm.priceSpecification = [];
        vm.calculatePrice = calculatePrice;
        vm.showQuantity = showQuantity;

        vm.$onInit = function () {
            if (vm.newOrderObj && vm.newOrderObj.allConstructions && vm.newOrderObj.allConstructions.length) {
                priceSpecificationDetails();
            }
        };

        // Getting price details
        function priceSpecificationDetails() {
            getActions();

            if (vm.labs) {
                var selectedLab = vm.labs.find(function (lab) {
                    return lab.lab && lab.lab._id === vm.newOrderObj.selectedLab;
                });
            }
            // if lab is found then add price list.
            if (selectedLab) {
                vm.selectedLabPriceList = selectedLab.priceList;
            }

            vm.priceDiff = orderNew2DeliveryService.getPriceDiff(selectedLab);

            var clinic = vm.clinicObj || vm.newOrderObj.clinic;

            var matGroups = clinic.materialGroups.reduce(function (res, mg) {
                return res.concat(mg.materials);
            }, []);

            var materials = orderNew2DeliveryService.getSelectedMaterials(vm.newOrderObj.allConstructions);

            materials = orderNew2DeliveryService.getMaterialWithName(materials, matGroups);
            vm.priceDiff = orderNew2DeliveryService.getMaterialWithName(vm.priceDiff, matGroups).filter(function (price) {
                return materials.some(function (mat) {
                    return mat.materialName === price.materialName;
                });
            });

            vm.priceDiff = orderNew2DeliveryService.getMaterialPriceDiff(vm.newOrderObj.allConstructions, vm.priceDiff);

            //get all actions from lab priceList array.
            var priceActions = getPriceList(vm.selectedLabPriceList);

            //get price from matched lab priceList actions.
            if (vm.priceSpecification) {
                vm.priceSpecification.forEach(function (action) {
                    var filter = priceActions.filter(function (priceAction) {
                        return action._id === priceAction.action;
                    });
                    filter.forEach(function (priceAction, index) {
                        action.price = priceAction.price;
                        if (action.warranty) {
                            action.price = 0;
                        }
                    });
                });
            }

            var addedMaterials = vm.newOrderObj.allConstructions.reduce(function (result, construction) {
                return result.concat(construction.addedMaterials || []);
            }, []);
            vm.priceSpecification = vm.priceSpecification.concat(addedMaterials);
            vm.totalVat = 0;
            vm.newOrderObj.deliveryInfo.price = vm.priceSpecification.reduce(function (sum, price) {
                var tempPrice = 0;
                var vatPrice = 0;
                //Check for if action per cost is enable for that action then multiply price by number of teeth selected in construction-----> only for single tooth options
                if (price.costPerTooth && price.teethNo && price.teethNo.length) {
                    tempPrice = sum + price.price * price.teethNo.length;
                    vatPrice = (price.price * price.teethNo.length) * (price.vat || 0) / 100;
                    vm.totalVat += vatPrice;
                    return tempPrice + vatPrice;
                }
                if (price.costPerTooth && price.teethRange && price.teethRange.start === 18 && price.teethRange.end === 38) {
                    //only if both up/low jaw teeth selected in construction then set quantity 2.
                    var upLowJawQuantity = 2;
                    tempPrice = sum + price.price * upLowJawQuantity;
                    vatPrice = (price.price * upLowJawQuantity) * (price.vat || 0) / 100;
                    vm.totalVat += vatPrice;
                    return tempPrice + vatPrice;
                }
                tempPrice = sum + price.price * (price.numberOfUnits || price.quantity);
                vatPrice = (price.price * (price.numberOfUnits || price.quantity)) * (price.vat || 0) / 100;
                vm.totalVat += vatPrice;
                return tempPrice + vatPrice;
            }, 0);

            vm.newOrderObj.deliveryInfo.price = vm.priceDiff.reduce(function (total, mat) {
                var matTempPrice = 0;
                var matVatPrice = 0;
                matTempPrice = total + (mat.priceDiff || 0) * mat.quantity;
                matVatPrice = (mat.priceDiff || 0) * mat.quantity * (mat.vat || 0) / 100;
                vm.totalVat += matVatPrice;
                return matTempPrice + matVatPrice;
            }, vm.newOrderObj.deliveryInfo.price);
            vm.newOrderObj.priceSpecification = _.cloneDeep(vm.priceSpecification);
            vm.newOrderObj.priceSpecification = vm.newOrderObj.priceSpecification.concat(vm.priceDiff);
        }

        //get actions from constructions.
        function getActions() {
            vm.priceSpecification = vm.newOrderObj.allConstructions.reduce(function (result, construction) {
                construction = _.cloneDeep(construction);
                construction.numberOfUnits = orderNew2DeliveryService.getNumOfUnits(construction.numberOfUnits);
                if (construction.actions) {
                    var actions = construction.actions.map(function (action) {
                        action.warranty = construction.warranty;
                        action.teethNo = construction.teethNo;
                        action.teethRange = construction.teethRange;
                        if (!construction.numberOfUnits) {
                            action.numberOfUnits = 1;
                            return _.clone(action);
                        }
                        var unit = construction.numberOfUnits.find(function (unit) {
                            return action._id === unit.actionId;
                        });
                        if (!unit) {
                            action.numberOfUnits = 1;
                            return _.clone(action);
                        }
                        action.numberOfUnits = unit.numberOfUnits || 1;
                        return _.clone(action);
                    });
                    return result.concat(actions);
                } else {
                    return result;
                }
            }, []);
        }

        function getPriceList(priceList) {
            if (priceList) {
                return priceList.reduce(function (result, price) {
                    return result.concat(price.actions);
                }, []);
            }
            return [];
        }

        function calculatePrice(action) {
            if (!action.quantity) {
                if (action.costPerTooth && action.teethNo && action.teethNo.length) {
                    return action.price * action.teethNo.length;
                }
                if (action.costPerTooth && action.teethRange &&
                    action.teethRange.start === 18 && action.teethRange.end === 38) {
                    return action.price * 2;
                }
                return action.price * (action.numberOfUnits ? action.numberOfUnits : 1);
            }
            return action.price * (action.quantity ? action.quantity : 1);

        }

        function showQuantity(action) {
            if (action.costPerTooth && action.teethNo && action.teethNo.length) {
                return action.teethNo.length;
            }
            if (action.costPerTooth && action.teethRange &&
                action.teethRange.start === 18 && action.teethRange.end === 38) {
                return 2;
            }
            return action.numberOfUnits ? action.numberOfUnits : 1;
        }

    }
})();