import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UsersService } from '../../../services/users/users.service';
import { GetAllUsersService } from '../../../services/order-list/get-all-users.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../../services/core/log.service';
import template from './users-list-item.html';
import { TranslationsService } from '../../../services/translations/translations.service';
import { CurrentUserService } from '../../../services/users/current-user.service';
import { cloneDeep } from 'lodash';
import { User } from '../../../models/user.model';

@Component({
  selector: 'users-list-item',
  template: template
})
export class UsersListItemComponent {
  @Output() onUserUpdate: EventEmitter<User> = new EventEmitter<User>();
  @Input() user: User;
  @Input() currentUser: User;
  @Input() isSuperAdmin: boolean;
  constructor(
    private userService: UsersService,
    private getAllUsersService: GetAllUsersService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private currentUserService: CurrentUserService,
    private logService: LogService
  ) {}

  editUser(event: Event): void {
    event.preventDefault();
    this.onUserUpdate.emit(cloneDeep(this.user));
  }

  toggleUserStatus(event: Event, userId: string, userStatus: boolean): void {
    event.preventDefault();
    userStatus = !userStatus;
    const user = {
      id: userId,
      active: userStatus
    };
    // call api to save the user to backend
    this.userService
      .editUser(user)
      .then(async () => {
        this.user.active = userStatus;
        this.getAllUsersService.clearUsersData(); // to clear cached users data
        const text = await this.translationsService.get('USER_STATUS_UPDATED');
        this.toastr.success(text);
      })
      .catch(async (err) => {
        const text = await this.translationsService.get('ERROR_IN_UPDATE_USER');
        this.logService.error(
          'users-list-item.component',
          'toggleUserStatus',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  async setUser(event: Event): Promise<void> {
    event.preventDefault();
    if (!this.user.active) {
      const text = await this.translationsService.get('USER_IS_INACTIVE');
      this.logService.error('users-item.component', 'setUser', text);
      this.toastr.error(text);
      return;
    }
    this.userService
      .loginAs({ id: this.user._id })
      .then((result) => {
        if (result && result.user && result.token) {
          this.currentUserService.login(
            result.token,
            result.settings,
            result.user
          );
        }
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_GETTING_APP_VERSION'
        );
        this.logService.error(
          'users-item.component',
          'setUser',
          text + ': ' + err.message
        );
        this.toastr.error(text);
      });
  }
}
