import { Injectable } from '@angular/core';

@Injectable()
export class NewOrderListRedirectService {
  newOrderList(labId?: string): void {
    window.top.postMessage(
      {
        eventType: 'navigate',
        urlSegment: this.newOrderListUrl(labId)
      },
      '*'
    );
  }

  newOrderListUrl(labId?: string): string {
    if (labId) {
      return `/orders/list?labId=${labId}`;
    }
    return `/orders/list`;
  }
}
