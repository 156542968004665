import { forwardRef, Inject, Injectable } from '@angular/core';
import { SessionService } from '../core/session.service';
import { PermissionService } from '../core/permission.service';
import {
  CustomerSettingType,
  CustomerSetting
} from '../../models/customer-setting.model';

@Injectable()
export class OrganizationSettingService {
  private customerSettings: CustomerSetting[] = [];

  setCustomerSettings(settings: CustomerSetting[]): void {
    if (settings.length) {
      this.customerSettings = settings;
    }
  }

  getCustomerSettings(): CustomerSetting[] {
    return this.customerSettings;
  }

  getCustomerSetting(name: string): any {
    const setting = this.customerSettings.find((theSetting) => {
      return theSetting.name === name;
    });

    // Should only occurr if customer settings couldn't be loaded. For example if logged in a super admin.
    if (!setting) {
      return undefined;
    }

    switch (setting.type) {
      case CustomerSettingType.Object: {
        try {
          return JSON.parse(setting.value);
        } catch (err) {
          return setting.value;
        }
      }
      case CustomerSettingType.Boolean:
        return setting.value === 'true' || setting.value === true;
      default:
        return setting.value;
    }
  }

  getPreSelectedValueOfSetting(name: string): any {
    const setting = this.customerSettings.find((theSetting) => {
      return theSetting.name === name;
    });
    if (!setting) {
      return;
    }
    return setting.selectedValue;
  }

  getFromCustomerSettings(customerSettings: CustomerSetting[], name: string): any {
    const setting = customerSettings.find((theSetting) => {
      return theSetting.name === name;
    });

    // Should only occurr if customer settings couldn't be loaded. For example if logged in a super admin.
    if (!setting) {
      return undefined;
    }

    switch (setting.type) {
      case CustomerSettingType.Object: {
        try {
          return JSON.parse(setting.value);
        } catch (err) {
          return setting.value;
        }
      }
      case CustomerSettingType.Boolean:
        return setting.value === 'true' || setting.value === true;
      default:
        return setting.value;
    }
  }
}
