import { Component, Input, Output, EventEmitter } from '@angular/core';
import template from './order-status-card.html';
import { Order } from '../../models/order.model';
import { User } from '../../models/user.model';
import { PermissionService } from '../../services/core/permission.service';
import { StatusService } from '../../services/status/status.service';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'order-status-card',
  template: template
})
export class OrderStatusCardComponent {
  @Output() onToggleLabHandleRequest: EventEmitter<{
    order: Order;
    isLabHandleRequest: boolean;
  }> = new EventEmitter<{ order: Order; isLabHandleRequest: boolean }>();
  @Input() order: Order;
  @Input() user: User;
  isRedClass = false;

  constructor(
    public permissionService: PermissionService,
    public statusService: StatusService
  ) {}

  showStatus() {
    return this.statusService.getStatus(this.order, this.user);
  }

  isDentist() {
    return this.permissionService.isDentist(this.user);
  }

  isLabTechnician() {
    return this.permissionService.isLabTechnician(this.user);
  }

  toggleLabHandleRequest(order, isLabHandleRequest) {
    this.onToggleLabHandleRequest.emit({ order: cloneDeep(order), isLabHandleRequest: isLabHandleRequest });
  }
}
