import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output
} from '@angular/core';
import { OrganizationService } from '../../../services/clinic/organization.service';
import { Organization } from '../../../models/organization.model';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import template from './clinic-card-item.html';

@Component({
  selector: 'clinic-card-item',
  template: template
})
export class ClinicCardItemComponent {
  @Input() clinic: Organization;
  @Output('onClinicUpdate') onClinicUpdate: EventEmitter<
    Organization
  > = new EventEmitter<Organization>();

  constructor(
    private organizationService: OrganizationService,
    private translationsService: TranslationsService,
    private logService: LogService,
    private toastr: ToastrService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  toggleClinicStatus(clinic: Organization): void {
    clinic.active = !clinic.active;
    // org obj to be updated
    this.organizationService
      .updateOrganization(clinic._id, { active: clinic.active })
      .then(async () => {
        this.clinic.active = clinic.active;
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
      })
      .catch(async (err: any) => {
        const text = await this.translationsService.get(
          'ERROR_IN_UPDATE_CLINIC'
        );
        this.logService.error(
          'clinic-cad-item.component',
          'toggleClinicStatus',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  editClinic(): void {
    this.onClinicUpdate.emit(_.cloneDeep(this.clinic));
  }

  goToSettings(): void {
    this.$state.go('app.customer-settings', { id: this.clinic._id });
  }
}
