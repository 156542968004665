/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './teeth-structure.html'
/**
 * @author Surendra
 * Teeth Structure component
 */
(function () {
    'use strict';
    angular.module('app').component('orderTeethStructure', {
        template: theTemplate,
        controller: teethStructureController,
        controllerAs: 'vm',
        bindings: {
            selectedTeeth: '=', //currently selected teeth
            selectedTeethRange: "=", // range of selected teeths
            selectedConstructionObj: '=', //currently selected constructions objects
            selectedConstructionType: '=', //selected constructions type
            teethSelected: '=', //function on a single teeth selected
            teethRangeSelected: '=',
            removeClicked: '=', // function after remove click
            selectedRemovableConstructionObj: '='
        }
    });
    teethStructureController.$inject = [ 'teethStructureService','toothStructureService', '$scope', '$rootScope' ];
    function teethStructureController(teethStructureService,toothStructureService, $scope, $rootScope) {
        var vm = this;
        //teeth structure upper and lower range
        vm.abutmentTeethFirstRange = [ 18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28 ];
        vm.abutmentTeethSecondRange = [ 48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38 ];
        vm.selectedTeethRange = {};
        //methods
        vm.teethClick = toothClick;
        vm.commonActiveCrown = commonActiveCrown;
        vm.removeConstruction = removeConstruction;

        /**
         * Action on click of a tooth
         * This function decides if we want to create any construction on this or not
         * @param tooth
         * @param isUpper
         */
        function toothClick(tooth, isUpper) {
            teethStructureService.toothClick(tooth,isUpper, vm.selectedConstructionObj, vm.selectedTeeth,
                vm.selectedConstructionType, vm.selectedTeethRange, vm.abutmentTeethFirstRange,
                vm.abutmentTeethSecondRange, vm.selectedRemovableConstructionObj, vm.teethRangeSelected, vm.teethSelected)
                .then(function (result) {
                    if (result) {
                        vm.selectedTeethRange = result.selectedTeethRange;
                        vm.teethRangeSelected = result.teethRangeSelected;
                        vm.selectedConstructionType = result.selectedConstructionType;
                        vm.selectedTeeth = result.selectedTeeth;
                        if (checkIfTheConstructionIsOfRemovableType(vm.selectedConstructionType)) {
                            $rootScope.$broadcast("changeConstructionType", { type: 'removable',
                                selectedConstructionType: result.selectedConstructionType,
                                tooth: tooth, isUpper: isUpper
                            });
                        }

                    }
                }).catch(function (err) {
                    vm.selectedTeethRange = {};
                    vm.selectedTeeth = '';
                });

        }

        /**
         * Remove construction click
         * @param teeth
         */
        function removeConstruction(event, teeth) {
            event.stopPropagation();
            if (vm.removeClicked) {
                vm.removeClicked(teeth);
            }
        }

        /**
         * Return class set for given tooth based on all conditions
         * @param teeth
         * @param isUpperRow
         * @returns {{}}
         */

        function commonActiveCrown(teeth, isUpperRow) {
            if (vm.selectedConstructionType === 'bridge' ||
                vm.selectedConstructionType === 'implant bridge') {
                vm.selectedTeeth = '';
            }
            var obj = teethStructureService.commonActiveDenture(teeth, isUpperRow, vm.selectedTeeth, vm.selectedConstructionType, vm.selectedTeethRange, vm.abutmentTeethFirstRange, vm.abutmentTeethSecondRange, vm.selectedRemovableConstructionObj);
            if (toothStructureService.isActiveSingleTeeth(teeth, vm.selectedConstructionObj,'singleCrowns')) {
                obj['single-crown'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, vm.selectedConstructionObj,'implantCrowns')) {
                obj['implant-crown'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, vm.selectedConstructionObj,'posts')) {
                obj['square-post'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, vm.selectedConstructionObj,'others')) {
                obj.other = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, vm.selectedConstructionObj,'implantCrowns')) {
                obj['square-bg'] = true;
            }
            if (vm.selectedTeeth === teeth) {
                obj['square-selected'] = true;
                //if single teeth then mark create color as well
                /*      if(vm.selectedConstructionType === 'post'){
                    obj['square-post'] = true;
                }
                else if(vm.selectedConstructionType === 'other'){
                    obj.other = true;
                }
                else if(vm.selectedConstructionType === 'implant crown'){
                    obj['implant-crown'] = true;
                }
                else if(vm.selectedConstructionType === 'single crown'){
                    obj['single-crown'] = true;
                }*/

            }

            if (vm.selectedTeethRange.start === teeth && (vm.selectedConstructionType === 'bridge' ||
                vm.selectedConstructionType === 'implant bridge')) {
                if (vm.selectedTeethRange.end) {
                    obj['bridge-start'] = true;
                    /*  if(vm.selectedConstructionType === 'implant bridge'){
                        obj['implant-bridge'] = true;
                    }
                    else {
                        obj.bridge = true;
                    }*/
                } else {
                    obj['bridge-start-temp'] = true;
                }

            }
            if (vm.selectedTeethRange.end === teeth && (vm.selectedConstructionType === 'bridge' ||
                vm.selectedConstructionType === 'implant bridge')) {
                obj['bridge-end'] = true;
                /*if(vm.selectedConstructionType === 'implant bridge'){
                    obj['implant-bridge'] = true;
                }
                else {
                    obj.bridge = true;
                }*/
            }
            if (toothStructureService.isInMiddleOfSelectedBridge(teeth, isUpperRow, vm.selectedTeethRange, vm.abutmentTeethFirstRange, vm.abutmentTeethSecondRange) && (vm.selectedConstructionType === 'bridge' ||
                vm.selectedConstructionType === 'implant bridge')) {
                //check if this teeth is in middle of selected bridge
                obj['bridge-middle'] = true;
                /*    if(vm.selectedConstructionType === 'implant bridge'){
                   obj['implant-bridge'] = true;
                }
                else {
                   obj.bridge = true;
                }*/
            }
            //find if it is inside bridge created
            var selectedClass = toothStructureService.isInMiddleOfAnyCreatedBridge(teeth, vm.selectedConstructionObj, vm.abutmentTeethFirstRange, vm.abutmentTeethSecondRange,'bridges');
            var implantSelectedClass = toothStructureService.isInMiddleOfAnyCreatedBridge(teeth, vm.selectedConstructionObj, vm.abutmentTeethFirstRange, vm.abutmentTeethSecondRange,'implantBridges');
            if (selectedClass) {
                //check if this teeth is in middle of selected bridge
                obj[selectedClass] = true;
                obj.bridge = true;
            }
            if (implantSelectedClass) {
                //check if this teeth is in middle of selected bridge
                obj[implantSelectedClass] = true;
                obj['implant-bridge'] = true;
            }
            return obj;

        }

        // listening when the tab is switching from removable to fixed
        $scope.$on("selectedConstructionType", function (result, data) {
            vm.selectedConstructionType = data.selectedConstructionType;
            toothClick(data.tooth, data.isUpper);
        });

        /**
         * method to check the construction is of removable construction type
         * @param selectedConstructionType
         * @returns {*}
         */
        function checkIfTheConstructionIsOfRemovableType(selectedConstructionType) {
            var removableConstructions = [ 'partial denture', 'complete denture', 'temporary denture', 'bitetray', 'other removable' ];
            return removableConstructions.indexOf(selectedConstructionType) > -1;
        }
    }
})();