import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import template from './construction-header.html';
import { DeliveryService } from '../../../../../services/delivery-service';
import { ConstructionService } from '../../../../../services/construction-service';
import { ConstructionSettingsService } from '../../../../../services/construction-settings-service';
import {
  Category,
  SubCategory
} from '../../../../../../../models/organization.model';
import style from './construction-header.less';

@Component({
  selector: 'construction-header',
  template,
  styles: [style]
})
export class ConstructionHeaderComponent implements OnChanges {
  @Input() selectedConstructions: Object;
  @Input() selectedTeethRange: { end: string; start: string };
  @Input() selectedTeeth: string[];
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedMainCategory: Category;
  @Input() hasTeethOption: boolean;

  @Output() constructionRemoved: EventEmitter<void> = new EventEmitter<void>();

  boxStyle: { background: string; color: string };

  constructor(
    private readonly deliveryService: DeliveryService,
    private readonly constructionSettingsService: ConstructionSettingsService,
    private readonly constructionService: ConstructionService
  ) {}

  ngOnChanges(): void {
    this.boxStyle = this.constructionSettingsService.getColorClass(
      this.selectedSubCategory
    );
  }

  removeConstruction() {
    this.constructionRemoved.emit();
  }
}
