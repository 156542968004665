import { Component, Input } from '@angular/core';
import { SubCategory } from '../../../../../../../../models/organization.model';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './btn-list-type-setting.html';

@Component({
  selector: 'btn-list-type-setting',
  template
})
export class BtnListTypeSettingComponent {
  @Input() selectedSubCategory: SubCategory;
  @Input() key: string;
  @Input() settingName: string;
  @Input() istogglable: boolean;
  @Input() list: string[];
  @Input() selectedItem: string;

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  getStyles(itemName: string) {
    if (itemName !== this.selectedItem) {
      return '';
    }
    return this.constructionSettingsService.getColorClass(this.selectedSubCategory);
  }

  setSetting(value) {
    this.selectedItem = this.istogglable && this.selectedItem === value ? '' : value;
    this.constructionSettingsService.setSetting(this.key, this.selectedItem);
  }
}
