import {
  Input,
  Component,
  ViewChild,
  AfterViewInit,
  Inject,
  forwardRef,
  OnInit
} from '@angular/core';
import { OrderService } from '../../../services/order-new/order-new.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { Organization } from '../../../models/organization.model';
import { DATE } from '../../../app.constant';
import template from './chat.html';
import { User } from '../../../models/user.model';
import { ScrollToBottomDirective } from '../../../directives/scroll-to-bottom.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import angular = require('angular');
import { Order } from '../../../models/order.model';
import { PermissionService } from '../../../services/core/permission.service';
import { EscapedMessageService } from '../../../services/escaped-message/escaped-message.service';

@Component({
  selector: 'chat',
  template: template
})
export class ChatComponent implements AfterViewInit, OnInit {
  @ViewChild(ScrollToBottomDirective) scroll: ScrollToBottomDirective;
  @Input() currentUser: User;
  @Input() messages: any;
  @Input() orderId: string;
  @Input() lab: Organization;
  @Input() isExternalTechnician: boolean;
  @Input() orderDetail: Order;
  message = null;
  showChatTemplateBtn = false;
  fullDateTimeFormat = DATE.FULL_DATE_TIME;

  constructor(
    private readonly orderService: OrderService,
    private readonly translationsService: TranslationsService,
    private readonly spinnerService: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService,
    private readonly permissionService: PermissionService,
    private readonly escapedMessageService: EscapedMessageService,
    @Inject(forwardRef(() => '$document')) private readonly $document: any
  ) {}

  ngAfterViewInit(): void {
    this.spinnerService.show();
  }

  convertToHtml(message, index, idText) {
    const textElement = angular.element(
      this.$document[0].querySelector('#' + idText + index)
    );
    return textElement.html('<p>' + message + '</p>');
  }

  inputMessage(event) {
    this.message = event.target.value;
  }

  async sendMessage(): Promise<any> {
    if (this.message) {
      const newMessage = this.escapedMessageService.getEscapedMessage(this.message);
      const sendObject: any = {
        orderId: this.orderId,
        message: newMessage
      };
      return this.orderService
        .addMessage(sendObject)
        .then(() => {
          this.message = null;
          const msgObj = {
            by: {
              name: this.currentUser.name,
              profileImage: this.currentUser.profileImage,
              _id: this.currentUser._id
            },
            message: newMessage,
            messageOn: new Date().getTime(),
            readBy: [this.currentUser._id],
            signedUrl: this.currentUser.signedUrl
          };
          if (!this.messages) {
            this.messages = [];
          }
          this.messages.push(msgObj);
        })
        .catch(async (err) => {
          this.logService.error(
            'chat.component',
            'sendMessage',
            `${await this.translationsService.get('ERROR_IN_SEND_MESSAGE')}: ${err}`
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_SEND_MESSAGE')
          );
        });
    } else {
      return this.toastr.error(
        await this.translationsService.get('INSERT_MESSAGE_PLEASE')
      );
    }
  }

  showLabChatDissabledMessage() {
    if (this.orderDetail) {
      return (
        this.permissionService.isLabTechnician(this.currentUser) &&
        !this.isExternalTechnician &&
        (this.orderDetail.isProductionFinished ||
          this.orderDetail.invoicingState !== 'NOT_READY')
      );
    }
    return false;
  }

  showOrderInteractionDisabledMessage() {
    if (this.orderDetail) {
      return (
        this.permissionService.isDentist(this.currentUser) &&
        (this.orderDetail.isProductionFinished ||
          this.orderDetail.invoicingState !== 'NOT_READY')
      );
    }
    return false;
  }

  isChatDisabled() {
    if (this.orderDetail) {
      return (
        this.permissionService.isDentist(this.currentUser) &&
        (this.orderDetail.isProductionFinished ||
          this.orderDetail.invoicingState !== 'NOT_READY')
      );
    }
    return true;
  }

  openChatTemplateDialog() {
    window.top.postMessage(
      {
        eventType: 'open-chat-template-dialog',
        orderData: {
          _id: this.orderDetail._id
        }
      },
      '*'
    );
  }

  ngOnInit(): void {
    if (this.currentUser && this.permissionService.isDentist(this.currentUser)) {
      const chatTemplateSetting = this.currentUser.organization.customerSettings.find(
        (setting) => {
          return setting.name === 'CHAT_TEMPLATE';
        }
      );
      if (chatTemplateSetting) {
        this.showChatTemplateBtn =
          chatTemplateSetting.value instanceof Array &&
          chatTemplateSetting.value.length > 0;
      }
    }
  }
}
