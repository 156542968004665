import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { User } from '../../models/user.model';
import { TranslationsService } from '../../services/translations/translations.service';
import { CacheService } from '../../services/core/cache.service';
import { LogService } from '../../services/core/log.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { OrganizationSettingService } from '../../services/organization-setting/organization-setting.service';
import { CONSTANT, CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { sortBy } from 'lodash';

import accountSystemSettingsTemplate from '../account-setting/account-system-setting.html';
import style from '../profile/system-setting-lab.less';
import { Organization } from '../../models/organization.model';
import { Language } from '../../models/language.model';

@Component({
  selector: 'account-system-setting',
  template: accountSystemSettingsTemplate,
  styles: [style]
})
export class AccountSystemSettingComponent implements OnInit {
  @Input() currentUser: User;
  language: { selected: any } = { selected: '' };
  languages: Language[] = CONSTANT.LANGUAGES;
  locales: string[] = CONSTANT.LOCALES;
  clinics: Organization[] = [];
  isAutoApproveSet: boolean;
  locale: string;
  currentDate: number = new Date().getTime();

  constructor(
    private toastr: ToastrService,
    public cacheService: CacheService,
    private organizationSettingService: OrganizationSettingService,
    private organizationService: OrganizationService,
    private logService: LogService,
    private translationsService: TranslationsService
  ) {}

  async getClinics(): Promise<void> {
    const self = this;
    return self.organizationService
      .getAllOrganizations({ type: 'clinic' })
      .then((result) => {
        self.clinics = result.data;
        sortBy(self.clinics, ['name']);
        // for preselection of default clinic and language
        if (self.currentUser && self.currentUser.systemSetting) {
          if (!self.currentUser.systemSetting.clinic) {
            self.currentUser.systemSetting.clinic = self.clinics[0]._id;
          }
        }
      })
      .catch(async (err) => {
        console.log('err', err);
        self.logService.error(
          'account-system-setting.component',
          'getClinics',
          (await self.translationsService.get('ERROR_IN_GETTING_CLINICS')) +
            ': ' +
            err
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_IN_GETTING_CLINICS')
        );
      });
  }

  getUserLanguage(): void {
    const self = this;
    self.language.selected = self.languages.find((language) => {
      return (
        self.currentUser.systemSetting &&
        self.currentUser.systemSetting.language &&
        language.value === self.currentUser.systemSetting.language
      );
    }).value;

    if (self.currentUser && !self.language) {
      self.language.selected = self.languages[0].value;
    }
  }

  languageSelected(): void {
    if (this.language.selected) {
      if (!this.currentUser.systemSetting) {
        this.currentUser.systemSetting = {};
      }
      this.currentUser.systemSetting.language = this.language.selected;
    }
  }

  ngOnInit(): void {
    const daysSetForAutoApprove = this.organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.AUTO_APPROVE
    );
    this.isAutoApproveSet = daysSetForAutoApprove > 0;
    this.getClinics();
    this.getUserLanguage();
  }
}
