import { Injectable } from '@angular/core';
import { CustomerService } from '../../../services/customer/customer.service';
import { Patient } from '../../../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerYearPrefixService {
  constructor(private readonly customerService: CustomerService) {}

  checkForInputLength(input) {
    const LAST_TWO_DIGITS_POS = -2;
    const presentYear = new Date()
      .getFullYear()
      .toString()
      .substr(LAST_TWO_DIGITS_POS);
    const inputLen = input.length;

    const YEAR_SHORT_LENGTH = 2;
    return (
      inputLen >= YEAR_SHORT_LENGTH &&
      parseInt(input.substring(0, YEAR_SHORT_LENGTH).trim()) > parseInt(presentYear)
    );
  }

  getInput(input, startingWith) {
    return !input.startsWith(startingWith) ? startingWith + input : input;
  }

  validate4DigitInputYear(pin: string, patient: Partial<Patient>) {
    let isPinValid = false;
    let valid19, valid20;
    const input = pin;
    const lenCheck = this.checkForInputLength(input);

    if (!input.startsWith('19') && lenCheck) {
      valid19 = this.customerService.validatePersonalNumber(
        this.getInput(input, '19')
      );
    }
    if (!input.startsWith('20') && lenCheck) {
      valid20 = this.customerService.validatePersonalNumber(
        this.getInput(input, '20')
      );
    }
    if (valid19) {
      patient.personalIdentity = this.addDashToNumbers('19' + pin);
      // Recall service to check again the pin is valid or not, after filter
      isPinValid = this.customerService.validatePersonalNumber(
        patient.personalIdentity
      );
    } else if (valid20) {
      patient.personalIdentity = this.addDashToNumbers('20' + pin);
      // Recall service to check again the pin is valid or not, after filter
      isPinValid = this.customerService.validatePersonalNumber(
        patient.personalIdentity
      );
    } else {
      isPinValid = false;
    }
    return isPinValid;
  }

  customerYearPrefix(pin: string, patient: Partial<Patient>): boolean {
    if (!pin) {
      return false;
    }
    // if pin have alphabet then convert it to upper case
    if (pin.match(/[a-z]/i)) {
      pin = pin.toUpperCase();
    }
    const input = pin;
    const inputLen = input.length;
    const MAX_PIN_LENGTH = 11;

    if (
      inputLen >= MAX_PIN_LENGTH + 1 &&
      (input.startsWith('19') || input.startsWith('20'))
    ) {
      patient.personalIdentity = this.addDashToNumbers(pin);

      // Recall service to check again the pin is valid or not, after filter
      return this.customerService.validatePersonalNumber(patient.personalIdentity);
    }
    return this.validate4DigitInputYear(pin, patient);
  }

  getPinGreaterThan11Chars(dashIndex: number, pinLength: number, pin: string) {
    const PIN_SEPERATOR_INDEX = 8;
    const MAX_PIN_LENGTH = 13;
    //if there is already - then remove it and add new on index 8
    if (dashIndex !== -1 && dashIndex !== PIN_SEPERATOR_INDEX) {
      const postion = dashIndex;
      pin = pin.substring(0, postion) + pin.substring(postion + 1, pinLength);
    }
    if (dashIndex === -1) {
      //add - at index 8
      pin = `${pin.slice(0, PIN_SEPERATOR_INDEX)}-${pin.slice(PIN_SEPERATOR_INDEX)}`;
    }
    //trim if length is greater then 12
    if (pinLength > MAX_PIN_LENGTH) {
      pin = pin.substring(0, MAX_PIN_LENGTH);
    }
    return pin;
  }

  getPinEqualTo11Chars(dashIndex: number, pinLength: number, pin: string) {
    //if there is already - then remove it and add new on index 6
    const PIN_SEPERATOR_INDEX = 8;
    if (dashIndex !== -1 && dashIndex === PIN_SEPERATOR_INDEX) {
      const postions = dashIndex;
      pin = pin.substring(0, postions) + pin.substring(postions + 1, pinLength);
    }
    if (dashIndex === -1) {
      const PIN_YEAR_POS = 6;
      //add - at index 6
      pin = `${pin.slice(0, PIN_YEAR_POS)}-${pin.slice(PIN_YEAR_POS)}`;
    }
    return pin;
  }

  addDashToNumbers(pin: string): string {
    const dashIndex = pin.indexOf('-');
    const pinLength = pin.length;
    const MAX_PIN_LENGTH = 11;

    //if there is no dash, add a dash
    if (dashIndex === -1) {
      const PIN_YEAR_POS = 6;
      if (pinLength <= MAX_PIN_LENGTH - 1 && pinLength > PIN_YEAR_POS) {
        return `${pin.slice(0, PIN_YEAR_POS)}-${pin.slice(PIN_YEAR_POS)}`;
      }
      if (pinLength > MAX_PIN_LENGTH - 1) {
        const PIN_SEPERATOR_INDEX = 8;
        //add - at index 8
        return `${pin.slice(0, PIN_SEPERATOR_INDEX)}-${pin.slice(
          PIN_SEPERATOR_INDEX
        )}`;
      }
      return pin;
    }
    if (pinLength > MAX_PIN_LENGTH) {
      return this.getPinGreaterThan11Chars(dashIndex, pinLength, pin);
    }
    if (pinLength === MAX_PIN_LENGTH) {
      return this.getPinEqualTo11Chars(dashIndex, pinLength, pin);
    }
    return pin;
  }
}
