import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Organization } from '../../models/organization.model';
import { UsersService } from '../../services/users/users.service';
import { LogService } from '../../services/core/log.service';
import { CustomerService } from '../../services/customer/customer.service';
import { User } from '../../models/user.model';
import template from './lab-notifications.html';

@Component({
  selector: 'lab-notifications',
  template: template
})
export class LabNotificationsComponent implements OnChanges {
  @Input() lab: Organization;
  userEmails: string[] = [];
  validEmail = false;
  labTechnicians: User[];

  constructor(
    private readonly userService: UsersService,
    private readonly logService: LogService,
    private readonly customerService: CustomerService
  ) {
    this.getLabTechnicians();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.lab?.emailNotifications?.otherEmail) {
      const emails = this.lab.emailNotifications.otherEmail.split(',');
      this.validEmail = emails.every((email) => this.checkEmail(email));
    }
    if (
      changes.lab &&
      this.lab &&
      this.lab.emailNotifications &&
      !this.lab.emailNotifications.type
    ) {
      this.lab.emailNotifications.type = 'user';
    }
  }

  getLabTechnicians(): void {
    this.userService
      .getLabTechnicians()
      .then((result) => {
        this.labTechnicians = result.data;
        this.labTechnicians.forEach((labTechnician) => {
          if (
            labTechnician.isAdmin &&
            this.userEmails.indexOf(this.userEmails[labTechnician.email]) === -1
          ) {
            this.userEmails.push(labTechnician.email);
          }
        });
      })
      .catch((err) => {
        this.logService.error(
          'lab-notifications.component',
          'getLabTechnicians',
          err
        );
      });
  }

  /*
   check email validity
    */
  checkEmail(email: string): boolean {
    return this.customerService.validateEmail(email);
  }

  emailChanged(emailString: string) {
    this.lab.emailNotifications.otherEmail = emailString;
    const emails = emailString.split(',');
    this.validEmail = emails.every((email) => this.checkEmail(email));
  }
}
