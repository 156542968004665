import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from '../../../../graphql-services/upload/upload.graphql.service';
import { LogService } from '../../../../services/core/log.service';
import { TranslationsService } from '../../../../services/translations/translations.service';
import template from './data-upload-modal.html';
import style from './data-upload-modal.less';

@Component({
  selector: 'data-upload-modal',
  styles: [style],
  template
})
export class DataUploadModalComponent {
  @Input() organization: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  entity = '';
  entities: { name: string; value: string }[] = [
    {
      name: 'MATERIAL_LIST',
      value: 'materialList'
    }
  ];
  files: any;

  constructor(
    private uploadService: UploadService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private logService: LogService
  ) {}

  uploadFile() {
    this.uploadService
      .uploadData(this.organization, this.files, this.entity)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('DATA_UPLOADED_SUCCESSFULLY')
        );
        this.closeModal.emit();
      })
      .catch(async (err) => {
        this.logService.error(
          'DataUploadModalComponent',
          'uploadFile',
          `${await this.translationsService.get('ERROR_IN_UPLOADING_DATA')}: ${err}`
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_UPLOADING_DATA')
        );
      });
  }

  fileUploaded(event) {
    this.files = event.target.files;
  }

  cancel() {
    this.files = null;
    this.entity = null;
    this.closeModal.emit();
  }
}
