import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { CONSTANT } from '../../app.constant';
import { TokenService } from '../../services/core/token.service';
import { UsersService } from '../../services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../services/core/log.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { SessionService } from '../../services/core/session.service';
import { LanguageCode, Language } from '../../models/language.model';
import { CustomerService } from '../../services/customer/customer.service';

import template from './profile.html';
import style from './profile.less';
import { NewFrontendNavigateService } from '../../services/new-frontend-navigate/new-frontend-navigate.service';

@Component({
  selector: 'profile',
  template: template,
  styles: [style]
})
export class ProfileComponent implements OnInit {
  @Input() currentUser: User;
  versionNumber: string = '';
  language: { selected: any } = { selected: '' };
  languages: Language[] = CONSTANT.LANGUAGES;
  selectedSetting: string = 'account';
  previousLanguage: string;
  accountSettingLabValid: boolean = true;

  constructor(
    private tokenService: TokenService,
    private userService: UsersService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private sessionService: SessionService,
    private logService: LogService,
    private customerService: CustomerService,
    private navigateService: NewFrontendNavigateService,
  ) {}

  ngOnInit(): void {
    if (this.currentUser.systemSetting && this.currentUser.systemSetting.language) {
      this.languages.forEach((language) => {
        if (language.value === this.currentUser.systemSetting.language) {
          this.language.selected = language;
        }
      });
    }
    if (this.currentUser && !this.language) {
      this.language.selected = this.languages[0];
    }
  }

  async saveChanges(event: { fileId: string; type: string }): Promise<void> {
    const self = this;
    if (!this.currentUser.systemSetting) {
      this.currentUser.systemSetting = {};
    }
    this.currentUser.id = this.tokenService.getPayload().user;
    if (event && event.fileId) {
      this.currentUser.profileImage = event.fileId;
    }
    return this.userService
      .editProfile(this.currentUser)
      .then(async (response) => {
        self.currentUser = response.data.user;
        if (event && event.type) {
          self.toastr.success(
            await self.translationsService.get('IMAGE_SUCCESSFULLY_UPLOADED')
          );
        } else {
          self.toastr.success(
            await self.translationsService.get('SUCCESSFULLY_UPDATED')
          );
        }
        if (self.currentUser.systemSetting.locale) {
          self.sessionService.setItem(
            'locale',
            self.currentUser.systemSetting.locale
          );
        }
        if (self.currentUser.systemSetting.language) {
          self.translationsService.use(self.currentUser.systemSetting.language);
          self.previousLanguage = self.sessionService.getItem('language');
          self.sessionService.setItem(
            'language',
            self.currentUser.systemSetting.language
          );
        } else {
          // Use default english
          self.translationsService.use(LanguageCode.SE);
          self.sessionService.setItem('language', 'se');
        }
      })
      .then((user) => {
        if (self.previousLanguage !== self.currentUser.systemSetting.language) {
          window.top.postMessage(
            {
              eventType: 'reload-page'
            },
            '*'
          );
        }
      })
      .catch(async (err) => {
        self.logService.error(
          'profile.component',
          'saveChanges',
          (await self.translationsService.get('ERROR_IN_UPDATE_USER')) + ': ' + err
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_IN_UPDATE_USER')
        );
      });
  }

  navigateToSystemSettings() {
    this.navigateService.navigate('/user-settings/system-settings');
  }
}
