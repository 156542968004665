import { Input, Component, forwardRef, Inject } from '@angular/core';
import { OrderService } from '../../../services/order-new/order-new.service';
import { GetAllOrdersService } from '../../../services/order-list/get-all-orders.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { Order } from '../../../models/order.model';
import { ORDER_VERSION } from '../../../app.constant';
import template from './accept-order-modal.html';

@Component({
  selector: 'accept-order-modal',
  template: template
})
export class AcceptOrderModalComponent {
  @Input() orderDetail: Order;

  constructor(
    private readonly orderService: OrderService,
    private readonly getAllOrdersService: GetAllOrdersService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService,
    @Inject(forwardRef(() => '$state')) private readonly $state: any
  ) {}

  changeOrderStatus(): Promise<void> {
    const statusObject = { ...this.orderDetail }; // Clone;
    statusObject.status = 'accepted_by_lab';
    statusObject.isStatusChanged = true;
    const isSimpleOrder = this.orderDetail.type === ORDER_VERSION.ORDER_1;
    statusObject.deliveryDetails.sample = statusObject.sample;
    statusObject.orderVersionType = this.orderDetail.type;
    const componentName = 'accept-order-modal.component';
    // Update status to accept for the order
    if (isSimpleOrder) {
      statusObject.orderId = this.$state.params.orderId;
      return this.orderService
        .updateOrder(statusObject)
        .then(async () => {
          this.getAllOrdersService.clearData(); //to clear cached orders list data
          this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
          const sendObject: any = {
            orderId: this.$state.params.orderId,
            message: 'accepted_by_lab',
            type: 'status'
          };
          // Add Message to the order messages.
          return this.orderService
            .addMessage(sendObject)
            .then(async () => {
              this.toastr.success(
                await this.translationsService.get('SUCCESSFULLY_UPDATED')
              );
              window.location.reload();
            })
            .catch(async (err) => {
              this.logService.error(
                componentName,
                'changeOrderStatus',
                `${await this.translationsService.get(
                  'ERROR_IN_ADD_MESSAGE'
                )}: ${err}`
              );
              this.toastr.error(
                await this.translationsService.get('ERROR_IN_ADD_MESSAGE')
              );
            });
        })
        .catch(async (err) => {
          this.logService.error(
            componentName,
            'changeOrderStatus 1',
            `${await this.translationsService.get('ERROR_IN_UPDATE_ORDER')}: ${err}`
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_UPDATE_ORDER')
          );
        });
    } else {
      statusObject.id = this.$state.params.orderId;
      return this.orderService
        .updateNewOrder(statusObject)
        .then(async () => {
          this.getAllOrdersService.clearData(); //to clear cached orders list data
          this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
          this.toastr.success(
            await this.translationsService.get('SUCCESSFULLY_UPDATED')
          );
          window.location.reload();
        })
        .catch(async (err) => {
          this.logService.error(
            componentName,
            'changeOrderStatus 2',
            `${await this.translationsService.get('ERROR_IN_UPDATE_ORDER')}: ${err}`
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_UPDATE_ORDER')
          );
        });
    }
  }
}
