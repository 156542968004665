import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { StateProvider, StateService } from '@uirouter/angularjs';
import { FederatedService } from '../../services/federated-login/federated.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import federatedLoginTemplate from './federated-login.html';

@Component({
  selector: 'federated-login',
  template: federatedLoginTemplate
})
export class FederatedLoginComponent implements OnInit {
  error: string;
  private window: any = window as any;

  static $inject: any = ['$state'];
  constructor(
    @Inject(forwardRef(() => '$state')) private $state: StateService,
    private federatedService: FederatedService,
    private logService: LogService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private currentUserService: CurrentUserService
  ) {}

  private setUser(user: any): void {
    console.log('SETTING FEDERATED USER', user);
  }

  ngOnInit(): void {
    const federation = this.$state.params.federation;
    const personalNumber = this.$state.params.personal_number;
    const returnTo = this.$state.params.return_to
      ? encodeURI(this.$state.params.return_to)
      : undefined;
    const returnParams = this.$state.params.return_params
      ? encodeURI(this.$state.params.return_params)
      : undefined;

    console.log('federated-login.component.ts ngOnInit');
    console.log(federation);
    console.log(personalNumber);
    console.log(returnTo);
    console.log(returnParams);

    this.federatedService
      .federatedLogin({
        federation: federation,
        personal_number: personalNumber,
        return_to: returnTo,
        return_params: returnParams
      })
      .then((response) => {
        console.log('federated-login.component.ts federatedService response');
        console.log(response);
        if (response.command === 'redirect') {
          this.window.location = response.redirectUrl;
        } else if (response.command === 'user') {
          this.setUser(response.user);
        }
      })
      .catch(async (err) => {
        this.logService.error(
          'federated-login.component',
          'federatedLogin',
          'FEDERATED ERROR - ' + JSON.stringify(err.message ? err.message : err)
        );
        this.currentUserService.logout();
        this.toastr.error(await this.translationsService.get('FEDERATED_ERROR'));
      });
  }
}
