import { Input, Component, OnChanges, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '../../../services/invoice-list/invoice.service';
import { Order } from '../../../models/order.model';
import { LogService } from '../../../services/core/log.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import template from './download-section.html';
import { PermissionService } from '../../../services/core/permission.service';
import { User } from '../../../models/user.model';
import { PrintService } from '../../../services/print/print.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'download-section',
  template: template
})
export class DownloadSectionComponent implements OnChanges, OnInit {
  @Input() orderDetail: Order;
  @Input() reactAppUrl: string;
  @Input() isExternalTechnician: boolean;
  @Input() currentUser: User;
  isShowInvoicePdf: boolean;
  showSpinner = {
    showDestLabelSpinner: false,
    showOrderSpinner: false,
    showPatientLabelSpinner: false,
    showPatientSpinner: false
  };
  currentDate = new Date().toLocaleDateString();

  constructor(
    private readonly invoiceService: InvoiceService,
    private readonly permissionService: PermissionService,
    private readonly toastr: ToastrService,
    private readonly spinnerService: NgxSpinnerService,
    private readonly logService: LogService,
    private readonly printService: PrintService,
    private readonly translationsService: TranslationsService
  ) {}

  ngOnInit(): void {
    this.showSpinner = {
      showDestLabelSpinner: false,
      showOrderSpinner: false,
      showPatientLabelSpinner: false,
      showPatientSpinner: false
    };
    window.addEventListener('message', this.handleEventsFromNewFE.bind(this));
  }

  ngOnChanges() {
    this.isShowInvoicePdf =
      this.orderDetail?.deliveryDetails?.price !== 0 &&
      !!this.orderDetail?.invoice?.pdfUrl &&
      (this.orderDetail?.invoice?.status === 'PROCESSING' ||
        this.orderDetail?.invoice?.status === 'SCHEDULED' ||
        this.orderDetail?.invoice?.status === 'SENT' ||
        this.orderDetail?.invoice?.status === 'CREATED');
  }

  handleEventsFromNewFE(e: any) {
    if (
      e.data &&
      e.origin === process.env.NEW_APP_URL &&
      e.data.eventType === 'hide-spinner'
    ) {
      this.spinnerService.hide();
      this.showSpinner = {
        showDestLabelSpinner: false,
        showOrderSpinner: false,
        showPatientLabelSpinner: false,
        showPatientSpinner: false
      };
    }
  }

  downloadInvoice() {
    this.invoiceService
      .invoicePdf(this.orderDetail.invoice._id)
      .then(async (result) => {
        window.top.postMessage({ eventType: 'open-url', url: result.data }, '*');
        this.toastr.success(
          await this.translationsService.get('INVOICE_DOWNLOADED_SUCCESSFULLY')
        );
      })
      .catch(async (err) => {
        this.logService.error('download-section.component', 'downloadPdf', err);
        this.toastr.error(
          await this.translationsService.get(
            'THE_PDF_INVOICE_COULD_NOT_BE_DOWNLOADED'
          )
        );
      });
  }

  printOrderMini() {
    this.printService.printElementFromID('OrderDetail2PrintableMiniArea');
  }

  printOrderPdf(type: string) {
    if (type === 'PATIENT_SPECIFICATION_PDF') {
      this.showSpinner['showPatientSpinner'] = true;
    } else {
      this.showSpinner['showOrderSpinner'] = true;
    }
    this.spinnerService.show();
    let fileName = '';
    if (this.permissionService.isLabTechnician(this.currentUser)) {
      fileName = `${this.orderDetail.orderId}_${this.orderDetail.patient.firstName} ${this.orderDetail.patient.lastName}_${this.currentDate}.pdf`;
    } else {
      fileName = `${this.translationsService.translate('PROSTHODONTICS')}_${
        this.orderDetail.patient.firstName
      } ${this.orderDetail.patient.lastName}_${this.currentDate}.pdf`;
    }
    window.top.postMessage(
      {
        eventType: 'pdf-print',
        fileName: fileName,
        type: type,
        orderId: this.orderDetail._id
      },
      '*'
    );
  }

  printPatientLabelPdf() {
    this.showSpinner['showPatientLabelSpinner'] = true;
    this.spinnerService.show();
    window.top.postMessage(
      {
        eventType: 'patient-label-pdf',
        orderId: this.orderDetail._id
      },
      '*'
    );
  }

  printDestinationAddressLabelPdf() {
    this.showSpinner['showDestLabelSpinner'] = true;
    this.spinnerService.show();
    window.top.postMessage(
      {
        eventType: 'destination-address-label-pdf',
        orderId: this.orderDetail._id
      },
      '*'
    );
  }

  async downloadDeliveryNote() {
    window.top.postMessage(
      { eventType: 'open-url', url: this.orderDetail.deliveryNotePdfSignedUrl },
      '*'
    );
    this.toastr.success(
      await this.translationsService.get('DELIVERY_NOTE_DOWNLOADED_SUCCESSFULLY')
    );
  }
}
