import { Input, Component } from '@angular/core';
import template from './error-modal.html';

@Component({
  selector: 'error-modal',
  template: template
})
export class ErrorModalComponent {
  @Input() errorTitle: string;
  @Input() errorDescription: string;
}
