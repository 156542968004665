import { Injectable } from '@angular/core';
import { LogService } from '../core/log.service';
import { DATE_FORMATS } from '../../app.constant';
import * as json2csv from 'json2csv';
import { FileSaverService } from 'ngx-filesaver';
import * as moment from 'moment';

@Injectable()
export class Json2CSVService {
  // FILE Constants
  private readonly MIME_TYPE = 'text/csv';
  private readonly ENCODING = 'charset=utf-8';
  private readonly FILE_TYPE = `${this.MIME_TYPE};${this.ENCODING}`;
  private readonly FILE_EXTENSION = '.csv';

  constructor(
    private readonly logService: LogService,
    private readonly fileSaverService: FileSaverService
  ) {}

  /**
   * convertJSON2CSV
   * Converts an array of JSON objects to a CSV string
   *
   * @param {Object[]} jsonData Array of JSON objects to be converted to CSV
   * @param {string[]} columns Array of strings representing the columns to extract
   *
   * @throws {Error} Will throw an error if the columns array is invalid
   *
   * @return {string} The CSV formated data as a string
   */
  convertJSON2CSV(jsonData, columns) {
    let csv;
    const opts = { fields: columns };

    try {
      csv = json2csv.parse(jsonData, opts);
    } catch (err) {
      this.logService.error('json-2-csv-service', 'convertJSON2CSV', err);
      throw err;
    }
    return csv;
  }

  /**
   * exportCSVToFile
   * Creates and saves a CSV file for the user
   *
   * @param {string} csvData The CSV data to be outputed
   * @param {string} fileName Name of file without filextension
   */
  exportCSVToFile(csvData, fileName) {
    const data = new Blob([csvData], { type: this.FILE_TYPE });
    const formatedDate = moment().format(DATE_FORMATS.DEFAULT);
    const finalFileName = `${fileName}-${formatedDate}${this.FILE_EXTENSION}`;

    window.top.postMessage(
      { eventType: 'file-download', filename: finalFileName, fileData: data },
      '*'
    );
  }
}
