import { Component, Input } from '@angular/core';
import template from './action-notes.html';

@Component({
  selector: 'action-notes',
  template
})
export class ActionNotesComponent {
  @Input() selectedActions: any;
}
