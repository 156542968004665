import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment';
import template from './ta-pickup-date.html';
import { Logistic } from '../../../../../models/logistic.model';
import { TaDatesService } from '../../../services/ta-dates.service';

@Component({
  selector: 'ta-pickup-date',
  template: template
})
export class TaPickupDateComponent implements OnInit {
  @Input() transportOrder: Partial<Logistic>;
  @Output() dateSelected = new EventEmitter<Moment>();
  pickupDateControl = new FormControl(moment());
  dropoffDate = moment();

  constructor(private readonly taDatesService: TaDatesService) {}

  ngOnInit() {
    this.pickupDateControl.setValue(this.calculateFirstValidPickupDate());
    this.onPickupDateControlChange(this.pickupDateControl.value);
    this.pickupDateControl.valueChanges.subscribe((selectedDate: Moment) => {
      this.onPickupDateControlChange(selectedDate);
    });
    this.dropoffDate = this.taDatesService.dropoffDateForPickup(
      moment(this.pickupDateControl.value)
    );
  }

  // Needs to be an arrow function to maintain scope inside the date picker.
  validPickupDateFilter = (date: Moment | null): boolean => {
    if (this.taDatesService.isTransportServiceAvailable(date || moment())) {
      return this.taDatesService.isPickupAllowed(date);
    }
    return false;
  };

  private onPickupDateControlChange(selectedDate: Moment) {
    if (this.pickupDateControl.errors === null) {
      this.dropoffDate = this.taDatesService.dropoffDateForPickup(selectedDate);
      this.transportOrder.transportDate = selectedDate.toDate();
      this.dateSelected.emit(selectedDate);
      return;
    }
    this.transportOrder.transportDate = null;
  }

  private calculateFirstValidPickupDate(): Moment {
    let nextValid = null;
    let daysCounter = 0;
    while (!nextValid) {
      const testDate = moment().add(daysCounter, 'days');
      if (
        this.taDatesService.isTransportServiceAvailable(testDate) &&
        this.taDatesService.isPickupAllowed(testDate)
      ) {
        nextValid = testDate;
      }
      daysCounter++;
    }
    return nextValid;
  }
}
