import { Input, Component } from '@angular/core';
import { Organization } from '../../../models/organization.model';
import template from './contact-info-modal.html';

@Component({
  selector: 'contact-info-modal',
  template: template
})
export class ContactInfoModalComponent {
  @Input() organization: Partial<Organization> = {};
}
