import { Input, Component, EventEmitter, Output } from '@angular/core';
import template from './added-actions.html';

@Component({
  selector: 'added-actions',
  template: template
})
export class AddedActionsComponent {
  @Input() addedActions: any = [];
  @Input() isVisibleInPrint: boolean = false;
  @Input() showEditBtn: boolean = false;
  @Input() currency: string;
  @Output() openEditActionDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() openConfirmDeleteActionDialog: EventEmitter<string> = new EventEmitter<
    string
  >();

  openEditDialog(addedAction: any) {
    if (this.openEditActionDialog) {
      this.openEditActionDialog.emit(addedAction);
    }
  }
  openDeleteActionDialog(addedActionId: string) {
    if (this.openConfirmDeleteActionDialog) {
      this.openConfirmDeleteActionDialog.emit(addedActionId);
    }
  }
}
