import {
  Component,
  forwardRef,
  Inject,
  OnChanges,
  OnInit,
  Renderer2
} from '@angular/core';
import { User } from '../../models/user.model';
import { Language } from '../../models/language.model';
import { CookieService } from 'ngx-cookie-service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { UsersService } from '../../services/users/users.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { EventService } from '../../services/core/event.service';
import { CacheService } from '../../services/core/cache.service';
import { PermissionService } from '../../services/core/permission.service';
import { NewOrderListRedirectService } from '../../services/new-order-list-redirect/new-order-list-redirect.service';
import { SessionService } from '../../services/core/session.service';
import { Organization } from '../../models/organization.model';
import template from './login.html';
import style from './login.less';
import { OrganizationSettingService } from '../../services/organization-setting/organization-setting.service';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';

@Component({
  selector: 'login',
  template: template,
  styles: [style]
})
export class LoginComponent implements OnInit {
  //private window: any = window as any;
  public email: string;
  public password: string;
  isFramed = false;
  labId: string;
  lab: Organization;
  reactAppUrl = process.env.NEW_APP_URL;
  denthubPublicUrl = process.env.DENTHUB_PUBLIC_URL;
  errorTitle: string;
  errorDescription: string;

  constructor(
    public readonly cacheService: CacheService,
    private readonly settingService: OrganizationSettingService,
    private readonly cookieService: CookieService,
    private readonly logService: LogService,
    private readonly organizationService: OrganizationService,
    private readonly toastr: ToastrService,
    private readonly translationsService: TranslationsService,
    private readonly usersService: UsersService,
    private readonly eventService: EventService,
    private readonly currentUserService: CurrentUserService,
    private readonly permissionService: PermissionService,
    private readonly newOrderListRedirectService: NewOrderListRedirectService,
    private sessionService: SessionService,
    private readonly renderer: Renderer2,
    private readonly window: Window,
    @Inject(forwardRef(() => '$state')) private $state: any,
    @Inject(forwardRef(() => '$location')) private $location: any,
    @Inject(forwardRef(() => '$stateParams')) private $stateParams: any
  ) {
    try {
      if (this.window.top != this.window) {
        /* break us out of any containing iframes */
        currentUserService.logout();
        this.window.top.location = this.window.location;
        return;
      }
      const payloadCookie = this.cookieService.get('federated-payload');
      if (payloadCookie) {
        const error = JSON.parse(payloadCookie).error;
        if (error) {
          this.translationsService.get('FEDERATED_ERROR').then((errorMessage) => {
            this.toastr.error(errorMessage);
          });
          this.logService.error(
            'login.component',
            'constructor',
            'Error logging in! - ' + JSON.stringify(error)
          );
        }
      }
      this.cookieService.delete('federated-payload');
    } catch (e) {
      console.error(
        'Error parsing federated payload cookie',
        this.cookieService.get('federated-payload')
      );
    }
  }

  ngOnInit() {
    this.labId = this.$stateParams.labId;
    if (this.labId) {
      this.isFramed = this.$stateParams.framed === 'true';
      this.getLabLogoImage();
      if (this.isFramed) {
        this.renderer.addClass(document.body, 'framed-limited-dentist-form');
      }
    }
    this.sessionService.clearLocalStorage();
  }

  getLabLogoImage() {
    this.organizationService
      .getLabLogo({
        labId: this.labId
      })
      .then((result) => {
        this.lab = result.data;
      })
      .catch((err) => {
        this.logService.error('getLabLogoImage', 'ERROR_IN_GETTING_LABS', err);
      });
  }

  // function to check the user has some connected labs or not
  checkConnectedLabs(labId?: string): void {
    const self = this;
    self.newOrderListRedirectService.newOrderList(labId);
  }

  checkIfWhiteLabelEnabled(user: User) {
    const lab = user.organization?.connectedLabs?.find(
      (lab) => lab.lab?._id === this.labId
    );
    if (!lab) {
      return false;
    }
    const isWhiteLabelEnabled = this.settingService.getFromCustomerSettings(
      lab.lab.customerSettings,
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.WHITE_LABEL
    );
    return isWhiteLabelEnabled === 'true' || isWhiteLabelEnabled === true;
  }

  userLogin(): void {
    const self = this;
    self.usersService
      .login(self.email, self.password)
      .then((response) => {
        return self.currentUserService.setUserData(response.user);
      })
      .then(async (user) => {
        if (
          user.organization.type === 'clinic' &&
          user.organization?.isWhiteLabeled
        ) {
          if (!this.labId) {
            self.logService.newError(
              new Error('Please use the login page you received from your lab')
            );
            this.errorDescription = await self.translationsService.get(
              'USE_LAB_LOGIN'
            );
            this.errorTitle = await self.translationsService.get('ERROR_TITLE');
            $('#error-modal').modal('show');
            return null;
          }
          const isWhiteLabelEnabled = this.checkIfWhiteLabelEnabled(user);
          if (!isWhiteLabelEnabled) {
            const text = await self.translationsService.get('DISABLED_WHITE_LABEL');
            self.logService.newError(new Error(text));
            this.errorDescription = text;
            this.errorTitle = await self.translationsService.get('ERROR_TITLE');
            $('#error-modal').modal('show');
            return null;
          } else {
            localStorage.setItem('wl_lab', this.labId);
          }
        }
        const parameters = self.$location.search();
        if (parameters.return_to && !parameters.return_to.includes('login')) {
          return self.$location
            .path(parameters.return_to)
            .search(JSON.parse(decodeURI(parameters.return_params)));
        } else if (self.permissionService.isSuperAdmin(user)) {
          const reactAppUrl = process.env.NEW_APP_URL;
          const url = `${reactAppUrl}/admin-dentists`;
          window.location.href = url;
        } else if (
          user.isAdmin &&
          user.type &&
          self.permissionService.isDentist(user)
        ) {
          self.checkConnectedLabs(this.labId);
        } else if (self.permissionService.isCarrier(user)) {
          const reactAppUrl = process.env.NEW_APP_URL;
          const url = `${reactAppUrl}/admin-transport/transport-orders`;
          window.location.href = url;
        } else {
          self.newOrderListRedirectService.newOrderList(this.labId);
        }
      })
      .then((user) => {
        if (!user) {
          return;
        }
        if (this.window.top != this.window) {
          /* break us out of any containing iframes */
          this.window.top.location.replace(`${this.reactAppUrl}/orders/list`);
          return;
        }
        return self.eventService.emit('login');
      })
      .catch(async (err) => {
        self.logService.newError(err);
        self.toastr.error(
          await self.translationsService.get('EMAIL_AND_PASSWORD_NOT_MATCHED')
        );
      });
  }

  goTo(state: string, params: any): void {
    console.log(params);
    this.$state.go(state, params);
  }

  federatedLogin(): void {
    const params = {
      federation: 'siths',
      return_to: this.$location.search().return_to,
      return_params: this.$location.search().return_params
    };
    console.log('login.component.ts federatedLogin()');
    console.log(params);
    this.$state.go('federated-login', params);
  }

  bankIdLogin(): void {
    const params = {
      return_to: this.$location.search().return_to,
      return_params: this.$location.search().return_params
    };
    this.$state.go('bankid', params);
  }

  setLanguage(lang: Language): void {
    this.translationsService.use(lang.value);
  }
}
