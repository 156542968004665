/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './lab-details.html'
/**
 * Connect lab module
 */
(function () {
    'use strict';
    angular.module('app').component('labDetails', {
        template: theTemplate,
        controller: LabDetailsController,
        controllerAs: 'vm',
        bindings: sessionStateBindings
    });

    // Inject connect lab dependencies here
    LabDetailsController.$inject = [ '$state', '$filter', 'organizationService', 'toastr', 'subNavService', 'logService' ];

    // Controller function fo connect lab module.
    function LabDetailsController($state, $filter, organizationService, toastr, subNavService, logService) {

        // scope and other variables
        var vm = this;

        // Member functions
        vm.connectLab = connectLab;
        vm.disconnectLab = disconnectLab;
        vm.addSubNavMenus = addSubNavMenus;

        // Init Method
        vm.$onInit = function() {
            getConnectedLabs();
        };

        /**
         * Method to add menus to sub nav bar
         */
        function addSubNavMenus() {
            subNavService.resetList();
            vm.menus = [
                {
                    name: $filter('translate')('LAB_PROFILE'),
                    state: 'app.show-lab',
                    labName: vm.lab.name,
                    icon: 'fa-angle-left',
                    isActive: true,
                    labProfile: true,
                    isDentistOrderDetails: true
                }
            ];

            for (var menu = 0; menu < vm.menus.length; menu++) {
                subNavService.addMenu({
                    name: vm.menus[menu].name,
                    icon: vm.menus[menu].icon || '',
                    state: vm.menus[menu].state || '',
                    labName: vm.menus[menu].labName || '',
                    labProfile: vm.menus[menu].labProfile || '',
                    isDentistOrderDetails: vm.menus[menu].isDentistOrderDetails
                });
            }

        }
        // Get Lab Details function
        function getLabDetails(id) {
            organizationService.getOrganization({ id: id }).then(function (result) {
                vm.lab = result.data;
                vm.lab.connected = false;
                if (!vm.lab.staffImage && !vm.lab.signedStaffUrl) {
                    vm.lab.staffImage = true;
                    vm.lab.signedStaffUrl = 'images/lab-doctor-img.jpg';
                }
                // If current user have connected labs then check the current lab is available in all labs or not
                if (vm.userOrganization && vm.userOrganization.connectedLabs && vm.userOrganization.connectedLabs.length) {
                    vm.lab.connected = vm.userOrganization.connectedLabs.some(function (connectedLab) {
                        return connectedLab.lab && connectedLab.lab._id + '' === vm.lab._id + '';
                    });
                }
                addSubNavMenus();
            }).catch(function (err) {
                logService.error('lab-details.component', 'getLabDetails', $filter('translate')('ERROR_IN_GETTING_LAB_DETAILS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_LAB_DETAILS'));
            });
        }

        // Get user organization details for connected labs --->
        function getConnectedLabs() {
            organizationService.getConnectedLabsForClinic({ organizationId: vm.currentUser.organization._id }).then(function (result) {
                vm.userOrganization = result.data;
                if ($state.params.labId) {
                    getLabDetails($state.params.labId);
                }
            }).catch(function (err) {
                logService.error('lab-details.component', 'getConnectedLabs', $filter('translate')('ERROR_IN_GETTING_LABS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_LABS'));
            });
        }

        // Connect to lab method
        function connectLab() {
            organizationService.connectLab({ labId: vm.lab._id }).then(function (result) {
                toastr.success($filter('translate')('LAB_CONNECTED'));
                getConnectedLabs();
            }).catch(function (err) {
                logService.error('lab-details.component', 'connectLab', $filter('translate')('ERROR_IN_CONNECT_LAB') + ": " + JSON.stringify(err));
                toastr.error($filter('translate')('ERROR_IN_CONNECT_LAB'));
            });
        }

        // disconnect to lab method
        function disconnectLab() {
            organizationService.disconnectLab({ labId: vm.lab._id }).then(function (result) {
                toastr.success($filter('translate')('LAB_DIS_CONNECTED'));
                getConnectedLabs();
            }).catch(function (err) {
                logService.error('lab-details.component', 'disconnectLab', $filter('translate')('ERROR_IN_DISCONNECT_LAB') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_DISCONNECT_LAB'));
            });
        }
    }

})();
