import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { DATE_FORMATS } from '../app.constant';
import { SessionService } from '../services/core/session.service';

@Pipe({
  name: 'transportDateFilter'
})
export class TransportDatePipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}
  transform(date: any, format:string) {
    if (!date) {
      return date;
    }
    const transportDate  = new Date(date).setHours(0, 0, 0, 0);
    const todayMilliseconds = new Date().setHours(0, 0, 0, 0);
    let tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
    let yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
    const tomorrowMilliseconds = new Date(tomorrow).setHours(0, 0, 0, 0);
    const yesterdayMilliseconds = new Date(yesterday).setHours(0, 0, 0, 0);
    if (transportDate === todayMilliseconds) {
      return 'TODAY';
    }
    if (transportDate === tomorrowMilliseconds) {
      return 'TOMORROW';
    }
    if (transportDate === yesterdayMilliseconds) {
      return 'YESTERDAY';
    }
    let locale = this.sessionService.getItem('locale');
    if (!locale) {
      locale = 'sv-SE';
    }
    moment.locale(locale);
    const shotWeekDay = moment(date).format('ddd');
    return `${ shotWeekDay }, ${ moment(date).format(DATE_FORMATS[format]) }`;
  }
}
