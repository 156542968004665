/**
 * Order new delivery service
 */
(function () {
    'use strict';
    angular.module('app').service('orderNewDeliveryService', orderNewDeliveryService);
    orderNewDeliveryService.$inject = [ '$q', 'DATE', '$filter' ];

    function orderNewDeliveryService($q, DATE, $filter) {
        return {
            updatePerfectDeliveryDate: updatePerfectDeliveryDate
        };

        function checkPerfectDeliveryDateIsHoliday(day, holidayDays, inActiveDays) {
            var delDay = day;
            return (holidayDays.indexOf(delDay) !== -1 || inActiveDays.indexOf(new Date(delDay).getDay()) !== -1) ? checkPerfectDeliveryDateIsHoliday(delDay + 86400000, holidayDays, inActiveDays) : delDay;
        }

        function countDays(daysRequired, holidayDays, inActiveDays) {
            var startDay = new Date();
            startDay = startDay.setHours(0, 0, 0, 0);
            var days = daysRequired;
            while (days > 0) {
                if (holidayDays.indexOf(startDay) === -1 && inActiveDays.indexOf(new Date(startDay).getDay()) === -1) {
                    days--;
                }
                startDay += 86400000;
            }
            return checkPerfectDeliveryDateIsHoliday(startDay, holidayDays, inActiveDays);
        }

        function updatePerfectDeliveryDate(currentSelectedLab, newOrderObj, deliveryInfo, additionalDeliveryDate) {
            var deferred = $q.defer();
            if (currentSelectedLab) {
                var constructions = [], daysForDelivery = 0;
                var perfectDeliveryDate = new Date();
                if (newOrderObj.selectedConstructions.fixedConstructions.singleCrowns.length) {
                    constructions.push({ type: "single_crown" });
                }
                if (newOrderObj.selectedConstructions.fixedConstructions.bridges.length) {
                    constructions.push({ type: "bridge" });
                }
                if (newOrderObj.selectedConstructions.fixedConstructions.implantBridges.length) {
                    constructions.push({ type: "implant_bridge" });
                }
                if (newOrderObj.selectedConstructions.fixedConstructions.implantCrowns.length) {
                    constructions.push({ type: "implant_crown" });
                }
                if (newOrderObj.selectedConstructions.fixedConstructions.posts.length) {
                    constructions.push({ type: "post" });
                }
                if (newOrderObj.selectedConstructions.removableConstructions.completeDentures.length) {
                    constructions.push({ type: "complete_denture" });
                }
                if (newOrderObj.selectedConstructions.removableConstructions.partialDentures.length) {
                    constructions.push({ type: "partial_denture" });
                }
                if (newOrderObj.selectedConstructions.removableConstructions.temporaryDentures.length) {
                    constructions.push({ type: "temporary_denture" });
                }
                if (newOrderObj.selectedConstructions.removableConstructions.bitetrays.length) {
                    constructions.push({ type: "bitetray" });
                }

                for (var i in currentSelectedLab.currentJobs) {
                    for (var j in constructions) {
                        if (currentSelectedLab.currentJobs[i].active) {
                            if (constructions[j].type === currentSelectedLab.currentJobs[i].name) {
                                constructions[j].day = currentSelectedLab.currentJobs[i].deliveryDays;
                            }
                        }

                    }
                }
                for (var k in constructions) {
                    if (constructions[k].day > daysForDelivery) {
                        daysForDelivery = constructions[k].day;
                    }
                }

                if (additionalDeliveryDate) {
                    daysForDelivery += additionalDeliveryDate;
                }

                // inactive days in a week
                var inActiveDays = [];
                for (var h in currentSelectedLab.openingHours) {
                    if (!currentSelectedLab.openingHours[h].active) {
                        var day = parseInt(h) + 1;
                        day = (day === 7 ? 0 : day);
                        inActiveDays.push(day);
                    }
                }

                if (!currentSelectedLab.holidayDays) {
                    currentSelectedLab.holidayDays = [];
                }

                var holidays = currentSelectedLab.holidayDays.concat(currentSelectedLab.unavailableLabDates || []);

                perfectDeliveryDate = countDays(daysForDelivery + 1, holidays, inActiveDays);
                deliveryInfo.date = $filter('date')(perfectDeliveryDate, DATE.FORMAT);
                var data = {
                    deliveryInfo: deliveryInfo,
                    newOrderObj: newOrderObj,
                    perfectDeliveryDate: perfectDeliveryDate
                };
                deferred.resolve(data);

            }
            return deferred.promise;
        }

    }
})();
