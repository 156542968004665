import {
  AfterViewInit,
  Component,
  forwardRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OrderService } from '../../services/order-new/order-new.service';
import { PrintService } from '../../services/print/print.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import template from './order-confirm.html';
import { sortBy, isEmpty } from 'lodash';
import {
  Order,
  SimpleConstructions,
  SignedUrl
} from '../../models/order.model';

@Component({
  selector: 'order-confirm',
  template: template
})
export class OrderConfirmComponent implements OnInit, AfterViewInit {
  @ViewChild('spinner') spinner: SpinnerComponent;

  orderDetail: Order;
  signedUrls: SignedUrl[] = [];
  // TODO: This needs typing, but unsure which is the correct typing for these
  constructions: any[] = [];
  fixedConstructions: any[] = [];
  removableConstructions: any[] = [];
  order: {
    orderNumber: string;
    patientName: string;
    personalIdentityNumber: string;
    constructions: any;
  };

  constructor(
    @Inject(forwardRef(() => '$state')) private $state: any,
    private orderService: OrderService,
    private logService: LogService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private printService: PrintService
  ) {}

  async ngOnInit(): Promise<void> {
    this.order = {
      orderNumber: '',
      patientName: '',
      personalIdentityNumber: '',
      constructions: []
    };
    await this.getOrder(this.$state.params.orderId);
  }

  ngAfterViewInit(): void {
    this.spinner.show();
  }

  filterConstructions(): void {
    const allowedFixedConstructionTypes = [
      'single crown',
      'bridge',
      'implant bridge',
      'implant crown',
      'post',
      'other'
    ];

    this.fixedConstructions = this.constructions.filter(
      (construction) =>
        allowedFixedConstructionTypes.indexOf(construction.type) > -1
    );
    this.fixedConstructions = sortBy(this.fixedConstructions, 'teethNo');

    const allowedRemovableConstructionTypes = [
      'partial denture',
      'complete denture',
      'bitetray',
      'temporary denture',
      'other removable'
    ];

    this.removableConstructions = this.constructions.filter(
      (construction) =>
        allowedRemovableConstructionTypes.indexOf(construction.type) > -1
    );
    this.removableConstructions = sortBy(
      this.removableConstructions,
      'teethNo'
    );
  }

  getOrder(orderId: string): Promise<void> {
    return this.orderService
      .getOrderById(orderId)
      .then((result) => {
        this.orderDetail = result.data;
        this.signedUrls =
          this.orderDetail.signedUrl &&
          this.orderDetail.signedUrl.filter(
            (image) =>
              image &&
              image.contentType &&
              image.contentType.split('/')[0] === 'image'
          );
        this.order = {
          orderNumber: result.data.orderId,
          patientName:
            result.data.patient.firstName + ' ' + result.data.patient.lastName,
          personalIdentityNumber: result.data.patient.personalIdentityNumber,
          constructions: []
        };

        // Check the constructions and teeth
        if (result.data.constructions.abutmentTeeth.singleCrowns.length) {
          this.pushConstruction(
            result.data.constructions.abutmentTeeth.singleCrowns
          );
          this.pushToConstruction(
            'single_crown',
            result.data.constructions.abutmentTeeth.singleCrowns,
            true
          );
        }
        if (result.data.constructions.abutmentTeeth.bridges.length) {
          this.pushConstruction(
            result.data.constructions.abutmentTeeth.bridges
          );
          this.pushToConstruction(
            'bridge',
            result.data.constructions.abutmentTeeth.bridges,
            false
          );
        }
        if (result.data.constructions.abutmentTeeth.implantBridges.length) {
          this.pushConstruction(
            result.data.constructions.abutmentTeeth.implantBridges
          );
          this.pushToConstruction(
            'implant_bridge',
            result.data.constructions.abutmentTeeth.implantBridges,
            false
          );
        }
        if (result.data.constructions.abutmentTeeth.implantCrowns.length) {
          this.pushConstruction(
            result.data.constructions.abutmentTeeth.implantCrowns
          );
          this.pushToConstruction(
            'implant_crown',
            result.data.constructions.abutmentTeeth.implantCrowns,
            true
          );
        }
        if (result.data.constructions.abutmentTeeth.posts.length) {
          this.pushConstruction(result.data.constructions.abutmentTeeth.posts);
          this.pushToConstruction(
            'post',
            result.data.constructions.abutmentTeeth.posts,
            true
          );
        }
        if (result.data.constructions.abutmentTeeth.others.length) {
          this.pushConstruction(result.data.constructions.abutmentTeeth.others);
          this.pushToConstruction(
            'other',
            result.data.constructions.abutmentTeeth.others,
            true
          );
        }
        if (result.data.constructions.abutmentTeeth.otherRemovables.length) {
          this.pushConstruction(
            result.data.constructions.abutmentTeeth.otherRemovables
          );
          this.pushToConstruction(
            'other',
            result.data.constructions.abutmentTeeth.otherRemovables,
            true
          );
        }
        if (result.data.constructions.abutmentTeeth.bitetrays.length) {
          this.pushConstruction(
            result.data.constructions.abutmentTeeth.bitetrays
          );
          this.pushToConstruction(
            'bitetray',
            result.data.constructions.abutmentTeeth.bitetrays,
            false
          );
        }
        if (result.data.constructions.abutmentTeeth.completeDentures.length) {
          this.pushConstruction(
            result.data.constructions.abutmentTeeth.completeDentures
          );
          this.pushToConstruction(
            'complete_denture',
            result.data.constructions.abutmentTeeth.completeDentures,
            false
          );
        }
        if (result.data.constructions.abutmentTeeth.partialDentures.length) {
          this.pushConstruction(
            result.data.constructions.abutmentTeeth.partialDentures
          );
          this.pushToConstruction(
            'partial_denture',
            result.data.constructions.abutmentTeeth.partialDentures,
            true
          );
        }
        if (result.data.constructions.abutmentTeeth.temporaryDentures.length) {
          this.pushConstruction(
            result.data.constructions.abutmentTeeth.temporaryDentures
          );
          this.pushToConstruction(
            'temporary_denture',
            result.data.constructions.abutmentTeeth.temporaryDentures,
            true
          );
        }
        this.filterConstructions();
        this.spinner.hide();
      })
      .catch(async (err: any) => {
        this.spinner.hide();
        this.logService.error(
          'order-confirm.component',
          'getOrderById',
          (await this.translationsService.get('ERROR_IN_GETTING_ORDER')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_ORDER')
        );
      });
  }

  async printOrder(id: string): Promise<void> {
    if (isEmpty(this.orderDetail)) {
      return;
    }
    await this.printService.printElementFromID(id);
  }

  pushToConstruction(
    type: string,
    constructions: SimpleConstructions,
    isSingleToothType: boolean
  ): void {
    for (const constructionKey in constructions) {
      if (constructions.hasOwnProperty(constructionKey)) {
        if (isSingleToothType) {
          this.updateOrderConstructions(
            constructions[constructionKey].teethNo,
            type
          );
        } else {
          this.updateOrderConstructions(
            constructions[constructionKey].teethRange.start +
              '-' +
              constructions[constructionKey].teethRange.end,
            type
          );
        }
      }
    }
  }

  updateOrderConstructions(teeth: string, type: string): void {
    // If there is no element then push the first element direct
    if (!this.order.constructions.length) {
      this.order.constructions.push({ teeth: teeth, type: type });
      return;
    }
    // If there are many elements then check the construction type
    // if match then add with the previous construction with a comma separated
    let notFound = true;
    this.order.constructions
      .filter((construction) => construction.type === type)
      .forEach((construction) => {
        notFound = false;
        construction.teeth = construction.teeth + ',' + teeth;
      });

    if (notFound) {
      this.order.constructions.push({ teeth: teeth, type: type });
    }
  }

  checkFixedConstructions(): boolean {
    if (
      !(
        this.orderDetail &&
        this.orderDetail.constructions &&
        this.orderDetail.constructions.abutmentTeeth
      )
    ) {
      return false;
    }
    return (
      this.orderDetail.constructions.abutmentTeeth.singleCrowns.length ||
      this.orderDetail.constructions.abutmentTeeth.bridges.length ||
      this.orderDetail.constructions.abutmentTeeth.implantBridges.length ||
      this.orderDetail.constructions.abutmentTeeth.implantCrowns.length ||
      this.orderDetail.constructions.abutmentTeeth.posts.length ||
      this.orderDetail.constructions.abutmentTeeth.others.length
    );
  }

  checkRemovableConstructions(): boolean {
    if (!(this.orderDetail && this.orderDetail.constructions)) {
      return false;
    }
    return (
      this.orderDetail.constructions.abutmentTeeth.partialDentures.length ||
      this.orderDetail.constructions.abutmentTeeth.completeDentures.length ||
      this.orderDetail.constructions.abutmentTeeth.bitetrays.length ||
      this.orderDetail.constructions.abutmentTeeth.temporaryDentures.length ||
      this.orderDetail.constructions.abutmentTeeth.otherRemovables.length
    );
  }

  pushConstruction(constructionArray: any): void {
    this.constructions = this.constructions.concat(constructionArray);
  }

  orderSummaryPdf() {
    window.top.postMessage(
      { eventType: 'ORDER_SUMMARY_PDF', orderData: this.orderDetail },
      '*'
    );
  }
}
