import { Injectable } from '@angular/core';
import { DATE_FORMATS } from '../../app.constant';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

@Injectable()
export class Json2ExcelService {
  constructor() {}

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${moment().format(DATE_FORMATS.DEFAULT)}.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = {
        Sheets: { data: worksheet },
        SheetNames: ['data']
      };
      const data = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
      window.top.postMessage(
        {
          eventType: 'file-download',
          filename: Json2ExcelService.toExportFileName(excelFileName),
          fileData: new Blob([data])
        },
        '*'
      );
    } catch (e) {
      console.error(e);
    }
  }
}
