import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import template from './edit-transport-dialog.html';
import { Logistic } from '../../../../../models/logistic.model';
import { Organization } from '../../../../../models/organization.model';
import { TaDatesService } from '../../../services/ta-dates.service';
import * as moment from 'moment';
import { TranslationsService } from '../../../../../services/translations/translations.service';

@Component({
  selector: 'edit-transport-dialog',
  template: template
})
export class EditTransportDialogComponent implements OnInit {
  transportOrder: Logistic;
  pickupOrganization: Organization;
  dropoffOrganization: Organization;
  allowEdit: boolean;
  pickupDate = new Date();
  dropoffDate = new Date();
  noOfPackages: number;
  comments: string;
  deadline: string;
  internalMessage: string;
  isDenthubOrder = false;

  constructor(
    public dialogRef: MatDialogRef<EditTransportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditTransportDialogData,
    private readonly translationsService: TranslationsService,
    private readonly taDatesService: TaDatesService
  ) {}

  ngOnInit(): void {
    this.transportOrder = this.data.transportOrder;
    this.initTemplateData();
  }

  onSave() {
    this.transportOrder.comments = this.comments;
    this.transportOrder.internalMessage = this.internalMessage;
    this.transportOrder.deadline = this.deadline;
    if (this.isDenthubOrder) {
      this.transportOrder.additionalPackages = this.noOfPackages;
      this.transportOrder.noOfRequestedPackages =
        this.transportOrder.orders.length + this.noOfPackages;
    } else {
      this.transportOrder.noOfRequestedPackages = this.noOfPackages;
    }
    this.dialogRef.close({ action: 'SAVE', transportOrder: this.transportOrder });
  }

  async onRevoke() {
    if (confirm(await this.translationsService.get('ARE_YOU_SURE_CANCEL'))) {
      this.dialogRef.close({
        action: 'REVOKE',
        transportOrder: this.transportOrder
      });
    }
  }

  isReadonly(): boolean {
    return !this.allowEdit || this.transportOrder.status !== 'PLANNED';
  }

  private initTemplateData() {
    this.isDenthubOrder = this.transportOrder.orders?.length > 0;
    this.allowEdit = this.data.allowEdit;
    this.pickupOrganization =
      this.data.type === 'PICKUP' ? this.data.clinic : this.data.lab;
    this.dropoffOrganization =
      this.data.type === 'PICKUP' ? this.data.lab : this.data.clinic;
    this.pickupDate = new Date(this.transportOrder.transportDay);
    this.dropoffDate = this.taDatesService
      .dropoffDateForPickup(moment(this.pickupDate))
      .toDate();
    this.comments = this.transportOrder.comments;
    this.deadline = this.transportOrder.deadline;
    this.internalMessage = this.transportOrder.internalMessage;
    if (this.isDenthubOrder) {
      // TODO: This logic should be in a service, both here and on the logisitcs page
      this.noOfPackages =
        this.transportOrder.noOfRequestedPackages -
        this.transportOrder.orders.length;
    } else {
      this.noOfPackages = this.transportOrder.noOfRequestedPackages;
    }
  }

  redirectToOrderDetailsPage(orderId) {
    const reactAppUrl = process.env.NEW_APP_URL;
    window.location.href = `${reactAppUrl}/order-details/${orderId}/main-view`;
  }
}

export type EditTransportDialogData = {
  transportOrder: Logistic;
  clinic: Organization;
  lab: Organization;
  allowEdit: boolean;
  type: 'PICKUP' | 'DELIVERY';
};

export type EditTransportDialogResult = {
  transportOrder: Logistic;
  action: string;
};
