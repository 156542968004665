import { Component, Input, OnInit } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../services/core/log.service';
import { PermissionService } from '../../services/core/permission.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { SessionService } from '../../services/core/session.service';
import { UserRoleService } from '../../services/roles/user-role.service';
import { EventService } from '../../services/core/event.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { User } from '../../models/user.model';
import { NgxSpinnerService } from 'ngx-spinner';

import template from './lab-technician.html';
import { Role } from '../../models/roles.model';

@Component({
  selector: 'lab-technician',
  template: template
})
export class LabTechnicianComponent implements OnInit {
  @Input() currentUser: User;
  labTechnicians: User[] = [];
  labTechnician: any = {};
  isSuperAdmin: boolean;
  selectedLabIds: string[] = [];
  roles: Role[] = [];
  impersonatedUser: any;
  constructor(
    private userService: UsersService,
    private permissionService: PermissionService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private subNavService: SubNavService,
    private sessionService: SessionService,
    private userRoleService: UserRoleService,
    private currentUserService: CurrentUserService,
    private eventService: EventService,
    private logService: LogService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.addMenu();
    this.isSuperAdmin = this.permissionService.isSuperAdmin(this.currentUser);
    this.impersonatedUser = this.currentUser;
    this.setUserRolesExcept('DENTIST');
    this.eventService.on('user', () => {
      this.currentUserService
        .getUserData()
        .then((result: User) => {
          if (result) {
            this.impersonatedUser = result;
            this.isSuperAdmin = this.permissionService.isSuperAdmin(result);
            this.updateLabTechnicians();
          }
        })
        .catch((err) => {
          this.currentUserService.logout();
          this.logService.error(
            'lab-technician.component',
            'user event on',
            err
          );
        });
    });
  }

  async addMenu(): Promise<void> {
    this.subNavService.addMenu({
      name: await this.translationsService.get('LIST_VIEW'),
      state: 'app.lab-technician',
      icon: 'fa-list-ul',
      isActive: true,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('CARD_VIEW'),
      state: 'app.lab-technician-card',
      icon: 'fa-th',
      isActive: false,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('ADD_LAB_TECHNICIAN'),
      icon: 'fa-plus',
      action: 'add-lab-technician-modal',
      isModel: true,
      isClass: true
    });
  }

  onFilterUpdate(selectedLabIds: string[]): void {
    this.selectedLabIds = selectedLabIds;
    if (this.selectedLabIds) {
      this.sessionService.setItem(
        'selectedLabIds',
        JSON.stringify(this.selectedLabIds)
      );
    }
    this.spinner.show();
    this.getAllUsersForSuperAdmin();
  }

  updateLabTechnicians(): void {
    if (this.impersonatedUser && this.impersonatedUser.organization) {
      this.userService
        .getAllUsers({ organizationId: this.impersonatedUser.organization._id })
        .then((result) => {
          this.spinner.hide();
          if (result.data) {
            this.labTechnicians = result.data.users;
          }
        });
    }
  }

  // TODO: Rename this method. It is not a getter.
  getAllUsersForSuperAdmin(): void {
    if (this.currentUser) {
      const labIds = JSON.parse(this.sessionService.getItem('selectedLabIds'));
      if (labIds && labIds.length > 0) {
        this.selectedLabIds = labIds;
      }
      this.userService
        .getAllUsersForSuperAdmin({
          type: 'lab_technician',
          labIds: this.selectedLabIds
        })
        .then((result) => {
          this.spinner.hide();
          this.labTechnicians = result.data;
        });
    }
  }

  /**
   * On technician change
   * @param labTechnician
   */
  onLabTechnicianUpdate(labTechnician: User): void {
    if (labTechnician) {
      this.labTechnician = labTechnician;
      this.labTechnician.email = labTechnician.email;
      this.labTechnician.lab = labTechnician.organization._id;
    }

    $('#add-lab-technician-modal').modal('show');
  }

  // TODO: Rename this method. It is not a getter.
  getUsers(): void {
    if (this.isSuperAdmin) {
      this.getAllUsersForSuperAdmin();
    } else {
      this.updateLabTechnicians();
    }
  }

  // on change
  onLabTechnicianChange(): void {
    this.labTechnicians = [];
    this.getUsers();
  }

  setUserRolesExcept(roleType: string): void {
    this.userRoleService
      .getRolesExcept(roleType)
      .then((roles) => {
        this.roles = roles;
        this.getUsers();
      })
      .catch(async (err) => {
        this.logService.error(
          'lab-technician.component',
          'setUserRolesExcept',
          (await this.translationsService.get('ERROR_IN_GETTING_ROLES')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_ROLES')
        );
      });
  }

  closeModal(): void {
    this.labTechnician = {
      name: {}
    };
    $('#add-lab-technician-modal').modal('hide');
  }
}
