import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.constant';
import { Injectable } from '@angular/core';
import { ApiInterceptor } from '../../interceptors/api-interceptor';

@Injectable()
export class LogoImageService extends ResourceService {
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
  }

  getLoginLogoImage(data: { type: string }): Promise<string> {
    const params = new HttpParams().set('type', data.type);

    return this.get(
      API.API_BASE + 'logo-image-url',
      params,
      this.apiInterceptor
    );
  }
}
