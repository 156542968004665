/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-upload-image.html'
(function () {
    'use strict';
    angular.module('app').component('orderNewUploadImage',{
        template: theTemplate,
        controllerAs: 'vm',
        bindings: {
            selectedFiles: '=',
            orderIdToModify: '=',
            signedUrl: '=',
            newOrderObj: '='
        },
        controller: orderFileUploadController
    });
    orderFileUploadController.$inject = [ 'fileService', '$scope', 'toastr', 'orderService', '$state', '$filter', 'CONSTANT', 'logService' ];

    function orderFileUploadController(fileService, $scope, toastr, orderService, $state, $filter, CONSTANT, logService) {
        var vm = this;
        vm.selectedFileObj = [];
        vm.removeFile = removeFile;
        vm.deleteFile = deleteFile;
        vm.removeSignedUrl = removeSignedUrl;
        vm.fileSize = CONSTANT.FILE_SIZE;
        //variables
        vm.progressPercentage = 0;
        $scope.$watch('vm.files', function () {
            upload(vm.files);
        });

        /**
         * uploadImage function
         * @param file
         */
        function uploadImage(file) {
            if (vm.orderIdToModify) {
                var directory = 'organizations/' + vm.newOrderObj.clinic._id + '/orders/' + vm.newOrderObj.orderObjId;
            }
            fileService.uploadFile(file, directory).then(function (resp) {
                //check file type is image or not
                if (/image/.test(file.type)) {
                    file.isImage = true;
                } else {
                    file.isImage = false;
                }

                file.isUploaded = true;
                file._id = resp.data.file._id;
                if (vm.selectedFiles && !vm.orderIdToModify) {
                    vm.selectedFiles.push(resp.data.file._id);
                } else if (vm.selectedFiles && vm.orderIdToModify) {
                    vm.selectedFiles.push(resp.data);
                }
            }, null, function (evt) {
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
        }

        function upload(files) {
            if (files && files.length) {
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    vm.selectedFileObj.push(file);
                    if (!file.$error) {
                        uploadImage(file);
                        vm.files = undefined;
                    }
                }
            } else if (files && !files.length) {
                vm.files = undefined;
                toastr.error($filter('translate')('FILE_IS_TOO_LARGE_TO_UPLOAD'));
            }
        }

        function removeFile(file,fileIndex) {
            fileService.removeFile(file._id).then(function (resp) {
                var index = vm.selectedFileObj.indexOf(file);
                var fileIndex;
                if (vm.orderIdToModify) {
                    fileIndex = vm.selectedFiles.findIndex(function (fileObj) {
                        return fileObj.file._id === file._id;
                    });
                } else {
                    fileIndex = vm.selectedFiles.indexOf(file._id);
                }
                if (index > -1) {
                    vm.selectedFileObj.splice(index, 1);
                }
                if (fileIndex > -1) {
                    vm.selectedFiles.splice(fileIndex, 1);
                }
            }, null, function (evt) {
            });
        }

        function removeSignedUrl(file) {
            fileService.removeFile(file.id).then(function (resp) {
                var index = vm.signedUrl.indexOf(file);
                var fileIndex = vm.selectedFiles.findIndex(function (fileObj) {
                    return fileObj.file._id === file.id;
                });
                if (index > -1) {
                    vm.signedUrl.splice(index, 1);
                }
                if (fileIndex > -1) {
                    vm.selectedFiles.splice(fileIndex, 1);
                }
            }, null, function (evt) {
            });
        }

        function deleteFile(fileId,index) {
            var fileObject = {
                fileId: fileId,
                orderId: $state.params.orderId

            };
            orderService.deleteFile(fileObject).then(function (result) {
            }).catch(function (err) {
                if (err.status === 401) {
                    toastr.error($filter('translate')('ACCESS_DENIED'));
                } else {
                    logService.error('order-new-upload-image.component', 'deleteFile', $filter('translate')('ERROR_IN_DELETE_FILE'));
                    toastr.error($filter('translate')('ERROR_IN_DELETE_FILE'));
                }
            });
        }
    }
})();