import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[numberOnly]'
})
export class NumberOnlyDirective {
  @HostListener('keypress', ['$event']) keyPress(event: KeyboardEvent): void {
    if (!(event.keyCode >= 48 && event.keyCode <= 57)) {
      event.preventDefault();
    }
  }
}
