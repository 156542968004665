import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import template from './clinics.html';
import { ORGANIZATION_DEFAULT_SETTINGS_OBJECT } from '../../app.constant';
import { PermissionService } from '../../services/core/permission.service';
import { User } from '../../models/user.model';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { Organization } from '../../models/organization.model';
import { SessionService } from '../../services/core/session.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'clinics',
  template: template
})
export class ClinicsComponent implements OnInit, AfterViewInit {
  @Input() currentUser: User;
  isSuperAdmin: boolean = false;
  clinics: Organization[] = [];
  clinic: Organization;
  selectedClinicsIds: string[] = [];

  @ViewChild('spinner') spinner: SpinnerComponent;

  constructor(
    private permissionService: PermissionService,
    private subNavService: SubNavService,
    private logService: LogService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private organizationService: OrganizationService,
    private sessionService: SessionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSuperAdmin = this.permissionService.isSuperAdmin(this.currentUser);
    await this.addMenu();
    if (this.isSuperAdmin) {
      this.clinics = await this.getClinics();
    } else if (
      this.currentUser.isAdmin ||
      this.permissionService.isDentist(this.currentUser)
    ) {
      this.clinics = await this.getDentistClinics();
    }
    this.spinner.hide();
  }

  ngAfterViewInit(): void {
    this.spinner.show();
  }

  async addMenu(): Promise<void> {
    this.subNavService.addMenu({
      name: await this.translationsService.get('LIST_VIEW'),
      state: 'app.clinics',
      icon: 'fa-list-ul',
      isActive: true,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('CARD_VIEW'),
      state: 'app.clinics-card',
      icon: 'fa-th',
      isActive: false,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('ADD_CLINIC'),
      icon: 'fa-plus',
      action: 'add-clinic-modal',
      isModel: true,
      isClass: true
    });
  }

  async onClinicChange(): Promise<void> {
    this.clinics = [];
    if (this.permissionService.isSuperAdmin(this.currentUser)) {
      this.clinics = await this.getClinics();
    } else if (
      this.currentUser.isAdmin ||
      this.permissionService.isDentist(this.currentUser)
    ) {
      this.clinics = await this.getDentistClinics();
    }
    this.spinner.hide();
  }

  getClinics(): Promise<Organization[]> {
    const clinicIds = JSON.parse(
      this.sessionService.getItem('clinicPageClinicFilter')
    );
    if (clinicIds && clinicIds.length > 0) {
      this.selectedClinicsIds = clinicIds;
    }
    return this.organizationService
      .getAllOrganizations({
        type: 'clinic',
        clinicIds: this.selectedClinicsIds
      })
      .then((result) => result.data)
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_GETTING_CLINICS'
        );
        this.logService.error(
          'clinics.component',
          'getClinics',
          text + ': ' + err
        );
        this.toastr.error(text);
        return [];
      });
  }

  onClinicUpdate(clinic: Organization): void {
    this.clinic = clinic;
    this.clinic.parentClinicId = clinic.parentClinicId
      ? clinic.parentClinicId['_id']
      : null;
    $('#add-clinic-modal').modal('show');
  }

  getDentistClinics(): Promise<Organization[]> {
    return this.organizationService
      .getDentistClinics({ id: this.currentUser.organization._id })
      .then((result) => result.data)
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_GETTING_CLINICS'
        );
        this.logService.error(
          'clinics.component',
          'getDentistClinics',
          text + ': ' + err
        );
        this.toastr.error(text);
        return [];
      });
  }

  async onFilterUpdate(selectedClinicsIds: string[]): Promise<void> {
    this.selectedClinicsIds = selectedClinicsIds;
    if (this.selectedClinicsIds) {
      this.sessionService.setItem(
        'clinicPageClinicFilter',
        JSON.stringify(this.selectedClinicsIds)
      );
    }
    this.spinner.show();
    this.clinics = await this.getClinics();
    this.spinner.hide();
  }
}
