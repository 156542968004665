import { Injectable } from '@angular/core';
import { ResourceService } from '../core/resource.service';
import { HttpClient } from '@angular/common/http';
import { ApiInterceptor } from '../../interceptors/api-interceptor';

@Injectable()
export class PrintService extends ResourceService {
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient);
  }

  /**
   * Print method to print according to id.
   * @param id
   * @param cssPath
   */
  printMethod(id: string, cssPath: string): void {
    const div = document.getElementById(id);
    const printContents = div.innerHTML;
    const htmlDoc =
      '<html>' +
      '<head>' +
      '<link rel="stylesheet" type="text/css" href="' +
      cssPath +
      '"/>' +
      '</head>' +
      '<body>' +
      printContents +
      '</body>' +
      '</html>';

    window.top.postMessage({ eventType: 'print', html: htmlDoc }, '*');
  }

  /**
   * Method to print a div element
   * @param id
   */
  printElementFromID(id: string): Promise<void> {
    return this.get<{ description: string; keywords: string }>(
      '../../../minified-css-file-name.json',
      undefined,
      this.apiInterceptor
    ).then((result) => {
      const cssFilePath = result.keywords;
      this.printMethod(id, cssFilePath);
    });
  }
}
