import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DEFAULT_COLOR_CODE } from '../../../../../../app.constant';
import { ConstructionService } from '../../../../services/construction-service';
import { ConstructionSettingsService } from '../../../../services/construction-settings-service';
import template from './construction-blocks.html';

@Component({
  selector: 'construction-blocks',
  template
})
export class ConstructionBlocksComponent implements OnChanges {
  @Input() constructions: any;
  @Input() orderIdToModify: string;
  isFirstTime = true;
  @Output() constructionBlockOpened: EventEmitter<any> = new EventEmitter<any>();
  private readonly backgroundColorCode = DEFAULT_COLOR_CODE.backgroundColorCode;
  private readonly textColorCode = DEFAULT_COLOR_CODE.textColorCode;

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService,
    private readonly constructionService: ConstructionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.constructions?.currentValue?.length &&
      this.orderIdToModify &&
      this.isFirstTime
    ) {
      this.openConstructionBlock(changes.constructions.currentValue[0]);
      this.isFirstTime = false;
    }
  }

  getNoOfUnits(numberOfUnits) {
    const unitObj = {};
    if (numberOfUnits instanceof Array) {
      numberOfUnits.forEach((unit) => {
        unitObj[unit.actionId] = unit;
      });
      return unitObj;
    }
    return numberOfUnits;
  }

  setOtherMaterial(construction) {
    const materials: any = this.constructionService.getMaterials(
      construction.actions
    );
    const materialIndex = materials.findIndex((material) => {
      return construction.material === material._id;
    });
    if (materialIndex === -1) {
      this.constructionSettingsService.setSetting('material', '');
      this.constructionSettingsService.setSetting('materialName', '');
      this.constructionSettingsService.setSetting(
        'otherMaterial',
        construction.material
      );
    }
  }

  setNumberOfUnits(construction) {
    if (construction.numberOfUnits) {
      const numberOfUnits = this.getNoOfUnits(construction.numberOfUnits);
      this.constructionSettingsService.setNumberOfUnits(
        'numberOfUnits',
        numberOfUnits
      );
    }
  }

  openConstructionBlock(construction) {
    try {
      this.constructionSettingsService.settings = construction;
      this.setNumberOfUnits(construction);
      this.setOtherMaterial(construction);
      this.constructionBlockOpened.emit(construction);
    } catch (error) {
      console.log(error);
    }
  }

  getHexColor(construction) {
    if (construction?.colorCode) {
      return {
        background: construction.colorCode,
        color: construction.textColorCode
      };
    }
    if (construction?.subCategory) {
      return {
        background: construction.subCategory.colorCode
          ? construction.subCategory.colorCode
          : this.backgroundColorCode,
        color: construction.subCategory.textColorCode
          ? construction.subCategory.textColorCode
          : this.textColorCode
      };
    }
    return {
      background: this.backgroundColorCode,
      color: this.textColorCode
    };
  }
}
