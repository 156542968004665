/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './sub-nav.component.html'
(function () {
  'use strict';
  angular.module('app').component('subNav', {
    template: theTemplate,
    controller: subNavController,
    controllerAs: 'vm',
    bindings: angular.extend({}, sessionStateBindings, {
      isMenuOpen: '='
    })
  });

  subNavController.$inject = [
    '$scope',
    'subNavService',
    '$window',
    'ORDER_VERSION',
    '$state',
    'logService',
    '$filter',
    'permissionService',
    '$stateParams',
    'ordersGraphqlService',
    'toastr'
  ];
  function subNavController(
    $scope,
    subNavService,
    $window,
    ORDER_VERSION,
    $state,
    logService,
    $filter,
    permissionService,
    $stateParams,
    ordersGraphqlService,
    toastr
  ) {
    var vm = this;
    vm.showNewOrder = false;
    vm.state = '';
    vm.allSettings = [];
    vm.reactAppUrl = process.env.NEW_APP_URL;
    vm.showModifyOrder = showModifyOrder;
    vm.isDentist = isDentist;
    vm.isLabTechnician = isLabTechnician;
    vm.changeStatusToAcceptedByLab = changeStatusToAcceptedByLab;

    $scope.$watch(function (newVal, oldVal) {
      vm.isOrderDetails = $state.$current.name === 'app.order-details-2';
      vm.shouldHideSubNav =
        $state.$current.name === 'app.scheduled-transports' ||
        $state.$current.name === 'app.settings' ||
        $state.$current.name === 'app.profile';
    });

    $scope.$watch(
      function () {
        subNavService.getList();
        // show/hide new order button
        vm.showNewOrder =
          vm.currentUser &&
          permissionService.isDentist(vm.currentUser) &&
          $stateParams.showNewOrder;
      },
      function (newVal, oldVal) {
        vm.menuList = subNavService.getList();
      }
    );
    vm.setOffset = function () {
      $window.scrollBy(0, -250);
    };

    function showModifyOrder(orderId, orderType, isResend, isCreatedByLab) {
      if (isCreatedByLab) {
        window.location.href = `${vm.reactAppUrl}/orders/update/${orderId}`;
      } else if (orderType === ORDER_VERSION.ORDER_1) {
        $state.go('app.order-new', { orderId: orderId, isResend: isResend });
      } else {
        $state.go('app.order-new-2', { orderId: orderId, isResend: isResend });
      }
    }

    $scope.$on('user', function (result, data) {
      if (data) {
        vm.currentUser = data.user;
      }
      if (data && permissionService.isDentist(data.user)) {
        vm.showNewOrder = true;
      } else {
        vm.showNewOrder = false;
      }
    });

    function isDentist(user) {
      return permissionService.isDentist(user);
    }

    function isLabTechnician(user) {
      return permissionService.isLabTechnician(user);
    }

    async function changeStatusToAcceptedByLab(orderId) {
      try {
        await ordersGraphqlService.changeStatusToAcceptedByLab(orderId);
        toastr.success($filter('translate')('SUCCESSFULLY_UPDATED'));
        window.location.reload();
      } catch (error) {
        logService.error(
          'sub-nav.component',
          'changeStatusToAcceptedByLab',
          $filter('translate')('ERROR_IN_STATUS_CHANGE') + ': ' + error
        );
      }
    }
  }
})();
