import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { OrganizationSettingService } from '../../services/organization-setting/organization-setting.service';
import template from './account-construction-setting.html';

@Component({
  selector: 'account-construction-setting',
  template: template
})
export class AccountConstructionSettingComponent implements OnInit {
  @Input() currentUser: User;

  contactTypes: any;
  constructionDesign: any;
  private occlusionsSettingName: any;
  private contactTypesSettingName: any;
  private ponticDesignsSettingName: any;
  private metalDesignsSettingName: any;
  private occlusions: any;
  private ponticDesigns: any;
  private metalDesigns: any;

  constructor(organizationSettingService: OrganizationSettingService) {
    this.occlusionsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.OCCLUSION_TYPE;
    this.contactTypesSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CONTACT_TYPE;
    this.ponticDesignsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PONTIC_DESIGN;
    this.metalDesignsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.METAL_DESIGN;

    // set construction settings value from customer settings
    this.occlusions = organizationSettingService.getCustomerSetting(
      this.occlusionsSettingName
    );
    this.contactTypes = organizationSettingService.getCustomerSetting(
      this.contactTypesSettingName
    );
    this.ponticDesigns = organizationSettingService.getCustomerSetting(
      this.ponticDesignsSettingName
    );
    this.metalDesigns = organizationSettingService.getCustomerSetting(
      this.metalDesignsSettingName
    );

    this.constructionDesign = {
      design: {
        metals: this.metalDesigns,
        pontics: this.ponticDesigns
      },
      occlusionContactTypes: this.occlusions,
      contactTypes: this.contactTypes
    };
  }

  ngOnInit(): void {
    const self = this;
    setTimeout(() => {
      self.defaultDesignSelect();
    }, 1000);
  }

  defaultDesignSelect(): void {
    if (
      this.currentUser &&
      this.currentUser.constructionSetting &&
      !this.currentUser.constructionSetting.ponticDesign
    ) {
      this.currentUser.constructionSetting.ponticDesign = this.constructionDesign.design.pontics[1].name;
    }
    if (
      this.currentUser &&
      this.currentUser.constructionSetting &&
      !this.currentUser.constructionSetting.metalDesign
    ) {
      this.currentUser.constructionSetting.metalDesign = this.constructionDesign.design.metals[0].name;
    }
    if (
      this.currentUser &&
      this.currentUser.constructionSetting &&
      !this.currentUser.constructionSetting.occlusionContact
    ) {
      this.currentUser.constructionSetting.occlusionContact = this.constructionDesign.occlusionContactTypes[1].name;
    }
    if (
      this.currentUser &&
      this.currentUser.constructionSetting &&
      !this.currentUser.constructionSetting.contactType
    ) {
      this.currentUser.constructionSetting.contactType = this.constructionDesign.contactTypes[1].name;
    }
  }
}
