import { Component, Input } from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../../../../app.constant';
import { SubCategory } from '../../../../../../../../models/organization.model';
import template from './spring-type.html';

@Component({
  selector: 'spring-type',
  template
})
export class SpringTypeComponent {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedSpringType: string;
  @Input() springTypeOptions: string[];
  springTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SPRING_TYPES;
}
