import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { User } from '../../../../../../models/user.model';
import template from './manage-participants.html';
import { Organization } from '../../../../../../models/organization.model';

@Component({
  selector: 'manage-participants',
  template
})
export class ManageParticipantsComponent implements OnInit, OnDestroy {
  @Output() linkedDentistsChanged: EventEmitter<User[]> = new EventEmitter<User[]>();
  @Output() linkedOrgClinicsChanged: EventEmitter<Organization[]> = new EventEmitter<
    Organization[]
  >();
  @Output() linkedEmailsChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  linkedDentists = [];
  linkedClinics = [];
  customParticipantEmails = [];

  constructor() {}

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleEventsFromNewFE.bind(this));
  }

  async ngOnInit() {
    window.addEventListener('message', this.handleEventsFromNewFE.bind(this));
  }

  removeDentist(id: string) {
    this.linkedDentists.splice(
      this.linkedDentists.findIndex((dentist) => {
        return dentist.user._id === id;
      }),
      1
    );
    this.linkedDentistsChanged.emit(this.linkedDentists);
  }

  removeClinic(id: string) {
    this.linkedClinics.splice(
      this.linkedClinics.findIndex((linkedClinic) => {
        return linkedClinic.clinic._id === id;
      }),
      1
    );
    this.linkedOrgClinicsChanged.emit(this.linkedClinics);
  }

  removeCustomEmail(email: string) {
    this.customParticipantEmails.splice(
      this.customParticipantEmails.findIndex((customEmail) => {
        return customEmail === email;
      }),
      1
    );
    this.linkedEmailsChanged.emit(this.customParticipantEmails);
  }
  
  openAddParticipantsDailog() {
    window.top.postMessage(
      { eventType: 'ADD_PARTICIPANTS' },
      '*'
    );
  }

  handleEventsFromNewFE(e) {
    if (
      e.data &&
      e.origin === process.env.NEW_APP_URL &&
      e.data.eventType === 'GET_PARTICIPANTS' &&
      e.data.participants
    ) {
      this.linkedDentists = e.data.participants.linkedDentists;
      this.linkedClinics = e.data.participants.linkedClinics;
      this.customParticipantEmails = e.data.participants.customParticipantEmails;
      this.linkedDentistsChanged.emit(this.linkedDentists);
      this.linkedOrgClinicsChanged.emit(this.linkedClinics);
      this.linkedEmailsChanged.emit(this.customParticipantEmails);
    }
  }
}
