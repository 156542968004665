import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { PermissionService } from '../../services/core/permission.service';
import { User } from '../../models/user.model';
import { Notification } from '../../models/notification.model';
import { OrderService } from '../../services/order-new/order-new.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { EventService } from '../../services/core/event.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { SessionService } from '../../services/core/session.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { Organization } from '../../models/organization.model';
import { orderBy } from 'lodash';
import { TokenService } from '../../services/core/token.service';
import template from './notifications.html';

@Component({
  selector: 'notifications',
  template: template
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Input() currentUser: User;
  interval: ReturnType<typeof setTimeout>;
  totalNotificationsCount: number;
  lab: Organization;
  notifications: Notification[] = [];

  constructor(
    private readonly permissionService: PermissionService,
    private readonly orderService: OrderService,
    private readonly organizationService: OrganizationService,
    private readonly eventService: EventService,
    private readonly sessionService: SessionService,
    private readonly logService: LogService,
    private readonly toastr: ToastrService,
    private readonly tokenService: TokenService,
    private readonly translationsService: TranslationsService,
    @Inject(forwardRef(() => '$state')) private readonly $state: any
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.permissionService.isSuperAdmin(this.currentUser)) {
      if (this.permissionService.isLabTechnician(this.currentUser)) {
        this.lab = await this.currentLab();
      }
      this.getNotifications();
      // Auto refresh in every 1 minute
      this.interval = setInterval(() => {
        this.getNotifications();
      }, 60000);

      this.eventService.on('$stateChangeSuccess', () => {
        this.notifications = [];
        if (this.tokenService.isAuthenticated()) {
          this.getNotifications();
        }
      });
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  clearAllNotifications(): void {
    const orderIds = this.notifications.map((notification) => notification.order);

    this.orderService
      .clearAllNotifications({ orderIds: orderIds })
      .then(async () => {
        this.getNotifications();
        this.toastr.success(
          await this.translationsService.get('NOTIFICATION_CLEARED')
        );
      })
      .catch(async (err: any) => {
        this.handleError(
          'clearAllNotifications',
          'ERROR_IN_CLEAR_NOTIFICATIONS',
          err
        );
      });
  }

  goToOrderDetail(orderId: string): void {
    this.clickOnNotification(orderId);
    this.$state.go(CUSTOMER_SETTINGS_CONSTANTS.ORDER_DETAIL_STATES.ORDER_DETAIL2, {
      orderId: orderId
    });
  }

  async addNotificationsForDentist(
    notification: Notification,
    type: string,
    patient: { firstName: string; lastName: string },
    orderDisplayId: string
  ): Promise<void> {
    if (this.currentUser.generalNotifications.newMessage && !type) {
      notification.message = `${await this.translationsService.get(
        'YOU_HAVE_A_NEW_MESSAGE_IN_ORDER'
      )} ${orderDisplayId}.`;
      notification.iconType = 'newMessage';
      this.notifications.push(notification);
    } else if (type && notification.message === 'delivery_date_changed') {
      notification.message = `${patient.firstName} ${
        patient.lastName
      } ${await this.translationsService.get(
        'ORDER'
      )} ${await this.translationsService.get('DELIVERY_DATE_CHANGES')}`;
      notification.iconType = 'status';
      this.notifications.push(notification);
    } else if (type && notification.message === '3shape') {
      notification.message = `${await this.translationsService.get(
        'THREE_SHAPE_UPLOADING_CLEAR_FOR'
      )} ${orderDisplayId}.`;
      notification.iconType = '3shape';
      this.notifications.push(notification);
    }
  }

  async addNotificationsForTechnician(
    notification: Notification,
    type: string,
    patient: { firstName: string; lastName: string },
    orderDisplayId: string
  ): Promise<void> {
    if (this.lab && this.lab.generalNotifications.newMessage && !type) {
      await this.handleLabNewMessage(notification, orderDisplayId);
    } else if (type && notification.message === 'sent_by_dentist') {
      await this.handleLabNewOrderMessage(notification, orderDisplayId);
    } else if (type && notification.message === 'order_modified') {
      await this.handleLabOrderModifiedMessage(notification, patient);
    } else if (type && notification.message === 'order_resend') {
      await this.handleLabOrderResend(notification, patient);
    }
  }

  // TODO: Rename this method. It is not a getter.
  getNotifications(): void {
    if (!this.permissionService.isSuperAdmin(this.currentUser)) {
      this.orderService
        .getNotifications()
        .then(async (result) => {
          this.notifications = [];
          if (!result.data) {
            return;
          }
          for (const messageObj of result.data) {
            await this.createNotifications(messageObj);
          }
          this.broadcastNewNotifications();

          if (this.$state.current.name === 'app.order-details') {
            this.clickOnNotification(this.$state.params.orderId);
          }
          this.notifications = orderBy(this.notifications, 'messageOn', 'desc');
          this.setNotificationCount();
        })
        .catch(async (err: any) => {
          this.handleError(
            'getNotifications',
            'ERROR_IN_GETTING_NOTIFICATIONS',
            err
          );
        });
    }
  }

  async currentLab(): Promise<Organization> {
    try {
      const labData = await this.organizationService.getCurrentLab();
      return labData.data;
    } catch (err) {
      this.handleError('currentLab', 'ERROR_IN_GETTING_LABS', err);
      return null;
    }
  }

  clickOnNotification(orderId: string): void {
    if (orderId) {
      this.orderService
        .changeMessageStatus(orderId)
        .then(async () => {
          this.getNotifications();
        })
        .catch(async (err: any) => {
          this.handleError(
            'clickOnNotification',
            'ERROR_IN_CHANGE_MESSAGE_STATUS',
            err
          );
        });
    }
  }

  private broadcastNewNotifications() {
    const orderNotificationLength = this.sessionService.getItem(
      'order-notification-length'
    );
    if (
      this.notifications &&
      this.notifications.length !== 0 &&
      orderNotificationLength !== this.notifications.length.toString()
    ) {
      const isOrderList = this.$state.current.name === 'app.order-list';
      this.eventService.broadcast('newNotificationAvailable', {
        isOrderListState: isOrderList
      });
    }
    this.sessionService.setItem(
      'order-notification-length',
      `${this.notifications.length}`
    );
  }

  private setNotificationCount(): void {
    this.totalNotificationsCount = this.notifications.filter(
      (notification) => !notification.isReadByUser
    ).length;
  }

  private async handleLabNewMessage(
    notification: Notification,
    orderDisplayId: string
  ): Promise<void> {
    const message = await this.translationsService.get(
      'YOU_HAVE_A_NEW_MESSAGE_IN_ORDER'
    );
    notification.message = `${message} ${orderDisplayId}.`;
    notification.iconType = 'newMessage';
    this.notifications.push(notification);
  }

  private async handleLabNewOrderMessage(
    notification: Notification,
    orderDisplayId: string
  ): Promise<void> {
    if (this.lab && this.lab.generalNotifications.newOrder) {
      notification.message = `${await this.translationsService.get(
        'NEW_ORDER_NOTIFICATION_PREFIX'
      )} ${orderDisplayId} ${await this.translationsService.get(
        'RECEIVED_NOTIFICATION_SUFFIX'
      )}`;
      notification.iconType = 'newOrder';
      this.notifications.push(notification);
    }
  }

  private async handleLabOrderModifiedMessage(
    notification: Notification,
    patient: { firstName: any; lastName: any }
  ) {
    if (this.lab && this.lab.generalNotifications.orderModified) {
      notification.message = `${patient.firstName} ${
        patient.lastName
      } ${await this.translationsService.get(
        'ORDER'
      )} ${await this.translationsService.get('IS_MODIFIED')}`;
      notification.iconType = 'status';
      this.notifications.push(notification);
    }
  }

  private async handleLabOrderResend(
    notification: Notification,
    patient: { firstName: any; lastName: any }
  ): Promise<void> {
    if (this.lab && this.lab.generalNotifications.orderResend) {
      notification.message = `${patient.firstName} ${
        patient.lastName
      } ${await this.translationsService.get(
        'ORDER'
      )} ${await this.translationsService.get('IS_RESEND')}`;
      notification.iconType = 'newOrder';
      this.notifications.push(notification);
    }
  }

  private async createNotifications(messageObj): Promise<void> {
    const message: Notification = {
      messageOn: messageObj.message ? messageObj.message.messageOn : null,
      message: messageObj.message ? messageObj.message.message : null,
      order: messageObj.order,
      iconType: '',
      messageBy: messageObj.message ? messageObj.message.by : null,
      currentUserId: this.currentUser._id,
      isReadByUser: messageObj.isReadByUser as boolean
    };
    if (this.permissionService.isDentist(this.currentUser)) {
      // new message
      await this.addNotificationsForDentist(
        message,
        messageObj.message.type,
        messageObj.patient,
        messageObj.orderDisplayId
      );
    } else {
      await this.addNotificationsForTechnician(
        message,
        messageObj.message.type,
        messageObj.patient,
        messageObj.orderDisplayId
      );
    }
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(NotificationsComponent.name, method, `${message} ${err}`);
    this.toastr.error(await this.translationsService.get(message));
  }
}
