import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {
  Material,
  SubCategory
} from '../../../../../../../models/organization.model';
import { ConstructionSettingsService } from '../../../../../services/construction-settings-service';
import template from './optional-construction-settings.html';

@Component({
  selector: 'optional-construction-settings',
  template
})
export class OptionalConstructionSettingsComponent implements OnInit, OnChanges {
  @ViewChild('container', { static: true }) container;
  @Input() constructionSettings: {
    [key: string]: { include: boolean; mandatory: boolean };
  } = {};
  @Input() selectedSubCategory: SubCategory;
  @Input() materials: Material[];
  @Input() metalDesigns: { name: string; imageUrl: string }[];
  @Input() ponticDesigns: { name: string; imageUrl: string }[];
  @Input() claspMaterialOptions: string[];
  @Input() facialLoopOptions: string[];
  @Input() claspTypeOptions: string[];
  @Input() springTypeOptions: string[];

  isNoOfUnitsMandatory: boolean;
  isNoOfUnitsIncluded: boolean;

  constructor(
    private readonly settingsService: ConstructionSettingsService,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  ngOnChanges(): void {
    this.isNoOfUnitsMandatory = this.settingsService.isNoOfUnitsMandatory(
      this.constructionSettings?.numberOfUnits || {}
    );

    this.isNoOfUnitsIncluded = this.settingsService.isNoOfUnitsIncluded(
      this.constructionSettings?.numberOfUnits || {}
    );
  }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.container);
  }
}
