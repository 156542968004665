/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './single-crown.html'
/**
 * Directive for adding or editing single crown
 */
(function () {
    'use strict';
    angular.module('app').component('singleCrown', {
        template: theTemplate,
        controller: singleCrownController,
        controllerAs: 'vm',
        bindings: angular.extend({}, sessionStateBindings,{
            constructionSettings: '=',
            selectedTeeth: '=',
            addUpdateSingleCrown: '=',
            removeSingleCrown: '=',
            selectedCrowningType: '=',
            orderIdToModify: '=',
            singleCrownData: '<',
            selectedConstructionObj: '=', //currently selected constructions objects
            tempSingleCrownData: "=",
            tempImplantCrownData: "=",
            singleCrownCommentsArray: '='
        })
    });
    singleCrownController.$inject = [ '$scope', 'userService', 'CUSTOMER_SETTINGS_CONSTANTS','CONSTRUCTIONS', 'DATE', 'organizationSettingService' ];
    /**
     * Controller function
     */
    function singleCrownController($scope, userService, CUSTOMER_SETTINGS_CONSTANTS,CONSTRUCTIONS, DATE, organizationSettingService) {
        var vm = this;
        vm.constructionName = {
            singleCrown: CONSTRUCTIONS.SINGLE_CROWN,
            implantCrown: CONSTRUCTIONS.IMPLANT_CROWN
        };

        if (!vm.singleCrownData) {
            //if null initialize. though this is not expected
            vm.singleCrownData = {};
        }
        vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;
        /**
         * Member functions
         */
        vm.setMaterial = setMaterial;
        vm.setSingleMaterial = setSingleMaterial;
        vm.setTypeOcclusion = setTypeOcclusion;
        vm.removeConstruction = removeConstruction;
        vm.saveTeethConstruction = saveTeethConstruction;
        vm.setTypeContact = setTypeContact;

        vm.metalDesignSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.METAL_DESIGN;
        vm.occlusionSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.OCCLUSION_TYPE;
        vm.ponticDesignSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PONTIC_DESIGN;
        vm.contactTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CONTACT_TYPE;

        var customerSettings = organizationSettingService.getCustomerSettings();
        vm.$onInit = function () {
            if (vm.currentUser && vm.currentUser.constructionSetting) {
                vm.defaultMetalDesign = vm.currentUser.constructionSetting.metalDesign;
                vm.defaultOcclusionSetting = vm.currentUser.constructionSetting.occlusionContact;
                vm.defaultContactType = vm.currentUser.constructionSetting.contactType;
                vm.defaultPonticDesign = vm.currentUser.constructionSetting.ponticDesign;
            } else {
                vm.defaultMetalDesign = organizationSettingService.getPreSelectedValueOfSetting(vm.metalDesignSettingName);
                vm.defaultOcclusionSetting = organizationSettingService.getPreSelectedValueOfSetting(vm.occlusionSettingName);
                vm.defaultContactType = organizationSettingService.getPreSelectedValueOfSetting(vm.contactTypeSettingName);
                vm.defaultPonticDesign = organizationSettingService.getPreSelectedValueOfSetting(vm.ponticDesignSettingName);
            }
        };

        $scope.$watch('vm.selectedTeeth',function() {
            //if we previously created any single crown then use that else create empty
            if (vm.selectedCrowningType === 'single crown') {
                if (vm.selectedConstructionObj && vm.selectedConstructionObj.singleCrowns && vm.selectedConstructionObj.singleCrowns.length > 0) {
                    var singleCrown = vm.selectedConstructionObj.singleCrowns[vm.selectedConstructionObj.singleCrowns.length - 1];
                    //get old construction

                    for (var sc in vm.selectedConstructionObj.singleCrowns) {
                        if (vm.selectedTeeth === vm.selectedConstructionObj.singleCrowns[sc].teethNo) {
                            vm.singleCrownData = {
                                material: vm.selectedConstructionObj.singleCrowns[sc].material,
                                shade: vm.selectedConstructionObj.singleCrowns[sc].shade,
                                color: vm.selectedConstructionObj.singleCrowns[sc].color,
                                metal: vm.selectedConstructionObj.singleCrowns[sc].metal,
                                occlusionType: vm.selectedConstructionObj.singleCrowns[sc].occlusionType,
                                contactType: vm.selectedConstructionObj.singleCrowns[sc].contactType,
                                colorSampling: vm.selectedConstructionObj.singleCrowns[sc].colorSampling,
                                createdAt: vm.selectedConstructionObj.singleCrowns[sc].createdAt,
                                type: 'single crown',
                                otherMaterial: vm.selectedConstructionObj.singleCrowns[sc].otherMaterial,
                                allComments: vm.selectedConstructionObj.singleCrowns[sc].allComments
                            };
                        }
                    }

                    if (!vm.singleCrownData.material) {
                        vm.singleCrownData = {
                            material: singleCrown.material,
                            shade: singleCrown.shade,
                            color: singleCrown.color,
                            metal: singleCrown.metal,
                            occlusionType: singleCrown.occlusionType,
                            contactType: singleCrown.contactType,
                            colorSampling: singleCrown.colorSampling,
                            createdAt: singleCrown.createdAt,
                            type: 'single crown',
                            otherMaterial: singleCrown.otherMaterial,
                            allComments: singleCrown.allComments
                        };
                    }

                } else {
                    userService.getCurrentUser().then(function (result) {
                        var userProfile = result.data;
                        if (userProfile.constructionSetting) {
                            //get default construction
                            if (vm.tempSingleCrownData && vm.tempSingleCrownData.material) {
                                vm.singleCrownData = {
                                    material: vm.tempSingleCrownData.material,
                                    shade: vm.tempSingleCrownData.shade,
                                    color: vm.tempSingleCrownData.color,
                                    createdAt: vm.tempSingleCrownData.createdAt,
                                    metal: vm.tempSingleCrownData.metal ? vm.tempSingleCrownData.metal : userProfile.constructionSetting.metalDesign,
                                    occlusionType: vm.tempSingleCrownData.occlusionType ? vm.tempSingleCrownData.occlusionType : userProfile.constructionSetting.occlusionContact,
                                    contactType: vm.tempSingleCrownData.contactType ? vm.tempSingleCrownData.contactType : userProfile.constructionSetting.contactType,
                                    type: 'single crown',
                                    otherMaterial: vm.tempSingleCrownData.otherMaterial,
                                    allComments: vm.tempSingleCrownData.allComments
                                };
                            } else {
                                vm.singleCrownData = {
                                    metal: userProfile.constructionSetting.metalDesign,
                                    occlusionType: userProfile.constructionSetting.occlusionContact,
                                    contactType: userProfile.constructionSetting.contactType,
                                    type: 'single crown',
                                    allComments: []
                                };
                            }

                        }
                    });
                }
            } else if (vm.selectedCrowningType === 'implant crown') {
                //if we previously created any implant crown then use that else create empty
                if (vm.selectedConstructionObj && vm.selectedConstructionObj.implantCrowns && vm.selectedConstructionObj.implantCrowns.length > 0) {
                    var implantCrown = vm.selectedConstructionObj.implantCrowns[vm.selectedConstructionObj.implantCrowns.length - 1];
                    //get old construction

                    for (var ic in vm.selectedConstructionObj.implantCrowns) {
                        if (vm.selectedTeeth === vm.selectedConstructionObj.implantCrowns[ic].teethNo) {
                            vm.singleCrownData = {
                                material: vm.selectedConstructionObj.implantCrowns[ic].material,
                                shade: vm.selectedConstructionObj.implantCrowns[ic].shade,
                                color: vm.selectedConstructionObj.implantCrowns[ic].color,
                                metal: vm.selectedConstructionObj.implantCrowns[ic].metal,
                                occlusionType: vm.selectedConstructionObj.implantCrowns[ic].occlusionType,
                                contactType: vm.selectedConstructionObj.implantCrowns[ic].contactType,
                                colorSampling: vm.selectedConstructionObj.implantCrowns[ic].colorSampling,
                                createdAt: vm.selectedConstructionObj.implantCrowns[ic].createdAt,
                                type: 'implant crown',
                                retainment: vm.selectedConstructionObj.implantCrowns[ic].retainment,
                                otherMaterial: vm.selectedConstructionObj.implantCrowns[ic].otherMaterial,
                                allComments: vm.selectedConstructionObj.implantCrowns[ic].allComments
                            };
                        }
                    }

                    if (!vm.singleCrownData.material) {
                        vm.singleCrownData = {
                            material: implantCrown.material,
                            shade: implantCrown.shade,
                            color: implantCrown.color,
                            metal: implantCrown.metal,
                            occlusionType: implantCrown.occlusionType,
                            contactType: implantCrown.contactType,
                            colorSampling: implantCrown.colorSampling,
                            createdAt: implantCrown.createdAt,
                            type: 'implant crown',
                            retainment: implantCrown.retainment,
                            otherMaterial: implantCrown.otherMaterial,
                            allComments: implantCrown.allComments
                        };
                    }

                } else {
                    userService.getCurrentUser().then(function (result) {
                        var userProfile = result.data;
                        if (userProfile.constructionSetting) {
                            //get default construction
                            if (vm.tempImplantCrownData && vm.tempImplantCrownData.material) {
                                vm.singleCrownData = {
                                    material: vm.tempImplantCrownData.material,
                                    shade: vm.tempSingleCrownData.shade,
                                    color: vm.tempSingleCrownData.color,
                                    metal: vm.tempImplantCrownData.metal ? vm.tempImplantCrownData.metal : userProfile.constructionSetting.metalDesign,
                                    occlusionType: vm.tempImplantCrownData.occlusionType ? vm.tempImplantCrownData.occlusionType : userProfile.constructionSetting.occlusionContact,
                                    contactType: vm.tempImplantCrownData.contactType ? vm.tempImplantCrownData.contactType : userProfile.constructionSetting.contactType,
                                    type: 'implant crown',
                                    retainment: vm.tempImplantCrownData.retainment,
                                    createdAt: vm.tempImplantCrownData.createdAt,
                                    otherMaterial: vm.tempImplantCrownData.otherMaterial,
                                    allComments: vm.tempImplantCrownData.allComments
                                };
                            } else {
                                vm.singleCrownData = {
                                    metal: userProfile.constructionSetting.metalDesign,
                                    occlusionType: userProfile.constructionSetting.occlusionContact,
                                    contactType: userProfile.constructionSetting.contactType,
                                    type: 'implant crown',
                                    allComments: []
                                };
                            }
                        }
                    });
                }
            }

        });

        /**
         * Remove construction on selected teeth
         */
        function removeConstruction() {
            // remove crown from existing structure
            if (vm.removeSingleCrown) {
                vm.removeSingleCrown(vm.selectedTeeth);
            }
            vm.selectedTeeth = '';
        }

        /**
         * select and de select
         */
        function setMaterial(material) {
            if (material) {
                if (material === vm.singleCrownData.material) {
                    vm.singleCrownData.material = '';
                } else {
                    if (vm.selectedCrowningType === 'single crown') {
                        vm.tempSingleCrownData.material = material;
                    } else {
                        vm.tempImplantCrownData.material = material;
                    }
                    vm.singleCrownData.material = material;
                }
            }
        }

        function setSingleMaterial(material) {
            if (material) {
                if (material === vm.singleCrownData.metal) {
                    vm.singleCrownData.metal = '';
                } else {
                    if (vm.selectedCrowningType === 'single crown') {
                        vm.tempSingleCrownData.metal = material;
                    } else {
                        vm.tempImplantCrownData.metal = material;
                    }
                    vm.singleCrownData.metal = material;
                }
            }
        }

        function setTypeOcclusion(type) {
            if (type) {
                if (type === vm.singleCrownData.occlusionType) {
                    vm.singleCrownData.occlusionType = '';
                } else {
                    if (vm.selectedCrowningType === 'single crown') {
                        vm.tempSingleCrownData.occlusionType = type;
                    } else {
                        vm.tempImplantCrownData.occlusionType = type;
                    }
                    vm.singleCrownData.occlusionType = type;
                }
            }
        }

        function setTypeContact(type) {
            if (type) {
                if (type === vm.singleCrownData.contactType) {
                    vm.singleCrownData.contactType = '';
                } else {
                    if (vm.selectedCrowningType === 'single crown') {
                        vm.tempSingleCrownData.contactType = type;
                    } else {
                        vm.tempImplantCrownData.contactType = type;
                    }
                    vm.singleCrownData.contactType = type;
                }
            }
        }
        /**
         * Save teeth construction
         */
        $scope.$watch('vm.singleCrownData', function (newVal, oldval) {
            // we can somehow make simple orders more dynamic as well.
            if (!vm.singleCrownData.metal) {
                if (vm.selectedCrowningType === 'single crown') {
                    vm.tempSingleCrownData.metal = vm.defaultMetalDesign;
                } else {
                    vm.tempImplantCrownData.metal = vm.defaultMetalDesign;
                }
                vm.singleCrownData.metal = vm.defaultMetalDesign;
            }

            if (!vm.singleCrownData.occlusionType) {
                if (vm.selectedCrowningType === 'single crown') {
                    vm.tempSingleCrownData.occlusionType = vm.defaultOcclusionSetting;
                } else {
                    vm.tempImplantCrownData.occlusionType = vm.defaultOcclusionSetting;
                }
                vm.singleCrownData.occlusionType = vm.defaultOcclusionSetting;
            }

            if (!vm.singleCrownData.contactType) {
                if (vm.selectedCrowningType === 'single crown') {
                    vm.tempSingleCrownData.contactType = vm.defaultContactType;
                } else {
                    vm.tempImplantCrownData.contactType = vm.defaultContactType;
                }
                vm.singleCrownData.contactType = vm.defaultContactType;
            }

            //METAL_DESIGN
            if ((((vm.singleCrownData.material === 'Other' && vm.singleCrownData.otherMaterial) || (vm.singleCrownData.material && vm.singleCrownData.material !== 'Other')) ||
                    vm.singleCrownData.comments) && (vm.singleCrownData.colorSampling || (vm.singleCrownData.shade && vm.singleCrownData.color))) {
                //it is valid construction. lets save it
                vm.singleCrownData.teethNo = vm.selectedTeeth;
                if (!vm.singleCrownData.type) {
                    vm.singleCrownData.type = vm.selectedCrowningType;
                }

                if (vm.singleCrownData.type === 'implant crown') {
                    if (vm.singleCrownData.retainment) {
                        vm.addUpdateSingleCrown(vm.singleCrownData,vm.selectedCrowningType);
                    }
                } else {
                    vm.addUpdateSingleCrown(vm.singleCrownData,vm.selectedCrowningType);
                }
            }
            if (!vm.orderIdToModify && !vm.singleCrownData.createdAt) {
                vm.singleCrownData.createdAt = new Date().getTime();
            }
        },true);

        function saveTeethConstruction(type) {
            //get whole data here. add validations as well. if he did not select all there show error
            vm.singleCrownData.teethNo = vm.selectedTeeth;
            vm.addUpdateSingleCrown(angular.copy(vm.singleCrownData),type);
            vm.selectedTeeth = '';
        }
    }

})();