import { Input, Component } from '@angular/core';
import { Order, SampleMethods } from '../../../models/order.model';
import { OrganizationSettingService } from '../../../services/organization-setting/organization-setting.service';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../app.constant';
import template from './sample-type.html';

@Component({
  selector: 'sample-type',
  template: template
})
export class SampleTypeComponent {
  @Input() newOrderObj: Order;
  @Input() sampleIsMandatory: boolean;
  sampleMethods: SampleMethods;
  sampleMethodSettingName: string;
  sampleMethod: any;
  sampleContentSettingName: string;

  constructor(private readonly organizationSettingService: OrganizationSettingService) {
    this.sampleMethods = CUSTOMER_SETTINGS_CONSTANTS.SAMPLE_METHODS;
    this.sampleMethodSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_METHOD;
    this.sampleMethod = this.organizationSettingService.getCustomerSetting(
      this.sampleMethodSettingName
    );
    this.sampleContentSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT;
  }

  toggleSampleContent(): void {
    if (this.newOrderObj.sample && this.newOrderObj.sample.content) {
      this.newOrderObj.sample.content.forEach((content) => {
        content.value = false;
      });
    }
  }

  clickOnContent(content: any): void {
    const con = this.newOrderObj.sample.content.find((cont) => {
      return cont.name === content.name;
    });
    if (con) {
      con.value = !con.value;
    }
  }

  // Method for remove default alphabetical order sorting in sample methods from keyvalue pipe
  returnZero() {
    return 0;
  }
}
