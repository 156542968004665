export const translationTransportEnglish = {
  // COMMON
  PICKUP: 'Pickup',
  DELIVERY: 'Delivery',
  NO_OF_PACKAGES: 'Number of packages',
  NO_OF_PACKAGES_HINT: 'Number of packages that should be transported',
  NO_PACKAGES: 'No packages',
  COMMENTS: 'Comments',
  INTERNAL_MESSAGE: 'Internal message (hidden from carrier)',
  OK: 'OK',
  PACKAGES: 'Packages',
  SAVE_CHANGES: 'Save changes',
  CANCEL_TRANSPORT: 'Cancel transport',
  SOMETHING_WENT_WRONG: 'Something went wrong',

  // NAV MENU
  TRANSPORT: 'Transport',
  NEW_TRANSPORT_ORDER: 'New transport order',
  TRANSPORT_ORDERS: 'Scheduled transports',
  DENTHUB_DESKTOP: 'Denthub',
  SETTINGS: 'Settings',
  LOGOUT: 'Logout',

  // MAIN PAGE
  PICKUP_FROM_CLINIC: 'Pickup from clinic',
  DELIVERY_TO_CLINIC: 'Delivery to clinic',
  SHOW_ALL_TRANSPORT_ORDERS: 'Show scheduled transport orders',

  // WIZARD
  CLINIC_STEP: 'Clinic',
  WHEN_STEP: 'When',
  DETAILS_STEP: 'Details',
  NEXT: 'Next',
  ORDER_TRANSPORT: 'Order transport',
  CANCEL_ORDER_TRANSPORT: 'Cancel order',
  CANCEL_ORDER_MSG: 'Translation cancelled. No transport order was made.',
  WIZARD_GENERIC_ERROR_MSG: 'Something went wrong. No transport order was made.',
  ORDER_PLACED_MSG: 'Order has been placed.',
  CHOOSE_CLINIC: 'Choose clinic',
  CHOOSE_CLINIC_HINT: 'Type the name of the clinic to search',
  DELIVERY_DATE: 'Delivery date',
  DELIVERY_DATE_HINT: 'The date when we deliver to the clinic',
  NOTE_TRANSPORT_DAYS_HINT: 'Note: More than 1 day between pickup and delivery.',
  PICKUP_FROM_YOU_DATE: 'We will pickup at you place:',
  PICKUP_DATE: 'Pickup date',
  PICKUP_DATE_HINT: 'The date when we pickup from the clinic',
  LATEST_DATE_DELIVERY: 'Latest date we will deliver to you:',
  EXISTING_TRANSPORT_ORDER_TITLE_PICKUP: 'A pickup already exists',
  EXISTING_TRANSPORT_ORDER_TITLE_DELIVERY: 'A delivery already exists',
  EXISTING_TRANSPORT_ORDER_TEXT:
    'Do you want to edit the existing transport order instead?',

  // DEADLINE PICKER
  DEADLINE: 'Deadline',
  DEADLINE_HINT: 'Latest time we need to deliver',

  // SCHEDULED TRANSPORTS
  PICKUPS: 'Pickups',
  DELIVERIES: 'Deliveries',
  NO_TRANSPORTS_TODAY: 'No transports ordered for this day.',
  PACKAGES_TRANSPORTED: 'packages transported',
  DENTHUB_ORDERS_TO_PICKUP: 'Denthub orders to pickup this day',
  ADDITIONAL_PACKAGES: 'Additional packages',
  ADDITIONAL_PACKAGES_HINT:
    'Additional packages that does not belong to a Denthub order',

  CAN_NOT_BE_MODIFIED: 'Can not be modified',
  ARE_YOU_SURE_CANCEL: 'Are you sure you want to cancel this transport?',

  // Transport status log
  TRANSPORT_STATUS_LOG: 'Transport status log',
  SHOW_DETAILS: 'Show details',
  EVENT: 'Event',
  NOTE_FROM_DRIVER: 'Note from driver',
  NO_STATUS_LOG_AVAILABLE: 'No status log available',
  TABLET_MODE: 'Switch to tablet mode',
  UNDER_OBSERVATION: 'Under observation'
};
