import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { CONSTANT } from '../../app.constant';
import { Language } from '../../models/language.model';
import languageSelectorTemplate from './language-selector.html';

@Component({
  selector: 'language-selector',
  template: languageSelectorTemplate
})
export class LanguageSelectorComponent implements OnInit {
  @Input() selected: Language;
  @Output() onSelect: EventEmitter<Language> = new EventEmitter<Language>();
  public languages: Language[] = CONSTANT.LANGUAGES;

  ngOnInit(): void {
    this.selected = this.languages[0];
  }

  languageSelected(): void {
    this.onSelect.emit(this.selected);
  }
}
