import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'plannedTransportFilter'
})
export class PlannedTransportFilterPipe implements PipeTransform {
  transform(transports: any, planned: boolean) {
    if (!planned) {
      return transports;
    }
    return transports.filter((transport) => {
      return transport.pickup.status || transport.delivery.status;
    });
  }
}
