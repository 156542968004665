import { Output, OnChanges, Component, EventEmitter } from '@angular/core';
import template from './add-image-modal.html';
import * as _ from 'lodash';
import { TranslationsService } from '../../../services/translations/translations.service';
import style from './add-image-modal.less';
import { ImageAnnotationService } from '../../../services/image-annotation/image-annotation.service';

@Component({
  selector: 'add-image-modal',
  template,
  styles: [style]
})
export class AddImageModalComponent implements OnChanges {
  @Output() onAddImage: EventEmitter<any> = new EventEmitter<any>();

  selectedBackground = 'template-new-image-blank';
  filename = '';

  constructor(
    private readonly translationsService: TranslationsService,
    private readonly imageAnnotationService: ImageAnnotationService
  ) {}

  ngOnChanges() {}

  onClose() {
    this.selectedBackground = 'template-new-image-blank';
    this.filename = '';
  }

  onBackgroundSelect(backgroundName) {
    this.selectedBackground = backgroundName;
  }

  addImage() {
    this.onAddImage.emit({
      elementId: this.selectedBackground,
      filename: this.filename + '.png'
    });
    this.onClose();
  }

  disableAddButton() {
    return !this.filename;
  }
}
