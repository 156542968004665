import { Component, OnInit, Input } from '@angular/core';
import template from './manage-labs.html';
import { Category } from '../../models/category.model';
import { User } from '../../models/user.model';
import { Organization } from '../../models/organization.model';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { OrganizationSettingService } from '../../services/organization-setting/organization-setting.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { CategoryService } from '../../services/manage-actions/category.service';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import * as _ from 'lodash';

@Component({
  selector: 'manage-labs',
  template: template
})
export class ManageLabsComponent implements OnInit {
  @Input() currentUser: User;
  labs: Organization[] = [];
  checkedLabs = {};
  sortBy = '';
  sortOrder = 'asc';
  sortType = true;
  userOrg: Organization;
  mainCategories: Category[];

  constructor(
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly logService: LogService,
    private readonly toastr: ToastrService,
    private readonly categoryService: CategoryService,
    private readonly translationsService: TranslationsService,
    private readonly subNavService: SubNavService,
    private readonly organizationService: OrganizationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.subNavService.addMenu({
      name: await this.translationsService.get('MANAGE_LABS'),
      state: 'app.manage-labs',
      isActive: true,
      isScrollSpy: false
    });
    if (
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.ACTION_BASED !==
      this.organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION
      )
    ) {
      //if this is not action based order then redirect to home.
      window.location.href = '/';
      return;
    }
    this.getMainCategories();
  }

  sortData(field: string): void {
    if (this.sortBy === field) {
      this.sortType = !this.sortType;
      this.sortOrder = this.sortType ? 'asc' : 'desc';
    } else {
      this.sortBy = field;
      this.sortType = true;
      this.sortOrder = 'asc';
    }
    this.userOrg.connectedLabs = _.orderBy(
      this.userOrg.connectedLabs,
      this.sortBy,
      this.sortOrder as 'asc' | 'desc'
    );
  }

  calculateLabRatings(labs, clinicId) {
    labs
      .filter((lab) => {
        return lab.lab;
      })
      .forEach((lab) => {
        const stat = lab.lab.statistics.find((stats) => {
          return stats.clinicId === clinicId;
        });
        lab.ratePercent =
          stat && stat.ratings ? (stat.ratings.satisfied / stat.numOrders) * 100 : 0;
        lab.totalRating = stat ? stat.numOrders : 0;
      });
    return labs;
  }

  getConnectedLabs() {
    this.organizationService
      .getConnectedLabsForClinic({
        organizationId: this.currentUser.organization._id
      })
      .then((result) => {
        this.userOrg = result.data;
        if (this.userOrg.connectedLabs.length) {
          this.userOrg.connectedLabs = this.calculateLabRatings(
            this.userOrg.connectedLabs,
            this.userOrg._id
          );
        }
        this.sortData('lab.name');
        this.getLabs();
      })
      .catch(async (err) => {
        this.handleError('getConnectedLabs', 'ERROR_IN_GETTING_LABS', err);
      });
  }

  getLabs() {
    this.organizationService
      .getAllLabs()
      .then((labs) => {
        this.labs = labs.data;
        this.getCheckedLabs();
      })
      .catch(async (err) => {
        this.handleError('getLabs', 'ERROR_IN_GETTING_LABS', err);
      });
  }

  getCheckedLabs() {
    this.labs.forEach((lab) => {
      this.checkedLabs[lab._id] = this.userOrg.connectedLabs.some((connectedLab) => {
        return connectedLab.lab && connectedLab.lab._id === lab._id;
      });
    });
  }

  openConnectToLabModal() {
    $('#connect-to-lab-modal').modal('show');
  }

  getMainCategories() {
    this.categoryService
      .getAllMainCategories()
      .then((response) => {
        this.mainCategories = response.data;
        this.getConnectedLabs();
      })
      .catch(async (err) => {
        this.handleError('getMainCategories', 'ERROR_IN_GETTING_CATEGORIES', err);
      });
  }

  onLabsConnected() {
    this.getConnectedLabs();
    this.getCheckedLabs();
    this.getLabs();
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(ManageLabsComponent.name, method, `${message} ${err}`);
    this.toastr.error(await this.translationsService.get(message));
  }
}
