import { Input, Component } from '@angular/core';
import _ = require('lodash');
import { Order } from '../../../models/order.model';
import { User } from '../../../models/user.model';
import template from './price-spec-modal.html';

@Component({
  selector: 'price-spec-modal',
  template
})
export class PriceSpecModalComponent {
  @Input() orderDetail: Order;
  @Input() currency: string;
  @Input() currentUser: User;
  @Input() totalVat: number;
  @Input() totalPrice: number;
}
