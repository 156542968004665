import { Component, Input } from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../../../../app.constant';
import { SubCategory } from '../../../../../../../../models/organization.model';
import { OrganizationSettingService } from '../../../../../../../../services/organization-setting/organization-setting.service';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './implant-step.html';

@Component({
  selector: 'implant-step',
  template
})
export class ImplantStepComponent {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;

  implantStepsSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.IMPLANT_STEPS;
  implantSteps: string[];

  constructor(
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly settingsService: ConstructionSettingsService
  ) {}

  ngOnInit(): void {
    this.implantSteps = this.organizationSettingService.getCustomerSetting(
      this.implantStepsSettingName
    );
  }
}
