import { Component, Input } from '@angular/core';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './protrusion.html';

@Component({
  selector: 'protrusion',
  template
})
export class ProtrusionComponent {
  @Input() isMandatory: boolean;

  @Input() protrusion: string;

  private readonly protrusionKey = 'protrusion';

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  setProtrusion(value: string) {
    const parsedNumer = parseFloat(value.replace(',', '.'));
    this.constructionSettingsService.setNumericSetting(
      this.protrusionKey,
      parsedNumer
    );
  }
}
