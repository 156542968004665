import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { Logistic, OrderStatus } from '../../models/logistic.model';
import { TranslationsService } from '../../services/translations/translations.service';
import {
  schedulePickupTemplate,
  schedulePickupFromTruckTemplate,
  updatePickupFromTruckTemplate,
  updatePickupTemplate,
  deleteTransportTemplate,
  getTransportsTemplate,
  scheduleDeliveryTemplate,
  updateDeliveryTemplate,
  toggleTransportTemplate,
  getTransportTemplate,
  statusChangedSubscriptionTemplate,
  getTransportStatusTemplate,
  getDeliveriesAndPickupsForDateTemplate
} from './logistics.graphql';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LogisticGraphqlService {
  constructor(
    private readonly apollo: Apollo,
    private readonly translationsService: TranslationsService
  ) {}

  schedulePickup(transport: Logistic): Promise<Logistic> {
    transport.transportDay = moment(transport.transportDate).format('YYYY-MM-DD');
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: schedulePickupTemplate,
          variables: transport,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['schedulePickup']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  schedulePickupFromTruck(transport: Logistic): Promise<Logistic> {
    transport.transportDay = moment(transport.transportDate).format('YYYY-MM-DD');
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: schedulePickupFromTruckTemplate,
          variables: transport,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['schedulePickupFromTruck']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updatePickupFromTruck(transport: Logistic): Promise<Logistic> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: updatePickupFromTruckTemplate,
          variables: transport,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['updatePickupFromTruck']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  scheduleDelivery(transport: Logistic): Promise<Logistic> {
    transport.transportDay = moment(transport.transportDate).format('YYYY-MM-DD');
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: scheduleDeliveryTemplate,
          variables: transport,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['scheduleDelivery']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updatePickup(transport: Logistic): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: updatePickupTemplate,
          variables: transport,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['updatePickup']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateDelivery(transport: Logistic): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: updateDeliveryTemplate,
          variables: transport,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['updateDelivery']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  deleteTransport(transportId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: deleteTransportTemplate,
          variables: { _id: transportId },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['deleteTransport']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getTransports(transportDay: string, isPlanned: boolean): Promise<Logistic[]> {
    return new Promise((resolve, reject) => {
      this.apollo
        .query({
          query: getTransportsTemplate,
          variables: { transportDay, isPlanned },
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            const logistics = cloneDeep(data['logistics']);
            for (let log of logistics) {
              log.transportDate = new Date(log.transportDay);
            }
            resolve(logistics);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getDeliveriesAndPickupsForDate(
    date: string,
    isPlanned: boolean
  ): Promise<Logistic[]> {
    return new Promise((resolve, reject) => {
      this.apollo
        .query({
          query: getDeliveriesAndPickupsForDateTemplate,
          variables: { date, isPlanned },
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            const logistics = cloneDeep(data['deliveriesAndPickupsForDate']);
            for (let log of logistics) {
              log.transportDate = new Date(log.transportDay);
            }
            resolve(logistics);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getTransport(_id: string): Promise<Logistic> {
    return new Promise((resolve, reject) => {
      this.apollo
        .query({
          query: getTransportTemplate,
          variables: { _id },
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            const logistic = cloneDeep(data['transport']);
            logistic.transportDate = new Date(logistic.transportDay);
            resolve(logistic);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  toggleTransport(isTransportEnable: {
    isTransportEnable: boolean;
  }): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: toggleTransportTemplate,
          variables: isTransportEnable,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['toggleTransport']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  statusChanged(labId: string): Observable<Logistic> {
    return this.apollo
      .subscribe({
        query: statusChangedSubscriptionTemplate,
        variables: { labId },
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      })
      .pipe(
        map((res) => JSON.parse(JSON.stringify(res.data['statusChanged']))),
        catchError((error) => {
          console.log(error, 'error');
          return of(null);
        })
      );
  }

  getTransportStatuses(opterOrderId: number): Promise<OrderStatus[]> {
    return new Promise((resolve, reject) => {
      this.apollo
        .watchQuery({
          query: getTransportStatusTemplate,
          variables: { opterOrderId },
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
          pollInterval: 0
        })
        .valueChanges.subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['transportStatuses']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
