import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { OrganizationService } from '../../services/clinic/organization.service';
import { Organization } from '../../models/organization.model';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../services/core/log.service';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { CustomerSetting } from '../../models/customer-setting.model';
import { sortedUniq } from 'lodash';
import template from './customer-settings.html';

@Component({
  selector: 'customer-settings',
  template: template
})
export class CustomerSettingsComponent implements OnInit {
  orgId: string;
  settingTypes: { [key: string]: string } = CUSTOMER_SETTINGS_CONSTANTS.TYPES;
  settings: CustomerSetting[] = [];
  settingCategories: string[] = [];
  organization: Organization;

  constructor(
    @Inject(forwardRef(() => '$state')) private $state: any,
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    private subNavService: SubNavService,
    private translationsService: TranslationsService,
    private currentUserService: CurrentUserService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.orgId = this.$state.params.id;
    if (this.orgId) {
      this.getOrganization(this.orgId);
    }
  }

  getSettingsForCategory(category: string): CustomerSetting[] {
    return this.settings.filter((setting) => setting.category === category);
  }

  // TODO: Rename this method. It is not a getter.
  getCustomerSettings(): void {
    this.organizationService
      .getCustomerSettings()
      .then((response) => {
        this.settings = response.data.map((setting) => {
          const existingSetting = this.organization.customerSettings.find(
            (thisExistingSetting) => thisExistingSetting.name === setting.name
          );

          if (existingSetting) {
            setting.value = existingSetting.value;
            if (setting.type === CUSTOMER_SETTINGS_CONSTANTS.TYPES.BOOLEAN) {
              setting.value =
                setting.value === 'true' || setting.value === true;
            }
          }

          return setting;
        });

        this.settingCategories = sortedUniq(
          this.settings.map((customerSetting) => customerSetting.category)
        );
      })
      .catch(async (err: any) => {
        this.logService.error(
          'customer-settings.component',
          'getCustomerSettings',
          (await this.translationsService.get('ERROR_IN_GETTING_SETTINGS')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_SETTINGS')
        );
      });
  }

  // TODO: Rename this method. Not a getter.
  getOrganization(orgId: string): void {
    this.organizationService
      .getOrganization({ id: orgId })
      .then(async (result) => {
        this.organization = result.data;
        const stateOnBackClick =
          this.organization.type === 'clinic'
            ? CUSTOMER_SETTINGS_CONSTANTS.ORGANIZATION_STATES.CLINICS
            : CUSTOMER_SETTINGS_CONSTANTS.ORGANIZATION_STATES.LABS;

        const subMenuName =
          stateOnBackClick === 'clinics'
            ? CUSTOMER_SETTINGS_CONSTANTS.STATE_MESSAGES.CLINICS
            : CUSTOMER_SETTINGS_CONSTANTS.STATE_MESSAGES.LABS;

        this.subNavService.addMenu({
          name: await this.translationsService.get(subMenuName),
          state: 'app.' + stateOnBackClick,
          isActive: true,
          isScrollSpy: false,
          icon: 'fa-angle-left'
        });
        this.getCustomerSettings();
      })
      .catch(async (err: any) => {
        this.logService.error(
          'customer-settings.component',
          'getOrganization',
          (await this.translationsService.get(
            'ERROR_IN_GETTING_ORGANIZATION'
          )) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_ORGANIZATION')
        );
      });
  }

  saveCustomerSettings(): void {
    const saveSettings: CustomerSetting[] = this.settings.map((setting) => ({
      name: setting.name,
      value: setting.value
    }));
    this.organizationService
      .updateOrganization(this.orgId, { customerSettings: saveSettings })
      .then(async (result) => {
        this.toastr.success(
          await this.translationsService.get('SETTINGS_SAVED_SUCCESSFULLY')
        );
        return this.currentUserService.updateCurrentUser();
      })
      .catch(async (err: any) => {
        this.logService.error(
          'customer-settings.component',
          'saveCustomerSettings',
          (await this.translationsService.get(
            'ERROR_IN_UPDATING_ORGANIZATION'
          )) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_UPDATING_ORGANIZATION')
        );
      });
  }

  isJsonValid(json: any): boolean {
    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      return false;
    }
  }
}
