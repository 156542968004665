import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import template from './transport-status-log-dialog.html';
import { OrderStatus } from '../../../../models/logistic.model';

@Component({
  selector: 'transport-status-log-dialog',
  template: template
})
export class TransportStatusLogDialogComponent implements OnInit {
  displayedColumns: string[] = ['DateAndTime', 'Status', 'POD_Descr'];
  dataSource: OrderStatus[];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { transportStatuses: OrderStatus[]; opterOrderId: string }
  ) {}

  ngOnInit() {
    this.dataSource = this.data.transportStatuses;
    console.log(this.dataSource);
  }
}
