import { Injectable } from '@angular/core';

@Injectable()
export class NewFrontendNavigateService {
  navigate(urlSegment: string) {
    window.top.postMessage(
      {
        eventType: 'navigate',
        urlSegment: urlSegment
      },
      '*'
    );
  }
}
