import { ChangeDetectorRef, Pipe, PipeTransform, ViewRef } from '@angular/core';
import { TranslationsService } from '../services/translations/translations.service';
import { EventService } from '../services/core/event.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  private static cache: { [key: string]: string } = {};

  constructor(
    private eventService: EventService,
    private translationsService: TranslationsService
  ) {
    eventService.on('translationsExpired', () => {
      TranslatePipe.cache = {};
    });
  }

  transform(key: string, args?: any): string {
    if (!TranslatePipe.cache[key]) {
      const translation = this.translationsService.translate(key);
      TranslatePipe.cache[key] = translation;
      return translation;
    } else {
      return TranslatePipe.cache[key];
    }
  }
}
