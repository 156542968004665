import { HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';

import { Status } from '../models/status.model';
import { ResourceInterceptor } from '../services/core/resource.service';
import { SessionService } from '../services/core/session.service';
import { TokenService } from '../services/core/token.service';
import { EventService } from '../services/core/event.service';
import * as angular from 'angular';

@Injectable()
export class ApiInterceptor implements ResourceInterceptor<any> {
  constructor(
    private sessionService: SessionService,
    private tokenService: TokenService,
    private eventService: EventService,
    @Inject(forwardRef(() => '$location')) private $location: any
  ) {}

  request(url: string, data: any): Promise<any> {
    if (data) {
      // TODO: Remove this after migrating to Angular is complete. Removes angular specific helper properties such as $$hashKey.
      return JSON.parse(angular.toJson(data));
    } else {
      return data;
    }
  }

  logoutRedirect(): void {
    window.top.postMessage(
      {
        eventType: 'logout'
      },
      '*'
    );
  }

  response(url: string, data: any): Promise<any> {
    if (data && data.code && data.code === Status.AUTH_FAILED) {
      console.log("AuthenticError when calling '" + url + "'!", data);
      this.logoutRedirect();
    } else if (data && data.code && data.code < 0) {
      console.log("Error when calling '" + url + "'!", data);
      throw new Error(JSON.stringify(data));
    }

    return Promise.resolve(data);
  }

  responseError(url: string, response: HttpErrorResponse): Promise<any> {
    if (response.status === 401 || response.status === 403) {
      console.log("Call to '" + url + "' failed. Not logged in.");
      this.logoutRedirect();
    } else if (response.error && response.error.code === Status.AUTH_FAILED) {
      this.logoutRedirect();
      throw new Error('You were logged out!');
    }
    if (response.status === 409 && response.error) {
      throw new Status(response.status, response.error.message);
    }
    if (response.status !== 500 && response.error) {
      throw new Status(response.error.code, response.error.message);
    }

    throw new Error(
      'HTTP Error ' + response.status + '! - ' + JSON.stringify(response.error)
    );
  }

  headers(url: string, headers: HttpHeaders): Promise<HttpHeaders> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    if (this.tokenService.isAuthenticated()) {
      return Promise.resolve(
        headers.set('Authorization', this.tokenService.getToken())
      );
    }

    return Promise.resolve(headers);
  }
}
