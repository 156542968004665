/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './complete-denture.html'
/**
 * order removable complete denture construction component
 */
(function () {
    'use strict';
    angular.module('app').component('completeDenture', {
        template: theTemplate,
        controller: completeDentureController,
        controllerAs: 'vm',
        bindings: {
            constructionSettings: '=',
            selectedTeethRange: '=',
            selectedTeeth: '=',
            orderIdToModify: '=',
            addUpdateCompleteDenture: '=',
            removeCompleteDenture: '=',
            selectedCompleteDentureType: '=',
            completeDentureData: '<',
            selectedConstructionObj: '=',//currently selected constructions objects
            tempCompleteDentureData: "=",
            tempBitetraysData: "=",
            completeDentureCommentsArray: "="
        }
    });

    /**
     * Inject the dependencies here
     * @type {string[]}
     */
    completeDentureController.$inject = [ '$scope', 'userService', 'tokenService','CONSTRUCTIONS', 'DATE', 'organizationSettingService', 'CUSTOMER_SETTINGS_CONSTANTS' ];

    /**
     * Controller function for the complete denture construction
     */
    function completeDentureController($scope, userService, tokenService,CONSTRUCTIONS, DATE, organizationSettingService, CUSTOMER_SETTINGS_CONSTANTS) {

        var vm = this;
        vm.constructionName = {
            bitetray: CONSTRUCTIONS.BITETRAY,
            completeDenture: CONSTRUCTIONS.COMPLETE_DENTURE
        };
        if (!vm.completeDentureData) {
            //if null initialize. though this is not expected
            vm.completeDentureData = {};
        }
        vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;

        //characteristics settings value according customer settings for translation
        vm.dentureStepsSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.DENTURE_STEPS;

        //set characteristics settings value from customer settings
        vm.dentureSteps = organizationSettingService.getCustomerSetting(vm.dentureStepsSettingName);

        /**
         * Member functions
         */
        vm.setMaterial = setMaterial;
        vm.setDentureSteps = setDentureSteps;

        /**
         * select and de select
         */
        function setMaterial(material) {
            if (material) {
                if (material === vm.completeDentureData.material) {
                    vm.completeDentureData.material = '';
                } else {
                    if (vm.selectedCompleteDentureType === 'complete denture') {
                        vm.tempCompleteDentureData.material = material;
                    } else {
                        vm.tempBitetraysData.material = material;
                    }
                    vm.completeDentureData.material = material;
                }
            }
        }

        // Watch Selected Construction type object for the old construction data.
        $scope.$watch('vm.selectedConstructionObj',function() {
            // If the selected bridge type is complete denture
            if (vm.selectedCompleteDentureType === 'complete denture') {
                // Check if old bridge construction is present or not
                if (vm.selectedConstructionObj && vm.selectedConstructionObj.completeDentures && vm.selectedConstructionObj.completeDentures.length > 0) {
                    var completeDenture = vm.selectedConstructionObj.completeDentures[vm.selectedConstructionObj.completeDentures.length - 1];
                    //get old construction
                    if (!vm.completeDentureData) {
                        vm.completeDentureData = {
                            shade: completeDenture.shade,
                            color: completeDenture.color,
                            allComments: completeDenture.allComments,
                            createdAt: completeDenture.createdAt,
                            colorSampling: completeDenture.colorSampling
                        };
                        vm.dentureSteps.forEach(function (dentureStep) {
                            vm.completeDentureData[dentureStep] = completeDenture[dentureStep];
                        });
                    }
                } else {
                    userService.getCurrentUser().then(function (result) {
                        var userProfile = result.data;
                        if (userProfile.constructionSetting) {
                            //get default construction
                            if (vm.tempCompleteDentureData && Object.keys(vm.tempCompleteDentureData).length > 0) {
                                vm.completeDentureData = {
                                    shade: vm.tempCompleteDentureData.shade,
                                    color: vm.tempCompleteDentureData.color,
                                    createdAt: vm.tempCompleteDentureData.createdAt,
                                    colorSampling: vm.tempCompleteDentureData.colorSampling,
                                    allComments: vm.tempCompleteDentureData.allComments
                                };
                                vm.dentureSteps.forEach(function (dentureStep) {
                                    vm.completeDentureData[dentureStep] = vm.tempCompleteDentureData[dentureStep];
                                });
                            }

                        }
                    });
                }
            } else if (vm.selectedCompleteDentureType === 'bitetray') {
                //if we previously created any implant bridge then use that else create empty
                if (vm.selectedConstructionObj && vm.selectedConstructionObj.bitetrays && vm.selectedConstructionObj.bitetrays.length > 0) {
                    var bitetray = vm.selectedConstructionObj.bitetrays[vm.selectedConstructionObj.bitetrays.length - 1];
                    if (!vm.completeDentureData) {
                        vm.completeDentureData = {
                            material: bitetray.material,
                            softInside: bitetray.softInside,
                            allComments: bitetray.allComments,
                            createdAt: bitetray.createdAt,
                            otherMaterial: bitetray.otherMaterial
                        };
                    }

                } else {
                    userService.getCurrentUser().then(function (result) {
                        var userProfile = result.data;
                        if (userProfile.constructionSetting) {
                            //get default construction
                            if (vm.tempBitetraysData && Object.keys(vm.tempBitetraysData).length > 0) {
                                vm.completeDentureData = {
                                    material: vm.tempBitetraysData.material,
                                    softInside: vm.tempBitetraysData.softInside,
                                    createdAt: vm.tempBitetraysData.createdAt,
                                    otherMaterial: vm.tempBitetraysData.otherMaterial,
                                    allComments: vm.tempBitetraysData.allComments

                                };
                            }
                        }
                    });
                }
            }

        },true);

        /**
         * Save teeth construction
         */
        $scope.$watch('vm.completeDentureData',function(newVal, oldval) {
            if (vm.selectedCompleteDentureType === 'complete denture') {
                if (vm.completeDentureData && ((vm.completeDentureData.individualTray || vm.completeDentureData.biteBlock) || (vm.completeDentureData.colorSampling || (vm.completeDentureData.shade && vm.completeDentureData.color)))) {
                    //it is valid construction. lets save it
                    vm.completeDentureData.teethRange = vm.selectedTeethRange;
                    vm.completeDentureData.type = 'complete denture';
                    vm.addUpdateCompleteDenture(angular.copy(vm.completeDentureData),vm.selectedCompleteDentureType);
                }
            } else {
                if (vm.completeDentureData && vm.completeDentureData.material) {
                    if (((vm.completeDentureData.material === 'Other' && vm.completeDentureData.otherMaterial) ||
                            (vm.completeDentureData.material && vm.completeDentureData.material !== 'Other'))) {
                        //it is valid construction. lets save it
                        vm.completeDentureData.teethRange = vm.selectedTeethRange;
                        vm.completeDentureData.type = 'bitetray';
                        vm.addUpdateCompleteDenture(angular.copy(vm.completeDentureData), vm.selectedCompleteDentureType);
                    }
                }
            }
            if (!vm.orderIdToModify && vm.completeDentureData && !vm.completeDentureData.createdAt) {
                vm.completeDentureData.createdAt = new Date().getTime();
            }
        },true);

        /**
         * Set denture steps
         * @param selectedStep
         */
        function setDentureSteps(selectedStep) {
            if (vm.dentureSteps) {
                vm.dentureSteps.forEach(function(step) {
                    vm.completeDentureData[step] = (step === selectedStep);
                });
            }
        }
    }

})();