import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import template from './users.html';
import { User } from '../../models/user.model';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { PermissionService } from '../../services/core/permission.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { UsersService } from '../../services/users/users.service';
import { UserRoleService } from '../../services/roles/user-role.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { EventService } from '../../services/core/event.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { GetAllUsersService } from '../../services/order-list/get-all-users.service';
import { SessionService } from '../../services/core/session.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Role } from '../../models/roles.model';
import { COMPONENTS } from '../../app.constant';
import { UsersGraphqlService } from '../../graphql-services/users/users.graphql.service';
@Component({
  selector: 'users',
  template: template
})
export class UsersComponent implements OnInit, AfterViewInit {
  @Input() currentUser: User;
  @ViewChild('spinner') spinner: SpinnerComponent;
  impersonatedUser: User;
  users: User[] = [];
  isSuperAdmin: boolean;
  busy: boolean = false;
  searchText: string = '';
  pageNo: number = 0;
  roles: Role[];
  selectedClinicsIds: string[];
  selectedUser: Partial<User>;
  currentUserPermissions: any = {};

  constructor(
    private readonly subNavService: SubNavService,
    private readonly permissionService: PermissionService,
    private readonly userRoleService: UserRoleService,
    private readonly logService: LogService,
    private readonly toastr: ToastrService,
    private readonly userService: UsersService,
    private readonly getAllUsersService: GetAllUsersService,
    private readonly currentUserService: CurrentUserService,
    private readonly eventService: EventService,
    private readonly sessionService: SessionService,
    private readonly translationsService: TranslationsService,
    private readonly usersGraphqlService: UsersGraphqlService
  ) {}

  ngAfterViewInit(): void {
    this.spinner.show();
  }

  searchTextChanged(value: string): void {
    this.searchText = value;
    // clear data when user tries to search through the list
    this.getAllUsersService.clearUsersData();
    this.getUsers(true);
  }

  impersonateUser(): void {
    this.currentUserService
      .getUserData()
      .then((result) => {
        if (result) {
          this.impersonatedUser = result;
          this.isSuperAdmin = this.permissionService.isSuperAdmin(
            this.impersonatedUser
          );
          if (!this.isSuperAdmin) {
            this.getUserList();
          } else {
            this.getAllUsersForSuperAdmin(true);
          }
        }
      })
      .catch((err) => {
        this.currentUserService.logout();
        this.logService.error('user.component', 'on user event', err);
      });
  }

  async ngOnInit(): Promise<void> {
    this.isSuperAdmin = this.permissionService.isSuperAdmin(this.currentUser);
    this.impersonatedUser = this.currentUser;
    await this.setUserRolesExcept('TECHNICIAN');
    await this.addMenus();
    this.getUsers(true);
    this.eventService.on('user', () => {
      this.impersonateUser();
    });
  }

  // TODO: Rename this method. It is not a getter.
  getAllUsersForSuperAdmin(refresh: boolean): void {
    const clinicIds = JSON.parse(
      this.sessionService.getItem('selectedParentClinicsIds')
    );
    if (clinicIds && clinicIds.length > 0) {
      this.selectedClinicsIds = clinicIds;
    }
    if (this.currentUser) {
      if (!this.getAllUsersService.getUserData() || refresh) {
        this.userService
          .getAllUsersForSuperAdmin({
            type: 'dentist',
            pageNo: 0,
            filter: this.searchText,
            clinicIds: this.selectedClinicsIds
          })
          .then((result) => {
            this.spinner.hide();
            const users = result.data;
            if (users) {
              this.users = users;
              this.getAllUsersService.getAllUsers(users, 0); // set data in service
            }
          })
          .catch(async (err: any) => {
            const text = await this.translationsService.get(
              'ERROR_IN_GETTING_USERS'
            );
            this.logService.error(
              COMPONENTS.USER_LIST,
              'getAllUsersForSuperAdmin',
              text + ': ' + err
            );
            this.toastr.error(text);
          });
      } else {
        this.users = this.getAllUsersService.getUserData().users; // get users data from service;
        this.pageNo = this.getAllUsersService.getUserData().pageNo;
        this.spinner.hide();
      }
    }
  }

  pagination(): void {
    if (this.busy || this.pageNo <= 0) {
      return;
    }
    this.busy = true;
    const clinicIds = JSON.parse(
      this.sessionService.getItem('selectedParentClinicsIds')
    );
    if (clinicIds && clinicIds.length > 0) {
      this.selectedClinicsIds = clinicIds;
    }
    if (this.isSuperAdmin && this.currentUser) {
      this.userService
        .getAllUsersForSuperAdmin({
          type: 'dentist',
          pageNo: this.pageNo,
          filter: this.searchText,
          clinicIds: this.selectedClinicsIds
        })
        .then((result) => {
          this.busy = result.data.length <= 0;
          this.users = this.users.concat(result.data);
          this.pageNo += 1;
          this.getAllUsersService.getAllUsers(this.users, this.pageNo); // set data in service
        })
        .catch(async (err: any) => {
          const text = await this.translationsService.get('ERROR_IN_GETTING_USERS');
          this.logService.error(
            COMPONENTS.USER_LIST,
            'pagination if-part',
            text + ': ' + err
          );
          this.toastr.error(text);
        });
    } else {
      if (this.impersonatedUser && this.impersonatedUser.organization) {
        this.userService
          .getAllUsers({
            organizationId: this.impersonatedUser.organization._id,
            type: 'dentist',
            filter: this.searchText,
            pageNo: this.pageNo
          })
          .then((result) => {
            this.busy = result.data.users.length <= 0;
            this.users = this.users.concat(result.data.users);
            this.pageNo += 1;
            this.getAllUsersService.getAllUsers(this.users, this.pageNo); // set data in service
          })
          .catch(async (err: any) => {
            const text = await this.translationsService.get(
              'ERROR_IN_GETTING_USERS'
            );
            this.logService.error(
              COMPONENTS.USER_LIST,
              'pagination else-part',
              text + ': ' + err
            );
            this.toastr.error(text);
          });
      }
    }
  }

  onUserUpdate(user: User): void {
    this.selectedUser = user;
    $('#add-user-modal').modal('show');
  }

  closeModal(): void {
    this.selectedUser = {
      name: {},
      systemSetting: {},
      organization: {}
    };
    $('#add-user-modal').modal('hide');
  }

  onUserChange(): void {
    this.users = [];
    this.getAllUsersService.clearUsersData(); // clear users data in service
    this.getUsers();
  }

  setUserRolesExcept(roleType: string): Promise<void> {
    return this.userRoleService
      .getRolesExcept(roleType)
      .then((roles) => {
        this.roles = roles;
      })
      .catch(async (err: any) => {
        const text = await this.translationsService.get('ERROR_IN_GETTING_ROLES');
        this.logService.error(
          'users.component',
          'setUserRolesExcept',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  getUsers(refresh?: boolean): void {
    if (this.isSuperAdmin) {
      this.getAllUsersForSuperAdmin(refresh);
    } else {
      this.getUserList();
    }
  }

  onFilterUpdate(selectedClinicsIds: string[]): void {
    this.selectedClinicsIds = selectedClinicsIds;
    if (this.selectedClinicsIds) {
      this.sessionService.setItem(
        'selectedParentClinicsIds',
        JSON.stringify(this.selectedClinicsIds)
      );
    }
    this.spinner.show();
    this.busy = false;
    this.pageNo = 0;
    this.getUsers(true);
  }

  async addMenus(): Promise<void> {
    this.subNavService.resetList();
    this.subNavService.addMenu({
      name: await this.translationsService.get('LIST_VIEW'),
      state: 'app.users',
      icon: 'fa-list-ul',
      isActive: true,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('CARD_VIEW'),
      state: 'app.users-card',
      icon: 'fa-th',
      isActive: false,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('ADD_USER'),
      icon: 'fa-plus',
      action: 'add-user-modal',
      isModel: true,
      isClass: true
    });
  }

  async getUserList() {
    try {
      if (!this.getAllUsersService.getUserData()) {
        const users: User[] = await this.usersGraphqlService.getUsers({
          organizationId: this.impersonatedUser.organization._id,
          pageNo: 0,
          filter: this.searchText
        });
        this.spinner.hide();
        this.users = users;
        this.getAllUsersService.getAllUsers(users, 0);
      } else {
        this.users = this.getAllUsersService.getUserData().users; // get users data from service;
        this.pageNo = this.getAllUsersService.getUserData().pageNo;
        this.spinner.hide();
      }
    } catch (err) {
      this.logService.newError(err);
      const text = await this.translationsService.get('ERROR_IN_GETTING_USERS');
      this.logService.error(COMPONENTS.USER_LIST, 'getAllUsers', `${text}: ${err}`);
      this.toastr.error(text);
    }
  }
}
