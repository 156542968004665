import { PipeTransform, Pipe } from '@angular/core';
import { LocaleFormatterService } from '../services/locale-formatter/locale-formatter.service';

@Pipe({
  name: 'currencyLocale'
})
export class CurrencyLocalePipe implements PipeTransform {
  constructor(private localeFormatterService: LocaleFormatterService) {}

  transform(price: number, currency: string): string {
    return this.localeFormatterService.formatCurrency(price, currency);
  }
}
