import { OnInit, Inject, forwardRef, Input, Component, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash';
import { LogService } from '../../../services/core/log.service';
import { OrganizationService } from '../../../services/clinic/organization.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../models/user.model';
import {
  Organization,
  Category,
  MaterialPriceDifference,
  ConnectedLab,
  MaterialGroup
} from '../../../models/organization.model';
import { TranslationsService } from '../../../services/translations/translations.service';
import template from './edit-lab.html';
import { Action, PriceList } from '../../../models/action.model';
import { SubNavService } from '../../../services/sub-nav/sub-nav.service';
import { COMPONENTS } from '../../../app.constant';
import { CountryNameService } from '../../../services/country-name/country-name';

@Component({
  selector: 'edit-lab',
  template: template
})
export class EditLabComponent implements OnInit, OnDestroy {
  // TODO:- initialize these
  @Input() currentUser: User;
  selectedCategory: Category;
  userOrg: Organization;

  labId = '';
  isLabDisabled = false;
  isRequireException = false;
  isSaveDisabled = false;
  actions: Action[] = [];
  labRank = 0;
  mainCategories: Category[] = [];
  selectedCategoryId = '';
  selectedMainCategories: Category[] = [];
  allActions: Action[] = [];
  lab: Partial<Organization> = {};
  materialPriceDifference: MaterialPriceDifference[] = [];
  priceDiffIndex = -1;
  selectedActionSet: string;
  connectedLabActionSet: any = {};

  constructor(
    private readonly logService: LogService,
    private readonly toastr: ToastrService,
    private readonly translationsService: TranslationsService,
    private readonly subNavService: SubNavService,
    private readonly organizationService: OrganizationService,
    private readonly countryNameService: CountryNameService,
    @Inject(forwardRef(() => '$state')) private readonly $state: any
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.subNavService.addMenu({
        name: await this.translationsService.get('BACK_HANDLING_LABS'),
        state: 'app.manage-labs',
        isActive: true,
        isScrollSpy: false,
        icon: 'fa-angle-left'
      });
      if (this.$state.params.labId) {
        this.labId = this.$state.params.labId;
        this.selectedActionSet = this.currentUser.organization.activeActionSet;
        this.fetchActionSet();
        //For getting action set data from new frontend
        window.addEventListener('message', this.handleConnectedLabActionSetEvent.bind(this));
      }
    } catch (err) {
      console.log(err, 'err');
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleConnectedLabActionSetEvent);
  }

  closeModal(): void {
    this.priceDiffIndex = -1;
    $('#material-price-diff-modal').modal('hide');
  }

  checkIfLabIsDisabled(labId: string, connectedLabs: ConnectedLab[]): void {
    this.isLabDisabled = connectedLabs.some((lab) => {
      return lab.lab && lab.lab._id === labId && lab.isDisabled;
    });
    this.isRequireException = connectedLabs.some((lab) => {
      return lab.lab && lab.lab._id === labId && lab.isRequireException;
    });
  }

  disableEnableSaveButton(): void {
    const clonedActions = cloneDeep(this.actions);
    this.isSaveDisabled = !clonedActions
      .filter((clonedAction) => clonedAction.enable)
      .every((action: any) => {
        return (
          action.price !== undefined && action.price !== null && action.deliveryTime
        );
      });
    this.isSaveDisabled = this.labRank <= 0 || this.isSaveDisabled;
  }

  toggleAction(actionId: string, isActionEnable: boolean): void {
    if (!isActionEnable) {
      this.actions.forEach((action) => {
        if (action._id === actionId) {
          action.price = undefined;
          action.deliveryTime = undefined;
        }
      });
    }
  }

  isException(): Promise<void> {
    this.userOrg.connectedLabs.forEach((lab) => {
      if (lab.lab && lab.lab._id === this.labId) {
        lab.isRequireException = this.isRequireException;
      }
    });
    return this.organizationService
      .updateOrganization(this.userOrg._id, this.userOrg)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
      })
      .catch(async (err) => {
        this.logService.error(
          COMPONENTS.EDIT_LAB,
          'isException',
          `${await this.translationsService.get('ERROR_IN_UPDATE_LAB')}: ${err}`
        );
        this.toastr.error(await this.translationsService.get('ERROR_IN_UPDATE_LAB'));
      });
  }

  getMaterialPriceDiff(
    materialPriceDifference: MaterialPriceDifference[],
    materialGroups: MaterialGroup[]
  ): MaterialPriceDifference[] {
    return materialGroups.map((mg) => {
      const priceDiff: MaterialPriceDifference | any = materialPriceDifference.find(
        (matPriceDiff) => mg._id === matPriceDiff.materialGroupId
      );

      const materials: any = mg.materials.map((mat) => {
        const material = {
          material: mat._id,
          materialName: mat.material,
          priceDiff: null
        };
        if (!priceDiff) {
          material.priceDiff = null;
          return material;
        }
        const pMat = priceDiff.materials.find(
          (material) => mat._id === material.material
        );
        if (!pMat) {
          material.priceDiff = null;
          return material;
        }
        material.priceDiff = pMat.priceDiff || null;
        return material;
      });

      return {
        actionSet: mg.actionSet,
        materialGroupId: mg._id,
        materialGroupName: mg.name,
        materials: materials
      };
    });
  }

  getConnectedLabs(): Promise<void> {
    return this.organizationService
      .getConnectedLabsForClinic({
        organizationId: this.currentUser.organization._id
      })
      .then((result) => {
        this.userOrg = result.data;
        this.checkIfLabIsDisabled(this.labId, this.userOrg.connectedLabs);
        const connectedLab = this.userOrg.connectedLabs.find(
          (lab) => lab.lab && lab.lab._id === this.labId
        );
        this.lab = connectedLab.lab;
        if (this.lab) {
          this.countryNameService
            .countryName(this.lab.countryCode)
            .then((name: string) => this.lab.countryName = name);
        }
        let savedPriceListCategory: string[] = [];
        if (connectedLab.priceList) {
          savedPriceListCategory = connectedLab.priceList
            .filter((price) => {
              return (
                this.selectedMainCategories.findIndex(
                  (category) => category._id === price.category &&
                    this.selectedActionSet === price.actionSet
                ) === -1
              );
            })
            .map((price) => price.category);
        }

        this.selectedMainCategories = this.mainCategories.filter((category) => {
          return savedPriceListCategory.indexOf(category._id) !== -1;
        });

        this.mainCategories = this.mainCategories.filter((category) => {
          return (
            this.selectedMainCategories.findIndex(
              (selectedCategory) => selectedCategory._id === category._id
            ) === -1
          );
        });
      })
      .catch(async (err) => {
        console.log(err, 'err');

        this.logService.error(
          COMPONENTS.EDIT_LAB,
          'getConnectedLabs',
          `${await this.translationsService.get('ERROR_IN_GETTING_LABS')}: ${err}`
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_LABS')
        );
      });
  }

  enableDisableLab(): Promise<void> {
    this.userOrg.connectedLabs.forEach((lab) => {
      if (lab.lab && lab.lab._id === this.labId) {
        lab.isDisabled = !lab.isDisabled;
      }
    });
    return this.organizationService
      .updateOrganization(this.userOrg._id, this.userOrg)
      .then(() => {
        this.isLabDisabled = !this.isLabDisabled;
      })
      .catch(async (err) => {
        this.logService.error(
          COMPONENTS.EDIT_LAB,
          'enableDisableLab',
          `${await this.translationsService.get('ERROR_IN_UPDATE_LAB')}: ${err}`
        );
        this.toastr.error(await this.translationsService.get('ERROR_IN_UPDATE_LAB'));
      });
  }

  async selectMainCategory(): Promise<void> {
    this.labRank = 0;
    this.selectedCategory = this.mainCategories.find(
      (category) => category._id === this.selectedCategoryId
    );
    if (this.selectedCategory && this.selectedCategory._id) {
      this.actions = (this.selectedCategory as any).actions;
      this.actions.forEach((action) => {
        action.enable = true;
      });
    }
  }

  getPriceList(category: Category): void {
    this.selectedCategory = category;
    this.selectedCategoryId = '';
    this.allActions = (this.selectedCategory as any).actions;
    const priceList = (this.selectedCategory as any).priceList[0];
    this.actions = priceList.actions;
    this.actions.forEach((act: any) => {
      this.allActions.forEach((allAct) => {
        if (act.action === allAct._id) {
          act.name = allAct.name;
          act.number = allAct.number;
          act._id = allAct._id;
        }
      });
    });
    this.labRank = priceList.rank;
    const filteredActions = this.allActions.filter((action) => {
      return this.actions.every((act: any) => act.action !== action._id);
    });
    filteredActions.forEach((filteredAction) => {
      filteredAction.enable = true;
    });
    this.actions = this.actions.concat(filteredActions);
  }

  getPriceListData(): PriceList {
    const actions = this.actions.map((action) => {
      return {
        action: action._id,
        price: action.price,
        deliveryTime: action.deliveryTime,
        enable: action.enable
      };
    });
    return {
      actionSet: this.selectedActionSet,
      category: this.selectedCategory._id,
      rank: this.labRank,
      actions: actions
    };
  }

  editMaterialPriceDiff(index: number): void {
    this.priceDiffIndex = index;
    $('#material-price-diff-modal').modal('show');
  }

  addPriceListToLab(): Promise<void> {
    const priceList: { priceList: PriceList } = {
      priceList: this.getPriceListData()
    };
    return this.organizationService
      .addPriceListToLab(this.labId, priceList)
      .then(async () => {
        const category = this.mainCategories.find(
          (mainCategory) => this.selectedCategory._id === mainCategory._id
        );
        if (category) {
          const categoryIndex = this.mainCategories.indexOf(category);
          this.mainCategories.splice(categoryIndex, 1);
          const priceListAlreadyAdded = this.selectedMainCategories.some(
            (selectedCategory) => selectedCategory._id === category._id
          );
          if (!priceListAlreadyAdded) {
            this.selectedMainCategories.push(category);
          }
        }
        const cLab = this.userOrg.connectedLabs.find(
          (cLab) => cLab.lab && cLab.lab._id === this.labId
        );
        if (cLab) {
          const priceCategory = priceList.priceList.category;
          const index = cLab.priceList.findIndex(
            (priceList) => priceList.category === priceCategory &&
              this.selectedActionSet === priceList.actionSet
          );
          if (index > -1) {
            cLab.priceList[index] = priceList.priceList;
            (this.selectedCategory as any).priceList[index] = priceList.priceList;
          } else {
            cLab.priceList.push(priceList.priceList);
            (this.selectedCategory as any).priceList.push(priceList.priceList);
          }
        }
        this.actions = [];
        this.selectedCategoryId = '';
        this.isSaveDisabled = true;
        this.toastr.success(
          await this.translationsService.get('PRICE_LIST_ADD_SUCCESS')
        );
      })
      .catch(async () => {
        this.logService.error(
          COMPONENTS.EDIT_LAB,
          'addPriceListToLab',
          await this.translationsService.get('ERROR_IN_ADDING_PRICE_LIST')
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_ADDING_PRICE_LIST')
        );
      });
  }

  removePriceList(): Promise<void> {
    return this.organizationService
      .removePriceList({
        labId: this.labId,
        categoryId: this.selectedCategory._id
      })
      .then(async () => {
        const index = this.selectedMainCategories.findIndex(
          (category) => category._id === this.selectedCategory._id
        );
        this.selectedCategoryId = '';
        this.mainCategories.push(this.selectedCategory);
        this.actions = [];
        this.labRank = 0;
        this.selectedMainCategories.splice(index, 1);
        this.toastr.success(
          await this.translationsService.get('PRICE_LIST_REMOVED_SUCCESS')
        );
      })
      .catch(async () => {
        this.logService.error(
          COMPONENTS.EDIT_LAB,
          'removePriceList',
          await this.translationsService.get('ERROR_IN_REMOVING_PRICE_LIST')
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_REMOVING_PRICE_LIST')
        );
      });
  }

  async formattedConnectedLabActionSetData(actionSet) {
    this.connectedLabActionSet = actionSet;
    this.materialPriceDifference = this.getMaterialPriceDiff(
      this.connectedLabActionSet.materialPriceDifference,
      this.connectedLabActionSet.materialGroups
    );
    this.mainCategories = this.connectedLabActionSet.categories;
    await this.getConnectedLabs();
    this.actions = [];
  }

  actionSetChanged(id: string) {
    this.selectedActionSet = id;
    this.fetchActionSet();
  }

  async handleConnectedLabActionSetEvent(e) {
    if (
      e.data &&
      e.origin === process.env.NEW_APP_URL
    ) {
      switch (e.data.eventType) {
        case 'get-connected-lab-action-set':
          await this.formattedConnectedLabActionSetData(e.data.actionSet);
          break;
        default:
          throw new Error('Unhandled event type: ' + e.data.eventType);
      }
    }
  }

  fetchActionSet() {
    window.top.postMessage(
      {
        eventType: 'connected-lab-action-set',
        actionSetId: this.selectedActionSet,
        labId: this.labId,
        orgId: this.currentUser.organization._id,
      },
      '*'
    );
  }
}
