import { Output, Input, Component, EventEmitter } from '@angular/core';
import template from './production-confirm-modal.html';

@Component({
  selector: 'production-confirm-modal',
  template: template
})
export class ProductionConfirmModalComponent {
  @Input() isProductionFinished: boolean;
  @Output() onConfirm: EventEmitter<void> = new EventEmitter<void>();
  isPriceConfirmed: boolean = false;

  handleConfirm() {
    this.onConfirm.emit();
  }
}
