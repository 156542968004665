/* start: imports-loader */
import '../../../app.upgrade';
import '../../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-2-sign-send.html'
(function () {
  'use strict';
  angular.module('app').component('orderNew2SignSend', {
    template: theTemplate,
    controller: order2ConstructionsController,
    controllerAs: 'vm',
    bindings: angular.extend({}, sessionStateBindings, {
      selectedConstructions: '=',
      newOrderObj: '=',
      orderIdToModify: '=',
      orderDbId: '=',
      currency: '=',
      isPatientCategoryEnabled: '=',
      sections: '=',
      labs: '=',
      clinicObj: '='
    })
  });
  order2ConstructionsController.$inject = [
    'ORDER_NEW_2_REASON',
    'fileService',
    'orderService',
    'toastr',
    'logService',
    '$state',
    '$filter',
    'CUSTOMER_SETTINGS_CONSTANTS',
    '$scope',
    '$stateParams',
    'organizationSettingService',
    'currentUserService',
    'ordersGraphqlService',
    'getAllOrdersService',
    'userService',
    'organizationService',
    'countryNameService'
  ];

  function order2ConstructionsController(
    ORDER_NEW_2_REASON,
    fileService,
    orderService,
    toastr,
    logService,
    $state,
    $filter,
    CUSTOMER_SETTINGS_CONSTANTS,
    $scope,
    $stateParams,
    organizationSettingService,
    currentUserService,
    ordersGraphqlService,
    getAllOrdersService,
    userService,
    organizationService,
    countryNameService
  ) {
    var vm = this;
    
    //characteristics settings name according customer settings for translation
    vm.orthoSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORTHODONTICS_COLORS;
    vm.claspMaterialSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_MATERIAL;
    vm.facialLoopSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.FACIAL_LOOP;
    vm.claspTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_TYPES;
    vm.springTypeSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SPRING_TYPES;
    vm.occlusionsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.OCCLUSION_TYPE;
    vm.contactTypesSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CONTACT_TYPE;
    vm.ponticDesignsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PONTIC_DESIGN;
    vm.metalDesignsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.METAL_DESIGN;
    vm.implantStepsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.IMPLANT_STEPS;
    vm.dentureStepsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.DENTURE_STEPS;
    vm.testingStepsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.TESTING_STEPS;

    //characteristics settings value from customer settings
    vm.dentureSteps = organizationSettingService.getCustomerSetting(
      vm.dentureStepsSettingName
    );
    vm.implantSteps = organizationSettingService.getCustomerSetting(
      vm.implantStepsSettingName
    );
    vm.testingSteps = organizationSettingService.getCustomerSetting(
      vm.testingStepsSettingName
    );

    vm.isCompletedDraftEnabled = organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.COMPLETED_DRAFT
    );

    vm.shouldDisallowNonDentists = organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.DISALLOW_NON_DENTIST
    );

    vm.previousDelivery = previousDelivery;
    vm.getTranslationKeyConstructionName = getTranslationKeyConstructionName;
    vm.getHexColor = getHexColor;
    vm.patientCategorySettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORIES;
    vm.labExceptionsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CHOOSE_LAB_EXCEPTIONS;
    vm.sampleContentSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT;
    vm.saveOrder = saveOrder;
    vm.showReason = showReason;
    vm.findMaterialName = findMaterialName;
    vm.showSampleContent = showSampleContent;
    vm.saveAndSendOrder = saveAndSendOrder;
    vm.saveCompletedDraft = saveCompletedDraft;
    vm.checkIfSendBtnShouldBeShown = checkIfSendBtnShouldBeShown;
    vm.participantsUsers = [];
    vm.participantsClinics = [];
    vm.pickupcountryName = '';
    vm.deliveryCountryName = '';

    function checkIfSendBtnShouldBeShown() {
      const baseCondition = vm.newOrderObj.status === 'draft' || !vm.orderIdToModify;
      if (vm.isCompletedDraftEnabled && !vm.shouldDisallowNonDentists) {
        return {
          showCompletedDraft:
            baseCondition && vm.newOrderObj.dentist && vm.newOrderObj.dentist._id,
          showSendOrder: baseCondition
        };
      }
      if (vm.isCompletedDraftEnabled && vm.shouldDisallowNonDentists) {
        return {
          showCompletedDraft:
            baseCondition && vm.newOrderObj.dentist && vm.newOrderObj.dentist._id,
          showSendOrder: baseCondition && vm.currentUser.title?.toUpperCase().includes('LÄKARE')
        };
      }
      if (!vm.isCompletedDraftEnabled && !vm.shouldDisallowNonDentists) {
        return {
          showCompletedDraft: false,
          showSendOrder: baseCondition
        };
      }
      return {
        showCompletedDraft: false,
        showSendOrder: baseCondition
      };
    }

    function previousDelivery() {
      vm.sections.showPatientSections = false;
      vm.sections.showConstructionSections = false;
      vm.sections.showDeliverySections = true;
      vm.sections.showSignSendSections = false;
    }

    function getTranslationKeyConstructionName(type) {
      if (type) {
        var constructionName = type.split(' ').join('_');
        return constructionName.toUpperCase();
      }
    }

    function showSampleContent() {
      if (vm.newOrderObj && vm.newOrderObj.sample && vm.newOrderObj.sample.content) {
        return vm.newOrderObj.sample.content.some(function (content) {
          return content.value;
        });
      } else {
        return false;
      }
    }

    function getHexColor(colorCode) {
      return {
        'border-bottom': colorCode ? 'solid 2px ' + colorCode : 'solid 2px #ffffff'
      };
    }

    $scope.$watch(
      'vm.newOrderObj',
      function () {
        if (vm.newOrderObj.deliveryInfo) {
          getCountryName();
        }
        vm.newOrderObj.allConstructions.forEach(function (construction) {
          construction.cloneNumberOfUnits = getNumOfUnits(
            construction.numberOfUnits
          );
        });
      },
      true
    );

    function getNumOfUnits(numberOfUnits) {
      if (!numberOfUnits) {
        return [];
      }
      if (numberOfUnits instanceof Array) {
        return numberOfUnits;
      }
      return Object.keys(numberOfUnits).map(function (actionId) {
        return {
          actionId: actionId,
          header: numberOfUnits[actionId].header,
          numberOfUnits: numberOfUnits[actionId].numberOfUnits
        };
      });
    }

    function showReason() {
      if (
        vm.newOrderObj &&
        vm.newOrderObj.deliveryInfo &&
        vm.newOrderObj.deliveryInfo.reason
      ) {
        return Object.keys(vm.newOrderObj.deliveryInfo.reason).some(function (
          reason
        ) {
          return vm.newOrderObj.deliveryInfo.reason[reason];
        });
      } else {
        return false;
      }
    }

    /**
     * Find material name from clinic material groups
     * @param constructionMaterial
     * @returns {*}
     */
    function findMaterialName(constructionMaterial) {
      var clinic = vm.clinicObj || vm.newOrderObj.clinic;
      var OrgMaterials = clinic.materialGroups.reduce(function (result, materials) {
        return result.concat(materials.materials);
      }, []);
      var material = OrgMaterials.find(function (material) {
        return material._id === constructionMaterial;
      });
      if (material) {
        return material.material;
      }
      return constructionMaterial;
    }

    function getPriceSpec() {
      if (vm.newOrderObj.priceSpecification.length) {
        return vm.newOrderObj.priceSpecification.map(function (priceSpec) {
          if (!priceSpec.material) {
            var quantity = 0;
            if (
              priceSpec.costPerTooth &&
              priceSpec.teethOption === 'Single tooth' &&
              priceSpec.teethNo &&
              priceSpec.teethNo.length
            ) {
              quantity = priceSpec.teethNo.length;
            } else if (
              priceSpec.costPerTooth &&
              priceSpec.teethOption === 'Up/low jaw' &&
              priceSpec.teethRange &&
              priceSpec.teethRange.start === 18 &&
              priceSpec.teethRange.end === 38
            ) {
              quantity = 2;
            } else {
              quantity = priceSpec.numberOfUnits;
            }
          }
          var actionObj = { name: '', number: '' };
          if (!priceSpec.material) {
            actionObj.name = priceSpec.name;
            actionObj.number = priceSpec.number;
          }
          if (priceSpec.priceDiff) {
            return {
              material: priceSpec.material,
              quantity: priceSpec.quantity,
              vat: priceSpec.vat,
              priceDiff: priceSpec.priceDiff,
              action: priceSpec.actionId
            };
          }
          return {
            action: !priceSpec.material ? priceSpec._id : null,
            actionObj: !priceSpec.material ? actionObj : null,
            material: priceSpec.material ? priceSpec.material : null,
            quantity: !priceSpec.quantity ? quantity : priceSpec.quantity,
            price: priceSpec.price,
            vat: priceSpec.vat || 0,
            _id: priceSpec._id ? priceSpec._id : undefined //for already saved added material and action
          };
        });
      }
    }

    async function saveCompletedDraft() {
      vm.showSpinner = true;
      await ordersGraphqlService.saveCompletedDraft(
        vm.orderDbId,
        vm.newOrderObj.dentist._id
      );
      vm.showSpinner = false;
      const reactAppUrl = process.env.NEW_APP_URL;
      const url = `${reactAppUrl}/orders/drafts`;
      window.location.href = url;
    }

    async function saveAndSendOrder() {
      vm.showSpinner = true;
      await ordersGraphqlService.saveAndSendOrder(
        vm.orderDbId,
        vm.newOrderObj.deliveryInfo.price,
        getPriceSpec()
      );
      vm.showSpinner = false;
      toastr.success($filter('translate')('ORDER_CREATED'));
      vm.disableAddOrderButton = true;
      getAllOrdersService.clearData(); //to clear cached orders list data
      getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
      $state.go('app.order-new-2-confirm', { orderId: vm.orderDbId });
    }

    /**
     * Method to save New Order 2.0 Details
     */
    function saveOrder(orderId) {
      vm.disableAddOrderButton = false;
      try {
        if (
          vm.newOrderObj &&
          vm.newOrderObj.patientInfo.firstName &&
          vm.newOrderObj.patientInfo.lastName &&
          vm.newOrderObj.patientInfo.personalIdentity &&
          vm.newOrderObj.allConstructions.length > 0
        ) {
          var orderObject = {
            patient: {
              firstName: vm.newOrderObj.patientInfo.firstName,
              lastName: vm.newOrderObj.patientInfo.lastName,
              gender: vm.newOrderObj.patientInfo.gender,
              personalIdentityNumber: vm.newOrderObj.patientInfo.personalIdentity
            },
            lab: vm.newOrderObj.selectedLab,
            files: [],
            deliveryDetails: {
              date: '',
              destination: '',
              pickUpLocation: ''
            },
            messages: [],
            additionalInfo: '',
            constructions: { type: '' },
            newConstructions: {
              constructions: []
            },
            priceSpecification: []
          };

          orderObject.clinic = vm.orderIdToModify
            ? vm.newOrderObj.clinic
            : vm.currentUser.organization;

          if (
            vm.newOrderObj.originalLabRank1 &&
            vm.newOrderObj.originalLabRank1.lab
          ) {
            orderObject.rankOneLab = vm.newOrderObj.originalLabRank1.lab._id;
          }

          if (
            vm.newOrderObj.originalLabRank1 &&
            vm.newOrderObj.originalLabRank1.lab
          ) {
            orderObject.rankOneLab = vm.newOrderObj.originalLabRank1.lab._id;
          }

          if (vm.newOrderObj.patientInfo.patientCategory) {
            orderObject.patient.patientCategory =
              vm.newOrderObj.patientInfo.patientCategory;
          }

          if (vm.newOrderObj.additionalInfo) {
            orderObject.additionalInfo = vm.newOrderObj.additionalInfo;
          }
          if (vm.newOrderObj.patientInfo.noPersonalIdentityNumber) {
            orderObject.patient.noPersonalIdentityNumber =
              vm.newOrderObj.patientInfo.noPersonalIdentityNumber;
          }
          if (vm.newOrderObj.sample) {
            vm.newOrderObj.sample.created = {
              at: Date.now(),
              by: vm.currentUser.name
            };
            orderObject.deliveryDetails.sample = vm.newOrderObj.sample;
          }

          // Set dentist field
          if (vm.newOrderObj.dentist && vm.newOrderObj.dentist._id) {
            orderObject.dentist = vm.newOrderObj.dentist._id;
          } else {
            // Set dentist field to the user who created the order, if there's not a different dentist chosen by the user
            if (vm.orderIdToModify) {
              orderObject.dentist = vm.newOrderObj.createdBy._id;
            } else {
              orderObject.dentist = vm.currentUser._id;
            }
          }

          if (vm.newOrderObj.files) {
            orderObject.files = vm.newOrderObj.files.map(function (file) {
              return { file: file.id };
            });
          }
          var categoryArr = vm.newOrderObj.allConstructions.map(function (
            construction
          ) {
            return construction.mainCategory._id;
          });
          if (categoryArr.length === _.uniq(categoryArr).length) {
            orderObject.rankType = 'SIMPLE';
          } else {
            orderObject.rankType = 'CALCULATED';
          }

          orderObject.type = 'Order_2';
          orderObject.isNewOrder = true;
          orderObject.linkedDentists = vm.newOrderObj.linkedDentists;
          orderObject.linkedClinics = vm.newOrderObj.linkedClinics;
          orderObject.customParticipantEmails = vm.newOrderObj.customParticipantEmails;
          orderObject.linkedExtTechs = vm.newOrderObj.linkedExtTechs;
          orderObject.deliveryDetails.date = new Date(
            new Date(vm.newOrderObj.deliveryInfo.date)
          ).getTime();
          orderObject.deliveryDetails.destination =
            vm.newOrderObj.deliveryInfo.clinic;
          orderObject.deliveryDetails.pickUpLocation =
            vm.newOrderObj.deliveryInfo.pickUpLocation;
          orderObject.deliveryDetails.status = vm.newOrderObj.deliveryInfo.status;
          orderObject.deliveryDetails.exportOrder =
            vm.newOrderObj.deliveryInfo.exportOrder;
          orderObject.deliveryDetails.isPickUpAtClinic =
            vm.newOrderObj.deliveryInfo.isPickUpAtClinic;
          orderObject.deliveryDetails.reason = vm.newOrderObj.deliveryInfo.reason;
          orderObject.deliveryDetails.price = vm.newOrderObj.deliveryInfo.price;
          orderObject.deliveryDetails.time = vm.newOrderObj.deliveryInfo.time;
          if (vm.newOrderObj.priceSpecification.length) {
            orderObject.priceSpecification = vm.newOrderObj.priceSpecification.map(
              function (priceSpec) {
                if (!priceSpec.material) {
                  var quantity = 0;
                  if (
                    priceSpec.costPerTooth &&
                    priceSpec.teethOption === 'Single tooth' &&
                    priceSpec.teethNo &&
                    priceSpec.teethNo.length
                  ) {
                    quantity = priceSpec.teethNo.length;
                  } else if (
                    priceSpec.costPerTooth &&
                    priceSpec.teethOption === 'Up/low jaw' &&
                    priceSpec.teethRange &&
                    priceSpec.teethRange.start === 18 &&
                    priceSpec.teethRange.end === 38
                  ) {
                    quantity = 2;
                  } else {
                    quantity = priceSpec.numberOfUnits;
                  }
                }
                var actionObj = { name: '', number: '' };
                if (!priceSpec.material) {
                  actionObj.name = priceSpec.name;
                  actionObj.number = priceSpec.number;
                }
                if (priceSpec.priceDiff) {
                  return {
                    material: priceSpec.material,
                    quantity: priceSpec.quantity,
                    vat: priceSpec.vat,
                    priceDiff: priceSpec.priceDiff,
                    action: priceSpec.actionId
                  };
                }
                return {
                  action: !priceSpec.material ? priceSpec._id : null,
                  actionObj: !priceSpec.material ? actionObj : null,
                  material: priceSpec.material ? priceSpec.material : null,
                  quantity: !priceSpec.quantity ? quantity : priceSpec.quantity,
                  price: priceSpec.price,
                  originalPrice: !priceSpec.material ? priceSpec.price : null,
                  vat: priceSpec.vat || 0,
                  _id: priceSpec._id ? priceSpec._id : undefined //for already saved added material and action
                };
              }
            );
          }

          if (vm.orderIdToModify) {
            orderObject.deliveryDetails.isLabHandleRequest =
              vm.newOrderObj.deliveryInfo.isLabHandleRequest;
          }

          orderObject.constructions.type =
            vm.newOrderObj.selectedConstructions.type === 'fixed'
              ? 'Fixed'
              : 'Removable';

          orderObject.newConstructions.constructions = vm.newOrderObj.allConstructions.map(
            function (construction) {
              var newConstruction = {
                category: '',
                subCategory: '',
                actions: [],
                teethDetails: {
                  isRange: false,
                  teethRange: {
                    start: null,
                    end: null,
                    isUpper: true
                  },
                  toothNumber: []
                },
                material: '',
                color: '',
                shade: '',
                colorSampling: false,
                design: '',
                contactType: '',
                occlusion: '',
                ponticDesign: '',
                retainment: '',
                implantSteps: '',
                implantDates: {},
                implantTimes: {},
                dentureSteps: '',
                dentureDates: {},
                dentureTimes: {},
                testingSteps: '',
                testingDates: {},
                testingTimes: {},
                claspTeeth: [],
                claspMaterial: '',
                orthColor: '',
                facialLoop: '',
                claspType: '',
                springType: '',
                springTeeth: [],
                expansionScrew: false,
                protrusion: 0,
                numberOfUnits: [],
                comment: ''
              };
              newConstruction.actions = construction.actions.map(function (action) {
                return action.id || action._id;
              });
              if (construction.teethNo && construction.teethNo.length) {
                newConstruction.teethDetails.toothNumber = construction.teethNo;
              } else if (construction.teethRange) {
                newConstruction.teethDetails.isRange = true;
                newConstruction.teethDetails.teethRange.start =
                  construction.teethRange.start;
                newConstruction.teethDetails.teethRange.end =
                  construction.teethRange.end;
                newConstruction.teethDetails.teethRange.isUpper =
                  construction.teethRange.isUpper;
              }
              newConstruction.addedMaterials = construction.addedMaterials;
              newConstruction.category = construction.mainCategory._id;
              newConstruction.createdAt = construction.createdAt;
              newConstruction.colorSampling = construction.colorSampling;
              newConstruction.color = construction.color;
              newConstruction.shade = construction.shade;
              newConstruction.subCategory = construction.subCategory;
              newConstruction.contactType = construction.contactType;
              newConstruction.dentureSteps = construction.dentureSteps;
              newConstruction.dentureDates = construction.dentureDates;
              newConstruction.dentureTimes = construction.dentureTimes;
              newConstruction.testingSteps = construction.testingSteps;
              newConstruction.testingDates = construction.testingDates;
              newConstruction.testingTimes = construction.testingTimes;
              newConstruction.design = construction.design;
              newConstruction.implantSteps = construction.implantSteps;
              newConstruction.implantDates = construction.implantDates;
              newConstruction.implantTimes = construction.implantTimes;
              newConstruction.material = construction.material;
              newConstruction.numberOfUnits = getNumOfUnits(
                construction.numberOfUnits
              );
              newConstruction.occlusion = construction.occlusion;
              newConstruction.ponticDesign = construction.ponticDesign;
              newConstruction.retainment = construction.retainment;
              newConstruction.shade = construction.shade;
              newConstruction.warranty = construction.warranty || false;
              newConstruction.warrantyType = construction.warrantyType;
              newConstruction.claspTeeth = construction.claspTeeth;
              newConstruction.claspMaterial = construction.claspMaterial;
              newConstruction.orthColor = construction.orthColor;
              newConstruction.facialLoop = construction.facialLoop;
              newConstruction.claspType = construction.claspType;
              newConstruction.springType = construction.springType;
              newConstruction.springTeeth = construction.springTeeth;
              newConstruction.expansionScrew = construction.expansionScrew || false;
              newConstruction.protrusion = construction.protrusion;
              newConstruction.comment = construction.comment;
              if (orderId) {
                newConstruction._id = construction._id;
                Object.keys(newConstruction).forEach(function (key) {
                  if (!newConstruction[key]) {
                    delete newConstruction[key];
                  }
                });
              }
              return newConstruction;
            }
          );
          if (orderId) {
            if (vm.isResend) {
              orderObject.isResend = vm.isResend;
            }
            if (vm.newOrderObj.approvedBy) {
              orderObject.approvedBy = vm.newOrderObj.approvedBy;
            }
            orderObject._id = orderId;
            // update order
            return orderService
              .updateNewOrder(orderObject)
              .then(function (order) {
                if (order) {
                  if (vm.isResend) {
                    toastr.success($filter('translate')('ORDER_RESENT'));
                  } else {
                    toastr.success($filter('translate')('ORDER_UPDATED'));
                  }
                  vm.disableAddOrderButton = true;
                  getAllOrdersService.clearData(); //to clear cached orders list data
                  getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data

                  const reactAppUrl = process.env.NEW_APP_URL;
                  window.location.href = `${reactAppUrl}/order-details/${orderId}/main-view`;
                }
              })
              .catch(function (err) {
                logService.error(
                  'order-new-2-sign-send.component',
                  'saveOrder 1',
                  $filter('translate')('ERROR_IN_UPDATE_ORDER') + ': ' + err
                );
                toastr.error($filter('translate')('ERROR_IN_UPDATE_ORDER'));
              });
          }
          //orderObject._id = vm.newOrderObj._id; TODO--> Uncomment it when we need 3shape
          return orderService
            .saveNewOrder(orderObject)
            .then(function (order) {
              if (order) {
                currentUserService.updateCurrentUser().then(() => {
                  vm.showSpinner = false;
                  toastr.success($filter('translate')('ORDER_CREATED'));
                  vm.disableAddOrderButton = true;
                  getAllOrdersService.clearData(); //to clear cached orders list data
                  getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
                  $state.go('app.order-new-2-confirm', { orderId: order._id });
                });
              }
            })
            .catch(function (err) {
              logService.error(
                'order-new-2-sign-send.component',
                'saveOrder 2',
                $filter('translate')('ERROR_IN_CREATING_ORDER') + ': ' + err
              );
              toastr.error($filter('translate')('ERROR_IN_CREATING_ORDER'));
            });
        }
      } catch (error) {
        logService.error(
          'order-new-2-sign-send.component',
          'saveOrder try-catch',
          $filter('translate')('ERROR_IN_CREATING_ORDER') + ': ' + error
        );
        toastr.error($filter('translate')('ERROR_IN_CREATING_ORDER'));
      }
    }

    function getCountryName () {
      if (vm.newOrderObj.deliveryInfo.selectedPickUpDetails) {
        countryNameService
          .countryName(vm.newOrderObj.deliveryInfo.selectedPickUpDetails.countryCode)
          .then((name) => vm.pickupcountryName = name);
      }
      if (vm.newOrderObj.deliveryInfo.selectedDeliveryDetails) {
        countryNameService
          .countryName(vm.newOrderObj.deliveryInfo.selectedDeliveryDetails.countryCode)
          .then((name) => vm.deliveryCountryName = name);
      }
    }

    vm.$onInit = function () {
      if (vm.newOrderObj.linkedDentists.length) {
        vm.newOrderObj.linkedDentists.forEach((dentist) => {
          userService.getUserById(dentist.user).then(function (result) {
            vm.participantsUsers.push({
              _id: result.data._id,
              name: result.data.name,
              email: result.data.email,
            });
          });
        });
      }
      if (vm.newOrderObj.linkedClinics.length) {
        vm.newOrderObj.linkedClinics.forEach((linkedClinic) => {
          organizationService.getOrganization({id: linkedClinic.clinic}).then(function (result) {
            vm.participantsClinics.push({
              _id: result.data._id,
              name: result.data.name,
              email: result.data.contact.email,
            });
          });
        });
      }
      vm.isResend = JSON.parse($stateParams.isResend || false);
    };
  }
})();
