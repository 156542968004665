/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './organization-setting.html'
/**
 * clinic add modal component
 */
(function () {
    'use strict';
    angular.module('app').component('organizationSetting', {
        template: theTemplate,
        controller: organizationSettingController,
        controllerAs: 'vm',
        bindings: angular.extend({}, sessionStateBindings, {
            onSettingChange: '=',
            settings: '='
        })
    });

    organizationSettingController.$inject = [ '$filter', 'organizationService', 'toastr',
        '$state','tokenService','sessionService','logService' ];
    /**
     * Controller function for clinic add component
     * @param $filter
     * @param organizationService
     * @param toastr
     * @param $state
     * @param tokenService
     * @param sessionService
     * @param logService
     */
    function organizationSettingController($filter, organizationService, toastr,
        $state, tokenService, sessionService, logService) {
        //variables
        var vm = this;

        vm.addSettings = addSettings;

        // add settings
        function addSettings(key) {
            vm.settings.modules[key] = !vm.settings.modules[key];
        }
    }
})();