/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new.html'
/**
 * Created By Sumit
 * New order component
 */
(function () {
    'use strict';
    angular.module('app').component('orderNew', {
        template: theTemplate,
        controller: orderNewController,
        controllerAs: 'vm',
        bindings: angular.extend({}, sessionStateBindings, {})
    });

    orderNewController.$inject = [ 'subNavService', 'DATE', 'orderService', '$filter', 'logService', 'toastr', '$scope', '$location', 'organizationSettingService', 'organizationService', 'CUSTOMER_SETTINGS_CONSTANTS', '$state', 'permissionService', 'additionalInfoService', '$stateParams' ];

  /**
   * New order controller
   * @param subNavService
   * @param DATE
   * @param orderService
   * @param $filter
   * @param logService
   * @param toastr
   * @param $scope
   * @param $location
   * @param organizationSettingService
   * @param organizationService
   * @param CUSTOMER_SETTINGS_CONSTANTS
   * @param $state
   * @param permissionService
   * @param additionalInfoService
   * @param $stateParams
   */
    function orderNewController(subNavService, DATE, orderService, $filter, logService, toastr, $scope, $location, organizationSettingService, organizationService, CUSTOMER_SETTINGS_CONSTANTS, $state, permissionService, additionalInfoService, $stateParams) {
        var vm = this;
        vm.showOtherSections = false;
        vm.isExistingOrders = false;
        vm.defaultDateType = DATE.FORMAT;
        vm.mandatoryCommentsSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.MANDATORY_COMMENTS;
        vm.mandatoryComments = organizationSettingService.getCustomerSetting(vm.mandatoryCommentsSettingName);
        //menus
        vm.menus = [
            {
                name: $filter('translate')('1_PATIENT_AND_LAB'),
                isActive: true,
                action: 'patient-section',
                isScrollSpy: true
            },
            {
                name: $filter('translate')('2_CONSTRUCTIONS'),
                isActive: false,
                action: 'construction-section',
                isScrollSpy: true
            }, {
                name: $filter('translate')('3_DELIVERY_INFORMATION'),
                isActive: false,
                action: 'delivery-section',
                isScrollSpy: true
            },
            {
                name: $filter('translate')('4_UPLOAD_IMAGE'),
                isActive: false,
                action: 'images-section',
                isScrollSpy: true
            },
            {
                name: $filter('translate')('5_SIGN_AND_SEND'),
                isActive: false,
                action: 'sign-section',
                isScrollSpy: true
            }];

        vm.newOrderObj = {
            patientInfo: {},
            selectedLab: '',
            files: [],
            deliveryInfo: {},
            isNewOrder: false,
            selectedConstructions: {
                type: 'fixed',
                fixedConstructions: {
                    singleCrowns: [],
                    bridges: [],
                    posts: [],
                    implantCrowns: [],
                    implantBridges: [],
                    others: []
                },
                removableConstructions: {
                    completeDentures: [],
                    partialDentures: [],
                    temporaryDentures: [],
                    bitetrays: [],
                    otherRemovables: []
                }
            },
            sample: {
                type: '',
                content: [],
                comments: ''
            }
        };

        /**
         * Format data so it's compatible will sample-method content implementation
         * */
        function getFormattedSampleContent(contents) {
            return contents.map(function (content) {
                return {
                    name: content,
                    value: false
                };
            });
        }

        //setting Export_production value if it is true at customer settings page
        if (organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.EXPORT_PRODUCTION)) {
            vm.showExportOrderCheckBox = true;
        }
        function checkOrderVersion() {
            var orderVersion = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION);

            if (orderVersion !== CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.SIMPLE) {
                console.log("USER SHOULD HAVE VERSION " + orderVersion + "! Redirecting with params", $location.search());
                $state.go('app.order-new-2', $location.search());
            }
        }

        function initPrefill() {
            var sampleContent = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT);
            if (vm.newOrderObj) {
                vm.newOrderObj.sample.content = getFormattedSampleContent(sampleContent);
            }
            vm.isPatientCategoryEnabled = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORY);
            if (vm.isPatientCategoryEnabled) {
                vm.patientCategories = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORIES);
            }
            vm.sampleMethodSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_METHOD;
            vm.isSampleMethod = organizationSettingService.getCustomerSetting(vm.sampleMethodSettingName);

            var enablePrefill = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_PREFILL);

            if (enablePrefill) {
                var parameters = $location.search();
                if (parameters) {
                    if (parameters.patient_category) {
                        parameters.patient_category = parameters.patient_category.replace(/[^a-zA-Z_ ]/g, "");
                    }
                    var patientCategory = parameters.patient_category && vm.patientCategories && vm.patientCategories
                        .find(function(thisPatientCategory) {
                            return thisPatientCategory.name.toLowerCase() === parameters.patient_category.toLowerCase();
                        });

                    vm.newOrderObj.patientInfo = {
                        personalIdentity: parameters.personal_number,
                        firstName: parameters.first_name ? parameters.first_name.replace(/\t/g, ' ').trim() : '',
                        lastName: parameters.last_name ? parameters.last_name.replace(/\t/g, ' ').trim(): '',
                        patientCategory: patientCategory
                    };

                    var clinicPromise;
                    var clinicExtIdNum = parseInt(organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_PREFILL_CLINIC_EXT_ID));
                    if (!_.isEmpty(parameters) && clinicExtIdNum && Number.isInteger(clinicExtIdNum) && clinicExtIdNum !== 3) {
                        var extIdValue = parameters["clinic_extid_" + clinicExtIdNum];
                        clinicPromise = organizationService.getOrganizationByExtId({ extidnum: clinicExtIdNum, extid: extIdValue });
                    } else if (parameters.clinic_extid_3) {
                        clinicPromise = organizationService.getOrganization({ id: parameters.clinic_extid_3 });
                    }

                    if (clinicPromise) {
                        clinicPromise.then(function(clinicResponse) {
                            vm.newOrderObj.deliveryInfo = {
                                clinic: clinicResponse.data._id,
                                pickUpLocation: clinicResponse.data._id
                            };
                        });
                    }
                }
            }
        }

        /**
         * Method to add menus to sub nav bar
         */
        function addSubNavMenus() {
            // we could use /addMenus function as well for bulk
            subNavService.resetList();
            //push menus to sub nav
            for (var menu = 0; menu < vm.menus.length; menu++) {
                subNavService.addMenu({
                    name: vm.menus[menu].name,
                    icon: '',
                    isScrollSpy: vm.menus[menu].isScrollSpy,
                    action: vm.menus[menu].action,
                    isActive: vm.menus[menu].isActive
                });
            }
        }

        function getOrderDetails(orderId) {
            orderService.getOrderById(orderId).then(function (response) {
                var orderObj = _.clone(response.data);
                vm.newOrderObj = orderObj;
                vm.newOrderObj.patientInfo = orderObj.patient;
                vm.newOrderObj.patientInfo.personalIdentity = orderObj.patient.personalIdentityNumber;
                vm.newOrderObj.selectedLab = orderObj.lab._id;
                vm.newOrderObj.files = orderObj.files;
                vm.newOrderObj.deliveryInfo = orderObj.deliveryDetails;
                vm.newOrderObj.savedPickUpAtClinicsValue = orderObj.deliveryDetails.isPickUpAtClinic;
                vm.newOrderObj.deliveryInfo.date = $filter('date')(orderObj.deliveryDetails.date, vm.defaultDateType);
                vm.newOrderObj.selectedConstructions = orderObj.constructions;
                vm.newOrderObj.orderObjId = orderObj._id;
                vm.newOrderObj.clinics = [ orderObj.deliveryDetails.destination ];
                vm.newOrderObj.clinic = orderObj.clinic;
                if (orderObj.deliveryDetails.destination._id !== orderObj.deliveryDetails.pickUpLocation._id) {
                    vm.newOrderObj.clinics.push(orderObj.deliveryDetails.pickUpLocation);
                }
                vm.newOrderObj.deliveryInfo.clinic = orderObj.deliveryDetails.destination._id;
                vm.newOrderObj.deliveryInfo.isLabHandleRequest = orderObj.deliveryDetails.isLabHandleRequest;
                vm.newOrderObj.deliveryInfo.pickUpLocation = orderObj.deliveryDetails.pickUpLocation._id;
                vm.newOrderObj.additionalInfo = orderObj.additionalInfo;
                vm.newOrderObj.auditLogs = orderObj.auditLogs;
                if (orderObj.constructions.abutmentTeeth) {
                    vm.newOrderObj.selectedConstructions.fixedConstructions = {
                        singleCrowns: orderObj.constructions.abutmentTeeth.singleCrowns,
                        bridges: orderObj.constructions.abutmentTeeth.bridges,
                        posts: orderObj.constructions.abutmentTeeth.posts,
                        implantCrowns: orderObj.constructions.abutmentTeeth.implantCrowns,
                        implantBridges: orderObj.constructions.abutmentTeeth.implantBridges,
                        others: orderObj.constructions.abutmentTeeth.others
                    };
                    vm.newOrderObj.selectedConstructions.removableConstructions = {
                        completeDentures: orderObj.constructions.abutmentTeeth.completeDentures,
                        partialDentures: orderObj.constructions.abutmentTeeth.partialDentures,
                        temporaryDentures: orderObj.constructions.abutmentTeeth.temporaryDentures,
                        bitetrays: orderObj.constructions.abutmentTeeth.bitetrays,
                        otherRemovables: orderObj.constructions.abutmentTeeth.otherRemovables
                    };
                }
                if (vm.newOrderObj.selectedConstructions.type) {
                    vm.newOrderObj.selectedConstructions.type = vm.newOrderObj.selectedConstructions.type.toLowerCase();
                }
                vm.additionalInfo = additionalInfoService.getAdditionalInfo(vm.newOrderObj);
                vm.newOrderObj.signedUrl = orderObj.signedUrl;
                vm.newOrderObj.deliveryInfo.additionalInfo = orderObj.additionalInfo;
                vm.showSpinner = false;
                //fetch sample content from customer setting when it was not saved in DB for old order
                if (!orderObj.deliveryDetails.sample.content.length > 0) {
                    var sampleContent = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT);
                    vm.newOrderObj.sample = orderObj.deliveryDetails.sample;
                    vm.newOrderObj.sample.content = getFormattedSampleContent(sampleContent);
                } else {
                    vm.newOrderObj.sample = orderObj.deliveryDetails.sample;
                }

                vm.newOrderObj.allAdditionalInfo = orderObj.allAdditionalInfo;

                vm.newOrderObj.allSampleComment = orderObj.allSampleComment;

                //for current sample comment not showing in textbox
                if (vm.newOrderObj.sample && vm.newOrderObj.sample.comments) {
                    vm.newOrderObj.sample.comments = '';
                }
                //for current additionalInfo not showing in textbox
                if (vm.newOrderObj.deliveryInfo.additionalInfo && vm.newOrderObj.additionalInfo) {
                    vm.newOrderObj.deliveryInfo.additionalInfo = '';
                    vm.newOrderObj.additionalInfo = '';
                }
                vm.newOrderObj.priceSpecification = orderObj.priceSpecification;
                vm.newOrderObj.approvedBy = orderObj.approvedBy;
                vm.newOrderObj.createdBy = orderObj.created.by;
            }).catch(function (err) {
                logService.error('order-new.component', 'getOrderDetails', err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_ORDER'));
            });
        }

        vm.$onInit = function() {
            if ($stateParams.orderId && !JSON.parse($stateParams.isResend)) {
                vm.mandatoryComments = false;
            }
            vm.orderIdToModify = $location.search().orderId;
            vm.isLabUser = permissionService.isLabTechnician(vm.currentUser);
            if (vm.orderIdToModify) {
                vm.showSpinner = true;
                getOrderDetails(vm.orderIdToModify);
            } else {
                checkOrderVersion();
            }
            addSubNavMenus();
            initPrefill();
        };

        // check the required (lab and patient info) present or not
        $scope.$watch('vm.newOrderObj', function () {
            var condition = !!(vm.newOrderObj.selectedLab && vm.newOrderObj.patientInfo && vm.newOrderObj.patientInfo.firstName &&
            vm.newOrderObj.patientInfo.lastName && vm.newOrderObj.patientInfo.personalIdentity);
            if (!vm.orderIdToModify && vm.isExistingOrders) {
                vm.showOtherSections = condition && vm.newOrderObj.isNewOrder;
            } else {
                vm.showOtherSections = condition;
            }
        }, true);
    }

})();
