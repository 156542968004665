import { Input, Component } from '@angular/core';
import { OrderService } from '../../../services/order-new/order-new.service';
import { GetAllOrdersService } from '../../../services/order-list/get-all-orders.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { Order } from '../../../models/order.model';
import template from './cancel-order-modal.html';

@Component({
  selector: 'cancel-order-modal',
  template: template
})
export class CancelOrderModalComponent {
  @Input() orderDetail: Partial<Order> = {};
  cancel: any = { message: '' };

  constructor(
    private orderService: OrderService,
    private getAllOrdersService: GetAllOrdersService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private logService: LogService
  ) {}

  cancelOrder(): Promise<void> {
    this.orderDetail.cancelObj = this.cancel;
    return this.orderService
      .cancelOrder(this.orderDetail)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
        this.getAllOrdersService.clearData(); //to clear cached orders list data
        this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
        window.location.reload();
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_CANCELLING_ORDER'
        );
        this.logService.error(
          'cancel-order-modal.component',
          'cancelOrder',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }
}
