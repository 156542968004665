import { Directive, HostListener, Input } from '@angular/core';
import { EventService } from '../services/core/event.service';

@Directive({
  selector: '[enterEvent]'
})
export class EnterEventDirective {
  @Input() orderId: string;
  @Input() index: number;
  @Input() arrowkeyLocation: number;
  constructor(private readonly eventService: EventService) {}

  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(_event: KeyboardEvent) {
    if (this.index === this.arrowkeyLocation) {
      this.eventService.emit('pressEnter', this.orderId);
    }
  }
}
