import { Output, Input, OnChanges, Component, EventEmitter } from '@angular/core';
import template from './add-order-material-modal.html';
import style from './order-material-modal.less';
import {
  Material,
  AssociatedMaterialList,
  Organization
} from '../../../models/organization.model';
import * as _ from 'lodash';
import { TranslationsService } from '../../../services/translations/translations.service';

@Component({
  selector: 'add-order-material-modal',
  template: template,
  styles: [style]
})
export class AddOrderMaterialModalComponent implements OnChanges {
  @Output() onAddUpdateMaterial: EventEmitter<Partial<Material>> = new EventEmitter<
    Partial<Material>
  >();
  @Output() onDeleteMaterial: EventEmitter<string> = new EventEmitter<string>();
  @Output() onModelClosed: EventEmitter<void> = new EventEmitter<void>();
  @Input() selectedMaterial: Partial<Material>;
  @Input() associatedMaterialList: AssociatedMaterialList[];
  @Input() actionVat: number;
  @Input() lab: Organization;
  material: Partial<Material> = {};
  showMaterialListDropdown = false;
  textForMaterialDropDown = '';
  isFreeText = false;
  isMaterialSelected = false;
  labConnectedMaterialList: Partial<AssociatedMaterialList> = {};

  constructor(private readonly translationsService: TranslationsService) {}

  closeModal() {
    this.material = {};
    this.selectedMaterial = {};
    this.textForMaterialDropDown = null;
    this.showMaterialListDropdown = false;
    setTimeout(() => {
      this.isFreeText = false;
      this.isMaterialSelected = false;
    }, 0);
    this.onModelClosed.emit();
  }

  ngOnChanges() {
    this.getMaterialList();
    if (this.selectedMaterial?._id) {
      this.isMaterialSelected = true;
      this.material = this.selectedMaterial;
      this.textForMaterialDropDown = this.selectedMaterial.material;
      if (this.textForMaterialDropDown) {
        this.isFreeText = !this.material.description;
      }
    }
    if (_.isEmpty(this.material) && this.actionVat) {
      this.material.vat = _.cloneDeep(this.actionVat);
    }
  }

  checkAndReplaceCommaFromPrice(entity: string) {
    if (entity.includes(',')) {
      entity = entity.split(',').join('.');
    }
    return parseFloat(entity);
  }

  getMaterialList() {
    if (this.associatedMaterialList) {
      const materialList = this.associatedMaterialList.find(
        (list) => list.lab === this.lab._id
      );
      if (materialList) {
        this.labConnectedMaterialList = materialList;
      }
      if (
        !materialList &&
        this.lab &&
        this.lab.materialLists &&
        this.lab.defaultMaterialListId
      ) {
        const defaultMaterialList = this.lab.materialLists.find(
          (list) => list._id === this.lab.defaultMaterialListId
        );
        if (defaultMaterialList) {
          this.labConnectedMaterialList = defaultMaterialList;
        }
      }
    }
  }

  getPriceWithVat() {
    if (!this.material.price || !this.material.quantity) {
      return 0;
    }
    const quantity = this.checkAndReplaceCommaFromPrice(
      this.material.quantity.toString()
    );
    const basePrice = this.checkAndReplaceCommaFromPrice(
      this.material.price.toString()
    );
    const price = parseFloat((basePrice * quantity).toFixed(2));
    if (!this.material.vat) {
      return price;
    }
    const vat = this.checkAndReplaceCommaFromPrice(this.material.vat.toString());
    return parseFloat((price + (price * vat) / 100).toFixed(2));
  }

  disableSaveButton() {
    if (!this.material || !this.material.material || !this.material.quantity || this.material.price === null) {
      return true;
    }
    if (!this.isFreeText) {
      return false;
    }
    if (
      !this.material.material ||
      this.material.price === null ||
      this.material.price.toString() === '-' ||
      !this.material.quantity
    ) {
      return true;
    }
    return false;
  }

  addUpdateMaterial() {
    this.material.quantity = this.checkAndReplaceCommaFromPrice(
      this.material.quantity.toString()
    );
    this.material.price = this.checkAndReplaceCommaFromPrice(
      this.material.price.toString()
    );
    if (this.material.vat) {
      this.material.vat = this.checkAndReplaceCommaFromPrice(
        this.material.vat.toString()
      );
    }
    this.onAddUpdateMaterial.emit(this.material);
    this.closeModal();
  }

  deleteMaterial() {
    this.onDeleteMaterial.emit(this.material._id);
    this.closeModal();
  }

  toggleMaterialList() {
    this.showMaterialListDropdown = !this.showMaterialListDropdown;
  }

  async selectFreeText() {
    this.isFreeText = true;
    this.isMaterialSelected = true;
    this.showMaterialListDropdown = false;
    this.material = {};
    this.textForMaterialDropDown = await this.translationsService.get('FREE_TEXT');
  }

  getSelectedValue(material: Partial<Material>) {
    let price = 0;
    this.isFreeText = false;
    this.isMaterialSelected = true;
    this.material.code = material.code;
    this.material.description = material.description;
    this.material.batchNumber = material.batchNumber;
    this.material.material = material.description;
    this.textForMaterialDropDown = this.material.material;
    if (material.discountedPrice) {
      price = material.discountedPrice;
    } else if (this.labConnectedMaterialList.generalDiscount) {
      price =  material.price - ((this.labConnectedMaterialList.generalDiscount as number) / 100) * material.price;
    } else {
      price = material.price;
    }
    this.material.price = price;
    this.showMaterialListDropdown = false;
  }

  getFreeText(materialName: string) {
    this.material.material = materialName;
    this.material.price = null;
  }
}
