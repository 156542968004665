/* start: imports-loader */
import '../../../app.upgrade';
import '../../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-details-2-images-files.html'
/**
 * Order Details Component for the order details of any order.
 */
(function () {
    'use strict';
    angular.module('app').component('orderDetails2ImagesFiles', {
        template: theTemplate,
        controller: orderDetails2ImagesFilesController,
        controllerAs: 'vm',
        bindings: {
            orderDetail: '=',
            isVisibleInPrint: '=',
            getOrder: '=',
            getClass: '=',
            selectedOrderVersion: '=',
            currentUser: '=',
            isExternalTechnician: '<'
        }
    });

    // Order Details controller Dependencies should be inject here.
    orderDetails2ImagesFilesController.$inject = ['CONSTANT', 'fileService', 'orderService', '$state', 'toastr', '$filter', 'logService', 'ORDER_VERSION', 'permissionService', 'imageAnnotationService', 'organizationSettingService', 'CUSTOMER_SETTINGS_CONSTANTS'];

    // Controller Function for the order details component
    function orderDetails2ImagesFilesController(CONSTANT, fileService, orderService, $state, toastr, $filter, logService, ORDER_VERSION, permissionService, imageAnnotationService, organizationSettingService, CUSTOMER_SETTINGS_CONSTANTS) {

        var vm = this;

        //variables
        vm.slides = [];
        vm.selectedFileObj = [];
        vm.fileSize = CONSTANT.FILE_SIZE;
        vm.progressPercentage = 0;
        vm.files = [];

        // methods
        vm.selectImage = selectImage;
        vm.openImgPopup = openImgPopup;
        vm.closeImgPopup = closeImgPopup;
        vm.editImageAndUpload = editImageAndUpload;
        vm.onAddImageClick = onAddImageClick;
        vm.onAddImage = onAddImage;
        vm.openThreeShapeModal = openThreeShapeModal;
        vm.isDentist = isDentist;
        vm.openScanView = openScanView;
        vm.downloadFile = downloadFile;
        vm.isUploadDisabled = isUploadDisabled;

        function openImgPopup(index) {
            $('#imgModal').modal('show');

            // Insert the images to a carousel array to display in a model
            if (vm.orderDetail.signedUrl.length) {
                vm.imageSlides = vm.orderDetail.signedUrl.filter(function (file) {
                    return file.contentType.split("/")[0] === 'image';
                }).map(function (imageFile, index) {
                    return {
                        id: index,
                        fileId: imageFile.id,
                        image: imageFile.url
                    };
                });

                var imageSlideLength = vm.imageSlides.length;
                // Changes the position according to the selected index
                vm.slides = vm.imageSlides.map(function (slide, slideIndex) {
                    // Change the position using a pointer
                    var pointer = (slideIndex + index) % imageSlideLength;
                    return {
                        id: slideIndex,
                        fileId: vm.imageSlides[pointer].fileId,
                        image: vm.imageSlides[pointer].image
                    };
                });
            }
        }

        // Method when the modal close
        function closeImgPopup() {
            // Empty the image array from the carousel
            vm.slides = [];
            $('#imgModal').modal('hide');
        }

        function onAddImageClick() {
            $('#add-image-modal').modal('show');
        }

        function onAddImage(addFile) {
            imageAnnotationService.showMarkerArea(
                addFile.elementId,
                async (err, dataUrl) => {
                    $('#add-image-modal').modal('hide');
                    if (dataUrl) {
                        const fileObj = {
                            name: addFile.filename,
                            type: 'image/png',
                            imageBinary: dataUrl
                        };
                        createNewImage(fileObj, true);
                    }
                }
            )
        }

        function editImageAndUpload(file) {
            try {
                imageAnnotationService.showMarkerArea(file.id, async (err, dataUrl) => {
                    if (dataUrl) {
                        fetch(dataUrl)
                            .then(res => res.blob())
                            .then(fileBlob => {
                                const fileObj = blobToFile(fileBlob, file.name);
                                createNewImage(fileObj, false);
                            });
                    }
                });
            } catch (err) {
                logService.error('order-detail-2-image-file.component', 'editImageAndUpload', err);
                toastr.error($filter('translate')('ERROR_IN_UPLOAD_FILE'));
            }
        }

        function blobToFile(theBlob, fileName) {
            return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
        }

        async function createNewImage(fileObj, isBase64) {
            await uploadImage(fileObj, isBase64 ? isBase64 : undefined);
            const isSimpleOrder = vm.orderDetail.type === ORDER_VERSION.ORDER_1;
            if (isSimpleOrder) {
                vm.orderDetail.orderId = $state.params.orderId;
                vm.orderDetail.isModify = true;
                await orderService.updateOrder(vm.orderDetail);
            } else {
                vm.orderDetail.id = $state.params.orderId;
                await orderService.updateNewOrder(vm.orderDetail);
            }
            vm.showProgressBar = false;
            vm.progressPercentage = 0;
            toastr.success($filter('translate')('SUCCESS_UPLOAD'));
            vm.getOrder();
        }

        /**
         *  upload image and then update in order table
         * @param file
         * @param isBase64
         */
        function uploadImage(file, isBase64) {
            return fileService.uploadFile(file, 'organizations/' + vm.orderDetail.clinic._id + '/orders/' + vm.orderDetail._id, isBase64).then(function (resp) {
                vm.orderDetail.files.push({
                    file: {
                        name: file.name,
                        contentType: file.type,
                        _id: resp.data.file._id
                    }
                });
                return file;
            }, null, function (evt) {
                vm.showProgressBar = true;
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
        }

        /**
         * After file select upload image one by one
         * @param files
         */
        function selectImage(files) {
            if (files && files.length) {
                var filePromises = files.filter(function (file) {
                    return !file.$error;
                }).map(function (file) {
                    return uploadImage(file);
                });
                Promise.all(filePromises).then(function (files) {
                    vm.selectedFileObj = vm.selectedFileObj.concat(files);
                    var isSimpleOrder = vm.orderDetail.type === ORDER_VERSION.ORDER_1;
                    var api = isSimpleOrder ? "updateOrder" : "updateNewOrder";
                    if (isSimpleOrder) {
                        vm.orderDetail.orderId = $state.params.orderId;
                        vm.orderDetail.isModify = true;
                        return orderService.updateOrder(vm.orderDetail);
                    } else {
                        vm.orderDetail.id = $state.params.orderId;
                        return orderService.updateNewOrder(vm.orderDetail);
                    }
                }).then(function () {
                    vm.selectedFileObj = [];
                    vm.showProgressBar = false;
                    vm.progressPercentage = 0;
                    toastr.success($filter('translate')('SUCCESS_UPLOAD'));
                    vm.getOrder();
                    vm.files = undefined;
                }).catch(function (err) {
                    logService.error('order-detail-2-image-file.component', 'uploadImage', err);
                    toastr.error($filter('translate')('ERROR_IN_UPLOAD_FILE'));
                });
            } else if (files && !files.length) {
                vm.files = undefined;
                toastr.error($filter('translate')('FILE_IS_TOO_LARGE_TO_UPLOAD'));
            }
        }

        function openThreeShapeModal() {
            window.top.postMessage(
                {
                    eventType: 'open-attach-3shape-case-modal',
                    orderData: {
                        _id: vm.orderDetail._id,
                        patient: vm.orderDetail.patient,
                        attachedCaseFiles: vm.orderDetail.attachedCaseFiles
                    }
                },
                '*'
            );
        }

        function isDentist() {
            return permissionService.isDentist(vm.currentUser);
        }
        function openScanView() {
            $('#three-shape-scan-view').modal('show');
        }
        function downloadFile(event, file) {
            event.stopPropagation();
            window.top.postMessage({
                eventType: 'url-file-download',
                filename: file.signedUrl.name,
                fileUrl: file.signedUrl.url
            }, '*');
        }
        function isUploadDisabled() {
            if (vm.orderDetail) {
                if (
                    vm.orderDetail.maxVersion !== vm.selectedOrderVersion ||
                    vm.isExternalTechnician
                ) {
                    return true;
                } else if (
                    isDentist() &&
                    (vm.orderDetail.isProductionFinished ||
                        vm.orderDetail.invoicingState !== 'NOT_READY')
                ) {
                    return true;
                }
            }
            return false;
        }
        vm.$onInit = function () {
            if (vm.currentUser && vm.currentUser.organization) {
                const isThreeShapeEnabled = organizationSettingService.getFromCustomerSettings(
                    vm.currentUser.organization.customerSettings,
                    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.THREE_SHAPE_INTEGRATION
                );
                const isScanCaseEnabled = organizationSettingService.getFromCustomerSettings(
                    vm.currentUser.organization.customerSettings,
                    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SCAN_CASES
                );
                vm.isThreeShapeEnabled = isThreeShapeEnabled && isScanCaseEnabled;
            } else {
                vm.isThreeShapeEnabled = false;
            }
        };
    }
})();