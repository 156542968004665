/**
 * This file contains ugly hacks to be used only while we still have angular1 js files.
 */

import './app.constant';

// Imports all services in the entire system, to avoid having to change all javascript files.
import './modules/additional-info/additional-info.service';
import './modules/connect-to-lab/search-text.service';
import './modules/customer/customer-year-prefiex.service';
import './modules/files/file.service';
import './modules/order-details-2/order-details-2.service';
import './modules/order-new-2/order-new-2-delivery/order-new-2-delivery.service';
import './modules/order-new/order-new-delivery.service';
import './modules/order-new/shades-colors.service';
import './modules/order-new/teeth-structure.service';
import './modules/order-removable-constructions/removable-teeth-structure.service';
import './modules/tooth-structure/tooth-structure.service';

// Imports all javascript components in the system.
import './modules/confirm-modal/confirm-modal.component';
import './modules/connect-to-lab/find-lab.component';
import './modules/connect-to-lab/lab-details.component';
import './modules/connect-to-lab/show-labs-item.component';
import './modules/connect-to-lab/show-labs.component';
import './modules/connect-to-lab/show-public-lab-item.component';
import './modules/footer/footer.component';
import './modules/layout/layout.component';
import './modules/logged-out/logged-out.component';
import './modules/order-details-2/order-details-2-constructions/order-details-2-constructions.component';
import './modules/order-details-2/order-details-2-images-files/order-details-2-images-files.component';
import './modules/order-details-2/order-details-2.component';
import './modules/order-new-2/order-new-2-confirm/order-new-2-confirm.component';
import './modules/order-new-2/order-new-2-delivery/order-new-2-delivery.component';
import './modules/order-new-2/order-new-2-delivery/order-new-2-lab-list-item.component';
import './modules/order-new-2/order-new-2-sign-send/order-new-2-sign-send.component';
import './modules/order-new-2/order-new-2-sign-send/price-specification-modal.component';
import './modules/order-new-2/order-new-2.component';
import './modules/order-new/bridge-crown.component';
import './modules/order-new/order-fixed-constructions.component';
import './modules/order-new/order-new-construction.component';
import './modules/order-new/order-new-delivery.component';
import './modules/order-new/order-new-patient.component';
import './modules/order-new/order-new-sign.component';
import './modules/order-new/order-new-upload-image.component';
import './modules/order-new/order-new.component';
import './modules/order-new/other-construction.component';
import './modules/order-new/post-construction.component';
import './modules/order-new/shades-colors.component';
import './modules/order-new/single-crown.component';
import './modules/order-new/teeth-structure.component';
import './modules/order-removable-constructions/complete-denture.component';
import './modules/order-removable-constructions/order-removable-constructions.component';
import './modules/order-removable-constructions/other-removable.component';
import './modules/order-removable-constructions/partial-denture.component';
import './modules/order-removable-constructions/removable-teeth-structure.component';
import './modules/organization-setting/organization-setting.component';
import './modules/reports/reports.component';
import './modules/sub-nav/sub-nav.component';
import './modules/success-modal/success-modal.component';

// import all filters
import './modules/customer/personal-identity.filter';
import './modules/my-enter/my-enter.filter';
import './modules/date-locale-filter/date-locale.filter';
import './modules/currency-locale-filter/currency-locale.filter';
