import { PipeTransform, Pipe } from '@angular/core';
import { Action } from '../models/action.model';

@Pipe({
  name: 'ActionSearch'
})
export class ActionSearchPipe implements PipeTransform {
  transform(actions: Action[], search: string): Action[] {
    if (!search) {
      return actions;
    }
    return actions.filter((action) => {
      const isActionNumberMatched =
        action.number && action.number.indexOf(search) !== -1;
      const isActionNameMatched =
        action.name &&
        action.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
      return isActionNumberMatched || isActionNameMatched;
    });
  }
}
