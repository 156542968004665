import { Output, Component, EventEmitter } from '@angular/core';
import template from './confirm-modal.html';

@Component({
  selector: 'confirmation-modal',
  template: template
})
export class ConfirmationModalComponent {
  @Output() onConfirm: EventEmitter<void> = new EventEmitter<void>();
  isPriceConfirmed: boolean = false;

  handleConfirm() {
    this.onConfirm.emit();
  }
}
