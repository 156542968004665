import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { User } from '../../../../../../models/user.model';
import template from './additional-dentist.html';

@Component({
  selector: 'additional-dentist',
  template
})
export class AdditionalDentistComponent implements OnInit, OnDestroy {
  @Input() createdBy: User;
  @Input() currentUser: User;
  @Input() dentist: User;
  @Input() isRequired: boolean;
  @Input() orderIdToModify: string;
  @Output() dentistChanged: EventEmitter<User> = new EventEmitter<User>();

  constructor() {}

  async ngOnInit() {
    window.addEventListener('message', this.handleEventsFromNewFE.bind(this));
  }
  
  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleEventsFromNewFE.bind(this));
  }

  handleEventsFromNewFE(e) {
    if (
      e.data &&
      e.origin === process.env.NEW_APP_URL &&
      e.data.eventType === 'GET_DENTIST' &&
      e.data.dentist
    ) {
      this.dentist = e.data.dentist;
      this.dentistChanged.emit(this.dentist);
    }
  }

  removeDentist() {
    this.dentist = null;
    this.dentistChanged.emit(this.dentist);
  }

  openAddDentistModal() {
    window.top.postMessage(
      { eventType: 'CHANGE_DENTIST' },
      '*'
    );
  }
}
