import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import template from './order-filter.html';
import { SessionService } from '../../services/core/session.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { User } from '../../models/user.model';
import { Organization } from '../../models/organization.model';
import { PermissionService } from '../../services/core/permission.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { sortBy } from 'lodash';
import { TranslationsService } from '../../services/translations/translations.service';
import { ORDER_STATUSES, CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { SampleMethods } from '../../models/order.model';

@Component({
  selector: 'order-filter',
  template: template
})
export class OrderFilterComponent implements OnInit {
  @Output() onFilterUpdate: EventEmitter<{
    searchText: string;
    selectedClinic: string;
    selectedStatus: string;
    selectedSample: string;
  }> = new EventEmitter<{ searchText: string; selectedClinic: string; selectedStatus: string; selectedSample: string }>();
  @Input() isLabUser: boolean;
  @Input() currentUser: User;
  selectedClinicId = '';
  clinics: Organization[] = [];
  selectedTypeFilter: string;
  searchText: string;
  selectedStatus = 'ALL';
  selectedSample = 'ALL';
  filterTimeout: ReturnType<typeof setTimeout>;
  orderStatuses = ORDER_STATUSES;
  sampleMethods: SampleMethods;

  constructor(
    private sessionService: SessionService,
    private permissionService: PermissionService,
    private logService: LogService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private organizationService: OrganizationService
  ) {
    this.sampleMethods = CUSTOMER_SETTINGS_CONSTANTS.SAMPLE_METHODS;
  }

  async ngOnInit(): Promise<void> {
    if (!this.isLabUser) {
      const selectedTypeFilter = this.sessionService.getItem(
        'selectedTypeFilter'
      );
      this.selectedTypeFilter = selectedTypeFilter || 'MY_ORDERS';
      await this.populateClinics();
    }
    if (
      this.sessionService.getItem('selectedStatus')
    ) {
      this.selectedStatus = this.sessionService.getItem('selectedStatus');
    }
    if (
      this.sessionService.getItem('selectedSample')
    ) {
      this.selectedSample = this.sessionService.getItem('selectedSample');
    }
  }

  populateClinics(): Promise<void> {
    return this.organizationService
      .getDentistClinics({ id: this.currentUser.organization._id })
      .then((result) => {
        this.clinics = sortBy(result.data, 'name');
        this.selectedClinicId = this.sessionService.getItem('selectedClinicId');
      })
      .catch(async (err: any) => {
        this.logService.error(
          'order-filters.component',
          'populateClinics',
          (await this.translationsService.get('ERROR_IN_GETTING_CLINICS')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_CLINICS')
        );
      });
  }

  callFilterFunction(): void {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      this.onFilterUpdate.emit({
        searchText: this.searchText,
        selectedClinic: this.selectedClinicId,
        selectedStatus: this.selectedStatus !== 'ALL' ? this.selectedStatus : '',
        selectedSample: this.selectedSample !== 'ALL' ? this.selectedSample : ''
      });
    });
  }

  async primaryFilterChanged(selectedTypeFilter: string): Promise<void> {
    this.selectedTypeFilter = selectedTypeFilter;
    if (selectedTypeFilter === 'MY_ORDERS') {
      this.sessionService.removeItem('selectedClinicId');
      this.sessionService.removeItem('selectedTypeFilter');
      this.selectedClinicId = '';
    } else if (this.permissionService.isDentist(this.currentUser)) {
      this.selectedClinicId = selectedTypeFilter;
      this.sessionService.setItem(
        'selectedTypeFilter',
        this.selectedTypeFilter
      );
      this.sessionService.setItem(
        'selectedClinicId',
        this.selectedClinicId
      );
    }
    this.callFilterFunction();
  }

  searchTextChange(searchText: string): void {
    this.searchText = searchText;
    this.callFilterFunction();
  }

  statusChanged(status: string): void {
    this.selectedStatus = status;
    if (this.selectedStatus === 'ALL') {
      this.sessionService.removeItem('selectedStatus');
    } else {
      this.sessionService.setItem(
        'selectedStatus',
        this.selectedStatus
      );
    }
    this.callFilterFunction();
  }

  sampleChanged(sample: string): void {
    this.selectedSample = sample;
    if (this.selectedSample === 'ALL') {
      this.sessionService.removeItem('selectedSample');
    } else {
      this.sessionService.setItem(
        'selectedSample',
        this.selectedSample
      );
    }
    this.callFilterFunction();
  }

  // Method for remove default alphabetical order sorting in sample methods from keyvalue pipe
  returnZero() {
    return 0;
  }
}
