/**
 * Teeth Structure service
 */
(function () {
    'use strict';
    angular.module('app').service('teethStructureService', teethStructureService);
    teethStructureService.$inject = [ '$q','toothStructureService' ];

    function teethStructureService($q,toothStructureService) {
        return {
            toothClick: toothClick,
            commonActiveDenture: commonActiveDenture
        };

        /**
         * Action on click of a tooth
         * This function decides if we want to create any construction on this or not
         * @param tooth
         * @param isUpper
         * @param selectedConstructionObj
         * @param selectedTeeth
         * @param selectedConstructionType
         * @param selectedTeethRange
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param selectedRemovableConstructionObj
         * @param teethRangeSelected
         * @param teethSelected
         */
        function toothClick(tooth, isUpper, selectedConstructionObj, selectedTeeth, selectedConstructionType, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedRemovableConstructionObj, teethRangeSelected, teethSelected) {
            var deferred = $q.defer();
            //handle post, crown or single crown, single tooth actions
            var construction = commonActiveCrown(tooth, isUpper, selectedConstructionObj, selectedTeeth, selectedConstructionType, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedRemovableConstructionObj);
            //construction type should not be post.
            var keysConstrictions = {
                'single-crown': 'single crown',
                'implant-crown': 'implant crown',
                'bridge': 'bridge',
                'other': 'other',
                'implant-bridge': 'implant bridge',
                'partial-denture': 'partial denture',
                'complete-denture': 'complete denture',
                'temporary-denture': 'temporary denture',
                'bitetray': 'bitetray',
                'other-removable': 'other removable'
            };
            function selectTypeOfConstruction() {
                for (var key in construction) {
                    if (key && keysConstrictions[key]) {
                        selectedConstructionType = keysConstrictions[key];
                    }
                }
            }

            /*only for select post construction while click on it */
            if (selectedConstructionType === '' && construction['square-post']) {
                selectedConstructionType = 'post';
            }

            if (construction && selectedConstructionType !== 'post') {
                var countConstructionTypes = Object.keys(construction).length;
                if (countConstructionTypes === 1 || (countConstructionTypes > 1 && !selectedConstructionType)) {
                    selectTypeOfConstruction();
                } else if (countConstructionTypes > 1) {
                    //if there are mote then one construction on teeth

                    //check if type doesnot match to any pre done construction else select first type
                    var isMatched = false;
                    for (var key in construction) {
                        if (selectedConstructionType === keysConstrictions[key]) {
                            isMatched = true;
                            break;
                        }
                    }
                    if (!isMatched) {
                        selectTypeOfConstruction();
                    }
                }
            }

            if (selectedConstructionType) {
                if (selectedConstructionType === 'single crown' || selectedConstructionType === 'post' || selectedConstructionType === 'implant crown' || selectedConstructionType === 'other') {
                    //Validations
                    //we can have post on any other structure. so for post all cases works
                    if (selectedConstructionType === 'post') {
                        selectedTeeth = tooth;
                    } else if (selectedConstructionType === 'other') {
                        //we can have other on any other structure. so for other all cases works
                        selectedTeeth = tooth;
                    } else {
                        //single or implant crown can not be at mid of any bridge or we can not set it on any other crown
                        // it should not be inside any bridge or implant bridge
                        if (isSelectedTeethInsideBridge(tooth, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'fixed') || isSelectedTeethInsideBridge(tooth, selectedRemovableConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'removable')) {
                            // there is already a bridge we can not create crown
                            deferred.reject('Cannot make construction');
                        }
                        //if it is a crown then there should not be any crown
                        if (selectedConstructionType === 'single crown' && (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'implantCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'temporaryDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'partialDentures'))) {
                            deferred.reject('Cannot make construction');
                        }
                        //if it is implant crown then there should not be crown
                        if (selectedConstructionType === 'implant crown' && (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'singleCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'partialDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'temporaryDentures'))) {
                            deferred.reject('Cannot make construction');
                        }
                        // it is valid click
                        selectedTeeth = tooth;
                    }
                    //if tooth is selected and valid then call tooth selected function
                    if (teethSelected) {
                        teethSelected(selectedTeeth);
                        commonActiveCrown(selectedTeeth, isUpper, selectedConstructionObj, selectedTeeth, selectedConstructionType, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedRemovableConstructionObj);
                    }
                } else if (selectedConstructionType === 'bridge' || selectedConstructionType === 'implant bridge') {
                    //check is bridge already created then select that one.
                    //else create new
                    var isInMid = false;
                    if (selectedConstructionObj && selectedConstructionObj.bridges && selectedConstructionType === 'bridge') {
                        selectedConstructionObj.bridges.forEach(function (bridge) {
                            if (toothStructureService.checkIfClickInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedTeethRange, teethRangeSelected) || toothStructureService.checkIfClickInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedTeethRange, teethRangeSelected)) {
                                isInMid = true;
                            }
                        });
                    } else if (selectedConstructionObj && selectedConstructionObj.implantBridges && selectedConstructionType === 'implant bridge') {
                        selectedConstructionObj.implantBridges.forEach(function (bridge) {
                            if (toothStructureService.checkIfClickInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedTeethRange, teethRangeSelected) || toothStructureService.checkIfClickInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedTeethRange, teethRangeSelected)) {
                                isInMid = true;
                            }
                        });
                    }

                    //if it is not in mid then create new one
                    if (!isInMid) {
                        if (selectedTeethRange && !selectedTeethRange.start) {
                            //we can not start from here if there is any crown or implant crown here.
                            if (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'singleCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'implantCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'partialDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'temporaryDentures')) {
                                deferred.reject('Cannot make construction');
                            }
                            selectedTeethRange.start = tooth;
                            selectedTeethRange.isUpper = isUpper;
                        } else if (selectedTeethRange && selectedTeethRange.start && selectedTeethRange.end) {
                            //if start and end already there then deselct old bridge
                            if (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'singleCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'implantCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'partialDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'temporaryDentures')) {
                                deferred.reject('Cannot make construction');
                            }
                            delete selectedTeethRange.end;
                            selectedTeethRange.start = tooth;
                            selectedTeethRange.isUpper = isUpper;
                        } else if (selectedTeethRange && selectedTeethRange.start && (selectedTeethRange.isUpper === isUpper)) {
                            //TO DO validations if he can add here or not
                            //End can not be same as start
                            if (selectedTeethRange.start === tooth) {
                                // so deselect the start tooth
                                delete selectedTeethRange.start;
                                deferred.reject('Cannot make construction');
                            }
                            //we can not start from here if there is any crown or implant crown here.
                            if (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'singleCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'implantCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'partialDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedRemovableConstructionObj,'temporaryDentures')) {
                                deferred.reject('Cannot make construction');
                            }
                            //there should not be any crown, implant crown or bridge or implant bridge in mid
                            if (toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, tooth, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedConstructionObj,'fixed',selectedConstructionType) || toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, tooth, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedRemovableConstructionObj,'removable',selectedConstructionType)) {
                                deferred.reject('Cannot make construction');
                            }
                            selectedTeethRange.end = tooth;
                            if (teethRangeSelected) {
                                teethRangeSelected(selectedTeethRange);
                            }
                        }
                        //if start is behind end then interchange them
                        // condition for upper rage
                        var start, end, temp;
                        if (isUpper) {
                            start = abutmentTeethFirstRange.indexOf(selectedTeethRange.start);
                            end = abutmentTeethFirstRange.indexOf(selectedTeethRange.end);
                            if (start !== -1 && end !== -1 && start > end) {
                                temp = selectedTeethRange.start;
                                selectedTeethRange.start = selectedTeethRange.end;
                                selectedTeethRange.end = temp;
                            }
                        } else {
                            // for lower range
                            start = abutmentTeethSecondRange.indexOf(selectedTeethRange.start);
                            end = abutmentTeethSecondRange.indexOf(selectedTeethRange.end);
                            if (start !== -1 && end !== -1 && start > end) {
                                temp = selectedTeethRange.start;
                                selectedTeethRange.start = selectedTeethRange.end;
                                selectedTeethRange.end = temp;
                            }
                        }

                    }
                }
            }
            var data = {
                selectedTeethRange: selectedTeethRange,
                teethRangeSelected: teethRangeSelected,
                selectedConstructionType: selectedConstructionType,
                selectedTeeth: selectedTeeth
            };
            deferred.resolve(data);
            return deferred.promise;

        }

        /**
         * Return class set for given tooth based on all conditions
         * @param teeth
         * @param isUpperRow
         * @param selectedConstructionObj
         * @param selectedTeeth
         * @param selectedConstructionType
         * @param selectedTeethRange
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param selectedRemovableConstructionObj
         * @returns {{}}
         */
        function commonActiveCrown(teeth, isUpperRow, selectedConstructionObj, selectedTeeth, selectedConstructionType, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedRemovableConstructionObj) {
            var obj = commonActiveDenture(teeth, isUpperRow, selectedTeeth, selectedConstructionType, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedRemovableConstructionObj);
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedConstructionObj,'singleCrowns')) {
                obj['single-crown'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedConstructionObj,'implantCrowns')) {
                obj['implant-crown'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedConstructionObj,'posts')) {
                obj['square-post'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedConstructionObj,'others')) {
                obj.other = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedConstructionObj,'implantCrowns')) {
                obj['square-bg'] = true;
            }
            if (selectedTeeth === teeth) {
                obj['square-selected'] = true;
                //if single teeth then mark create color as well
                /*      if(selectedConstructionType === 'post'){
                          obj['square-post'] = true;
                      }
                      else if(selectedConstructionType === 'other'){
                          obj.other = true;
                      }
                      else if(selectedConstructionType === 'implant crown'){
                          obj['implant-crown'] = true;
                      }
                      else if(selectedConstructionType === 'single crown'){
                          obj['single-crown'] = true;
                      }*/

            }

            if (selectedTeethRange.start === teeth && (selectedConstructionType === 'bridge' ||
                    selectedConstructionType === 'implant bridge')) {
                if (selectedTeethRange.end) {
                    obj['bridge-start'] = true;
                    /*  if(selectedConstructionType === 'implant bridge'){
                          obj['implant-bridge'] = true;
                      }
                      else {
                          obj.bridge = true;
                      }*/
                } else {
                    obj['bridge-start-temp'] = true;
                }

            }
            if (selectedTeethRange.end === teeth && (selectedConstructionType === 'bridge' ||
                    selectedConstructionType === 'implant bridge')) {
                obj['bridge-end'] = true;
                /*if(selectedConstructionType === 'implant bridge'){
                    obj['implant-bridge'] = true;
                }
                else {
                    obj.bridge = true;
                }*/
            }
            if (toothStructureService.isInMiddleOfSelectedBridge(teeth, isUpperRow, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange) && (selectedConstructionType === 'bridge' ||
                    selectedConstructionType === 'implant bridge')) {
                //check if this teeth is in middle of selected bridge
                obj['bridge-middle'] = true;
                /*    if(selectedConstructionType === 'implant bridge'){
                       obj['implant-bridge'] = true;
                    }
                    else {
                       obj.bridge = true;
                    }*/
            }
            //find if it is inside bridge created
            var selectedClass = toothStructureService.isInMiddleOfAnyCreatedBridge(teeth, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'bridges');
            var implantSelectedClass = toothStructureService.isInMiddleOfAnyCreatedBridge(teeth, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'implantBridges');
            if (selectedClass) {
                //check if this teeth is in middle of selected bridge
                obj[selectedClass] = true;
                obj.bridge = true;
            }
            if (implantSelectedClass) {
                //check if this teeth is in middle of selected bridge
                obj[implantSelectedClass] = true;
                obj['implant-bridge'] = true;
            }
            return obj;

        }

        /**
         * Return class set for given tooth based on all conditions
         * @param teeth
         * @param isUpperRow
         * @param selectedTeeth
         * @param selectedConstructionType
         * @param selectedTeethRange
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param selectedRemovableConstructionObj
         * @returns {{}}
         */
        function commonActiveDenture(teeth, isUpperRow, selectedTeeth, selectedConstructionType, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedRemovableConstructionObj) {
            var obj = {};
            if (toothStructureService.isActiveSingleTeeth(teeth,selectedRemovableConstructionObj,'partialDentures')) {
                obj['partial-denture'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth,selectedRemovableConstructionObj,'temporaryDentures')) {
                obj['temporary-denture'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth,selectedRemovableConstructionObj,'otherRemovables')) {
                obj['other-removable'] = true;
            }
            if (selectedTeeth === teeth) {
                obj['square-selected'] = true;
                //if single teeth then mark create color as well
                if (selectedConstructionType === 'temporary denture') {
                    obj['temporary-denture'] = true;
                } else if (selectedConstructionType === 'partial denture') {
                    obj['partial-denture'] = true;
                }

            }
            if (selectedTeethRange.start === teeth && (selectedConstructionType === 'complete denture' ||
                    selectedConstructionType === 'bitetray')) {

                if (selectedTeethRange.end) {
                    obj['bridge-start'] = true;
                    if (selectedConstructionType === 'bitetray') {
                        obj.bitetray = true;
                    } else {
                        obj['complete-denture'] = true;
                    }
                } else {
                    obj['bridge-start-temp'] = true;
                }

            }
            if (selectedTeethRange.end === teeth && (selectedConstructionType === 'complete denture' ||
                    selectedConstructionType === 'bitetray')) {
                obj['bridge-end'] = true;
                if (selectedConstructionType === 'bitetray') {
                    obj.bitetray = true;
                } else {
                    obj['complete-denture'] = true;
                }
            }
            if (toothStructureService.isInMiddleOfSelectedBridge(teeth, isUpperRow, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange) && (selectedConstructionType === 'complete denture' ||
                    selectedConstructionType === 'bitetray')) {
                //check if this teeth is in middle of selected bridge
                obj['bridge-middle'] = true;
                if (selectedConstructionType === 'bitetray') {
                    obj.bitetray = true;
                } else {
                    obj['complete-denture'] = true;
                }
            }

            //find if it is inside complete denture created
            var selectedClass = isInMiddle(teeth, selectedRemovableConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'completeDentures');
            var bitetraySelectedClass = isInMiddle(teeth, selectedRemovableConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'bitetrays');
            if (selectedClass) {
                //check if this teeth is in middle of selected Complete Denture
                obj[selectedClass] = true;
                obj['complete-denture'] = true;
            }
            if (bitetraySelectedClass) {
                //check if this teeth is in middle of selected Complete Denture
                obj[bitetraySelectedClass] = true;
                obj.bitetray = true;
            }

            return obj;

        }

        /**
         * Check if the tooth is in start or end or in middle of complete denture or bitetrays
         * we return the class bases on condition null for outside,
         * if it is at start then we return bridge-selected-start or if it is at end then we return bridge-selected-end
         * @param teeth
         * @param selectedRemovableConstructionObj
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param type
         * @returns {string}
         */
        function isInMiddle(teeth, selectedRemovableConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange, type) {
            var selectedClass = '';
            if (selectedRemovableConstructionObj && selectedRemovableConstructionObj[type]) {
                selectedRemovableConstructionObj[type].forEach(function (denture) {
                    if (denture.teethRange) {
                        var start, end, teethIndex;
                        if (denture.teethRange.isUpper) {
                            start = abutmentTeethFirstRange.indexOf(denture.teethRange.start);
                            end = abutmentTeethFirstRange.indexOf(denture.teethRange.end);
                            teethIndex = abutmentTeethFirstRange.indexOf(teeth);
                        } else {
                            start = abutmentTeethSecondRange.indexOf(denture.teethRange.start);
                            end = abutmentTeethSecondRange.indexOf(denture.teethRange.end);
                            teethIndex = abutmentTeethSecondRange.indexOf(teeth);
                        }
                        if (start < teethIndex && teethIndex < end) {
                            selectedClass = 'bridge-selected';
                        } else if (start === teethIndex && teethIndex < end) {
                            selectedClass = 'bridge-selected-start';
                        } else if (start < teethIndex && teethIndex === end) {
                            selectedClass = 'bridge-selected-end';
                        }
                    }
                });
            }

            return selectedClass;
        }

        /**
         * Check if particular is inside bridge/implant bridge ,complete denture
         * @param tooth
         * @param constructionObj
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param type
         * @returns {boolean}
         */
        function isSelectedTeethInsideBridge(tooth, constructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange, type) {
            var mid = false;
            if (type === 'fixed') {
                if (constructionObj && constructionObj.bridges) {
                    constructionObj.bridges.forEach(function (bridge) {
                        if (toothStructureService.checkIfInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange)) {
                            mid = true;
                        }
                    });
                }
                if (constructionObj && constructionObj.implantBridges) {
                    constructionObj.implantBridges.forEach(function (bridge) {
                        if (toothStructureService.checkIfInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange)) {
                            mid = true;
                        }
                    });
                }
            }
            if (type === 'removable') {
                if (constructionObj && constructionObj.completeDentures) {
                    constructionObj.completeDentures.forEach(function (denture) {
                        if (toothStructureService.checkIfInsideBridge(denture, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange)) {
                            mid = true;
                        }
                    });
                }
            }
            return mid;
        }

    }

})();