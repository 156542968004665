import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { MdAccountModule } from './md-account.module';
import { PipesModule } from '../../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Components
import { LimitedDentistFormComponent } from './pages/limited-dentist-form/limited-dentist-form.component';
import { DentistSignupFormComponent } from './pages/dentist-signup-form/dentist-signup-form.component';
import { AccountUserFormComponent } from './shared/account-user-form.component';
import { AccountClinicFormComponent } from './shared/account-clinic-form.component';
import { InvoiceFormComponent } from './pages/dentist-signup-form/invoice-form/invoice-form.component';

// Services
import { WhitelabelService } from './services/whitelabel.service';
import { OrganizationNumberPipe } from '../../pipes/organization-number.pipe';
import { SePostalCodePipe } from '../../pipes/se-postal-code.pipe';

@NgModule({
  entryComponents: [LimitedDentistFormComponent, DentistSignupFormComponent],
  declarations: [
    LimitedDentistFormComponent,
    DentistSignupFormComponent,
    AccountUserFormComponent,
    AccountClinicFormComponent,
    InvoiceFormComponent
  ],
  imports: [
    ...MdAccountModule,
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    FormsModule
  ],
  providers: [WhitelabelService, OrganizationNumberPipe, SePostalCodePipe]
})
export class AccountModule {}
