import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.constant';
import { Injectable } from '@angular/core';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { Role } from '../../models/roles.model';
import { Status } from '../../models/status.model';
import _ = require('lodash');
import {
  Permissions,
  CategorizedPermissions,
  PermissionProperties
} from '../../models/permissions.model';

@Injectable()
export class RoleService extends ResourceService {
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
  }

  async getAllRoles(): Promise<Role[]> {
    const res = await this.get(
      API.API_BASE + 'roles',
      undefined,
      this.apiInterceptor
    );
    return res.data;
  }

  saveRole(roleObj: {
    role: string;
    type: string;
  }): Promise<{ message: string; data: Role[] }> {
    return this.post(
      API.API_BASE + 'roles',
      roleObj,
      undefined,
      this.apiInterceptor
    );
  }

  async getRole(id: string): Promise<Role> {
    const params = new HttpParams().set('roleId', id);
    const res = await this.get(
      `${API.API_BASE}roles/:roleId`,
      params,
      this.apiInterceptor
    );
    return res.data;
  }

  updateRole(roleObj: Role): Promise<Partial<Status>> {
    const params = new HttpParams().set('roleId', roleObj._id);
    return this.put(
      `${API.API_BASE}roles/:roleId`,
      roleObj,
      params,
      this.apiInterceptor
    );
  }

  deleteRole(id: string): Promise<Partial<Status>> {
    const params = new HttpParams().set('roleId', id);
    return this.delete(`${API.API_BASE}roles/:roleId`, params, this.apiInterceptor);
  }

  async getAllPermissions(): Promise<CategorizedPermissions> {
    const res = await this.get(
      `${API.API_BASE}all/permissions`,
      undefined,
      this.apiInterceptor
    );
    return this.categorizePermissions(res.data);
  }

  private categorizePermissions(permissions: Permissions): CategorizedPermissions {
    const categorized = {};
    _.keys(permissions).forEach((key) => {
      const properties: PermissionProperties = permissions[key];
      properties.permissionName = key;
      if (!categorized[properties.category]) {
        categorized[properties.category] = [];
      }
      categorized[properties.category].push(properties);
    });

    return categorized;
  }
}
