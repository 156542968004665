/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './removable-teeth-structure.html'
/**
 * Removable Teeth Structure component
 */
(function () {
    'use strict';
    angular.module('app').component('removableTeethStructure', {
        template: theTemplate,
        controller: removableTeethStructureController,
        controllerAs: 'vm',
        bindings: {
            selectedTeeth: '=', //currently selected teeth
            selectedTeethRange: "=", // range of selected teeths
            selectedConstructionObj: '=', //currently selected constructions objects
            selectedConstructionType: '=', //selected constructions type
            teethSelected: '=', //function on a single teeth selected
            teethRangeSelected: '=',
            removeClicked: '=', // function after remove click
            selectedFixedConstructionObj: '='
        }
    });
    removableTeethStructureController.$inject = [ 'CONSTRUCTIONS','removableTeethStructureService','toothStructureService', '$rootScope', '$scope' ];
    function removableTeethStructureController(CONSTRUCTIONS,removableTeethStructureService,toothStructureService, $rootScope, $scope) {
        var vm = this;
        //teeth structure upper and lower range
        vm.abutmentTeethFirstRange = [ 18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28 ];
        vm.abutmentTeethSecondRange = [ 48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38 ];
        vm.selectedTeethRange = {};
        vm.constructionName = {
            temporaryDenture: CONSTRUCTIONS.TEMPORARY_DENTURE,
            partialDenture: CONSTRUCTIONS.PARTIAL_DENTURE,
            completeDenture: CONSTRUCTIONS.COMPLETE_DENTURE,
            bitetray: CONSTRUCTIONS.BITETRAY,
            otherRemovable: CONSTRUCTIONS.OTHER_REMOVABLE
        };
        //methods
        vm.teethClick = toothClick;
        vm.commonActiveDenture = commonActiveDenture;
        vm.removeConstruction = removeConstruction;
        /**
         * Action on click of a tooth
         * This function decides if we want to create any construction on this or not
         * @param tooth
         * @param isUpper
         */
        function toothClick(tooth, isUpper) {
            removableTeethStructureService.toothClick(tooth, isUpper, vm.selectedConstructionObj, vm.selectedTeeth,
                vm.selectedConstructionType, vm.selectedTeethRange, vm.abutmentTeethFirstRange, vm.abutmentTeethSecondRange,
                vm.selectedFixedConstructionObj, vm.teethRangeSelected, vm.teethSelected)
                .then(function (result) {
                    // It the construction is possible to that particular tooth or teeth range then there is a result from service
                    if (result) {
                        vm.selectedTeethRange = result.selectedTeethRange;
                        vm.teethRangeSelected = result.teethRangeSelected;
                        vm.selectedConstructionType = result.selectedConstructionType;
                        vm.selectedTeeth = result.selectedTeeth;
                        // when the construction if of fixed construction type then we have to switch the tab
                        if (checkIfTheConstructionIsOfFixedType(vm.selectedConstructionType)) {
                            $rootScope.$broadcast("changeConstructionType", { type: 'fixed',
                                selectedConstructionType: result.selectedConstructionType,
                                tooth: tooth, isUpper: isUpper
                            });
                        }
                    }
                }).catch(function (err) {
                    // If there is error then empty the teeth range and selected teeth to not make a construction.
                    vm.selectedTeethRange = {};
                    vm.selectedTeeth = '';
                });
        }

        /**
         * Remove construction click
         * @param teeth
         */
        function removeConstruction(event, teeth) {
            event.stopPropagation();
            if (vm.removeClicked) {
                vm.removeClicked(teeth);
            }
        }

        /**
         * Return class set for given tooth based on all conditions
         * @param teeth
         * @param isUpperRow
         * @returns {{}}
         */

        function commonActiveDenture(teeth, isUpperRow) {
            if (vm.selectedConstructionType === 'bitetray' ||
                vm.selectedConstructionType === 'complete denture') {
                vm.selectedTeeth = '';
            }
            var obj = removableTeethStructureService.commonActiveCrown(teeth, true, vm.selectedFixedConstructionObj, vm.selectedTeeth, vm.selectedConstructionType, vm.selectedTeethRange, vm.abutmentTeethFirstRange, vm.abutmentTeethSecondRange);
            if (toothStructureService.isActiveSingleTeeth(teeth, vm.selectedConstructionObj,'partialDentures')) {
                obj['partial-denture'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, vm.selectedConstructionObj,'temporaryDentures')) {
                obj['temporary-denture'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, vm.selectedConstructionObj,'otherRemovables')) {
                obj['other-removable'] = true;
            }
            if (vm.selectedTeeth === teeth) {
                obj['square-selected'] = true;
                //if single teeth then mark create color as well
                /*   if(vm.selectedConstructionType === vm.constructionName.temporaryDenture){
                      obj['temporary-denture'] = true;
                  }
                  else if(vm.selectedConstructionType === vm.constructionName.partialDenture){
                      obj['partial-denture'] = true;
                  }
                   else if(vm.selectedConstructionType === vm.constructionName.otherRemovable){
                       obj['other-removable'] = true;
                   }*/

            }
            if (vm.selectedTeethRange.start === teeth && (vm.selectedConstructionType === 'complete denture' ||
                    vm.selectedConstructionType === 'bitetray')) {

                if (vm.selectedTeethRange.end) {
                    obj['bridge-start'] = true;
                    /*if(vm.selectedConstructionType === 'bitetray'){
                       obj.bitetray = true;
                    }
                    else {
                       obj['complete-denture'] = true;
                    }*/
                } else {
                    obj['bridge-start-temp'] = true;
                }

            }
            if (vm.selectedTeethRange.end === teeth && (vm.selectedConstructionType === 'complete denture' ||
                    vm.selectedConstructionType === 'bitetray')) {
                obj['bridge-end'] = true;
                /*if(vm.selectedConstructionType === 'bitetray'){
                  obj.bitetray = true;
                }
                else {
                    obj['complete-denture'] = true;
                }*/
            }
            if (toothStructureService.isInMiddleOfSelectedBridge(teeth, isUpperRow, vm.selectedTeethRange, vm.abutmentTeethFirstRange, vm.abutmentTeethSecondRange) && (vm.selectedConstructionType === 'complete denture' ||
                    vm.selectedConstructionType === 'bitetray')) {
                //check if this teeth is in middle of selected bridge
                obj['bridge-middle'] = true;
                /*if(vm.selectedConstructionType === 'bitetray'){
                   obj.bitetray = true;
                }
                else {
                   obj['complete-denture'] = true;
                }*/
            }

            //find if it is inside complete denture created
            var selectedClass = toothStructureService.isInMiddleOfAnyCreatedBridge(teeth,vm.selectedConstructionObj, vm.abutmentTeethFirstRange, vm.abutmentTeethSecondRange,'completeDentures');
            var bitetraySelectedClass = toothStructureService.isInMiddleOfAnyCreatedBridge(teeth,vm.selectedConstructionObj, vm.abutmentTeethFirstRange, vm.abutmentTeethSecondRange,'bitetrays');
            if (selectedClass) {
                //check if this teeth is in middle of selected Complete Denture
                obj[selectedClass] = true;
                obj['complete-denture'] = true;
            }
            if (bitetraySelectedClass) {
                //check if this teeth is in middle of selected Complete Denture
                obj[bitetraySelectedClass] = true;
                obj.bitetray = true;
            }

            return obj;

        }

        /**
         * method to check if it is of fixed construction type
         * @param selectedConstructionType
         * @returns {*}
         */
        function checkIfTheConstructionIsOfFixedType(selectedConstructionType) {
            var fixedConstructions = [ 'single crown', 'implant crown', 'bridge', 'other', 'implant bridge' ];
            return fixedConstructions.indexOf(selectedConstructionType) > -1;

        }

        /**
         * listening when the tab is switiong from fixed to removable
         */
        $scope.$on("selectedRemovableConstructionType", function (result, data) {
            vm.selectedConstructionType = data.selectedConstructionType;
            toothClick(data.tooth, data.isUpper);
        });
    }

})();