import { Input, Component, EventEmitter, Output } from '@angular/core';
import template from './added-materials.html';

@Component({
  selector: 'added-materials',
  template: template
})
export class AddedMaterialsComponent {
  @Input() addedMaterials: any = [];
  @Input() isVisibleInPrint: boolean = false;
  @Input() parentIndex: number = -1;
  @Input() showEditBtn: boolean = false;
  @Input() currency: string;
  @Output() openEditMaterialDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() openConfirmDeleteMaterialDialog: EventEmitter<string> = new EventEmitter<
    string
  >();

  openEditDialog(addedMaterial: any) {
    if (this.openEditMaterialDialog) {
      this.openEditMaterialDialog.emit(addedMaterial);
    }
  }

  openDeleteMaterialDialog(addedMaterialId: string) {
    if (this.openConfirmDeleteMaterialDialog) {
      this.openConfirmDeleteMaterialDialog.emit(addedMaterialId);
    }
  }

  getClass(field: string): any {
    return {
      'red-color': false
    };
  }
}
