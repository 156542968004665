import { Component, EventEmitter, Input, Output } from '@angular/core';
import template from './add-actionList-action-modal.html';
import { ActionListService } from '../../../../../services/action-list/action-list.service';
import {
  ActionNew,
  ActionList
} from '../../../../../models/organization.model';
import { LogService } from '../../../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../../../../services/translations/translations.service';
@Component({
  selector: 'add-actionList-action-modal',
  template
})
export class AddActionListActionModalComponent {
  @Input() action: ActionNew;
  @Input() selectedList: ActionList;
  @Output()
  onModalClosed: EventEmitter<ActionList | void> = new EventEmitter<ActionList | void>();
  constructor(
    private readonly actionListService: ActionListService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService
  ) {}

  updateAction(): Promise<void> {
    if (this.action.price) {
      this.action.price = this.checkAndReplaceComma(this.action.price.toString());
    }
    if (this.action.vat) {
      this.action.vat = this.checkAndReplaceComma(this.action.vat.toString());
    }
    return this.actionListService
      .updateActionOnList(this.selectedList._id, this.action)
      .then(async (result) => {
        this.toastr.success(
          await this.translationsService.get('ACTION_UPDATED')
        );
        this.onModalClosed.emit(result.data.actionLists);
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_UPDATING_ACTION'
        );
        this.logService.error(
          'add-actionList-action-modal.component',
          'updateAction',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  addAction(): Promise<void> {
    if (this.action.price) {
      this.action.price = this.checkAndReplaceComma(this.action.price.toString());
    }
    if (this.action.vat) {
      this.action.vat = this.checkAndReplaceComma(this.action.vat.toString());
    }
    return this.actionListService
      .addActionToList(this.selectedList._id, this.action)
      .then(async (result) => {
        this.toastr.success(
          await this.translationsService.get('ACTION_CREATED')
        );
        this.onModalClosed.emit(result.data.actionLists);
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_CREATING_ACTION'
        );
        this.logService.error(
          'add-actionList-action-modal.component',
          'addAction',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  cancel() {
    this.onModalClosed.emit();
  }

  checkAndReplaceComma(entity: string) {
    if (entity.includes(',')) {
      entity = entity.split(',').join('.');
    }
    return parseFloat(entity);
  }

  activeInactiveAction(value: boolean) {
    this.action.inactive = value;
    if (value) {
      this.action.inactiveDate = Date.now();
    } else {
      this.action.inactiveDate = null;
    }
  }

  disableSave(): boolean {
    if (
      !this.action ||
      !this.action.categoryId ||
      !this.action.code ||
      !this.action.description ||
      !this.action.price
    ) {
      return true;
    }
  }
}
