import { Output, Input, OnChanges, Component, EventEmitter } from '@angular/core';
import template from './add-simple-order-action-modal.html';
import style from '../add-order-material-modal/order-material-modal.less';
import {
  ActionNew,
  AssociatedActionList,
  Organization
} from '../../../models/organization.model';
import * as _ from 'lodash';
import { TranslationsService } from '../../../services/translations/translations.service';

@Component({
  selector: 'add-simple-order-action-modal',
  template,
  styles: [style]
})
export class AddSimpleOrderActionModalComponent implements OnChanges {
  @Output() onAddUpdateAction: EventEmitter<Partial<ActionNew>> = new EventEmitter<
    Partial<ActionNew>
    >();
  @Output() onDeleteAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() onModelClosed: EventEmitter<void> = new EventEmitter<void>();
  @Input() selectedAction: Partial<ActionNew>;
  @Input() associatedActionList: AssociatedActionList[];
  @Input() lab: Organization;
  action: Partial<ActionNew> = {};
  showActionListDropdown = false;
  textForActionDropDown = '';
  isFreeText = false;
  isActionSelected = false;
  labConnectedActionList: Partial<AssociatedActionList> = {};
  selectedCategory;

  constructor(private readonly translationsService: TranslationsService) {}

  closeModal() {
    this.action = {};
    this.selectedAction = {};
    this.textForActionDropDown = null;
    this.showActionListDropdown = false;
    this.selectedCategory = null;
    setTimeout(() => {
      this.isFreeText = false;
      this.isActionSelected = false;
    }, 0);
    this.onModelClosed.emit();
  }

  ngOnChanges() {
    this.getActionList();
    if (this.selectedAction?._id) {
      this.isActionSelected = true;
      this.action = this.selectedAction;
      this.textForActionDropDown = this.selectedAction.fullActionName;
      if (this.textForActionDropDown) {
        this.isFreeText = !this.action.description;
      }
    }
  }

  getActionsByCategory(categoryId) {
    this.getActionList();
    if (!categoryId) {
      return;
    }
    this.labConnectedActionList.actions = this.labConnectedActionList.actions.filter(
      (action) => action.categoryId === categoryId
    );
  }

  checkAndReplaceCommaFromPrice(entity: string) {
    if (entity.includes(',')) {
      entity = entity.split(',').join('.');
    }
    return parseFloat(entity);
  }

  getActionList() {
    if (this.associatedActionList) {
      const actionList = this.associatedActionList.find(
        (list) => list.lab === this.lab._id
      );
      if (actionList) {
        this.labConnectedActionList = _.cloneDeep(actionList);
        this.lab && this.lab.actionLists && this.lab.actionLists.forEach((list) => {
          if(list._id === this.labConnectedActionList.actionListId) {
            this.labConnectedActionList.categories = list.categories
          }
        });
      }
     this.getDefaultActionList(actionList);
    }
  }

  getDefaultActionList(actionList) {
    if (
      !actionList &&
      this.lab &&
      this.lab.actionLists &&
      this.lab.defaultActionListId
    ) {
      const defaultActionList = this.lab.actionLists.find(
        (list) => list._id === this.lab.defaultActionListId
      );
      if (defaultActionList) {
        this.labConnectedActionList = _.cloneDeep(defaultActionList);
      }
    }
  }

  getPriceWithVat() {
    if (!this.action.price || !this.action.quantity) {
      return 0;
    }
    const quantity = this.checkAndReplaceCommaFromPrice(
      this.action.quantity.toString()
    );
    const basePrice = this.checkAndReplaceCommaFromPrice(
      this.action.price.toString()
    );
    const price = parseFloat((basePrice * quantity).toFixed(2));
    if (!this.action.vat) {
      return price;
    }
    const vat = this.checkAndReplaceCommaFromPrice(this.action.vat.toString());
    return parseFloat((price + (price * vat) / 100).toFixed(2));
  }

  disableSaveButton() {
    if (!this.action || !this.action.fullActionName || !this.action.quantity || !this.action.price) {
      return true;
    }
    if (!this.isFreeText) {
      return false;
    }
    return this.checkFieldFilled();
  }

  checkFieldFilled() {
    if (
      !this.action.fullActionName ||
      !this.action.price ||
      this.action.price.toString() === '-' ||
      !this.action.quantity
    ) {
      return true;
    }
    return false;
  }

  addUpdateAction() {
    this.action.quantity = this.checkAndReplaceCommaFromPrice(
      this.action.quantity.toString()
    );
    this.action.price = this.checkAndReplaceCommaFromPrice(
      this.action.price.toString()
    );
    if (this.action.vat) {
      this.action.vat = this.checkAndReplaceCommaFromPrice(
        this.action.vat.toString()
      );
    }
    if (this.isFreeText) {
      this.action.actionObj = { name: this.action.fullActionName, number: '' };
    }
    this.onAddUpdateAction.emit(this.action);
    this.closeModal();
  }

  deleteAction() {
    this.onDeleteAction.emit(this.action._id);
    this.closeModal();
  }

  toggleActionList() {
    this.showActionListDropdown = !this.showActionListDropdown;
  }

  async selectFreeText() {
    this.isFreeText = true;
    this.isActionSelected = true;
    this.showActionListDropdown = false;
    this.action = {};
    this.textForActionDropDown = await this.translationsService.get('FREE_TEXT');
  }

  getSelectedValue(action: Partial<ActionNew>) {
    let price = 0;
    this.isFreeText = false;
    this.isActionSelected = true;
    this.action.code = action.code;
    this.action.description = action.description;
    this.action.action = action._id;
    this.action.fullActionName = `${action.code} ${action.description}`;
    this.textForActionDropDown = this.action.fullActionName;
    if (action.discountedPrice) {
      price = action.discountedPrice;
    } else if (this.labConnectedActionList.generalDiscount) {
      price =  action.price - ((this.labConnectedActionList.generalDiscount as number) / 100) * action.price;
    } else {
      price = action.price;
    }
    this.action.price = price;
    this.action.vat = action.vat;
    this.action.actionObj = { name: action.description, number: action.code };
    this.showActionListDropdown = false;
  }
}
