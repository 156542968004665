import { Injectable } from '@angular/core';

@Injectable()
export class PatientCategoryService {
  getPatientCategoryByPin(pin, patientCategories) {
    if (!pin) {
      return '';
    }
    const yearDigits = 4;
    const maxAge = 150;
    const presentYear = new Date().getFullYear();
    const yearFromPin = pin.substring(0, yearDigits);
    const age = presentYear - yearFromPin;
    if (!patientCategories || patientCategories.length === 0) {
      return '';
    }
    return patientCategories.find((item) => {
      return age >= (item.minage || 0) && age <= (item.maxage || maxAge);
    });
  }
}
