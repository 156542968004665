import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Action } from '../../../../../../models/action.model';
import template from './actions-dropdown.html';
import { ConstructionPageService } from '../../../../services/construction-page-service';
import { isEqual } from 'lodash';
import { ConstructionSettingsService } from '../../../../services/construction-settings-service';
import { TranslationsService } from '../../../../../../services/translations/translations.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'actions-dropdown',
  template: template
})
export class ActionsDropdownComponent implements OnChanges {
  @Input() actions: Action[] = [];
  @Output() actionSelected: EventEmitter<Action[]> = new EventEmitter<Action[]>();

  selectedActions: Action[] = [];

  constructor(
    private readonly constructionPageService: ConstructionPageService,
    private readonly constructionSettingsService: ConstructionSettingsService,
    private readonly toastr: ToastrService,
    private readonly translationsService: TranslationsService
  ) {}

  setSelectedActions() {
    this.selectedActions = this.constructionPageService.selectedActions;
    this.selectedActions.forEach((selectedAction) => {
      selectedAction.selected = true;
      const action = this.actions?.find(
        (action) => action._id === selectedAction._id
      );
      if (action?._id) {
        action.selected = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isEqual(changes.actions?.currentValue, changes.actions?.previousValue)) {
      this.selectedActions = [];
    }
    changes.actions?.currentValue.forEach((action) => {
      action.selected = false;
    });
    this.setSelectedActions();
  }

  async actionSelectionClick(event: any, action: Action) {
    event.preventDefault();
    if (action.isNotesEnabled) {
      event.stopPropagation();
    }
    // if action was already selected, de-select it
    if (action.selected) {
      if (this.selectedActions.length !== 1) {
        action.selected = false;
        const index = this.selectedActions.findIndex(
          (selectedAction) => selectedAction._id === action._id
        );
        this.selectedActions.splice(index, 1);
        this.constructionSettingsService.removeNumberOfUnits(action._id);
        this.constructionPageService.selectedActions = this.selectedActions;
        this.actionSelected.emit(this.selectedActions);
      } else {
        // show toastr here
        this.toastr.warning(
          await this.translationsService.get('AT_LEAST_ONE_ACTION_REQUIRED')
        );
      }
    } else {
      // select it
      action.selected = true;
      this.selectedActions.push(action);
      this.constructionPageService.selectedActions = this.selectedActions;
      this.actionSelected.emit(this.selectedActions);
    }
  }

  getCheckedActions(): string {
    if (!this.selectedActions) {
      return null;
    }
    return this.selectedActions
      .map((action) => `${action.number} ${action.name}`)
      .join(',');
  }
}
