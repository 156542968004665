import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import {
  MAT_DATE_LOCALE,
  MatDateFormats,
  DateAdapter,
  MAT_DATE_FORMATS
} from '@angular/material/core';

import '../../../scss/main.scss';

// Modules
import { MdTransportAppModule } from './md-transport-app.module';
import { PipesModule } from '../../pipes/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';

// Components
import { TaSelectClinicComponent } from './pages/transport-order-wizard/ta-select-clinic/select-clinic.component';
import { TransportOrderWizardComponent } from './pages/transport-order-wizard/transport-order-wizard.component';
import { TaPickupDateComponent } from './pages/transport-order-wizard/ta-transport-dates/ta-pickup-date.component';
import { TaDeliveryDateComponent } from './pages/transport-order-wizard/ta-transport-dates/ta-delivery-date.component';
import { TaPackagePicker } from './shared/ta-package-picker/ta-package-picker';
import { ScheduledTransportsComponent } from './pages/scheduled-transports/scheduled-transports.component';
import { TransportRowComponent } from './pages/scheduled-transports/transport-row/transport-row.component';
import { ScheduledTransportsActionsComponent } from './pages/scheduled-transports/scheduled-transports-actions/scheduled-transports-actions.component';
import { EditTransportDialogComponent } from './pages/scheduled-transports/edit-transport-dialog/edit-transport-dialog.component';
import { TaDeadlinePicker } from './shared/ta-deadline-picker/ta-deadline-picker.component';
import { TaTransportStatus } from './shared/ta-transport-status/ta-transport-status.component';
import { ExistingTransportDialogComponent } from './pages/transport-order-wizard/existing-transport-dialog/existing-transport-dialog.component';
import { TransportStatusLogDialogComponent } from './shared/transport-status-log-dialog/transport-status-log-dialog.component';

// Services
import { TransportAppService } from './services/transport-app.service';
import { TaDatesService } from './services/ta-dates.service';

// TODO: Could be set in app root or similar
export const DENTHUB_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'ddd, YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  entryComponents: [
    TransportOrderWizardComponent,
    ScheduledTransportsComponent,
    EditTransportDialogComponent,
    ExistingTransportDialogComponent,
    TransportStatusLogDialogComponent
  ],
  declarations: [
    TaSelectClinicComponent,
    TransportOrderWizardComponent,
    TaPickupDateComponent,
    TaDeliveryDateComponent,
    TaPackagePicker,
    ScheduledTransportsComponent,
    TransportRowComponent,
    ScheduledTransportsActionsComponent,
    EditTransportDialogComponent,
    TaDeadlinePicker,
    TaTransportStatus,
    ExistingTransportDialogComponent,
    TransportStatusLogDialogComponent
  ],
  imports: [
    ...MdTransportAppModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgSelectModule,
    UiSwitchModule
  ],
  exports: [
    NgSelectModule,
    UiSwitchModule,
    ScheduledTransportsComponent,
    TransportOrderWizardComponent,
    ...MdTransportAppModule
  ],
  providers: [
    TransportAppService,
    TaDatesService,
    // TODO: Could be set in app root or similar
    { provide: MAT_DATE_LOCALE, useValue: 'sv' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DENTHUB_FORMATS }
  ]
})
export class TransportAppModule {}
