import { Injectable } from '@angular/core';
import { Order } from '../../models/order.model';
import { OrderService } from './order-new.service';
import { LogService } from '../core/log.service';
import { ORDER_VERSION } from '../../app.constant';
import { Status } from '../../models/status.model';

@Injectable()
export class UnArchiveOrderService {
  constructor(
    private orderService: OrderService,
    private logService: LogService
  ) {}

  unArchiveOrder(
    orderId: string,
    orderDetail: Order
  ): Promise<Partial<Status>> {
    const updatedObject = orderDetail;
    updatedObject.isArchive = false;
    updatedObject.sentMail = true;

    const isSimpleOrder = orderDetail.type === ORDER_VERSION.ORDER_1;
    if (isSimpleOrder) {
      updatedObject.orderId = orderId;
      return this.orderService
        .updateOrder(updatedObject)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          this.logService.error(
            'un-archive-order.service',
            'unArchiveOrder',
            err
          );
          throw err;
        });
    } else {
      updatedObject.id = orderId;
      return this.orderService
        .updateNewOrder(updatedObject)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          this.logService.error(
            'un-archive-order.service',
            'unArchiveOrder 1',
            err
          );
          throw err;
        });
    }
  }
}
