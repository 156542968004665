import { Component, Input } from '@angular/core';
import template from './account-user-form.html';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'account-user-form',
  template
})
export class AccountUserFormComponent {
  @Input() formGroup: FormGroup;
}
