/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-fixed-constructions.html'
/**
 * @Author : Surendra
 * This is main component for fixed type constructions
 */
(function () {
  'use strict';
  angular.module('app').component('orderFixedConstructions', {
    template: theTemplate,
    controller: fixedConstructionController,
    controllerAs: 'vm',
    bindings: angular.extend({}, sessionStateBindings, {
      selectedConstructionObj: '=',
      selectedLab: '=',
      newOrderObj: '=',
      orderIdToModify: '=',
      commentsArray: '='
    })
  });
  fixedConstructionController.$inject = [
    'DESIGNS',
    '$scope',
    'organizationService',
    'logService',
    'eventService',
    'toastr',
    '$filter',
    'organizationSettingService',
    'CUSTOMER_SETTINGS_CONSTANTS'
  ];
  /**
   * Controller function for the component
   */
  function fixedConstructionController(
    DESIGNS,
    $scope,
    organizationService,
    logService,
    eventService,
    toastr,
    $filter,
    organizationSettingService,
    CUSTOMER_SETTINGS_CONSTANTS
  ) {
    //variables
    var vm = this;
    vm.selectedTeeth = '';
    vm.selectedTeethRange = {};
    vm.tempSingleCrownData = {};
    vm.tempImplantCrownData = {};
    vm.tempImplantBridgeData = {};
    vm.tempBridgeData = {};
    vm.tempPostData = {};
    vm.singleCrownCommentsArray = [];
    vm.bridgeCommentsArray = [];
    vm.otherCommentsArray = [];
    vm.postCommentsArray = [];
    vm.showSpinner = false;
    vm.selectedConstruction = {
      selectedType: ''
    };
    //methods
    vm.selectConstruction = selectConstruction;
    vm.addUpdateSingleCrown = addUpdateSingleCrown;
    vm.removeSingleCrown = removeSingleCrown;
    vm.addUpdatePost = addUpdatePost;
    vm.addUpdateBridge = addUpdateBridge;
    vm.removePost = removePost;
    vm.removeBridge = removeBridge;
    vm.teethSelected = teethSelected;
    vm.teethRangeSelected = teethRangeSelected;
    vm.selectType = selectType;
    vm.removeConstruction = removeConstruction;
    vm.constructionTypeClass = constructionTypeClass;
    vm.addUpdateOther = addUpdateOther;
    vm.removeOther = removeOther;

    /**
     * Construction settings
     */
    vm.constructionSettings = {
      types: DESIGNS.TYPES,
      materials: DESIGNS.MATERIALS,
      occlusionContactTypes: organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.OCCLUSION_TYPE
      ),
      contactTypes: organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CONTACT_TYPE
      ),
      postMaterials: DESIGNS.POST_MATERIALS,
      design: {
        metals: organizationSettingService.getCustomerSetting(
          CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.METAL_DESIGN
        ),
        pontics: organizationSettingService.getCustomerSetting(
          CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PONTIC_DESIGN
        )
      }
    };
    /**
     * remove construction from here if any
     * @param teeth
     */
    function removeConstruction(teeth) {
      if (vm.selectedConstruction.selectedType === 'single crown') {
        removeSingleCrown(teeth);
      } else if (vm.selectedConstruction.selectedType === 'implant crown') {
        removeImplantCrown(teeth);
      } else if (vm.selectedConstruction.selectedType === 'post') {
        removePost(teeth);
      } else if (vm.selectedConstruction.selectedType === 'bridge') {
        removeBridgeEnd(teeth);
      } else if (vm.selectedConstruction.selectedType === 'implant bridge') {
        removeBridgeImplantEnd(teeth);
      } else if (vm.selectedConstruction.selectedType === 'other') {
        removeOther(teeth);
      }
    }

    /**
     * Select new type button click
     * @param type
     */
    function selectType(type) {
      vm.selectedConstruction.selectedType = type;
      vm.selectedTeeth = '';
      vm.selectedTeethRange = {};
    }
    /**
     * Function to decide which construction to be created on this teeth
     * @param teeth
     */
    function selectConstruction(teeth) {
      vm.selectedTeeth = teeth;
    }

    vm.resetTempConstructionPerTeeth = resetTempConstructionPerTeeth;
    vm.tempSelectConstructionPerTooth = {
      material: '',
      shade: '',
      color: '',
      otherMaterial: '',
      metalDesign: '',
      ponticType: '',
      occlusionType: ''
    };
    function resetTempConstructionPerTeeth() {
      vm.tempSelectConstructionPerTooth = {
        material: '',
        shade: '',
        color: '',
        otherMaterial: '',
        metalDesign: '',
        ponticType: '',
        occlusionType: ''
      };
    }

    /**
     *
     * @param type
     */
    function constructionTypeClass(type, selectedType) {
      if (type !== selectedType) {
        return '';
      }
      var colorClass = '';
      switch (type) {
        case 'single crown':
          colorClass = 'single-crown';
          break;
        case 'bridge':
          colorClass = 'bridge';
          break;
        case 'implant crown':
          colorClass = 'implant-crown';
          break;
        case 'post':
          colorClass = 'post';
          break;
        case 'implant bridge':
          colorClass = 'implant-bridge';
          break;
        case 'other':
          colorClass = 'other';
          break;
      }

      return colorClass;
    }

    function teethRangeSelected(teethRange) {
      if (vm.selectedConstruction.selectedType === 'bridge') {
        vm.bridgeCrownData = {};
        vm.selectedConstructionObj.bridges.forEach(function (bridge) {
          if (
            bridge.teethRange &&
            bridge.teethRange.start === teethRange.start &&
            bridge.teethRange.end === teethRange.end
          ) {
            // it is update case
            vm.bridgeCrownData = angular.copy(bridge);
          }
        });
      } else if (vm.selectedConstruction.selectedType === 'implant bridge') {
        vm.bridgeCrownData = {};
        vm.selectedConstructionObj.implantBridges.forEach(function (bridge) {
          if (
            bridge.teethRange &&
            bridge.teethRange.start === teethRange.start &&
            bridge.teethRange.end === teethRange.end
          ) {
            // it is update case
            vm.bridgeCrownData = angular.copy(bridge);
          }
        });
      }
    }

    /**
     * Action on when we selected a teeth from teeth structure
     * @param teeth
     */

    function teethSelected(teeth) {
      //vm.teethSelected
      if (
        vm.selectedConstruction.selectedType === 'single crown' ||
        vm.selectedConstruction.selectedType === 'implant crown'
      ) {
        vm.singleCrownObj = {};
        vm.selectedConstructionObj.singleCrowns.forEach(function (crown) {
          if (crown.teethNo === teeth) {
            // it is update case
            vm.singleCrownObj = angular.copy(crown);
          }
        });
        vm.selectedConstructionObj.implantCrowns.forEach(function (crown) {
          if (crown.teethNo === teeth) {
            // it is update case
            vm.singleCrownObj = angular.copy(crown);
          }
        });
      } else if (vm.selectedConstruction.selectedType === 'post') {
        vm.singlePostData = {};
        vm.selectedConstructionObj.posts.forEach(function (post) {
          if (post.teethNo === teeth) {
            // it is update case
            vm.singlePostData = angular.copy(post);
          }
        });
      } else if (vm.selectedConstruction.selectedType === 'other') {
        vm.singleOtherData = {};
        vm.selectedConstructionObj.others.forEach(function (other) {
          if (other.teethNo === teeth) {
            // it is update case
            vm.singleOtherData = angular.copy(other);
          }
        });
      }
    }

    /**
     * Add or update a single crown on a teeth
     * @param singleCrown
     */
    function addUpdateSingleCrown(singleCrown, type) {
      if (!vm.tempSingleCrownData) {
        vm.tempSingleCrownData = {};
      }
      //vm.tempSingleCrownData = {};
      vm.tempImplantCrownData = {};
      var isUpdated = false;
      if (type === 'implant crown') {
        //find
        vm.selectedConstructionObj.implantCrowns.forEach(function (crown, index) {
          if (singleCrown.teethNo === crown.teethNo) {
            vm.selectedConstructionObj.implantCrowns[index] = singleCrown;
            isUpdated = true;
          }
        });
        if (!isUpdated) {
          vm.selectedConstructionObj.implantCrowns.push(singleCrown);
        }
      } else {
        //find
        vm.selectedConstructionObj.singleCrowns.forEach(function (crown, index) {
          if (singleCrown.teethNo === crown.teethNo) {
            vm.selectedConstructionObj.singleCrowns[index] = singleCrown;
            isUpdated = true;
          }
        });
        //if not found then add
        /* vm.selectedConstructionObj.singleCrowns.push(singleCrown);*/
        if (!isUpdated) {
          vm.selectedConstructionObj.singleCrowns.push(singleCrown);
        }
      }
    }

    /**
     * Add or update a single crown on a teeth
     * @param singleCrown
     */
    function addUpdatePost(post) {
      //find
      var isUpdated = false;
      vm.selectedConstructionObj.posts.forEach(function (p, index) {
        if (p.teethNo === post.teethNo) {
          vm.selectedConstructionObj.posts[index] = post;
          isUpdated = true;
        }
      });
      //if not found then add
      if (!isUpdated) {
        vm.selectedConstructionObj.posts.push(post);
      }
    }

    /**
     * Add or update a single crown on a teeth
     * @param singleCrown
     */
    function addUpdateOther(other) {
      //find
      var isUpdated = false;
      vm.selectedConstructionObj.others.forEach(function (p, index) {
        if (p.teethNo === other.teethNo) {
          vm.selectedConstructionObj.others[index] = other;
          isUpdated = true;
        }
      });
      //if not found then add
      if (!isUpdated) {
        vm.selectedConstructionObj.others.push(other);
      }
    }

    function addUpdateBridge(bridge, type) {
      vm.tempBridgeData = {};
      var ind = 0;
      vm.tempImplantBridgeData = {};
      var isUpdated = false;
      if (type === 'implant bridge') {
        if (
          vm.selectedConstructionObj.implantBridges &&
          vm.selectedConstructionObj.implantBridges.length
        ) {
          for (var ib in vm.selectedConstructionObj.implantBridges) {
            if (
              vm.selectedConstructionObj.implantBridges[ib].teethRange.start ===
                bridge.teethRange.start &&
              vm.selectedConstructionObj.implantBridges[ib].teethRange.end ===
                bridge.teethRange.end
            ) {
              isUpdated = true;
              ind = ib;
            }
          }

          //if not found then add
          if (!isUpdated) {
            vm.selectedConstructionObj.implantBridges.push(bridge);
          } else {
            if (ib) {
              vm.selectedConstructionObj.implantBridges.splice(ind, 1);
              vm.selectedConstructionObj.implantBridges.splice(ind, 0, bridge);
            }
          }
        } else {
          vm.selectedConstructionObj.implantBridges.push(bridge);
        }
      } else {
        if (
          vm.selectedConstructionObj.bridges &&
          vm.selectedConstructionObj.bridges.length
        ) {
          for (var br in vm.selectedConstructionObj.bridges) {
            if (
              vm.selectedConstructionObj.bridges[br].teethRange.start ===
                bridge.teethRange.start &&
              vm.selectedConstructionObj.bridges[br].teethRange.end ===
                bridge.teethRange.end
            ) {
              isUpdated = true;
              ind = br;
            }
          }

          //if not found then add
          if (!isUpdated) {
            vm.selectedConstructionObj.bridges.push(bridge);
          } else {
            if (br) {
              vm.selectedConstructionObj.bridges.splice(ind, 1);
              vm.selectedConstructionObj.bridges.splice(ind, 0, bridge);
            }
          }
        } else {
          vm.selectedConstructionObj.bridges.push(bridge);
        }
      }
    }

    /**
     * remove already created crown
     * @param teeth
     */
    function removeSingleCrown(teeth) {
      var index = -1;
      for (var i = 0; i < vm.selectedConstructionObj.singleCrowns.length; i++) {
        if (vm.selectedConstructionObj.singleCrowns[i].teethNo === teeth) {
          index = i;
        }
      }
      if (index > -1) {
        vm.selectedConstructionObj.singleCrowns.splice(index, 1);
      }
      vm.selectedTeeth = '';
    }
    /**
     * remove already created crown
     * @param teeth
     */
    function removeImplantCrown(teeth) {
      var index = -1;
      for (var i = 0; i < vm.selectedConstructionObj.implantCrowns.length; i++) {
        if (vm.selectedConstructionObj.implantCrowns[i].teethNo === teeth) {
          index = i;
        }
      }
      if (index > -1) {
        vm.selectedConstructionObj.implantCrowns.splice(index, 1);
      }
      vm.selectedTeeth = '';
    }

    function removePost(teeth) {
      var index = -1;
      for (var i = 0; i < vm.selectedConstructionObj.posts.length; i++) {
        if (vm.selectedConstructionObj.posts[i].teethNo === teeth) {
          index = i;
        }
      }
      if (index > -1) {
        vm.selectedConstructionObj.posts.splice(index, 1);
      }
      vm.selectedTeeth = '';
    }

    function removeOther(teeth) {
      var index = -1;
      for (var i = 0; i < vm.selectedConstructionObj.others.length; i++) {
        if (vm.selectedConstructionObj.others[i].teethNo === teeth) {
          index = i;
        }
      }
      if (index > -1) {
        vm.selectedConstructionObj.others.splice(index, 1);
      }
      vm.selectedTeeth = '';
    }

    function removeBridge(teethRange) {
      var index = -1;
      for (var i = 0; i < vm.selectedConstructionObj.bridges.length; i++) {
        if (
          vm.selectedConstructionObj.bridges[i].teethRange.start ===
            teethRange.start &&
          vm.selectedConstructionObj.bridges[i].teethRange.end === teethRange.end
        ) {
          index = i;
        }
      }
      if (index > -1) {
        vm.selectedConstructionObj.bridges.splice(index, 1);
      }

      var indexImplant = -1;
      for (var j = 0; j < vm.selectedConstructionObj.implantBridges.length; j++) {
        if (
          vm.selectedConstructionObj.implantBridges[i].teethRange.start ===
            teethRange.start &&
          vm.selectedConstructionObj.implantBridges[i].teethRange.end ===
            teethRange.end
        ) {
          indexImplant = j;
          break;
        }
      }
      if (indexImplant > -1) {
        vm.selectedConstructionObj.implantBridges.splice(indexImplant, 1);
      }
    }

    function removeBridgeEnd(teeth) {
      var index = -1;
      for (var i = 0; i < vm.selectedConstructionObj.bridges.length; i++) {
        if (vm.selectedConstructionObj.bridges[i].teethRange.end === teeth) {
          index = i;
        }
      }
      if (index > -1) {
        vm.selectedConstructionObj.bridges.splice(index, 1);
      }
      vm.selectedTeethRange = {};
    }

    function removeBridgeImplantEnd(teeth) {
      var index = -1;
      for (var i = 0; i < vm.selectedConstructionObj.implantBridges.length; i++) {
        if (vm.selectedConstructionObj.implantBridges[i].teethRange.end === teeth) {
          index = i;
        }
      }
      if (index > -1) {
        vm.selectedConstructionObj.implantBridges.splice(index, 1);
      }
      vm.selectedTeethRange = {};
    }

    // Method to check the current lab have the type of constructions
    $scope.$watch('vm.selectedLab', function () {
      vm.showSpinner = true;
      if (vm.selectedLab) {
        organizationService
          .getOrganization({ id: vm.selectedLab })
          .then(function (result) {
            vm.currentSelectedLab = result.data;
            vm.newOrderObj.labData = result.data;
            if (vm.currentSelectedLab.currentJobs.length) {
              for (var i in vm.currentSelectedLab.currentJobs) {
                for (var j in vm.constructionSettings.types) {
                  if (
                    vm.currentSelectedLab.currentJobs[i].name ===
                    vm.constructionSettings.types[j].value
                  ) {
                    vm.constructionSettings.types[j].active =
                      vm.currentSelectedLab.currentJobs[i].active;
                  }
                }
              }
            } else {
              vm.constructionSettings.types.forEach(function (type) {
                if (type.value !== 'other') {
                  type.active = false;
                }
              });
            }
            vm.showSpinner = false;
            eventService.emit('settingTypesFetched', vm.constructionSettings.types);
          })
          .catch(function (err) {
            logService.error(
              'order-fixed-constructions.component',
              '$watch',
              $filter('translate')('ERROR_IN_GETTING_LAB') + ': ' + err
            );
            toastr.error($filter('translate')('ERROR_IN_GETTING_LAB'));
          });
      }
    });

    $scope.$watch(
      'vm.commentsArray',
      function () {
        // check if there is array of fixed constructions
        if (vm.commentsArray && vm.commentsArray.length) {
          vm.singleCrownCommentsArray = vm.commentsArray.filter(function (comment) {
            return (
              comment.constructionType === 'singleCrowns' ||
              comment.constructionType === 'implantCrowns'
            );
          });
          vm.bridgeCommentsArray = vm.commentsArray.filter(function (comment) {
            return (
              comment.constructionType === 'bridges' ||
              comment.constructionType === 'implantBridges'
            );
          });
          vm.postCommentsArray = vm.commentsArray.filter(function (comment) {
            return comment.constructionType === 'posts';
          });
          vm.otherCommentsArray = vm.commentsArray.filter(function (comment) {
            return comment.constructionType === 'others';
          });
        }
      },
      true
    );
  }
})();
