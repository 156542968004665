import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../models/user.model';
import { Organization } from '../../models/organization.model';
import template from './clinic-filter.html';
import { SessionService } from '../../services/core/session.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { sortBy } from 'lodash';

@Component({
  selector: 'clinic-filter',
  template: template
})
export class ClinicFilterComponent implements OnInit {
  @Input() key: string;
  @Input() currentUser: User;
  @Output() onFilterUpdate: EventEmitter<any> = new EventEmitter<any>();
  clinics: Organization[];

  selectedClinicIds: string[] = [];
  storeClinicsIds: string[] = [];

  constructor(
    private sessionService: SessionService,
    private organizationService: OrganizationService,
    private logService: LogService,
    private translationsService: TranslationsService,
    private toastr: ToastrService
  ) {}

  callFilterFunction(): void {
    this.onFilterUpdate.emit({
      selectedClinics: this.selectedClinicIds
    });
  }

  get(): void {
    this.selectedClinicIds = this.clinics
      .filter((clinic) => clinic.selected)
      .map((clinic) => clinic._id);
    this.callFilterFunction();
  }

  ngOnInit(): void {
    this.getClinics();
  }

  // TODO: Rename this method. It is not a getter.
  getClinics(): void {
    this.organizationService
      .getAllOrganizations({ type: 'clinic', active: true })
      .then((result) => {
        const clinics = result.data.filter((clinic) => !clinic.parentClinicId);
        this.clinics = sortBy(clinics, 'name');
        this.storeClinicsIds = JSON.parse(
          this.sessionService.getItem(this.key)
        );
        if (
          this.storeClinicsIds &&
          this.storeClinicsIds.length > 0 &&
          this.clinics &&
          this.clinics.length > 0
        ) {
          this.clinics
            .filter((clinic) => this.storeClinicsIds.indexOf(clinic._id) > -1)
            .forEach((clinic) => {
              clinic.selected = true;
            });
        }
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_GETTING_CLINICS'
        );
        this.logService.error(
          'clinic-filter.component',
          'getClinics',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  getClinicsName(): string {
    if (!this.clinics) {
      return '';
    }

    let filteredData = this.clinics
      .filter((clinic) => clinic.selected)
      .map((clinic) => clinic.name);
    // for sorting selected clinic by name
    filteredData = sortBy(filteredData, [
      (clinicName) => clinicName.toLowerCase() || ''
    ]);
    return filteredData.join(',');
  }
}
