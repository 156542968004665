/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-delivery.html'
(function () {
    'use strict';
    angular.module('app').component('orderNewDelivery', {
        template: theTemplate,
        controllerAs: 'vm',
        bindings: angular.extend({}, sessionStateBindings, {
            deliveryInfo: '=',
            newOrderObj: '=',
            selectedLab: '=',
            showExportOrderCheckBox: '=',
            additionalInfo: '=',
            orderIdToModify: '='
        }),
        controller: orderNewDeliveryController
    });
    orderNewDeliveryController.$inject = ['$scope', 'organizationService', '$filter', 'DATE',
        'tokenService', '$state', 'sessionService', 'orderNewDeliveryService',
        'logService', 'toastr', 'additionalInfoService', 'permissionService', 'CUSTOMER_SETTINGS_CONSTANTS', 'organizationSettingService'];

    function orderNewDeliveryController($scope, organizationService, $filter, DATE,
        tokenService, $state, sessionService, orderNewDeliveryService,
        logService, toastr, additionalInfoService, permissionService, CUSTOMER_SETTINGS_CONSTANTS, organizationSettingService) {
        var vm = this;
        vm.currentDate = new Date();
        vm.defaultDateType = DATE.FORMAT;
        vm.minDate = moment().subtract(1, 'days').format("YYYY-MM-DD");
        vm.perfectDeliveryDate = new Date();
        vm.disabledDays = [];
        vm.myDataFromBackend = false;
        vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;
        vm.isInfoChange = true;
        vm.isDateChangedManually = false;
        vm.sampleMethods = CUSTOMER_SETTINGS_CONSTANTS.SAMPLE_METHODS;
        vm.sampleMethodSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_METHOD;
        vm.sampleMethod = organizationSettingService.getCustomerSetting(vm.sampleMethodSettingName);
        vm.sampleIsMandatory = true;

        /**
         * Method to load clinic
         */
        function getClinics() {
            organizationService.getDentistClinics({ id: vm.currentUser.organization._id }).then(function (result) {
                vm.clinics = result.data;
                var clinic, pickUpLocation;

                if (!vm.currentUser.systemSetting.clinic) {
                    clinic = vm.clinics[0]._id;
                    pickUpLocation = vm.clinics[0]._id;
                } else {
                    clinic = vm.currentUser.systemSetting.clinic;
                    pickUpLocation = vm.currentUser.systemSetting.clinic;
                }

                var defaultLocationClinic = vm.clinics.find(function (orgClinic) {
                    return orgClinic._id === clinic;
                });

                //set logged in user clinic as default clinic if default clinic not set by user
                if (vm.deliveryInfo && !vm.deliveryInfo.clinic && vm.currentUser && clinic && !defaultLocationClinic.isNotPickUpAndDeliveryPlace) {
                    vm.deliveryInfo.clinic = clinic;
                    vm.deliveryInfo.pickUpLocation = pickUpLocation;
                    vm.deliveryInfo.date = $filter('date')(vm.currentDate, DATE.FORMAT);

                }
                getOrderAdditionalInfo();
            }).catch(function (err) {
                logService.error('order-new-delivery.component', 'getClinics', $filter('translate')('ERROR_IN_GETTING_CLINICS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_CLINICS'));
            });
        }

        vm.$onInit = function () {
            vm.isLabUser = permissionService.isLabTechnician(vm.currentUser);
            if (!vm.isLabUser) {
                getClinics();
            }
        };

        // Method to get current lab details for the delivery info
        $scope.$watch('vm.newOrderObj', function () {
            if (vm.newOrderObj && vm.newOrderObj.selectedLab) {
                vm.currentSelectedLab = vm.newOrderObj.labData;
            }
            if (vm.isLabUser && vm.newOrderObj && vm.newOrderObj.clinics) {
                vm.clinics = vm.newOrderObj.clinics;
                vm.deliveryInfo.clinic = vm.newOrderObj.deliveryInfo.clinic;
                vm.deliveryInfo.pickUpLocation = vm.newOrderObj.deliveryInfo.pickUpLocation;
            }
        }, true);

        function updatePerfectDeliveryDate() {
            if (vm.currentSelectedLab) {
                var additionalDeliveryDate = 0;
                if (vm.deliveryInfo.exportOrder) {
                    additionalDeliveryDate = vm.currentSelectedLab.export.additionalDeliveryDays;
                }
                orderNewDeliveryService.updatePerfectDeliveryDate(vm.currentSelectedLab, vm.newOrderObj, vm.deliveryInfo, additionalDeliveryDate).then(function (result) {
                    if (result) {
                        vm.newOrderObj = result.newOrderObj;
                        vm.deliveryInfo = result.deliveryInfo;
                        vm.perfectDeliveryDate = result.perfectDeliveryDate;
                        if (vm.additionalInfo && vm.additionalInfo.length) {
                            vm.deliveryInfo.information = '';
                        }
                    }
                }).catch(function (err) {
                    logService.error('order-new-delivery.component', '$watch-vm.currentSelectedLab', $filter('translate')('ERROR_IN_GETTING_LAB') + ": " + err);
                    toastr.error($filter('translate')('ERROR_IN_GETTING_LAB'));
                });

                if (vm.currentSelectedLab.holidayDays.length) {
                    vm.disabledDays = [];
                    for (var day in vm.currentSelectedLab.holidayDays) {
                        vm.disabledDays.push(new Date(vm.currentSelectedLab.holidayDays[day]));
                    }
                    if (vm.currentSelectedLab.unavailableLabDates.length) {
                        for (var unavailableDay in vm.currentSelectedLab.unavailableLabDates) {
                            vm.disabledDays.push(new Date(vm.currentSelectedLab.unavailableLabDates[unavailableDay]));
                        }
                    }
                    vm.myDataFromBackend = true;
                }

            }
        }

        // To match the delivery date is normal or early according to constructions
        $scope.$watch('vm.newOrderObj.selectedConstructions', function (newVal, oldVal) {
            // check if there is new construction then call it
            if (newVal && oldVal) {
                updatePerfectDeliveryDate();
            }
        }, true);

        // for Modify order watch current selected lab to get the perfect delivery date
        $scope.$watch('vm.currentSelectedLab', function (newVal, oldVal) {
            // check if there is change in new lab

            //default selection of "Pick up requested?" if available for that clinic
            vm.client = vm.currentSelectedLab && vm.currentSelectedLab.clientSettings && vm.currentSelectedLab.clientSettings.find((clientSetting) => clientSetting.clinicId === vm.deliveryInfo.pickUpLocation);
            if (vm.client && vm.client.transportProvider && vm.client.transportProvider.channel) {
                vm.deliveryInfo.isPickUpAtClinic = true;
            } else {
                vm.deliveryInfo.isPickUpAtClinic = false;
            }
            if (newVal) {
                if (vm.orderIdToModify) {
                    //saved DB value selection of "Pick up requested?"
                    vm.deliveryInfo.isPickUpAtClinic = vm.newOrderObj.savedPickUpAtClinicsValue;
                }
            }
            if (newVal && oldVal) {
                updatePerfectDeliveryDate();
            }
        }, true);

        // Watch delivery date to check the date which is perfect delivery date or not
        $scope.$watch('vm.newOrderObj.deliveryInfo.date', function () {
            if (vm.newOrderObj && vm.newOrderObj.deliveryInfo) {
                var selectedDate = new Date(vm.newOrderObj.deliveryInfo.date);
                selectedDate = selectedDate.getTime();
                vm.perfectDeliveryDate = new Date(vm.perfectDeliveryDate);
                vm.perfectDeliveryDate = vm.perfectDeliveryDate.setHours(0, 0, 0, 0);
                if (selectedDate < vm.perfectDeliveryDate) {
                    vm.deliveryInfo.status = 'early_delivery';
                } else {
                    vm.deliveryInfo.status = '';
                }
            }
        }, true);

        $scope.$watch('vm.deliveryInfo.exportOrder', function (newVal, oldVal) {
            // if there is a change in export order checkbox
            if (typeof newVal === 'boolean' && typeof oldVal === 'boolean') {
                updatePerfectDeliveryDate();
            }
        }, true);

        // function to get the additional info of the current order
        function getOrderAdditionalInfo() {
            if (vm.newOrderObj) {
                vm.additionalInfo = additionalInfoService.getAdditionalInfo(vm.newOrderObj);
            }
        }

        $scope.$watch('vm.newOrderObj.sample.type', function (newVal, oldVal) {
            //Deselect selection of "Pick up requested?" if sample type is DIGITAL Or NO_SAMPLE
            vm.client = vm.currentSelectedLab && vm.currentSelectedLab.clientSettings && vm.currentSelectedLab.clientSettings.find((clientSetting) => clientSetting.clinicId === vm.deliveryInfo.pickUpLocation);
            if (vm.client && vm.client.transportProvider && vm.client.transportProvider.channel) {
                if (vm.newOrderObj.sample.type === 'DIGITAL') {
                    vm.deliveryInfo.isPickUpAtClinic = false;
                } else if (vm.newOrderObj.sample.type === 'NO_SAMPLE') {
                    vm.deliveryInfo.isPickUpAtClinic = false;
                } else if (vm.newOrderObj.sample.type === 'PHYSICAL') {
                    vm.deliveryInfo.isPickUpAtClinic = true;
                }
            } else {
                vm.deliveryInfo.isPickUpAtClinic = false;
            }
        }, true);

        function infoChange() {
            if (vm.isInfoChange && vm.deliveryInfo && vm.deliveryInfo.information && vm.additionalInfo.length) {
                vm.isInfoChange = false;
                vm.deliveryInfo.information = '';
            }
        }

        $scope.$watch('vm.deliveryInfo.information', function (newVal, oldVal) {
            // if there is a change in information
            if (newVal) {
                infoChange();
            }
        }, true);

        $scope.$watch('vm.deliveryInfo.pickUpLocation', function (newVal, oldVal) {
            //default selection of "Pick up requested?" if available for that clinic
            vm.client = vm.currentSelectedLab && vm.currentSelectedLab.clientSettings && vm.currentSelectedLab.clientSettings.find((clientSetting) => clientSetting.clinicId === vm.deliveryInfo.pickUpLocation);
            if (vm.client && vm.client.transportProvider && vm.client.transportProvider.channel) {
                vm.deliveryInfo.isPickUpAtClinic = true;
            } else {
                vm.deliveryInfo.isPickUpAtClinic = false;
            }
        }, true);

    }
})();