import { Component, Input } from '@angular/core';
import { CustomerSetting } from '../../../models/customer-setting.model';
import template from './text-area.html';

@Component({
  selector: 'text-area',
  template: template
})
export class TextAreaComponent {
  @Input() setting: CustomerSetting;
}
