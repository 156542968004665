import { Component, Input, OnInit } from '@angular/core';
import template from './user-card.html';
import { User } from '../../models/user.model';
import { TranslationsService } from '../../services/translations/translations.service';
import { PermissionService } from '../../services/core/permission.service';
import { UserRoleService } from '../../services/roles/user-role.service';
import { GetAllUsersService } from '../../services/order-list/get-all-users.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../services/users/users.service';
import { SessionService } from '../../services/core/session.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { Role } from '../../models/roles.model';
import { COMPONENTS } from '../../app.constant';

@Component({
  selector: 'users-card',
  template: template
})
export class UsersCardComponent implements OnInit {
  // TODO: initialize this
  @Input() currentUser: User;
  isSuperAdmin: boolean = false;
  users: User[] = [];
  selectedUser: any = {};
  busy: boolean = false;
  pageNo: number = 0;
  searchText: string = '';
  roles: Role[] = [];
  selectedClinicsIds: string[] = [];

  constructor(
    private translationsService: TranslationsService,
    private permissionService: PermissionService,
    private userRoleService: UserRoleService,
    private logService: LogService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    private sessionService: SessionService,
    private userService: UsersService,
    private subNavService: SubNavService,
    private getAllUsersService: GetAllUsersService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinnerService.show();
    this.subNavService.addMenu({
      name: await this.translationsService.get('LIST_VIEW'),
      state: 'app.users',
      icon: 'fa-list-ul',
      isActive: false,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('CARD_VIEW'),
      state: 'app.users-card',
      icon: 'fa-th',
      isActive: true,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('ADD_USER'),
      icon: 'fa-plus',
      action: 'add-user-modal',
      isModel: true,
      isClass: true
    });

    this.isSuperAdmin = this.permissionService.isSuperAdmin(this.currentUser);
    this.setUserRolesExcept('TECHNICIAN');
  }

  searchTextChanged(value: string): void {
    this.searchText = value;
    // clear data when user tries to search through the list
    this.getAllUsersService.clearUsersData();
    this.getUsers(true);
  }

  setUserRolesExcept(roleType: string): void {
    this.userRoleService
      .getRolesExcept(roleType)
      .then((roles) => {
        this.roles = roles;
        this.getUsers();
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_GETTING_ROLES'
        );
        this.logService.error(
          COMPONENTS.USER_CARD,
          'setUserRolesExcept',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  onUserChange(): void {
    this.users = [];
    this.getAllUsersService.clearUsersData(); // clear users data in service
    this.getUsers();
  }

  getUsers(refresh?: boolean): void {
    if (this.isSuperAdmin) {
      this.getAllUsersForSuperAdmin(refresh);
    } else {
      this.updateUsers();
    }
  }

  onUserUpdate(user: User): void {
    // after user get updated from edit form
    this.selectedUser = user;
    $('#add-user-modal').modal('show');
  }

  onUserRemoved(index: number): void {
    if (index > -1) {
      this.users.splice(index, 1);
    }
  }

  onFilterUpdate(selectedClinicsIds: string[]): void {
    this.selectedClinicsIds = selectedClinicsIds;
    if (this.selectedClinicsIds) {
      this.sessionService.setItem(
        'selectedParentClinicsIds',
        JSON.stringify(this.selectedClinicsIds)
      );
    }
    this.spinnerService.show();
    this.busy = false;
    this.pageNo = 0;
    this.getUsers(true);
  }

  getAllUsersForSuperAdmin(refresh: boolean): void {
    const clinicIds = JSON.parse(
      this.sessionService.getItem('selectedParentClinicsIds')
    );
    if (clinicIds && clinicIds.length > 0) {
      this.selectedClinicsIds = clinicIds;
    }
    if (!this.getAllUsersService.getUserData() || refresh) {
      if (this.currentUser) {
        this.userService
          .getAllUsersForSuperAdmin({
            type: 'dentist',
            pageNo: 0,
            filter: this.searchText,
            clinicIds: this.selectedClinicsIds
          })
          .then((result) => {
            this.users = result.data;
            this.getAllUsersService.getAllUsers(result.data, 0); // set data in service
            this.spinnerService.hide();
          })
          .catch(async (err: any) => {
            const text = await this.translationsService.get(
              'ERROR_IN_GETTING_USERS'
            );
            this.logService.error(
              COMPONENTS.USER_CARD,
              'getAllUsersForSuperAdmin',
              text + ': ' + err
            );
            this.toastr.error(text);
          });
      }
    } else {
      if (this.currentUser) {
        // using timeout(0) for huge data
        setTimeout(() => {
          this.users = this.getAllUsersService.getUserData().users; // get users data from service;
          this.spinnerService.hide();
        }, 0);
      }
    }
  }

  updateUsers(): void {
    if (!this.getAllUsersService.getUserData()) {
      if (this.currentUser) {
        this.userService
          .getAllUsers({
            organizationId: this.currentUser.organization._id,
            pageNo: 0,
            filter: this.searchText
          })
          .then((result) => {
            if (result.data) {
              this.users = result.data.users;
              this.getAllUsersService.getAllUsers(result.data.users, 0); // set data in service
            }
            this.spinnerService.hide();
          })
          .catch(async (err: any) => {
            const text = await this.translationsService.get(
              'ERROR_IN_GETTING_USERS'
            );
            this.logService.error(
              COMPONENTS.USER_CARD,
              'updateUsers',
              text + ': ' + err
            );
            this.toastr.error(text);
          });
      }
    } else {
      if (this.currentUser) {
        // using timeout(0) for huge data
        setTimeout(() => {
          this.users = this.getAllUsersService.getUserData().users; // get users data from service;
          this.spinnerService.hide();
        }, 0);
      }
    }
  }

  closeModal(): void {
    $('#add-user-modal').modal('hide');
    this.selectedUser = {
      name: {},
      systemSetting: {},
      organization: {}
    };
  }

  pagination(): void {
    if (this.busy || this.pageNo <= 0) {
      return;
    }
    this.busy = true;
    const clinicIds = JSON.parse(
      this.sessionService.getItem('selectedParentClinicsIds')
    );
    if (clinicIds && clinicIds.length > 0) {
      this.selectedClinicsIds = clinicIds;
    }
    this.spinnerService.show();
    if (this.isSuperAdmin && this.currentUser) {
      this.userService
        .getAllUsersForSuperAdmin({
          type: 'dentist',
          pageNo: this.pageNo,
          filter: this.searchText,
          clinicIds: this.selectedClinicsIds
        })
        .then((result) => {
          this.busy = result.data.length <= 0;
          this.users = this.users.concat(result.data);
          this.spinnerService.hide();
          this.pageNo += 1;
          this.getAllUsersService.getAllUsers(this.users, this.pageNo); // set data in service
        })
        .catch(async (err: any) => {
          const text = await this.translationsService.get(
            'ERROR_IN_GETTING_USERS'
          );
          this.logService.error(
            COMPONENTS.USER_CARD,
            'pagination if-part',
            text + ': ' + err
          );
          this.toastr.error(text);
        });
    } else {
      if (this.currentUser) {
        this.userService
          .getAllUsers({
            organizationId: this.currentUser.organization._id,
            type: 'dentist',
            filter: this.searchText,
            pageNo: this.pageNo
          })
          .then((result) => {
            this.busy = result.data.users.length <= 0;
            this.users = this.users.concat(result.data.users);
            this.spinnerService.hide();
            this.pageNo += 1;
            this.getAllUsersService.getAllUsers(this.users, this.pageNo); // set data in service
          })
          .catch(async (err: any) => {
            const text = await this.translationsService.get(
              'ERROR_IN_GETTING_USERS'
            );
            this.logService.error(
              COMPONENTS.USER_CARD,
              'pagination else-part',
              text + ': ' + err
            );
            this.toastr.error(text);
          });
      }
    }
  }
}
