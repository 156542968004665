import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.constant';
import { Organization } from '../../models/organization.model';
import { CustomerSetting } from '../../models/customer-setting.model';
import { Injectable } from '@angular/core';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { isUndefined } from 'lodash';
import { Action, PriceList } from '../../models/action.model';
import { Status } from '../../models/status.model';

interface Actions {
  action: string;
  price: number;
  deliveryTime: string;
  enable: boolean;
}

@Injectable()
export class OrganizationService extends ResourceService {
  private readonly orgIdPath = 'organization/:id';

  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
  }

  getAllLabs(): Promise<{ data: Organization[] }> {
    return this.get(`${API.API_BASE}labs`, undefined, this.apiInterceptor);
  }

  getCurrentLab(): Promise<{ data: Organization }> {
    return this.get(
      `${API.API_BASE}organizations/currentlab`,
      undefined,
      this.apiInterceptor
    );
  }

  getAllOrganizations(data: {
    clinicIds?: string[];
    active?: boolean;
    type: string;
  }): Promise<{ data: Organization[] }> {
    let params = new HttpParams().set('type', data.type);

    if (!isUndefined(data.active)) {
      params = params.append('active', data.active + '');
    }
    if (data.clinicIds && data.clinicIds.length) {
      data.clinicIds.forEach(
        (clinicId) => (params = params.append('clinicIds[]', clinicId))
      );
    }
    return this.get(`${API.API_BASE}organizations`, params, this.apiInterceptor);
  }

  getOrganization(data: { id: string }): Promise<{ data: Organization }> {
    const params = new HttpParams().set('id', data.id);
    return this.get(API.API_BASE + this.orgIdPath, params, this.apiInterceptor);
  }

  getOrganizationByExtId(data: {
    extidnum: number;
    extid: string;
  }): Promise<{ data: Organization }> {
    const params = new HttpParams()
      .set('extidnum', data.extidnum + '')
      .set('extid', data.extid);
    return this.get(
      `${API.API_BASE}organization/extid/:extidnum/:extid`,
      params,
      this.apiInterceptor
    );
  }

  getDentistClinic(data: {
    id: string;
    clinicId: string;
  }): Promise<{ data: Organization }> {
    const params = new HttpParams()
      .set('id', data.id)
      .set('clinicId', data.clinicId);
    return this.get(
      `${API.API_V2_BASE}organizations/:id/clinics/:clinicId`,
      params,
      this.apiInterceptor
    );
  }

  getDentistClinics(data: { id: string }): Promise<{ data: Organization[] }> {
    const params = new HttpParams().set('id', data.id);
    return this.get(
      `${API.API_V2_BASE}organizations/:id/clinics`,
      params,
      this.apiInterceptor
    );
  }

  getRating(data: { labId: string }): Promise<{ rating: number }> {
    const params = new HttpParams().set('labId', data.labId);
    return this.get(`${API.API_BASE}lab-rating/:labId`, params, this.apiInterceptor);
  }

  getCustomerSettings(): Promise<{ data: CustomerSetting[] }> {
    return this.get(`${API.API_BASE}settings`, undefined, this.apiInterceptor);
  }

  getConnectedLabs(): Promise<{ data: Organization[] }> {
    return this.get(
      `${API.API_BASE}user/connected-labs`,
      undefined,
      this.apiInterceptor
    );
  }

  connectToLabs(): Promise<{ data: Organization[] }> {
    return this.get(
      `${API.API_BASE}connect-to-labs`,
      undefined,
      this.apiInterceptor
    );
  }

  searchLabs(data: { query: string }): Promise<{ data: Organization[] }> {
    const params = new HttpParams().set('query', data.query);

    return this.get(`${API.API_BASE}labs/:query`, params, this.apiInterceptor);
  }

  getConnectedLabsForClinic(data: {
    organizationId: string;
  }): Promise<{ data: Organization }> {
    const params = new HttpParams().set('organizationId', data.organizationId);

    return this.get(
      `${API.API_V2_BASE}organizations/:organizationId/connected-labs`,
      params,
      this.apiInterceptor
    );
  }

  getConnectedParentClinics(data: {
    organizationId: string;
  }): Promise<{ data: Organization[] }> {
    const params = new HttpParams().set('organizationId', data.organizationId);

    return this.get(
      `${API.API_V2_BASE}organizations/:organizationId/connected-parent-clinics`,
      params,
      this.apiInterceptor
    );
  }

  searchSelectedLabs(data: { labs: string[] }): Promise<{ data: Organization[] }> {
    const params = new HttpParams().set('labs', data.labs.join(','));

    return this.get(`${API.API_BASE}search-labs/:labs`, params, this.apiInterceptor);
  }

  getPriceList(data: {
    labId: string;
    categoryId: string;
  }): Promise<{ data: Action[] }> {
    const params = new HttpParams()
      .set('labId', data.labId)
      .set('categoryId', data.categoryId);

    return this.get(
      `${API.API_BASE}labs/price-list/:labId/:categoryId`,
      params,
      this.apiInterceptor
    );
  }

  connectMultipleLabs(labs: { lab: string }[]): Promise<Status> {
    return this.put(`${API.API_BASE}labs`, labs, undefined, this.apiInterceptor);
  }

  removePriceList(data: {
    labId: string;
    categoryId: string;
  }): Promise<{ data: Action[] }> {
    const params = new HttpParams()
      .set('labId', data.labId)
      .set('categoryId', data.categoryId);

    return this.put(
      `${API.API_BASE}labs/price-list/:labId/:categoryId`,
      undefined,
      params,
      this.apiInterceptor
    );
  }

  addPriceListToLab(
    id: string,
    priceList: { priceList: PriceList }
  ): Promise<{ data: Status }> {
    const params = new HttpParams().set('id', id);

    return this.put(
      `${API.API_BASE}labs/:id`,
      priceList,
      params,
      this.apiInterceptor
    );
  }

  // TODO: The organization object has mandatory properties, but this update method should not require any
  updateOrganization(
    id: string,
    organization: Partial<Organization>
  ): Promise<Status> {
    const params = new HttpParams().set('id', id);

    return this.put(
      API.API_BASE + this.orgIdPath,
      organization,
      params,
      this.apiInterceptor
    );
  }

  disconnectLab(data: { labId: string }): Promise<Status> {
    const params = new HttpParams().set('labId', data.labId);

    return this.put(
      `${API.API_BASE}user/connect-labs/:labId`,
      undefined,
      params,
      this.apiInterceptor
    );
  }

  updateOrganizationSettings(lab: Organization): Promise<Status> {
    return this.put(
      `${API.API_BASE}organization-setting`,
      lab,
      undefined,
      this.apiInterceptor
    );
  }

  uploadOrganizationFile(fileObj: {
    _id: string;
    logoImage?: string;
    staffImage?: string;
    fileType: string;
  }): Promise<Status> {
    return this.put(
      `${API.API_BASE}organization-file`,
      fileObj,
      undefined,
      this.apiInterceptor
    );
  }

  saveOrganization(clinic: Organization): Promise<Status> {
    return this.post(
      `${API.API_BASE}organizations`,
      clinic,
      undefined,
      this.apiInterceptor
    );
  }

  inviteLab(lab: Organization): Promise<Status> {
    return this.post(
      `${API.API_BASE}invite/lab`,
      lab,
      undefined,
      this.apiInterceptor
    );
  }

  connectLab(data: { labId: string }): Promise<Status> {
    const params = new HttpParams().set('labId', data.labId);
    return this.post(
      `${API.API_BASE}user/connect-labs/:labId`,
      undefined,
      params,
      this.apiInterceptor
    );
  }

  removeOrganization(data: { id: string }): Promise<Status> {
    const params = new HttpParams().set('id', data.id);
    return this.delete(API.API_BASE + this.orgIdPath, params, this.apiInterceptor);
  }

  savePickUpClinics(pickUpClinics: Organization): Promise<Status> {
    return this.put(
      `${API.API_V2_BASE}lab/pick-up-clinics`,
      pickUpClinics,
      undefined,
      this.apiInterceptor
    );
  }

  getLabLogo(data: {
    labId: string;
  }): Promise<{ data: Organization }> {
    const params = new HttpParams().set('labId', data.labId);

    return this.get(
      `${API.API_V2_BASE}limited-dentist/lab/:labId/logoImage`,
      params,
      this.apiInterceptor
    );
  }
}
