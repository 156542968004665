import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { User } from '../../models/user.model';
import { TranslationsService } from '../../services/translations/translations.service';
import { CacheService } from '../../services/core/cache.service';
import { LogService } from '../../services/core/log.service';
import { OrganizationSettingService } from '../../services/organization-setting/organization-setting.service';
import { CONSTANT } from '../../app.constant';
import { Language } from '../../models/language.model';
import { Organization } from '../../models/organization.model';

import style from './system-setting-lab.less';
import template from './system-setting-lab.html';

@Component({
  selector: 'system-setting-lab',
  template: template,
  styles: [style]
})
export class SystemSettingLabComponent implements OnInit {
  @Input() userProfile: User;
  language: Language;
  languages: Language[] = CONSTANT.LANGUAGES;
  locales: string[] = CONSTANT.LOCALES;
  locale: string;
  currentDate: number = new Date().getTime();

  constructor(
    private toastr: ToastrService,
    private logService: LogService,
    public cacheService: CacheService,
    private translationsService: TranslationsService
  ) {}

  getUserLanguage(): void {
    const self = this;
    self.language = self.languages.find((language) => {
      return (
        self.userProfile.systemSetting &&
        self.userProfile.systemSetting.language &&
        language.value === self.userProfile.systemSetting.language
      );
    });

    if (self.userProfile && !self.language) {
      self.language = self.languages[0];
    }
  }

  languageSelected(): void {
    if (this.language) {
      if (!this.userProfile.systemSetting) {
        this.userProfile.systemSetting = {};
      }
      this.userProfile.systemSetting.language = this.language.value;
    }
  }

  async ngOnInit(): Promise<any> {
    this.getUserLanguage();
  }
}
