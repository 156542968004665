import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'materialFilter'
})
export class MaterialFilterPipe implements PipeTransform {
  transform(materials: any, search: string) {
    if (!search) {
      return materials;
    }
    return materials.filter((material) => {
      const regex = new RegExp(search, 'i');
      return (
        regex.test(material.code) ||
        regex.test(material.description) ||
        regex.test(material.batchNumber)
      );
    });
  }
}
