import { Component, Input, forwardRef, Inject, OnInit, OnChanges } from '@angular/core';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './warranty.html';

@Component({
  selector: 'warranty',
  template
})
export class WarrantyComponent implements OnInit, OnChanges{
  @Input() warranty: boolean;
  @Input() warrantyType: string;
  isResend = false;
  private readonly warrantyKey = 'warranty';
  private readonly warrantyTypeKey = 'warrantyType';

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService,
    @Inject(forwardRef(() => '$stateParams')) private readonly $stateParams: any
  ) {}

  ngOnInit() {
    if (this.$stateParams && this.$stateParams.isResend) {
      this.isResend = Boolean(JSON.parse(this.$stateParams.isResend));
    }
  }

  ngOnChanges() {
    if (!this.warranty) {
      this.warranty = undefined;
    }
  }

  warrantyChanged(isChecked: boolean) {
    if (isChecked) {
      this.warranty = false;
      $('#warranty-type-modal').modal('show');
    } else {
      this.warranty = false;
      this.warrantyType = 'NONE';
      this.constructionSettingsService.setBooleanSetting(this.warrantyKey, false);
      this.constructionSettingsService.setSetting(this.warrantyTypeKey, 'NONE');
    }
  }

  onWarrantyTypeSelection(warrantyType: string) {
    this.warranty = true;
    this.warrantyType = warrantyType;
    this.constructionSettingsService.setBooleanSetting(this.warrantyKey, true);
    this.constructionSettingsService.setSetting(this.warrantyTypeKey, warrantyType);
  }

  onCancel() {
    this.warranty = undefined;
    this.warrantyType = undefined;
  }
}
