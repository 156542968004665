import { Component, Inject, forwardRef, Input } from '@angular/core';
import * as angular from 'angular';
import { User } from '../../models/user.model';
import { PermissionService } from '../../services/core/permission.service';
import template from './settings.html';

@Component({
  selector: 'settings',
  template: template
})
export class SettingsComponent {
  @Input() currentUser: User;

  constructor(public permissionService: PermissionService) {}
}
