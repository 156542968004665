import { Component, Input, OnInit, Inject, forwardRef, Output, EventEmitter } from '@angular/core';
import template from './order-list-item.html';
import * as moment from 'moment';
import { Order } from '../../models/order.model';
import { User } from '../../models/user.model';
import { PermissionService } from '../../services/core/permission.service';
import { StatusService } from '../../services/status/status.service';
import { CUSTOMER_SETTINGS_CONSTANTS } from "../../app.constant";
import { cloneDeep } from 'lodash';

@Component({
  selector: 'order-list-item',
  template: template
})
export class OrderListItemComponent implements OnInit {
  @Output() onToggleLabHandleRequest: EventEmitter<{
    order: Order;
    isLabHandleRequest: boolean;
  }> = new EventEmitter<{ order: Order; isLabHandleRequest: boolean }>();
  @Input() order: Order;
  @Input() currentUser: User;

  constructor(
    public permissionService: PermissionService,
    public statusService: StatusService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  ngOnInit(): void {
    if (this.order && this.order.ratings && this.order.ratings.overAll) {
      this.order.ratings.overAll = Math.round(this.order.ratings.overAll);
    }
  }

  showStatus() {
    return this.statusService.getStatus(this.order, this.currentUser);
  }

  // TODO: Please rename to "should..." since this returns a boolean
  showBackgroundColor(): boolean {
    const yesterdayDate = moment().subtract(1, 'days').toDate().getTime();
    return yesterdayDate > this.order.deliveryDetails.date
      ? this.currentUser.viewSettings.deliveryDatePassed
      : false;
  }

  goToOrderDetail(orderId: string): void {
    this.$state.go(CUSTOMER_SETTINGS_CONSTANTS.ORDER_DETAIL_STATES.ORDER_DETAIL2, {
      orderId
    });
  }

  isDentist() {
    return this.permissionService.isDentist(this.currentUser);
  }

  isLabTechnician() {
    return this.permissionService.isLabTechnician(this.currentUser);
  }

  toggleLabHandleRequest(order, isLabHandleRequest) {
    this.onToggleLabHandleRequest.emit({ order: cloneDeep(order), isLabHandleRequest: isLabHandleRequest });
  }

}
