export const translationAccountSwedish = {
  WHITE_LABEL: 'White label',
  WHITE_LABEL_DESCRIPTION: 'On/Off the lab white label feature',
  HEX_CODE: 'Färgkod (hex)',
  LIMITED_DENTIST_ACCOUNT_CREATED: 'Ditt nya konto har registrerats',
  LAB_ID_MISSING: 'Lab id saknas i URL:n',
  WHITE_LABEL_ADD_DENTIST_LINK: 'White label, länk till registreringsformulär',
  MEDIA_DESIGN: 'Media och design',
  LOGIN_HERE: 'Har du redan ett konto? Logga in här.',
  CREATE_ACCOUNT: 'Saknar du konto? Registrera dig här.',
  USER_ALREADY_EXIST:
    'En användare med denna e-post finns redan, prova att logga in eller kontakta support@denthub.com',
  ORGANIZATION_ALREADY_EXIST:
    'En klinik med samma organisationsnummer finns redan, prova att logga in eller kontakta support@denthub.com',
  INVALID_ORGANIZATION_NUMBER: 'Ange ett giltigt organisationsnummer',
  REGISTER_NEW_ACCOUNT: 'Registrera nytt konto',
  WHITELABEL_USER: 'Användare',
  USER_SECTION_DESC:
    'Använd en personliga e-post då denna används vid inloggning. Om ni är flera på kliniken behöver ni endast registera er en gång via detta formulär. Din användare blir administratör och du kommer själv kunna skapa fler användare vid behov.',
  CLINIC_SECTION_DESC: 'Ange kontaktuppgifter för din klinik.',
  CLINIC_NAME: 'Klinikens namn',
  DONE: 'Klar!',
  HOW_INVOICE: 'Hur vill du bli fakturerad?',
  INVOICE_SAME_ADDRESS: 'Samma adress',
  INVOICE_DIFFERENT_ADDRESS: 'Annan address',
  INVOICE_EMAIL: 'Via epost'
};
