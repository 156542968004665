import template from './transport-order-wizard.html';
import { Component, Input, forwardRef, Inject } from '@angular/core';
import { Organization } from '../../../../models/organization.model';
import { TransportAppService } from '../../services/transport-app.service';
import { Logistic } from '../../../../models/logistic.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../../../models/user.model';
import { StateService } from '@uirouter/angularjs';
import { Moment } from 'moment';
import { LogService } from '../../../../services/core/log.service';
import { TranslationsService } from '../../../../services/translations/translations.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ExistingTransportDialogComponent,
  ExistingTransportDialogData
} from './existing-transport-dialog/existing-transport-dialog.component';

@Component({
  selector: 'transport-order-wizard',
  template: template
})
export class TransportOrderWizardComponent {
  @Input() currentUser: User;
  lab: Organization;
  transportClinics: Organization[];
  selectedClinic: Organization;
  selectedDate: Moment;
  transportOrder: Partial<Logistic>;
  transportType: string;
  isPlaceOrderDisabled = false;

  constructor(
    public dialog: MatDialog,
    private readonly transportAppService: TransportAppService,
    private readonly snackBar: MatSnackBar,
    private readonly logService: LogService,
    private readonly translationsService: TranslationsService,
    @Inject(forwardRef(() => '$state')) private readonly $state: StateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.transportType = this.$state.params.transportType.toUpperCase();
    if (this.currentUser?.organization?.type === 'lab') {
      this.lab = this.currentUser.organization;
      this.transportOrder = this.transportAppService.createEmptyTransportOrder();
      this.transportClinics = await this.transportAppService.loadTransportClinics();
    }
  }

  onClinicSelected(event: Organization) {
    this.selectedClinic = event;
  }

  onDateSelected(event: Moment) {
    this.selectedDate = event;
  }

  goToHome() {
    this.$state.go('app.scheduled-transports');
  }

  async onCancel() {
    this.showSnackBar(await this.translationsService.get('CANCEL_ORDER_MSG'));
    this.goToHome();
  }

  async onPlaceOrder() {
    try {
      this.isPlaceOrderDisabled = true;
      const existingOrder = await this.transportAppService.fetchExistingTransportOrder(
        this.selectedDate.toDate(),
        this.transportType,
        this.selectedClinic
      );
      if (existingOrder && Array.isArray(existingOrder)) {
        const pickup = existingOrder.find(
          (transport) =>
            transport.type === 'PICKUP' && transport.status === 'PLANNED'
        );
        if (pickup) {
          this.handleExistingOrder(pickup);
          this.isPlaceOrderDisabled = false;
          return;
        }
      } else if (existingOrder && !Array.isArray(existingOrder)) {
        this.handleExistingOrder(existingOrder as Logistic);
        this.isPlaceOrderDisabled = false;
        return;
      }
      await this.placeNewOrder();
    } catch (e) {
      this.logService.newError(e);
      this.snackBar.open(
        await this.translationsService.get('WIZARD_GENERIC_ERROR_MSG'),
        await this.translationsService.get('OK'),
        {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-warn']
        }
      );
    }
  }

  private async placeNewOrder() {
    await this.transportAppService.placeTransportOrder(
      this.transportType,
      this.lab,
      this.selectedClinic,
      this.transportOrder
    );
    this.showSnackBar(await this.translationsService.get('ORDER_PLACED_MSG'));
    this.goToHome();
  }

  private handleExistingOrder(existingOrder: Logistic) {
    this.dialog
      .open(ExistingTransportDialogComponent, {
        autoFocus: false,
        width: '500px',
        height: '230px',
        data: {
          transportOrder: existingOrder,
          clinicName: this.selectedClinic.name,
          transportDate: this.selectedDate
        } as ExistingTransportDialogData
      })
      .afterClosed()
      .subscribe((shouldEdit: boolean) => {
        if (shouldEdit) {
          this.$state.go('app.scheduled-transports', {
            date: this.selectedDate.valueOf(),
            editTransportId: existingOrder._id
          });
        }
      });
  }

  private async showSnackBar(message: string) {
    this.snackBar.open(message, await this.translationsService.get('OK'), {
      duration: 5000,
      horizontalPosition: 'right'
    });
  }
}
