import { Component, Input, OnInit } from '@angular/core';
import template from './check-box.html';
import { CustomerSetting } from '../../../models/customer-setting.model';

@Component({
  selector: 'check-box',
  template: template
})
export class CheckBoxComponent implements OnInit {
  @Input() setting: CustomerSetting;

  ngOnInit(): void {
    if (this.setting.value === undefined) {
      this.setting.value = this.setting.defaultValue;
    }
  }
}
