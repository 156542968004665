import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { User } from '../../models/user.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UploadedFile } from '../../models/uploaded-file.model';
import {
  CustomerService,
  PersonalNumberFormat
} from '../../services/customer/customer.service';
import { FederatedOperation } from '../../models/federated-operation.model';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { NgForm } from '@angular/forms';
import template from './account-setting-info.html';

@Component({
  selector: 'account-setting-info',
  template: template
})
export class AccountSettingInfoComponent {
  @Input() currentUser: User;
  @Output() updateUser: EventEmitter<{
    type: string;
    fileId: string;
  }> = new EventEmitter<{ type: string; fileId: string }>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('infoSettingForm') infoSettingForm: NgForm;
  @ViewChild('changePasswordModal') changePasswordModal: ElementRef;

  modalConfig: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-lg',
    keyboard: false
  };

  modalRef: BsModalRef;
  bankIdOperation: FederatedOperation;

  constructor(
    private modalService: BsModalService,
    private logService: LogService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private customerService: CustomerService,
    private currentUserService: CurrentUserService
  ) {}

  ngAfterViewInit(): void {
    this.infoSettingForm.statusChanges.subscribe(this.formChanged.bind(this));
  }

  openPasswordModal(): void {
    this.modalRef = this.modalService.show(
      this.changePasswordModal,
      this.modalConfig
    );
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  changePicture(file: UploadedFile, error: string): void {
    if (error) {
      console.error('Error uploading!', error);
      throw new Error('Error uploading!');
    }
    if (this.updateUser) {
      this.updateUser.emit({ type: 'upload', fileId: file._id });
    }
  }

  async activateBankId(): Promise<void> {
    this.updateUser.emit();
    this.bankIdOperation = FederatedOperation.Activate;
  }

  async deactivateBankId(): Promise<void> {
    this.updateUser.emit();
    this.bankIdOperation = FederatedOperation.Deactivate;
  }

  cancelBankId(): void {
    this.bankIdOperation = undefined;
  }

  async onBankIDSuccess(event: {
    operation: string;
    data: any;
  }): Promise<void> {
    const self = this;
    const updatedUser = await this.currentUserService.updateCurrentUser();
    this.currentUser = updatedUser;

    switch (event.operation) {
      case FederatedOperation.Activate: {
        self.toastr.success(
          await self.translationsService.get('SUCCESSFULLY_ACTIVATE_BANKID')
        );
        break;
      }
      case FederatedOperation.Deactivate: {
        self.toastr.success(
          await self.translationsService.get('SUCCESSFULLY_DEACTIVATE_BANKID')
        );
        break;
      }
      default: {
        self.logService.error(
          'account-setting-lab.component',
          'onBankIDSuccess',
          (await self.translationsService.get('ERROR_FEDERATED_OPERATION')) +
            " - '" +
            event.operation +
            "': " +
            event.data
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_FEDERATED_OPERATION')
        );
      }
    }

    self.bankIdOperation = undefined;
  }

  async onBankIDFail(event: { operation: string; data: any }): Promise<void> {
    const self = this;

    switch (event.operation) {
      case FederatedOperation.Activate: {
        self.logService.error(
          'account-setting-lab.component',
          'onBankIDFail',
          (await self.translationsService.get('ERROR_ACTIVATE_BANKID')) +
            ': ' +
            event.data
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_ACTIVATE_BANKID')
        );
        break;
      }
      case FederatedOperation.Deactivate: {
        self.logService.error(
          'account-setting-lab.component',
          'onBankIDFail',
          (await self.translationsService.get('ERROR_DEACTIVATE_BANKID')) +
            ': ' +
            event.data
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_DEACTIVATE_BANKID')
        );
        break;
      }
      default: {
        self.logService.error(
          'account-setting-lab.component',
          'onBankIDFail',
          (await self.translationsService.get('ERROR_FEDERATED_OPERATION')) +
            " - '" +
            event.operation +
            "': " +
            event.data
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_FEDERATED_OPERATION')
        );
      }
    }

    self.bankIdOperation = undefined;
  }

  personalNumberChanged(personalIdentityNumber: string): void {
    const isValid = this.customerService.validatePersonalNumber(
      personalIdentityNumber,
      false
    );
    if (isValid) {
      this.currentUser.personalIdentityNumber = this.customerService.formatPersonalNumber(
        personalIdentityNumber,
        PersonalNumberFormat.FULL_NODASH
      );
    }
  }

  formChanged(): void {
    this.valid.emit(this.infoSettingForm.valid);
  }
}
