import {
  Component,
  OnInit,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { DEFAULT_COLOR_CODE, CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { OrganizationSettingService } from '../../services/organization-setting/organization-setting.service';
import template from './manage-actions.html';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { MaterialService } from '../../services/manage-actions/material-group.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { ActionService } from '../../services/manage-actions/action.service';
import { CategoryService } from '../../services/manage-actions/category.service';
import { cloneDeep, sortBy, orderBy } from 'lodash';
import { Category, SubCategory, ActionSet } from '../../models/organization.model';
import { Action } from '../../models/action.model';
import { MaterialGroups } from '../../models/material-group.model';
import { User } from '../../models/user.model';

@Component({
  selector: 'manage-actions',
  template: template
})
export class ManageActionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currentUser: User;
  categoryTree: Category[] = [];
  category: Category;
  actions: Action[] = [];
  clonedActions: Action[] = [];
  action: Partial<Action> = {};
  currentUserPermissions: { [key: string]: boolean };
  subCategory: SubCategory;
  filteredSubCategories: SubCategory[] = [];
  materials: MaterialGroups[] = [];
  materialGroup: Partial<MaterialGroups> = {};
  searchByMainCategory: string;
  searchBySubCategory: string;
  searchByNameOrNumber: string;
  groupMaterials: { material: string }[] = [];
  backgroundColorCode = DEFAULT_COLOR_CODE.backgroundColorCode;
  textColorCode = DEFAULT_COLOR_CODE.textColorCode;
  sortBy = 'number';
  sortAscending = true;
  selectedActionSet: string;
  activeActionSet: string;
  actionSet: ActionSet = {} as ActionSet;

  constructor(
    private readonly subNavService: SubNavService,
    private readonly currentUserService: CurrentUserService,
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly materialService: MaterialService,
    private readonly translationsService: TranslationsService,
    private readonly logService: LogService,
    private readonly toastr: ToastrService,
    private readonly actionService: ActionService,
    private readonly categoryService: CategoryService,
    @Inject(forwardRef(() => '$location')) private $location: any
  ) {}

  async ngOnInit() {
    this.subNavService.addMenu({
      name: await this.translationsService.get('EDIT_ACTIONS'),
      state: 'app.manage-actions',
      icon: '',
      isActive: true,
      isScrollSpy: false
    });
    this.currentUserPermissions = this.currentUserService.getPermissions();
    //user shoould have permission to edit the actions and order should be action based.
    if (
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.ACTION_BASED !==
        this.organizationSettingService.getCustomerSetting(
          CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION
        ) ||
      !this.currentUserPermissions.EDIT_ACTIONS
    ) {
      //if this is not action based order then redirect to home.
      this.$location.path('/');
      return;
    }
    //For getting action set data from new frontend
    window.addEventListener('message', this.handleActionSetEvent.bind(this));
  }

  ngOnChanges() {
    this.activeActionSet = this.currentUser.organization.activeActionSet;
    this.selectedActionSet = this.currentUser.organization.activeActionSet;
    window.top.postMessage(
      {
        eventType: 'action-set',
        actionSetId: this.selectedActionSet,
        orgId: this.currentUser.organization._id
      },
      '*'
    );
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleActionSetEvent);
  }

  onFilterChange(type) {
    this.actionSet.actions = this.clonedActions.filter((item) => {
      return this.searchByMainCategory === ''
        ? true
        : item.mainCategory
        ? this.searchByMainCategory === item.mainCategory._id
        : true;
    });
    if (type === 'sub_category') {
      this.actionSet.actions = this.actionSet.actions.filter((item) => {
        return item.subCategory.some((subCat) => {
          return !this.searchBySubCategory
            ? true
            : subCat
            ? this.searchBySubCategory === subCat._id
            : true;
        });
      });
    }
  }

  addEditCategory(category) {
    this.category = category
      ? JSON.parse(JSON.stringify(category))
      : { actionSet: this.selectedActionSet };
    $('#main-category-modal').modal('show');
  }

  addEditSubCategory(category) {
    this.subCategory = category
      ? JSON.parse(JSON.stringify(category))
      : {
          colorCode: this.backgroundColorCode,
          textColorCode: this.textColorCode
        };
    $('#sub-category-modal').modal('show');
  }

  addEditAction(action) {
    this.action = action
      ? JSON.parse(JSON.stringify(action))
      : { actionSet: this.selectedActionSet };
    $('#add-action-modal').modal('show');
  }

  addEditMaterial(material) {
    if (material) {
      var clonedMaterialGroup = JSON.parse(JSON.stringify(material));
      this.materialGroup = clonedMaterialGroup;
      this.groupMaterials = clonedMaterialGroup.materials;
    } else {
      this.materialGroup = { actionSet: this.selectedActionSet };
    }
    $('#material-group-modal').modal('show');
  }

  getButtonColor(category) {
    return {
      background: category.colorCode ? category.colorCode : this.backgroundColorCode,
      color: category.textColorCode ? category.textColorCode : this.textColorCode
    };
  }

  sortData(field) {
    if (this.sortBy === field) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortBy = field;
      this.sortAscending = true;
    }
    const sortOrder = this.sortAscending ? 'asc' : 'desc';
    this.actionSet.actions = orderBy(this.actionSet.actions, this.sortBy, sortOrder);
  }

  getSubCategories(categoryFilter: string, id: string) {
    this.searchByMainCategory = id;
    if (this.searchByMainCategory) {
      var filteredCategories = this.actionSet.categories.filter((category) => {
        return this.searchByMainCategory === category._id;
      });
      filteredCategories.forEach((category) => {
        this.filteredSubCategories = category.subCategories.map((subCategory) => {
          return subCategory;
        });
      });
    } else {
      this.filteredSubCategories = [];
      this.searchBySubCategory = '';
    }

    // apply main category filter
    if (categoryFilter) {
      this.searchBySubCategory = '';
      this.onFilterChange('category');
    }
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(ManageActionComponent.name, method, `${message} ${err}`);
    this.toastr.error(await this.translationsService.get(message));
  }

  actionSetChanged(id: string) {
    this.selectedActionSet = id;
    if (this.selectedActionSet === this.currentUser.organization.activeActionSet) {
      this.activeActionSet = this.currentUser.organization.activeActionSet;
    } else {
      this.activeActionSet = '';
    }
    window.top.postMessage(
      {
        eventType: 'action-set',
        actionSetId: this.selectedActionSet,
        orgId: this.currentUser.organization._id
      },
      '*'
    );
  }

  activeActionSetChanged(value: boolean) {
    this.activeActionSet = value ? this.selectedActionSet : '';
    window.top.postMessage(
      { eventType: 'active-action-set', activeActionSet: this.activeActionSet },
      '*'
    );
  }

  addActionSet() {
    window.top.postMessage({ eventType: 'add-action-set' }, '*');
  }

  handleActionSetEvent(e) {
    if (e.data && e.origin === process.env.NEW_APP_URL) {
      switch (e.data.eventType) {
        case 'get-action-set':
          this.actionSet = e.data.actionSet;
          if (this.actionSet.actions) {
            this.actionSet.actions = sortBy(this.actionSet.actions, ['number']);
            this.clonedActions = cloneDeep(this.actionSet.actions);
          }
          this.actionSet.categories?.forEach((category) => {
            category.subCategories.forEach((subCategory) => {
              subCategory.parentCategoryId = category._id;
            });
          });
          break;
        case 'action-set-added':
          this.currentUser.organization.actionSets.push(e.data.actionSet);
          break;
        case 'activate-action-set':
          const actionSetId = e.data.actionSet._id;
          this.currentUser.organization.activeActionSet = actionSetId;
          this.activeActionSet = actionSetId;
          break;
        default:
          throw new Error('Unhandled event type: ' + e.data.eventType);
      }
    }
  }

  reFetch() {
    window.top.postMessage(
      {
        eventType: 'action-set',
        actionSetId: this.selectedActionSet,
        orgId: this.currentUser.organization._id
      },
      '*'
    );
  }
}
