import { Input, Component } from '@angular/core';
import { Order } from '../../../models/order.model';
import template from './additional-info.html';

@Component({
  selector: 'additional-info',
  template: template
})
export class AdditionalInfoComponent {
  @Input() orderDetail: Partial<Order> = {};
}
