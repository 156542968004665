import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppVersion } from '../../models/app-version.model';
import { LogoImage } from '../../models/logo-image.model';
import { LogService } from './log.service';
import { API } from '../../app.constant';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { ResourceService } from './resource.service';

@Injectable()
export class CacheService extends ResourceService {
  public appVersion: string;
  public logoImage: string;
  public fullLogoImage: string;

  // This service is started automatically. Don't inject any services that depend on AngularJS here.
  constructor(private http: HttpClient) {
    super(http, '');
  }

  async init(): Promise<void> {
    await this.getAppVersion()
      .then((version: string) => {
        this.appVersion = version;
      })
      .catch((err: any) => {
        console.error('ERROR_IN_GETTING_APP_VERSION: ', err);
        this.appVersion = 'unknown';
      });

    await this.getLoginLogoImage({ type: 'login' })
      .then((imageUrl: string) => {
        this.logoImage = imageUrl;
      })
      .catch((err: any) => {
        console.error('ERROR_IN_GETTING_LOGIN_LOGO_IMAGE: ', err);
        this.logoImage = '../images/login-logo.png';
      });

    await this.getLoginLogoImage({ type: 'app' })
      .then((imageUrl) => {
        this.fullLogoImage = imageUrl;
      })
      .catch((err: any) => {
        console.error('ERROR_IN_GETTING_FULL_LOGO_IMAGE: ', err);
        this.fullLogoImage = '../images/logo-text.svg';
      });
  }

  getLoginLogoImage(data: { type: string }): Promise<string> {
    const params = new HttpParams().set('type', data.type);

    return this.get<LogoImage>(API.API_BASE + 'logo-image-url', params).then(
      (logoData) => {
        if (logoData.imageUrl) {
          return logoData.imageUrl;
        } else {
          throw new Error('Error getting image url! - ' + logoData.message);
        }
      }
    );
  }

  private getAppVersion(): Promise<string> {
    return this.get<AppVersion>('build-version.json', undefined).then(
      (versionObject) => {
        return versionObject.version + '.' + versionObject.git.revision;
      }
    );
  }
}
