import { Component, Input } from '@angular/core';
import template from './report-description-modal.html';
import style from './report-description.less';

@Component({
  selector: 'report-description-modal',
  template: template,
  styles: [style]
})
export class ReportDescriptionModalComponent {
  @Input() reportTemplate: string;
}
