import { Injectable } from '@angular/core';
import { DATE_FORMATS } from '../../app.constant';
import * as moment from 'moment';
import { SessionService } from '../core/session.service';

@Injectable()
export class LocaleFormatterService {
  constructor(private sessionService: SessionService) {}

  formatDate(date: Date, format: string, localeForDemo: string): string {
    const locale: string = localeForDemo
      ? localeForDemo
      : this.sessionService.getItem('locale');
    if (!locale) {
      return moment(date).format(DATE_FORMATS.DEFAULT);
    }
    moment.locale(locale);
    return moment(date).format(format ? DATE_FORMATS[format] : DATE_FORMATS.DEFAULT);
  }

  formatCurrency(price: number, currency: string): string {
    const locale = this.sessionService.getItem('locale');
    if (!currency || !locale) {
      return price + '';
    }
    return new Intl.NumberFormat(locale, {
      currency: currency,
      style: 'currency',
      minimumFractionDigits: 2,
      currencyDisplay: 'symbol'
    }).format(price);
  }
}
