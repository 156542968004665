import { Component, Input } from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../../../../app.constant';
import { SubCategory } from '../../../../../../../../models/organization.model';
import template from './facial-loop.html';

@Component({
  selector: 'facial-loop',
  template
})
export class FacialLoopComponent {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedFacialLoop: string;
  @Input() facialLoopOptions: string[];
  facialLoopSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.FACIAL_LOOP;
}
