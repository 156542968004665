import { Component, Input } from '@angular/core';
import { SubCategory } from '../../../../../../../../models/organization.model';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './material.html';

@Component({
  selector: 'material',
  template
})
export class MaterialComponent {
  @Input() isMandatory: boolean;
  @Input() isVisible: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() materials: { material: string }[];

  private readonly materialKey = 'material';
  private readonly otherMaterialKey = 'otherMaterial';

  @Input() selectedMaterial: string;
  @Input() selectedOtherMaterial: string;

  private readonly materialNameKey = 'materialName';

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  setMaterial(materialName: string, materialId: string) {
    const isSameMaterialClicked = this.selectedMaterial === materialId;
    this.selectedMaterial = isSameMaterialClicked ? '' : materialId;
    this.constructionSettingsService.setSetting(
      this.materialKey,
      this.selectedMaterial
    );
    this.constructionSettingsService.setSetting(
      this.materialNameKey,
      isSameMaterialClicked ? '' : materialName
    );
    this.constructionSettingsService.setSetting(this.otherMaterialKey, '');
  }

  setOtherMaterial(value: string) {
    this.selectedOtherMaterial = value;
    this.constructionSettingsService.setSetting(this.otherMaterialKey, value);
  }

  getStyles(materialId: string) {
    if (materialId !== this.selectedMaterial) {
      return '';
    }
    return this.constructionSettingsService.getColorClass(this.selectedSubCategory);
  }

  onInputFocus() {
    this.selectedMaterial = '';
    this.constructionSettingsService.setSetting(this.materialKey, '');
    this.constructionSettingsService.setSetting(this.materialNameKey, '');
  }
}
