/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './confirm-modal.html'
/**
 * Confirmation box modal component
 * this modela can be used anywhere in the application
 * it is dependant on bootstrap ui
 */
(function () {
// Please note that the close and dismiss bindings are from $uibModalInstance.
    angular.module('app').component('confirmModal', {
        template: theTemplate,
        bindings: {
            close: '&', //on model close
            dismiss: '&', // on dismiss on the model
            resolve: '<' // reslove parameter
        },
        controller: confirmModelController
    });

    confirmModelController.$inject = [];
    /**
     * Controller function
     */
    function confirmModelController() {
        var $ctrl = this;
        // init function
        $ctrl.$onInit = function () {
            $ctrl.header = $ctrl.resolve.header;
            $ctrl.body = $ctrl.resolve.body;
        };
        // on ok press
        $ctrl.ok = function () {
            $ctrl.close({ $value: true });
        };
        // on cancel or dismiss on the model
        $ctrl.cancel = function () {
            $ctrl.dismiss({ $value: false });
        };
    }
})();