/* start: imports-loader */
import '../../../app.upgrade';
import '../../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-2-confirm.html'
/**
 * Order Confirm Component
 */
(function () {
    'use strict';
    angular.module('app').component('orderNew2Confirm', {
        template: theTemplate,
        controller: orderNew2ConfirmController,
        controllerAs: 'vm',
        bindings: {}
    });
    orderNew2ConfirmController.$inject = [ 'orderService', '$state','logService','toastr','$filter', 'printService', 'CUSTOMER_SETTINGS_CONSTANTS', 'sessionService', '$location', 'newOrderListRedirectService' ];
    /**
     * Confirm order component controller function
     * @param orderService
     * @param $state
     * @param logService
     * @param toastr
     * @param $filter
     */
    function orderNew2ConfirmController(orderService, $state, logService, toastr, $filter, printService, CUSTOMER_SETTINGS_CONSTANTS, sessionService, $location, newOrderListRedirectService) {

        var vm = this;
        vm.printOrder = printOrder;
        vm.navigateToOrderList = navigateToOrderList;
        vm.orderSummaryPdf = orderSummaryPdf;
        vm.copyOrder = copyOrder;
        vm.isVisibleInPrint = false;
        vm.showConfirmSpinner = true;

        vm.orthoSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORTHODONTICS_COLORS;
        vm.claspMaterialSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_MATERIAL;
        vm.facialLoopSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.FACIAL_LOOP;
        vm.claspTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_TYPES;
        vm.springTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SPRING_TYPES;

        function printOrder(id) {
            if (_.isEmpty(vm.orderDetail)) {
                return;
            }
            vm.isVisibleInPrint = true;
            printService.printElementFromID(id).then(function () {
                vm.isVisibleInPrint = false;
            });
        }

        function navigateToOrderList(state) {
            var OrderState = sessionService.getItem('OrderState');
            if (OrderState === 'app.order-status-view') {
                $location.path('/app/order-status-view');
                return;
            }
            newOrderListRedirectService.newOrderList();
        }        

        /**
         * Get Order data when the order is placed
         */
        orderService.getOrderById($state.params.orderId).then(function (result) {
            vm.orderDetail = result.data;
            vm.order = {
                'orderNumber': result.data.orderId,
                "patientName": result.data.patient.firstName + ' ' + result.data.patient.lastName,
                "personalIdentityNumber": result.data.patient.personalIdentityNumber,
                "constructions": []
            };
            if (vm.orderDetail.newConstructions.constructions.length) {
                vm.printableOrder = _.groupBy(vm.orderDetail.newConstructions.constructions, "category.name");
                vm.categories = Object.keys(vm.printableOrder);
            }
            vm.showConfirmSpinner = false;
        }).catch(function (err) {
            vm.showConfirmSpinner = false;
            logService.error('order-confirm.component', 'getOrderById', $filter('translate')('ERROR_IN_GETTING_ORDER') + ": " + err);
            toastr.error($filter('translate')('ERROR_IN_GETTING_ORDER'));
        });

        function orderSummaryPdf() {
            window.top.postMessage(
              { eventType: 'ORDER_SUMMARY_PDF', orderData: this.orderDetail },
              '*'
            );
        }

        function copyOrder() {
            window.top.postMessage(
              { eventType: 'COPY_ORDER', orderData: this.orderDetail },
              '*'
            );
        }
    }
})();