(function () {
    'use strict';

    angular.module('app').config(config);

    config.$inject = [ '$httpProvider' ];

    function config($httpProvider) {
        $httpProvider.interceptors.push([ '$q', '$location', '$rootScope', 'tokenService', function($q, $location, $rootScope, tokenService) {
            function logoutRedirect() {
                var federation = $location.search().federation;
                var redirectTo = $location.search().return_to;
                var redirectParams = $location.search().return_params;
                if (!redirectTo) {
                    redirectTo = $location.path();
                }
                if (!redirectParams && $location.search()) {
                    redirectParams = encodeURI(JSON.stringify($location.search()));
                }

                $rootScope.$emit('logout', redirectTo, redirectParams, federation);
            }

            return {
                request: function(httpConfig) {
                    if (tokenService.isAuthenticated()) {
                        httpConfig.headers.Authorization = tokenService.getToken();
                    }
                    return httpConfig;
                },
                // Response method invoke at every response from http request
                response: function(response) {
                    /**
                     * Check for the invalid token from the code sent from the passport
                     * If condition true then logout the user and redirect to the login page using $location
                     */
                    if (response && response.data && response.data.code === -2) {
                        logoutRedirect();
                    }
                    return response;
                },
                responseError: function(response) {
                    if (response.status === 401 || response.status === 403 || response.data && response.data.code === -2) {
                        var url = response.config.url;
                        console.log("Call to '" + url + "' failed. Not logged in.");

                        //For remove modal open class to resolve black screen issue after logged out
                        $('body').removeClass('modal-open');
                        $('.modal-backdrop').remove();
                        logoutRedirect();
                    }
                    return $q.reject(response);
                }
            };
        } ]);
    }

})();