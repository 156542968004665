import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { Organization, InvoicePaymentMethod } from '../../models/organization.model';
import template from './invoice-clinics.html';

@Component({
  selector: 'invoice-clinics',
  template: template
})
export class InvoiceClinicsComponent implements OnInit {
  @Input() lab: Organization;
  @Input() parentClinics: Organization[];
  @Input() isInvoicingEnabled: boolean;

  ngOnInit(): void {
    const self = this;
    if (!this.lab.invoicing.clinics || !this.lab.invoicing.clinics.length) {
      this.lab.invoicing.clinics = [];
    }
    if (!this.lab.clinicInvoicing) {
      this.lab.clinicInvoicing = {};
    }
    this.lab.invoicing.clinics.forEach((clinic) => {
      if (clinic.invoice) {
        self.lab.clinicInvoicing[clinic.clinicId] = clinic;
      }
    });
  }

  enableInvoicingForClinic(parentClinicId: string, enabled: boolean): void {
    if (this.isInvoicingEnabled) {
      if (!this.lab.clinicInvoicing[parentClinicId]) {
        this.lab.clinicInvoicing[parentClinicId] = {
          invoice: true,
          clinicId: parentClinicId,
          method: InvoicePaymentMethod.PER_ORDER,
          paymentTerms: 30
        };
      } else if (this.lab.clinicInvoicing[parentClinicId]) {
        this.lab.clinicInvoicing[parentClinicId].invoice = enabled;
      }
    }
  }
}
