/**
 * Removable Teeth Structure service
 */
(function () {
    'use strict';
    angular.module('app').service('removableTeethStructureService', removableTeethStructureService);
    removableTeethStructureService.$inject = [ '$q', 'CONSTRUCTIONS','toothStructureService' ];

    function removableTeethStructureService($q, CONSTRUCTIONS,toothStructureService) {

        var constructionName = {
            temporaryDenture: CONSTRUCTIONS.TEMPORARY_DENTURE,
            partialDenture: CONSTRUCTIONS.PARTIAL_DENTURE,
            completeDenture: CONSTRUCTIONS.COMPLETE_DENTURE,
            bitetray: CONSTRUCTIONS.BITETRAY,
            otherRemovable: CONSTRUCTIONS.OTHER_REMOVABLE
        };
        return {
            toothClick: toothClick,
            commonActiveCrown: commonActiveCrown
        };

        /**
         * Tooth click method execute when click on tooth or teeth range
         * @param tooth
         * @param isUpper
         * @param selectedConstructionObj
         * @param selectedTeeth
         * @param selectedConstructionType
         * @param selectedTeethRange
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param selectedFixedConstructionObj
         * @param teethRangeSelected
         * @param teethSelected
         */
        function toothClick(tooth, isUpper, selectedConstructionObj, selectedTeeth, selectedConstructionType, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedFixedConstructionObj, teethRangeSelected, teethSelected) {
            var deferred = $q.defer();
            var construction = commonActiveDenture(tooth, isUpper, selectedTeeth, selectedTeethRange, selectedConstructionType, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedFixedConstructionObj);
            //construction type should not be post.
            var keysConstrictions = {
                'partial-denture': 'partial denture',
                'complete-denture': 'complete denture',
                'temporary-denture': 'temporary denture',
                'bitetray': 'bitetray',
                'other-removable': 'other removable',
                'single-crown': 'single crown',
                'implant-crown': 'implant crown',
                'bridge': 'bridge',
                'other': 'other',
                'implant-bridge': 'implant bridge'
            };
            function selectTypeOfConstruction() {
                for (var key in construction) {
                    if (key && keysConstrictions[key]) {
                        selectedConstructionType = selectedConstructionType === '' ? keysConstrictions[key] : selectedConstructionType;
                    }
                }
            }

            if (construction && selectedConstructionType !== 'bitetray') {
                var countConstructionTypes = Object.keys(construction).length;
                if (countConstructionTypes === 1 || (countConstructionTypes > 1 && !selectedConstructionType)) {
                    selectTypeOfConstruction();
                } else if (countConstructionTypes > 1) {
                    //if there are more then one construction on teeth

                    //check if type does not match to any pre done construction else select first type
                    var isMatched = false;
                    for (var key in construction) {
                        if (selectedConstructionType === keysConstrictions[key]) {
                            isMatched = true;
                            break;
                        }
                    }
                    if (!isMatched) {
                        selectTypeOfConstruction();
                    }
                }
            }

            if (selectedConstructionType) {
                if (selectedConstructionType === constructionName.otherRemovable) {
                    //Validations
                    // it should not be inside completeDentures or bitetrays
                    if (isSelectedTeethInsideCompleteDentures(tooth, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'removable') || isSelectedTeethInsideCompleteDentures(tooth, selectedFixedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'fixed')) {
                        // there is already a complete denture we can not create partial denture
                        deferred.reject('Cannot make construction');
                    }
                    //if it is a partial denture then there should not be any partial denture
                    if (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'partialDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'temporaryDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'implantCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'singleCrowns')) {
                        deferred.reject('Cannot make construction');
                    }
                    // it is valid click
                    selectedTeeth = tooth;
                    //if tooth is selected and valid then call tooth selected function
                    if (teethSelected) {
                        teethSelected(selectedTeeth);
                        commonActiveDenture(selectedTeeth, isUpper, selectedTeeth, selectedTeethRange, selectedConstructionType, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedFixedConstructionObj);
                    }
                } else if (selectedConstructionType === 'partial denture' || selectedConstructionType === 'temporary denture') {
                    //handle partial and temporary denture, single tooth actions
                    //Validations
                    // it should not be inside completeDentures or bitetrays
                    if (isSelectedTeethInsideCompleteDentures(tooth, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'removable') || isSelectedTeethInsideCompleteDentures(tooth, selectedFixedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'fixed')) {
                        // there is already a complete denture we can not create partial denture
                        deferred.reject('Cannot make construction');
                    }
                    //if it is a partial denture then there should not be any partial denture
                    if (selectedConstructionType === 'partial denture' && (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'temporaryDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'implantCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'otherRemovables') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'singleCrowns'))) {
                        deferred.reject('Cannot make construction');
                    }
                    //if it is a temporary denture then there should not be any  temporary denture
                    if (selectedConstructionType === 'temporary denture' && (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'partialDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'implantCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'otherRemovables') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'singleCrowns'))) {
                        deferred.reject('Cannot make construction');
                    }
                    // it is valid click
                    selectedTeeth = tooth;
                    //if tooth is selected and valid then call tooth selected function
                    if (teethSelected) {
                        teethSelected(selectedTeeth);
                        commonActiveDenture(selectedTeeth, isUpper, selectedTeeth, selectedTeethRange, selectedConstructionType, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedFixedConstructionObj);
                    }
                } else if (selectedConstructionType === 'complete denture' || selectedConstructionType === 'bitetray') {
                    //check is complete denture already created then select that one.
                    //else create new
                    var isInMid = false;
                    if (selectedConstructionObj && selectedConstructionObj.completeDentures && selectedConstructionType === 'complete denture') {
                        selectedConstructionObj.completeDentures.forEach(function (denture) {
                            if (toothStructureService.checkIfClickInsideBridge(denture, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedTeethRange, teethRangeSelected) || toothStructureService.checkIfClickInsideBridge(denture, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedTeethRange, teethRangeSelected)) {
                                isInMid = true;
                            }
                        });
                    } else if (selectedConstructionObj && selectedConstructionObj.bitetrays && selectedConstructionType === 'bitetray') {
                        //check is bitetray already created then select that one.
                        //else create new
                        selectedConstructionObj.bitetrays.forEach(function (bitetray) {
                            if (toothStructureService.checkIfClickInsideBridge(bitetray, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedTeethRange, teethRangeSelected) || toothStructureService.checkIfClickInsideBridge(bitetray, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedTeethRange, teethRangeSelected)) {
                                isInMid = true;
                            }
                        });
                    }

                    //if it is not in mid then create new one
                    if (!isInMid) {
                        if (selectedTeethRange && !selectedTeethRange.start) {
                            //we can not start from here if there is any Partial Denture or Temporary Denture here.
                            if (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'partialDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'temporaryDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'singleCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'implantCrowns')) {
                                deferred.reject('Cannot make construction');
                            }
                            selectedTeethRange.start = tooth;
                            selectedTeethRange.isUpper = isUpper;
                        } else if (selectedTeethRange && selectedTeethRange.start && selectedTeethRange.end) {
                            //if start and end already there then deselct old Complete Denture
                            if (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'partialDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'temporaryDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'singleCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'implantCrowns')) {
                                deferred.reject('Cannot make construction');
                            }
                            delete selectedTeethRange.end;
                            selectedTeethRange.start = tooth;
                            selectedTeethRange.isUpper = isUpper;
                        } else if (selectedTeethRange && selectedTeethRange.start && (selectedTeethRange.isUpper === isUpper)) {
                            //End can not be same as start
                            if (selectedTeethRange.start === tooth) {
                                // so deselect the start tooth
                                delete selectedTeethRange.start;
                                deferred.reject('Cannot make construction');
                            }
                            //we can not start from here if there is any Partial Denture or Temporary Denture here.
                            if (toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'partialDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedConstructionObj,'temporaryDentures') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'singleCrowns') || toothStructureService.isActiveSingleTeeth(tooth, selectedFixedConstructionObj,'implantCrowns')) {
                                deferred.reject('Cannot make construction');
                            }
                            //there should not be any complete, partial , temporary Denture or Bitetray in mid
                            /*if (toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, tooth, isUpper,abutmentTeethFirstRange,abutmentTeethSecondRange,selectedConstructionObj,removable)) {
                             return;
                             }*/
                            selectedTeethRange.end = tooth;
                            if (teethRangeSelected) {
                                teethRangeSelected(selectedTeethRange);
                            }
                        }

                        //if start is behind end then interchange them
                        // condition for upper range
                        var start, end, temp;
                        if (isUpper) {
                            //there should not be any complete, partial , temporary Denture or Bitetray in mid
                            if (selectedConstructionObj.partialDentures.length > 0 || selectedConstructionObj.temporaryDentures.length > 0 || selectedFixedConstructionObj.singleCrowns.length > 0 ||
                                selectedFixedConstructionObj.bridges.length > 0 || selectedFixedConstructionObj.implantCrowns.length > 0 || selectedFixedConstructionObj.implantBridges.length > 0) {
                                selectedTeethRange.start = 18;
                                selectedTeethRange.end = 28;
                                if (toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, selectedTeethRange.end, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedConstructionObj,'removable', selectedConstructionType) || toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, selectedTeethRange.end, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedFixedConstructionObj,'fixed', selectedConstructionType)) {
                                    selectedTeethRange = {};
                                    deferred.reject('Cannot make construction');
                                }
                            }

                            if (toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, tooth, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedConstructionObj,'removable', selectedConstructionType) || toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, tooth, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedFixedConstructionObj,'fixed', selectedConstructionType)) {
                                selectedTeethRange = {};
                                deferred.reject('Cannot make construction');
                            }
                            selectedTeethRange.start = 18;
                            selectedTeethRange.end = 28;
                            start = abutmentTeethFirstRange.indexOf(selectedTeethRange.start);
                            end = abutmentTeethFirstRange.indexOf(selectedTeethRange.end);
                            if (start !== -1 && end !== -1 && start > end) {
                                temp = selectedTeethRange.start;
                                selectedTeethRange.start = selectedTeethRange.end;
                                selectedTeethRange.end = temp;
                            }
                        } else {
                            // for lower range
                            //there should not be any complete, partial , temporary Denture or Bitetray in mid
                            if (selectedConstructionObj.partialDentures.length > 0 || selectedConstructionObj.temporaryDentures.length > 0 || selectedFixedConstructionObj.singleCrowns.length > 0 ||
                                selectedFixedConstructionObj.bridges.length > 0 || selectedFixedConstructionObj.implantCrowns.length > 0 || selectedFixedConstructionObj.implantBridges.length > 0) {
                                selectedTeethRange.start = 48;
                                selectedTeethRange.end = 38;
                                if (toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, selectedTeethRange.end, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedConstructionObj,'removable', selectedConstructionType) || toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, selectedTeethRange.end, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedFixedConstructionObj,'fixed', selectedConstructionType)) {
                                    selectedTeethRange = {};
                                    deferred.reject('Cannot make construction');
                                }
                            }
                            if (toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, tooth, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedConstructionObj,'removable', selectedConstructionType) || toothStructureService.checkIfAnyConstructionInRange(selectedTeethRange.start, tooth, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedFixedConstructionObj,'fixed', selectedConstructionType)) {
                                selectedTeethRange = {};
                                deferred.reject('Cannot make construction');
                            }
                            selectedTeethRange.start = 48;
                            selectedTeethRange.end = 38;
                            start = abutmentTeethSecondRange.indexOf(selectedTeethRange.start);
                            end = abutmentTeethSecondRange.indexOf(selectedTeethRange.end);
                            if (start !== -1 && end !== -1 && start > end) {
                                temp = selectedTeethRange.start;
                                selectedTeethRange.start = selectedTeethRange.end;
                                selectedTeethRange.end = temp;
                            }
                        }

                    }
                }
            }
            var data = {
                selectedTeethRange: selectedTeethRange,
                teethRangeSelected: teethRangeSelected,
                selectedConstructionType: selectedConstructionType,
                selectedTeeth: selectedTeeth
            };
            deferred.resolve(data);
            return deferred.promise;
        }

        /**
         * Check if particular is inside completeDentures/bitetrays
         * @param tooth
         * @param constructionObj
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param type
         * @returns {boolean}
         */
        function isSelectedTeethInsideCompleteDentures(tooth, constructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,type) {
            var mid = false;

            if (type === 'removable') {
                if (constructionObj && constructionObj.completeDentures) {
                    constructionObj.completeDentures.forEach(function (denture) {
                        if (toothStructureService.checkIfInsideBridge(denture, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange)) {
                            mid = true;
                        }
                    });
                }
            }
            if (type === 'fixed') {
                if (constructionObj && constructionObj.bridges) {
                    constructionObj.bridges.forEach(function (bridge) {
                        if (toothStructureService.checkIfInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange)) {
                            mid = true;
                        }
                    });
                }
                if (constructionObj && constructionObj.implantBridges) {
                    constructionObj.implantBridges.forEach(function (bridge) {
                        if (toothStructureService.checkIfInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange)) {
                            mid = true;
                        }
                    });
                }
            }
            return mid;
        }

        /**
         * Return class set for given tooth based on all conditions
         * @param teeth
         * @param isUpperRow
         * @param selectedTeeth
         * @param selectedTeethRange
         * @param selectedConstructionType
         * @param selectedConstructionObj
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param selectedFixedConstructionObj
         * @returns {{}}
         */
        function commonActiveDenture(teeth, isUpperRow, selectedTeeth, selectedTeethRange, selectedConstructionType, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedFixedConstructionObj) {
            var obj = commonActiveCrown(teeth, isUpperRow, selectedFixedConstructionObj, selectedTeeth, selectedConstructionType, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange);
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedConstructionObj,'partialDentures')) {
                obj['partial-denture'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedConstructionObj,'temporaryDentures')) {
                obj['temporary-denture'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedConstructionObj,'otherRemovables')) {
                obj['other-removable'] = true;
            }
            if (selectedTeeth === teeth) {
                obj['square-selected'] = true;
                //if single teeth then mark create color as well
                /*   if(selectedConstructionType === constructionName.temporaryDenture){
                      obj['temporary-denture'] = true;
                  }
                  else if(selectedConstructionType === constructionName.partialDenture){
                      obj['partial-denture'] = true;
                  }
                   else if(selectedConstructionType === constructionName.otherRemovable){
                       obj['other-removable'] = true;
                   }*/

            }
            if (selectedTeethRange.start === teeth && (selectedConstructionType === 'complete denture' ||
                    selectedConstructionType === 'bitetray')) {

                if (selectedTeethRange.end) {
                    obj['bridge-start'] = true;
                    /*if(selectedConstructionType === 'bitetray'){
                       obj.bitetray = true;
                    }
                    else {
                       obj['complete-denture'] = true;
                    }*/
                } else {
                    obj['bridge-start-temp'] = true;
                }

            }
            if (selectedTeethRange.end === teeth && (selectedConstructionType === 'complete denture' ||
                    selectedConstructionType === 'bitetray')) {
                obj['bridge-end'] = true;
                /*if(selectedConstructionType === 'bitetray'){
                  obj.bitetray = true;
                }
                else {
                    obj['complete-denture'] = true;
                }*/
            }
            if (toothStructureService.isInMiddleOfSelectedBridge(teeth, isUpperRow, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange) && (selectedConstructionType === 'complete denture' ||
                    selectedConstructionType === 'bitetray')) {
                //check if this teeth is in middle of selected bridge
                obj['bridge-middle'] = true;
                /*if(selectedConstructionType === 'bitetray'){
                   obj.bitetray = true;
                }
                else {
                   obj['complete-denture'] = true;
                }*/
            }

            //find if it is inside complete denture created
            var selectedClass = toothStructureService.isInMiddleOfAnyCreatedBridge(teeth, selectedConstructionObj,abutmentTeethFirstRange, abutmentTeethSecondRange, 'completeDentures');
            var bitetraySelectedClass = toothStructureService.isInMiddleOfAnyCreatedBridge(teeth,selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange, 'bitetrays');
            if (selectedClass) {
                //check if this teeth is in middle of selected Complete Denture
                obj[selectedClass] = true;
                obj['complete-denture'] = true;
            }
            if (bitetraySelectedClass) {
                //check if this teeth is in middle of selected Complete Denture
                obj[bitetraySelectedClass] = true;
                obj.bitetray = true;
            }

            return obj;

        }

        /**
         * Return class set for given tooth based on all conditions
         * @param teeth
         * @param isUpperRow
         * @param selectedFixedConstructionObj
         * @param selectedTeeth
         * @param selectedConstructionType
         * @param selectedTeethRange
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @returns {{}}
         */
        function commonActiveCrown(teeth, isUpperRow, selectedFixedConstructionObj, selectedTeeth, selectedConstructionType, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange) {
            var obj = {};
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedFixedConstructionObj,'singleCrowns')) {
                obj['single-crown'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedFixedConstructionObj,'implantCrowns')) {
                obj['implant-crown'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedFixedConstructionObj,'posts')) {
                obj['square-post'] = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedFixedConstructionObj,'others')) {
                obj.other = true;
            }
            if (toothStructureService.isActiveSingleTeeth(teeth, selectedFixedConstructionObj,'implantCrowns')) {
                obj['square-bg'] = true;
            }
            if (selectedTeeth === teeth) {
                obj['square-selected'] = true;
                //if single teeth then mark create color as well
                if (selectedConstructionType === 'post') {
                    obj['square-post'] = true;
                } else if (selectedConstructionType === 'implant crown') {
                    obj['implant-crown'] = true;
                } else if (selectedConstructionType === 'single crown') {
                    obj['single-crown'] = true;
                }

            }

            if (selectedTeethRange.start === teeth && (selectedConstructionType === 'bridge' ||
                    selectedConstructionType === 'implant bridge')) {
                if (selectedTeethRange.end) {
                    obj['bridge-start'] = true;
                    if (selectedConstructionType === 'implant bridge') {
                        obj['implant-bridge'] = true;
                    } else {
                        obj.bridge = true;
                    }
                } else {
                    obj['bridge-start-temp'] = true;
                }

            }
            if (selectedTeethRange.end === teeth && (selectedConstructionType === 'bridge' ||
                    selectedConstructionType === 'implant bridge')) {
                obj['bridge-end'] = true;
                if (selectedConstructionType === 'implant bridge') {
                    obj['implant-bridge'] = true;
                } else {
                    obj.bridge = true;
                }
            }
            if (toothStructureService.isInMiddleOfSelectedBridge(teeth, isUpperRow, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange) && (selectedConstructionType === 'bridge' ||
                    selectedConstructionType === 'implant bridge')) {
                //check if this teeth is in middle of selected bridge
                obj['bridge-middle'] = true;
                if (selectedConstructionType === 'implant bridge') {
                    obj['implant-bridge'] = true;
                } else {
                    obj.bridge = true;
                }
            }
            //find if it is inside bridge created
            var selectedClass = isInMiddleOfCreatedBridge(teeth, selectedFixedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'bridges');
            var implantSelectedClass = isInMiddleOfCreatedBridge(teeth, selectedFixedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,'implantBridges');
            if (selectedClass) {
                //check if this teeth is in middle of selected bridge
                obj[selectedClass] = true;
                obj.bridge = true;
            }
            if (implantSelectedClass) {
                //check if this teeth is in middle of selected bridge
                obj[implantSelectedClass] = true;
                obj['implant-bridge'] = true;
            }
            return obj;

        }

        /**
         * Check if the tooth is in start or end or in middle of bridge
         * we return the class bases on condition null for outside,
         * if it is at start then we return bridge-selected-start or if it is at end then we return bridge-selected-end
         * @param teeth
         * @param selectedFixedConstructionObj
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param type
         * @returns {string}
         */
        function isInMiddleOfCreatedBridge(teeth, selectedFixedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,type) {
            var selectedClass = '';
            if (selectedFixedConstructionObj && selectedFixedConstructionObj[type]) {
                selectedFixedConstructionObj[type].forEach(function (bridge) {
                    if (bridge.teethRange) {
                        var start, end, teethIndex;
                        if (bridge.teethRange.isUpper) {
                            start = abutmentTeethFirstRange.indexOf(bridge.teethRange.start);
                            end = abutmentTeethFirstRange.indexOf(bridge.teethRange.end);
                            teethIndex = abutmentTeethFirstRange.indexOf(teeth);
                        } else {
                            start = abutmentTeethSecondRange.indexOf(bridge.teethRange.start);
                            end = abutmentTeethSecondRange.indexOf(bridge.teethRange.end);
                            teethIndex = abutmentTeethSecondRange.indexOf(teeth);
                        }
                        if (start < teethIndex && teethIndex < end) {
                            selectedClass = 'bridge-selected';
                        } else if (start === teethIndex && teethIndex < end) {
                            selectedClass = 'bridge-selected-start';
                        } else if (start < teethIndex && teethIndex === end) {
                            selectedClass = 'bridge-selected-end';
                        }
                    }
                });
            }

            return selectedClass;
        }

    }

})();