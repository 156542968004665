import { Injectable } from '@angular/core';
import { difference, isEmpty, uniqBy } from 'lodash';
import { AVAILABLE_SETTINGS } from '../../../app.constant';
import { Unit } from '../../../models/action.model';
import { ConstructionSettingsService } from './construction-settings-service';

@Injectable({
  providedIn: 'root'
})
export class ConstructionService {
  private _selectedConstructions: any;

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  compareSelectedTeeth(constructionTeeth, selectedTeeth) {
    if (!constructionTeeth) {
      return false;
    }
    return constructionTeeth.every((tooth) => {
      return selectedTeeth.indexOf(tooth) > -1;
    });
  }

  isNoOfUnitsFilled(numberOfUnits, numberOfUnitsData) {
    const actionIds = Object.keys(numberOfUnits).filter((actionId) => {
      return numberOfUnits[actionId].mandatory;
    });
    if (!actionIds || !actionIds.length) {
      return true;
    }
    if (!numberOfUnitsData) {
      return false;
    }
    return actionIds.some((actionId) => {
      if (!numberOfUnitsData[actionId]) {
        return false;
      }
      return numberOfUnitsData[actionId].numberOfUnits;
    });
  }

  deleteEmptyNumberOfUnits(numberOfUnits: { [key: string]: Unit }) {
    Object.keys(numberOfUnits).forEach((actionId) => {
      if (!numberOfUnits[actionId].numberOfUnits) {
        delete numberOfUnits[actionId];
      }
    });
    return numberOfUnits;
  }

  getMandatoryFields(constructionSettings) {
    return Object.keys(constructionSettings).filter((key) => {
      if (key === 'numberOfUnits') {
        return this.constructionSettingsService.isNoOfUnitsMandatory(
          constructionSettings.numberOfUnits
        );
      }
      return constructionSettings[key].mandatory;
    });
  }

  checkForColorOrShade(settingsData) {
    return (
      settingsData['colorSampling'] ||
      (settingsData['color'] && settingsData['shade'])
    );
  }

  checkForMaterial(settingsData, key) {
    return settingsData[key] || settingsData['otherMaterial'];
  }

  checkIfMandatoryFieldsFilled(
    mandatoryFields: string[],
    settingsData: {
      [key: string]: any;
    },
    constructionSettings
  ) {
    return mandatoryFields.every((key) => {
      if (key === 'colorOrShade') {
        return this.checkForColorOrShade(settingsData);
      }
      if (key === 'material' || key === 'otherMaterial') {
        return this.checkForMaterial(settingsData, key);
      }
      if (key === 'numberOfUnits') {
        return this.isNoOfUnitsFilled(constructionSettings[key], settingsData[key]);
      }
      if (
        key === 'implantTimes' ||
        key === 'dentureTimes' ||
        key === 'testingTimes'
      ) {
        return this.checkIfTimesFilled(key, settingsData[key]);
      }
      return settingsData[key];
    });
  }

  checkIfTimesFilled(key, times) {
    const { hour, minute } = times[key];
    if (isEmpty(times)) {
      return true;
    }
    return hour && minute;
  }

  getExistingConstructionIndex(
    selectedConstructions,
    mainCategoryName,
    subCategoryName,
    settingsCreatedAt,
    selectedTeethRange,
    selectedTeeth
  ) {
    return selectedConstructions[mainCategoryName][subCategoryName].findIndex(
      (construction) => {
        const isTeethNumber = this.compareSelectedTeeth(
          construction.teethNo,
          selectedTeeth
        );
        //For updating teeth range of present construction.
        if (construction.teethRange && isEmpty(construction.teethRange)) {
          return true;
        }
        if (!construction.teethNo && settingsCreatedAt === construction.createdAt) {
          return true;
        }
        if (construction.teethRange?.start && construction.teethRange?.end) {
          const isTeethRangeStart =
            construction.teethRange.start === selectedTeethRange.start;
          const isTeethRangeEnd =
            construction.teethRange.end === selectedTeethRange.end;
          return isTeethRangeStart && isTeethRangeEnd;
        }
        return isTeethNumber;
      }
    );
  }

  deleteDummyConstructions() {
    Object.keys(this._selectedConstructions || {})
      .filter((key) => key !== 'type')
      .forEach((mainCategory) => {
        Object.keys(this._selectedConstructions[mainCategory]).forEach(
          (subCategory) => {
            if (this._selectedConstructions[mainCategory][subCategory]) {
              const dummyConstructions = this._selectedConstructions[mainCategory][
                subCategory
              ].filter((construct) => !construct.createdAt);
              this._selectedConstructions[mainCategory][subCategory] = difference(
                this._selectedConstructions[mainCategory][subCategory],
                dummyConstructions
              );
            }
          }
        );
      });
  }

  get selectedConstructions() {
    return this._selectedConstructions;
  }

  set selectedConstructions(selectedConstructions) {
    this._selectedConstructions = selectedConstructions;
  }

  addSelectedConstructions(
    mainCategoryName: string,
    subCategoryName: string,
    construction: any
  ) {
    if (!this._selectedConstructions) {
      this._selectedConstructions = {};
    }
    if (!this._selectedConstructions[mainCategoryName]) {
      this._selectedConstructions[mainCategoryName] = {};
    }
    if (!this._selectedConstructions[mainCategoryName][subCategoryName]) {
      this._selectedConstructions[mainCategoryName][subCategoryName] = [];
    }
    this._selectedConstructions[mainCategoryName][subCategoryName].push(
      construction
    );
  }

  resetSelectedConstructions() {
    this._selectedConstructions = null;
  }

  getConstructionSettings(actions) {
    const constructionSettings: { [key: string]: any } = {};
    AVAILABLE_SETTINGS.forEach((setting) => {
      constructionSettings[setting] = {};
      constructionSettings[setting].include = actions.some(
        (action) => action.settings[setting].include
      );
      constructionSettings[setting].mandatory = actions.some(
        (action) => action.settings[setting].mandatory
      );
    });

    actions.forEach((action) => {
      if (
        action.settings.numberOfUnits.include ||
        action.settings.numberOfUnits.mandatory
      ) {
        if (!constructionSettings.numberOfUnits) {
          constructionSettings.numberOfUnits = {};
        }
        constructionSettings.numberOfUnits[action._id] = Object.assign(
          {},
          action.settings.numberOfUnits
        );
      }
    });
    if (constructionSettings.numberOfUnits) {
      constructionSettings.actionIds = Object.keys(
        constructionSettings.numberOfUnits
      );
    }
    return constructionSettings;
  }

  getMaterials(actions) {
    const combinedActions = actions.reduce((result, action) => {
      if (action.materialGroup) {
        return result.concat(action.materialGroup.materials);
      }
      return result;
    }, []);
    return uniqBy(combinedActions, 'material');
  }
}
