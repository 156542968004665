import { Component, forwardRef, Inject, Renderer2 } from '@angular/core';
import template from './forgot-password.html';
import { UsersService } from '../../services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { CurrentUserService } from '../../services/users/current-user.service';
import { LogService } from '../../services/core/log.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { Language } from '../../models/language.model';
import { CacheService } from '../../services/core/cache.service';
import { CustomerService } from '../../services/customer/customer.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { Organization } from '../../models/organization.model';

@Component({
  selector: 'forgot-password',
  template: template
})
export class ForgotPasswordComponent {
  email: string;
  isEmailValid: boolean = true;
  labId: string;
  isFramed = false;
  lab: Organization;

  constructor(
    private userService: UsersService,
    private currentUserService: CurrentUserService,
    private logService: LogService,
    public cacheService: CacheService,
    private customerService: CustomerService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private readonly organizationService: OrganizationService,
    private readonly renderer: Renderer2,
    @Inject(forwardRef(() => '$stateParams')) private $stateParams: any
  ) {}

  ngOnInit() {
    this.labId = this.$stateParams.labId;
    if (this.labId) {
      this.isFramed = this.$stateParams.framed === 'true';
      this.getLabLogoImage();
      if (this.isFramed) {
        this.renderer.addClass(document.body, 'framed-limited-dentist-form');
      }
    }
  }

  getLabLogoImage() {
    this.organizationService
      .getLabLogo({
        labId: this.labId
      })
      .then((result) => {
        this.lab = result.data;
      })
      .catch((err) => {
        this.logService.error('getLabLogoImage', 'ERROR_IN_GETTING_LABS', err);
      });
  }

  sendEmail(): void {
    this.userService
      .sendEmailUser({ email: this.email })
      .then(async (status) => {
        // if status is there, then go to Login Page
        if (status) {
          this.toastr.success(
            await this.translationsService.get('VISIT_EMAIL_FOR_RESET')
          );
          this.currentUserService.goToLogin();
        } else {
          this.toastr.error(
            await this.translationsService.get('VISIT_EMAIL_FOR_RESET')
          );
        }
      })
      .catch(async (err: any) => {
        const text = await this.translationsService.get('ERROR_IN_SENDING_EMAIL');
        this.logService.error(
          'forgot-password.component',
          'sendEmail',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  goToLogin(): void {
    this.currentUserService.goToLogin();
  }

  setLanguage(lang: Language): Promise<void> {
    return this.translationsService.use(lang.value);
  }

  onEmailChange(email: string): void {
    this.email = email;
    this.isEmailValid = this.customerService.validateEmail(email);
  }
}
