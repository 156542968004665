import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  OnDestroy,
  Renderer2
} from '@angular/core';
import template from './limited-dentist-form.html';
import { LimitedDentist } from '../../../../models/user.model';
import { UsersGraphqlService } from '../../../../graphql-services/users/users.graphql.service';
import { TranslationsService } from '../../../../services/translations/translations.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Organization } from '../../../../models/organization.model';
import { WhitelabelService } from '../../services/whitelabel.service';
import { isValidOrganizationNumber } from '../../../../directives/organization-number-validator.directive';
import { isValidSePostalCode } from '../../../../directives/se-postal-code-validator.directive';
import { LogService } from '../../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { StateService, StateParams } from '@uirouter/angularjs';
import { fieldsMatchValidator } from '../../../../directives/fields-match-validator.directive';

@Component({
  selector: 'limited-dentist-form',
  template
})
export class LimitedDentistFormComponent implements OnInit, OnDestroy {
  labId: string;
  labLogo: Partial<Organization>;
  errorMessage: string;
  userForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', [Validators.required, fieldsMatchValidator('password')]]
  });
  clinicForm = this.fb.group({
    clinicName: ['', Validators.required],
    organizationNumber: ['', [Validators.required, isValidOrganizationNumber]],
    phone: ['', Validators.required],
    street: ['', Validators.required],
    postalCode: ['', [Validators.required, isValidSePostalCode]],
    city: ['', Validators.required]
  });

  constructor(
    private readonly toastr: ToastrService,
    private readonly usersGraphqlService: UsersGraphqlService,
    private readonly translationsService: TranslationsService,
    private readonly whitelabelService: WhitelabelService,
    private readonly fb: FormBuilder,
    private readonly renderer: Renderer2,
    @Inject(forwardRef(() => '$state')) private readonly $state: StateService,
    @Inject(forwardRef(() => '$stateParams'))
    private readonly $stateParams: StateParams
  ) {}

  async ngOnInit() {
    if (this.$stateParams.framed === 'true') {
      this.renderer.addClass(document.body, 'framed-limited-dentist-form');
    }
    this.labId = this.$stateParams.labId;
    this.labLogo = await this.whitelabelService.fetchLabLogo(this.labId);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'framed-limited-dentist-form');
  }

  async onSubmit() {
    try {
      if (!this.userForm.invalid && !this.clinicForm.invalid && this.labId) {
        const limitedDentist = this.createLimitedDentist();
        await this.usersGraphqlService.createLimitedDentist(limitedDentist);
        this.toastr.success(
          await this.translationsService.get('LIMITED_DENTIST_ACCOUNT_CREATED')
        );
        this.$state.go('login', {
          labId: this.labId,
          framed: this.$stateParams.framed
        });
      }
    } catch (error) {
      this.errorMessage = error.message;
    }
  }

  navigateToLogin() {
    this.$state.go('login', {
      labId: this.labId,
      framed: this.$stateParams.framed
    });
  }

  private createLimitedDentist(): Partial<LimitedDentist> {
    const user = this.userForm.value;
    const clinic = this.clinicForm.value;
    return {
      name: {
        firstName: user.firstName,
        lastName: user.lastName
      },
      password: user.password,
      confirmPassword: user.confirmPassword,
      email: user.email,
      clinicName: clinic.clinicName,
      organizationNumber: clinic.organizationNumber,
      contact: {
        address: {
          street: clinic.street,
          city: clinic.city,
          postal_code: parseInt(clinic.postalCode.replace(/ /g, '')), // NUMBER!!
          country: 'SE'
        }
      },
      invoice: {
        street: clinic.street,
        city: clinic.city,
        postal_code: clinic.postalCode.replace(/ /g, ''),
        costCenter: ''
      },
      contactInfo: {
        phone: clinic.phone,
        web: '',
        email: user.email
      },
      labId: this.labId
    };
  }
}
