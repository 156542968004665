import { Input, Component } from '@angular/core';
import { OrderService } from '../../../services/order-new/order-new.service';
import { GetAllOrdersService } from '../../../services/order-list/get-all-orders.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { Order } from '../../../models/order.model';
import template from './decline-order-modal.html';

@Component({
  selector: 'decline-order-modal',
  template: template
})
export class DeclineOrderModalComponent {
  @Input() orderDetail: Partial<Order> = {};
  decline: any = { message: '' };

  constructor(
    private orderService: OrderService,
    private getAllOrdersService: GetAllOrdersService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private logService: LogService
  ) {}

  declineOrder(): Promise<void> {
    this.orderDetail.declineObj = this.decline;
    return this.orderService
      .declineOrder(this.orderDetail)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
        this.getAllOrdersService.clearData(); //to clear cached orders list data
        this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
        window.location.reload();
      })
      .catch(async (err) => {
        if (err.data.message === 'Already Declined') {
          const text = await this.translationsService.get('ALREADY_DECLINED');
          this.toastr.error(text);
        } else {
          const text = await this.translationsService.get(
            'ERROR_IN_DECLINE_ORDER'
          );
          this.logService.error(
            'decline-order-modal.component',
            'declineOrder',
            text + ': ' + err
          );
          this.toastr.error(text);
        }
      });
  }
}
