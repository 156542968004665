import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
  EventEmitter,
  Output
} from '@angular/core';
import { TokenService } from '../../services/core/token.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FederatedOperation } from '../../models/federated-operation.model';
import { FederatedService } from '../../services/federated-login/federated.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import template from './federated-operation-frame.html';
import style from './federated-operation-frame.less';

@Component({
  selector: 'federated-operation-frame',
  template: template,
  styles: [style]
})
export class FederatedOperationFrameComponent implements OnChanges {
  @Input() personalIdentityNumber: string;
  @Input() operation: FederatedOperation;
  @Input() federation: string;
  @Output() onInit: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSuccess: EventEmitter<{
    operation: string;
    data: any;
  }> = new EventEmitter<{ operation: string; data: any }>();
  @Output() onFail: EventEmitter<{
    operation: string;
    data: any;
  }> = new EventEmitter<{ operation: string; data: any }>();
  // @ViewChild('federatedIFrame') federatedIframe;
  @ViewChild('spinner') spinner: SpinnerComponent;
  public federatedRedirectUrl: SafeResourceUrl;

  constructor(
    private tokenService: TokenService,
    private federatedService: FederatedService,
    private currentUserService: CurrentUserService,
    private sanitizer: DomSanitizer
  ) {}

  onLoad(): void {
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    this.onInit.emit();
  }

  async showIFrame(federation: string): Promise<void> {
    let redirectUrl: SafeResourceUrl;
    if (this.operation && federation !== 'bankid-ask') {
      this.spinner.show();
      const payload = {
        federation: federation,
        operation: this.operation,
        personal_number: this.personalIdentityNumber
      };
      const rawUrl = (await this.federatedService.federatedOperation(payload))
        .redirectUrl;

      // tslint:disable-next-line:max-line-length
      // this.rawUrl = "http://localhost:9000/api/v1/federated-login/deactivateRedirect/bankid-ask/5c90cb0ec0fa547a1dad3c33?grandidsession=cd1228e8da74b211e522811298002021";

      redirectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(rawUrl);
    }

    this.federatedRedirectUrl = redirectUrl;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // If federation is 'bankid-ask', show the form to select bankid method first
    if (changes.operation) {
      this.showIFrame(this.federation);
    }
  }

  @HostListener('window:message', ['$event']) async onPostMessage(
    event: MessageEvent
  ): Promise<void> {
    const self = this;
    if (
      event.type === 'message' &&
      event.data.type &&
      event.data.type === 'federation'
    ) {
      const federatedMessage: {
        operation: string;
        type: string;
        code: string;
        message: string;
        token?: string;
      } = event.data;



      if (federatedMessage.code === 'success') {
        this.onSuccess.emit({
          operation: federatedMessage.operation,
          data: federatedMessage.message
        });
      } else if (federatedMessage.code !== 'success') {
        this.onFail.emit({
          operation: federatedMessage.operation,
          data: federatedMessage.message
        });
      }
    }
  }
}
