import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'logisticsClinicFilter'
})
export class LogisticsClinicFilterPipe implements PipeTransform {
  transform(clinics: any, search: string) {
    if (!search) {
      return clinics;
    }
    return clinics.filter((clinic) => {
      const regex = new RegExp(search, 'i');
      return (
        regex.test(clinic.clinic?.name) ||
        regex.test(clinic.clinic?.contact?.address?.postal_code) ||
        regex.test(clinic.clinic?.contact?.address?.street)
      );
    });
  }
}
