import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges
} from '@angular/core';
import template from './add-lab-technician-modal.html';
import { ROLE, STATUS } from '../../app.constant';
import { PermissionService } from '../../services/core/permission.service';
import { CustomerService } from '../../services/customer/customer.service';
import { UsersService } from '../../services/users/users.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../services/core/log.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { Organization } from '../../models/organization.model';
import { User } from '../../models/user.model';
import { Role } from '../../models/roles.model';

interface TechnicianUser {
  id: string;
  active: boolean;
  name?: {
    first?: string;
    last?: string;
  };
  type?: string;
  email?: string;
  lab?: string;
  organization?: string;
  role?: string;
  isAdmin?: boolean;
}

@Component({
  selector: 'add-lab-technician-modal',
  template
})
export class AddLabTechnicianModalComponent implements OnInit, OnChanges {
  @Output() onLabTechnicianChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() roles: Role[];
  @Input() currentUser: User;
  @Input() labTechnician: TechnicianUser;

  disableAddLabTechnicianButton = true;
  validEmail = false;
  isSuperAdmin: boolean;
  user: Partial<User>;
  labs: Organization[];

  constructor(
    private readonly permissionService: PermissionService,
    private readonly customerService: CustomerService,
    private readonly userService: UsersService,
    private readonly toastr: ToastrService,
    private readonly translationsService: TranslationsService,
    private readonly logService: LogService,
    private readonly organizationService: OrganizationService
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.labTechnician) {
      this.labTechnician = {} as TechnicianUser;
    }
    if (!this.labTechnician.name) {
      this.labTechnician.name = {};
    }
    this.labs = await this.fetchLabs();
    this.isSuperAdmin = this.permissionService.isSuperAdmin(this.currentUser);
    // Assign current user parent lab while creating new lab technician by lab admin
    if (!this.isSuperAdmin) {
      this.labTechnician.lab = this.currentUser.organization._id;
    }

    if (this.labTechnician.email) {
      this.checkEmail(this.labTechnician.email);
    }
  }

  ngOnChanges(): void {
    this.validEmail = this.customerService.validateEmail(this.labTechnician.email);
  }

  checkEmail(email: string): void {
    this.labTechnician.email = email;
    this.validEmail = this.customerService.validateEmail(this.labTechnician.email);
  }

  cancel(): void {
    this.closeModal.emit();
  }

  async updateLabTechnician(labTechnicianId: string): Promise<void> {
    this.disableAddLabTechnicianButton = false;
    this.labTechnician.organization = this.labTechnician.lab;
    this.labTechnician.id = labTechnicianId;
    this.labTechnician.isAdmin = this.labTechnician.role === ROLE.TECHNICIAN_ADMIN;
    try {
      await this.userService.editUser(this.labTechnician);
      this.labTechnician = {} as TechnicianUser;
      if (!this.isSuperAdmin && this.currentUser.organization) {
        this.labTechnician.lab = this.currentUser.organization._id;
      }
      const text = await this.translationsService.get('LAB_TECHNICIAN_UPDATED');
      this.toastr.success(text);
      this.validEmail = false;
      this.disableAddLabTechnicianButton = true;
      this.user = {};
      this.onLabTechnicianChange.emit();
      this.closeModal.emit();
    } catch (err) {
      if (err.code === STATUS.DUPLICATE_EMAIL) {
        this.handleError('updateLabTechnician', 'DUPLICATE_EMAIL', err);
      } else {
        this.handleError(
          'updateLabTechnician',
          'ERROR_IN_UPDATE_LAB_TECHNICIAN',
          err
        );
      }
    }
  }

  async saveLabTechnician(): Promise<void> {
    this.disableAddLabTechnicianButton = false;
    const labTechnicianObj: TechnicianUser = this.labTechnician;
    labTechnicianObj.organization = labTechnicianObj.lab;
    labTechnicianObj.type = 'lab_technician';
    this.labTechnician.isAdmin = this.labTechnician.role === ROLE.TECHNICIAN_ADMIN;

    // TODO: Not sure if this works - casting to User.
    try {
      await this.userService.saveDetail(labTechnicianObj as User);
      this.labTechnician = {} as TechnicianUser;
      if (!this.isSuperAdmin && this.currentUser.organization) {
        this.labTechnician.lab = this.currentUser.organization._id;
      }
      this.toastr.success(
        await this.translationsService.get('LAB_TECHNICIAN_CREATED')
      );
      this.validEmail = false;
      this.disableAddLabTechnicianButton = true;
      this.onLabTechnicianChange.emit();
      this.closeModal.emit();
    } catch (err) {
      if (err.code === STATUS.DUPLICATE_EMAIL) {
        this.handleError('saveLabTechnician', 'DUPLICATE_EMAIL', err);
      } else {
        this.handleError('saveLabTechnician', 'ERROR_IN_ADD_LAB_TECHNICIAN', err);
      }
    }
  }

  private async fetchLabs(): Promise<Organization[]> {
    const labs = await this.organizationService.getAllOrganizations({
      type: 'lab',
      active: true
    });
    return labs.data;
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(
      AddLabTechnicianModalComponent.name,
      method,
      `${message} ${err}`
    );
    this.toastr.error(await this.translationsService.get(message));
  }
}
