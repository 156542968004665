import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { API } from '../../app.constant';
import { Injectable } from '@angular/core';
import { Order, Message, OrderMessage } from '../../models/order.model';
import { Status } from '../../models/status.model';
import { CacheFactory, Cache } from 'cachefactory';

@Injectable()
export class OrderService extends ResourceService {
  private cache: Cache;
  private orderCache: Cache;
  private readonly notificationsKey = 'notifications';
  private readonly unreadMessagesKey = 'unread-messages';

  constructor(
    httpClient: HttpClient,
    private readonly apiInterceptor: ApiInterceptor
  ) {
    super(httpClient, '');
    this.setupCache();
  }

  saveDetail(order: Order): Promise<{ _id: string; message: string }> {
    this.orderCache.removeAll();
    return this.post(`${API.API_BASE}orders`, order, undefined, this.apiInterceptor);
  }

  getAllOrders(queryParams: {
    searchText: string;
    statusView?: boolean;
    clinicId: string;
    pageNo?: number;
    orderStatus?: string;
    orderSample?: string;
  }): Promise<{ data: Order[] }> {
    let params = new HttpParams().set('searchText', queryParams.searchText);
    if (queryParams.pageNo) {
      params = params.set('pageNo', queryParams.pageNo.toString());
    }
    if (queryParams.statusView) {
      params = params.set('statusView', queryParams.statusView.toString());
    }

    if (queryParams.clinicId) {
      params = params.set('clinicIds', queryParams.clinicId);
    }

    if (queryParams.orderStatus) {
      params = params.set('orderStatus', queryParams.orderStatus);
    }
    if (queryParams.orderSample) {
      params = params.set('orderSample', queryParams.orderSample);
    }

    return this.get(`${API.API_V2_BASE}orders`, params, this.apiInterceptor);
  }

  getOrdersForPatient(personalIdentityNumber: string): Promise<{ data: Order[] }> {
    const params = new HttpParams().set(
      'personalIdentityNumber',
      personalIdentityNumber
    );

    return this.get(
      `${API.API_V2_BASE}order/:personalIdentityNumber`,
      params,
      this.apiInterceptor
    );
  }

  async getOrderById(orderId: string, isDraft = false): Promise<{ data: Order }> {
    if (!this.orderCache.get(orderId)) {
      this.orderCache.put(orderId, await this.getOrderByIdBackend(orderId, isDraft));
    }
    return this.orderCache.get(orderId);
  }

  getPatientFromOrder(orderId: string): Promise<Partial<Order>> {
    const params = new HttpParams().set('orderId', orderId);
    return this.get(
      `${API.API_V2_BASE}orders/:orderId/patient`,
      params,
      this.apiInterceptor
    );
  }

  addNewMaterial(orderId: string, constructionId: string, material) {
    this.orderCache.removeAll();
    const params = new HttpParams()
      .set('orderId', orderId)
      .set('constructionId', constructionId);
    return this.put(
      `${API.API_V2_BASE}orders/:orderId/:constructionId`,
      material,
      params,
      this.apiInterceptor
    );
  }

  updateMaterial(orderId: string, constructionId: string, material) {
    this.orderCache.removeAll();
    const params = new HttpParams()
      .set('orderId', orderId)
      .set('constructionId', constructionId)
      .set('materialId', material._id);
    return this.put(
      `${API.API_V2_BASE}orders/:orderId/:constructionId/:materialId`,
      material,
      params,
      this.apiInterceptor
    );
  }

  deleteMaterial(orderId: string, constructionId: string, materialId: string) {
    this.orderCache.removeAll();
    const params = new HttpParams()
      .set('orderId', orderId)
      .set('constructionId', constructionId)
      .set('materialId', materialId);
    return this.delete(
      `${API.API_V2_BASE}orders/:orderId/:constructionId/:materialId`,
      params,
      this.apiInterceptor
    );
  }

  updateSimpleMaterial(orderId: string, data) {
    this.orderCache.removeAll();
    const params = new HttpParams()
      .set('orderId', orderId)
      .set('materialId', data.material._id);
    return this.put(
      `${API.API_V2_BASE}simple-orders/:orderId/:materialId`,
      data,
      params,
      this.apiInterceptor
    );
  }

  deleteSimpleMaterial(orderId: string, materialId: string, data) {
    this.orderCache.removeAll();
    const params = new HttpParams()
      .set('orderId', orderId)
      .set('materialId', materialId);
    return this.deleteWithBody(
      `${API.API_V2_BASE}simple-orders/:orderId/:materialId`,
      data,
      params,
      this.apiInterceptor
    );
  }

  addSimpleOrderNewMaterial(orderId: string, data) {
    this.orderCache.removeAll();
    const params = new HttpParams().set('orderId', orderId);
    return this.put(
      `${API.API_V2_BASE}simple-orders/:orderId`,
      data,
      params,
      this.apiInterceptor
    );
  }

  addSimpleOrderNewAction(orderId: string, data) {
    this.orderCache.removeAll();
    const params = new HttpParams().set('orderId', orderId);
    return this.put(
      `${API.API_V2_BASE}simple-orders-action/:orderId`,
      data,
      params,
      this.apiInterceptor
    );
  }

  updateSimpleAction(orderId: string, data) {
    this.orderCache.removeAll();
    const params = new HttpParams()
      .set('orderId', orderId)
      .set('actionId', data.action._id);
    return this.put(
      `${API.API_V2_BASE}simple-orders-action/:orderId/:actionId`,
      data,
      params,
      this.apiInterceptor
    );
  }

  deleteSimpleAction(orderId: string, actionId: string, data) {
    this.orderCache.removeAll();
    const params = new HttpParams()
      .set('orderId', orderId)
      .set('actionId', actionId);
    return this.deleteWithBody(
      `${API.API_V2_BASE}simple-orders-action/:orderId/:actionId`,
      data,
      params,
      this.apiInterceptor
    );
  }

  removeLabFlag(orderId: string): Promise<Partial<Order>> {
    this.orderCache.removeAll();
    const params = new HttpParams().set('orderId', orderId);
    return this.put(
      `${API.API_V2_BASE}lab/:orderId/remove-lab-flag`,
      undefined,
      params,
      this.apiInterceptor
    );
  }

  async getOrder(data: { orderId: string; version?: string }): Promise<Order> {
    //TODO: The cache feature should be completely removed for orders
    this.orderCache.removeAll();
    const cacheKey = `${data.orderId}V${data.version}`;
    if (!this.orderCache.get(cacheKey)) {
      this.orderCache.put(cacheKey, await this.getOrderBackend(data));
    }
    return this.orderCache.get(cacheKey);
  }

  updateOrder(order: Order): Promise<Partial<Status>> {
    this.orderCache.removeAll();
    const params = new HttpParams().set('orderId', order._id || order.orderId);
    return this.put(
      `${API.API_BASE}order/:orderId`,
      order,
      params,
      this.apiInterceptor
    );
  }

  declineOrder(order: Partial<Order>): Promise<Partial<Status>> {
    this.orderCache.removeAll();
    const params = new HttpParams().set('orderId', order._id);
    return this.put(
      `${API.API_BASE}decline-order/:orderId`,
      order,
      params,
      this.apiInterceptor
    );
  }

  cancelOrder(order: Partial<Order>): Promise<Partial<Status>> {
    this.orderCache.removeAll();
    const params = new HttpParams().set('orderId', order._id);
    return this.put(
      `${API.API_BASE}cancel-order/:orderId`,
      order,
      params,
      this.apiInterceptor
    );
  }

  deleteFile(data: { fileId: string; orderId: string }): Promise<Partial<Status>> {
    this.orderCache.removeAll();
    const params = new HttpParams()
      .set('fileId', data.fileId)
      .set('orderId', data.orderId);
    return this.delete(
      `${API.API_BASE}delete-file/:fileId/:orderId`,
      params,
      this.apiInterceptor
    );
  }

  addMessage(message: Message): Promise<Partial<Status>> {
    this.orderCache.removeAll();
    const params = new HttpParams().set('orderId', message.orderId);
    return this.post(
      `${API.API_BASE}order-messages/:orderId`,
      message,
      params,
      this.apiInterceptor
    );
  }

  getMessages(orderId: string): Promise<{ data: { message: Message[] } }> {
    const params = new HttpParams().set('orderId', orderId);
    return this.get(
      `${API.API_BASE}order-messages/:orderId`,
      params,
      this.apiInterceptor
    );
  }

  async getUnreadMessageData(): Promise<{ data: OrderMessage[] }> {
    if (!this.cache.get(this.unreadMessagesKey)) {
      this.cache.put(
        this.unreadMessagesKey,
        await this.getUnreadMessageDataFromBackend()
      );
    }
    return this.cache.get(this.unreadMessagesKey);
  }

  async getNotifications(): Promise<{
    data: {
      messageData: any;
      orderDisplayId: string;
      order: string;
      patient: any;
    }[];
  }> {
    if (!this.cache.get(this.notificationsKey)) {
      this.cache.put(
        this.notificationsKey,
        await this.getNotificationsFromBackend()
      );
    }
    return this.cache.get(this.notificationsKey);
  }

  clearAllNotifications(orderIds: {
    orderIds: string[];
  }): Promise<{ data: string }> {
    this.cache.removeAll();
    return this.put(
      `${API.API_V2_BASE}notifications/mark-all-as-read`,
      orderIds,
      undefined,
      this.apiInterceptor
    );
  }

  changeMessageStatus(orderId: string): Promise<Partial<Status>> {
    this.cache.removeAll();
    const params = new HttpParams().set('orderId', orderId);
    return this.put(
      `${API.API_BASE}change/order-messages-status/:orderId`,
      undefined,
      params,
      this.apiInterceptor
    );
  }

  saveNewOrder(order: Order): Promise<{ _id: string; message: string }> {
    return this.post(
      `${API.API_BASE}orders-new`,
      order,
      undefined,
      this.apiInterceptor
    );
  }

  updateNewOrder(order: Order): Promise<Partial<Status>> {
    const params = new HttpParams().set('id', order._id);
    this.orderCache.removeAll();
    this.cache.removeAll();
    return this.put(
      `${API.API_BASE}orders-new/:id`,
      order,
      params,
      this.apiInterceptor
    );
  }

  rateSimpleOrder(order: Order): Promise<Partial<Status>> {
    const params = new HttpParams().set('id', order._id);
    return this.put(
      `${API.API_BASE}rate-simple-order/:id`,
      order,
      params,
      this.apiInterceptor
    );
  }

  setLabFlag(orderId: string): Promise<Partial<Order>> {
    const params = new HttpParams().set('orderId', orderId);
    return this.put(
      `${API.API_V2_BASE}lab/:orderId/set-lab-flag`,
      undefined,
      params,
      this.apiInterceptor
    );
  }

  private async getNotificationsFromBackend() {
    return this.get(
      `${API.API_V2_BASE}notifications`,
      undefined,
      this.apiInterceptor
    );
  }

  private async getUnreadMessageDataFromBackend() {
    return this.get(
      `${API.API_BASE}order-unread-messages`,
      undefined,
      this.apiInterceptor
    );
  }

  private async getOrderByIdBackend(orderId: string, isDraft){
    const params = new HttpParams()
      .set('orderId', orderId)
      .set('isDraft', String(isDraft));
    return this.get(`${API.API_BASE}order/:orderId`, params, this.apiInterceptor);
  }

  private async getOrderBackend(data: { orderId: string; version?: string }) {
    let params = new HttpParams().set('orderId', data.orderId);
    if (data.version) {
      params = params.append('version', data.version || '');
    }
    return this.get(
      `${API.API_V2_BASE}orders/:orderId`,
      params,
      this.apiInterceptor
    );
  }

  private setupCache() {
    const cacheFactory = new CacheFactory();
    this.cache = cacheFactory.createCache('OrderService', {
      deleteOnExpire: 'aggressive',
      maxAge: 2 * 60 * 1000
    });
    this.orderCache = cacheFactory.createCache('OrderServiceOrderById', {
      deleteOnExpire: 'aggressive',
      maxAge: 2 * 60 * 1000
    });
  }
}
