import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import template from './order-list.html';
import { ORDER_VERSION } from '../../app.constant';
import { User } from '../../models/user.model';
import { PermissionService } from '../../services/core/permission.service';
import { SessionService } from '../../services/core/session.service';
import { GetAllOrdersService } from '../../services/order-list/get-all-orders.service';
import { OrderService } from '../../services/order-new/order-new.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { Order, OrderMessage } from '../../models/order.model';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { Organization } from '../../models/organization.model';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import * as _ from 'lodash';
import { EventService } from '../../services/core/event.service';
import { NewOrderListRedirectService } from '../../services/new-order-list-redirect/new-order-list-redirect.service';

@Component({
  selector: 'order-list',
  template: template
})
export class OrderListComponent implements OnInit {
  @Input() currentUser: User;
  isBusy: boolean;
  selectedClinicId = '';
  selectedStatus = '';
  selectedSample = '';
  searchText: string;
  sortType: string = '';
  sortReverse: boolean = true;
  lab: Organization;
  orders: Order[] = [];
  busy: boolean = false;
  pageNo: number = 0;
  selectedPickUpData = {};
  SelectedOrder: Order;

  constructor(
    public permissionService: PermissionService,
    private sessionService: SessionService,
    private orderService: OrderService,
    private logService: LogService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private eventService: EventService,
    private organizationService: OrganizationService,
    private subNavService: SubNavService,
    private getAllOrdersService: GetAllOrdersService,
    private newOrderListRedirectService: NewOrderListRedirectService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  async ngOnInit(): Promise<void> {
    // Code for redirect to new order list in new Frontend
    this.newOrderListRedirectService.newOrderList();
    return;

    this.isBusy = true;
    await this.addMenu();
    this.sessionService.setItem('OrderState', 'app.order-list');
    this.eventService.on('newNotificationAvailable', async (result, data) => {
      if (data.isOrderListState) {
        await this.populateOrders(true);
      }
    });
    if (this.permissionService.isSuperAdmin(this.currentUser)) {
      this.$state.transitionTo('app.users');
    } else {
      if (this.permissionService.isLabTechnician(this.currentUser)) {
        this.lab = await this.currentLab();
      }
      await this.populateOrders();
      const orderSortObj = this.sessionService.getObject('orderSortObj');
      if (orderSortObj && orderSortObj.type) {
        this.sortOrderData(orderSortObj.type, orderSortObj.sortReverse);
      }
    }
  }

  async addMenu(): Promise<void> {
    this.subNavService.resetList();
    this.subNavService.addMenu({
      name: await this.translationsService.get('LIST_VIEW'),
      state: 'app.order-list',
      icon: 'fa-list-ul',
      isActive: true,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('STATUS_VIEW'),
      state: 'app.order-status-view',
      icon: 'fa-plus',
      isActive: false,
      isScrollSpy: false
    });
  }

  async onFilterUpdate(data: {
    selectedClinic: string;
    searchText: string;
    selectedStatus: string;
    selectedSample: string;
  }): Promise<void> {
    this.selectedClinicId = data.selectedClinic;
    this.searchText = data.searchText;
    this.selectedStatus = data.selectedStatus;
    this.selectedSample = data.selectedSample;
    this.isBusy = true;
    this.busy = false;
    this.pageNo = 0;
    await this.populateOrders(true);
  }

  pagination(): void {
    if (this.busy) {
      return;
    }
    this.busy = true;
    this.selectedClinicId = this.sessionService.getItem('selectedClinicId');
    this.selectedStatus = this.sessionService.getItem('selectedStatus');
    this.selectedSample = this.sessionService.getItem('selectedSample');
    this.isBusy = true;
    this.orderService
      .getAllOrders({
        searchText: this.searchText,
        clinicId: this.selectedClinicId,
        pageNo: this.pageNo,
        orderStatus: this.selectedStatus,
        orderSample: this.selectedSample
      })
      .then((response) => {
        this.busy = response.data.length <= 0;
        this.orders = this.orders.concat(response.data);
        this.orders = _.uniqBy(this.orders, '_id');
        this.isBusy = false;
        this.pageNo += 1;
        this.getAllOrdersService.setOrderData(this.orders, this.pageNo); // set data in service
        if (!this.permissionService.isSuperAdmin(this.currentUser)) {
          this.getUnreadMessageData(true);
        }
      })
      .catch(async (err: any) => {
        this.logService.error(
          'order-list.component',
          'pagination',
          (await this.translationsService.get('ERROR_IN_GETTING_ORDERS')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_ORDERS')
        );
      });
  }

  populateOrders(refresh?: boolean): Promise<void> {
    this.selectedClinicId = this.sessionService.getItem('selectedClinicId');
    this.selectedStatus = this.sessionService.getItem('selectedStatus');
    this.selectedSample = this.sessionService.getItem('selectedSample');
    if (!this.getAllOrdersService.getOrderData() || refresh) {
      return this.orderService
        .getAllOrders({
          searchText: this.searchText,
          clinicId: this.selectedClinicId,
          pageNo: 0,
          orderStatus: this.selectedStatus,
          orderSample: this.selectedSample
        })
        .then((response) => {
          this.getAllOrdersService.setOrderData(response.data, 0); // set data in service
          this.orders = response.data;
          this.isBusy = false;
          if (!this.permissionService.isSuperAdmin(this.currentUser)) {
            this.getUnreadMessageData(true);
          }
        })
        .catch(async (err: any) => {
          this.logService.error(
            'order-list.component',
            'populateOrders',
            (await this.translationsService.get('ERROR_IN_GETTING_ORDERS')) +
              ': ' +
              err
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_GETTING_ORDERS')
          );
        });
    } else {
      const orderData = this.getAllOrdersService.getOrderData();
      this.orders = orderData.orders; // get data from service
      this.pageNo = orderData.pageNo;
      this.isBusy = false;
      if (!this.permissionService.isSuperAdmin(this.currentUser)) {
        this.getUnreadMessageData();
      }
    }
  }

  setMessageCountForDentists(orderMessages: OrderMessage[]): void {
    this.orders.forEach((order) => {
      const message = orderMessages.find((orderMessage) => {
        return orderMessage.order === order._id;
      });
      if (message) {
        const shouldShowMessage = message.messagesData.some((messageData) => {
          return (
            (this.currentUser.generalNotifications.newMessage &&
              !messageData.type) ||
            (messageData.type && messageData.message === 'delivery_date_changed')
          );
        });
        if (shouldShowMessage) {
          order.unreadMessages = message.message;
        }
      }
    });
  }

  checkMessageForTechnicians(
    message: string,
    generalNotifications: {
      newOrder: boolean;
      orderModified: boolean;
      orderResend: boolean;
    }
  ) {
    return (
      (message === 'sent_by_dentist' && generalNotifications.newOrder) ||
      (message === 'order_modified' && generalNotifications.orderModified) ||
      (message === 'order_resend' && generalNotifications.orderResend)
    );
  }

  setMessageCountForTechnicians(orderMessages: OrderMessage[]): void {
    const generalNotifications = this.lab.generalNotifications;
    this.orders.forEach((order) => {
      const message = orderMessages.find((orderMessage) => {
        return orderMessage.order === order._id;
      });
      if (message) {
        const shouldShowMessage = message.messagesData.some((messageData) => {
          return (
            (generalNotifications.newMessage && !messageData.type) ||
            (messageData.type &&
              this.checkMessageForTechnicians(
                messageData.message,
                generalNotifications
              ))
          );
        });
        if (shouldShowMessage) {
          order.unreadMessages = message.message;
        }
      }
    });
  }

  setMessageCount(orderMessages: OrderMessage[]): void {
    if (this.permissionService.isDentist(this.currentUser)) {
      return this.setMessageCountForDentists(orderMessages);
    }
    if (this.lab) {
      return this.setMessageCountForTechnicians(orderMessages);
    }
  }

  getUnreadMessageData(refresh?: boolean): void {
    if (!this.getAllOrdersService.getUnreadMessagesDataData() || refresh) {
      this.orderService
        .getUnreadMessageData()
        .then((response) => {
          this.getAllOrdersService.setUnreadMessages(response); // set unread messages data in service
          if (response.data && response.data.length) {
            this.setMessageCount(response.data);
          }
        })
        .catch(async (err: any) => {
          this.logService.error(
            'order-list.component',
            'getUnreadMessageData',
            `${await this.translationsService.get(
              'ERROR_IN_GETTING_MESSAGES'
            )}: ${err}`
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_GETTING_MESSAGES')
          );
        });
    } else {
      const unreadMessages = this.getAllOrdersService.getUnreadMessagesDataData(); // get unread messages data from service
      if (unreadMessages && unreadMessages.data) {
        this.setMessageCount(unreadMessages.data);
      }
    }
  }

  currentLab(): Promise<Organization> {
    return this.organizationService
      .getCurrentLab()
      .then((labData) => {
        return labData.data;
      })
      .catch(async (err: any) => {
        this.logService.error(
          'order-list.component',
          'currentLab',
          `${await this.translationsService.get('ERROR_IN_GETTING_LABS')}: ${err}`
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_LABS')
        );
        return undefined;
      });
  }

  sortData(type: string): void {
    this.sortType = type;
    this.sortReverse = !this.sortReverse;
    this.sessionService.setObject('orderSortObj', {
      type: this.sortType,
      sortReverse: this.sortReverse
    });
    const sortOrder = this.sortReverse ? 'asc' : 'desc';
    this.orders = _.orderBy(this.orders, this.sortType, sortOrder);
  }

  sortOrderData(type: string, sortReverse: boolean): void {
    this.sortType = type;
    this.sortReverse = sortReverse;
    const sortOrder = sortReverse ? 'asc' : 'desc';
    this.orders = _.orderBy(this.orders, type, sortOrder);
  }

  async onTruckChange(): Promise<void> {
    this.orders = [];
    this.getAllOrdersService.clearData(); //to clear cached orders list data
    this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
    await this.populateOrders(true);
  }

  onToggleLabHandleRequest(data): void {
    this.SelectedOrder = data.order;
    if (
      this.currentUser.organization &&
      this.currentUser.organization.isTransportEnable &&
      !data.order.deliveryDetails.isLabHaveOwnResource
    ) {
      if (
        data.order.transport &&
        data.order.transport.status &&
        data.order.transport.status !== 'PLANNED'
      ) {
        return;
      }
      this.setPickUpData();
      if (data.order.transport && data.order.transport._id) {
        this.selectedPickUpData = {
          _id: data.order.transport._id,
          clinic: data.order.transport.clinic,
          clinicName: data.order.deliveryDetails.pickUpLocation.name,
          clinicContact: data.order.deliveryDetails.pickUpLocation.contact,
          comments: data.order.transport.comments,
          orderId: data.order._id,
          noOfRequestedPackages: data.order.transport.noOfRequestedPackages,
          transportDay: data.order.transport.transportDay,
          transportDate: new Date(data.order.transport.transportDay),
          dentistName: data.order.dentist
            ? data.order.dentist.name
            : data.order.created.by.name
        };
      }
      $('#schedule-pick-up-from-truck-icon-modal').modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      });
      return;
    }
    data.order.deliveryDetails.isLabHandleRequest = data.isLabHandleRequest;
    data.order.deliveryDetails.isLabHaveOwnResource = false;
    this.saveLabHandleRequest(data.order);
  }

  setPickUpData() {
    this.selectedPickUpData = {
      clinic: this.SelectedOrder.deliveryDetails.pickUpLocation._id,
      clinicName: this.SelectedOrder.deliveryDetails.pickUpLocation.name,
      clinicContact: this.SelectedOrder.deliveryDetails.pickUpLocation.contact,
      comments: null,
      orderId: this.SelectedOrder._id,
      noOfRequestedPackages: 1,
      transportDay: null,
      transportDate: null,
      dentistName: this.SelectedOrder.dentist
        ? this.SelectedOrder.dentist.name
        : this.SelectedOrder.created.by.name
    };
  }

  closePickupModal() {
    this.SelectedOrder = null;
    $('#schedule-pick-up-from-truck-icon-modal').modal('hide');
  }

  onLabHandle() {
    this.SelectedOrder.deliveryDetails.isLabHandleRequest = true;
    this.SelectedOrder.deliveryDetails.isLabHaveOwnResource = true;
    this.saveLabHandleRequest(this.SelectedOrder);
  }

  saveLabHandleRequest(order) {
    const isSimpleOrder = order.type === ORDER_VERSION.ORDER_1;
    order.labHandleReq = true;
    if (isSimpleOrder) {
      order.orderId = order._id;
      this.orderService
        .updateOrder(order)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('SAVE_LAB_HANDLE_REQUEST')
          );
          this.getAllOrdersService.clearData(); //to clear cached orders list data
          this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
          await this.populateOrders(true);
        })
        .catch(async (err) => {
          this.logService.error('order-list.component', 'saveLabHandleRequest', err);
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_UPDATE_ORDER')
          );
        });
    } else {
      order.id = order._id;
      this.orderService
        .updateNewOrder(order)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('SAVE_LAB_HANDLE_REQUEST')
          );
          this.getAllOrdersService.clearData(); //to clear cached orders list data
          this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
          await this.populateOrders(true);
        })
        .catch(async (err) => {
          this.logService.error(
            'order-list.component',
            'saveLabHandleRequest 1',
            err
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_UPDATE_ORDER')
          );
        });
    }
  }
}
