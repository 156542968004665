import { Injectable } from '@angular/core';
import * as markerjs2 from 'markerjs2';
import { TranslationsService } from '../translations/translations.service';

@Injectable()
export class ImageAnnotationService {
  constructor(private translationsService: TranslationsService) {}

  showMarkerArea(targetId, cb) {
    const markerArea = new markerjs2.MarkerArea(
      document.getElementById(`img-${targetId}`)
    );

    // render image with annotation in base64
    markerArea.addEventListener('render', (event) => {
      cb(null, event.dataUrl);
    });

    // make sure clicking close wasn't an accident
    markerArea.addEventListener('beforeclose', async (event) => {
      // eslint-disable-next-line no-restricted-globals
      $('#add-image-modal').modal('hide');
      if (!confirm(await this.translationsService.get('CONFIRM_CLOSE'))) {
        event.preventDefault();
      }
    });

    markerArea.settings.displayMode = 'popup';
    markerArea.settings.popupMargin = 80;
    markerArea.renderAtNaturalSize = true;
    markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;
    markerArea.uiStyleSettings.zoomButtonVisible = true;
    markerArea.uiStyleSettings.zoomOutButtonVisible = true;
    markerArea.uiStyleSettings.zIndex = '1000000000';
    markerArea.uiStyleSettings.canvasBackgroundColor = '#333';
    markerArea.uiStyleSettings.toolbarBackgroundColor = '#105c9d';
    markerArea.uiStyleSettings.toolbarBackgroundHoverColor = '#00bbd1';
    markerArea.uiStyleSettings.okButtonColor = '#0f0';
    markerArea.show();
  }
}
