import {
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  Component,
  Inject,
  forwardRef
} from '@angular/core';
import { OrganizationService } from '../../services/clinic/organization.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { OrganizationSettingService } from '../../services/organization-setting/organization-setting.service';
import { CustomerService } from '../../services/customer/customer.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { Organization } from '../../models/organization.model';
import { User } from '../../models/user.model';
import * as _ from 'lodash';
import * as moment from 'moment';

import template from './lab-profile.html';
import style from './lab-profile.less';
import { StateService } from '@uirouter/angularjs';

@Component({
  selector: 'lab-profile',
  template,
  styles: [style]
})
export class LabProfileComponent implements OnInit, OnChanges {
  @Input() currentUser: User;
  lab: Organization;
  selectedSetting = 'general';
  parentClinics: Organization[];
  private isInvoicingEnabled: boolean;
  invoicingSettingName: string =
    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.INVOICING_MODULE;
  invoicingSettingIndex: number;
  isWhiteLabelEnabled: boolean;
  isLabWorkflowEnabled: boolean;
  workflowUrl: string;
  materialsUrl: string;
  actionsUrl: string;
  deviationsUrl: string;
  notificationsUrl: string;

  isProduction = process.env.NODE_ENV === 'production';

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly toastr: ToastrService,
    private readonly translationsService: TranslationsService,
    private readonly logService: LogService,
    private readonly organizationSettingsService: OrganizationSettingService,
    private readonly customerService: CustomerService,
    private currentUserService: CurrentUserService,
    @Inject(forwardRef(() => '$state')) private readonly $state: StateService
  ) {
    this.isInvoicingEnabled = this.organizationSettingsService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.INVOICING_MODULE
    );
    this.isWhiteLabelEnabled = this.organizationSettingsService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.WHITE_LABEL
    );
    this.isLabWorkflowEnabled = this.organizationSettingsService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.LAB_WORKFLOW
    );
  }

  ngOnInit(): void {
    this.currentLab();
    this.workflowUrl = `${process.env.NEW_APP_URL}/lab-settings/workflow`;
    this.materialsUrl = `${process.env.NEW_APP_URL}/lab-settings/material-settings`;
    this.actionsUrl = `${process.env.NEW_APP_URL}/lab-settings/action-settings`;
    this.deviationsUrl = `${process.env.NEW_APP_URL}/lab-settings/deviation-settings`;
    this.notificationsUrl = `${process.env.NEW_APP_URL}/lab-settings/notification-settings`;
    if (this.$state.params.tab) {
      this.selectedSetting = this.$state.params.tab.split('?')[0];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lab) {
      const newVal = changes.lab.currentValue.customerSettings;
      const invoicingSettingIndex = newVal.findIndex((setting) => {
        return setting.name === this.invoicingSettingName;
      });
      if (invoicingSettingIndex > -1) {
        this.isInvoicingEnabled =
          newVal[invoicingSettingIndex].value === 'true' ||
          newVal[invoicingSettingIndex].value === true;
      }
    }
  }

  toggleInvoicing(isEnabledInvoicing: boolean) {
    this.isInvoicingEnabled = isEnabledInvoicing;
  }

  async updateCustomerSettings(): Promise<void> {
    if (this.lab.customerSettings) {
      this.invoicingSettingIndex = this.lab.customerSettings.findIndex((setting) => {
        return setting.name === this.invoicingSettingName;
      });
      if (this.invoicingSettingIndex === -1) {
        this.lab.customerSettings.push({
          name: this.invoicingSettingName,
          value: this.isInvoicingEnabled + ''
        });
      } else {
        ((this.lab.customerSettings[this.invoicingSettingIndex]
          .value as any) as boolean) = this.isInvoicingEnabled;
      }
      this.organizationService
        .updateOrganization(this.lab._id, {
          customerSettings: this.lab.customerSettings
        })
        .then(async (result) => {
          this.toastr.success(
            await this.translationsService.get('SETTINGS_SAVED_SUCCESSFULLY')
          );
          this.currentUserService.updateCurrentUser();
        })
        .catch(async (err) => {
          const message = await this.translationsService.get(
            'ERROR_IN_UPDATING_ORGANIZATION'
          );
          this.logService.error(
            'lab-profile.component',
            'updateCustomerSettings',
            message + ': ' + err
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_UPDATING_ORGANIZATION')
          );
        });
    }
  }

  saveLabChanges() {}

  // Get Current lab details
  currentLab(): void {
    this.organizationService
      .getCurrentLab()
      .then((labData) => {
        this.lab = labData.data;
        this.lab.selectedDocs = [];
        this.lab.defaultJobs = this.labDefaultJobs();
        this.lab.defaultHours = this.defaultHours();
        if (!this.lab.currentJobs.length) {
          this.lab.currentJobs = [];
        } else {
          this.lab.currentJobs.forEach((currentJob) => {
            this.lab.defaultJobs.forEach((defaultJob) => {
              if (currentJob.name === defaultJob.name) {
                defaultJob.active = currentJob.active;
                defaultJob.deliveryDays = currentJob.deliveryDays;
              }
            });
          });
        }
        if (!this.lab.openingHours.length) {
          this.lab.openingHours = [];
        } else {
          this.lab.openingHours.forEach((openingHour) => {
            this.lab.defaultHours.forEach((defaultHour) => {
              if (openingHour.day === defaultHour.day) {
                defaultHour.active = openingHour.active;
                defaultHour.time = openingHour.time;
              }
            });
          });
        }
        if (this.lab.documents.length) {
          this.lab.signedDocuments &&
            this.lab.signedDocuments.forEach((signedDocument) => {
              this.lab.selectedDocs.push(signedDocument);
            });
        }
        this.getConnectedParentClinics();
      })
      .catch(async (err) => {
        this.handleError('currentLab', 'ERROR_IN_GETTING_LABS', err);
      });
  }

  /**
   * Get All Clinics
   */
  // TODO: Rename this method. It is not a getter.
  getConnectedParentClinics(): void {
    this.organizationService
      .getConnectedParentClinics({ organizationId: this.lab._id })
      .then((clinics) => {
        this.parentClinics = clinics.data;
      })
      .catch(async (err) => {
        this.handleError(
          'getConnectedParentClinics',
          'ERROR_IN_GETTING_CLINICS',
          err
        );
      });
  }

  // Checking other email field filled or not for lab user
  isOtherTypeEmail(): boolean {
    const otherEmailNotGiven =
      this.lab &&
      this.lab.emailNotifications &&
      this.lab.emailNotifications.type &&
      this.lab.emailNotifications.type === 'other' &&
      !this.lab.emailNotifications.otherEmail;

    // for opening hours time not valid
    let openingHoursTimeInvalid = false;
    if (this.lab.isvalidOpeningHoursTime === false) {
      openingHoursTimeInvalid = true;
    }

    let invoicingDataNotFilled = false;
    if (this.isInvoicingEnabled && this.lab && this.lab.invoicing) {
      if (
        !this.lab.invoicing.invoiceAddress ||
        !this.lab.invoicing.organizationNo ||
        !this.lab.invoicing.paymentDetails
      ) {
        return true;
      }

      if (
        !(
          this.lab.invoicing.paymentDetails.postgiro ||
          this.lab.invoicing.paymentDetails.bankgiro ||
          (this.lab.invoicing.paymentDetails.bicSwift &&
            this.lab.invoicing.paymentDetails.iban)
        )
      ) {
        return true;
      }

      if (
        !this.lab.invoicing.contactInfo ||
        !this.lab.invoicing.contactInfo.email ||
        !this.customerService.validateEmail(this.lab.invoicing.contactInfo.email)
      ) {
        return true;
      }

      let zipCodeInValid = true;
      const zipCode = Number(this.lab.invoicing.invoiceAddress.postal_code);
      zipCodeInValid = zipCode < 10000 || zipCode > 99999;
      invoicingDataNotFilled =
        !this.lab.invoicing.invoiceAddress.street ||
        !this.lab.invoicing.invoiceAddress.postal_code ||
        !this.lab.invoicing.invoiceAddress.city;
      const isValidOrgNr = new RegExp('^\\d{6,6}-\\d{4,4}$').test(
        this.lab.invoicing.organizationNo
      );
      let isValidVatNumber = true;
      if (this.lab.invoicing.vatNo) {
        isValidVatNumber = new RegExp('^SE\\d{10,10}01$').test(
          this.lab.invoicing.vatNo
        );
      }
      return (
        otherEmailNotGiven ||
        openingHoursTimeInvalid ||
        invoicingDataNotFilled ||
        zipCodeInValid ||
        !isValidOrgNr ||
        !isValidVatNumber
      );
    }
    return otherEmailNotGiven || openingHoursTimeInvalid;
  }

  // ToDo: Error handeling could be shared over all components.
  private async handleError(
    method: string,
    message: string,
    err: Error
  ): Promise<void> {
    this.logService.error(LabProfileComponent.name, method, `${message} ${err}`);
    this.toastr.error(await this.translationsService.get(message));
  }

  private labDefaultJobs() {
    return [
      { name: 'single_crown', active: false, deliveryDays: 0 },
      { name: 'bridge', active: false, deliveryDays: 0 },
      { name: 'implant_crown', active: false, deliveryDays: 0 },
      { name: 'implant_bridge', active: false, deliveryDays: 0 },
      { name: 'post', active: false, deliveryDays: 0 },
      { name: 'complete_denture', active: false, deliveryDays: 0 },
      { name: 'partial_denture', active: false, deliveryDays: 0 },
      { name: 'temporary_denture', active: false, deliveryDays: 0 },
      { name: 'bitetray', active: false, deliveryDays: 0 }
    ];
  }

  private defaultHours() {
    const defaultTimeFrom = new Date();
    const defaultTimeTo = new Date();
    defaultTimeFrom.setHours(8, 0);
    defaultTimeTo.setHours(17, 0);
    return [
      {
        day: 'Monday',
        active: false,
        time: {
          from: defaultTimeFrom.getDate(),
          to: defaultTimeTo.getDate()
        }
      },
      {
        day: 'Tuesday',
        active: false,
        time: {
          from: defaultTimeFrom.getDate(),
          to: defaultTimeTo.getDate()
        }
      },
      {
        day: 'Wednesday',
        active: false,
        time: {
          from: defaultTimeFrom.getDate(),
          to: defaultTimeTo.getDate()
        }
      },
      {
        day: 'Thursday',
        active: false,
        time: {
          from: defaultTimeFrom.getDate(),
          to: defaultTimeTo.getDate()
        }
      },
      {
        day: 'Friday',
        active: false,
        time: {
          from: defaultTimeFrom.getDate(),
          to: defaultTimeTo.getDate()
        }
      },
      {
        day: 'Saturday',
        active: false,
        time: {
          from: defaultTimeFrom.getDate(),
          to: defaultTimeTo.getDate()
        }
      },
      {
        day: 'Sunday',
        active: false,
        time: {
          from: defaultTimeFrom.getDate(),
          to: defaultTimeTo.getDate()
        }
      }
    ];
  }
}
