import { Inject, Injectable } from '@angular/core';
import { CurrentUserService } from '../users/current-user.service';
import { RollbarPerson } from '../../models/rollbar-person.model';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';

export enum LogLevel {
  CRITICAL = 'critical',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEBUG = 'debug'
}

@Injectable()
export class LogService {
  constructor(
    @Inject(RollbarService) private readonly rollbar: Rollbar,
    private currentUserService: CurrentUserService
  ) {}

  optionalDebug(
    debug: boolean,
    controllerName: string,
    methodName: string,
    message: string
  ): void {
    if (debug) {
      this.debug(controllerName, methodName, message);
    }
  }

  debug(controllerName: string, methodName: string, message: string): void {
    this.log(controllerName, methodName, message, LogLevel.DEBUG);
  }

  info(controllerName: string, methodName: string, message: string): void {
    this.log(controllerName, methodName, message, LogLevel.INFO);
  }

  warning(controllerName: string, methodName: string, message: string): void {
    this.log(controllerName, methodName, message, LogLevel.WARNING);
  }

  error(controllerName: string, methodName: string, message: string): void {
    this.log(controllerName, methodName, message, LogLevel.ERROR);
  }

  newError(error: Error) {
    this.rollbar.error(error);
    console.error(error);
  }

  trace(controllerName: string, methodName: string, message: string): void {
    console.trace(controllerName + ':' + methodName + ' =>', message);
    this.log(controllerName, methodName, message, LogLevel.ERROR);
  }

  critical(controllerName: string, methodName: string, message: string): void {
    this.log(controllerName, methodName, message, LogLevel.CRITICAL);
  }

  getPerson(): Promise<RollbarPerson | undefined> {
    return this.currentUserService
      .getUserData()
      .then((result) => {
        return {
          id: result.email,
          username: result.email,
          email: result.email
        };
      })
      .catch((_error) => {
        return { id: null };
      });
  }

  async log(
    controllerName: string,
    methodName: string,
    message: string,
    level: LogLevel
  ): Promise<void> {
    try {
      const userData = await this.getPerson();
      if (level === LogLevel.CRITICAL || level === LogLevel.ERROR) {
        this.rollBarLog(userData, message, controllerName, methodName);
      }
      console.log(
        'Controller Name'.padEnd(16) +
          ' ==>  ' +
          controllerName +
          '\n' +
          'Method Name'.padEnd(16) +
          ' ==>  ' +
          methodName +
          '\n' +
          'User'.padEnd(16) +
          ' ==>  ' +
          (userData ? userData.email : '-') +
          '\n' +
          (level + ' message').padEnd(16) +
          ' ==>  ' +
          message +
          '\n'
      );
    } catch (err) {
      console.error('Error logging to rollbar!', err);
    }
  }

  async rollBarLog(
    person: RollbarPerson | undefined,
    message: string,
    controllerName: string,
    methodName: string
  ): Promise<void> {
    try {
      this.rollbar.error(message + ' ' + controllerName + '-' + methodName);
    } catch (err) {
      console.log('Error in rollbar logging', err);
    }
  }
}
