import { Input, Component, EventEmitter, Output } from '@angular/core';

import template from './patient-order-list.html';
import { Order } from '../../../../models/order.model';

@Component({
  selector: 'patient-order-list',
  template
})
export class PatientOrderListComponent {
  @Input() ordersForPatient: Order[];
  @Input() isNewOrder: boolean;
  @Output() isNewOrderChecked: EventEmitter<boolean> = new EventEmitter<boolean>();

  newOrderChecked(isNewOrder: boolean) {
    this.isNewOrderChecked.emit(isNewOrder);
  }
}
