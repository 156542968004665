/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './footer.html'
(function () {
    'use strict';
    angular.module('app').component('footerBar', {
        template: theTemplate,
        controller: footerController,
        controllerAs: 'vm'
    });

    footerController.$inject = [];

    function footerController() {

    }

})();