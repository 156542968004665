/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './partial-denture.html'
/**
 * order removable partial denture construction component
 */
(function () {
    'use strict';
    angular.module('app').component('partialDenture', {
        template: theTemplate,
        controller: partialDentureController,
        controllerAs: 'vm',
        bindings: {
            constructionSettings: '=',
            selectedTeeth: '=',
            addUpdatePartialDenture: '=',
            removePartialDenture: '=',
            orderIdToModify: '=',
            selectedDentureType: '=',
            removeConstruction: '=',
            partialDentureData: '<',
            selectedConstructionObj: '=', //currently selected constructions objects
            tempPartialDentureData: "=",
            tempTemporaryDentureData: "=",
            partialDentureCommentsArray: "="
        }
    });

    /**
     * Inject the dependencies here
     * @type {string[]}
     */
    partialDentureController.$inject = [ '$scope', 'userService', 'tokenService', 'CONSTRUCTIONS', 'DATE', 'CUSTOMER_SETTINGS_CONSTANTS', 'organizationSettingService', 'printService' ];

    /**
     * Controller function for the partial denture construction
     */
    function partialDentureController($scope, userService, tokenService, CONSTRUCTIONS, DATE, CUSTOMER_SETTINGS_CONSTANTS, organizationSettingService, printService) {

        var vm = this;
        vm.constructionName = {
            temporaryDenture: CONSTRUCTIONS.TEMPORARY_DENTURE,
            partialDenture: CONSTRUCTIONS.PARTIAL_DENTURE
        };
        if (!vm.partialDentureData) {
            //if null initialize. though this is not expected
            vm.partialDentureData = {};
        }
        vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;

        //characteristics settings value according customer settings for translation
        vm.partialDentureStepsSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PARTIAL_DENTURE_STEPS;

        // set characteristics settings value from customer settings
        vm.partialDentureSteps = organizationSettingService.getCustomerSetting(vm.partialDentureStepsSettingName);

        vm.isVisibleInPrint = false;

        /**
         * Member functions
         */
        vm.setMaterial = setMaterial;
        vm.setPartialDentureSteps = setPartialDentureSteps;
        vm.printUpperLowerJawImage = printUpperLowerJawImage;

        $scope.$watch('vm.selectedTeeth', function () {
            //if we previously created any single crown then use that else create empty
            if (vm.selectedDentureType === 'partial denture') {
                if (vm.selectedConstructionObj && vm.selectedConstructionObj.partialDentures && vm.selectedConstructionObj.partialDentures.length > 0) {
                    var partialDenture = vm.selectedConstructionObj.partialDentures[vm.selectedConstructionObj.partialDentures.length - 1];
                    //get old construction

                    for (var sc in vm.selectedConstructionObj.partialDentures) {
                        if (vm.selectedTeeth === vm.selectedConstructionObj.partialDentures[sc].teethNo) {
                            vm.partialDentureData = {
                                shade: vm.selectedConstructionObj.partialDentures[sc].shade,
                                color: vm.selectedConstructionObj.partialDentures[sc].color,
                                colorSampling: vm.selectedConstructionObj.partialDentures[sc].colorSampling,
                                allComments: vm.selectedConstructionObj.partialDentures[sc].allComments,
                                material: vm.selectedConstructionObj.partialDentures[sc].material,
                                createdAt: vm.selectedConstructionObj.partialDentures[sc].createdAt,
                                otherMaterial: vm.selectedConstructionObj.partialDentures[sc].otherMaterial
                            };
                        }
                    }

                    if (!vm.partialDentureData.shade) {
                        vm.partialDentureData = {
                            shade: partialDenture.shade,
                            color: partialDenture.color,
                            colorSampling: partialDenture.colorSampling,
                            allComments: partialDenture.allComments,
                            material: partialDenture.material,
                            createdAt: partialDenture.createdAt,
                            otherMaterial: partialDenture.otherMaterial
                        };
                    }
                    vm.partialDentureSteps.forEach(function (partialDentureStep) {
                        vm.partialDentureData[partialDentureStep] = partialDenture[partialDentureStep];
                    });

                } else {
                    userService.getCurrentUser().then(function (result) {
                        var userProfile = result.data;
                        if (userProfile.constructionSetting) {
                            //get default construction
                            if (vm.tempPartialDentureData) {
                                vm.partialDentureData = {
                                    shade: vm.tempPartialDentureData.shade,
                                    color: vm.tempPartialDentureData.color,
                                    createdAt: vm.tempPartialDentureData.createdAt,
                                    allComments: vm.tempPartialDentureData.allComments,
                                    material: 'CoCr',
                                    otherMaterial: vm.tempPartialDentureData.otherMaterial
                                };

                                vm.partialDentureSteps.forEach(function (partialDentureStep) {
                                    vm.partialDentureData[partialDentureStep] = vm.tempPartialDentureData[partialDentureStep];
                                });
                            }
                        }
                    });
                }
            } else if (vm.selectedDentureType === 'temporary denture') {
                //if we previously created any implant crown then use that else create empty
                if (vm.selectedConstructionObj && vm.selectedConstructionObj.temporaryDentures && vm.selectedConstructionObj.temporaryDentures.length > 0) {
                    var temporaryDenture = vm.selectedConstructionObj.temporaryDentures[vm.selectedConstructionObj.temporaryDentures.length - 1];
                    //get old construction

                    for (var ic in vm.selectedConstructionObj.temporaryDentures) {
                        if (vm.selectedTeeth === vm.selectedConstructionObj.temporaryDentures[ic].teethNo) {
                            vm.partialDentureData = {
                                material: vm.selectedConstructionObj.temporaryDentures[ic].material,
                                allComments: vm.selectedConstructionObj.temporaryDentures[ic].allComments,
                                shade: vm.selectedConstructionObj.temporaryDentures[ic].shade,
                                color: vm.selectedConstructionObj.temporaryDentures[ic].color,
                                colorSampling: vm.selectedConstructionObj.temporaryDentures[ic].colorSampling,
                                otherMaterial: vm.selectedConstructionObj.temporaryDentures[ic].otherMaterial,
                                createdAt: vm.selectedConstructionObj.temporaryDentures[ic].createdAt
                            };
                        }
                    }

                    if (!vm.partialDentureData.material) {
                        vm.partialDentureData = {
                            material: temporaryDenture.material,
                            allComments: temporaryDenture.allComments,
                            shade: temporaryDenture.shade,
                            color: temporaryDenture.color,
                            colorSampling: temporaryDenture.colorSampling,
                            createdAt: temporaryDenture.createdAt,
                            otherMaterial: temporaryDenture.otherMaterial
                        };
                    }

                } else {
                    userService.getCurrentUser().then(function (result) {
                        var userProfile = result.data;
                        if (userProfile.constructionSetting) {
                            //get default construction
                            if (vm.tempTemporaryDentureData && vm.tempTemporaryDentureData.material) {
                                vm.partialDentureData = {
                                    material: vm.tempTemporaryDentureData.material,
                                    shade: vm.tempPartialDentureData.shade,
                                    allComments: vm.tempPartialDentureData.allComments,
                                    color: vm.tempPartialDentureData.color,
                                    createdAt: vm.tempPartialDentureData.createdAt,
                                    otherMaterial: vm.tempTemporaryDentureData.otherMaterial
                                };
                            }
                        }
                    });
                }
            }

        });

        /**
         * select and de select
         */
        function setMaterial(material) {
            if (material) {
                if (material === vm.partialDentureData.material) {
                    vm.partialDentureData.material = '';
                } else {
                    if (vm.selectedDentureType === 'partial denture') {
                        vm.tempPartialDentureData.material = material;
                    } else {
                        vm.tempTemporaryDentureData.material = material;
                    }
                    vm.partialDentureData.material = material;
                }
            }
        }

        /**
         * Save teeth construction
         */
        $scope.$watch('vm.partialDentureData', function (newVal, oldval) {
            if (vm.selectedDentureType === 'partial denture') {
                if ((((vm.partialDentureData.material === 'Other' && vm.partialDentureData.otherMaterial) ||
                    (vm.partialDentureData.material && vm.partialDentureData.material !== 'Other')) || vm.partialDentureData.comments) &&
                    (vm.partialDentureData.colorSampling || (vm.partialDentureData.shade && vm.partialDentureData.color))) {
                    //it is valid construction. lets save it
                    vm.partialDentureData.teethNo = vm.selectedTeeth;
                    vm.partialDentureData.type = 'partial denture';
                    vm.addUpdatePartialDenture(vm.partialDentureData, vm.selectedDentureType);
                }
            } else {
                // Check all the properties should be same as the 0th value
                if ((((vm.partialDentureData.material === 'Other' && vm.partialDentureData.otherMaterial) ||
                    (vm.partialDentureData.material && vm.partialDentureData.material !== 'Other')) || vm.partialDentureData.comments) &&
                    (vm.partialDentureData.colorSampling || (vm.partialDentureData.shade && vm.partialDentureData.color))) {
                    //it is valid construction. lets save it
                    vm.partialDentureData.teethNo = vm.selectedTeeth;
                    vm.partialDentureData.type = 'temporary denture';
                    vm.addUpdatePartialDenture(vm.partialDentureData, vm.selectedDentureType);
                }
            }
            if (!vm.orderIdToModify && !vm.partialDentureData.createdAt) {
                vm.partialDentureData.createdAt = new Date().getTime();
            }
        }, true);

        /**
         * Set Partial Denture steps
         * @param selectedStep
         */
        function setPartialDentureSteps(selectedStep) {
            if (vm.partialDentureSteps) {
                vm.partialDentureSteps.forEach(function (step) {
                    vm.partialDentureData[step] = (step === selectedStep);
                });
            }
        }

        /**
         * Method to print Upper / Lower jaw image
         */
        function printUpperLowerJawImage() {
            vm.isVisibleInPrint = true;
            printService.printElementFromID('PrintableImage').then(function () {
                vm.isVisibleInPrint = false;
            });
        }
    }

})();