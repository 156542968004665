/**
 * File service to upload files
 */
(function () {
    'use strict';

    angular.module('app').factory('fileService', fileService);

    fileService.$inject = [ 'Upload','API','$http' ];
    /**
     * function for the file Service
     * @param Upload
     * @param API
     * @param $http
     * @returns {{uploadFile: uploadFile, removeFile: removeFile}}
     */
    function fileService(Upload, API, $http) {

        /**
         * Variables for the service
         * @type {{uploadFile: uploadFile, removeFile: removeFile}}
         */
        var service = {
            uploadFile: uploadFile,
            removeFile: removeFile,
            getS3FileUrl: getS3FileUrl
        };

        return service;

        /**
         * Upload file method for uploading files
         * @param file
         * @param directory
         * @param isBase64
         * @returns {*}
         */
        function uploadFile(file, directory, isBase64) {
            return Upload.upload({
                url: API.API_BASE + 'file',
                data: {
                    file: file,
                    directory: directory,
                    isBase64: isBase64
                }
            });
        }

        /**
         * Remove method for removing files
         * @param file
         * @param directory
         * @returns {HttpPromise}
         */
        function removeFile(file,directory) {
            return $http.delete(API.API_BASE + 'file/' + file + '/' + directory);
        }

        /**
         * get s3 file base url + bucket name
         */
        function getS3FileUrl() {
            return $http.get(API.API_BASE + 's3-file-url');
        }
    }
})();