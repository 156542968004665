/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './show-labs-item.html'
/**
 * Connect lab module
 */
(function () {
    'use strict';
    angular.module('app').component('showLabsItem', {
        template: theTemplate,
        controller: ShowLabsItemController,
        controllerAs: 'vm',
        bindings: angular.extend({}, sessionStateBindings, {
            lab: "=",
            clinic: '='
        })
    });

    // Inject connect lab dependencies here
    ShowLabsItemController.$inject = [ '$filter', 'organizationService', 'toastr', 'logService' ];

    // Controller function fo connect lab module.
    function ShowLabsItemController($filter, organizationService, toastr, logService) {

        // Scope and other variables
        var vm = this;
        vm.openingDays = [];

        // Member functions
        vm.connectLab = connectLab;
        vm.disconnectLab = disconnectLab;

        function getLabDetails() {
            vm.openingDays = [];
            vm.lab.connected = false;
            for (var i in vm.lab.openingHours) {
                if (vm.lab.openingHours[i].active) {
                    vm.openingDays.push({ day: vm.lab.openingHours[i].day.substring(0, 3),value: i });
                }
            }

            if (vm.clinic && vm.clinic.connectedLabs && vm.clinic.connectedLabs.length) {
                vm.lab.connected = vm.clinic.connectedLabs.some(function (connectedLab) {
                    return connectedLab.lab && connectedLab.lab._id + '' === vm.lab._id + '';
                });
            }

            openForDay();

        }

        // Connect lab method to connect a lab
        function connectLab() {
            return organizationService.connectLab({ labId: vm.lab._id }).then(function (result) {
                toastr.success($filter('translate')('LAB_CONNECTED'));
                vm.lab.connected = true;
            }).catch(function (err) {
                logService.error('show-labs-item.component', 'connectLab', $filter('translate')('ERROR_IN_CONNECT_LAB') + ": " + JSON.stringify(err));
                toastr.error($filter('translate')('ERROR_IN_CONNECT_LAB'));
            });
        }

        function disconnectLab() {
            return organizationService.disconnectLab({ labId: vm.lab._id }).then(function (result) {
                toastr.success($filter('translate')('LAB_DIS_CONNECTED'));
                vm.lab.connected = false;
            }).catch(function (err) {
                logService.error('show-labs-item.component', 'disconnectLab', $filter('translate')('ERROR_IN_DISCONNECT_LAB') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_DISCONNECT_LAB'));
            });
        }

        vm.$onInit = function() {
            getLabDetails();
        };

        function openForDay() {
            vm.openDayString = '';
            if (vm.openingDays.length >= 5) {
                if (vm.openingDays.length === 7) {
                    vm.openDayString = $filter('translate')('MON') + '-' + $filter('translate')('SUN');
                } else {
                    for (var i in vm.openingDays) {
                        if (!vm.openDayString) {
                            vm.openDayString = $filter('translate')(vm.openingDays[i].day);
                        } else {
                            if (vm.openingDays[i].value - 1 === Number(vm.openingDays[i - 1].value)) {
                                if (vm.openDayString.charAt(vm.openDayString.length - 4) === '-') {
                                    vm.openDayString = vm.openDayString.substring(0, vm.openDayString.length - 3) + $filter('translate')(vm.openingDays[i].day);
                                } else {
                                    vm.openDayString = vm.openDayString + '-' + $filter('translate')(vm.openingDays[i].day);
                                }
                            } else {
                                vm.openDayString = vm.openDayString + ',' + $filter('translate')(vm.openingDays[i].day);
                            }
                        }
                    }
                }
            }
        }
    }

})();
