import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import template from './spinner.html';

/**
 * This spinner component can be used in two ways.
 * - Include spinner as "@ViewChild('spinner') spinner: SpinnerComponent;" and <spinner #spinner></spinner> in template.
 *   Then use spinner.show() and spinner.hide().
 * or
 * - Use <spinner [spin]="isBusy"></spinner> in the template and set the "isBusy" boolean in the component.
 *
 * Both ways are fully valid.
 */
@Component({
  selector: 'spinner',
  template: template
})
export class SpinnerComponent implements OnChanges {
  @Input()
  spin: boolean;

  constructor(private spinner: NgxSpinnerService) {}

  public show(): void {
    this.spinner.show();
  }

  public hide(): void {
    this.spinner.hide();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.spin) {
      if (this.spin) {
        this.show();
      } else {
        this.hide();
      }
    }
  }
}
