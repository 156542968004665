import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'organizationNumberPipe'
})
export class OrganizationNumberPipe implements PipeTransform {
  private isValidWithoutHyphen = new RegExp(/^\d{10}$/);

  transform(value: string): string {
    const orgNumber = value.trim();
    if (this.isValidWithoutHyphen.test(orgNumber)) {
      return orgNumber.substring(0, 6) + '-' + orgNumber.substring(6, 10);
    }
    return value;
  }
}
