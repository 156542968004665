import { ResourceService } from '../core/resource.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.constant';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { Status } from '../../models/status.model';
import { Category, SubCategory } from '../../models/organization.model';

@Injectable()
export class CategoryService extends ResourceService {
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
  }

  getAllMainCategories(): Promise<{ data: Category[] }> {
    return this.get(`${API.API_BASE}categories`, undefined, this.apiInterceptor);
  }

  getCategoryTree(): Promise<{ data: Category[] }> {
    return this.get(`${API.API_BASE}category-tree`, undefined, this.apiInterceptor);
  }

  saveCategory(
    category: Partial<Category> | Partial<SubCategory>
  ): Promise<{ _id: string; message: string }> {
    return this.post(
      `${API.API_BASE}categories`,
      category,
      undefined,
      this.apiInterceptor
    );
  }

  updateCategory(
    category: Partial<Category> | Partial<SubCategory>
  ): Promise<Partial<Status>> {
    const params = new HttpParams().set('id', category._id);

    return this.put(
      `${API.API_BASE}categories/:id`,
      category,
      params,
      this.apiInterceptor
    );
  }

  deleteCategory(id: string): Promise<Partial<Status>> {
    const params = new HttpParams().set('id', id);

    return this.delete(`${API.API_BASE}categories/:id`, params, this.apiInterceptor);
  }
}
