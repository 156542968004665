/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-construction.html'
(function () {
  'use strict';
  angular.module('app').component('orderNewConstruction', {
    template: theTemplate,
    controller: orderConstructionsController,
    controllerAs: 'vm',
    bindings: angular.extend({}, sessionStateBindings, {
      selectedConstructions: '=',
      selectedLab: '=',
      newOrderObj: '=',
      orderIdToModify: '=',
      showSpinner: '=',
      mandatoryComments: '<'
    })
  });
  orderConstructionsController.$inject = ['$scope', '$rootScope', 'eventService'];

  function orderConstructionsController($scope, $rootScope, eventService) {
    var vm = this;
    vm.commentsArray = [];
    vm.isAlreadyCheckedForTypes = false;

    eventService.on('settingTypesFetched', (_, fixedSettingTypes) => {
      if (!vm.isAlreadyCheckedForTypes) {
        const fixedTypes = fixedSettingTypes.filter((type) => type.name !== 'other');
        const isActivated = fixedTypes.some((type) => type.active);
        if (!isActivated) {
          vm.selectedConstructions.type = 'removable';
        }
        vm.isAlreadyCheckedForTypes = true;
      }
    });

    function getConstructionComments() {
      if (vm.newOrderObj && vm.newOrderObj.constructions) {
        vm.commentsArray = Object.keys(
          vm.newOrderObj.constructions.abutmentTeeth
        ).reduce(function (result, constructionKey) {
          var constructComments = vm.newOrderObj.constructions.abutmentTeeth[
            constructionKey
          ]
            .filter(function (construct) {
              return !!construct.comments;
            })
            .map(function (construct) {
              return construct.comments;
            });
          return result.concat(constructComments);
        }, []);
      }
    }

    $scope.$watch(
      'vm.newOrderObj',
      function () {
        getConstructionComments();
      },
      true
    );

    // listen the change on construction when we click on the othe construction type (fixed or removable)
    $scope.$on('changeConstructionType', function (result, data) {
      vm.selectedConstructions.type = data.type;
      setTimeout(function () {
        $rootScope.$broadcast('selectedRemovableConstructionType', {
          selectedConstructionType: data.selectedConstructionType,
          tooth: data.tooth,
          isUpper: data.isUpper
        });
      }, 100);
    });
  }
})();
