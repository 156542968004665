import { Inject, Injectable } from '@angular/core';
import { ResourceInterceptor } from './resource.service';
import { SessionService } from './session.service';
import { TokenPayload, TokenLoginResponse } from '../../models/token-payload.model';
import * as jwtDecode from 'jwt-decode';
import * as Cookies from 'js-cookie';

@Injectable()
export class TokenService {
  private static readonly SESSION_TOKEN_KEY: string = 'token';

  constructor(private sessionService: SessionService) {}

  getToken(): string {
    this.sessionService.setItem(
      TokenService.SESSION_TOKEN_KEY,
      Cookies.get(TokenService.SESSION_TOKEN_KEY)
    );
    return Cookies.get(TokenService.SESSION_TOKEN_KEY);
  }

  getPayload(): TokenPayload {
    return jwtDecode(this.getToken());
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  logout(): void {
    this.sessionService.removeItem(TokenService.SESSION_TOKEN_KEY);
  }
}
