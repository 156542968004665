import {
  OnChanges,
  Component,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { CategoryService } from '../../../services/manage-actions/category.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import template from './sub-category-modal.html';
import { SubCategory } from '../../../models/organization.model';
import { Category } from '../../../models/category.model';

@Component({
  selector: 'sub-category-modal',
  template: template
})
export class SubCategoryModalComponent implements OnChanges {
  @Output() getCategoryTree: EventEmitter<void> = new EventEmitter<void>();
  @Input() subCategory: Partial<SubCategory> = {};
  @Input() categories: Category[] = [];
  isValidHexCode: boolean = false;

  constructor(
    private categoryService: CategoryService,
    private translationsService: TranslationsService,
    private logService: LogService,
    private toastr: ToastrService
  ) {}

  ngOnChanges(): void {
    if (!this.subCategory) {
      this.subCategory = {};
    }
    this.validateHexCode(this.subCategory.textColorCode);
    this.validateHexCode(this.subCategory.colorCode);
  }

  closeSubCategoryModal(): void {
    this.subCategory = {};
    $('#sub-category-modal').modal('hide');
  }

  saveUpdateSubCategory(): Promise<void> {
    if (this.subCategory._id) {
      return this.categoryService
        .updateCategory(this.subCategory)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('CATEGORY_UPDATED_SUCCESSFULLY')
          );
          this.getCategoryTree.emit();
        })
        .catch(async (err) => {
          this.logService.error(
            'main-category-modal.component',
            'addUpdateSubCategory',
            (await this.translationsService.get('ERROR_IN_UPDATING_CATEGORY')) +
              ': ' +
              err
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_UPDATING_CATEGORY')
          );
        });
    }
    return this.categoryService
      .saveCategory(this.subCategory)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('CATEGORY_CREATED_SUCCESSFULLY')
        );
        this.getCategoryTree.emit();
      })
      .catch(async (err) => {
        this.logService.error(
          'main-category-modal.component',
          'addUpdateSubCategory',
          (await this.translationsService.get('ERROR_IN_CREATING_CATEGORY')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_CREATING_CATEGORY')
        );
      });
  }

  deleteSubCategory(): Promise<void> {
    return this.categoryService
      .deleteCategory(this.subCategory._id)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('CATEGORY_DELETED_SUCCESSFULLY')
        );
        this.getCategoryTree.emit();
      })
      .catch(async (err) => {
        this.logService.error(
          'main-category-modal.component',
          'deleteSubCategory',
          (await this.translationsService.get('ERROR_IN_DELETING_CATEGORY')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_DELETING_CATEGORY')
        );
      });
  }

  validateHexCode(hexCode: string): void {
    this.isValidHexCode = /^#[0-9A-F]{6}$/i.test(hexCode);
  }
}
