import {
  Component,
  Inject,
  forwardRef,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import template from './scheduled-transports-actions.html';
import { FormControl } from '@angular/forms';
import { Moment } from 'moment';
import { TaDatesService } from '../../../services/ta-dates.service';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NewFrontendNavigateService } from '../../../../../services/new-frontend-navigate/new-frontend-navigate.service';

@Component({
  selector: 'scheduled-transports-actions',
  template: template,
  providers: [{ provide: Document, useValue: document }]
})
export class ScheduledTransportsActionsComponent implements OnInit {
  @Input() selectedDate: Date;
  @Output() selectedDateChange = new EventEmitter<Date>();
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<Date>>;
  @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();
  dateControl = new FormControl(new Date());
  isPrinting = false;
  searchText = '';

  constructor(
    private readonly taDatesService: TaDatesService,
    private readonly document: Document,
    private navigateService: NewFrontendNavigateService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  ngOnInit() {
    this.dateControl.setValue(this.selectedDate);
    this.dateControl.valueChanges.subscribe((pickedDate: Moment) => {
      this.selectedDateChange.emit(pickedDate.toDate());
    });
  }

  searchTextChange(event) {
    this.searchTextChanged.emit(event);
  }

  validServiceDateFilter = (date: Moment | null): boolean => {
    if (this.taDatesService.isTransportServiceAvailable(date || moment())) {
      return true;
    }
    return false;
  };

  onAddTransport(type: string) {
    this.navigateService.navigate(`/add-transport/${type.toLocaleLowerCase()}`);
  }

  onForwardOneDay() {
    const date = moment(this.selectedDate);
    this.updateDate(date.add(1, 'days').toDate());
  }

  onBackOneDay() {
    const date = moment(this.selectedDate);
    this.updateDate(date.subtract(1, 'days').toDate());
  }

  dateSelected(date: Date) {
    this.updateDate(moment(date).toDate());
  }

  onPrint() {
    this.isPrinting = true;
    window.top.postMessage(
      {
        eventType: 'transport-schedule-pdf',
        selectedDate: this.selectedDate
      },
      '*'
    );
    this.isPrinting = false;
  }

  private updateDate(date: Date) {
    this.selectedDate = date;
    this.dateControl.setValue(this.selectedDate);
    this.selectedDateChange.emit(this.selectedDate);
  }
}
