import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { User, LimitedDentist } from '../../models/user.model';
import {
  userListTemplate,
  connectedToAppTemplate,
  createLimitedDentistTemplate,
  createDentistAccountTemplate
} from './users.graphql';
import { TranslationsService } from '../../services/translations/translations.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersGraphqlService {
  constructor(
    private readonly apollo: Apollo,
    private readonly translationsService: TranslationsService
  ) {}

  getUsers(filters): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this.apollo
        .query({
          query: userListTemplate,
          variables: filters,
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        })
        .subscribe(({ data, errors }) => {
          if (errors?.length) {
            reject(errors[0]);
            return;
          }
          resolve(cloneDeep(data['users']));
        });
    });
  }

  connectedToApp(): Observable<boolean> {
    return this.apollo
      .subscribe({
        query: connectedToAppTemplate,
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      })
      .pipe(
        map((res) => JSON.parse(JSON.stringify(res.data['connectedToApp']))),
        catchError((error) => {
          console.log(error, 'error');
          return of(null);
        })
      );
  }

  createLimitedDentist(user: Partial<LimitedDentist>): Promise<LimitedDentist> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: createLimitedDentistTemplate,
          variables: user,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['createLimitedDentist']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  createDentistAccount(user: Partial<LimitedDentist>): Promise<LimitedDentist> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: createDentistAccountTemplate,
          variables: user,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['createDentistAccount']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
