import { Injectable } from '@angular/core';
import { TransportAppModule } from '../transport-app.module';
import { OrganizationService } from '../../../services/clinic/organization.service';
import { LogisticsService } from '../../../services/logistics/logistics.service';
import { Organization } from '../../../models/organization.model';
import { LogisticGraphqlService } from '../../../graphql-services/logistics/logistics.graphql.service';
import { Logistic } from '../../../models/logistic.model';
import { TaDatesService } from './ta-dates.service';
import * as moment from 'moment';

@Injectable({
  providedIn: TransportAppModule
})
export class TransportAppService {
  constructor(
    private readonly logisticsService: LogisticsService,
    private readonly organizationService: OrganizationService,
    private readonly logisticGqlService: LogisticGraphqlService,
    private readonly taDatesService: TaDatesService
  ) {}

  async loadCurrentLab(): Promise<Organization> {
    return (await this.organizationService.getCurrentLab()).data;
  }

  async loadTransportClinics(): Promise<Organization[]> {
    const clinics = (await this.logisticsService.getConnectedAllClinics()).data;
    return clinics.sort((a, b) => a.name.localeCompare(b.name));
  }

  async loadTransports(date: Date): Promise<MappedTransportClinic[]> {
    // Get pickups and deliveries both on selected date
    const [clinics, pickupsAndDeliveries] = await Promise.all([
      this.loadTransportClinics(),
      this.logisticGqlService.getDeliveriesAndPickupsForDate(
        moment(date).format('YYYY-MM-DD'),
        true
      )
    ]);

    return this.mapClinics(clinics, pickupsAndDeliveries);
  }

  async fetchExistingTransportOrder(
    date: Date,
    type: string,
    clinic: Organization
  ): Promise<Logistic | Logistic[] | null> {
    const mappedClinics = await this.loadTransports(date);
    for (const mappedClinic of mappedClinics) {
      if (mappedClinic.clinic._id === clinic._id) {
        if (type === 'PICKUP' && mappedClinic.pickup) {
          return mappedClinic.pickup;
        } else if (type === 'DELIVERY' && mappedClinic.delivery) {
          return mappedClinic.delivery;
        }
      }
    }
    return null;
  }

  createEmptyTransportOrder(): Partial<Logistic> {
    return {
      noOfRequestedPackages: 1,
      transportDate: new Date(),
      deadline: 'No Deadline'
    };
  }

  async placeTransportOrder(
    transportType: string,
    lab: Organization,
    selectedClinic: Organization,
    transportOrder: Partial<Logistic>
  ) {
    const fullOrder = this.buildTransportOrder(
      transportType,
      lab,
      selectedClinic,
      transportOrder
    );
    if (transportType == 'PICKUP') {
      await this.logisticGqlService.schedulePickup(fullOrder);
      return;
    }
    await this.logisticGqlService.scheduleDelivery(fullOrder);
  }

  async updateTransportOrder(transportOrder: Logistic) {
    if (transportOrder.type === 'pickup') {
      await this.logisticGqlService.updatePickup(transportOrder);
      return;
    }
    await this.logisticGqlService.updateDelivery(transportOrder);
  }

  async revokeTransportOrder(transportOrder: Logistic) {
    await this.logisticGqlService.deleteTransport(transportOrder._id);
  }

  private buildTransportOrder(
    transportType: string,
    lab: Organization,
    selectedClinic: Organization,
    transportOrder: Partial<Logistic>
  ): Logistic {
    transportOrder.clinic = selectedClinic._id;
    transportOrder.lab = lab._id;
    transportOrder.type = transportType.toLocaleLowerCase();
    transportOrder.clinicName = selectedClinic.name;
    transportOrder.clinicContact = selectedClinic.contact;
    transportOrder.local = selectedClinic.local;
    return transportOrder as Logistic;
  }

  private mapClinics(clinics: Organization[], pickupsAndDeliveries: Logistic[]) {
    const mappedClinics: MappedTransportClinic[] = [];
    for (const clinic of clinics) {
      const pickup = this.findPickUpTransport(clinic, pickupsAndDeliveries, 'PICKUP');
      const delivery = this.findDeliveryTransport(clinic, pickupsAndDeliveries, 'DELIVERY');
      if (pickup.length || delivery) {
        mappedClinics.push({
          clinic,
          pickup,
          delivery
        });
      }
    }
    return mappedClinics;
  }

  private findPickUpTransport(
    clinic: Organization,
    transports: Logistic[],
    transportType: string
  ) {
    return transports.filter((transport) => {
      return transport.clinic == clinic._id && transport.type === transportType;
    });
  }

  private findDeliveryTransport(
    clinic: Organization,
    transports: Logistic[],
    transportType: string
  ) {
    return transports.find((transport) => {
      return transport.clinic == clinic._id && transport.type === transportType;
    });
  }
}

export type MappedTransportClinic = {
  clinic: Organization;
  pickup?: Logistic[];
  delivery?: Logistic;
};
