export enum LanguageCode {
  EN = 'en',
  SE = 'se'
}

export interface Language {
  name: string;
  value: LanguageCode;
  url: string;
}
