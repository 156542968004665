import { Component, Input, OnInit } from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../../../../app.constant';
import { SubCategory } from '../../../../../../../../models/organization.model';
import { OrganizationSettingService } from '../../../../../../../../services/organization-setting/organization-setting.service';
import template from './occlusion.html';

@Component({
  selector: 'occlusion',
  template
})
export class OcclusionComponent implements OnInit {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedOcclusion: string;

  occlusionsSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.OCCLUSION_TYPE;
  occlusions: string[];

  constructor(
    private readonly organizationSettingService: OrganizationSettingService
  ) {}

  ngOnInit(): void {
    this.occlusions = this.organizationSettingService.getCustomerSetting(
      this.occlusionsSettingName
    );
  }
}
