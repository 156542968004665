// Refactor later when we migrate delivery component
import { Injectable } from '@angular/core';
import { clone, cloneDeep } from 'lodash';
import { DATE } from '../../../app.constant';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  getNumOfUnits(numberOfUnits) {
    if (!numberOfUnits) {
      return [];
    }
    if (numberOfUnits instanceof Array) {
      return numberOfUnits;
    }
    return Object.keys(numberOfUnits).map((actionId) => {
      return {
        actionId,
        header: numberOfUnits[actionId].header,
        numberOfUnits: numberOfUnits[actionId].numberOfUnits
      };
    });
  }

  private getMaterialQuantity(costPerTooth, construction, unit) {
    const TEETH_RANGE_QUANTITY = 2;
    const TEETH_RANGE_START = 18;
    const TEETH_RANGE_END = 38;
    if (costPerTooth && construction.teethNo?.length) {
      return construction.teethNo.length;
    } else if (
      costPerTooth &&
      construction.teethRange?.start === TEETH_RANGE_START &&
      construction.teethRange?.end === TEETH_RANGE_END
    ) {
      return TEETH_RANGE_QUANTITY;
    } else {
      return unit ? unit.numberOfUnits : 1;
    }
  }

  private getMaterialsWithQuantity(construction) {
    return construction.actions.reduce((res, action) => {
      const unit = construction.numberOfUnits.find(
        (nUnit) => action._id === nUnit.actionId
      );
      if (action.materialGroup && action.materialGroup.materials) {
        const mats = cloneDeep(action.materialGroup.materials);
        mats.forEach((material) => {
          material.quantity = this.getMaterialQuantity(
            action.costPerTooth,
            construction,
            unit
          );
          material.vat = action.vat || 0;
          material.actionId = action._id;
        });
        return res.concat(mats);
      }
      return res;
    }, []);
  }

  private getMaterialsForPriceDiff(construction, priceDiff) {
    construction.numberOfUnits = this.getNumOfUnits(construction.numberOfUnits);
    const materials = this.getMaterialsWithQuantity(construction);
    const mats = materials.filter(
      (mat) => construction.materialName === mat.material
    );

    mats.forEach((mat) => {
      const pDiff = priceDiff.find((diff) => mat._id === diff.material);
      if (pDiff) {
        mat.priceDiff = pDiff.priceDiff;
      }
    });
    return mats;
  }

  getMaterialPriceDiff(constructions, priceDiff) {
    return constructions.reduce((res, construction) => {
      const mats = this.getMaterialsForPriceDiff(construction, priceDiff);
      return res.concat(mats);
    }, []);
  }

  getMaterialWithName(materials, matGroups) {
    materials.forEach((material) => {
      const matGroup = matGroups.find((mg) => mg._id === material.material);
      if (matGroup) {
        material.materialName = matGroup.material;
      }
    });
    return materials;
  }

  getPriceDiff(lab) {
    if (!lab.materialPriceDifference) {
      lab.materialPriceDifference = [];
    }
    return lab.materialPriceDifference.reduce(
      (res, matPriceDiff) => res.concat(matPriceDiff.materials),
      []
    );
  }

  getSelectedMaterials(constructions) {
    return constructions.reduce((res, construction) => {
      if (construction.material) {
        res.push({
          material: construction.material
        });
      }
      return res;
    }, []);
  }

  calculateLabRatings(labs, clinicId) {
    labs
      .filter((lab) => lab.lab)
      .forEach((lab) => {
        const stat = lab.lab.statistics.find((stats) => stats.clinicId === clinicId);
        const PERCENT = 100;
        lab.ratePercent =
          stat && stat.ratings
            ? (stat.ratings.satisfied / stat.numOrders) * PERCENT
            : 0;
        lab.totalRating = stat ? stat.numOrders : 0;
      });
  }

  checkPerfectDeliveryDateIsHoliday(day, holidayDays, inActiveDays) {
    const delDay = day;
    let condition;
    let inActivityDays;
    const SUNDAY_WEEK_NUM = 7;
    if (inActiveDays.length === 0 || inActiveDays.length === SUNDAY_WEEK_NUM) {
      condition = holidayDays.indexOf(delDay) !== -1;
      inActivityDays = [];
    } else {
      condition =
        holidayDays.indexOf(delDay) !== -1 ||
        inActiveDays.indexOf(new Date(delDay).getDay()) !== -1;
      inActivityDays = inActiveDays;
    }
    const M_SECONDS_IN_DAY = 86400000;
    return condition
      ? this.checkPerfectDeliveryDateIsHoliday(
          delDay + M_SECONDS_IN_DAY,
          holidayDays,
          inActivityDays
        )
      : delDay;
  }

  getActionsForPriceDiff(construction) {
    return construction.actions.map((action) => {
      action.warranty = construction.warranty;
      action.teethNo = construction.teethNo;
      action.teethRange = construction.teethRange;
      if (!construction.numberOfUnits) {
        action.numberOfUnits = 1;
        return clone(action);
      }
      // in case when order is modified
      if (construction.numberOfUnits instanceof Array) {
        const actionObj = construction.numberOfUnits.find(
          (numberOfUnits) => action._id === numberOfUnits.actionId
        );
        if (!actionObj) {
          action.numberOfUnits = 1;
          return clone(action);
        }
        action.numberOfUnits = actionObj.numberOfUnits;
      } else {
        const actionId = Object.keys(construction.numberOfUnits).find(
          (id) => action._id === id
        );
        if (!actionId) {
          action.numberOfUnits = 1;
          return clone(action);
        }
        action.numberOfUnits = construction.numberOfUnits[actionId].numberOfUnits;
      }
      return clone(action);
    });
  }

  getSelectedActions(constructions) {
    return constructions.reduce((result, construction) => {
      if (!construction.actions) {
        return result;
      }
      const actions = this.getActionsForPriceDiff(construction);
      return result.concat(actions);
    }, []);
  }

  getPriceList(priceList) {
    return priceList.reduce((result, price) => result.concat(price.actions), []);
  }

  checkForStartDay(holidayDays, startDay, inActiveDays) {
    return (
      holidayDays.indexOf(startDay) === -1 &&
      inActiveDays.indexOf(new Date(startDay).getDay()) === -1
    );
  }

  checkForInActiveDays(inActiveDays, SUNDAY_WEEK_NUM) {
    return inActiveDays.length === 0 || inActiveDays.length === SUNDAY_WEEK_NUM;
  }

  countDays(daysRequired, holidayDays, inActiveDays) {
    let startDay = new Date().setHours(0, 0, 0, 0);
    let days = daysRequired;
    const SUNDAY_WEEK_NUM = 7;
    while (days > 0) {
      if (this.checkForInActiveDays(inActiveDays, SUNDAY_WEEK_NUM)) {
        days = holidayDays.indexOf(startDay) === -1 ? days - 1 : days;
      } else {
        days = this.checkForStartDay(holidayDays, startDay, inActiveDays)
          ? days - 1
          : days;
      }
      const M_SECONDS_IN_DAY = 86400000;
      startDay += M_SECONDS_IN_DAY;
    }
    return this.checkPerfectDeliveryDateIsHoliday(
      startDay,
      holidayDays,
      inActiveDays
    );
  }

  getDaysForDelivery(connectedLabPriceList, newOrderObj) {
    let daysForDelivery = 0;

    if (connectedLabPriceList && newOrderObj.allConstructions?.length > 0) {
      //get all selection action from constructions
      const selectedActions = this.getSelectedActions(newOrderObj.allConstructions);

      //get all actions from priceList array.
      const allActions = this.getPriceList(connectedLabPriceList);
      // loop over all actions to find total price and delivery time of selected actions.
      if (selectedActions?.length) {
        allActions.forEach((action) => {
          selectedActions
            .filter((selectedAction) => action.action === selectedAction._id)
            .forEach(() => {
              if (daysForDelivery === 0 || action.deliveryTime >= daysForDelivery) {
                daysForDelivery = action.deliveryTime;
              }
            });
        });
      }
    }
    return daysForDelivery;
  }

  getInActiveDays(openingHours) {
    const inActiveDays = [];
    openingHours.forEach((dayObj, index) => {
      if (!dayObj.active) {
        let day = index + 1;
        const SUNDAY_WEEK_NUM = 7;
        day = day === SUNDAY_WEEK_NUM ? 0 : day;
        inActiveDays.push(day);
      }
    });
    return inActiveDays;
  }

  updatePerfectDeliveryDate(
    currentSelectedLab,
    newOrderObj,
    deliveryInfo,
    additionalDeliveryDate,
    connectedLabPriceList
  ) {
    if (!currentSelectedLab) {
      return {};
    }
    let perfectDeliveryDate = new Date();
    let daysForDelivery = this.getDaysForDelivery(
      connectedLabPriceList,
      newOrderObj
    );
    if (additionalDeliveryDate) {
      daysForDelivery += additionalDeliveryDate;
    }

    // inactive days in a week
    const inActiveDays = this.getInActiveDays(currentSelectedLab.openingHours || []);
    if (!currentSelectedLab.holidayDays) {
      currentSelectedLab.holidayDays = [];
    }
    const holidays = currentSelectedLab.holidayDays.concat(
      currentSelectedLab.unavailableLabDates || []
    );

    perfectDeliveryDate = this.countDays(
      daysForDelivery + 1,
      holidays,
      inActiveDays
    );
    deliveryInfo.date = moment(perfectDeliveryDate).format(DATE.FORMAT);
    return {
      deliveryInfo,
      newOrderObj,
      perfectDeliveryDate
    };
  }
}
