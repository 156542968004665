import { Component, forwardRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import {
  CustomerService,
  PersonalNumberFormat
} from '../../services/customer/customer.service';
import { CacheService } from '../../services/core/cache.service';
import { StateService } from '@uirouter/angularjs';
import template from './bankid-login.html';
import { Organization } from '../../models/organization.model';
import { OrganizationService } from '../../services/clinic/organization.service';

export enum BankIdSigninPreference {
  SAME_DEVICE = 'same',
  OTHER_DEVICE = 'other'
}
@Component({
  selector: 'bankid-login',
  template: template
})
export class BankIdLoginComponent implements OnInit {
  public signInPreference: BankIdSigninPreference;
  public personalIdentityNumber: string;
  isPersonalIdentityNumberValid: boolean = true;
  labId: string;
  isFramed = false;
  lab: Organization;


  constructor(
    public cacheService: CacheService,
    private toastr: ToastrService,
    private logService: LogService,
    private translationsService: TranslationsService,
    private customerService: CustomerService,
    private readonly organizationService: OrganizationService,
    private readonly renderer: Renderer2,    
    @Inject(forwardRef(() => '$state')) private $state: StateService,
    @Inject(forwardRef(() => '$stateParams')) private $stateParams: any
  ) {}

  ngOnInit(): void {
    if (this.$state.params.personal_number) {
      this.personalIdentityNumber = this.$state.params.personal_number;
    }
    this.labId = this.$stateParams.labId;
    if (this.labId) {
      this.isFramed = this.$stateParams.framed === 'true';
      this.getLabLogoImage();
      if (this.isFramed) {
        this.renderer.addClass(document.body, 'framed-limited-dentist-form');
      }
    }
  }

  getLabLogoImage() {
    this.organizationService
      .getLabLogo({
        labId: this.labId
      })
      .then((result) => {
        this.lab = result.data;
      })
      .catch((err) => {
        this.logService.error('getLabLogoImage', 'ERROR_IN_GETTING_LABS', err);
      });
  }

  validatePersonalIdNumber(personalIdNumber: string): void {
    this.personalIdentityNumber = personalIdNumber;
    this.isPersonalIdentityNumberValid = this.customerService.validatePersonalNumber(
      personalIdNumber,
      false
    );
  }

  signIn(): void {
    const params: any = {
      federation: 'bankid-' + this.signInPreference,
      return_to: this.$state.params.return_to,
      return_params: this.$state.params.return_params
    };

    if (this.personalIdentityNumber) {
      const formattedPersonalNumber = this.customerService.formatPersonalNumber(
        this.personalIdentityNumber,
        PersonalNumberFormat.FULL_NODASH
      );
      params.personal_number = formattedPersonalNumber;
    }

    this.$state.go('federated-login', params);
  }
}
