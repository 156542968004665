import { Input, Component } from '@angular/core';
import { User } from '../../../models/user.model';
import template from './dentist-info-modal.html';

@Component({
  selector: 'dentist-info-modal',
  template: template
})
export class DentistInfoModalComponent {
  @Input() dentist: Partial<User> = {};
}
