import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Inject
} from '@angular/core';
import { OrganizationService } from '../../../services/clinic/organization.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../../services/core/log.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { Organization } from '../../../models/organization.model';

import template from './lab-card-item.html';

@Component({
  selector: 'lab-card-item',
  template: template
})
export class LabCardItemComponent {
  @Output() onLabEdit: EventEmitter<Organization> = new EventEmitter<
    Organization
  >();
  @Input() lab: Organization;

  constructor(
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private logService: LogService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  // set active/inactive status of lab
  toggleLabStatus(lab: Organization): Promise<void> {
    lab.active = !lab.active;
    return this.organizationService
      .updateOrganization(lab._id, { active: lab.active })
      .then(async (result) => {
        this.lab.active = lab.active;
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
      })
      .catch(async (err) => {
        this.logService.error(
          'lab-card-item.component',
          'toggleLabStatus',
          (await this.translationsService.get('ERROR_IN_UPDATE_LAB')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_UPDATE_LAB')
        );
      });
  }

  goToSettings(): void {
    this.$state.go('app.customer-settings', { id: this.lab._id });
  }

  // get data for update
  editLab(): void {
    this.onLabEdit.emit(this.lab);
  }
}
