import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { Directive } from '@angular/core';
import { CustomerService } from '../services/customer/customer.service';

@Directive({
  selector: '[personalNumber][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PersonalNumberValidator,
      multi: true
    }
  ]
})
export class PersonalNumberValidator implements Validator {
  constructor(private customerService: CustomerService) {}

  validate(control: AbstractControl): ValidationErrors | null {
    const isValid =
      !control.value ||
      this.customerService.validatePersonalNumber(control.value, false);

    if (isValid) {
      return null;
    } else {
      return {
        validateEqual: false
      };
    }
    return undefined;
  }
}
