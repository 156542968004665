/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './logged-out.html'
(function () {
  'use strict';
  angular.module('app').component('loggedOut', {
    template: theTemplate,
    controller: loggedOutController,
    controllerAs: 'vm',
    bindings: {}
  });

  loggedOutController.$inject = [
    '$location',
    'currentUserService',
    '$filter',
    'logoImageService',
    '$state',
    'cacheService',
    'logService',
    'toastr'
  ];

  function loggedOutController(
    $location,
    currentUserService,
    $filter,
    logoImageService,
    $state,
    cacheService,
    logService,
    toastr
  ) {
    if (window.top != window) {
      /* break us out of any containing iframes */
      window.top.location = window.location;
      return;
    }
    var vm = this;
    vm.federation;
    vm.cacheService = cacheService;

    vm.redirectToLogin = function () {
      $state.go('federated-login', { federation: vm.federation });
    };

    vm.$onInit = function () {
      if ($location.search().federation) {
        vm.federation = $location.search().federation;
      } else if ($location.search().labId) {
        currentUserService.logout(null, null, null, {
          labId: $location.search().labId
        });
      } else {
        currentUserService.logout();
      }

      logoImageService.getLoginLogoImage({ type: 'login' }).then(function (data) {
        if (data && data.imageUrl) {
          vm.logoImage = data.imageUrl;
        }
      });
    };
  }
})();
