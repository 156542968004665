/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './post-construction.html'
(function () {
    'use strict';
    angular.module('app').component('postConstruction', {
        template: theTemplate,
        controller: postConstructionController,
        controllerAs: 'vm',
        bindings: {
            constructionSettings: '=',
            selectedTeeth: '=',
            addUpdatePost: '=',
            removePost: '=',
            orderIdToModify: '=',
            singlePostData: '=',
            selectedConstructionObj: '=',//currently selected constructions objects
            tempPostData: "=",
            postCommentsArray: '='
        }
    });

    // Services to be injected here for this component
    postConstructionController.$inject = [ '$scope', 'DATE' ];

    function postConstructionController($scope, DATE) {
        // Scope variable
        var vm = this;
        vm.setMaterial = setMaterial;
        vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;
        // Variables used in this controllers
        if (!vm.singlePostData) {
            vm.singlePostData = {};
        }

        //functions to be used in this controller
        vm.removePostTeethConstruction = removePostTeethConstruction;
        vm.savePostTeethConstruction = savePostTeethConstruction;

        /**
         * Method to remove post teeth construction
         */
        function removePostTeethConstruction() {
            if (vm.removePost) {
                vm.removePost(vm.selectedTeeth);
            }
            vm.selectedTeeth = '';
        }
        /**
         * select and de select material
         */
        function setMaterial(material) {
            if (material) {
                if (material === vm.singlePostData.material) {
                    vm.singlePostData.material = '';
                } else {
                    vm.tempPostData.material = material;
                    vm.singlePostData.material = material;
                }
            }
        }

        // Watch Selected Construction type object for the old construction data.
        $scope.$watch('vm.selectedTeeth',function() {
            //if we previously created any post then use that else create empty
            if (vm.selectedConstructionObj && vm.selectedConstructionObj.posts && vm.selectedConstructionObj.posts.length > 0) {
                var post = vm.selectedConstructionObj.posts[vm.selectedConstructionObj.posts.length - 1];
                //get old construction
                for (var p in vm.selectedConstructionObj.posts) {
                    if (vm.selectedTeeth === vm.selectedConstructionObj.posts[p].teethNo) {
                        vm.singlePostData = {
                            material: vm.selectedConstructionObj.posts[p].material,
                            otherMaterial: vm.selectedConstructionObj.posts[p].otherMaterial,
                            allComments: vm.selectedConstructionObj.posts[p].allComments
                        };
                    }
                }

                if (!vm.singlePostData || !vm.singlePostData.material) {
                    vm.singlePostData = {
                        material: post.material,
                        otherMaterial: post.otherMaterial,
                        allComments: post.allComments
                    };
                }

            } else {
                if (vm.tempPostData) {
                    vm.singlePostData = {
                        material: vm.tempPostData.material,
                        otherMaterial: vm.tempPostData.otherMaterial,
                        allComments: vm.tempPostData.allComments
                    };
                }

            }
        });

        /**
         * Save Post construction
         */
        $scope.$watch('vm.singlePostData', function (newVal, oldval) {
            if (vm.singlePostData && vm.singlePostData.material) {
                if (((vm.singlePostData.material === 'Other' && vm.singlePostData.otherMaterial) ||
                        (vm.singlePostData.material && vm.singlePostData.material !== 'Other')) ||
                    vm.singlePostData.comments) {
                    //it is valid construction. lets save it
                    vm.singlePostData.teethNo = vm.selectedTeeth;
                    vm.singlePostData.type = 'post';
                    vm.addUpdatePost(vm.singlePostData);
                }
                if (!vm.orderIdToModify && !vm.singlePostData.createdAt) {
                    vm.singlePostData.createdAt = new Date().getTime();
                }
            }
        }, true);

        /**
         * Method to add post teeth data
         */
        function savePostTeethConstruction() {
            //get whole data here. add validations as well.
            vm.singlePostData.teethNo = vm.selectedTeeth;
            if (vm.addUpdatePost) {
                vm.addUpdatePost(vm.singlePostData);
            }
            vm.selectedTeeth = '';
        }
    }

})();