import {
  Component,
  AfterViewChecked,
  OnInit,
  Input,
  Inject,
  forwardRef
} from '@angular/core';
import template from './order-details-subnav.html';
import { SubNavService } from '../../../../services/sub-nav/sub-nav.service';
import { PermissionService } from '../../../../services/core/permission.service';
import { CurrentUserService } from '../../../../services/users/current-user.service';
import { ORDER_VERSION } from '../../../../app.constant';
import { StateService } from '@uirouter/angularjs';
import { User } from '../../../../models/user.model';
import { Order, SampleType } from '../../../../models/order.model';
import { OrganizationSettingService } from '../../../../services/organization-setting/organization-setting.service';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../app.constant';
import { ScanCase } from '../../../../models/scan-case.model';

@Component({
  selector: 'order-details-subnav',
  template
})
export class OrderDetailsSubnavComponent implements AfterViewChecked, OnInit {
  @Input() currentUser: User;
  _menuItems: any[] = [];
  orderId: string = null;
  orderType: string = null;
  isDentist = false;
  isCertified = false;
  showProductionFinished = false;
  showProductionNotFinished = false;
  showChangeWorkflowStep = false;
  showWorkflowPauseButton = false;
  showWorkflowResumeButton = false;
  showCreateWarrantyOrderButton = false;
  showInternalDeliveryDateButton = false;
  isReadyForInvoice = false;
  isLabTechnician = false;
  reactAppUrl = process.env.NEW_APP_URL;
  orderData: Order;

  isCreatedByLab = false;
  allowModify = false;
  allowResend = false;
  allowCancel = false;
  allowDecline = false;
  allowRate = false;
  allowArchive = false;
  allowUnarchive = false;

  allowAcceptedByLab = false;
  allowLabWorkStarted = false;
  allowSentByLab = false;
  allowClinicRecievedDelivery = false;
  allowApprovedByDentist = false;
  sampleTypeMap = {
    NO_SAMPLE: SampleType.NO_SAMPLE,
    PHYSICAL: SampleType.PHYSICAL,
    DIGITAL: SampleType.DIGITAL
  };

  constructor(
    private readonly subNavService: SubNavService,
    private readonly permissionService: PermissionService,
    private readonly currentUserService: CurrentUserService,
    private readonly organizationSettingsService: OrganizationSettingService,
    @Inject(forwardRef(() => '$state')) private readonly $state: StateService
  ) {}

  async ngOnInit() {
    const user = await this.currentUserService.getUserData();
    this.isDentist = this.permissionService.isDentist(user);
    this.isLabTechnician = this.permissionService.isLabTechnician(user);

    // Handle QR code event from new frontend, happy path send to clinic
    window.addEventListener('message', this.handleQrCodeEvent.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.handleQrCodeEvent);
  }

  ngAfterViewChecked() {
    this._menuItems = this.subNavService.getList();
    this.updateActions(this._menuItems);
  }

  handleQrCodeEvent(e: MessageEvent) {
    if (
      e.data &&
      e.origin === process.env.NEW_APP_URL &&
      e.data.eventType === 'qr-send-to-clinic-happy'
    ) {
      this.openSentByLab();
    }
  }

  isExternalTechnician(): boolean {
    return this.permissionService.isExternalTechnician(this.currentUser);
  }

  async updateActions(menuItems) {
    this.allowModify = this.shouldAllowCheck('isModify', menuItems);
    this.allowResend = this.shouldAllowCheck('isResend', menuItems);
    this.allowCancel = this.shouldAllowStatusCheck('cancel-order', menuItems);
    this.allowDecline = this.shouldAllowStatusCheck('decline-order', menuItems);
    if (this.isDentist) {
      this.allowRate = this.shouldAllowCheck('isRating', menuItems);
    }
    this.allowArchive = this.shouldAllowStatusCheck('archive-order', menuItems);
    this.allowUnarchive = this.shouldAllowStatusCheck('un-archive-order', menuItems);

    this.allowAcceptedByLab = this.shouldAllowStatusCheck('accept-order', menuItems);
    this.allowLabWorkStarted = this.shouldAllowStatusCheck(
      'lab-work-started',
      menuItems
    );
    this.allowSentByLab = this.shouldAllowStatusCheck('send-order', menuItems);
    this.allowClinicRecievedDelivery = this.shouldAllowStatusCheck(
      'clinic-received-delivery',
      menuItems
    );
    this.allowApprovedByDentist = this.shouldAllowStatusCheck(
      'approve-modal',
      menuItems
    );
    this.isReadyForInvoice = this.shouldAllowStatusCheck('certify-order', menuItems);
    this.isCertified = this.shouldAllowStatusCheck('confirm', menuItems);
    this.showProductionFinished = this.shouldAllowStatusCheck(
      'production-finished',
      menuItems
    );
    this.showProductionNotFinished = this.shouldAllowStatusCheck(
      'production-not-finished',
      menuItems
    );
    this.showChangeWorkflowStep = this.shouldAllowStatusCheck(
      'change-workflow-step',
      menuItems
    );
    this.showWorkflowPauseButton = this.shouldAllowStatusCheck(
      'pause-step',
      menuItems
    );
    this.showWorkflowResumeButton = this.shouldAllowStatusCheck(
      'resume-step',
      menuItems
    );
    this.showCreateWarrantyOrderButton = this.shouldAllowStatusCheck(
      'warranty-order',
      menuItems
    );
    this.showInternalDeliveryDateButton = this.shouldAllowStatusCheck(
      'change-internal-delivery-date',
      menuItems
    );
  }

  showModifyOrder(isResend) {
    if (this.isCreatedByLab) {
      const scanCase = this.orderData.scanCase as ScanCase;
      const resendString = isResend ? 'isResend=true' : '';
      const caseIdStr = scanCase?._id ? `caseId=${scanCase._id}` : '';
      const queryString =
        resendString && caseIdStr
          ? `${resendString}&${caseIdStr}`
          : resendString || caseIdStr;
      window.location.href = `${this.reactAppUrl}/orders/update/${this.orderId}?${queryString}`;
    } else if (this.orderType === ORDER_VERSION.ORDER_1) {
      this.$state.go('app.order-new', { orderId: this.orderId, isResend: isResend });
    } else {
      this.$state.go('app.order-new-2', {
        orderId: this.orderId,
        isResend: isResend
      });
    }
  }

  navigateTabs(event: number) {
    const orderId = this.$state.params.orderId;
    if (event === 1 && !this.isExternalTechnician()) {
      window.location.href = `${this.reactAppUrl}/order-details/${orderId}/order-timeline`;
    }
    if (event === 1 && this.isExternalTechnician()) {
      window.location.href = `${this.reactAppUrl}/order-details/${orderId}/lab-internal`;
    }
    if (event === 2) {
      window.location.href = `${this.reactAppUrl}/order-details/${orderId}/lab-internal`;
    }
    if (event === 3) {
      window.location.href = `${this.reactAppUrl}/order-details/${orderId}/workflow-log`;
    }
    if (event === 4) {
      window.location.href = `${this.reactAppUrl}/order-details/${orderId}/deviations`;
    }
  }

  showWorkflowLog() {
    const isLabWorkflowEnabled = this.organizationSettingsService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.LAB_WORKFLOW
    );
    return (
      this.isLabTechnician && !this.isExternalTechnician() && isLabWorkflowEnabled
    );
  }

  private shouldAllowCheck(propName: string, menuItems: any[]) {
    for (const menuItem of menuItems) {
      if (menuItem[propName] === true && menuItem.isShow) {
        this.orderId = menuItem.orderId ? menuItem.orderId : this.orderId;
        this.orderType = menuItem.orderType ? menuItem.orderType : this.orderType;
        this.isCreatedByLab =
          menuItem.isCreatedByLab === true ? true : this.isCreatedByLab;
        return true;
      }
    }
    return false;
  }

  private shouldAllowStatusCheck(actionName: string, menuItems: any[]) {
    for (const menuItem of menuItems) {
      if (menuItem.action === actionName && menuItem.isShow) {
        this.orderId = menuItem.orderId ? menuItem.orderId : this.orderId;
        this.orderData = menuItem.orderData;
        this.orderType = menuItem.orderType ? menuItem.orderType : this.orderType;
        this.isCreatedByLab =
          menuItem.isCreatedByLab === true ? true : this.isCreatedByLab;
        return true;
      }
    }
    return false;
  }

  setSampleType() {
    if (this.orderData?.deliveryDetails?.sample) {
      this.orderData.deliveryDetails.sampleType = this.sampleTypeAsEnum(
        this.orderData.deliveryDetails.sample.type
      );
    }
  }

  openChangeStep() {
    this.setSampleType();
    window.top.postMessage(
      { eventType: 'change-step', orderData: this.orderData },
      '*'
    );
  }

  openPauseResumeStep() {
    window.top.postMessage(
      { eventType: 'pause-resume-step', orderData: this.orderData },
      '*'
    );
  }

  sampleTypeAsEnum(type: string): SampleType {
    if (!type) return SampleType.NO_SAMPLE;
    if (this.sampleTypeMap[type]) {
      return this.sampleTypeMap[type];
    }
  }

  openReceivedByLab() {
    this.setSampleType();
    window.top.postMessage(
      { eventType: 'received-by-lab', orderData: this.orderData },
      '*'
    );
  }

  openSentByLab() {
    this.setSampleType();
    window.top.postMessage(
      { eventType: 'sent-by-lab', orderData: this.orderData },
      '*'
    );
  }

  openWarrantyOrder() {
    window.top.postMessage(
      { eventType: 'warranty-order', orderData: this.orderData },
      '*'
    );
  }

  openChangeInternalDeliveryDate() {
    window.top.postMessage(
      { eventType: 'change-internal-delivery-date', orderData: this.orderData },
      '*'
    );
  }
}
