/**
 * Check additional info
 */
(function () {
  'use strict';
  angular.module('app').service('additionalInfoService', additionalInfoService);
  additionalInfoService.$inject = [];

  function additionalInfoService() {
    return {
      getAdditionalInfo: getAdditionalInfo
    };

    function getAdditionalInfo(order) {
      var additionalInfo = [];
      if (order && order.constructions) {
        if (order.auditLogs && order.auditLogs.length) {
          additionalInfo = order.auditLogs
            .filter(function (log) {
              return (
                log.type === 'ADDITIONAL_INFO' &&
                log.oldValue &&
                log.oldValue.value
              );
            })
            .map(function (auditLog, index) {
              var time =
                index === 0
                  ? order.created.on
                  : order.auditLogs[index - 1].created.on;
              return {
                info: auditLog.oldValue.value,
                on: time
              };
            });
        }
        if (order.additionalInfo) {
          additionalInfo.push({
            info: order.additionalInfo,
            on:
              order.auditLogs && order.auditLogs.length
                ? order.updated.on
                : order.created.on
          });
        }
      }
      return additionalInfo;
    }
  }
})();
