import {
  Component,
  OnChanges,
  Input,
  forwardRef,
  Inject,
  SimpleChanges
} from '@angular/core';
import template from './show-connected-labs-item.html';
import { StateService } from '@uirouter/angularjs';
import { Category } from '../../models/category.model';
import { ConnectedLab } from '../../models/organization.model';

@Component({
  selector: 'show-connected-labs-item',
  template: template
})
export class ShowConnectedLabsItemComponent implements OnChanges {
  @Input() mainCategories: Category[] = [];
  // Initialize this
  @Input() connectedLab: ConnectedLab;
  @Input() activeActionSet: string;
  categories: Category[] = [];

  constructor(
    @Inject(forwardRef(() => '$state')) private readonly $state: StateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connectedLab?.currentValue?.priceList && this.mainCategories) {
      const categories = this.connectedLab.priceList
        .filter((priceList) => priceList.actionSet === this.activeActionSet)
        .map((priceList) => priceList.category);
      this.categories = this.mainCategories.filter((mainCategory) => {
        return categories.some((categoryId) => categoryId === mainCategory._id);
      });
    }
  }

  goToEditLabPage(): void {
    this.$state.go('app.edit-lab', { labId: this.connectedLab.lab._id });
  }

  goToLabAvailabilityPage(): void {
    this.$state.go('app.labs-availability', { labId: this.connectedLab.lab._id });
  }
}
