import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../../../../models/user.model';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './comments.html';

@Component({
  selector: 'comments',
  template
})
export class CommentsComponent implements OnInit {
  allComments: { createdDate: string; createdBy: User }[] = [];

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  ngOnInit(): void {
    this.allComments = this.constructionSettingsService.getSetting('allComments');
  }
}
