import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'actionFilter'
})
export class ActionFilterPipe implements PipeTransform {
  transform(actions: any, search: string) {
    if (!search) {
      return actions;
    }
    return actions.filter((action) => {
      const regex = new RegExp(search, 'i');
      return (
        regex.test(action.code) ||
        regex.test(action.description)
      );
    });
  }
}
