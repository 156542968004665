import { Component, Input } from '@angular/core';
import { CustomerSetting } from '../../../models/customer-setting.model';
import template from './drop-down.html';

@Component({
  selector: 'drop-down',
  template: template
})
export class DropDownComponent {
  @Input() setting: CustomerSetting;
  @Input() values: any[];
}
