import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { OrganizationService } from '../../services/clinic/organization.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../services/core/log.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Organization } from '../../models/organization.model';

import template from './lab-card.html';

@Component({
  selector: 'lab',
  template: template
})
export class LabCardComponent implements OnInit, AfterViewInit {
  lab: any = {};
  labs: Organization[] = [];
  @ViewChild('spinner') spinner: SpinnerComponent;

  constructor(
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private subNavService: SubNavService,
    private logService: LogService
  ) {}

  ngAfterViewInit(): void {
    this.spinner.show();
  }

  ngOnInit(): void {
    this.addMenu();
    this.updateLabs();
  }

  async addMenu(): Promise<void> {
    this.subNavService.addMenu({
      name: await this.translationsService.get('LIST_VIEW'),
      state: 'app.labs',
      icon: 'fa-list-ul',
      isActive: false,
      isScrollSpy: false
    });

    this.subNavService.addMenu({
      name: await this.translationsService.get('CARD_VIEW'),
      state: 'app.labs-card',
      icon: 'fa-th',
      isActive: true,
      isScrollSpy: false
    });

    this.subNavService.addMenu({
      name: await this.translationsService.get('ADD_LAB'),
      icon: 'fa-plus',
      action: 'add-lab-modal',
      isModel: true,
      isClass: true
    });
  }

  // on any change in lab after updating and creating
  onLabChange(lab: Organization): void {
    $('#add-lab-modal').modal('hide');
    if (lab) {
      this.labs.push(lab);
    } else {
      this.labs = [];
      this.spinner.show();
      this.updateLabs();
    }
  }

  // get list of the labs
  updateLabs(): Promise<void> {
    return this.organizationService
      .getAllOrganizations({ type: 'lab' })
      .then((result) => {
        this.spinner.hide();
        this.labs = result.data;
      })
      .catch(async (err) => {
        this.logService.error(
          'lab-card.component',
          'updateLabs',
          (await this.translationsService.get('ERROR_IN_GETTING_LABS')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_LABS')
        );
      });
  }

  // open lab edit model and get lab data for edit
  onLabEdit(lab: Organization): void {
    this.lab = lab;
    $('#add-lab-modal').modal('show');
  }

  closeModal(): void {
    this.lab = {};
    this.lab.contact = {};
    this.lab.contact.address = {};
    $('#add-lab-modal').modal('hide');
  }
}
