/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './success-modal.html'
(function () {
    'use strict';
    angular.module('app').component('successModal', {
        template: theTemplate,
        bindings: {
            close: '&', //on model close
            dismiss: '&', // on dismiss on the model
            resolve: '<' // reslove parameter
        },
        controller: successModalController
    });
    successModalController.$inject = [];

    function successModalController() {
        var $ctrl = this;
        $ctrl.$onInit = function () {
            $ctrl.header = $ctrl.resolve.header;
        };
        // on cancel or dismiss on the model
        $ctrl.cancel = function () {
            $ctrl.dismiss({ $value: false });
        };

    }

})();