import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SubCategory } from '../../../../../../../../models/organization.model';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './step.html';

@Component({
  selector: 'step',
  template
})
export class StepComponent implements OnChanges {
  @Input() selectedSubCategory: SubCategory;
  @Input() steps: string[] = [];
  @Input() stepKey: string;
  @Input() stepSettingName: string;

  @Input() selectedStep: string;

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedStep?.currentValue) {
      this.getRadioColorClass(this.selectedSubCategory);
    }
  }

  getRadioColorClass(type) {
    const radios: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      '.check-radio-color'
    );
    const noOfRadios = radios.length;
    /** different browsers return NodeList differently. so, forEach might not work in some browsers( IE in our case ).
     * below links recommend traditional for loop to iterate over DOM nodes.
     * @link https://css-tricks.com/snippets/javascript/loop-queryselectorall-matches/
     * @link https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/
     * */
    for (let i = 0; i < noOfRadios; i++) {
      radios[i].style.setProperty(
        '--radio-color',
        type && type.colorCode ? type.colorCode : '#000000',
        'important'
      );
    }
  }

  setStepSetting(value: string) {
    this.selectedStep = value;
    this.constructionSettingsService.setSetting(this.stepKey, value);
  }
}
