import { Component, Input, OnChanges } from '@angular/core';
import { User } from '../../models/user.model';
import { CustomerService } from '../../services/customer/customer.service';
import template from './account-notifications.html';

@Component({
  selector: 'account-notifications',
  template: template
})
export class AccountNotificationsComponent implements OnChanges {
  @Input() currentUser: User;
  validEmail = false;

  constructor(private readonly customerService: CustomerService) {}

  ngOnChanges() {
    if (this.currentUser?.emailNotifications?.otherEmail) {
      const emails = this.currentUser.emailNotifications.otherEmail.split(',');
      this.validEmail = emails.every((email) => this.checkEmail(email));
    }
  }

  checkEmail(email: string): boolean {
    return this.customerService.validateEmail(email);
  }

  emailChanged(emailsString: string) {
    this.currentUser.emailNotifications.otherEmail = emailsString;
    const emails = emailsString.split(',');
    this.validEmail = emails.every((email) => this.checkEmail(email));
  }
}
