import { Input, Component } from '@angular/core';
import template from './order-id-barcode.html';

@Component({
  selector: 'order-id-barcode',
  template: template
})
export class OrderIdBarcodeComponent {
  @Input() orderId: string;
}
