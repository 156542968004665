import { Input, Output, Component, forwardRef, Inject } from '@angular/core';
import { OrderService } from '../../../services/order-new/order-new.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { Order } from '../../../models/order.model';
import { User } from '../../../models/user.model';
import { ORDER_VERSION } from '../../../app.constant';
import template from './rate-order-modal.html';

@Component({
  selector: 'rate-order-modal',
  template: template
})
export class RateOrderModalComponent {
  @Input() orderDetail: Order;
  @Input() currentUser: User;

  constructor(
    private orderService: OrderService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private logService: LogService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  disableRateButton(orderDetail: Order): boolean {
    if (!orderDetail || !orderDetail.rating) {
      return true;
    }
    if (orderDetail.rating.satisfied) {
      return false;
    } else if (orderDetail.rating.notSatisfied) {
      return (
        orderDetail.rating.reasons &&
        orderDetail.rating.reasons.every((reason) => {
          return !reason.value;
        })
      );
    } else {
      return true;
    }
  }

  rateOrder(): Promise<void> {
    this.orderDetail.isRated = true;
    this.orderDetail.isRatedStatus = true;
    this.orderDetail.id = this.$state.params.orderId;
    this.orderDetail.rating.by = this.currentUser._id;
    const isSimpleOrder = this.orderDetail.type === ORDER_VERSION.ORDER_1;
    if (isSimpleOrder) {
      return this.orderService
        .rateSimpleOrder(this.orderDetail)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('SUCCESSFULLY_UPDATED')
          );
          window.location.reload();
        })
        .catch(async (err) => {
          const text = await this.translationsService.get(
            'ERROR_IN_RATE_ORDER'
          );
          this.logService.error(
            'rate-order-modal.component',
            'rateOrder',
            text + ': ' + err
          );
          this.toastr.error(text);
        });
    } else {
      return this.orderService
        .updateNewOrder(this.orderDetail)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('SUCCESSFULLY_UPDATED')
          );
          window.location.reload();
        })
        .catch(async (err) => {
          const text = await this.translationsService.get(
            'ERROR_IN_RATE_ORDER'
          );
          this.logService.error(
            'rate-order-modal.component',
            'rateOrder 1',
            text + ': ' + err
          );
          this.toastr.error(text);
        });
    }
  }
}
