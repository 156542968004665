import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import template from './change-dentist-modal.html';
import { clone, sortBy } from 'lodash';
import { User } from '../../../../../../../models/user.model';
interface Filter {
  skip: number;
  limit: number;
  filter: string;
}
@Component({
  selector: 'change-dentist-modal',
  template: template
})
export class ChangeDentistModalComponent implements OnChanges, OnInit {
  constructor(private _renderer: Renderer2) {}
  @Input() dentists: User[];
  @Input() currentUser: User;
  @Input() userCount: number;
  @Input() dentist: User;
  @Input() modalClassName: string;
  @Input() title: string;
  @Input() buttonTitle: string;
  @Output() getAllDentistOfOrganization: EventEmitter<Filter> = new EventEmitter<
    Filter
  >();
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('filterInput') filterInput: ElementRef;
  @Input() set open(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.filterInput.nativeElement.focus();
        this._renderer.setAttribute(this.filterInput.nativeElement, 'focus', 'true');
      }, 500);
    }
  }

  dentistSearch: string;
  selectedDentist: User;
  limit: number = 10;
  currentPage: number = 0;
  timeOut: ReturnType<typeof setTimeout>;

  ngOnInit(): void {
    if (this.userCount > 0) {
      this.getNoOfPages();
    }
  }

  ngOnChanges(): void {
    if (this.dentists && this.dentists.length) {
      this.dentists = sortBy(this.dentists, 'name.firstName');
    }
  }

  closeChangeDentistModal(): void {
    this.dentistSearch = '';
    this.selectedDentist = null;
    const data = {
      skip: 0,
      limit: this.limit,
      filter: null
    };
    this.getAllDentistOfOrganization.emit(data);
    this.closeModal.emit(this.dentist);
  }

  addSelectedDentist(): void {
    this.dentist = clone(this.selectedDentist);
    this.closeChangeDentistModal();
  }

  selectedAdditionalDentist(selectedDentist: User): void {
    this.selectedDentist = selectedDentist;
  }

  getNoOfPages(): void {
    this.currentPage = 1;
  }

  filterUsers(event): void {
    this.dentistSearch = event;
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }

    this.timeOut = setTimeout(() => {
      const data = {
        skip: 0,
        limit: this.limit,
        filter: this.dentistSearch
      };
      this.getAllDentistOfOrganization.emit(data);
    }, 400);
  }

  selectPage(page): void {
    const data = {
      skip: this.limit * (page.page - 1),
      limit: this.limit,
      filter: this.dentistSearch ? this.dentistSearch : null
    };
    this.getAllDentistOfOrganization.emit(data);
  }
}
