import {
  OnChanges,
  Component,
  Input,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { Organization } from '../../models/organization.model';
import { UploadedFile } from '../../models/uploaded-file.model';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { LogService } from '../../services/core/log.service';
import { FileUploader } from 'ng2-file-upload';
import { API } from '../../app.constant';
import template from './lab-media.html';
import { Status } from '../../models/status.model';

@Component({
  selector: 'lab-media',
  template: template
})
export class LabMediaComponent {
  @Input() lab: Organization;
  @Input() isWhiteLabelEnabled: boolean;
  @Output() updateLab: EventEmitter<void> = new EventEmitter<void>();
  API: typeof API = API;
  progressPercentage: number;

  constructor(
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private organizationService: OrganizationService,
    private logService: LogService
  ) {
    const self = this;
  }

  uploadLogo(file: UploadedFile, error: string): void {
    if (error) {
      console.error('Error uploading!', error);
      throw new Error('Error uploading!');
    }

    const self = this;
    const objToSend: any = {};
    objToSend._id = self.lab._id;
    objToSend.logoImage = file._id;
    objToSend.fileType = 'logoImage';
    self.organizationService
      .uploadOrganizationFile(objToSend)
      .then(async (result) => {
        self.toastr.success(
          await self.translationsService.get('FILE_UPLOADED')
        );
        self.updateLab.emit();
      })
      .catch(async (err) => {
        self.logService.error(
          'lab-media.component',
          'uploadImage',
          (await self.translationsService.get('ERROR_IN_UPLOAD_LOGO_IMAGE')) +
            ': ' +
            err
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_IN_UPLOAD_LOGO_IMAGE')
        );
      });
  }

  uploadStaff(file: UploadedFile, error: string): void {
    if (error) {
      console.error('Error uploading!', error);
      throw new Error('Error uploading!');
    }

    const self = this;
    const objToSend: any = {};
    objToSend._id = self.lab._id;
    objToSend.staffImage = file._id;
    objToSend.fileType = 'staffImage';
    self.organizationService
      .uploadOrganizationFile(objToSend)
      .then(async (result) => {
        self.toastr.success(
          await self.translationsService.get('FILE_UPLOADED')
        );
        self.updateLab.emit();
      })
      .catch(async (err) => {
        self.logService.error(
          'lab-media.component',
          'uploadImage 1',
          (await self.translationsService.get('ERROR_IN_UPLOAD_STAFF_IMAGE')) +
            ': ' +
            err
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_IN_UPLOAD_STAFF_IMAGE')
        );
      });
  }
}
