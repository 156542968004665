import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

const validOrgNr = new RegExp(/^\d{6,6}-\d{4,4}$/);

export const isValidOrganizationNumber: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (control.value && !validOrgNr.test(control.value)) {
    return {
      isValidFormat: false
    };
  }
  return null;
};
