import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import template from './actions.html';
import { ActionNew, ActionList } from '../../../../models/organization.model';
import * as _ from "lodash";

interface ActionStatusOption {
  name: string;
  value: boolean;
  color?: string;
}
@Component({
  selector: 'actions',
  template
})
export class ActionsComponent implements OnChanges {
  @Input() selectedList: ActionList;
  @Input() currency: string;
  @Output() selectedListUpdated: EventEmitter<ActionList> = new EventEmitter<
    ActionList
    >();
  action = {};
  readonly modalId = '#add-actionList-action-modal';
  actions: any = [];
  actionStatusOptions: ActionStatusOption[] = [
    {
      name: 'All Actions',
      value: null
    },
    {
      name: 'Active Actions',
      value: false,
      color: 'black'
    },
    {
      name: 'Inactive Actions',
      value: true,
      color: 'gray'
    }
  ];
  selectedStatus = 'All Actions';
  filteredActions: ActionNew[] = [];

  ngOnChanges() {
    if (this.selectedList) {
      this.actions = this.selectedList.actions;
      this.filteredActions = Object.assign([], this.actions);
      this.selectedList.categories.forEach((category) => {
        this.filteredActions.forEach((action: any) => {
            if (category._id === action.categoryId) {
              action.category = category;
            }
        });
      });
    }
  }

  openModal() {
    $(this.modalId).modal('show');
  }

  modalClosed(actionsLists?: ActionList) {
    this.action = {};
    this.selectedListUpdated.emit(actionsLists);
    $(this.modalId).modal('hide');
  }

  editAction(action: ActionNew) {
    this.action = _.cloneDeep(action);
    $(this.modalId).modal('show');
  }

  filterActionsByStatus(selectedStatusOption: ActionStatusOption) {
    if (selectedStatusOption.value === null) {
      this.filteredActions = this.actions;
      return;
    }
    this.filteredActions = this.actions.filter(
      (action) => action.inactive === selectedStatusOption.value
    );
  }

  filterActionsByCategory(category) {
    if (!category) {
      this.filteredActions = this.actions;
      return;
    }
    this.filteredActions = this.actions.filter(
      (action) => action.categoryId === category._id
    );
  }
}
