import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { User } from '../../models/user.model';
import { uploadDataTemplate } from './upload.graphql';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private readonly apollo: Apollo) {}

  uploadData(organization: string, files: any, entity: string): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: uploadDataTemplate,
          variables: {
            organization,
            files,
            entity
          },
          errorPolicy: 'all',
          context: { useMultipart: true }
        })
        .subscribe(({ data, errors }) => {
          if (errors?.length) {
            reject(errors);
            return;
          }
          resolve(cloneDeep(data['uploadData']));
        });
    });
  }
}
