import { Component, HostListener } from '@angular/core';
import { EventService } from '../services/core/event.service';

@Component({
  selector: 'app',
  template: ''
})
export class AppRootComponent {
  constructor(private eventService: EventService) {}

  @HostListener('document:click', ['$event'])
  @HostListener('document:keydown.escape', ['$event'])
  onEvent(event: any): void {
    this.eventService.emit('clickoutside');
  }
}
