import { Component, Input } from '@angular/core';
import template from './invoice-form.html';
import { FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { isValidSePostalCode } from '../../../../../directives/se-postal-code-validator.directive';
import { SePostalCodePipe } from '../../../../../pipes/se-postal-code.pipe';

@Component({
  selector: 'invoice-form',
  template
})
export class InvoiceFormComponent {
  @Input() formGroup: FormGroup;
  invoiceType = 'SAME_ADDRESS';

  constructor(private readonly sePostalCodePipe: SePostalCodePipe) {}

  onInvoiceTypeChange(event: MatRadioChange) {
    switch (event.value) {
      case 'EMAIL': {
        this.clearInvoiceAddress();
        this.attachEmailValidators();

        break;
      }
      case 'DIFFERENT_ADDRESS': {
        this.clearEmail();
        this.attachAddressValidators();
        this.formGroup
          .get('postalCode')
          .valueChanges.subscribe((postalCode: string) => {
            this.transformPostalCode(postalCode);
          });
        break;
      }
      default: {
        this.clearInvoiceAddress();
        this.clearEmail();
        break;
      }
    }
  }

  private attachEmailValidators() {
    this.formGroup
      .get('email')
      .setValidators([Validators.required, Validators.email]);
  }

  attachAddressValidators() {
    this.formGroup.get('street').setValidators(Validators.required);
    this.formGroup
      .get('postalCode')
      .setValidators([Validators.required, isValidSePostalCode]);
    this.formGroup.get('city').setValidators(Validators.required);
  }

  private clearEmail() {
    this.formGroup.get('email').setValidators([]);
    this.formGroup.get('email').setValue(null);
  }

  private clearInvoiceAddress() {
    this.formGroup.get('street').setValidators([]);
    this.formGroup.get('street').setValue(null);
    this.formGroup.get('postalCode').setValidators([]);
    this.formGroup.get('postalCode').setValue(null);
    this.formGroup.get('city').setValidators([]);
    this.formGroup.get('city').setValue(null);
  }

  private transformPostalCode(postalCode: string) {
    const transformed = this.sePostalCodePipe.transform(postalCode);
    if (transformed !== postalCode) {
      this.formGroup.get('postalCode').patchValue(transformed, {
        emitEvent: false
      });
    }
  }
}
