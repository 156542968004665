import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  OnChanges,
  Output
} from '@angular/core';
import { StateService } from '@uirouter/angularjs';
import { ToastrService } from 'ngx-toastr';
import { ORDER_DETAIL_TYPE, ORDER_VERSION, STATUS } from '../../../app.constant';
import { InvoiceStatus } from '../../../models/invoice.model';
import { Order } from '../../../models/order.model';
import { LogService } from '../../../services/core/log.service';
import { InvoiceService } from '../../../services/invoice-list/invoice.service';
import { OrderService } from '../../../services/order-new/order-new.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { OrdersGraphqlService } from '../../../graphql-services/orders/orders.graphql.service';
import { PermissionService } from '../../../services/core/permission.service';
import template from './order-info.html';
import * as _ from 'lodash';
import { User } from '../../../models/user.model';
import { LabClient } from '../../../models/client-directory';
import { OrganizationSettingService } from '../../../services/organization-setting/organization-setting.service';
import { Organization } from '../../../models/organization.model';

@Component({
  selector: 'order-info',
  template: template
})
export class OrderInfoComponent implements OnInit, OnChanges {
  private readonly window: any = window as any;
  @Input() orderDetail: Order;
  @Input() currentUser: User;
  @Input() isVisibleInPrint: boolean;
  @Input() showPrice: boolean;
  @Input() isLabTechnician: boolean;
  @Input() isDentist: boolean;
  @Input() isExternalTechnician: boolean;
  @Input() selectedOrderVersion: string;
  @Input() currency: string;
  @Output() getOrder: EventEmitter<void> = new EventEmitter<void>();
  @Output() setOrder: EventEmitter<Order> = new EventEmitter<Order>();
  showInvoiceNumberBlock: boolean;
  showOrderIdBlock: boolean;
  readyForInvoice: boolean;
  isCertificationEnabled: boolean;
  invoiceType: string;
  invoiceDeliveryMethod: string;
  order2Type = ORDER_DETAIL_TYPE.ORDER2;
  showInvoicingInfo = false;
  showTrackingLinkBlock: boolean;
  isValidUrl: boolean;
  invoiceState = 'NOT_READY';
  isDisableInvoice = false;
  showCreateInvoiceBtn = false;
  invoiceNumber = '';

  constructor(
    private readonly invoiceService: InvoiceService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService,
    private readonly translationsService: TranslationsService,
    private readonly orderService: OrderService,
    private readonly permissionService: PermissionService,
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly ordersGraphqlService: OrdersGraphqlService,
    @Inject(forwardRef(() => '$state')) private readonly $state: StateService
  ) {}

  ngOnChanges() {
    if (this.orderDetail) {
      this.showOrderIdBlock = !!this.orderDetail.internalOrderId;
      this.showInvoiceNumberBlock = !!this.orderDetail.tempInvoiceNumber;
      if (this.orderDetail.invoice) {
        this.readyForInvoice =
          this.orderDetail.invoicingState === 'READY_FOR_INVOICE';
        this.invoiceState = 'READY_FOR_INVOICE';
      } else if (this.orderDetail.invoicingState) {
        this.invoiceState = this.orderDetail.invoicingState;
      } else {
        this.invoiceState = 'NOT_READY';
      }
      this.isDisableInvoice =
        this.orderDetail.invoice?.status === 'SENT' ||
        this.orderDetail.invoice?.status === 'SCHEDULED' ||
        this.orderDetail.invoice?.status === 'PROCESSING' ||
        this.orderDetail?.invoice?.status === 'CREATED';
      //Just for show selected value in UI
      if (this.isDisableInvoice) {
        this.invoiceState = 'READY_FOR_INVOICE';
      }
      this.isValidUrl = this.isValidWebUrl(this.orderDetail.transportTrackingLink);
    }
  }

  ngOnInit(): void {
    this.isCertificationEnabled = this.organizationSettingService.getFromCustomerSettings(
      this.orderDetail.clinic?.customerSettings || [],
      'CERTIFICATION'
    );
    this.invoiceType = this.organizationSettingService.getFromCustomerSettings(
      this.orderDetail.clinic?.customerSettings || [],
      'INVOICING_TYPE'
    );
    this.invoiceDeliveryMethod = this.organizationSettingService.getFromCustomerSettings(
      this.orderDetail.clinic?.customerSettings || [],
      'INVOICING_DELIVERY'
    );
    let clientSettings = [];
    if (this.isLabTechnician) {
      clientSettings = this.currentUser.organization?.clientSettings;
    } else {
      clientSettings = (this.orderDetail?.lab as Organization)?.clientSettings;
    }
    let clientSetting: LabClient = clientSettings?.find((setting) => {
      return (
        setting.clinicId === this.orderDetail.clinic._id && setting.invoiceEnabled
      );
    });
    if (!clientSetting) {
      clientSetting = clientSettings?.find((setting) => {
        return (
          setting.clinicId === this.orderDetail.deliveryDetails?.destination?._id &&
          setting.invoiceEnabled
        );
      });
      if (clientSetting) {
        this.showInvoicingInfo = true;
        if (!this.invoiceType) {
          this.invoiceType = clientSetting.invoiceSettings?.type;
        }
        if (!this.invoiceDeliveryMethod) {
          this.invoiceDeliveryMethod =
            clientSetting.invoiceSettings?.invoiceDelivery;
        }
      }
    } else {
      this.showInvoicingInfo = true;
      if (!this.invoiceType) {
        this.invoiceType = clientSetting.invoiceSettings?.type;
      }
      if (!this.invoiceDeliveryMethod) {
        this.invoiceDeliveryMethod = clientSetting.invoiceSettings?.invoiceDelivery;
      }
    }
    this.showCreateInvoiceBtn = this.invoiceType === 'SINGLE';
  }

  async setInvoiceState(value: string) {
    this.invoiceState = value;
    if (this.invoiceState === 'READY_FOR_INVOICE') {
      window.top.postMessage(
        {
          eventType: 'set-order-ready-for-invoice',
          orderData: {
            clinicId: this.orderDetail.deliveryDetails?.destination?._id,
            _id: this.orderDetail._id,
            tempInvoiceNumber: this.orderDetail.tempInvoiceNumber
          }
        },
        '*'
      );
    } else if (this.invoiceState === 'NOT_READY') {
      window.top.postMessage(
        {
          eventType: 'unset-order-ready-for-invoice',
          orderData: {
            _id: this.orderDetail._id
          }
        },
        '*'
      );
    } else if (this.invoiceState === 'SHOULD_NOT_INVOICE') {
      window.top.postMessage(
        {
          eventType: 'toggle-order-do-not-invoice',
          orderData: {
            _id: this.orderDetail._id,
            isDoNotInvoice: true
          }
        },
        '*'
      );
    }
  }

  toggleDoNotInvoice(value: string) {
    window.top.postMessage(
      {
        eventType: 'toggle-order-do-not-invoice',
        orderData: {
          _id: this.orderDetail._id,
          isDoNotInvoice: value
        }
      },
      '*'
    );
  }

  openCreateInvoiceConfirmDialog() {
    window.top.postMessage(
      { eventType: 'create-invoice', orderData: this.orderDetail },
      '*'
    );
  }

  openInvoiceNumberTextBox() {
    this.showInvoiceNumberBlock = !this.showInvoiceNumberBlock;
  }

  openInternalOrderIdTextBox() {
    this.showOrderIdBlock = !this.showOrderIdBlock;
  }

  saveInvoiceNumber() {
    window.top.postMessage(
      {
        eventType: 'save-invoice-number',
        orderData: {
          _id: this.orderDetail._id,
          tempInvoiceNumber: this.invoiceNumber
        }
      },
      '*'
    );
  }

  assignInternalOrderIdData(internalOrderId: string) {
    this.orderDetail.internalOrderId = internalOrderId;
  }

  assignInvoiceNumberData(invoiceNumber: string) {
    this.invoiceNumber = invoiceNumber;
  }

  getRedClass(field) {
    return {
      'red-color': false
    };
  }

  openTrackingLinkTextBox() {
    this.showTrackingLinkBlock = !this.showTrackingLinkBlock;
  }

  assignTrackingLinkData(transportTrackingLink: string) {
    this.orderDetail.transportTrackingLink = transportTrackingLink;
    this.isValidUrl = this.isValidWebUrl(this.orderDetail.transportTrackingLink);
  }

  editTrackingLink() {
    this.showTrackingLinkBlock = !this.showTrackingLinkBlock;
  }

  isValidWebUrl(url) {
    let regEx = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return regEx.test(url);
  }

  async saveTrackingLink() {
    await this.ordersGraphqlService
      .transportTrackingLink(
        this.orderDetail._id,
        this.orderDetail.transportTrackingLink
      )
      .then(async (result: string) => {
        this.orderDetail.transportTrackingLink = result;
        this.showTrackingLinkBlock = !this.showTrackingLinkBlock;
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
      })
      .catch(async (err) => {
        this.logService.error(
          'order-detail-info.component',
          'saveTrackingLink',
          err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_TRANSPORT_TRACKING_LINK')
        );
      });
  }

  saveInternalOrderId() {
    window.top.postMessage(
      {
        eventType: 'save-internal-order-id',
        orderData: {
          _id: this.orderDetail._id,
          internalOrderId: this.orderDetail.internalOrderId
        }
      },
      '*'
    );
  }

  openHelpPopUp() {
    window.top.postMessage({ eventType: 'help-popup' }, '*');
  }
}
