import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ScanCase, ThreeShapeAttachments } from '../../../models/scan-case.model';
import template from './three-shape-attachments.html';
import { Organization } from '../../../models/organization.model';
import { PermissionService } from '../../../services/core/permission.service';
import { User } from '../../../models/user.model';

@Component({
  selector: 'three-shape-attachments',
  template: template
})
export class ThreeShapeAttachmentsComponent implements OnChanges {
  @Input() currentUser: User;
  @Input() scanCase: ScanCase;
  @Input() org: Organization;
  @Input() orderDbId: string;
  @Input() showDownloadButton: boolean;
  @Input() showFormButton: boolean;
  @Output() onOpenUnlinkDialog: EventEmitter<string> = new EventEmitter<string>();
  @Input() isCreatedByLab: boolean;
  threeShapeAttachments: ThreeShapeAttachments[];
  orderFormHtml: ThreeShapeAttachments;
  isDentist = false;

  constructor(private readonly permissionService: PermissionService){}

  ngOnChanges(): void {
    if (this.scanCase.attachments?.length) {
      this.isDentist = this.permissionService.isDentist(this.currentUser);
      this.threeShapeAttachments = this.scanCase.attachments.filter((attachment) => {
        if (attachment.FileType === 'html') {
          this.orderFormHtml = attachment;
        }
        return attachment.FileType !== 'html';
      });
    }
  }

  scanFiles(type: string) {
    if (this.threeShapeAttachments) {
      return this.threeShapeAttachments.filter(
        (file) => file.FileType.toLocaleLowerCase() === type
      );
    }
    return [];
  }

  downloadFile(attachment: ThreeShapeAttachments) {
    window.top.postMessage(
      {
        eventType: 'download-scan-case-file',
        attachment,
        scanCaseId: this.scanCase._id,
        orderData: {
          _id: this.orderDbId
        }
      },
      '*'
    );
  }

  downloadAllFiles() {
    window.top.postMessage(
      {
        eventType: 'open-scan-case-dialog',
        scanCaseId: this.scanCase._id,
        orderData: {
          _id: this.orderDbId
        }
      },
      '*'
    );
  }

  unlinkAttachment(scanCaseId: string) {
    this.onOpenUnlinkDialog.emit(scanCaseId);
  }
}
