import { Component, Input, OnChanges } from '@angular/core';
import { ConnectedClinics } from '../../../../../models/logistic.model';
import template from './logistics-customer-list.html';
import { TransportClinic } from '../../../../../models/transport-clinic.model';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../../../../services/core/log.service';
import { TranslationsService } from '../../../../../services/translations/translations.service';
import { LogisticsCustomerGraphqlService } from '../../../../../graphql-services/logistics-customer/logistics-customer.graphql.service';
import { CountryNameService } from '../../../../../services/country-name/country-name';

@Component({
  selector: 'logistics-customer-list',
  template: template
})
export class LogisticsCustomerListComponent implements OnChanges{
  @Input() labsConnectedClinic: ConnectedClinics;
  @Input() transportClinics: TransportClinic;

  constructor(
    private readonly logisticsCustomerGraphqlService: LogisticsCustomerGraphqlService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService,
    private readonly translationsService: TranslationsService,
    public countryNameService: CountryNameService,
  ) {}

  ngOnChanges(): void {
    if (this.labsConnectedClinic) {
      this.countryNameService
        .countryName(this.labsConnectedClinic.clinicCountryCode)
      .then((name: string) => this.labsConnectedClinic.clinicCountryName = name);
    }
  }

  async onClinicSelect(labId, clinicId, transportClinicId) {
    try {
      const connectedTransportClinic = {
        labId: labId,
        clinicId: clinicId,
        transportClinicId: transportClinicId
      };
      await this.logisticsCustomerGraphqlService.addConnectedTransportClinic(connectedTransportClinic);
      await this.showSuccessToastr('TRANSPORT_CLINIC_ADDED');
    } catch (error) {
      this.logService.error('logistics-customer-list', 'onClinicSelect', error);
      this.toastr.error(error.message);
    }
  }

  async showSuccessToastr(message) {
    const text = await this.translationsService.get(message);
    this.toastr.success(text);
  }
}