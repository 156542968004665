import { Input, Component, forwardRef, Inject } from '@angular/core';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { Order } from '../../../models/order.model';
import { User } from '../../../models/user.model';
import template from './certify-order-modal.html';
import { OrdersGraphqlService } from '../../../graphql-services/orders/orders.graphql.service';

@Component({
  selector: 'certify-order-modal',
  template: template
})
export class CertifyOrderModalComponent {
  @Input() orderDetail: Order;
  @Input() currentUser: User;
  @Input() totalVat: number = 0;
  @Input() totalPrice: number = 0;
  @Input() currency: string = '';
  isPriceConfirmed: boolean = false;

  constructor(
    private readonly ordersGraphqlService: OrdersGraphqlService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private logService: LogService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  async certifyOrder(): Promise<void> {
    try {
      await this.ordersGraphqlService.certifyUnCertifyOrder(
        this.$state.params.orderId
      );
      this.toastr.success(
        await this.translationsService.get('SUCCESSFULLY_UPDATED')
      );
      window.location.reload();
    } catch (err) {
      const text = await this.translationsService.get('ERROR_IN_CERTIFYING_ORDER');
      this.logService.error(
        'certify-order-modal.component',
        'certifyOrder',
        text + ': ' + err
      );
      this.toastr.error(text);
    }
  }
}
