/**
 * Order new delivery service
 */
(function () {
    'use strict';
    angular.module('app').service('orderNew2DeliveryService', orderNew2DeliveryService);
    orderNew2DeliveryService.$inject = [ '$q', 'DATE', '$filter', 'organizationSettingService', 'CUSTOMER_SETTINGS_CONSTANTS' ];

    function orderNew2DeliveryService($q, DATE, $filter, organizationSettingService, CUSTOMER_SETTINGS_CONSTANTS) {
        return {
            updatePerfectDeliveryDate: updatePerfectDeliveryDate,
            getSelectedActions: getSelectedActions,
            getPriceList: getPriceList,
            deleteDummyConstructions: deleteDummyConstructions,
            countDays: countDays,
            calculateLabRatings: calculateLabRatings,
            getSelectedMaterials: getSelectedMaterials,
            getMaterialWithName: getMaterialWithName,
            getPriceDiff: getPriceDiff,
            getMaterialPriceDiff: getMaterialPriceDiff,
            getNumOfUnits: getNumOfUnits,
        };

        function getNumOfUnits(numberOfUnits) {
            if (!numberOfUnits) {
                return [];
            }
            if (numberOfUnits instanceof Array) {
                return numberOfUnits;
            }
            return Object.keys(numberOfUnits).map(function (actionId) {
                return {
                    actionId: actionId,
                    header: numberOfUnits[actionId].header,
                    numberOfUnits: numberOfUnits[actionId].numberOfUnits
                };
            });
        }

        function getMaterialPriceDiff(constructions, priceDiff) {
            return constructions.reduce(function (res, construction) {
                construction.numberOfUnits = getNumOfUnits(construction.numberOfUnits);
                var materials = construction.actions.reduce(function (res, action) {
                    var unit = construction.numberOfUnits.find(function (unit) {
                        return action._id === unit.actionId;
                    });
                    if (action.materialGroup && action.materialGroup.materials) {
                        var mats = _.cloneDeep(action.materialGroup.materials);
                        mats.forEach(function (material) {
                            if (action.costPerTooth && construction.teethNo && construction.teethNo.length) {
                                material.quantity = construction.teethNo.length;
                            } else if (action.costPerTooth && construction.teethRange && construction.teethRange.start === 18 && construction.teethRange.end === 38) {
                                material.quantity = 2;
                            } else {
                                material.quantity = unit ? unit.numberOfUnits : 1;
                            }
                            material.vat = action.vat || 0;
                            material.actionId = action._id;
                        });
                        return res.concat(mats);
                    }
                    return res;
                }, []);

                var mats = materials.filter(function (mat) {
                    return construction.materialName === mat.material;
                });

                mats.forEach(function (mat) {
                    var diff = priceDiff.find(function (diff) {
                        return mat._id === diff.material;
                    });
                    if (diff) {
                        mat.priceDiff = diff.priceDiff;
                    }
                });

                return res.concat(mats);
            }, []);
        }

        function getMaterialWithName(materials, matGroups) {
            materials.forEach(function (material) {
                var mg = matGroups.find(function (mg) {
                    return mg._id === material.material;
                });
                if (mg) {
                    material.materialName = mg.material;
                }
            });
            return materials;
        }

        function getPriceDiff(lab) {
            if (!lab.materialPriceDifference) {
                lab.materialPriceDifference = [];
            }
            return lab.materialPriceDifference.reduce(function (res, matPriceDiff) {
                return res.concat(matPriceDiff.materials);
            }, []);
        }

        function getSelectedMaterials(constructions) {
            return constructions.reduce(function (res, construction) {
                if (construction.material) {
                    res.push({
                        material: construction.material
                    });
                }
                return res;
            }, []);
        }

        function calculateLabRatings(labs, clinicId) {
            labs.filter(function (lab) {
                return lab.lab;
            }).forEach(function (lab) {
                var stat = lab.lab.statistics.find(function (stats) {
                    return stats.clinicId === clinicId;
                });
                lab.ratePercent = stat && stat.ratings ? stat.ratings.satisfied / stat.numOrders * 100 : 0;
                lab.totalRating = stat ? stat.numOrders : 0;
            });
        }

        function checkPerfectDeliveryDateIsHoliday(day, holidayDays, inActiveDays) {
            var delDay = day;
            if (inActiveDays.length === 0 || inActiveDays.length === 7) {
                return (holidayDays.indexOf(delDay) !== -1) ? checkPerfectDeliveryDateIsHoliday(delDay + 86400000, holidayDays, []) : delDay;
            } else {
                return (holidayDays.indexOf(delDay) !== -1 || inActiveDays.indexOf(new Date(delDay).getDay()) !== -1) ? checkPerfectDeliveryDateIsHoliday(delDay + 86400000, holidayDays, inActiveDays) : delDay;
            }
        }

        function getSelectedActions(constructions) {
            return constructions.reduce(function (result, construction) {
                if (construction.actions) {
                    var actions = construction.actions.map(function (action) {
                        action.warranty = construction.warranty;
                        action.teethNo = construction.teethNo;
                        action.teethRange = construction.teethRange;
                        if (!construction.numberOfUnits) {
                            action.numberOfUnits = 1;
                            return _.clone(action);
                        }
                        // in case when order is modified
                        if (construction.numberOfUnits instanceof Array) {
                            var actionObj = construction.numberOfUnits.find(function (numberOfUnits) {
                                return action._id === numberOfUnits.actionId;
                            });
                            if (!actionObj) {
                                action.numberOfUnits = 1;
                                return _.clone(action);
                            }
                            action.numberOfUnits = actionObj.numberOfUnits;
                        } else {
                            var actionId = Object.keys(construction.numberOfUnits).find(function (actionId) {
                                return action._id === actionId;
                            });
                            if (!actionId) {
                                action.numberOfUnits = 1;
                                return _.clone(action);
                            }
                            action.numberOfUnits = construction.numberOfUnits[actionId].numberOfUnits;
                        }
                        return _.clone(action);
                    });
                    return result.concat(actions);
                }
            }, []);
        }

        function getPriceList(priceList) {
            return priceList.reduce(function (result, price) {
                return result.concat(price.actions);
            }, []);
        }

        function countDays(daysRequired, holidayDays, inActiveDays) {
            var startDay = new Date();
            startDay = startDay.setHours(0, 0, 0, 0);
            var days = daysRequired;
            while (days > 0) {
                if (inActiveDays.length === 0 || inActiveDays.length === 7) {
                    if (holidayDays.indexOf(startDay) === -1) {
                        days--;
                    }
                } else {
                    if (holidayDays.indexOf(startDay) === -1 && inActiveDays.indexOf(new Date(startDay).getDay()) === -1) {
                        days--;
                    }
                }
                startDay += 86400000;
            }
            return checkPerfectDeliveryDateIsHoliday(startDay, holidayDays, inActiveDays);
        }

        function updatePerfectDeliveryDate(currentSelectedLab, newOrderObj, deliveryInfo, additionalDeliveryDate, connectedLabPriceList) {
            if (currentSelectedLab) {
                const useCalendarDeliveryDays = organizationSettingService.getCustomerSetting(
                  CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.USE_CALENDAR_DELIVERY_DAYS
                );
                var daysForDelivery = 0;
                var perfectDeliveryDate = new Date();

                if (connectedLabPriceList && newOrderObj.allConstructions && newOrderObj.allConstructions.length > 0) {
                    //get all function selection action from constructions
                    var selectedActions = getSelectedActions(newOrderObj.allConstructions);

                    //get all actions from priceList array.
                    var allActions = getPriceList(connectedLabPriceList);
                    // loop over all actions to find total price and delivery time of selected actions.
                    if (selectedActions && selectedActions.length) {
                        allActions.forEach(function (action) {
                            selectedActions.filter(function (selectedAction) {
                                return action.action === selectedAction._id;
                            }).forEach(function () {
                                if (daysForDelivery === 0) {
                                    daysForDelivery = action.deliveryTime;
                                } else if (action.deliveryTime >= daysForDelivery) {
                                    daysForDelivery = action.deliveryTime;
                                }
                            });
                        });
                    }
                }
                if (additionalDeliveryDate) {
                    daysForDelivery += additionalDeliveryDate;
                }

                // inactive days in a week
                var inActiveDays = [];

                if (currentSelectedLab && currentSelectedLab.openingHours) {
                    currentSelectedLab.openingHours.forEach(function (dayObj, index) {
                        if (!dayObj.active) {
                            var day = index + 1;
                            day = (day === 7 ? 0 : day);
                            inActiveDays.push(day);
                        }
                    });
                }
                if (!currentSelectedLab.holidayDays) {
                    currentSelectedLab.holidayDays = [];
                }
                var holidays = currentSelectedLab.holidayDays.concat(currentSelectedLab.unavailableLabDates || []);
                //INFO: If useCalendarDeliveryDays setting is true then we use the calendar days instead of working days delivery date
                if (useCalendarDeliveryDays && inActiveDays.length !== 7) {
                    let calendarDate = moment().add(daysForDelivery, 'days').format("YYYY-MM-DD");
                    perfectDeliveryDate = previousWorkingDay(calendarDate, holidays, inActiveDays);
                } else {
                    perfectDeliveryDate = countDays(daysForDelivery + 1, holidays, inActiveDays);
                }
                deliveryInfo.date = $filter('date')(perfectDeliveryDate, DATE.FORMAT);
                var data = {
                    deliveryInfo: deliveryInfo,
                    newOrderObj: newOrderObj,
                    perfectDeliveryDate: perfectDeliveryDate
                };

            }
            return data;
        }

        function deleteDummyConstructions(selectedConstructions) {
            if (selectedConstructions) {
                Object.keys(selectedConstructions).filter(function (key) {
                    return key !== 'type';
                }).forEach(function (mainCategory) {
                    Object.keys(selectedConstructions[mainCategory]).forEach(function (subCategory) {
                        if (selectedConstructions[mainCategory][subCategory]) {
                            var dummyConstructions = selectedConstructions[mainCategory][subCategory].filter(function (construct) {
                                return !construct.createdAt;
                            });
                            selectedConstructions[mainCategory][subCategory] = _.difference(selectedConstructions[mainCategory][subCategory], dummyConstructions);
                        }
                    });
                });
            }
        }

        function previousWorkingDay(selectedDate, holidays, inActiveDays) {
            let inActiveDayIndex,
              holidayIndex,
              previousDate = moment(selectedDate);
            const previousDateMilliseconds = previousDate.toDate().setHours(0, 0, 0, 0);
            holidayIndex = holidays.indexOf(previousDateMilliseconds);
            inActiveDayIndex = inActiveDays.indexOf(previousDate.toDate().getDay());
            while (inActiveDayIndex > -1 || holidayIndex > -1) {
                previousDate = moment(previousDate).subtract(1, 'days');
                const latestDeliveryDateMilliseconds = previousDate.toDate().setHours(0, 0, 0, 0);
                holidayIndex = holidays.indexOf(latestDeliveryDateMilliseconds);
                inActiveDayIndex = inActiveDays.indexOf(previousDate.toDate().getDay());
            }

            const todayDateMillSecond = new Date().setHours(0, 0, 0, 0);
            const deliveryDateMilliSecond = previousDate.toDate().setHours(0, 0, 0, 0);
            //INFO: deliveryDate should always be greater than from today date then we send delivery date
            // otherwise user need to select the delivery date manually
            if (deliveryDateMilliSecond > todayDateMillSecond) {
                return deliveryDateMilliSecond;
            }
            return null;
        }
    }
})();
