import { Component, Input } from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../../../../app.constant';
import { SubCategory } from '../../../../../../../../models/organization.model';
import template from './clasp-material.html';

@Component({
  selector: 'clasp-material',
  template
})
export class ClaspMaterialComponent {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedClaspMaterial: string;
  @Input() claspMaterialOptions: string[];
  claspMaterialSettingName =
    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_MATERIAL;
}
