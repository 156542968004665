import { Input, Component, Output, EventEmitter, OnChanges } from '@angular/core';
import { Category } from '../../../../../../models/organization.model';
import template from './main-categories.html';
import _ = require('lodash');
import { ConstructionPageService } from '../../../../services/construction-page-service';
import style from './main-categories.less';

@Component({
  selector: 'main-categories',
  template,
  styles: [style]
})
export class MainCategoriesComponent implements OnChanges {
  @Output() categorySelected: EventEmitter<Category> = new EventEmitter<Category>();
  @Input() categoryTree: Category[] = [];
  @Input() selectedMainCategory: Category;

  constructor(private readonly constructionPageService: ConstructionPageService) {}

  ngOnChanges(): void {
    this.selectedMainCategory = this.constructionPageService.selectedMainCategory;
  }

  selectCategory(category: Category) {
    this.selectedMainCategory = category;
    this.constructionPageService.selectedMainCategory = category;
    this.categorySelected.emit(category);
  }
}
