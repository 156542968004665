import { Injectable } from '@angular/core';
import { RoleService } from './role.service';
import { Role } from '../../models/roles.model';

@Injectable()
export class UserRoleService {
  constructor(private roleService: RoleService) {}

  async getRolesExcept(roleType: string): Promise<Role[]> {
    const response = await this.roleService.getAllRoles();
    return (response || []).filter((rol) => {
      return rol.type !== roleType;
    });
  }
}
