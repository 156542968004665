(function () {
    "use strict";
    angular.module("app").factory("translationsLoader", translationsLoader);
    translationsLoader.$inject = [ 'currentUserService', 'translationsService' ];
    function translationsLoader(currentUserService, translationsService) {
        return function (options) {
            try {
                var languageCode = options.key;

                translationsService.init();
                currentUserService.setCustomTranslations(languageCode, false);
                const updatedTranslations = translationsService.getTranslations(languageCode);

                return Promise.resolve(updatedTranslations);
            } catch(e) {
                console.error("Error reloading translations!");
                return Promise.reject(e);
            }
        };
    }
})();