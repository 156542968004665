import { PipeTransform, Pipe } from '@angular/core';
import { LocaleFormatterService } from '../services/locale-formatter/locale-formatter.service';

@Pipe({
  name: 'dateLocale'
})
export class DateLocalePipe implements PipeTransform {
  constructor(private localeFormatterService: LocaleFormatterService) {}

  transform(date: Date, format: string, localeForDemo: string): string {
    return this.localeFormatterService.formatDate(date, format, localeForDemo);
  }
}
