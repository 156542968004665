import { Component, Input, OnInit } from '@angular/core';
import template from './account-clinic-form.html';
import { FormGroup } from '@angular/forms';
import { OrganizationNumberPipe } from '../../../pipes/organization-number.pipe';
import { SePostalCodePipe } from '../../../pipes/se-postal-code.pipe';

@Component({
  selector: 'account-clinic-form',
  template
})
export class AccountClinicFormComponent implements OnInit {
  @Input() formGroup: FormGroup;

  constructor(
    private readonly organizationNumberPipe: OrganizationNumberPipe,
    private readonly sePostalCodePipe: SePostalCodePipe
  ) {}

  ngOnInit() {
    this.formGroup
      .get('organizationNumber')
      .valueChanges.subscribe((orgNumber: string) => {
        this.transformOrganizationNumber(orgNumber);
      });

    this.formGroup.get('postalCode').valueChanges.subscribe((postalCode: string) => {
      this.transformPostalCode(postalCode);
    });
  }

  private transformOrganizationNumber(orgNumber: string) {
    const transformed = this.organizationNumberPipe.transform(orgNumber);
    if (transformed !== orgNumber) {
      this.formGroup.get('organizationNumber').patchValue(transformed, {
        emitEvent: false
      });
    }
  }

  private transformPostalCode(postalCode: string) {
    const transformed = this.sePostalCodePipe.transform(postalCode);
    if (transformed !== postalCode) {
      this.formGroup.get('postalCode').patchValue(transformed, {
        emitEvent: false
      });
    }
  }
}
