import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.constant';
import { Material, Organization } from '../../models/organization.model';
import { Injectable } from '@angular/core';
import { ApiInterceptor } from '../../interceptors/api-interceptor';

@Injectable()
export class MaterialListService extends ResourceService {
  constructor(
    httpClient: HttpClient,
    private readonly apiInterceptor: ApiInterceptor
  ) {
    super(httpClient, '');
  }

  addMaterialToList(materialListId: string, material: Material) {
    const params = new HttpParams().set('id', materialListId);
    return this.put(
      `${API.API_V2_BASE}material-list/:id/materials`,
      material,
      params,
      this.apiInterceptor
    );
  }

  updateMaterialOnList(listId: string, material: Material) {
    const params = new HttpParams()
      .set('id', material._id)
      .set('listId', listId);
    return this.put(
      `${API.API_V2_BASE}material-list/:listId/materials/:id`,
      material,
      params,
      this.apiInterceptor
    );
  }

  addMaterialListForLab(materialLists: Partial<Organization>) {
    return this.put(
      `${API.API_V2_BASE}organizations/material-list`,
      materialLists,
      undefined,
      this.apiInterceptor
    );
  }

  updateMaterialListForLab(material: Partial<Organization>) {
    const params = new HttpParams().set('id', material._id);
    return this.put(
      `${API.API_V2_BASE}organizations/material-list/:id`,
      material,
      params,
      this.apiInterceptor
    );
  }

  saveDefaultMaterialList(data: { materialListId: string }) {
    return this.put(
      `${API.API_V2_BASE}organizations/defaultMaterialList`,
      data,
      undefined,
      this.apiInterceptor
    );
  }
}
