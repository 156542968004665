import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationService } from '../../../services/clinic/organization.service';
import { Organization, ConnectedLab } from '../../../models/organization.model';
import * as _ from 'lodash';
import template from './connect-to-lab-modal.html';

@Component({
  selector: 'connect-to-lab-modal',
  template: template
})
export class ConnectToLabModalComponent implements OnInit {
  @Output() onLabsConnected: EventEmitter<void> = new EventEmitter<void>();
  @Input() userOrg: Organization;
  @Input() labs: Organization[];
  @Input() checkedLabs: { [key: string]: Organization };
  labSearch: string = '';
  sortBy: string = '';
  sortType: boolean = true;
  labsToConnect: { lab: string }[] = [];
  sortOrder: string = 'asc';

  constructor(
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private organizationService: OrganizationService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.sortData('name');
  }

  // close connect to lab modal
  closeConnectToLabModal(): void {
    this.labsToConnect = [];
    this.labSearch = '';
    this.sortBy = '';
    this.sortData('name');
    $('#connect-to-lab-modal').modal('hide');
  }

  isCheckboxDisabled(lab: Organization): boolean {
    return this.userOrg.connectedLabs.some((connectedLab: ConnectedLab) => {
      return (
        connectedLab.lab &&
        connectedLab.lab._id === lab._id &&
        connectedLab.priceList.length
      );
    });
  }

  // sort labs list
  sortData(field: string): void {
    if (this.sortBy === field) {
      this.sortType = !this.sortType;
      this.sortOrder = this.sortType ? 'asc' : 'desc';
    } else {
      this.sortBy = field;
      this.sortType = true;
      this.sortOrder = 'asc';
    }
    this.labs = _.orderBy(
      this.labs,
      this.sortBy,
      this.sortOrder as 'asc' | 'desc'
    );
  }

  // TODO: Rename this method. It returns a boolean.
  disableSaveButton(): boolean {
    return !_.keys(this.checkedLabs).some((key) => this.checkedLabs[key]);
  }

  // connect selected labs
  connectLabs(): Promise<void> {
    const labsToConnect = _.keys(this.checkedLabs)
      .filter((key) => this.checkedLabs[key])
      .map((key) => ({ lab: key }));
    return this.organizationService
      .connectMultipleLabs(labsToConnect)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
        this.labsToConnect = [];
        this.labSearch = '';
        this.sortBy = '';
        this.onLabsConnected.emit();
        this.sortData('name');
      })
      .catch(async (err) => {
        this.logService.error(
          'connect-to-lab-modal.component',
          'getConnectedLabs',
          (await this.translationsService.get('ERROR_IN_CONNECT_LABS')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_CONNECT_LABS')
        );
      });
  }
}
