import { ResourceService } from '../core/resource.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { API } from '../../app.constant';
import { MaterialGroups } from '../../models/material-group.model';
import { Status } from '../../models/status.model';

@Injectable()
export class MaterialService extends ResourceService {
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
  }

  getAllMaterialGroups(): Promise<{ data: MaterialGroups[] }> {
    return this.get(
      API.API_BASE + 'material-groups',
      undefined,
      this.apiInterceptor
    );
  }

  saveMaterialGroup(
    materialGroup: Partial<MaterialGroups>
  ): Promise<Partial<Status>> {
    return this.post(
      API.API_BASE + 'material-groups',
      materialGroup,
      undefined,
      this.apiInterceptor
    );
  }

  updateMaterialGroup(
    materialGroup: Partial<MaterialGroups>
  ): Promise<Partial<Status>> {
    const params = new HttpParams().set('id', materialGroup._id);

    return this.put(
      API.API_BASE + 'material-groups/:id',
      materialGroup,
      params,
      this.apiInterceptor
    );
  }

  deleteMaterialGroup(
    materialGroup: Partial<MaterialGroups>
  ): Promise<Partial<Status>> {
    const params = new HttpParams().set('id', materialGroup._id);

    return this.delete(
      API.API_BASE + 'material-groups/:id',
      params,
      this.apiInterceptor
    );
  }
}
