import { Input, Component } from '@angular/core';
import { User } from '../../../models/user.model';
import template from './user-info-modal.html';

@Component({
  selector: 'user-info-modal',
  template: template
})
export class UserInfoModalComponent {
  @Input() user: Partial<User> = {};
}
