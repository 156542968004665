import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { downgradeInjectable } from '@angular/upgrade/static';

// Translations
import { translationEnglish } from './translations/english-translation';
import { translationSwedish } from './translations/swedish-translation';

// Services
import { PermissionService } from './services/core/permission.service';
import { FileUploadService } from './services/file/file-upload.service';
import { CacheService } from './services/core/cache.service';
import { CurrentUserService } from './services/users/current-user.service';
import { GetAllOrdersService } from './services/order-list/get-all-orders.service';
import { GetAllUsersService } from './services/order-list/get-all-users.service';
import { LogService } from './services/core/log.service';
import { OrganizationSettingService } from './services/organization-setting/organization-setting.service';
import { SessionService } from './services/core/session.service';
import { UserRoleService } from './services/roles/user-role.service';
import { LogoImageService } from './services/logo-image/logo-image-service';
import { MaterialService } from './services/manage-actions/material-group.service';
import { UsersService } from './services/users/users.service';
import { OrganizationService } from './services/clinic/organization.service';
import { CategoryService } from './services/manage-actions/category.service';
import { ReportsService } from './services/reports/reports.service';
import { ActionService } from './services/manage-actions/action.service';
import { RoleService } from './services/roles/role.service';
import { OrderService } from './services/order-new/order-new.service';
import { InvoiceService } from './services/invoice-list/invoice.service';
import { TokenService } from './services/core/token.service';
import { EventService } from './services/core/event.service';
import { DenthubSettingsService } from './services/core/denthub-settings.service';
import { CustomerService } from './services/customer/customer.service';
import { PrintService } from './services/print/print.service';
import { TranslationsService } from './services/translations/translations.service';
import { SubNavService } from './services/sub-nav/sub-nav.service';
import { FormatReportService } from './services/format-report/format-report.service';
import { Json2CSVService } from './services/json-2-csv/json-2-csv.service';
import { Json2ExcelService } from './services/json-2-excel/json-2-excel.service';
import { UsersGraphqlService } from './graphql-services/users/users.graphql.service';
import { LogisticGraphqlService } from './graphql-services/logistics/logistics.graphql.service';
import { PatientCategoryService } from './feature/order/services/patient-category-service';
import { CustomerYearPrefixService } from './feature/order/services/customer-year-prefix-service';
import { OrdersGraphqlService } from './graphql-services/orders/orders.graphql.service';
import { ImageAnnotationService } from './services/image-annotation/image-annotation.service';
import { NewOrderListRedirectService } from './services/new-order-list-redirect/new-order-list-redirect.service';
import { RegexService } from './services/regex/regex';
import { NewFrontendNavigateService } from './services/new-frontend-navigate/new-frontend-navigate.service';
import { EscapedMessageService } from './services/escaped-message/escaped-message.service';
import { CountryNameService } from './services/country-name/country-name';

// Components - only components referenced by ui-router is needed here.
import { SettingsComponent } from './newmodules/settings/settings.component';
import { ProfileComponent } from './newmodules/profile/profile.component';
import { AppRootComponent } from './newmodules/app-root.component';
import { FederatedLoginComponent } from './newmodules/federated-login/federated-login.component';
import { LanguageSelectorComponent } from './newmodules/login/language-selector.component';
import { FederatedSelectOrganizationComponent } from './newmodules/federated-login/federated-select-organization.component';
import { LocaleFormatterService } from './services/locale-formatter/locale-formatter.service';
import { BankIdLoginComponent } from './newmodules/bankid-login/bankid-login.component';
import { LabTechnicianComponent } from './newmodules/lab-technician/lab-technician.component';
import { LabTechnicianCardComponent } from './newmodules/lab-technician-card-view/lab-technician-card.component';
import { LoginComponent } from './newmodules/login/login.component';
import { AddLabModalComponent } from './newmodules/add-lab-modal/add-lab-modal.component';
import { AddDentistModalComponent } from './newmodules/add-dentist-modal/add-dentist-modal.component';
import { MenuListComponent } from './newmodules/menu-list/menu-list.component';
import { UsersComponent } from './newmodules/users/users.component';
import { ChangeDentistModalComponent } from './feature/order/pages/action-based-order/patient/additional-dentist/change-dentist-modal/change-dentist-modal.component';
import { InviteLabModalComponent } from './newmodules/invite-lab-modal/invite-lab-modal.component';
import { LabFilterComponent } from './newmodules/lab-filter/lab-filter.component';
import { ClinicFilterComponent } from './newmodules/clinic-filter/clinic-filter.component';
import { AddLabTechnicianModalComponent } from './newmodules/add-lab-technician-modal/add-lab-technician-modal.component';
import { SearchComponent } from './newmodules/search/search.component';
import { DenthubHeaderComponent } from './newmodules/denthub-header/denthub-header.component';
import { LabCardComponent } from './newmodules/lab-card-view/lab-card.component';
import { UsersCardComponent } from './newmodules/user-card-view/user-card.component';
import { LabComponent } from './newmodules/lab/lab.component';
import { NotificationsComponent } from './newmodules/notifications/notifications.component';
import { ResetPasswordComponent } from './newmodules/reset-password/reset-password.component';
import { CancelOrderModalComponent } from './newmodules/order-details/cancel-order-modal/cancel-order-modal.component';
import { CustomerSettingsComponent } from './newmodules/customer-settings/customer-settings.component';
import { PermissionsComponent } from './newmodules/permissions/permissions.component';
import { AddRoleModalComponent } from './newmodules/permissions/add-role-modal/add-role-modal.component';
import { DeclineOrderModalComponent } from './newmodules/order-details/decline-order-modal/decline-order-modal.component';
import { ChatComponent } from './newmodules/order-details/chat/chat.component';
import { CareAdviceComponent } from './newmodules/order-details/care-advice/care-advice.component';
import { ReportDescriptionModalComponent } from './newmodules/report-description-modal/report-description-modal.component';
import { ReportDataComponent } from './newmodules/reports/report-data.component';
import { OrderConfirmComponent } from './newmodules/order-confirm/order-confirm.component';
import { OrderFilterComponent } from './newmodules/order-filter/order-filter.component';
import { ClinicsComponent } from './newmodules/clinic/clinics.component';
import { AddClinicModalComponent } from './newmodules/clinic/add-clinic-modal.component';
import { ForgotPasswordComponent } from './newmodules/forgot-password/forgot-password.component';
import { ContactInfoModalComponent } from './newmodules/order-details/contact-info-modal/contact-info-modal.component';
import { DentistInfoModalComponent } from './newmodules/order-details/dentist-info-modal/dentist-info-modal.component';
import { UserInfoModalComponent } from './newmodules/order-details/user-info-modal/user-info-modal.component';
import { ApproveOrderModalComponent } from './newmodules/order-details/approve-order-modal/approve-order-modal.component';
import { CertifyOrderModalComponent } from './newmodules/order-details/certify-order/certify-order-modal.component';
import { ConfirmationModalComponent } from './newmodules/confirm-modal/confirm-modal.component';
import { RateOrderModalComponent } from './newmodules/order-details/rate-order-modal/rate-order-modal.component';
import { AcceptOrderModalComponent } from './newmodules/order-details/accept-order-modal/accept-order-modal.component';
import { AddImageModalComponent } from './newmodules/order-details/add-image-modal/add-image-modal.component';
import { SampleTypeComponent } from './newmodules/order-new-2/sample-type/sample-type.component';
import { SubCategoriesComponent } from './feature/order/pages/action-based-order/constructions/sub-categories/sub-categories.component';
import { SampleContentInfoComponent } from './newmodules/order-details/sample-content-info/sample-content-info.component';
import { AdditionalInfoComponent } from './newmodules/order-details/additional-info/additional-info.component';
import { ClinicsCardComponent } from './newmodules/clinic-card-view/clinics-card.component';
import { OrderListComponent } from './newmodules/order-list/order-list.component';
import { OrderStatusViewComponent } from './newmodules/order-status-view/order-status-view.component';
import { AddOrderMaterialModalComponent } from './newmodules/order-details/add-order-material-modal/add-order-material-modal.component';
import { ManageLabsComponent } from './newmodules/manage-labs/manage-labs.component';
import { ShowConnectedLabsItemComponent } from './newmodules/manage-labs/show-connected-labs-item.component';
import { MaterialPriceDiffModalComponent } from './newmodules/manage-labs/edit-lab/material-price-diff-modal/material-price-diff-modal.component';
import { EditLabComponent } from './newmodules/manage-labs/edit-lab/edit-lab.component';
import { MainCategoryModalComponent } from './newmodules/manage-actions/main-category-modal/main-category-modal.component';
import { SubCategoryModalComponent } from './newmodules/manage-actions/sub-category-modal/sub-category-modal.component';
import { DentistSearchFilterComponent } from './newmodules/dentist-search-filter/dentist-search-filter.component';
import { MaterialGroupModalComponent } from './newmodules/manage-actions/material-group-modal/material-group-modal.component';
import { AddActionModalComponent } from './newmodules/manage-actions/add-action-modal/add-action-modal.component';
import { OrderInfoComponent } from './newmodules/order-details/order-info/order-info.component';
import { LabsAvailabilityComponent } from './newmodules/manage-labs/labs-availability.component';
import { ManageActionComponent } from './newmodules/manage-actions/manage-actions.component';
import { ArchiveOrderModalComponents } from './newmodules/order-details/archive-order-modal/archive-order-modal.components';
import { ActionsDropdownComponent } from './feature/order/pages/action-based-order/constructions/actions-dropdown/actions-dropdown.component';
import { SchedulePickUpFromTruckIconModalComponent } from './newmodules/logistics/schedule-pick-up-from-truck-icon-modal/schedule-pick-up-from-truck-icon-modal';
import { PriceSpecModalComponent } from './newmodules/order-details/price-spec-modal/price-spec-modal.component';
import { PriceSpecListComponent } from './newmodules/order-details/price-spec-list/price-spec-list.component';
import { PatientComponent } from './feature/order/pages/action-based-order/patient/patient.component';
import { PatientOrderListComponent } from './feature/order/shared/patient-order-list/patient-order-list.component';
import { AdditionalDentistComponent } from './feature/order/pages/action-based-order/patient/additional-dentist/additional-dentist.component';
import { ManageParticipantsComponent } from './feature/order/pages/action-based-order/patient/manage-participants/manage-participants.component';
import { PatientOrderListItemComponent } from './feature/order/shared/patient-order-list/patient-order-list-item/patient-order-list-item.component';
import { DeliveryService } from './feature/order/services/delivery-service';
import { ConstructionSettingsService } from './feature/order/services/construction-settings-service';
import { ConstructionService } from './feature/order/services/construction-service';
import { RollbarService } from './rollbar';

import { ConstructionSettingsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/construction-settings.component';
import { ConstructionHeaderComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/construction-header/construction-header.component';
import { ConstructionActionListComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/construction-action-list/construction-action-list.component';
import { MandatoryConstructionSettingsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/mandatory-construction-settings/mandatory-construction-settings.component';
import { OptionalConstructionSettingsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/optional-construction-settings/optional-construction-settings.component';
import { ClaspMaterialComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/clasp-material/clasp-material.component';
import { OrthoColorComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/ortho-color/ortho-color.component';
import { ClaspTeethComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/clasp-teeth/clasp-teeth.component';
import { ClaspTypeComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/clasp-type/clasp-type.component';
import { CommentsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/comments/comments.component';
import { ContactTypeComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/contact-type/contact-type.component';
import { DentureStepComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/denture-step/denture-step.component';
import { ExpansionScrewComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/expansion-screw/expansion-screw.component';
import { FacialLoopComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/facial-loop/facial-loop.component';
import { ImplantStepComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/implant-step/implant-step.component';
import { MaterialComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/material/material.component';
import { MetalDesignComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/metal-design/metal-design.component';
import { NumberOfUnitsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/number-of-units/number-of-units.component';
import { OcclusionComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/occlusion/occlusion.component';
import { PonticDesignComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/pontic-design/pontic-design.component';
import { ProtrusionComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/protrusion/protrusion.component';
import { RetainmentComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/retainment/retainment.component';
import { SpringTeethComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/spring-teeth/spring-teeth.component';
import { SpringTypeComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/spring-type/spring-type.component';
import { StepComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/step/step.component';
import { TestingStepComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/testing-step/testing-step.component';
import { WarrantyComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/warranty/warranty.component';
import { ShadesAndColorsComponent } from './feature/order/shared/shades-and-colors/shades-and-colors.component';
import { BtnListTypeSettingComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/btn-list-type-setting/btn-list-type-setting';
import { PositiveNumberDirective } from './directives/positive-number.directive';
import { MultiselectSettingsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/multiselect-settings/multiselect-settings.component';
import { ConstructionBlocksComponent } from './feature/order/pages/action-based-order/constructions/construction-blocks/construction-blocks.component';
import { MainCategoriesComponent } from './feature/order/pages/action-based-order/constructions/main-categories/main-categories.component';
import { LimitedDentistFormComponent } from './feature/account/pages/limited-dentist-form/limited-dentist-form.component';
import { DentistSignupFormComponent } from './feature/account/pages/dentist-signup-form/dentist-signup-form.component';
import { ConstructionsComponent } from './feature/order/pages/action-based-order/constructions/constructions.component';
import { TeethStructureComponent } from './feature/order/pages/action-based-order/constructions/teeth-structure/teeth-structure.component';
import { ActionNotesComponent } from './feature/order/pages/action-based-order/constructions/action-notes/action-notes.component';
import { AddSimpleOrderActionModalComponent } from './newmodules/order-details/add-simple-order-action-modal/add-simple-order-action-modal.component';
import { FileUploadComponent } from './feature/order/pages/action-based-order/constructions/file-upload/file-upload.component';
import { ScheduledTransportsComponent } from './feature/transport/pages/scheduled-transports/scheduled-transports.component';
import { TransportOrderWizardComponent } from './feature/transport/pages/transport-order-wizard/transport-order-wizard.component';
import { OrderIdBarcodeComponent } from './newmodules/order-details/order-id-barcode/order-id-barcode.component';
import { DownloadSectionComponent } from './newmodules/order-details/download-section/download-section.component';
import { ThreeShapeUploadModalComponent } from './feature/order/pages/action-based-order/constructions/three-shape-upload-modal/three-shape-upload.component';
import { ThreeShapeScanViewComponent } from './feature/order/pages/action-based-order/three-shape-scan-view/three-shape-scan-view.component';
import { LogisticsCustomerComponent } from './feature/transport/pages/logistics-customer/logistics-customer.component';
import { TransportClinicsComponents } from './feature/transport/pages/transport-clinics/transport-clinics.components';
import { ClinicReceivedDeliveryModalComponent } from './newmodules/order-details/clinic-received-delivery-modal/clinic-received-delivery-modal.component';
import { LabWorkStartedModalComponent } from './newmodules/order-details/lab-work-started-modal/lab-work-started-modal.component';
import { OrderDetailsSubnavComponent } from './feature/order/pages/order-details-subnav/order-details-subnav.component';
import { ProductionConfirmModalComponent } from './newmodules/order-details/production-confirm-modal/production-confirm-modal.component';
import { WarrantyTypeModalComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/warranty-type-modal/warranty-type-modal.component';
import { DeviationsComponent } from './newmodules/order-details/deviations/deviations.component';
import { ErrorModalComponent } from './newmodules/error-modal/error-modal.component';
import { ThreeShapeInfoComponent } from './newmodules/order-details/three-shape-info/three-shape-info.component';
import { ThreeShapeRestorationsTableComponent } from './newmodules/order-details/three-shape-restorations-table/three-shape-restorations-table.component';
import { AddedMaterialsTableHeaderComponent } from './newmodules/order-details/added-materials/added-materials-table-header.component';
import { AddedActionsTableHeaderComponent } from './newmodules/order-details/added-actions/added-actions-table-header.component';
import { AddedActionsComponent } from './newmodules/order-details/added-actions/added-actions.component';
import { AddedMaterialsComponent } from './newmodules/order-details/added-materials/added-materials.component';
import { ThreeShapeAttachmentsComponent } from './newmodules/order-details/three-shape-attachments/three-shape-attachments.component';

const appModule = angular.module('app');

const getComponent = (component): angular.IDirectiveFactory => {
  return downgradeComponent({
    component: component
  }) as angular.IDirectiveFactory;
};

const getService = (service): angular.IDirectiveFactory => {
  return downgradeInjectable(service) as angular.IDirectiveFactory;
};

// Downgraded components
appModule.directive(
  'schedulePickUpFromTruckIconModal',
  getComponent(SchedulePickUpFromTruckIconModalComponent)
);
appModule.directive('priceSpecModal', getComponent(PriceSpecModalComponent));
appModule.directive('priceSpecList', getComponent(PriceSpecListComponent));
appModule.directive('federatedLogin', getComponent(FederatedLoginComponent));
appModule.directive(
  'federatedSelectOrganization',
  getComponent(FederatedSelectOrganizationComponent)
);
appModule.directive('languageSelector', getComponent(LanguageSelectorComponent));
appModule.directive('settings', getComponent(SettingsComponent));
appModule.directive('profile', getComponent(ProfileComponent));
appModule.directive('app', getComponent(AppRootComponent));
appModule.directive('bankidLogin', getComponent(BankIdLoginComponent));
appModule.directive('labTechnician', getComponent(LabTechnicianComponent));
appModule.directive('labTechnicianCard', getComponent(LabTechnicianCardComponent));
appModule.directive('login', getComponent(LoginComponent));
appModule.directive('menuList', getComponent(MenuListComponent));
appModule.directive('inviteLabModal', getComponent(InviteLabModalComponent));
appModule.directive('labFilter', getComponent(LabFilterComponent));
appModule.directive('addLabModal', getComponent(AddLabModalComponent));
appModule.directive('addDentistModal', getComponent(AddDentistModalComponent));
appModule.directive('changeDentistModal', getComponent(ChangeDentistModalComponent));
appModule.directive('clinicFilter', getComponent(ClinicFilterComponent));
appModule.directive(
  'addLabTechnicianModal',
  getComponent(AddLabTechnicianModalComponent)
);
appModule.directive('labCard', getComponent(LabCardComponent));
appModule.directive('search', getComponent(SearchComponent));
appModule.directive('users', getComponent(UsersComponent));
appModule.directive('denthubHeader', getComponent(DenthubHeaderComponent));
appModule.directive('usersCard', getComponent(UsersCardComponent));
appModule.directive('lab', getComponent(LabComponent));
appModule.directive('customerSettings', getComponent(CustomerSettingsComponent));
appModule.directive('permissions', getComponent(PermissionsComponent));
appModule.directive('addRoleModal', getComponent(AddRoleModalComponent));
appModule.directive(
  'reportDescriptionModal',
  getComponent(ReportDescriptionModalComponent)
);
appModule.directive('reportData', getComponent(ReportDataComponent));
appModule.directive('cancelOrderModal', getComponent(CancelOrderModalComponent));
appModule.directive('orderConfirm', getComponent(OrderConfirmComponent));
appModule.directive('resetPassword', getComponent(ResetPasswordComponent));
appModule.directive('notifications', getComponent(NotificationsComponent));
appModule.directive('orderFilter', getComponent(OrderFilterComponent));
appModule.directive('declineOrderModal', getComponent(DeclineOrderModalComponent));
appModule.directive('chat', getComponent(ChatComponent));
appModule.directive('careAdvice', getComponent(CareAdviceComponent));
appModule.directive('addClinicModal', getComponent(AddClinicModalComponent));
appModule.directive('forgotPassword', getComponent(ForgotPasswordComponent));
appModule.directive('clinicsCard', getComponent(ClinicsCardComponent));
appModule.directive('clinics', getComponent(ClinicsComponent));
appModule.directive('contactInfoModal', getComponent(ContactInfoModalComponent));
appModule.directive('dentistInfoModal', getComponent(DentistInfoModalComponent));
appModule.directive('userInfoModal', getComponent(UserInfoModalComponent));
appModule.directive('approveOrderModal', getComponent(ApproveOrderModalComponent));
appModule.directive('certifyOrderModal', getComponent(CertifyOrderModalComponent));
appModule.directive('confirmationModal', getComponent(ConfirmationModalComponent));
appModule.directive('rateOrderModal', getComponent(RateOrderModalComponent));
appModule.directive('acceptOrderModal', getComponent(AcceptOrderModalComponent));
appModule.directive('addImageModal', getComponent(AddImageModalComponent));
appModule.directive('sampleType', getComponent(SampleTypeComponent));
appModule.directive('subCategories', getComponent(SubCategoriesComponent));
appModule.directive('sampleContentInfo', getComponent(SampleContentInfoComponent));
appModule.directive('additionalInfo', getComponent(AdditionalInfoComponent));
appModule.directive('orderList', getComponent(OrderListComponent));
appModule.directive('archiveOrderModal', getComponent(ArchiveOrderModalComponents));
appModule.directive('orderStatusView', getComponent(OrderStatusViewComponent));
appModule.directive(
  'clinicReceivedDeliveryModal',
  getComponent(ClinicReceivedDeliveryModalComponent)
);
appModule.directive(
  'labWorkStartedModal',
  getComponent(LabWorkStartedModalComponent)
);
appModule.directive(
  'showConnectedLabsItem',
  getComponent(ShowConnectedLabsItemComponent)
);
appModule.directive(
  'materialPriceDiffModal',
  getComponent(MaterialPriceDiffModalComponent)
);
appModule.directive(
  'addOrderMaterialModal',
  getComponent(AddOrderMaterialModalComponent)
);
appModule.directive('editLab', getComponent(EditLabComponent));
appModule.directive('mainCategoryModal', getComponent(MainCategoryModalComponent));
appModule.directive('subCategoryModal', getComponent(SubCategoryModalComponent));
appModule.directive('mainCategoryModal', getComponent(MainCategoryModalComponent));
appModule.directive(
  'dentistSearchFilter',
  getComponent(DentistSearchFilterComponent)
);
appModule.directive('labsAvailability', getComponent(LabsAvailabilityComponent));
appModule.directive('materialGroupModal', getComponent(MaterialGroupModalComponent));
appModule.directive('orderInfo', getComponent(OrderInfoComponent));
appModule.directive(
  'threeShapeRestorationsTable',
  getComponent(ThreeShapeRestorationsTableComponent)
);
appModule.directive(
  'addedMaterialsTableHeader',
  getComponent(AddedMaterialsTableHeaderComponent)
);
appModule.directive(
  'addedActionsTableHeader',
  getComponent(AddedActionsTableHeaderComponent)
);
appModule.directive('addedActions', getComponent(AddedActionsComponent));
appModule.directive('addedMaterials', getComponent(AddedMaterialsComponent));
appModule.directive('threeShapeInfo', getComponent(ThreeShapeInfoComponent));
appModule.directive(
  'threeShapeAttachments',
  getComponent(ThreeShapeAttachmentsComponent)
);
appModule.directive('mainCategoryModal', getComponent(MainCategoryModalComponent));
appModule.directive('subCategoryModal', getComponent(SubCategoryModalComponent));
appModule.directive('manageLabs', getComponent(ManageLabsComponent));
appModule.directive('addActionModal', getComponent(AddActionModalComponent));
appModule.directive('manageActions', getComponent(ManageActionComponent));
appModule.directive('actionsDropdown', getComponent(ActionsDropdownComponent));
appModule.directive('patient', getComponent(PatientComponent));
appModule.directive('logisticsCustomer', getComponent(LogisticsCustomerComponent));
appModule.directive('transportClinics', getComponent(TransportClinicsComponents));
appModule.directive('additionalDentist', getComponent(AdditionalDentistComponent));
appModule.directive('manageParticipants', getComponent(ManageParticipantsComponent));

appModule.directive('patientOrderList', getComponent(PatientOrderListComponent));
appModule.directive(
  'patientOrderListItem',
  getComponent(PatientOrderListItemComponent)
);
appModule.directive(
  'constructionSettings',
  getComponent(ConstructionSettingsComponent)
);
appModule.directive('constructionHeader', getComponent(ConstructionHeaderComponent));
appModule.directive(
  'constructionActionList',
  getComponent(ConstructionActionListComponent)
);
appModule.directive(
  'mandatoryConstructionSettings',
  getComponent(MandatoryConstructionSettingsComponent)
);
appModule.directive(
  'optionalConstructionSettings',
  getComponent(OptionalConstructionSettingsComponent)
);
appModule.directive('claspMaterial', getComponent(ClaspMaterialComponent));
appModule.directive('orthoColor', getComponent(OrthoColorComponent));
appModule.directive('claspTeeth', getComponent(ClaspTeethComponent));
appModule.directive('claspType', getComponent(ClaspTypeComponent));
appModule.directive('comments', getComponent(CommentsComponent));
appModule.directive('contactType', getComponent(ContactTypeComponent));
appModule.directive('dentureStep', getComponent(DentureStepComponent));
appModule.directive('expansionScrew', getComponent(ExpansionScrewComponent));
appModule.directive('facialLoop', getComponent(FacialLoopComponent));
appModule.directive('implantStep', getComponent(ImplantStepComponent));
appModule.directive('material', getComponent(MaterialComponent));
appModule.directive('metalDesign', getComponent(MetalDesignComponent));
appModule.directive('numberOfUnits', getComponent(NumberOfUnitsComponent));
appModule.directive('occlusion', getComponent(OcclusionComponent));
appModule.directive('ponticDesign', getComponent(PonticDesignComponent));
appModule.directive('protrusion', getComponent(ProtrusionComponent));
appModule.directive('retainment', getComponent(RetainmentComponent));
appModule.directive('springTeeth', getComponent(SpringTeethComponent));
appModule.directive('springType', getComponent(SpringTypeComponent));
appModule.directive('step', getComponent(StepComponent));
appModule.directive('testingStep', getComponent(TestingStepComponent));
appModule.directive('warranty', getComponent(WarrantyComponent));
appModule.directive('warrantyTypeModal', getComponent(WarrantyTypeModalComponent));
appModule.directive('shadesAndColors', getComponent(ShadesAndColorsComponent));
appModule.directive('btnListTypeSetting', getComponent(BtnListTypeSettingComponent));
appModule.directive(
  'multiselectSettings',
  getComponent(MultiselectSettingsComponent)
);
appModule.directive('constructionBlocks', getComponent(ConstructionBlocksComponent));
appModule.directive('mainCategories', getComponent(MainCategoriesComponent));
appModule.directive('constructions', getComponent(ConstructionsComponent));
appModule.directive('teethStructure', getComponent(TeethStructureComponent));
appModule.directive('actionNotes', getComponent(ActionNotesComponent));
appModule.directive('orderDetailsSubnav', getComponent(OrderDetailsSubnavComponent));
appModule.directive('fileUpload', getComponent(FileUploadComponent));
appModule.directive('limitedDentistForm', getComponent(LimitedDentistFormComponent));
appModule.directive('dentistSignupForm', getComponent(DentistSignupFormComponent));
appModule.directive(
  'productionConfirmModal',
  getComponent(ProductionConfirmModalComponent)
);
appModule.directive('deviations', getComponent(DeviationsComponent));
appModule.directive('errorModal', getComponent(ErrorModalComponent));
appModule.directive(
  'addSimpleOrderActionModal',
  getComponent(AddSimpleOrderActionModalComponent)
);

appModule.directive(
  'scheduledTransports',
  getComponent(ScheduledTransportsComponent)
);
appModule.directive(
  'transportOrderWizard',
  getComponent(TransportOrderWizardComponent)
);
appModule.directive('orderIdBarcode', getComponent(OrderIdBarcodeComponent));
appModule.directive('downloadSection', getComponent(DownloadSectionComponent));
appModule.directive(
  'threeShapeUploadModal',
  getComponent(ThreeShapeUploadModalComponent)
);
appModule.directive('threeShapeScanView', getComponent(ThreeShapeScanViewComponent));

// Downgraded services and factories
appModule.factory('rollbar', downgradeInjectable(RollbarService));
appModule.factory('usersGraphqlService', downgradeInjectable(UsersGraphqlService));
appModule.factory(
  'logisticGraphqlService',
  downgradeInjectable(LogisticGraphqlService)
);
appModule.factory('cacheService', downgradeInjectable(CacheService));
appModule.factory('currentUserService', downgradeInjectable(CurrentUserService));
appModule.factory('organizationService', downgradeInjectable(OrganizationService));
appModule.factory('getAllOrdersService', downgradeInjectable(GetAllOrdersService));
appModule.factory('getAllUsersService', downgradeInjectable(GetAllUsersService));
appModule.factory('logService', downgradeInjectable(LogService));
appModule.factory(
  'organizationSettingService',
  downgradeInjectable(OrganizationSettingService)
);
appModule.factory('permissionService', downgradeInjectable(PermissionService));
appModule.factory('fileUploadService', downgradeInjectable(FileUploadService));
appModule.factory('sessionService', downgradeInjectable(SessionService));
appModule.factory('userRoleService', downgradeInjectable(UserRoleService));
appModule.factory('logoImageService', downgradeInjectable(LogoImageService));
appModule.factory('userService', downgradeInjectable(UsersService));
appModule.factory('materialService', downgradeInjectable(MaterialService));
appModule.factory('reportsService', downgradeInjectable(ReportsService));
appModule.factory('categoryService', downgradeInjectable(CategoryService));
appModule.factory('roleService', downgradeInjectable(RoleService));
appModule.factory('orderService', downgradeInjectable(OrderService));
appModule.factory('invoiceService', downgradeInjectable(InvoiceService));
appModule.factory('actionService', downgradeInjectable(ActionService));
appModule.factory('tokenService', downgradeInjectable(TokenService));
appModule.factory('eventService', downgradeInjectable(EventService));
appModule.factory('denthubSettings', downgradeInjectable(DenthubSettingsService));
appModule.factory('customerService', downgradeInjectable(CustomerService));
appModule.factory('printService', downgradeInjectable(PrintService));
appModule.factory('translationsService', downgradeInjectable(TranslationsService));
appModule.factory(
  'localeFormatterService',
  downgradeInjectable(LocaleFormatterService)
);
appModule.factory('subNavService', downgradeInjectable(SubNavService));
appModule.factory('formatReportService', downgradeInjectable(FormatReportService));
appModule.factory('json2CSVService', downgradeInjectable(Json2CSVService));
appModule.factory('json2ExcelService', downgradeInjectable(Json2ExcelService));
appModule.factory('regexService', downgradeInjectable(RegexService));
appModule.factory(
  'patientCategoryService',
  downgradeInjectable(PatientCategoryService)
);
appModule.factory(
  'customerYearPrefixService',
  downgradeInjectable(CustomerYearPrefixService)
);
appModule.factory('deliveryService', downgradeInjectable(DeliveryService));
appModule.factory(
  'constructionSettingsService',
  downgradeInjectable(ConstructionSettingsService)
);
appModule.factory('constructionService', downgradeInjectable(ConstructionService));
appModule.factory('positiveNumber', downgradeInjectable(PositiveNumberDirective));
appModule.factory('ordersGraphqlService', downgradeInjectable(OrdersGraphqlService));
appModule.factory(
  'imageAnnotationService',
  downgradeInjectable(ImageAnnotationService)
);
appModule.factory(
  'newOrderListRedirectService',
  downgradeInjectable(NewOrderListRedirectService)
);
appModule.factory(
  'newFrontendNavigateService',
  downgradeInjectable(NewFrontendNavigateService)
);

appModule.factory(
  'escapedMessageService',
  downgradeInjectable(EscapedMessageService)
);

appModule.factory(
  'countryNameService',
  downgradeInjectable(CountryNameService)
);

// Downgraded constants
appModule.constant('translationEnglish', translationEnglish);
appModule.constant('translationSwedish', translationSwedish);
