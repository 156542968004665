/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-2.html'
/**
 * Created By Sumit
 * New order 2.0 component
 */
(function () {
  'use strict';
  angular.module('app').component('orderNew2', {
    template: theTemplate,
    controller: orderNew2Controller,
    controllerAs: 'vm',
    bindings: angular.extend({}, sessionStateBindings, {
      showConstructionSettings: '=',
      selectedMainCategory: '=',
      selectedSubCategory: '='
    })
  });

  orderNew2Controller.$inject = [
    'subNavService',
    'orderService',
    '$filter',
    'logService',
    'toastr',
    '$location',
    '$scope',
    '$timeout',
    'PATIENT_CATEGORY',
    'organizationService',
    'organizationSettingService',
    'CUSTOMER_SETTINGS_CONSTANTS',
    'permissionService',
    '$state',
    'actionService',
    'categoryService',
    'DATE',
    'constructionService'
  ];

  /**
   * New order controller
   * @param subNavService
   * @param orderService
   * @param $filter
   * @param logService
   * @param toastr
   * @param $location
   * @param $scope
   * @param PATIENT_CATEGORY
   * @param organizationService
   * @param organizationSettingService
   * @param CUSTOMER_SETTINGS_CONSTANTS
   */
  function orderNew2Controller(
    subNavService,
    orderService,
    $filter,
    logService,
    toastr,
    $location,
    $scope,
    $timeout,
    PATIENT_CATEGORY,
    organizationService,
    organizationSettingService,
    CUSTOMER_SETTINGS_CONSTANTS,
    permissionService,
    $state,
    actionService,
    categoryService,
    DATE,
    constructionService
  ) {
    var vm = this;
    vm.directory = '';
    vm.isDiscountedPrice = false;
    vm.newOrderObj = {
      patientInfo: {},
      selectedLab: '',
      files: [],
      s3Url: [],
      labRank: 1,
      deliveryInfo: {
        exportOrder: false,
        price: 0
      },
      allConstructions: [],
      selectedConstructions: {},
      dentist: {},
      sample: {
        type: '',
        content: [],
        comments: ''
      },
      linkedDentists: [],
      linkedClinics: [],
      customParticipantEmails: [],
      linkedExtTechs: []
    };
    vm.showSpinner = false;

    //setting Export_production value if it is true at customer settings page
    if (
      organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.EXPORT_PRODUCTION
      )
    ) {
      vm.showExportOrderCheckBox = true;
    }
    var sampleContent = organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT
    );
    if (vm.newOrderObj && sampleContent) {
      vm.newOrderObj.sample.content = getFormattedSampleContent(sampleContent);
    }

    /**
     * Format data so it's compatible will sample-method content implementation
     * */
    function getFormattedSampleContent(contents) {
      return contents.map(function (content) {
        return {
          name: content,
          value: false
        };
      });
    }

    function checkOrderVersion() {
      var orderVersion = organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION
      );

      if (
        orderVersion !== CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.ACTION_BASED
      ) {
        console.log(
          'USER SHOULD HAVE VERSION ' + orderVersion + '! Redirecting with params',
          $location.search()
        );
        $state.go('app.order-new', $location.search());
      }
    }

    function initPrefill() {
      vm.isPatientCategoryEnabled = organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORY
      );
      if (vm.isPatientCategoryEnabled) {
        vm.patientCategories = organizationSettingService.getCustomerSetting(
          CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORIES
        );
      }

      var enablePrefill = organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_PREFILL
      );

      if (enablePrefill) {
        var parameters = $location.search();
        if (parameters) {
          if (parameters.patient_category) {
            parameters.patient_category = parameters.patient_category.replace(
              /[^a-zA-Z_ ]/g,
              ''
            );
          }
          var patientCategory =
            parameters.patient_category &&
            vm.patientCategories &&
            vm.patientCategories.find(function (thisPatientCategory) {
              return (
                thisPatientCategory.name.toLowerCase() ===
                parameters.patient_category.toLowerCase()
              );
            });
          if (patientCategory) {
            const patientCategoryName = patientCategory.name;
            patientCategory.name = patientCategoryName.toUpperCase();
            patientCategory.translatedName = $filter('translate')(
              CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORIES +
              '_' +
              patientCategoryName
            );
          }
          $timeout(() => {
            console.log(parameters);
            vm.newOrderObj.patientInfo = {
              personalIdentity: parameters.personal_number,
              firstName: parameters.first_name ? parameters.first_name.replace(/\t/g, ' ').trim() : '',
              lastName: parameters.last_name ? parameters.last_name.replace(/\t/g, ' ').trim() : '',
              patientCategory: patientCategory
            };
          }, 100);

          var clinicPromise;
          var clinicExtIdNum = parseInt(
            organizationSettingService.getCustomerSetting(
              CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_PREFILL_CLINIC_EXT_ID
            )
          );
          if (
            !_.isEmpty(parameters) &&
            clinicExtIdNum &&
            Number.isInteger(clinicExtIdNum) &&
            clinicExtIdNum !== 3 &&
            parameters['clinic_extid_' + clinicExtIdNum]
          ) {
            var extIdValue = parameters['clinic_extid_' + clinicExtIdNum];
            clinicPromise = organizationService.getOrganizationByExtId({
              extidnum: clinicExtIdNum,
              extid: extIdValue
            });
          } else if (parameters.clinic_extid_3) {
            clinicPromise = organizationService.getOrganization({
              id: parameters.clinic_extid_3
            });
          }

          if (clinicPromise) {
            clinicPromise.then(function (clinicResponse) {
              vm.newOrderObj.deliveryInfo = {
                clinic: clinicResponse.data._id,
                pickUpLocation: clinicResponse.data._id
              };
            });
          }
        }
      }
    }

    vm.sectionChanged = function (sections) {
      vm.sections = sections;
    };

    vm.documentsUploaded = function (documents) {
      vm.newOrderObj.files = documents;
    };

    vm.additionalDentistAdded = function (dentist) {
      vm.newOrderObj.dentist = dentist;
    };

    vm.patientInfoChanged = function (patientInfo) {
      vm.newOrderObj.patientInfo = patientInfo;
      vm.newOrderObj.isNewOrder = true;
    };

    vm.draftOrderSaved = function (order) {
      vm.newOrderObj = Object.assign({}, vm.newOrderObj, order);
    };

    vm.linkedUsersChanged = function (users) {
      vm.newOrderObj.linkedDentists = users;
    };

    vm.linkedClinicsChanged = function (clinics) {
      vm.newOrderObj.linkedClinics = clinics;
    };

    vm.customEmailsChanged = function (emails) {
      vm.newOrderObj.customParticipantEmails = emails;
    };

    vm.constructionInfoChanged = function (data) {
      vm.newOrderObj.allConstructions = data.constructions;
      vm.newOrderObj.additionalInfo = data.additionalInfo;
      vm.newOrderObj.selectedActions = data.selectedActions;
      vm.newOrderObj.isStepDateChanged = data.isStepDateChanged;
    };

    vm.onThreeShapeModalOpen = function () {
      window.top.postMessage(
        {
          eventType: 'open-attach-3shape-case-modal',
          orderData: {
            _id: vm.newOrderObj._id,
            patient: { 
              personalIdentityNumber: vm.newOrderObj.patientInfo.personalIdentity, 
              firstName: vm.newOrderObj.patientInfo.firstName,
              lastName: vm.newOrderObj.patientInfo.lastName
            },
            attachedCaseFiles: vm.orderIdToModify ? vm.newOrderObj.attachedCaseFiles || [] : []
          }
        },
        '*'
      );
    }

    /**
     * Method to add menus to sub nav bar
     */
    function addSubNavMenus() {
      // we could use /addMenus function as well for bulk
      subNavService.resetList();
      //push menus to sub nav
      for (var menu = 0; menu < vm.menus.length; menu++) {
        subNavService.addMenu({
          name: vm.menus[menu].name,
          icon: '',
          action: vm.menus[menu].action,
          isActive: vm.menus[menu].isActive,
          isNewOrderVersion2: vm.menus[menu].isNewOrderVersion2
        });
      }
    }

    function addMenu() {
      var isLabUser = permissionService.isLabTechnician(vm.currentUser);
      vm.sections = {
        showPatientSections: true,
        showConstructionSections: false,
        showDeliverySections: false,
        showSignSendSections: false
      };
      vm.menus = [
        {
          name: $filter('translate')('1_PATIENT'),
          isActive: true,
          action: 'patient-section',
          isNewOrderVersion2: true
        },
        {
          name: $filter('translate')('2_CONSTRUCTIONS'),
          isActive: false,
          action: 'construction-section',
          isNewOrderVersion2: true
        },
        {
          name: $filter('translate')('3_LAB_AND_DELIVERY'),
          isActive: false,
          action: 'delivery-section',
          isNewOrderVersion2: true
        },
        {
          name: $filter('translate')('4_SIGN_AND_SEND'),
          isActive: false,
          action: 'sign-section',
          isNewOrderVersion2: true
        }
      ];
      var orderId = $location.search().orderId;
      if (orderId) {
        if (isLabUser) {
          vm.sections = {
            showConstructionSections: true,
            showDeliverySections: false,
            showSignSendSections: false
          };
          vm.menus = [
            {
              name: $filter('translate')('1_CONSTRUCTIONS'),
              isActive: true,
              action: 'construction-section',
              isNewOrderVersion2: true
            },
            {
              name: $filter('translate')('2_LAB_AND_DELIVERY'),
              isActive: false,
              action: 'delivery-section',
              isNewOrderVersion2: true
            },
            {
              name: $filter('translate')('3_SIGN_AND_SEND'),
              isActive: false,
              action: 'sign-section',
              isNewOrderVersion2: true
            }
          ];
        }
        vm.orderIdToModify = orderId;
      }
      addSubNavMenus();
    }

    async function getDraftOrder(orderId) {
      try {
        const { data: orderObj } = await orderService.getOrderById(orderId, true);
        vm.orderObj = orderObj;
        getActionSet(orderObj.actionSet ? orderObj.actionSet : vm.currentUser.organization.activeActionSet, orderObj.clinic._id);
      } catch (error) {
        console.log(error, 'error');
      }
    }
    function checkIfThreeShapeIsEnabled() {
      if (vm.currentUser && vm.currentUser.organization) {
          const isThreeShapeEnabled = organizationSettingService.getFromCustomerSettings(
              vm.currentUser.organization.customerSettings,
              CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.THREE_SHAPE_INTEGRATION
          );
          const isMeditEnabled = organizationSettingService.getFromCustomerSettings(
              vm.currentUser.organization.customerSettings,
              CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.MEDIT_INTEGRATION
          );
          const isScanCaseEnabled = organizationSettingService.getFromCustomerSettings(
              vm.currentUser.organization.customerSettings,
              CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SCAN_CASES
          );
          vm.isThreeShapeEnabled = isThreeShapeEnabled && isScanCaseEnabled;
          vm.isMeditEnabled = isMeditEnabled && isScanCaseEnabled;
      } else {
          vm.isThreeShapeEnabled = false;
          vm.isMeditEnabled = false;
      }
    }

    function getActionSet(actionSetId, orgId) {
      window.top.postMessage(
        { eventType: 'action-set', actionSetId: actionSetId, orgId: orgId },
        '*'
      );
    }

    function setModifyOrderProps(orderObj) {
      vm.newOrderObj._id = orderObj._id;
      vm.newOrderObj.patientInfo = orderObj.patient;
      vm.newOrderObj.patientInfo.personalIdentity =
        orderObj.patient.personalIdentityNumber;
      if (
        orderObj.patient.personalIdentityNumber &&
        !orderObj.patient.noPersonalIdentityNumber
      ) {
        vm.newOrderObj.validPin = true;
      }

      vm.showSpinner = false;
      vm.newOrderObj.originalLabRank1 = orderObj.rankOneLab;
      vm.newOrderObj.isModifying = true;
      vm.newOrderObj.allAdditionalInfo = orderObj.allAdditionalInfo;
      vm.newOrderObj.allSampleComment = orderObj.allSampleComment;
      // vm.newOrderObj.files = orderObj.files;
      vm.newOrderObj.selectedLab = orderObj.lab._id;
      vm.selectedLab = orderObj.lab._id;
      vm.newOrderObj.savedPickUpAtClinicsValue =
        orderObj.deliveryDetails.isPickUpAtClinic;
      vm.newOrderObj.savedPrice = _.clone(orderObj.deliveryDetails.price);
      vm.newOrderObj.savedDeliverydate = _.cloneDeep(
        orderObj.deliveryDetails.date
      );
      vm.newOrderObj.clinics = [orderObj.deliveryDetails.destination];
      vm.newOrderObj.clinic = orderObj.clinic;
      vm.newOrderObj.attachedCaseFiles = orderObj.attachedCaseFiles;
      vm.newOrderObj.type = orderObj.type;
      if (
        orderObj.deliveryDetails.destination._id !==
        orderObj.deliveryDetails.pickUpLocation._id
      ) {
        vm.newOrderObj.clinics.push(orderObj.deliveryDetails.pickUpLocation);
      }
      if (!Object.keys(vm.newOrderObj.selectedConstructions).length) {
        vm.newOrderObj.selectedConstructions = getConstructions(
          orderObj.newConstructions.constructions
        );
        constructionService.selectedConstructions =
          vm.newOrderObj.selectedConstructions;
        addToConstructions(vm.newOrderObj.selectedConstructions);
      }
      vm.newOrderObj.orderObjId = orderObj._id;
      if (orderObj.signedUrl && orderObj.signedUrl.length) {
        vm.newOrderObj.files = orderObj.signedUrl.map((file) => {
          return Object.assign(file, {
            isImage: /image/.test(file.contentType)
          });
        });
      }
      if (orderObj.created) {
        vm.newOrderObj.createdBy = orderObj.created.by;
      }
      vm.newOrderObj.dentist = orderObj.dentist;
      vm.newOrderObj.invoice = orderObj.invoice;
      vm.newOrderObj.approvedBy = orderObj.approvedBy;
      vm.newOrderObj.linkedDentists = orderObj.linkedDentists;
      vm.newOrderObj.linkedClinics = orderObj.linkedClinics;
      vm.newOrderObj.customParticipantEmails = orderObj.customParticipantEmails;
      vm.newOrderObj.linkedExtTechs = orderObj.linkedExtTechs;
      setDeliveryInfo(orderObj);
      setSampleAndAdditionalInfo(orderObj);
      vm.directory =
        'organizations/' +
        vm.newOrderObj.clinic._id +
        '/orders/' +
        vm.newOrderObj.orderObjId;
      vm.isDiscountedPrice = orderObj.priceSpecification?.some((priceSpec) => priceSpec.originalPrice && priceSpec.originalPrice !== priceSpec.price);
    }

    function setDraftOrderProps(orderObj) {
      vm.newOrderObj = Object.assign({}, orderObj);
      if (vm.newOrderObj.isWarrantyOrder || vm.newOrderObj.isCopyOrder) {
        if (
          orderObj.deliveryDetails?.destination._id !==
          orderObj.deliveryDetails?.pickUpLocation._id
        ) {
          vm.newOrderObj.clinics.push(orderObj.deliveryDetails?.pickUpLocation);
        }
        vm.newOrderObj.clinics = [orderObj.deliveryDetails?.destination];
        vm.newOrderObj.clinic = orderObj.clinic;
        vm.newOrderObj.allAdditionalInfo = orderObj.allAdditionalInfo;
        vm.newOrderObj.allSampleComment = orderObj.allSampleComment;
      }
      vm.newOrderObj.linkedDentists = orderObj.linkedDentists;
      vm.newOrderObj.linkedClinics = orderObj.linkedClinics;
      vm.newOrderObj.customParticipantEmails = orderObj.customParticipantEmails;
      vm.newOrderObj.sample = {};
      vm.newOrderObj.isNewOrder = true;
      setDeliveryInfo(orderObj);
      setSampleAndAdditionalInfo(orderObj);
      vm.newOrderObj.patientInfo = orderObj.patient;
      vm.newOrderObj.patientInfo.personalIdentity =
        orderObj.patient.personalIdentityNumber;
      if (
        orderObj.patient.personalIdentityNumber &&
        !orderObj.patient.noPersonalIdentityNumber
      ) {
        vm.newOrderObj.validPin = true;
      }
      vm.showSpinner = false;
      if (orderObj.lab) {
        vm.newOrderObj.selectedLab = orderObj.lab._id;
        vm.selectedLab = orderObj.lab._id;
      }
      vm.newOrderObj.savedPickUpAtClinicsValue =
        orderObj.deliveryDetails?.isPickUpAtClinic;
      vm.newOrderObj.savedPrice = _.clone(orderObj.deliveryDetails?.price);
      vm.newOrderObj.savedDeliverydate = _.cloneDeep(orderObj.deliveryDetails?.date);
      vm.newOrderObj.allConstructions = [];
      if (
        orderObj.newConstructions &&
        (!vm.newOrderObj.selectedConstructions ||
          !Object.keys(vm.newOrderObj.selectedConstructions).length)
      ) {
        vm.newOrderObj.selectedConstructions = getConstructions(
          orderObj.newConstructions.constructions
        );
        constructionService.selectedConstructions =
          vm.newOrderObj.selectedConstructions;
        addToConstructions(vm.newOrderObj.selectedConstructions);
      }
      if (orderObj.signedUrl && orderObj.signedUrl.length) {
        vm.newOrderObj.files = orderObj.signedUrl.map((file) => {
          return Object.assign(file, {
            isImage: /image/.test(file.contentType)
          });
        });
      }
      if (orderObj.created) {
        vm.newOrderObj.createdBy = orderObj.created.by;
      }
    }

    vm.$onInit = function () {
      //For getting data from new frontend
      window.addEventListener('message', handleEventsFromNewFE);
      try {
        vm.orderIdToModify = $location.search().orderId;
        vm.isLabUser = permissionService.isLabTechnician(vm.currentUser);
        if (
          $state.current.name === 'app.draft-order' &&
          $state.params.orderId
        ) {
          vm.showSpinner = true;
          if (!vm.isLabUser) {
            vm.newOrderObj._id = $state.params.orderId;
            getDraftOrder($state.params.orderId);
          } else {
            vm.orderIdToModify = $state.params.orderId;
            getDraftOrder($state.params.orderId);
          }
        } else if (!vm.orderIdToModify) {
          checkOrderVersion();
          getActionSet(vm.currentUser.organization.activeActionSet, vm.currentUser.organization._id);
        } else {
          if (!vm.isLabUser) {
            vm.showSpinner = true;
          }
          getOrderDetails(vm.orderIdToModify);
        }
        checkIfThreeShapeIsEnabled();
        addMenu();
        initPrefill();
      } catch (error) {
        console.log(error, 'error');
      }
    };

    function setDeliveryInfo(orderObj) {
      vm.newOrderObj.deliveryInfo = Object.assign({}, orderObj.deliveryDetails);
      if (orderObj.deliveryDetails?.date) {
        vm.newOrderObj.deliveryInfo.date = $filter('date')(
          orderObj.deliveryDetails.date,
          DATE.FORMAT
        );
      }
      if (orderObj.deliveryDetails?.destination) {
        vm.newOrderObj.deliveryInfo.clinic =
          orderObj.deliveryDetails.destination._id;
        vm.newOrderObj.deliveryInfo.destination =
          orderObj.deliveryDetails.destination._id;
      }
      if (orderObj.deliveryDetails?.pickUpLocation) {
        vm.newOrderObj.deliveryInfo.pickUpLocation =
          orderObj.deliveryDetails.pickUpLocation._id;
      }
    }

    function setSampleAndAdditionalInfo(orderObj) {
      //fetch sample content from customer setting when it was not saved in DB for old order
      if (!orderObj.deliveryDetails) {
        const sampleContent = organizationSettingService.getCustomerSetting(
          CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT
        );
        vm.newOrderObj.sample.content = getFormattedSampleContent(sampleContent);
      } else {
        vm.newOrderObj.sample = orderObj.deliveryDetails.sample;
      }
      //for current additionalInfo not showing in textbox
      if (
        vm.newOrderObj.deliveryInfo.additionalInfo &&
        vm.newOrderObj.additionalInfo
      ) {
        vm.newOrderObj.deliveryInfo.additionalInfo = '';
        vm.newOrderObj.additionalInfo = '';
      }
    }

    function formattedConstruction(construct, category) {
      const selectedType = category.subCategories.find((subCat) => {
        return construct.subCategory._id === subCat._id;
      });
      if (selectedType) {
        construct.type = selectedType.name.toLowerCase();
      }

      const materials = construct.actions.reduce((res, action) => {
        if (!action.materialGroup || !action.materialGroup.materials) {
          return res;
        }
        return res.concat(action.materialGroup.materials);
      }, []);
      const mat = materials.find((material) => {
        return material._id === construct.material;
      });
      if (mat) {
        construct.materialName = mat.material;
      }

      if (construct.teethDetails.isRange) {
        construct.teethRange = {
          start: construct.teethDetails.teethRange.start,
          end: construct.teethDetails.teethRange.end,
          isUpper: construct.teethDetails.teethRange.isUpper
        };
      } else if (construct.teethDetails.toothNumber?.length) {
        construct.teethNo = construct.teethDetails.toothNumber;
      } else {
        construct.teethNo = [];
        construct.teethRange = {};
      }
      return construct;
    }

    function getConstructions(constructions) {
      const obj = {};
      console.log(constructions, 'constructions');
      constructions.forEach((construct) => {
        if (!obj[construct.category.name]) {
          obj[construct.category.name] = {};
        }
        if (!obj[construct.category.name][construct.subCategory.name]) {
          obj[construct.category.name][construct.subCategory.name] = [];
        }
        construct.mainCategory = construct.category;
        const category = vm.categoryTree.find((categoryObj) => {
          return categoryObj._id === construct.mainCategory._id;
        });
        construct.actions.forEach((action) => {
          construct.constructionType = action.teethOption;
          action.subCategory = action.subCategory.map((subCategory) => {
            return category.subCategories.find((subCat) => {
              if (subCategory._id) {
                return subCat._id === subCategory._id;
              }
              return subCat._id === subCategory;
            });
          });
        });
        construct = formattedConstruction(construct, category);
        obj[construct.category.name][construct.subCategory.name].push(construct);
      });
      return obj;
    }

    vm.pushToConstruction = function (constructionCategoryObj) {
      Object.keys(constructionCategoryObj).forEach((key) => {
        vm.newOrderObj.allConstructions.push(constructionCategoryObj[key]);
      });
      vm.newOrderObj.allConstructions = _.sortBy(
        vm.newOrderObj.allConstructions.reduce(
          (result, element) => result.concat(element),
          []
        ),
        'createdAt'
      );
    };

    function addToConstructions(selectedConstructions) {
      Object.keys(selectedConstructions)
        .filter((key) => key !== 'type')
        .forEach((key) => {
          vm.pushToConstruction(selectedConstructions[key]);
        });
    }

    function getOrderDetails(orderId) {
      orderService
        .getOrderById(orderId)
        .then((response) => {
          const orderObj = response.data;
          vm.orderObj = orderObj;
          getActionSet(orderObj.actionSet, orderObj.clinic._id);
        })
        .catch(async (err) => {
          console.error(err, 'getOrderDetails - err');
          logService.error('order-new-2.component', 'getOrderDetails', err);
          toastr.error($filter('translate')('ERROR_IN_GETTING_ORDER'));
        });
    }

    $scope.$on('$destroy', () => {
      constructionService.resetSelectedConstructions();
      window.removeEventListener('message', handleEventsFromNewFE);
    });

    function handleAttachScanCase(scanCase) {
      if (!vm.newOrderObj.attachedCaseFiles || !vm.newOrderObj.attachedCaseFiles.length) {
        vm.newOrderObj.attachedCaseFiles = [scanCase];
        return;
      }
      const index = vm.newOrderObj.attachedCaseFiles.findIndex(function (sCase) {
        return sCase._id === scanCase._id;
      });
      if (index === -1) {
        vm.newOrderObj.attachedCaseFiles.push(scanCase);
      }
    }

    function handleUnlinkAttachScanCase(scanCaseId) {
      const index = vm.newOrderObj.attachedCaseFiles.findIndex(function (sCase) {
        return sCase._id === scanCaseId;
      });
      if (index !== -1) {
        vm.newOrderObj.attachedCaseFiles.splice(index, 1);
      }
    }

    function handleEventsFromNewFE(e) {
      if (
        e.data &&
        e.origin === process.env.NEW_APP_URL
      ) {
        switch (e.data.eventType) {
          case 'scan-case-attached':
            handleAttachScanCase(e.data.scanCase);
            break;
          case 'unlink-scan-case-attachment':
            handleUnlinkAttachScanCase(e.data.unlinkScanCaseAttachment);
            break;
          case 'get-action-set':
            const actionSet = e.data.actionSet;
            vm.categoryTree = actionSet.categories;
            vm.actions = actionSet.actions;
            if ($state.current.name === 'app.draft-order') {
              setDraftOrderProps(vm.orderObj);
            } else if (vm.orderIdToModify) {
              setModifyOrderProps(vm.orderObj);
            }
            break;
          default:
            throw new Error('Unhandled event type: ' + e.data.eventType);
        }
      }
    }

    vm.onOpenUnlinkDialog = function (scanCaseId) {
      window.top.postMessage(
        {
          eventType: 'delete-scan-case-attachment',
          orderData: {
            _id: vm.newOrderObj._id,
            scanCaseId: scanCaseId,
          }
        },
        '*'
      );
    }
  }
})();
