import gql from 'graphql-tag';

export const userListTemplate = gql`
  query users(
    $organizationId: ID!
    $pageNo: Int!
    $limit: Int
    $skip: Int
    $filter: SearchString
  ) {
    users(
      organizationId: $organizationId
      pageNo: $pageNo
      limit: $limit
      skip: $skip
      filter: $filter
    ) {
      _id
      name {
        firstName
        lastName
      }
      organization {
        name
        _id
      }
      email
      role
      systemSetting {
        clinic
      }
      active
    }
  }
`;

export const connectedToAppTemplate = gql`
  subscription connectedToApp {
    connectedToApp
  }
`;

export const createLimitedDentistTemplate = gql`
  mutation createLimitedDentist(
    $name: DentistName!
    $clinicName: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
    $organizationNumber: String!
    $contact: Contact
    $invoice: InvoiceAddress
    $contactInfo: ContactInfo
    $labId: String!
  ) {
    createLimitedDentist(
      name: $name
      clinicName: $clinicName
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      organizationNumber: $organizationNumber
      contact: $contact
      invoice: $invoice
      contactInfo: $contactInfo
      labId: $labId
    )
  }
`;

export const createDentistAccountTemplate = gql`
  mutation createDentistAccount(
    $name: DentistName!
    $clinicName: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
    $organizationNumber: String!
    $contact: Contact
    $invoice: InvoiceAddress
    $contactInfo: ContactInfo
  ) {
    createDentistAccount(
      name: $name
      clinicName: $clinicName
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      organizationNumber: $organizationNumber
      contact: $contact
      invoice: $invoice
      contactInfo: $contactInfo
    )
  }
`;
