import { Input, Output, Component, EventEmitter, OnInit } from '@angular/core';
import { MaterialListService } from '../../../../services/material-list/material-list.service';
import { TranslationsService } from '../../../../services/translations/translations.service';
import { LogService } from '../../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import {
  MaterialList,
  Organization
} from '../../../../models/organization.model';
import template from './add-material-list-modal.html';

@Component({
  selector: 'add-material-list-modal',
  template: template
})
export class AddMaterialListModalComponent implements OnInit {
  @Output() onModalClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCreatedNewList: EventEmitter<void> = new EventEmitter<void>();
  @Output() onMaterialListChanged: EventEmitter<
    Organization
  > = new EventEmitter<Organization>();
  @Input() materialList: Partial<MaterialList>;

  constructor(
    private readonly materialListService: MaterialListService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService
  ) {}

  ngOnInit() {
    if (!this.materialList) {
      this.materialList = {};
    }
  }

  cancel() {
    this.onModalClosed.emit();
  }

  async sucessCallback(organization: Organization, message: string, condition) {
    this.toastr.success(await this.translationsService.get(message));
    this.onMaterialListChanged.emit(organization);
    if (condition === 'created') {
      this.onCreatedNewList.emit();
    }
    this.onModalClosed.emit();
  }

  async errorCallback(message: string, method: string, err: Error){
    const text = await this.translationsService.get(message);
    this.logService.error(
      'add-material-list-modal.component',
      method,
      `${text}: ${err}`
    );
    this.toastr.error(text);
  }

  addMaterialList(): Promise<void> {
    const data = {
      name: this.materialList.name,
      description: this.materialList.description
    };
    return this.materialListService
      .addMaterialListForLab(data)
      .then(async (result) =>
        this.sucessCallback(result.data.materialLists, 'MATERIAL_LIST_CREATED', 'created')
      )
      .catch(async (err) =>
        this.errorCallback(
          'ERROR_IN_CREATING_MATERIAL_LIST',
          'addMaterialList',
          err
        )
      );
  }

  updateMaterialList(): Promise<void> {
    return this.materialListService
      .updateMaterialListForLab(this.materialList)
      .then(async (result) =>
        this.sucessCallback(result.data.materialLists, 'MATERIAL_LIST_UPDATED', null)
      )
      .catch(async (err) =>
        this.errorCallback(
          'ERROR_IN_UPDATING_MATERIAL_LIST',
          'updateMaterialList',
          err
        )
      );
  }
}
