import { Input, Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer/customer.service';
import { Organization } from '../../models/organization.model';
import template from './invoice-info.html';

@Component({
  selector: 'invoice-info',
  template: template
})
export class InvoiceInfoComponent implements OnInit {
  @Input() lab: Organization;
  @Input() isInvoicingEnabled: boolean;
  isEmailValid: boolean = true;
  isZipCodeValid: boolean = true;
  isValidOrgNumber: boolean = true;
  isValidVatNumber: boolean = true;

  constructor(private customerService: CustomerService) {}

  ngOnInit(): void {
    if (!this.lab.invoicing.invoiceAddress) {
      this.lab.invoicing.invoiceAddress = {};
    }
    if (!this.lab.invoicing.contactInfo) {
      this.lab.invoicing.contactInfo = {};
    }
    if (!this.lab.invoicing.contactInfo.address) {
      this.lab.invoicing.contactInfo.address = {};
    }
    if (!this.lab.invoicing.paymentDetails) {
      this.lab.invoicing.paymentDetails = {};
    }
    this.checkOrgNumber();
  }

  checkPostalCode(event: KeyboardEvent, zip: number): void {
    const key = event.which;
    if (!((key >= 48 && key <= 57) || (key >= 96 && key <= 105))) {
      event.preventDefault();
    }
    this.isZipCodeValid = zip >= 10000 && zip <= 99999;
  }

  /*
     check email validity
     */
  checkEmailValidity(email: string): void {
    this.isEmailValid = this.customerService.validateEmail(email);
  }

  checkVatNumber(): void {
    this.isValidVatNumber = new RegExp('^SE\\d{10,10}01$').test(
      this.lab.invoicing.vatNo
    );
  }

  checkOrgNumber(): void {
    if (
      new RegExp('^\\d{6,6}\\d{4,4}$').test(this.lab.invoicing.organizationNo)
    ) {
      this.lab.invoicing.organizationNo =
        this.lab.invoicing.organizationNo.substring(0, 6) +
        '-' +
        this.lab.invoicing.organizationNo.substring(6, 10);
      this.isValidOrgNumber = true;
      return;
    }
    this.isValidOrgNumber = new RegExp('^\\d{6,6}-\\d{4,4}$').test(
      this.lab.invoicing.organizationNo
    );
  }
}
