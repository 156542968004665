import template from './ta-package-picker.html';
import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ta-package-picker',
  template: template
})
export class TaPackagePicker {
  @Input() title: string;
  @Input() hint: string;
  @Input() readonly: boolean;
  @Input() noneOption: boolean;

  private _noOfPackages: number;
  @Output() noOfPackagesChange = new EventEmitter<number>();
  @Input()
  set noOfPackages(newValue: number) {
    this._noOfPackages = newValue;
    this.noOfPackagesChange.emit(newValue);
  }
  get noOfPackages(): number {
    return this._noOfPackages;
  }

  packagesNumbers = Array.from({ length: 20 }, (_, k) => k + 1);
}
