import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { ConnectedClinics } from '../../models/logistic.model';
import { TransportClinic } from '../../models/transport-clinic.model';
import { TranslationsService } from '../../services/translations/translations.service';
import {
  getLabsConnectedClinicsTemplate,
  addTransportClinicTemplate,
  getTransportClinicsTemplate,
  updateTransportClinicTemplate,
  deleteTransportClinicTemplate,
  addConnectedTransportClinicTemplate
} from './logistics-customer.graphql';

@Injectable({
  providedIn: 'root'
})
export class LogisticsCustomerGraphqlService {
  constructor(private readonly apollo: Apollo,
              private readonly translationsService: TranslationsService) {
  }

  getLabsConnectedClinics(): Promise<ConnectedClinics[]> {
    return new Promise((resolve, reject) => {
      this.apollo
        .query({
          query: getLabsConnectedClinicsTemplate,
          variables: {},
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['labsConnectedClinics']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  addTransportClinic(transportClinic: TransportClinic): Promise<TransportClinic> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: addTransportClinicTemplate,
          variables: transportClinic,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['addTransportClinic']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateTransportClinic(transportClinic: TransportClinic): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: updateTransportClinicTemplate,
          variables: transportClinic,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['updateTransportClinic']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  deleteTransportClinic(transportClinicId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: deleteTransportClinicTemplate,
          variables: { _id: transportClinicId },
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['deleteTransportClinic']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getTransportClinics(): Promise<TransportClinic[]> {
    return new Promise((resolve, reject) => {
      this.apollo
        .query({
          query: getTransportClinicsTemplate,
          variables: {},
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['transportClinics']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  addConnectedTransportClinic(connectedTransportClinic): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: addConnectedTransportClinicTemplate,
          variables: connectedTransportClinic,
          errorPolicy: 'all'
        })
        .subscribe(
          async ({ data, errors }) => {
            if (errors?.length) {
              errors[0].message = await this.translationsService.get(
                errors[0].message
              );
              reject(errors[0]);
              return;
            }
            resolve(cloneDeep(data['addConnectedTransportClinic']));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}