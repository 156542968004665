/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './show-labs.html'
/**
 * Show Labs Component
 */
(function () {
    'use strict';
    angular.module('app').component('showLabs', {
        template: theTemplate,
        controller: ShowLabsController,
        controllerAs: 'vm',
        bindings: sessionStateBindings
    });

    /**
     * Dependencies to Show Labs
     */
    ShowLabsController.$inject = [ 'logService', 'organizationService', 'searchLabTextService', 'toastr', '$filter', 'subNavService', 'CUSTOMER_SETTINGS_CONSTANTS', 'organizationSettingService', '$location' ];

    // Controller function for show labs component
    function ShowLabsController(logService, organizationService, searchLabTextService, toastr, $filter, subNavService, CUSTOMER_SETTINGS_CONSTANTS, organizationSettingService, $location) {

        // Scope and other variables
        var vm = this;
        vm.labs = [];
        vm.allLabs = [];
        vm.sortType = 1;
        vm.sortBy = 'isDenthub';
        vm.showSpinner = true;
        vm.currentPage = 1;
        vm.totalItems = '';
        vm.maxSize = 5;
        vm.itemsPerPage = 10;
        vm.labToSearch = [];

        // Member functions
        vm.getAllLabs = getAllLabs;
        vm.sortData = sortData;
        vm.searchSelectedLabs = searchSelectedLabs;
        vm.resetSearch = resetSearch;
        vm.itemsPagination = itemsPagination;
        vm.shouldShowConnectLabs = shouldShowConnectLabs;

        subNavService.resetList();
        subNavService.addMenu({
            name: $filter('translate')('CONNECT_TO_LAB'),
            state: 'app.show-lab',
            icon: 'fa-thermometer-three-quarters',
            isActive: true,
            isScrollSpy: false
        });

        function shouldShowConnectLabs () {
            return CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.ACTION_BASED !==
                organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION);
        }

        // init function
        function init() {
            getConnectedLabs();
        }

        function getSearchLabText() {
            searchLabTextService.getSearchLabText().then(function (result) {
                if (result) {
                    vm.labToSearch.push({ text: result });
                    searchSelectedLabs();
                } else {
                    getAllLabs();
                }
            }).catch(function (err) {
                logService.error('show-labs.component', 'init', $filter('translate')('ERROR_IN_SEARCH_LABS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_SEARCH_LABS'));
            });
        }

        //reset search when click on cross button
        function resetSearch() {
            vm.labToSearch = [];
            searchSelectedLabs();
        }

        // Get labs from the search query
        function getLabs(query) {
            organizationService.searchLabs({ query: query }).then(function (result) {
                vm.labs = result.data;
            }).catch(function (err) {
                logService.error('show-labs.component', 'getLabs', $filter('translate')('ERROR_IN_GETTING_LABS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_LABS'));
            });
        }

        //for pagination page changed
        function itemsPagination () {
            vm.labs = vm.allLabs.slice((vm.currentPage - 1) * 10, (vm.currentPage) * 10);
        }

        // Get list of all labs
        function getAllLabs () {
            organizationService.connectToLabs().then(function (result) {
                vm.allLabs = result.data;
                vm.labs = vm.allLabs.slice(0, 10);
                vm.totalItems = result.data.length;
                vm.showSpinner = false;
            }).catch(function (err) {
                logService.error('show-labs.component', 'getAllLabs', $filter('translate')('ERROR_IN_GETTING_LABS') + ": " + JSON.stringify(err));
                toastr.error($filter('translate')('ERROR_IN_GETTING_LABS'));
            });
        }

        /*
        sort Data
         */
        function sortData(field) {
            if (vm.sortBy === field) {
                vm.sortType = this.sortType === 1 ? -1 : 1;
                vm.sortOrder = this.sortOrder === true ? false : true;
                if (vm.sortType === '1') {
                    vm.sortBy = field;
                } else {
                    vm.sortBy = '-' + field;

                }
            } else {
                vm.sortBy = field;
                vm.sortType = 1;
                vm.sortOrder = true;
            }
        }

        // Method to search the typed labs
        function searchSelectedLabs() {
            if (vm.labToSearch.length) {
                var labs = [];
                for (var i in vm.labToSearch) {
                    labs.push(vm.labToSearch[i].text);
                }
                organizationService.searchSelectedLabs({ labs: labs }).then(function (result) {
                    vm.allLabs = result.data;
                    vm.labs = vm.allLabs.slice(0, 10);
                    vm.totalItems = result.data.length;
                    vm.showSpinner = false;
                }).catch(function (err) {
                    logService.error('show-labs.component', 'searchSelectedLabs', $filter('translate')('ERROR_IN_SEARCH_LABS') + ": " + err);
                    toastr.error($filter('translate')('ERROR_IN_SEARCH_LABS'));
                });
            } else {
                getAllLabs();
            }
        }

        function getConnectedLabs() {
            return organizationService.getConnectedLabsForClinic({ organizationId: vm.currentUser.organization._id }).then(function (result) {
                vm.clinic = result.data;
                getSearchLabText();
            }).catch(function (err) {
                logService.error('show-labs-item.component', 'getConnectedLabs', $filter('translate')('ERROR_IN_GETTING_LABS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_LABS'));
            });
        }

        vm.$onInit = function() {
            // Init Method call
            if (CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.ACTION_BASED ===
                organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION)) {
                //if it is action based order then redirect to home. This page should not be accessed to such users.
                $location.path("/");
                return;
            }
            init();
        };

    }

})();
