import { Injectable } from '@angular/core';
import { AccountModule } from '../account.module';
import { Organization } from '../../../models/organization.model';
import { LogService } from '../../../services/core/log.service';
import { OrganizationService } from '../../../services/clinic/organization.service';

@Injectable({
  providedIn: AccountModule
})
export class WhitelabelService {
  constructor(
    private readonly organizationService: OrganizationService,
    private readonly logService: LogService
  ) {}

  async fetchLabLogo(labId: string): Promise<Partial<Organization>> {
    try {
      return (
        await this.organizationService.getLabLogo({
          labId
        })
      ).data;
    } catch (err) {
      this.logService.error('getLabLogoImage', 'ERROR_IN_GETTING_LABS', err);
      throw err;
    }
  }
}
