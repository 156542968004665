import { Input, Component, Output, EventEmitter } from '@angular/core';
import { SubCategory } from '../../../../../../models/organization.model';
import {
  DEFAULT_COLOR_CODE,
  DEFAULT_SUBCATEGORY_COLOR_CODE
} from '../../../../../../app.constant';
import template from './sub-categories.html';
import { ConstructionPageService } from '../../../../services/construction-page-service';
import style from './sub-categories.less';

@Component({
  selector: 'sub-categories',
  template: template,
  styles: [style]
})
export class SubCategoriesComponent {
  @Input() subCategories: SubCategory[];
  @Output() subCategorySelected: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectedSubCategory: SubCategory;
  textColorCode: string = DEFAULT_COLOR_CODE.textColorCode;
  subCategorybackgroundColorCode: string =
    DEFAULT_SUBCATEGORY_COLOR_CODE.subCategorybackgroundColorCode;

  constructor(private readonly constructionPageService: ConstructionPageService) {}

  constructionTypeClass(subCategory: SubCategory) {
    return {
      background:
        this.selectedSubCategory?.name === subCategory.name &&
        this.selectedSubCategory?.colorCode
          ? this.selectedSubCategory?.colorCode
          : this.subCategorybackgroundColorCode,
      color:
        this.selectedSubCategory?.name === subCategory.name &&
        this.selectedSubCategory?.textColorCode
          ? this.selectedSubCategory?.textColorCode
          : this.textColorCode
    };
  }

  selectSubCategory(subCategory: SubCategory): void {
    this.selectedSubCategory = subCategory;
    this.constructionPageService.selectedSubCategory = subCategory;
    this.subCategorySelected.emit(subCategory);
  }
}
