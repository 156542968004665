import { forwardRef, Inject } from '@angular/core';
import { IAngularEvent } from 'angular';

/**
 * This is a temporary implementation of an event service compatible with AngularJS.
 * TODO: When each component is migrated to Angular which uses AngularJS events, migrate it to use this class instead.
 * TODO: Rewrite this with a modern Angular event system when application has been fully migrated to Angular
 */

export class EventService {
  constructor(@Inject(forwardRef(() => '$rootScope')) private $rootScope: any) {}

  emit(eventName: string, ...args: any[]): void {
    this.$rootScope.$emit(eventName, ...args);
  }

  broadcast(eventName: string, ...args: any[]): void {
    this.$rootScope.$broadcast(eventName, ...args);
  }

  on(
    eventName: string,
    eventHandler: (event: IAngularEvent, ...args: any[]) => any
  ): void {
    this.$rootScope.$on(eventName, eventHandler);
  }
}
