import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  Organization,
  MaterialPriceDifference
} from '../../../../models/organization.model';
import { OrganizationService } from '../../../../services/clinic/organization.service';
import { TranslationsService } from '../../../../services/translations/translations.service';
import { LogService } from '../../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import template from './material-price-diff-modal.html';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'material-price-diff-modal',
  template: template
})
export class MaterialPriceDiffModalComponent implements OnChanges {
  @Input() priceDiffIndex: number = -1;
  @Input() materialPriceDifference: MaterialPriceDifference[] = [];
  @Input() selectedActionSet: string;
  // TODO: Initialize this
  @Input() userOrg: Organization;
  @Input() labId: string = '';
  @Output() onModalClosed: EventEmitter<void> = new EventEmitter<void>();

  priceDiff: Partial<MaterialPriceDifference> = {};

  constructor(
    private organizationService: OrganizationService,
    private translationsService: TranslationsService,
    private logService: LogService,
    private toastr: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.priceDiffIndex &&
      changes.priceDiffIndex.currentValue !== undefined &&
      changes.priceDiffIndex.currentValue !== null
    ) {
      // to deep copy
      this.priceDiff = cloneDeep(
        this.materialPriceDifference[changes.priceDiffIndex.currentValue]
      );
    }
  }

  closeMaterialGroupModal(): void {
    this.priceDiff = {};
    this.onModalClosed.emit();
  }

  addUpdateMaterialPriceDiff(): Promise<void> {
    this.materialPriceDifference[this.priceDiffIndex].materials = cloneDeep(
      this.priceDiff.materials || []
    );
    this.materialPriceDifference[
      this.priceDiffIndex
    ].materialGroupName = this.priceDiff.materialGroupName;
    this.materialPriceDifference[
      this.priceDiffIndex
    ].materialGroupId = this.priceDiff.materialGroupId;
    const matPriceDiff = this.materialPriceDifference.filter((matPriceDiff) => {
      return (
        matPriceDiff.materials.some((mat: any) => mat.priceDiff) &&
        this.selectedActionSet === matPriceDiff.actionSet
      );
    });
    this.userOrg.connectedLabs &&
      this.userOrg.connectedLabs.forEach((cLab) => {
        if (cLab.lab && cLab.lab._id === this.labId) {
          var cLabMaterialPriceDiffIndex = cLab.materialPriceDifference.findIndex(
            (materialPriceDiff) => {
              return (
                materialPriceDiff.materialGroupId.toString() ===
                this.priceDiff.materialGroupId.toString()
              );
            }
          );
          if (cLabMaterialPriceDiffIndex !== -1) {
            const matPriceIndex = matPriceDiff.findIndex((matPrice) => {
              return (
                matPrice.materialGroupId ===
                cLab.materialPriceDifference[cLabMaterialPriceDiffIndex].materialGroupId
              );
            });
            cLab.materialPriceDifference.splice(
              cLabMaterialPriceDiffIndex,
              1,
              matPriceDiff[matPriceIndex]
            );
          } else {
            cLab.materialPriceDifference.push(
              this.priceDiff as MaterialPriceDifference
            );
          }
        }
      });
    return this.organizationService
      .updateOrganization(this.userOrg._id, this.userOrg)
      .then(async () => {
        this.closeMaterialGroupModal();
        this.toastr.success(
          await this.translationsService.get(
            'PRICE_DIFFERENCE_UPDATED_SUCCESSFULLY'
          )
        );
      })
      .catch(async (err) => {
        this.closeMaterialGroupModal();
        const text = await this.translationsService.get(
          'ERROR_IN_UPDATING_PRICE_DIFFERENCE'
        );
        this.logService.error(
          'material-price-diff-modal.component',
          'addUpdateMaterialPriceDiff',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }
}
