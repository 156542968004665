import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DEFAULT_COLOR_CODE } from '../../../app.constant';
import { Unit } from '../../../models/action.model';
import { SubCategory } from '../../../models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class ConstructionSettingsService {
  private _settings: {
    [key: string]: any;
  } = {};
  private readonly backgroundColorCode = DEFAULT_COLOR_CODE.backgroundColorCode;
  private readonly textColorCode = DEFAULT_COLOR_CODE.textColorCode;

  setSetting(key: string, value: string) {
    this._settings[key] = value;
  }

  setNumericSetting(key: string, value: number) {
    this._settings[key] = value;
  }

  setMultiSelectSetting(key: string, value: { id: number }[]) {
    this._settings[key] = value;
  }

  setStepTimeSetting(
    key: string,
    value: { [key: string]: { hour: number; minute: number } }
  ) {
    this._settings[key] = value;
  }

  setStepDateSetting(key: string, value: { [key: string]: string }) {
    this._settings[key] = value;
  }

  setBooleanSetting(key: string, value: boolean) {
    this._settings[key] = value;
  }

  removeNumberOfUnits(actionId: string) {
    const numberOfUnits = this._settings['numberOfUnits'];
    if (numberOfUnits && numberOfUnits[actionId]) {
      delete numberOfUnits[actionId];
    }
  }

  setNumberOfUnits(
    actionId: string,
    numberOfUnits: {
      [key: string]: Unit;
    }
  ) {
    if (numberOfUnits[actionId]) {
      if (numberOfUnits[actionId].isInteger) {
        numberOfUnits[actionId].numberOfUnits = parseInt(
          numberOfUnits[actionId].numberOfUnits?.toString()
        );
      } else {
        numberOfUnits[actionId].numberOfUnits = parseFloat(
          numberOfUnits[actionId].numberOfUnits?.toFixed(
            numberOfUnits[actionId].numberOfDecimals
          )
        );
      }
    }
    this._settings['numberOfUnits'] = numberOfUnits;
  }

  getUnits(actionId: string) {
    if (!this._settings.numberOfUnits || !this._settings.numberOfUnits[actionId]) {
      return 0;
    }
    return this._settings.numberOfUnits[actionId].numberOfUnits;
  }

  getColorClass(selectedSubCategory: SubCategory) {
    return {
      background: selectedSubCategory?.colorCode
        ? selectedSubCategory.colorCode
        : this.backgroundColorCode,
      color: selectedSubCategory?.textColorCode
        ? selectedSubCategory.textColorCode
        : this.textColorCode
    };
  }

  getSetting(type: string) {
    return this._settings[type];
  }

  resetSettings() {
    this._settings = {};
  }

  get settings() {
    return cloneDeep(this._settings);
  }

  set settings(settings) {
    this._settings = cloneDeep(settings);
  }

  isNoOfUnitsMandatory(numberOfUnits) {
    return Object.keys(numberOfUnits).some((actionId) => {
      return numberOfUnits[actionId].mandatory;
    });
  }

  isNoOfUnitsIncluded(numberOfUnits) {
    return Object.keys(numberOfUnits).some((actionId) => {
      return numberOfUnits[actionId].include;
    });
  }
}
