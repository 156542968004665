import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SHADES_AND_COLORS } from '../../../../app.constant';
import { ConstructionSettingsService } from '../../services/construction-settings-service';
import template from './shades-and-colors.html';

interface ShadesAndColors {
  name: string;
  colors: string[];
}

@Component({
  selector: 'shades-and-colors',
  template
})
export class ShadesAndColorsComponent implements OnChanges {
  @Input() shade: string;
  @Input() color: string;
  @Input() colorSampling: boolean;
  shades: ShadesAndColors[];
  colors: string[];

  private readonly shadeKey = 'shade';

  private readonly colorKey = 'color';

  private readonly colorSamplingKey = 'colorSampling';

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.shades = SHADES_AND_COLORS;
    if (!this.shade) {
      this.shade = this.shades[1].name;
      this.constructionSettingsService.setSetting(this.shadeKey, this.shade);
    }
    this.getColors();
    if (!changes.color?.currentValue) {
      this.color = '';
    }
  }

  colorSamplingChanged(colorSampling: boolean) {
    this.colorSampling = colorSampling;
    this.constructionSettingsService.setBooleanSetting(
      this.colorSamplingKey,
      colorSampling
    );
  }

  shadeSelected(shade: string) {
    this.shade = shade;
    this.constructionSettingsService.setSetting(this.shadeKey, shade);
    this.getColors();
    this.constructionSettingsService.setSetting(this.colorKey, '');
    this.color = '';
  }

  colorSelected(color: string) {
    this.color = color;
    this.constructionSettingsService.setSetting(this.colorKey, color);
  }

  getColors() {
    const shade = this.shades.find(
      (shadesAndColors) => shadesAndColors.name === this.shade
    );
    if (shade) {
      this.colors = shade.colors;
    }
  }
}
