import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Inject
} from '@angular/core';
import { Organization } from '../../../models/organization.model';

import template from './lab-list-item.html';
@Component({
  selector: 'lab-list-item',
  template: template
})
export class LabListItemComponent {
  @Output() onLabUpdate: EventEmitter<Organization> = new EventEmitter<
    Organization
  >();
  @Output() showUploadModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() lab: Organization;

  constructor(@Inject(forwardRef(() => '$state')) private $state: any) {}

  ngOnInit(): void {
    if (!this.lab.contact) {
      this.lab.contact = {};
    }
    if (!this.lab.contact.address) {
      this.lab.contact.address = {};
    }
  }

  editLab(): void {
    this.onLabUpdate.emit(this.lab);
  }

  openUploadModal() {
    this.showUploadModal.emit();
  }

  goToSettings(): void {
    this.$state.go('app.customer-settings', { id: this.lab._id });
  }
}
