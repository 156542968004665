export const translationTransportSwedish = {
  // COMMON
  PICKUP: 'Upphämtning',
  DELIVERY: 'Leverans',
  NO_OF_PACKAGES: 'Antal paket',
  NO_OF_PACKAGES_HINT: 'Antal paket som ska transporteras',
  NO_PACKAGES: 'Inga paket',
  COMMENTS: 'Kommentarer',
  INTERNAL_MESSAGE: 'Intern anteckning (visas ej för föraren)',
  OK: 'OK',
  PACKAGES: 'Paket',
  SAVE_CHANGES: 'Spara ändringar',
  CANCEL_TRANSPORT: 'Avbeställ transport',
  SOMETHING_WENT_WRONG: 'Något gick fel',

  // NAV MENU
  TRANSPORT: 'Transport',
  NEW_TRANSPORT_ORDER: 'Ny transportorder',
  TRANSPORT_ORDERS: 'Beställda transporter',
  DENTHUB_DESKTOP: 'Denthub',
  SETTINGS: 'Inställningar',
  LOGOUT: 'Logga ut',

  // MAIN PAGE
  PICKUP_FROM_CLINIC: 'Hämta från klinik',
  DELIVERY_TO_CLINIC: 'Lämna hos klinik',
  SHOW_ALL_TRANSPORT_ORDERS: 'Visa alla beställda transporter',

  // WIZARD
  CLINIC_STEP: 'Klinik',
  WHEN_STEP: 'När',
  DETAILS_STEP: 'Detaljer',
  NEXT: 'Nästa',
  ORDER_TRANSPORT: 'Beställ transport',
  CANCEL_ORDER_TRANSPORT: 'Avbryt beställningen',
  CANCEL_ORDER_MSG: 'Beställningen avbröts. Ingen transportorder har skapats.',
  WIZARD_GENERIC_ERROR_MSG: 'Något gick fel. Ingen transportorder har skapats.',
  ORDER_PLACED_MSG: 'Transport är beställd.',
  CHOOSE_CLINIC: 'Välj klinik',
  CHOOSE_CLINIC_HINT: 'Skriv namnet på kliniken för att söka',
  DELIVERY_DATE: 'Leveransdatum',
  DELIVERY_DATE_HINT: 'Datum då vi levererar till kliniken',
  NOTE_TRANSPORT_DAYS_HINT: 'Notera: Mer än 1 dag mellan upphämtning och leverans',
  PICKUP_FROM_YOU_DATE: 'Vi hämtar hos er:',
  PICKUP_DATE: 'Upphämtningsdatum',
  PICKUP_DATE_HINT: 'Dagen vi hämtar från kliniken',
  LATEST_DATE_DELIVERY: 'Senaste datum vi levererar till er:',
  EXISTING_TRANSPORT_ORDER_TITLE_PICKUP: 'Det finns redan en beställd upphämtning',
  EXISTING_TRANSPORT_ORDER_TITLE_DELIVERY: 'Det finns redan en beställd leverans',
  EXISTING_TRANSPORT_ORDER_TEXT:
    'Vill du ändra den existerande transportordern istället?',

  // DEADLINE PICKER
  DEADLINE: 'Deadline',
  DEADLINE_HINT: 'Senaste tid vi behöver leverera',

  // SCHEDULED TRANSPORTS
  PICKUPS: 'Upphämtningar',
  DELIVERIES: 'Leveranser',
  NO_TRANSPORTS_TODAY: 'Inga beställda transporter för denna dag.',
  PACKAGES_TRANSPORTED: 'paket transporterades',
  DENTHUB_ORDERS_TO_PICKUP: 'Denthub ordrar som hämtas denna dag',
  ADDITIONAL_PACKAGES: 'Tilläggspaket',
  ADDITIONAL_PACKAGES_HINT: 'Ytterligare paket som inte tillhör en denthub order',
  CAN_NOT_BE_MODIFIED: 'Kan inte ändras',
  ARE_YOU_SURE_CANCEL: 'Är du säker på att du vill avbeställa denna transport?',

  // Transport status log
  TRANSPORT_STATUS_LOG: 'Transportlogg',
  SHOW_DETAILS: 'Visa detaljer',
  EVENT: 'Händelse',
  NOTE_FROM_DRIVER: 'Meddelande från förare',
  NO_STATUS_LOG_AVAILABLE: 'Ingen status tillgänglig',
  TABLET_MODE: 'Switch to tablet mode',
  UNDER_OBSERVATION: 'Under observation'
};
