import { Injectable } from '@angular/core';
import { Action } from '../../../models/action.model';
import {
  ActionBasedConstruction,
  PriceSpecification
} from '../../../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class PriceSpecService {
  private readonly teethRangeStart = 18;
  private readonly teethRangeEnd = 38;
  private readonly percent = 100;

  calculatePrice(action) {
    if (!action.quantity) {
      if (this.checkIfTeethExists(action, action.teethNo)) {
        return action.price * action.teethNo.length;
      } else if (this.checkIfTeethRangeExists(action, action.teethRange)) {
        return action.price * 2;
      }
      return action.price * (action.numberOfUnits ? action.numberOfUnits : 1);
    }
    return action.price * (action.quantity ? action.quantity : 1);
  }

  showQuantity(action) {
    if (action.costPerTooth) {
      if (action.teethNo?.length) {
        return action.teethNo.length;
      }
      if (
        action.teethRange?.start === this.teethRangeStart &&
        action.teethRange?.end === this.teethRangeEnd
      ) {
        return 2;
      }
    }
    return action.numberOfUnits ? action.numberOfUnits : 1;
  }

  getNumOfUnits(numberOfUnits) {
    if (!numberOfUnits) {
      return [];
    }
    if (numberOfUnits instanceof Array) {
      return numberOfUnits;
    }
    return Object.keys(numberOfUnits).map(function (actionId) {
      return {
        actionId,
        header: numberOfUnits[actionId].header,
        numberOfUnits: numberOfUnits[actionId].numberOfUnits
      };
    });
  }

  getConstructionActions(construction: ActionBasedConstruction, actions: Action[]) {
    return actions.map((action) => {
      return {
        actionId: action._id,
        costPerTooth: action.costPerTooth,
        teethOption: action.teethOption,
        number: action.number,
        name: action.name,
        warranty: construction.warranty,
        teethNo: construction.teethDetails.toothNumber,
        teethRange: construction.teethDetails.teethRange,
        numberOfUnits: action.numberOfUnits || 1,
        vat: action.vat || 0
      };
    });
  }

  getActionsAndMaterials(constructions: ActionBasedConstruction[]) {
    return constructions.reduce((result, construction) => {
      const materials = construction.addedMaterials.map((material) => {
        material.warranty = construction.warranty;
        return material;
      });
      const actions = this.getConstructionActions(
        construction,
        construction.actions
      );

      const addedActions = construction.numberOfUnits.filter((action) => {
        return !action.notSaved;
      });

      if (!addedActions.length) {
        return result.concat(actions, materials);
      }

      const uniqueActions = this.getUniqueActions(actions, addedActions);

      return result.concat(uniqueActions, materials);
    }, []);
  }

  getUniqueActions(actions, addedActions) {
    return actions.map((action) => {
      const actionIndex = addedActions.findIndex((addedAction) => {
        return addedAction.actionId === action.actionId;
      });
      if (actionIndex > -1) {
        action.numberOfUnits = addedActions[actionIndex].numberOfUnits;
      }
      return action;
    });
  }

  checkIfTeethExists(action: PriceSpecification, teethNo: number[]) {
    return action.costPerTooth && teethNo?.length;
  }

  checkIfTeethRangeExists(
    action: PriceSpecification,
    teethRange: { start: number; end: number }
  ) {
    return (
      action.costPerTooth &&
      teethRange?.start === this.teethRangeStart &&
      teethRange?.end === this.teethRangeEnd
    );
  }

  addToPriceAndVat(
    action: PriceSpecification,
    actionPrices: PriceSpecification[],
    tempPrice: number,
    vatPrice: number
  ) {
    let totalVat = 0;
    let totalPrice = 0;
    actionPrices.forEach((priceAction) => {
      action.price = priceAction.price;
      if (action.warranty) {
        action.price = 0;
      }

      // Check for if action per cost is enable for that action
      // then multiply price by number of teeth selected in construction-----> only for single tooth options
      if (this.checkIfTeethExists(action, action.teethNo)) {
        tempPrice += action.price * action.teethNo.length;
        vatPrice = (tempPrice * (action.vat || 0)) / this.percent;
      } else if (this.checkIfTeethRangeExists(action, action.teethRange)) {
        //only if both up/low jaw teeth selected in construction then set quantity 2.
        const upLowJawQuantity = 2;
        tempPrice += action.price * upLowJawQuantity;
        vatPrice = (tempPrice * (action.vat || 0)) / this.percent;
      } else {
        tempPrice += action.price * action.numberOfUnits;
        vatPrice = (tempPrice * (action.vat || 0)) / this.percent;
      }
      totalVat += vatPrice;
      totalPrice += tempPrice + vatPrice;
    });
    return { totalVat, totalPrice };
  }
}
