import { Input, OnInit, Component, forwardRef, Inject } from '@angular/core';
import { OrderService } from '../../../services/order-new/order-new.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { Order } from '../../../models/order.model';
import { User } from '../../../models/user.model';
import { OrganizationSettingService } from '../../../services/organization-setting/organization-setting.service';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../app.constant';
import template from './approve-order-modal.html';

@Component({
  selector: 'approve-order-modal',
  template: template
})
export class ApproveOrderModalComponent implements OnInit {
  // TODO Need to refactor the current Model type functionality in future of all models.ts files for allowing the rule of strictPropertyInitialization
  @Input() orderDetail: Order;
  @Input() currentUser: User;
  @Input() totalVat: number = 0;
  @Input() totalPrice: number = 0;
  @Input() currency: string = '';
  isArchiveForAllByApproved: boolean = true;
  isPriceConfirmed: boolean = false;
  isCertifyEnabled: boolean = false;

  constructor(
    private orderService: OrderService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private logService: LogService,
    private organizationSettingService: OrganizationSettingService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  ngOnInit() {
    this.isCertifyEnabled = this.organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CERTIFICATION
    );
  }

  disableApproveButton(orderDetail: Order): boolean {
    if (!orderDetail || !orderDetail.rating) {
      return true;
    }
    if (orderDetail.rating.satisfied) {
      return false;
    } else if (orderDetail.rating.notSatisfied) {
      return (
        orderDetail.rating.reasons &&
        orderDetail.rating.reasons.every((reason) => {
          return !reason.value;
        })
      );
    } else {
      return true;
    }
  }

  approveOrder(): Promise<void> {
    this.orderDetail.status = 'order_approved';
    this.orderDetail.isStatusChanged = true;
    this.orderDetail.id = this.$state.params.orderId;
    if (this.isArchiveForAllByApproved) {
      this.orderDetail.isArchiveForAllByApproved = this.isArchiveForAllByApproved;
    }
    this.orderDetail.rating.by = this.currentUser._id;
    if (this.isCertifyEnabled) {
      this.orderDetail.isPriceConfirmed = this.isPriceConfirmed;
    }
    return this.orderService
      .updateNewOrder(this.orderDetail)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
        window.location.reload();
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_APPROVING_ORDER'
        );
        this.logService.error(
          'approve-order-modal.component',
          'approveOrder',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }
}
