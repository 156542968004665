(function () {
    "use strict";
    angular.module("app").service("OrderDetails2Service", OrderDetails2Service);
    OrderDetails2Service.$inject = [ '$filter', 'subNavService', 'permissionService', '$state', 'organizationSettingService', 'CUSTOMER_SETTINGS_CONSTANTS' ];
    function OrderDetails2Service($filter, subNavService, permissionService, $state, organizationSettingService, CUSTOMER_SETTINGS_CONSTANTS) {
        return {
            getConstructionsToIterate: getConstructionsToIterate,
            addSubMenu: addSubMenu,
            disableRateOrApproveButton: disableRateOrApproveButton
        };

        function getConstructionsToIterate(order) {
            if (order.newConstructions && order.newConstructions.constructions && order.newConstructions.constructions.length) {
                return order.newConstructions.constructions;
            }

            /**
             * Method for single tooth,  finding same constructions and if yes then merged it into one construction
             */
            Object.keys(order.constructions.abutmentTeeth).forEach(function (constructionKey) {
                order.constructions.abutmentTeeth[constructionKey].forEach(function (construction1, index1) {
                    if (construction1.teethNo) {
                        var teeth1 = construction1.teethNo;
                        order.constructions.abutmentTeeth[constructionKey].forEach(function (construction2, index2) {
                            if (construction2.teethNo && construction1.teethNo !== construction2.teethNo) {
                                delete construction1.teethNo;
                                var teeth2 = construction2.teethNo;
                                delete construction2.teethNo;
                                if (_.isEqual(construction1, construction2)) {
                                    construction1.teethNo = teeth1 + ', ' + teeth2;
                                    teeth1 = teeth1 + ', ' + teeth2;
                                    order.constructions.abutmentTeeth[constructionKey].splice(index2, 1);
                                } else {
                                    construction1.teethNo = teeth1;
                                    construction2.teethNo = teeth2;
                                }
                            }
                        });
                    }
                });
            });

            return order.constructions.abutmentTeeth;
        }

        function disableRateOrApproveButton(orderDetail) {
            if (!orderDetail || !orderDetail.rating) {
                return true;
            }
            if (orderDetail.rating.satisfied) {
                return false;
            } else if (orderDetail.rating.notSatisfied) {
                return orderDetail.rating.reasons && orderDetail.rating.reasons.every(function (reason) {
                    return !reason.value;
                });
            } else {
                return true;
            }
        }

        /**
         * enable or disable according to the order status
         * @param {boolean} approveOrder
         * @param {boolean} cancelOrder
         * @param {boolean} modifyOrder
         * @param {boolean} acceptByLab
         * @param {boolean} declineByLab
         * @param {boolean} sentByLab
         * @param {boolean} resendOrder
         * @param {boolean} rateOrder
         * @param {boolean} archiveOrder
         * @param {boolean} sentByDentist
         * @param {boolean} unArchiveOrder
         * @param {boolean} createdByLab
         * @param {boolean} clinicReceivedDelivery
         * @param {boolean} labWorkStarted
         * */
        function getMenuConfig(approveOrder, cancelOrder, modifyOrder, acceptByLab, declineByLab, sentByLab, resendOrder, rateOrder, archiveOrder, sentByDentist, unArchiveOrder, createdByLab, labWorkStarted, clinicReceivedDelivery, certifyOrder = false) {
            return {
                approveOrder: approveOrder,
                cancelOrder: cancelOrder,
                modifyOrder: modifyOrder,
                acceptByLab: acceptByLab,
                declineByLab: declineByLab,
                sentByLab: sentByLab,
                resendOrder: resendOrder,
                rateOrder: rateOrder,
                archiveOrder: archiveOrder,
                sentByDentist: sentByDentist,
                unArchiveOrder: unArchiveOrder,
                createdByLab: createdByLab,
                labWorkStarted: labWorkStarted,
                clinicReceivedDelivery: clinicReceivedDelivery,
                certifyOrder: certifyOrder
            };
        }

        function getDisabledMenuConfig(cancelOrder, rateOrder, sentByLab, archiveOrder) {
            return {
                cancelOrder: cancelOrder,
                rateOrder: rateOrder,
                sentByLab: sentByLab,
                archiveOrder: archiveOrder
            };
        }

        function addSubMenu(orderDetail, user, userStatus) {
            var status = '';
            var showMenu = getMenuConfig(true, true, true, true, true, true, true, true, true, true, true, true, true, true);
            var menus = [];
            var disableMenu = getDisabledMenuConfig(false, false, false, false);
            if (userStatus) {
                if (userStatus === 'declined' || userStatus === 'cancelled') {
                    orderDetail.rejectStatus = userStatus;
                }
                orderDetail.status = userStatus;
            }

            // conditions to show menu and enable or disable according to the order status
            if (orderDetail) {
                var resendOrder = false;
                if (orderDetail.type === 'Order_1') {
                    var archivedOrder = false;
                    var declineOrder = false;

                    if (permissionService.isDentist(user)) {
                        archivedOrder = true;
                        resendOrder = true;
                    }
                    if (permissionService.isLabTechnician(user)) {
                        declineOrder = true;
                    }
                    if (orderDetail.status === 'sent_by_dentist') {
                        status = $filter('translate')('STATUS') + ': ' + $filter('translate')('SENT_BY_DENTIST');
                        showMenu = getMenuConfig(false, true, true, true, true, false, resendOrder, true, archivedOrder, false, false, false, false, false);
                        disableMenu = getDisabledMenuConfig(false, false, false, false);
                    } else if (orderDetail.status === 'accepted_by_lab') {
                        status = $filter('translate')('STATUS') + ': ' + $filter('translate')('ACCEPTED_BY_LAB');
                        showMenu = getMenuConfig(false, true, true, false, declineOrder, true, resendOrder, true, archivedOrder, false, false, false, false, false);
                        disableMenu = getDisabledMenuConfig(false, false, false, false);
                    } else if (orderDetail.status === 'sent_by_lab') {
                        status = $filter('translate')('STATUS') + ': ' + $filter('translate')('SENT_BY_LAB');
                        showMenu = getMenuConfig(false, false, true, false, false, false, true, true, true, false, false, false, false, false);
                        disableMenu = getDisabledMenuConfig(false, false, false, false);
                    }
                    if (orderDetail.rejectStatus === 'declined') {
                        status = $filter('translate')('STATUS') + ': ' + $filter('translate')('DECLINE_BY_LAB');
                        showMenu = getMenuConfig(false, false, false, false, false, false, resendOrder, false, true, false, false, false, false, false);
                        disableMenu = getDisabledMenuConfig(false, false, false, false);
                    } else if (orderDetail.rejectStatus === 'cancelled') {
                        status = $filter('translate')('STATUS') + ': ' + $filter('translate')('CANCEL_ORDER');
                        showMenu = getMenuConfig(false, false, false, false, false, false, resendOrder, false, true, false, false, false, false, false);
                        disableMenu = getDisabledMenuConfig(false, false, false, false);

                        // if order is cancelled when order status is accepted_by_lab - rate should not be displayed
                        if (orderDetail && orderDetail.status === 'accepted_by_lab') {
                            showMenu.rateOrder = false;
                        }
                    }
                    if (orderDetail.archived.byClinic && permissionService.isDentist(user)) {
                        showMenu.unArchiveOrder = true;
                        showMenu.archiveOrder = false;
                    }
                    if (orderDetail.archived.byLab && permissionService.isLabTechnician(user)) {
                        showMenu.unArchiveOrder = true;
                        showMenu.archiveOrder = false;
                    }
                    if (orderDetail.isProductionFinished || orderDetail.invoicingState !== 'NOT_READY') {
                        showMenu.modifyOrder = false;
                        showMenu.resendOrder = false;
                    }

                } else {
                    if (permissionService.isDentist(user)) {
                        resendOrder = true;
                    }
                    if (orderDetail.status === 'sent_by_dentist') {
                        status = $filter('translate')('STATUS') + ': ' + $filter('translate')('SENT_BY_DENTIST');
                        showMenu = getMenuConfig(true, true, true, true, true, false, resendOrder, false, false, false, false, false, false, false);
                    } else if (orderDetail.status === 'accepted_by_lab') {
                        status = $filter('translate')('STATUS') + ': ' + $filter('translate')('ACCEPTED_BY_LAB');
                        showMenu = getMenuConfig(true, true, true, false, true, isSentByLab, resendOrder, false, false, false, false, false, false, false);

                    } else if (orderDetail.status === 'lab_work_started') {
                      status = $filter('translate')('STATUS') + ': ' + $filter('translate')('LAB_WORK_STARTED');
                      showMenu = getMenuConfig(true, true, true, false, true, true, resendOrder, false, false, false, false, false, false, false);
                    } else if (orderDetail.status === 'sent_by_lab') {
                        status = $filter('translate')('STATUS') + ': ' + $filter('translate')('SENT_BY_LAB');
                        var archiveOrder = false;
                        var createdByLab = false;
                        if (permissionService.isLabTechnician(user)) {
                            archiveOrder = true;
                        }
                        if (permissionService.isLabTechnician(user) && orderDetail.isCreatedByLab) {
                            createdByLab = true;
                        }
                        const isClinicReceivedDeliveryEnabled = organizationSettingService.getCustomerSetting(
                          CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLINIC_RECEIVED_DELIVERY
                        );
                        showMenu = getMenuConfig(true, false, true, false, false, false, true, true, archiveOrder, false, false, createdByLab, false, isClinicReceivedDeliveryEnabled);
                    } else if (orderDetail.status === 'clinic_received_delivery') {
                      status = $filter('translate')('STATUS') + ': ' + $filter('translate')('CLINIC_RECEIVED_DELIVERY');
                      var archiveOrder = false;
                      var createdByLab = false;
                      if (permissionService.isLabTechnician(user)) {
                        archiveOrder = true;
                      }
                      if (permissionService.isLabTechnician(user) && orderDetail.isCreatedByLab) {
                        createdByLab = true;
                      }
                      showMenu = getMenuConfig(true, false, true, false, false, false, true, true, archiveOrder, false, false, createdByLab, false, false);
                    } else if (orderDetail.status === 'order_approved') {
                        status = $filter('translate')('STATUS') + ': ' + $filter('translate')('APPROVED');
                        showMenu = getMenuConfig(false, false, false, false, false, false, false, false, true, false, false, false, false, false);
                    }
                    if (orderDetail.rejectStatus) {
                        if (orderDetail.rejectStatus === 'declined') {
                            status = $filter('translate')('STATUS') + ': ' + $filter('translate')('DECLINE_BY_LAB');
                            showMenu = getMenuConfig(false, false, false, false, false, false, resendOrder, false, true, false, false, false, false, false);
                        } else if (orderDetail.rejectStatus === 'cancelled') {
                            status = $filter('translate')('STATUS') + ': ' + $filter('translate')('CANCEL_ORDER');
                            showMenu = getMenuConfig(false, false, false, false, false, false, resendOrder, false, true, false, false, false, false, false);
                        }
                    }
                    if (orderDetail.archived.byClinic && permissionService.isDentist(user)) {
                        showMenu.unArchiveOrder = true;
                        showMenu.archiveOrder = false;
                    }
                    if (orderDetail.archived.byLab && permissionService.isLabTechnician(user)) {
                        showMenu.unArchiveOrder = true;
                        showMenu.archiveOrder = false;
                    }
                    if (orderDetail.isProductionFinished || orderDetail.invoicingState !== 'NOT_READY') {
                        showMenu.modifyOrder = false;
                        showMenu.resendOrder = false;
                    }
                    if (permissionService.isDentist(user) && orderDetail.isCreatedByLab) {
                        showMenu.modifyOrder = false;
                        showMenu.resendOrder = false;
                    }
                }
            }
            if (orderDetail && orderDetail.orderId) {
                const isReadyForInvoice = orderDetail.invoicingState === 'READY_FOR_INVOICE';
                const isCertified = orderDetail.isCertified;
                const showProductionFinishedButton = orderDetail.status !== 'sent_by_dentist' &&
                  (!orderDetail.invoice ||
                  (orderDetail.invoice.status !== 'SENT' && orderDetail.invoice.status !== 'PROCESSING'));
                const isProductionFinished = orderDetail.isProductionFinished;
                const isCertificationEnabled = organizationSettingService.getCustomerSetting(
                    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CERTIFICATION
                );
                const showWorkflowChangeStep = orderDetail.status === 'accepted_by_lab' &&
                  (orderDetail.rejectStatus !== 'cancelled' && orderDetail.rejectStatus !== 'declined');
                const isPausedStepOrder = orderDetail.workFlow && orderDetail.workFlow.currentStep.isPaused;
                const showWorkflowPauseResumeButton = orderDetail.status === 'accepted_by_lab' &&
                  (orderDetail.rejectStatus !== 'cancelled' && orderDetail.rejectStatus !== 'declined') &&
                  orderDetail.workFlow && orderDetail.workFlow.currentStep.stepId;
                const isLabWorkflowEnabled = organizationSettingService.getCustomerSetting(
                    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.LAB_WORKFLOW
                );
                const showActionBasedCreateWarrantyOrderButton = !orderDetail.isCreatedByLab && orderDetail.type === 'Order_2' &&
                  permissionService.isDentist(user) &&
                  (orderDetail.isProductionFinished || orderDetail.invoicingState !== 'NOT_READY');
                if (showActionBasedCreateWarrantyOrderButton) {
                  showMenu.resendOrder = false;
                }
                const showLabOrderCreateWarrantyOrderButton = permissionService.isLabTechnician(user) &&
                  (orderDetail.isProductionFinished || orderDetail.invoicingState !== 'NOT_READY');
                if (showLabOrderCreateWarrantyOrderButton) {
                  showMenu.resendOrder = false;
                }
                const showInternalDeliveryDateButton = (orderDetail.status === 'sent_by_dentist' ||
                  orderDetail.status === 'accepted_by_lab' || orderDetail.status === 'lab_work_started') &&
                  (orderDetail.rejectStatus !== 'cancelled' && orderDetail.rejectStatus !== 'declined');
                if (orderDetail.type === 'Order_1') {
                    // If current user type is dentist then add the selected menus.
                    if (permissionService.isDentist(user)) {
                        menus = [
                            {
                                name: $filter('translate')('ORDER_ID') + ': ' + orderDetail.orderId,
                                state: 'app.order-list',
                                status: status,
                                isShow: true,
                                icon: 'fa-angle-left',
                                isDentistOrderDetails: true,
                                isActive: true,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('CANCELLED'),
                                action: 'cancel-order',
                                isShow: showMenu.cancelOrder,
                                isModel: true,
                                isDentistOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false,
                                isDisable: disableMenu.cancelOrder
                            },
                            {
                                name: $filter('translate')('RATE_ORDER'),
                                action: 'rate-modal',
                                isShow: showMenu.rateOrder,
                                isRating: true,
                                isDentistOrderDetails: true,
                                isModel: false,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('RESEND_ORDER'),
                                orderId: $state.params.orderId,
                                orderType: orderDetail.type,
                                isShow: showMenu.resendOrder,
                                isDentistOrderDetails: true,
                                isResend: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('MODIFY_ORDER'),
                                orderId: $state.params.orderId,
                                orderType: orderDetail.type,
                                isShow: showMenu.modifyOrder,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isActive: false,
                                isScrollSpy: false,
                                isModify: true
                            },

                            {
                                name: $filter('translate')('ARCHIVE_ORDER'),
                                action: 'archive-order',
                                isShow: showMenu.archiveOrder,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false,
                                isDisable: disableMenu.archiveOrder,
                                isCancel: showMenu.cancelOrder
                            },
                            {
                                name: $filter('translate')('UNARCHIVE_ORDER'),
                                action: 'un-archive-order',
                                isShow: showMenu.unArchiveOrder,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CERTIFY_ORDER'),
                                action: 'certify-order',
                                isShow: isCertificationEnabled && isReadyForInvoice,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CONFIRM'),
                                action: 'confirm',
                                isShow: isCertificationEnabled && isCertified,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            }

                        ];

                    } else if (permissionService.isLabTechnician(user)) {
                        // If current user type is lab_technician the add the different menus
                        menus = [
                            {
                                name: $filter('translate')('ORDER_ID') + ': ' + orderDetail.orderId,
                                state: 'app.order-list',
                                isShow: true,
                                icon: 'fa-angle-left',
                                status: status,
                                isLabOrderDetails: true,
                                isActive: true,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('ACCEPTED_BY_LAB'),
                                action: 'accept-order',
                                isShow: showMenu.acceptByLab,
                                isModel: true,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('DECLINED'),
                                action: 'decline-order',
                                isShow: showMenu.declineByLab,
                                isModel: true,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('SENT_BY_LAB'),
                                action: 'send-order',
                                isShow: showMenu.sentByLab,
                                isDisable: disableMenu.sentByLab,
                                isModel: true,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('RESEND_ORDER'),
                                orderId: $state.params.orderId,
                                orderType: orderDetail.type,
                                isShow: showMenu.resendOrder,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isResend: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('MODIFY_ORDER'),
                                orderId: $state.params.orderId,
                                orderType: orderDetail.type,
                                isShow: showMenu.modifyOrder,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false,
                                isModify: true
                            },
                            {
                                name: $filter('translate')('ARCHIVE_ORDER'),
                                action: 'archive-order',
                                isShow: showMenu.archiveOrder,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false,
                                isCancel: showMenu.cancelOrder
                            },
                            {
                                name: $filter('translate')('UNARCHIVE_ORDER'),
                                action: 'un-archive-order',
                                isShow: showMenu.unArchiveOrder,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('RATE_ORDER'),
                                action: 'rate-modal',
                                isShow: showMenu.rateOrder,
                                isRating: true,
                                isDentistOrderDetails: true,
                                isModel: false,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('PRODUCTION_FINISHED'),
                                action: 'production-finished',
                                isShow: orderDetail.status !== 'sent_by_dentist' && !isProductionFinished,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('PRODUCTION_NOT_FINISHED'),
                                action: 'production-not-finished',
                                isShow: showProductionFinishedButton && isProductionFinished,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('SEND_TO_STEP'),
                                action: 'change-workflow-step',
                                isShow: isLabWorkflowEnabled && showWorkflowChangeStep,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('UN_PAUSE_STEP'),
                                action: 'resume-step',
                                isShow: isLabWorkflowEnabled && isPausedStepOrder && showWorkflowPauseResumeButton,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('PAUSE_STEP'),
                                action: 'pause-step',
                                isShow: isLabWorkflowEnabled && !isPausedStepOrder && showWorkflowPauseResumeButton,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('INTERNAL_DELIVERY_DATE'),
                                action: 'change-internal-delivery-date',
                                isShow: showInternalDeliveryDateButton,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            }
                        ];
                    } else if (permissionService.isSuperAdmin(user)) {
                        menus = [
                            {
                                name: $filter('translate')('ORDER_ID') + ':' + orderDetail.orderId,
                                state: 'app.order-list',
                                status: status,
                                isShow: true,
                                icon: 'fa-angle-left',
                                isDentistOrderDetails: true,
                                isActive: true,
                                isScrollSpy: false
                            }];
                    }
                } else {
                    // If current user type is dentist then add the selected menus.
                    if (permissionService.isDentist(user)) {
                        menus = [
                            {
                                name: $filter('translate')('ORDER_ID') + ': ' + orderDetail.orderId,
                                state: 'app.order-list',
                                status: status,
                                isShow: true,
                                icon: 'fa-angle-left',
                                isDentistOrderDetails: true,
                                isActive: true,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('CLINIC_RECEIVED_DELIVERY'),
                                action: 'clinic-received-delivery',
                                isShow: showMenu.clinicReceivedDelivery,
                                isModel: true,
                                isDentistOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('APPROVED'),
                                action: 'approve-modal',
                                isShow: showMenu.approveOrder,
                                isCreatedByLab: orderDetail.isCreatedByLab,
                                isModel: true,
                                isDentistOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('SENT_BY_DENTIST'),
                                action: 'sent-by-dentist',
                                isShow: showMenu.sentByDentist,
                                isModel: true,
                                isDentistOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('CANCELLED'),
                                action: 'cancel-order',
                                isShow: showMenu.cancelOrder,
                                isModel: true,
                                isDentistOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('RESEND_ORDER'),
                                orderId: $state.params.orderId,
                                orderType: orderDetail.type,
                                isShow: showMenu.resendOrder,
                                isDentistOrderDetails: true,
                                isResend: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('RATE_ORDER'),
                                action: 'rate-modal',
                                isShow: showMenu.rateOrder,
                                isRating: true,
                                isDentistOrderDetails: true,
                                isModel: false,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('MODIFY_ORDER'),
                                orderId: $state.params.orderId,
                                orderType: orderDetail.type,
                                isShow: showMenu.modifyOrder,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isActive: false,
                                isScrollSpy: false,
                                isModify: true
                            },
                            {
                                name: $filter('translate')('ARCHIVE_ORDER'),
                                action: 'archive-order',
                                isShow: showMenu.archiveOrder,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false,
                                isCancel: showMenu.cancelOrder
                            },
                            {
                                name: $filter('translate')('UNARCHIVE_ORDER'),
                                action: 'un-archive-order',
                                isShow: showMenu.unArchiveOrder,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CERTIFY_ORDER'),
                                action: 'certify-order',
                                isShow: isCertificationEnabled && isReadyForInvoice,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CONFIRM'),
                                action: 'confirm',
                                isShow: isCertificationEnabled && isCertified,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CREATE_WARRANTY_ORDER'),
                                action: 'warranty-order',
                                isShow: showActionBasedCreateWarrantyOrderButton,
                                isDentistOrderDetails: true,
                                isLabOrderDetails: false,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                        ];

                    } else if (permissionService.isLabTechnician(user)) {
                        // If current user type is lab_technician the add the different menus
                        menus = [
                            {
                                name: $filter('translate')('ORDER_ID') + ': ' + orderDetail.orderId,
                                state: 'app.order-list',
                                isShow: true,
                                icon: 'fa-angle-left',
                                status: status,
                                isLabOrderDetails: true,
                                isActive: true,
                                isScrollSpy: false
                            },
                            // For created by lab order
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('ACCEPTED_BY_LAB'),
                                orderId: $state.params.orderId,
                                isCreatedByLab: showMenu.createdByLab,
                                isShow: showMenu.createdByLab,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false,
                                isModify: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('ACCEPTED_BY_LAB'),
                                action: 'accept-order',
                                isShow: showMenu.acceptByLab,
                                isModel: true,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('LAB_WORK_STARTED'),
                                action: 'lab-work-started',
                                isShow: showMenu.labWorkStarted,
                                isModel: true,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('SENT_BY_LAB'),
                                action: 'send-order',
                                isShow: showMenu.sentByLab,
                                isModel: true,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CHANGE_TO'),
                                subName: $filter('translate')('DECLINED'),
                                action: 'decline-order',
                                isShow: showMenu.declineByLab,
                                isModel: true,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('RESEND_ORDER'),
                                orderId: $state.params.orderId,
                                orderType: orderDetail.type,
                                isShow: showMenu.resendOrder,
                                isDentistOrderDetails: false,
                                isCreatedByLab: orderDetail.isCreatedByLab,
                                isLabOrderDetails: true,
                                isResend: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('MODIFY_ORDER'),
                                orderId: $state.params.orderId,
                                orderType: orderDetail.type,
                                isShow: showMenu.modifyOrder,
                                isCreatedByLab: orderDetail.isCreatedByLab,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isActive: false,
                                isScrollSpy: false,
                                isModify: true
                            },

                            {
                                name: $filter('translate')('ARCHIVE_ORDER'),
                                action: 'archive-order',
                                isShow: showMenu.archiveOrder,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false,
                                isCancel: showMenu.cancelOrder
                            },
                            {
                                name: $filter('translate')('UNARCHIVE_ORDER'),
                                action: 'un-archive-order',
                                isShow: showMenu.unArchiveOrder,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('PRODUCTION_FINISHED'),
                                action: 'production-finished',
                                isShow: orderDetail.status !== 'sent_by_dentist' && !isProductionFinished,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('PRODUCTION_NOT_FINISHED'),
                                action: 'production-not-finished',
                                isShow: showProductionFinishedButton && isProductionFinished,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('SEND_TO_STEP'),
                                action: 'change-workflow-step',
                                isShow: isLabWorkflowEnabled && showWorkflowChangeStep,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('UN_PAUSE_STEP'),
                                action: 'resume-step',
                                isShow: isLabWorkflowEnabled && isPausedStepOrder && showWorkflowPauseResumeButton,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('PAUSE_STEP'),
                                action: 'pause-step',
                                isShow: isLabWorkflowEnabled && !isPausedStepOrder && showWorkflowPauseResumeButton,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('CREATE_WARRANTY_ORDER'),
                                action: 'warranty-order',
                                isShow: showLabOrderCreateWarrantyOrderButton,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },
                            {
                                name: $filter('translate')('INTERNAL_DELIVERY_DATE'),
                                action: 'change-internal-delivery-date',
                                isShow: showInternalDeliveryDateButton,
                                isDentistOrderDetails: false,
                                isLabOrderDetails: true,
                                isModel: true,
                                isActive: false,
                                isScrollSpy: false
                            },

                        ];
                    } else if (permissionService.isSuperAdmin(user)) {
                        menus = [
                            {
                                name: $filter('translate')('ORDER_ID') + ':' + orderDetail.orderId,
                                state: 'app.order-list',
                                status: status,
                                isShow: true,
                                icon: 'fa-angle-left',
                                isDentistOrderDetails: true,
                                isActive: true,
                                isScrollSpy: false
                            }];
                    }
                }
            }
            // first reset all thew menus of sub nav bar
            subNavService.resetList();
            //push menus to sub nav one by one
            menus.forEach(function (menu) {
                subNavService.addMenu({
                    name: menu.name,
                    orderType: menu.orderType,
                    subName: menu.subName || '',
                    icon: menu.icon || '',
                    status: menu.status || '',
                    action: menu.action || '',
                    state: menu.state || '',
                    isScrollSpy: menu.isScrollSpy,
                    isActive: menu.isActive,
                    isModel: menu.isModel,
                    isRating: menu.isRating || false,
                    isDentistOrderDetails: menu.isDentistOrderDetails || false,
                    isLabOrderDetails: menu.isLabOrderDetails || false,
                    isShow: menu.isShow,
                    isDisable: menu.isDisable,
                    isResend: menu.isResend,
                    isModify: menu.isModify,
                    orderId: $state.params.orderId,
                    isCertified: orderDetail.isCertified,
                    isReadyForInvoice: orderDetail.invoicingState === 'READY_FOR_INVOICE',
                    isArchive: menu.isArchive,
                    overAllRating: menu.overAllRating,
                    isCancel: menu.isCancel,
                    showNewOrderSubMenu: true,
                    isCreatedByLab: menu.isCreatedByLab,
                    orderData: orderDetail
                });
            });
        }
    }
})();
