import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import template from './categories.html';
import { CategoryNew, ActionList } from '../../../../models/organization.model';

@Component({
  selector: 'categories',
  template
})
export class CategoriesComponent implements OnChanges {
  @Input() selectedList: ActionList;
  @Output() selectedListUpdated: EventEmitter<ActionList> = new EventEmitter<
    ActionList
    >();
  category = {};
  readonly modalId = '#add-category-modal';
  categories: any = [];

  ngOnChanges() {
    if (this.selectedList) {
      this.categories = this.selectedList.categories;
    }
  }

  openModal() {
    $(this.modalId).modal('show');
  }

  modalClosed(actionsLists?: ActionList) {
    this.category = {};
    this.selectedListUpdated.emit(actionsLists);
    $(this.modalId).modal('hide');
  }

  editCategory(category: CategoryNew) {
    this.category = Object.assign(category);
    $(this.modalId).modal('show');
  }
}
