import { Input, Component } from '@angular/core';
import template from './added-materials-table-header.html';

@Component({
  selector: 'added-materials-table-header',
  template: template
})
export class AddedMaterialsTableHeaderComponent {
  @Input() isVisibleInPrint: boolean = false;
  @Input() addedMaterials: any = [];
}
