import { Component, Input } from '@angular/core';
import { ThreeShapeData } from '../../../models/scan-case.model';
import template from './three-shape-info.html';

@Component({
  selector: 'three-shape-info',
  template: template
})
export class ThreeShapeInfoComponent {
  @Input() threeShapeData: ThreeShapeData[];
  constructor() {}
}
