import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { SessionService } from '../../services/core/session.service';
import { LogService } from '../../services/core/log.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import * as _ from 'lodash';

import template from './lab-filter.html';
import { Organization } from '../../models/organization.model';

@Component({
  selector: 'lab-filter',
  template: template
})
export class LabFilterComponent implements OnInit {
  @Input() key: string;
  @Output() onFilterUpdate: EventEmitter<any> = new EventEmitter<any>();
  selectedLabIds: string[] = [];
  labs: Organization[] = [];

  constructor(
    private toastr: ToastrService,
    private logService: LogService,
    private sessionService: SessionService,
    private organizationService: OrganizationService,
    private translationsService: TranslationsService
  ) {}

  ngOnInit(): void {
    this.getLabs();
  }

  getLabs(): void {
    this.organizationService
      .getAllOrganizations({ type: 'lab', active: true })
      .then((result) => {
        this.labs = result.data;
        // for sorting lab by name
        this.labs = _.sortBy(this.labs, [
          (lab) => {
            return lab.name.toLowerCase() || '';
          }
        ]);
        const storeLabIds = JSON.parse(this.sessionService.getItem(this.key));
        if (
          storeLabIds &&
          storeLabIds.length > 0 &&
          this.labs &&
          this.labs.length > 0
        ) {
          this.labs
            .filter((lab) => {
              return storeLabIds.indexOf(lab._id) > -1;
            })
            .forEach((lab) => {
              lab.selected = true;
            });
        }
      })
      .catch(async (err) => {
        this.logService.error(
          'lab-filter.component',
          'getLabs',
          (await this.translationsService.get('ERROR_IN_GETTING_LABS')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_LABS')
        );
      });
  }

  get(): void {
    this.selectedLabIds = this.labs
      .filter((lab) => {
        return lab.selected;
      })
      .map((lab) => {
        return lab._id;
      });
    this.callFilterFunction();
  }

  getLabName(): string {
    if (!this.labs) {
      return '';
    }

    let filteredData = this.labs
      .filter((lab) => {
        return lab.selected;
      })
      .map((lab) => {
        return lab.name;
      });
    // for sorting selected lab by name
    filteredData = _.sortBy(filteredData, [
      (labName) => {
        return labName.toLowerCase() || '';
      }
    ]);
    return filteredData.join(',');
  }

  /**
   * Function to invoke filter api.
   */
  callFilterFunction(): void {
    this.onFilterUpdate.emit(this.selectedLabIds);
  }
}
