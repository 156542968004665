import { PipeTransform, Pipe } from '@angular/core';
import { ConnectedLab } from '../models/organization.model';

@Pipe({
  name: 'connectedLabSearch'
})
export class ConnectedLabSearchPipe implements PipeTransform {
  transform(labs: ConnectedLab[], search: string): ConnectedLab[] {
    if (!search) {
      return labs;
    }
    return labs.filter((lab) => {
      return (
        lab.lab.name &&
        lab.lab.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      );
    });
  }
}
