import { Input, Component, Inject, forwardRef } from '@angular/core';
import { StateService } from '@uirouter/angularjs';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../app.constant';
import { Order } from '../../../../../models/order.model';

import template from './patient-order-list-item.html';
import { NewFrontendNavigateService } from '../../../../../services/new-frontend-navigate/new-frontend-navigate.service';

@Component({
  selector: 'patient-order-list-item',
  template
})
export class PatientOrderListItemComponent {
  @Input() order: Order;

  constructor(
    private navigateService: NewFrontendNavigateService,
    @Inject(forwardRef(() => '$state')) private readonly $state: StateService
  ) {}

  goToOrderDetail(orderId: string) {
    this.navigateService.navigate(`/order-details/${orderId}/main-view`);
  }
}
