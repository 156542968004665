import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';

@Injectable()
export class PermissionService {
  hasLabTechnicainAccess(user: User): boolean {
    return (
      user &&
      ((this.isLabTechnician(user) && user.isAdmin) || this.isSuperAdmin(user))
    );
  }

  hasClinicAccess(user: User): boolean {
    return (
      user &&
      ((this.isDentist(user) && user.isAdmin) ||
        this.isSuperAdmin(user) ||
        (user.organization && user.organization.isWhiteLabeled))
    );
  }

  hasDentistAccess(user: User): boolean {
    return (
      user && ((this.isDentist(user) && user.isAdmin) || this.isSuperAdmin(user))
    );
  }

  hasDentistAdminAccess(user: User): boolean {
    return (
      user &&
      ((this.isDentist(user) && user.role === 'DENTIST_ADMIN') ||
        this.isSuperAdmin(user))
    );
  }

  hasLabAccess(user: User): boolean {
    return user && this.isSuperAdmin(user);
  }

  hasSettingAccess(user: User): boolean {
    return user && user.isAdmin;
  }

  hasConnectLabAccess(user: User): boolean {
    return user && user.isAdmin && this.isDentist(user);
  }

  isSuperAdmin(user: User): boolean {
    return user && user.role === 'super_admin';
  }

  isDentist(user: User): boolean {
    return user && user.type === 'dentist';
  }

  isLabTechnician(user: User): boolean {
    return user && user.type === 'lab_technician';
  }

  isExternalTechnician(user: User): boolean {
    return user && user.role === 'EXTERNAL_TECHNICIAN';
  }

  isCarrier(user: User): boolean {
    return user && user.role === 'CARRIER';
  }
}
