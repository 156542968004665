import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ConstructionService } from '../../../../services/construction-service';
import template from './construction-settings.html';
import { isEmpty, isEqual } from 'lodash';
import { ConstructionSettingsService } from '../../../../services/construction-settings-service';
import { Category, SubCategory } from '../../../../../../models/organization.model';
import { Action } from '../../../../../../models/action.model';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../../app.constant';
import { OrganizationSettingService } from '../../../../../../services/organization-setting/organization-setting.service';
import style from './construction-settings.less';
import settingTypesStyle from './settings/settings-types.less';

@Component({
  selector: 'construction-settings',
  template,
  styles: [style, settingTypesStyle]
})
export class ConstructionSettingsComponent implements OnInit, OnChanges {
  @Input() constructionSettings: {
    [key: string]: { include: boolean; mandatory: boolean };
  };
  @Input() selectedConstructions: Object;
  @Input() selectedTeeth: string[];
  @Input() selectedTeethRange: { end: string; start: string };
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedMainCategory: Category;
  @Input() constructionType: string;
  @Input() actionsForConstructionSettings: Action[];
  @Input() userSettings: { [key: string]: string };
  @Input() hasTeethOption: boolean;
  @Input() warrantyType: string;
  @Input() isWarrantyOrder: boolean;
  @Input() orderStatus: string;
  @Input() isDentist: boolean;
  @Output() constructionSaved: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() constructionRemoved: EventEmitter<void> = new EventEmitter<void>();
  materials: any;
  constructionSettingStyle: { border: string };
  keyValueMapping = {
    contactType: 'contactType',
    metalDesign: 'design',
    occlusionContact: 'occlusion',
    ponticDesign: 'ponticDesign'
  };
  metalDesignsSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.METAL_DESIGN;
  metalDesigns: { name: string; imageUrl: string }[];

  ponticDesignsSettingName =
    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PONTIC_DESIGN;
  ponticDesigns: { name: string; imageUrl: string }[];

  claspMaterialSettingName =
    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_MATERIAL;
  claspMaterialOptions: string[];

  facialLoopSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.FACIAL_LOOP;
  facialLoopOptions: string[];

  claspTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_TYPES;
  claspTypeOptions: string[];

  springTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SPRING_TYPES;
  springTypeOptions: string[];

  constructor(
    private readonly constructionService: ConstructionService,
    private readonly constructionSettingsService: ConstructionSettingsService,
    private readonly organizationSettingService: OrganizationSettingService
  ) {}

  ngOnInit(): void {
    this.metalDesigns = this.organizationSettingService.getCustomerSetting(
      this.metalDesignsSettingName
    );

    this.ponticDesigns = this.organizationSettingService.getCustomerSetting(
      this.ponticDesignsSettingName
    );

    this.facialLoopOptions = this.organizationSettingService.getCustomerSetting(
      this.facialLoopSettingName
    );

    this.claspTypeOptions = this.organizationSettingService.getCustomerSetting(
      this.claspTypeSettingName
    );

    this.springTypeOptions = this.organizationSettingService.getCustomerSetting(
      this.springTypeSettingName
    );

    this.claspMaterialOptions = this.organizationSettingService.getCustomerSetting(
      this.claspMaterialSettingName
    );

    this.applyPreselectedValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedSubCategory) {
      this.constructionSettingStyle = {
        border: 'solid 1px ' + this.selectedSubCategory.colorCode
      };
    }
    if (
      !isEqual(
        changes.actionsForConstructionSettings?.previousValue,
        changes.actionsForConstructionSettings?.currentValue
      )
    ) {
      this.materials = this.constructionService.getMaterials(
        this.actionsForConstructionSettings
      );
    }
  }

  applyPreselectedValues() {
    Object.keys(this.userSettings).forEach((settingName) => {
      const key = this.keyValueMapping[settingName];
      if (
        this.constructionSettings[key] &&
        (this.constructionSettings[key].include ||
          this.constructionSettings[key].mandatory) &&
        !this.constructionSettingsService.getSetting(key)
      ) {
        this.constructionSettingsService.setSetting(
          key,
          this.userSettings[settingName]
        );
      }
    });
    if (
      this.isDentist &&
      this.orderStatus === 'draft' &&
      this.warrantyType &&
      this.isWarrantyOrder
    ) {
      this.constructionSettingsService.setSetting(
        'warrantyType',
        this.warrantyType
      );
      this.constructionSettingsService.setBooleanSetting(
        'warranty',
        true
      );
    }
  }

  getSettingsObj(settingsData, subCategoryName) {
    return Object.assign({}, settingsData, {
      material: settingsData.material || settingsData.otherMaterial,
      addedMaterials: settingsData.addedMaterials || [],
      createdAt: settingsData.createdAt || new Date().getTime(),
      type: subCategoryName.toLowerCase(),
      constructionType: this.constructionType,
      colorCode: this.selectedSubCategory.colorCode,
      textColorCode: this.selectedSubCategory.textColorCode,
      subCategory: this.selectedSubCategory._id,
      mainCategory: this.selectedMainCategory,
      actions: this.actionsForConstructionSettings
    });
  }

  checkForExistingConstructionAndCreate(
    mainCategoryName,
    subCategoryName,
    constructionIndex,
    settingObj,
    settingsData,
    selectedConstructions
  ) {
    if (settingObj.createdAt === settingsData.createdAt) {
      constructionIndex = selectedConstructions[mainCategoryName][
        subCategoryName
      ].findIndex((construction) => {
        return settingsData.createdAt === construction.createdAt;
      });
      if (constructionIndex !== -1) {
        selectedConstructions[mainCategoryName][subCategoryName].splice(
          constructionIndex,
          1,
          settingObj
        );
      }
    } else {
      selectedConstructions[mainCategoryName][subCategoryName].push(settingObj);
    }
  }

  saveTeethConstruction() {
    try {
      const settingsData = this.constructionSettingsService.settings;
      if (settingsData.numberOfUnits) {
        // eslint-disable-next-line max-len
        settingsData.numberOfUnits = this.constructionService.deleteEmptyNumberOfUnits(
          settingsData.numberOfUnits
        );
      }

      const subCategoryName = this.selectedSubCategory.name;
      const mainCategoryName = this.selectedMainCategory.name;
      const settingObj = this.getSettingsObj(settingsData, subCategoryName);

      if (this.selectedTeeth.length > 0) {
        settingObj.teethNo = this.selectedTeeth;
        settingObj.teethRange = null;
      } else {
        settingObj.teethRange = this.selectedTeethRange;
        settingObj.teethNo = [];
      }
      const selectedConstructions = Object.assign({}, this.selectedConstructions);
      if (selectedConstructions[mainCategoryName][subCategoryName]?.length) {
        const index = this.constructionService.getExistingConstructionIndex(
          selectedConstructions,
          mainCategoryName,
          subCategoryName,
          settingsData.createdAt,
          this.selectedTeethRange || {},
          this.selectedTeeth || []
        );
        if (index !== -1) {
          selectedConstructions[mainCategoryName][subCategoryName].splice(
            index,
            1,
            settingObj
          );
        } else {
          this.checkForExistingConstructionAndCreate(
            mainCategoryName,
            subCategoryName,
            index,
            settingObj,
            settingsData,
            selectedConstructions
          );
        }
      } else {
        selectedConstructions[mainCategoryName][subCategoryName] = [settingObj];
      }
      this.constructionService.deleteDummyConstructions();
      this.constructionSettingsService.resetSettings();
      this.constructionSaved.emit(selectedConstructions);
    } catch (error) {
      console.error(error);
    }
  }

  disableSaveButton() {
    if (!this.constructionSettings) {
      return true;
    }
    // disable if teeth option is enabled but not selected
    if (
      this.hasTeethOption &&
      isEmpty(this.selectedTeeth) &&
      isEmpty(this.selectedTeethRange)
    ) {
      return true;
    }
    const mandatoryFields = this.constructionService.getMandatoryFields(
      this.constructionSettings
    );
    const settingsData = this.constructionSettingsService.settings;

    return !this.constructionService.checkIfMandatoryFieldsFilled(
      mandatoryFields,
      settingsData,
      this.constructionSettings
    );
  }

  removedConstruction() {
    const subCategoryName = this.selectedSubCategory.name;
    const mainCategoryName = this.selectedMainCategory.name;
    const selectedConstructions = Object.assign({}, this.selectedConstructions);
    if (
      selectedConstructions[mainCategoryName] &&
      selectedConstructions[mainCategoryName][subCategoryName]?.length
    ) {
      const constructionIndex = selectedConstructions[mainCategoryName][
        subCategoryName
      ].findIndex((construction) => {
        return construction.teethNo?.length
          ? this.constructionService.compareSelectedTeeth(
              construction.teethNo,
              this.selectedTeeth
            )
          : construction.teethRange?.start === this.selectedTeethRange?.start &&
              construction.teethRange?.end === this.selectedTeethRange?.end;
      });
      if (constructionIndex > -1) {
        selectedConstructions[mainCategoryName][subCategoryName].splice(
          constructionIndex,
          1
        );
      }
    }
    this.constructionService.selectedConstructions = selectedConstructions;
    this.constructionService.deleteDummyConstructions();
    this.constructionSettingsService.resetSettings();
    this.constructionRemoved.emit();
  }
}
