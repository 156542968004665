import { Injectable } from '@angular/core';
import { TranslationsService } from '../translations/translations.service';

@Injectable()
export class FormatReportService {
  constructor(private readonly translationsService: TranslationsService) {}

  formatRatingStatisticsReport(reportData, columns) {
    return reportData.map((entry) => {
      const formattedEntry = {};
      columns.forEach((column) => {
        formattedEntry[column.name] = entry[column.name] ? entry[column.name] : 0;
      });
      return formattedEntry;
    });
  }

  formatNumberOfOrdersReport(reportData, columns) {
    return reportData.map((entry) => {
      const formattedEntry = {};
      columns.forEach((column) => {
        if (column.name === 'labs') {
          column.value &&
            column.value.forEach((innerElem) => {
              formattedEntry[innerElem.name] = 0;
            });
          entry.labs &&
            entry.labs.forEach((lab) => {
              if (typeof formattedEntry[lab.name] !== undefined) {
                formattedEntry[lab.name] = lab.count || lab.totalAmount;
              }
            });
        } else {
          formattedEntry[column.name] = entry[column.name] ? entry[column.name] : 0;
        }
      });
      return formattedEntry;
    });
  }

  formatEconomyReport(reportData, columns) {
    return reportData.map((entry) => {
      const formattedEntry = {};
      columns.forEach((column) => {
        if (column.name === 'patientCategories') {
          column.value &&
            column.value.forEach((innerElem) => {
              formattedEntry[innerElem.name] = 0;
            });
          entry.patientCategories &&
            entry.patientCategories.forEach((patientCategory) => {
              if (typeof formattedEntry[patientCategory.name] !== undefined) {
                formattedEntry[patientCategory.name] =
                  patientCategory.categoryTotalAmount;
              }
            });
        } else {
          formattedEntry[column.name] = entry[column.name] ? entry[column.name] : 0;
        }
      });
      return formattedEntry;
    });
  }

  formatLabRatingReport(reportData, columns) {
    return reportData.map((entry) => {
      const formattedEntry = {};
      columns.forEach((column) => {
        if (column.name === '_id') {
          formattedEntry[column.name] = entry._id;
        } else if (column.name === 'reasons') {
          column.value.forEach((innerElem) => {
            formattedEntry[innerElem.name] = entry.reasons[innerElem.name] || 0;
          });
        } else {
          formattedEntry[column.name] = entry[column.name] ? entry[column.name] : 0;
        }
      });
      return formattedEntry;
    });
  }

  async formatOrderRatingReport(reportData, columns) {
    const formattedEntry = [];
    for (const entry of reportData) {
      if (entry.remarks && entry.remarks.length > 0) {
        const promises = entry.remarks.map((elem) => {
          return this.translationsService.get(elem.name);
        });
        const remarks = await Promise.all(promises);
        entry.remarks = remarks.join(', ');
      }
      const tempFormattedEntry: any = {};
      columns.forEach((column) => {
        tempFormattedEntry[column.name] =
          entry[column.name] && entry[column.name].length !== 0
            ? entry[column.name]
            : '-';
      });
      if (tempFormattedEntry.rating === 'SATISFIED') {
        tempFormattedEntry.remarks = '-';
      }
      tempFormattedEntry.rating = await this.translationsService.get(
        tempFormattedEntry.rating
      );
      formattedEntry.push(tempFormattedEntry);
    }
    return formattedEntry;
  }

  formatRankingAdherenceReport(reportData, columns) {
    return reportData.map((entry) => {
      const formattedEntry = {};
      columns.forEach((column) => {
        if (column.name === 'name') {
          formattedEntry[
            column.name
          ] = `${entry.name.firstName} ${entry.name.lastName}`;
        } else if (column.name === 'reasons') {
          column.value &&
            column.value.forEach((nestedColumn) => {
              formattedEntry[nestedColumn.name] = 0;
            });
          entry.reasons &&
            entry.reasons.forEach((reason) => {
              if (typeof formattedEntry[reason.name] !== undefined) {
                formattedEntry[reason.name] = reason.count;
              }
            });
        } else {
          formattedEntry[column.name] = entry[column.name] ? entry[column.name] : 0;
          if (!entry[column.name] && column.name === 'adherencePercentage') {
            formattedEntry[column.name] = '0%';
          }
        }
      });
      return formattedEntry;
    });
  }

  formatRankingViolationsReport(reportData, columns) {
    return reportData.map((entry) => {
      const formattedEntry = {};
      columns.forEach((column) => {
        if (column.name === 'reasons') {
          column.value &&
            column.value.forEach((nestedColumn) => {
              formattedEntry[nestedColumn.name] = 0;
            });
          entry.reasons &&
            entry.reasons.forEach((reason) => {
              if (typeof formattedEntry[reason.type] !== undefined) {
                formattedEntry[reason.type] = reason.count;
              }
            });
        } else {
          formattedEntry[column.name] = entry[column.name] ? entry[column.name] : 0;
        }
      });
      return formattedEntry;
    });
  }

  formatColumns(columns, reportName) {
    const reportColumns = [];
    columns.forEach((column) => {
      if (column.type === 'array') {
        column.value.forEach((nestedColumn) => {
          reportColumns.push(nestedColumn);
        });
      } else {
        column.value = column.value.toUpperCase();
        reportColumns.push(column);
      }
    });
    if (reportName === 'RANKING_ADHERENCE' && reportColumns.length > 4) {
      [reportColumns[3], reportColumns[4]] = [reportColumns[4], reportColumns[3]];
    }
    return reportColumns;
  }

  formatIterableReport(reportData, reportColumns) {
    return reportData.map((elem) => {
      const elemArray = [];
      reportColumns.forEach((column) => {
        elemArray.push(elem[column.name]);
      });
      return elemArray;
    });
  }
}
