import { Injectable } from '@angular/core';

@Injectable()
export class CurrentYearDayService {
  /**
   * get current year week days by day name
   * @param dayName
   * @returns {Array}
   */
  // TODO: Only used in database patch. Renaming this for removal.
  getCurrentYearWeekDayArray_OLD_REMOVE(dayName: string): any {
    const currentYear = new Date().getFullYear();
    const weekday = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    const day = weekday.indexOf(dayName);
    const date = new Date();
    while (date.getDay() !== day) {
      date.setDate(date.getDate() + 1);
    }
    const days = [];
    while (date.getFullYear() === currentYear) {
      days.push(date.setHours(0, 0, 0, 0));
      date.setDate(date.getDate() + 7);
    }
    return days;
  }
}
