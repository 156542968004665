import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Organization } from '../../models/organization.model';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { ControlValueAccessor } from '@angular/forms';
import { UploadedFile } from '../../models/uploaded-file.model';
import { UploadButtonComponent } from '../upload/upload-button.component';
import style from './lab-general-info.less';
import template from './lab-general-info.html';

@Component({
  selector: 'lab-general-info',
  template: template,
  styles: [style]
})
export class LabGeneralInfoComponent {
  @ViewChild('uploadbutton') uploadButton: UploadButtonComponent;
  public uploader: FileUploader = new FileUploader({ url: 'krfsm' });
  @Input() lab: Organization;
  @Input() isWhiteLabelEnabled: boolean;
  @Output() updateLab: EventEmitter<void> = new EventEmitter<void>();
  dropHover: boolean = false;
  uploadingDocs: any[] = [];
  progressPercentage: number;
  denthubPublicUrl = process.env.DENTHUB_PUBLIC_URL;

  constructor(
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private organizationService: OrganizationService
  ) {
    const self = this;
  }

  /**
   * uploadImage function
   * @param file
   */
  async docUploaded(file: UploadedFile, error: string): Promise<void> {
    if (error) {
      console.error('Error uploading document!', error);
      throw new Error('Error uploading document!');
    }

    const uploadDocIndexToRemove = this.uploadingDocs.findIndex(
      (uploadingDoc) => uploadingDoc.name === file.name
    );
    this.uploadingDocs.splice(uploadDocIndexToRemove);
    this.lab.documents.push({ _id: file._id, document: file._id });
    return this.organizationService
      .updateOrganization(this.lab._id, { documents: this.lab.documents })
      .then(async () => {
        this.toastr.success(await this.translationsService.get('FILE_UPLOADED'));
        this.updateLab.emit();
      });
  }

  docBeforeUpload(file: FileItem): void {
    this.uploadingDocs.push({
      progress: 10,
      document: {
        name: file.file.name
      }
    });
  }

  async docProgress(data: { file: FileItem; progress: number }): Promise<void> {
    try {
      const uploadingDocument = this.uploadingDocs.find(
        (doc) => doc.document.name === data.file.file.name
      );

      uploadingDocument.progress = data.progress;
    } catch (e) {
      console.error("Can't set file progress!", e);
    }
  }

  removeFile(id: string): Promise<void> {
    const self = this;
    const indexToRemove = this.lab.documents.findIndex((d) => {
      return d._id === id;
    });

    this.lab.documents.splice(indexToRemove, 1);
    return this.organizationService
      .updateOrganization(this.lab._id, { documents: this.lab.documents })
      .then(async () => {
        self.toastr.success(await self.translationsService.get('FILE_REMOVED'));
        this.updateLab.emit();
      });
  }
}
