import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import template from './clinic-list-item.html';
import { OrganizationService } from '../../services/clinic/organization.service';
import { Organization } from '../../models/organization.model';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';
import { CurrentUserService } from '../../services/users/current-user.service';
import { PermissionService } from '../../services/core/permission.service';

@Component({
  selector: 'clinics-list-item',
  template: template
})
export class ClinicsListItemComponent implements OnInit {
  @Input() clinic: Organization;
  @Output('onClinicUpdate') onClinicUpdate: EventEmitter<
    Organization
  > = new EventEmitter<Organization>();
  currentUserPermissions = {};
  isSuperAdmin: boolean;

  constructor(
    private organizationService: OrganizationService,
    private translationsService: TranslationsService,
    private logService: LogService,
    private toastr: ToastrService,
    private currentUserService: CurrentUserService,
    private permissionService: PermissionService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  async ngOnInit() {
    this.currentUserPermissions = this.currentUserService.getPermissions();
    const currentUser = await this.currentUserService.getUserData();
    this.isSuperAdmin = this.permissionService.isSuperAdmin(currentUser);
  }

  toggleClinicStatus(clinic: Organization): void {
    clinic.active = !clinic.active;
    // org obj to be updated
    this.organizationService
      .updateOrganization(clinic._id, { active: clinic.active })
      .then(async () => {
        this.clinic.active = clinic.active;
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_UPDATED')
        );
      })
      .catch(async (err: any) => {
        const text = await this.translationsService.get('ERROR_IN_UPDATE_CLINIC');
        this.logService.error(
          'clinics-list-item.component',
          'toggleClinicStatus',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  editClinic(): void {
    this.onClinicUpdate.emit(cloneDeep(this.clinic));
  }

  goToSettings(): void {
    this.$state.go('app.customer-settings', { id: this.clinic._id });
  }
}
