import { Input, Component } from '@angular/core';
import { Deviation } from '../../../models/deviations.model';
import template from './deviations.html';

@Component({
  selector: 'deviations',
  template: template
})
export class DeviationsComponent {
  @Input() deviations: Deviation[] = [];
}
