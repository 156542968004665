import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { TransportClinic } from '../../../../../models/transport-clinic.model';
import template from './add-transport-clinic.html';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../../../../services/core/log.service';
import { TranslationsService } from '../../../../../services/translations/translations.service';
import { LogisticsCustomerGraphqlService } from '../../../../../graphql-services/logistics-customer/logistics-customer.graphql.service';

const componentName = 'add-transport-clinic';
@Component({
  selector: componentName,
  template: template
})
export class AddTransportClinicComponent implements OnChanges{
  @Output() clinicAdded: EventEmitter<TransportClinic> = new EventEmitter<TransportClinic>();
  @Output() transportClinicChanged: EventEmitter<TransportClinic> = new EventEmitter<TransportClinic>();
  @Output() transportClinicDeleted: EventEmitter<TransportClinic> = new EventEmitter<TransportClinic>();
  @Input() transportClinic: Partial<TransportClinic>;
  isPostCodeValid: boolean = false;
  private readonly modalId = '#add-transport-clinic-modal';

  constructor(
    private readonly logisticsCustomerGraphqlService: LogisticsCustomerGraphqlService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService,
    private readonly translationsService: TranslationsService,
  ) {}

  ngOnChanges(): void {
    if (!this.transportClinic) {
      this.transportClinic = {};
    }
    this.validatePostCode(this.transportClinic.zipCode);
  }

  validatePostCode(zipcode: number): void {
    this.transportClinic.zipCode = zipcode;
    this.isPostCodeValid = new RegExp('^\\d{5,5}$').test(`${zipcode}`);
  }

  async addTransportClinic() {
    try {
      const transportClinic = await this.logisticsCustomerGraphqlService.addTransportClinic(
        this.transportClinic as TransportClinic
      );
      await this.showSuccessToastr('TRANSPORT_CLINIC_ADDED');
      this.clinicAdded.emit(transportClinic);
      $(this.modalId).modal('hide');
    } catch (error) {
      this.logService.error(componentName, 'addTransportClinic', error);
      this.toastr.error(error.message);
    }
  }

  async updateTransportClinic() {
    try {
      await this.logisticsCustomerGraphqlService.updateTransportClinic(this.transportClinic as TransportClinic);
      await this.showSuccessToastr('TRANSPORT_CLINIC_UPDATED');
      this.transportClinicChanged.emit(this.transportClinic as TransportClinic);
      $(this.modalId).modal('hide');
    } catch (error) {
      this.logService.error(componentName, 'updateTransportClinic', error);
      this.toastr.error(error.message);
    }
  }

  async deleteTransportClinic() {
    try {
      await this.logisticsCustomerGraphqlService.deleteTransportClinic(this.transportClinic._id);
      await this.showSuccessToastr('TRANSPORT_CLINIC_DELETED');
      this.transportClinicDeleted.emit(this.transportClinic as TransportClinic);
      $(this.modalId).modal('hide');
    } catch (error) {
      this.logService.error(componentName, 'deleteTransportClinic', error);
      this.toastr.error(error.message);
    }
  }

  async showSuccessToastr(message) {
    const text = await this.translationsService.get(message);
    this.toastr.success(text);
  }

  closeModal() {
    this.transportClinic = {};
    $(this.modalId).modal('hide');
  }

}