import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationService } from '../../services/clinic/organization.service';
import template from './add-lab-modal.html';

@Component({
  selector: 'add-lab-modal',
  template: template
})
export class AddLabModalComponent implements OnInit {
  @Output() onLabChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() lab: any;
  organization: any = {};
  disableAddLabButton: boolean = true;

  constructor(
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private organizationService: OrganizationService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    if (!this.lab) {
      this.lab = {};
    }
    if (!this.lab.contact) {
      this.lab.contact = {};
    }
    if (!this.lab.contact.address) {
      this.lab.contact.address = {};
    }
  }

  async updateLab(labId: string) {}

  addLab(): Promise<void> {
    this.disableAddLabButton = false;
    this.lab.type = 'lab';
    return this.organizationService
      .saveOrganization(this.lab)
      .then(async (result) => {
        this.closeModal.emit();
        this.toastr.success(
          await this.translationsService.get('SUCCESSFULLY_ADDED')
        );
        this.disableAddLabButton = true;
        this.onLabChange.emit(result.data);
      })
      .catch(async (err) => {
        this.logService.error(
          'addlab--modal.component',
          'addLab',
          (await this.translationsService.get('ERROR_IN_ADD_LAB')) + ': ' + err
        );
        this.toastr.error(await this.translationsService.get('ERROR_IN_ADD_LAB'));
      });
  }

  cancel(): void {
    this.closeModal.emit();
  }
}
