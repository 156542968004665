import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../app.constant';
import { Injectable } from '@angular/core';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { FederatedLoginResponse } from '../../models/federated-login.model';

@Injectable()
export class FederatedService extends ResourceService {
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
  }

  federatedLogin(data: {
    federation: string;
    personal_number?: string;
    return_to: string;
    return_params: string;
  }): Promise<FederatedLoginResponse> {
    const params = new HttpParams()
      .set('federation', data.federation)
      .set('personal_number', data.personal_number)
      .set('return_to', data.return_to)
      .set('return_params', data.return_params);

    return this.get(
      API.API_BASE + 'federated-login/login/:federation',
      params,
      this.apiInterceptor
    );
  }

  federatedOperation(data: {
    federation: string;
    operation: string;
    personal_number?: string;
  }): Promise<FederatedLoginResponse> {
    const params = new HttpParams()
      .set('federation', data.federation)
      .set('operation', data.operation)
      .set('personal_number', data.personal_number);

    return this.get(
      API.API_BASE + 'federated-:operation/:federation',
      params,
      this.apiInterceptor
    );
  }
}
