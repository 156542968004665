export const translationAccountEnglish = {
  WHITE_LABEL: 'White label',
  WHITE_LABEL_DESCRIPTION: 'On/Off the lab white label feature',
  HEX_CODE: 'Hex code',
  LIMITED_DENTIST_ACCOUNT_CREATED: 'Added limited dentist',
  LAB_ID_MISSING: 'lab id is missing in url',
  WHITE_LABEL_ADD_DENTIST_LINK: 'White label add dentist link',
  MEDIA_DESIGN: 'Media and design',
  LOGIN_HERE: 'Already have an account? Login here.',
  CREATE_ACCOUNT: 'No account? Create account here',
  USER_ALREADY_EXIST:
    'A user with that email already exists, please login instead or contact support@denthub.com',
  ORGANIZATION_ALREADY_EXIST:
    'An clinic with that organization number already exists, please login instead or contact support@denthub.com',
  INVALID_ORGANIZATION_NUMBER: 'Enter a valid organization number',
  REGISTER_NEW_ACCOUNT: 'Register a new account',
  WHITELABEL_USER: 'User',
  USER_SECTION_DESC:
    'Please use your personal email as this will be used for login. If you need more then one user you only need to use this form once. Your user will be admin and you will be able to create more users if needed.',
  CLINIC_SECTION_DESC: 'Please enter contact details for your clinic.',
  CLINIC_NAME: 'Clinic name',
  DONE: 'Done!',
  HOW_INVOICE: 'How would you like to receive your invoice?',
  INVOICE_SAME_ADDRESS: 'Same address',
  INVOICE_DIFFERENT_ADDRESS: 'Diffrent address',
  INVOICE_EMAIL: 'By email'
};
