import * as angular from 'angular';

import '@uirouter/angularjs';
import '@uirouter/angularjs/lib/legacy/stateEvents';

import 'bootstrap';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-toastr';
import 'angular-resource';
import 'angular-dynamic-locale';
import 'ng-rollbar';
import 'angular-animate';
import 'angular-loading-bar';
import 'angular-scroll';
import 'ng-file-upload';
import 'angularjs-datepicker';
import 'angular-ui-bootstrap';
import 'angular-rateit';
import 'ng-tags-input';
import 'ui-select';
import 'angular-spinner';
import 'angular-base64';
import 'gm.datepicker-multi-select';
import 'angular-ui-switch';
import 'angular-promise-buttons';
import 'angularjs-dropdown-multiselect';
import 'angular-file-saver';
import 'ng-infinite-scroll';

import 'core-js/proposals/reflect-metadata';
import 'zone.js/dist/zone.js';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'classlist.js';
import 'web-animations-js';

import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';

import Pubsub from './app.pubsub';

import { NgModule, Provider, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxBarcode6Module } from 'ngx-barcode6';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from 'ng2-file-upload';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CookieService } from 'ngx-cookie-service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FileSaverModule } from 'ngx-filesaver';

import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MultipleDatePickerModule } from 'multiple-date-picker-angular';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';

// Material Design Modules used by this module
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';

// Constants
import { TOASTR_CONFIG } from './app.constant';

// Components
import { SettingsComponent } from './newmodules/settings/settings.component';
import { AccountSettingComponent } from './newmodules/account-setting/account-setting.component';
import { AccountSettingInfoComponent } from './newmodules/account-setting/account-setting-info.component';
import { AccountNotificationsComponent } from './newmodules/account-setting/account-notifications.component';
import { AccountSystemSettingComponent } from './newmodules/account-setting/account-system-setting.component';
import { AccountConstructionSettingComponent } from './newmodules/account-setting/account-construction-setting.component';
import { LogoImageService } from './services/logo-image/logo-image-service';
import { ApiInterceptor } from './interceptors/api-interceptor';
import { LabProfileComponent } from './newmodules/lab-profile/lab-profile.component';
import { LabContactComponent } from './newmodules/lab-profile/lab-contact.component';
import { LabAvailabilityComponent } from './newmodules/lab-profile/lab-availability.component';
import { LabGeneralInfoComponent } from './newmodules/lab-profile/lab-general-info.component';
import { LabNotificationsComponent } from './newmodules/lab-profile/lab-notifications.component';
import { LabMediaComponent } from './newmodules/lab-profile/lab-media.component';
import { LabPickUpAtClinicsComponent } from './newmodules/lab-profile/lab-pick-up-at-clinics.component';
import { ConfigurationComponent } from './newmodules/invoice-settings/configuration.component';
import { InvoiceClinicsComponent } from './newmodules/invoice-settings/invoice-clinics.component';
import { InvoiceInfoComponent } from './newmodules/invoice-settings/invoice-info.component';
import { InvoiceSettingsComponent } from './newmodules/invoice-settings/invoice-settings.component';
import { UploadButtonComponent } from './newmodules/upload/upload-button.component';
import { ChangePasswordComponent } from './newmodules/change-password-modal/change-password-modal.component';
import { ProfileComponent } from './newmodules/profile/profile.component';
import { LabTechnicianListItemComponent } from './newmodules/lab-technician/lab-technician-list-item/lab-technician-list-item.component';
import { LabTechnicianCardItemComponent } from './newmodules/lab-technician-card-view/lab-technician-card-item/lab-technician-card-item.component';
import { LabTechnicianCardComponent } from './newmodules/lab-technician-card-view/lab-technician-card.component';
import { AccountSettingLabComponent } from './newmodules/profile/account-setting-lab.component';
import { LabTechnicianComponent } from './newmodules/lab-technician/lab-technician.component';
import { SystemSettingLabComponent } from './newmodules/profile/system-setting-lab.component';
import { UserCardItemComponent } from './newmodules/user-card-view/user-card-item.component';
import { MenuListComponent } from './newmodules/menu-list/menu-list.component';
import { InviteLabModalComponent } from './newmodules/invite-lab-modal/invite-lab-modal.component';
import { LabFilterComponent } from './newmodules/lab-filter/lab-filter.component';
import { LabCardComponent } from './newmodules/lab-card-view/lab-card.component';
import { LabCardItemComponent } from './newmodules/lab-card-view/lab-card-item/lab-card-item.component';
import { AddLabModalComponent } from './newmodules/add-lab-modal/add-lab-modal.component';
import { LabComponent } from './newmodules/lab/lab.component';
import { LabListItemComponent } from './newmodules/lab/lab-list-item/lab-list-item.component';
import { ReportDescriptionModalComponent } from './newmodules/report-description-modal/report-description-modal.component';
import { ReportDataComponent } from './newmodules/reports/report-data.component';
import { ResetPasswordComponent } from './newmodules/reset-password/reset-password.component';
import { ContactInfoModalComponent } from './newmodules/order-details/contact-info-modal/contact-info-modal.component';
import { DentistInfoModalComponent } from './newmodules/order-details/dentist-info-modal/dentist-info-modal.component';
import { UserInfoModalComponent } from './newmodules/order-details/user-info-modal/user-info-modal.component';
import { ApproveOrderModalComponent } from './newmodules/order-details/approve-order-modal/approve-order-modal.component';
import { CertifyOrderModalComponent } from './newmodules/order-details/certify-order/certify-order-modal.component';
import { ConfirmationModalComponent } from './newmodules/confirm-modal/confirm-modal.component';
import { CancelOrderModalComponent } from './newmodules/order-details/cancel-order-modal/cancel-order-modal.component';
import { RateOrderModalComponent } from './newmodules/order-details/rate-order-modal/rate-order-modal.component';
import { RateOrderContentComponent } from './newmodules/order-details/rate-order-content/rate-order-content.component';
import { AcceptOrderModalComponent } from './newmodules/order-details/accept-order-modal/accept-order-modal.component';
import { AddImageModalComponent } from './newmodules/order-details/add-image-modal/add-image-modal.component';
import { SampleTypeComponent } from './newmodules/order-new-2/sample-type/sample-type.component';
import { SubCategoriesComponent } from './feature/order/pages/action-based-order/constructions/sub-categories/sub-categories.component';
import { SampleContentInfoComponent } from './newmodules/order-details/sample-content-info/sample-content-info.component';
import { AdditionalInfoComponent } from './newmodules/order-details/additional-info/additional-info.component';
import { ConnectToLabModalComponent } from './newmodules/manage-labs/connect-to-lab-modal/connect-to-lab-modal.component';
import { ArchiveOrderModalComponents } from './newmodules/order-details/archive-order-modal/archive-order-modal.components';
import { DeclineOrderModalComponent } from './newmodules/order-details/decline-order-modal/decline-order-modal.component';
import { ChatComponent } from './newmodules/order-details/chat/chat.component';
import { DenthubHeaderComponent } from './newmodules/denthub-header/denthub-header.component';
import { MaterialGroupModalComponent } from './newmodules/manage-actions/material-group-modal/material-group-modal.component';
import { SchedulePickUpFromTruckIconModalComponent } from './newmodules/logistics/schedule-pick-up-from-truck-icon-modal/schedule-pick-up-from-truck-icon-modal';
import { AddActionModalComponent } from './newmodules/manage-actions/add-action-modal/add-action-modal.component';
import { PatientComponent } from './feature/order/pages/action-based-order/patient/patient.component';
import { PatientOrderListComponent } from './feature/order/shared/patient-order-list/patient-order-list.component';
import { MainCategoriesComponent } from './feature/order/pages/action-based-order/constructions/main-categories/main-categories.component';
import { ActionListsComponent } from './newmodules/lab-profile/action-lists/action-lists.component';
import { AddActionListModalComponent } from './newmodules/lab-profile/action-lists/add-action-list-modal/add-action-list-modal.component';
import { CategoriesComponent } from './newmodules/lab-profile/action-lists/categories/categories.component';
import { AddCategoryModalComponent } from './newmodules/lab-profile/action-lists/categories/add-category-modal/add-category-modal.component';
import { ActionsComponent } from './newmodules/lab-profile/action-lists/actions/actions.component';
import { AddActionListActionModalComponent } from './newmodules/lab-profile/action-lists/actions/add-actionList-action-modal/add-actionList-action-modal.component';
import { AddSimpleOrderActionModalComponent } from './newmodules/order-details/add-simple-order-action-modal/add-simple-order-action-modal.component';
import { ClinicReceivedDeliveryModalComponent } from './newmodules/order-details/clinic-received-delivery-modal/clinic-received-delivery-modal.component';
import { LabWorkStartedModalComponent } from './newmodules/order-details/lab-work-started-modal/lab-work-started-modal.component';
import { ProductionConfirmModalComponent } from './newmodules/order-details/production-confirm-modal/production-confirm-modal.component';
import { DeviationsComponent } from './newmodules/order-details/deviations/deviations.component';
import { ErrorModalComponent } from './newmodules/error-modal/error-modal.component';

// Services
import { PermissionService } from './services/core/permission.service';
import { FileUploadService } from './services/file/file-upload.service';
import { UserRoleService } from './services/roles/user-role.service';
import { LogService } from './services/core/log.service';
import { CacheService } from './services/core/cache.service';
import { CurrentUserService } from './services/users/current-user.service';
import { GetAllOrdersService } from './services/order-list/get-all-orders.service';
import { GetAllUsersService } from './services/order-list/get-all-users.service';
import { OrganizationSettingService } from './services/organization-setting/organization-setting.service';
import { SessionService } from './services/core/session.service';
import { UsersService } from './services/users/users.service';
import { OrganizationService } from './services/clinic/organization.service';
import { ReportsService } from './services/reports/reports.service';
import { MaterialService } from './services/manage-actions/material-group.service';
import { CategoryService } from './services/manage-actions/category.service';
import { ActionService } from './services/manage-actions/action.service';
import { RoleService } from './services/roles/role.service';
import { OrderService } from './services/order-new/order-new.service';
import { InvoiceService } from './services/invoice-list/invoice.service';
import { FederatedService } from './services/federated-login/federated.service';
import { TokenService } from './services/core/token.service';
import { EventService } from './services/core/event.service';
import { CurrentYearDayService } from './services/core/current-year-day.service';
import { DenthubSettingsService } from './services/core/denthub-settings.service';
import { CustomerService } from './services/customer/customer.service';
import { PrintService } from './services/print/print.service';
import { TranslationsService } from './services/translations/translations.service';
import { SubNavService } from './services/sub-nav/sub-nav.service';
import { UnArchiveOrderService } from './services/order-new/un-archive-order.service';
import { FormatReportService } from './services/format-report/format-report.service';
import { Json2CSVService } from './services/json-2-csv/json-2-csv.service';
import { Json2ExcelService } from './services/json-2-excel/json-2-excel.service';
import { LogisticsService } from './services/logistics/logistics.service';
import { StatusService } from './services/status/status.service';
import { TransportDateService } from './services/transport-date/transport-date.service';
import { PatientCategoryService } from './feature/order/services/patient-category-service';
import { ActionListService } from './services/action-list/action-list.service';
import { ImageAnnotationService } from './services/image-annotation/image-annotation.service';
import { NewOrderListRedirectService } from './services/new-order-list-redirect/new-order-list-redirect.service';
import { RegexService } from './services/regex/regex';
import { CountryNameService } from './services/country-name/country-name';
import { EscapedMessageService } from './services/escaped-message/escaped-message.service';

// Directives
import { NumberOnlyDirective } from './directives/number-only.directive';
import { EnterEventDirective } from './directives/enter-event.directive';
import { ScrollToBottomDirective } from './directives/scroll-to-bottom.directive';
import { AppRootComponent } from './newmodules/app-root.component';
import { FederatedLoginComponent } from './newmodules/federated-login/federated-login.component';
import { FederatedSelectOrganizationComponent } from './newmodules/federated-login/federated-select-organization.component';
import { LanguageSelectorComponent } from './newmodules/login/language-selector.component';
import { LocaleFormatterService } from './services/locale-formatter/locale-formatter.service';
import { BankIdLoginComponent } from './newmodules/bankid-login/bankid-login.component';
import { LoginComponent } from './newmodules/login/login.component';
import { PersonalNumberValidator } from './directives/personalnumber-validator.directive';
import { FederatedOperationFrameComponent } from './newmodules/federated-operation/federated-operation-frame.component';
import { UsersListItemComponent } from './newmodules/users/users-list-item/users-list-item.component';
import { AddDentistModalComponent } from './newmodules/add-dentist-modal/add-dentist-modal.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ChangeDentistModalComponent } from './feature/order/pages/action-based-order/patient/additional-dentist/change-dentist-modal/change-dentist-modal.component';
import { ClinicFilterComponent } from './newmodules/clinic-filter/clinic-filter.component';
import { SpinnerComponent } from './newmodules/spinner/spinner.component';
import { AddLabTechnicianModalComponent } from './newmodules/add-lab-technician-modal/add-lab-technician-modal.component';
import { SearchComponent } from './newmodules/search/search.component';
import { UsersCardComponent } from './newmodules/user-card-view/user-card.component';
import { OrderFilterComponent } from './newmodules/order-filter/order-filter.component';
import { ForgotPasswordComponent } from './newmodules/forgot-password/forgot-password.component';
import { NotificationsComponent } from './newmodules/notifications/notifications.component';
import { CustomerSettingsComponent } from './newmodules/customer-settings/customer-settings.component';
import { TextFieldComponent } from './newmodules/customer-settings/text-field/text-field.component';
import { TextAreaComponent } from './newmodules/customer-settings/text-area/text-area.component';
import { DropDownComponent } from './newmodules/customer-settings/drop-down/drop-down.component';
import { CheckBoxComponent } from './newmodules/customer-settings/check-box/check-box.component';
import { PermissionsComponent } from './newmodules/permissions/permissions.component';
import { AddRoleModalComponent } from './newmodules/permissions/add-role-modal/add-role-modal.component';
import { OrderConfirmComponent } from './newmodules/order-confirm/order-confirm.component';
import { AddClinicModalComponent } from './newmodules/clinic/add-clinic-modal.component';
import { ClinicsComponent } from './newmodules/clinic/clinics.component';
import { ClinicsListItemComponent } from './newmodules/clinic/clinic-list-item.component';
import { ClinicsCardComponent } from './newmodules/clinic-card-view/clinics-card.component';
import { ClinicCardItemComponent } from './newmodules/clinic-card-view/clinic-card-item/clinic-card-item.component';
import { UsersComponent } from './newmodules/users/users.component';
import { OrderListItemComponent } from './newmodules/order-list/order-list-item.component';
import { OrderListComponent } from './newmodules/order-list/order-list.component';
import { OrderStatusCardComponent } from './newmodules/order-status-view/order-status-card.component';
import { OrderStatusViewComponent } from './newmodules/order-status-view/order-status-view.component';
import { ManageLabsComponent } from './newmodules/manage-labs/manage-labs.component';
import { ShowConnectedLabsItemComponent } from './newmodules/manage-labs/show-connected-labs-item.component';
import { MaterialPriceDiffModalComponent } from './newmodules/manage-labs/edit-lab/material-price-diff-modal/material-price-diff-modal.component';
import { EditLabComponent } from './newmodules/manage-labs/edit-lab/edit-lab.component';
import { MainCategoryModalComponent } from './newmodules/manage-actions/main-category-modal/main-category-modal.component';
import { SubCategoryModalComponent } from './newmodules/manage-actions/sub-category-modal/sub-category-modal.component';
import { LabsAvailabilityComponent } from './newmodules/manage-labs/labs-availability.component';
import { DentistSearchFilterComponent } from './newmodules/dentist-search-filter/dentist-search-filter.component';
import { MaterialListsComponent } from './newmodules/lab-profile/material-lists/material-lists.component';
import { AddMaterialModalComponent } from './newmodules/lab-profile/material-lists/materials/add-material-modal/add-material-modal.components';
import { AddMaterialListModalComponent } from './newmodules/lab-profile/material-lists/add-material-list-modal/add-material-list-modal.component';
import { MaterialsComponent } from './newmodules/lab-profile/material-lists/materials/materials.component';
import { OrderInfoComponent } from './newmodules/order-details/order-info/order-info.component';
import { ManageActionComponent } from './newmodules/manage-actions/manage-actions.component';
import { AddOrderMaterialModalComponent } from './newmodules/order-details/add-order-material-modal/add-order-material-modal.component';
import { PercentageDirective } from './directives/percentage.directive';
import { TwoDigitDecimalNumberDirective } from './directives/two-digit-decimal-number.directive';
import { DecimalWithNegativeValueDirective } from './directives/decimal-with-negative-value.directive';
import { MaterialListService } from './services/material-list/material-list.service';
import { ActionsDropdownComponent } from './feature/order/pages/action-based-order/constructions/actions-dropdown/actions-dropdown.component';
import { DataUploadModalComponent } from './newmodules/lab/lab-list-item/data-upload-modal/data-upload-modal.component';
import { PriceSpecModalComponent } from './newmodules/order-details/price-spec-modal/price-spec-modal.component';
import { PriceSpecListComponent } from './newmodules/order-details/price-spec-list/price-spec-list.component';
import { AdditionalDentistComponent } from './feature/order/pages/action-based-order/patient/additional-dentist/additional-dentist.component';
import { ManageParticipantsComponent } from './feature/order/pages/action-based-order/patient/manage-participants/manage-participants.component';
import { PatientOrderListItemComponent } from './feature/order/shared/patient-order-list/patient-order-list-item/patient-order-list-item.component';
import { ConstructionSettingsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/construction-settings.component';
import { ConstructionHeaderComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/construction-header/construction-header.component';
import { ConstructionActionListComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/construction-action-list/construction-action-list.component';
import { MandatoryConstructionSettingsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/mandatory-construction-settings/mandatory-construction-settings.component';
import { OptionalConstructionSettingsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/optional-construction-settings/optional-construction-settings.component';
import { ClaspMaterialComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/clasp-material/clasp-material.component';
import { OrthoColorComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/ortho-color/ortho-color.component';
import { ClaspTeethComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/clasp-teeth/clasp-teeth.component';
import { ClaspTypeComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/clasp-type/clasp-type.component';
import { CommentsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/comments/comments.component';
import { ContactTypeComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/contact-type/contact-type.component';
import { DentureStepComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/denture-step/denture-step.component';
import { ExpansionScrewComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/expansion-screw/expansion-screw.component';
import { FacialLoopComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/facial-loop/facial-loop.component';
import { ImplantStepComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/implant-step/implant-step.component';
import { MaterialComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/material/material.component';
import { MetalDesignComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/metal-design/metal-design.component';
import { NumberOfUnitsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/number-of-units/number-of-units.component';
import { OcclusionComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/occlusion/occlusion.component';
import { PonticDesignComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/pontic-design/pontic-design.component';
import { ProtrusionComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/protrusion/protrusion.component';
import { RetainmentComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/retainment/retainment.component';
import { SpringTeethComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/spring-teeth/spring-teeth.component';
import { SpringTypeComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/spring-type/spring-type.component';
import { StepComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/step/step.component';
import { TestingStepComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/testing-step/testing-step.component';
import { WarrantyComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/warranty/warranty.component';
import { ShadesAndColorsComponent } from './feature/order/shared/shades-and-colors/shades-and-colors.component';
import { BtnListTypeSettingComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/btn-list-type-setting/btn-list-type-setting';
import { PositiveNumberDirective } from './directives/positive-number.directive';
import { MultiselectSettingsComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/multiselect-settings/multiselect-settings.component';
import { ConstructionBlocksComponent } from './feature/order/pages/action-based-order/constructions/construction-blocks/construction-blocks.component';
import { ConstructionsComponent } from './feature/order/pages/action-based-order/constructions/constructions.component';
import { TeethStructureComponent } from './feature/order/pages/action-based-order/constructions/teeth-structure/teeth-structure.component';
import { ActionNotesComponent } from './feature/order/pages/action-based-order/constructions/action-notes/action-notes.component';
import { FileUploadComponent } from './feature/order/pages/action-based-order/constructions/file-upload/file-upload.component';
import { PersonalIdDirective } from './directives/personal-id.directive';
import { ThreeShapeUploadModalComponent } from './feature/order/pages/action-based-order/constructions/three-shape-upload-modal/three-shape-upload.component';
import { ThreeShapeScanViewComponent } from './feature/order/pages/action-based-order/three-shape-scan-view/three-shape-scan-view.component';
import { OrderDetailsSubnavComponent } from './feature/order/pages/order-details-subnav/order-details-subnav.component';
import { WarrantyTypeModalComponent } from './feature/order/pages/action-based-order/constructions/construction-settings/settings/warranty-type-modal/warranty-type-modal.component';

// Modules
import { TransportAppModule } from './feature/transport/transport-app.module';
import { PipesModule } from './pipes/pipes.module';
import { OrderIdBarcodeComponent } from './newmodules/order-details/order-id-barcode/order-id-barcode.component';
import { DownloadSectionComponent } from './newmodules/order-details/download-section/download-section.component';
import { AccountModule } from './feature/account/account.module';
import { CareAdviceComponent } from './newmodules/order-details/care-advice/care-advice.component';
import { LogisticsCustomerComponent } from './feature/transport/pages/logistics-customer/logistics-customer.component';
import { LogisticsCustomerListComponent } from './feature/transport/pages/logistics-customer/logistics-customer-list/logistics-customer-list.component';
import { TransportClinicsComponents } from './feature/transport/pages/transport-clinics/transport-clinics.components';
import { TransportClinicsListComponent } from './feature/transport/pages/transport-clinics/transport-clinics-list/transport-clinics-list.component';
import { AddTransportClinicComponent } from './feature/transport/pages/transport-clinics/add-transport-clinic/add-transport-clinic.component';
import { ThreeShapeInfoComponent } from './newmodules/order-details/three-shape-info/three-shape-info.component';
import { ThreeShapeRestorationsTableComponent } from './newmodules/order-details/three-shape-restorations-table/three-shape-restorations-table.component';
import { AddedMaterialsTableHeaderComponent } from './newmodules/order-details/added-materials/added-materials-table-header.component';
import { AddedActionsTableHeaderComponent } from './newmodules/order-details/added-actions/added-actions-table-header.component';
import { AddedActionsComponent } from './newmodules/order-details/added-actions/added-actions.component';
import { AddedMaterialsComponent } from './newmodules/order-details/added-materials/added-materials.component';
import { ThreeShapeAttachmentsComponent } from './newmodules/order-details/three-shape-attachments/three-shape-attachments.component';
import Cookies = require('js-cookie');
import { NewFrontendNavigateService } from './services/new-frontend-navigate/new-frontend-navigate.service';

const testModule = (module): void => {
  try {
    angular.module(module);
  } catch (err) {
    console.error(`- Error loading module "${module}"!`, err);
  }
};

const modules = [
  'ngSanitize',
  'pascalprecht.translate',
  'ui.router',
  'ui.router.state.events',
  'toastr',
  'ngResource',
  'tmh.dynamicLocale',
  'ngAnimate',
  'angular-loading-bar',
  'duScroll',
  'ngFileUpload',
  '720kb.datepicker',
  'ui.bootstrap',
  'ngRateIt',
  'ngTagsInput',
  'ui.select',
  'angularSpinner',
  'base64',
  'gm.datepickerMultiSelect',
  'uiSwitch',
  'angularPromiseButtons',
  'angularjs-dropdown-multiselect',
  'ngFileSaver',
  'infinite-scroll'
];

const upgradedServices = [
  // factories
  'fileService',
  'formatReportService',
  'materialService',
  'organizationService',
  'shadesColorService',
  'translationsLoader',

  // services
  'OrderDetails2Service',
  'additionalInfoService',
  'labAvailabilityCalendarService',
  'orderNew2DeliveryService',
  'orderNewDeliveryService',
  'patientCategoryService',
  'printService',
  'removableTeethStructureService',
  'searchLabTextService',
  'teethStructureService',
  'toothStructureService',

  // 3rd party
  '$location',
  '$state',
  '$stateParams',
  '$window',
  '$document',
  '$translate',
  'tmhDynamicLocale',
  'Rollbar'
];

angular.module('app', modules);

modules.forEach((module) => {
  testModule(module);
});

export const upgradedServiceProviders: Provider[] = upgradedServices.map(
  (service) => {
    return {
      provide: service,
      useFactory: (i: { get: Function }) => {
        return i.get(service);
      },
      deps: ['$injector']
    };
  }
);

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    UpgradeModule,
    FormsModule,
    FileUploadModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    MultipleDatePickerModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    BrowserAnimationsModule,
    InfiniteScrollModule,
    ToastrModule.forRoot(TOASTR_CONFIG),
    NgxSpinnerModule,
    FileSaverModule,
    Angular2PromiseButtonModule.forRoot(),
    PipesModule,
    TransportAppModule,
    AccountModule,
    NgxBarcode6Module,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatCardModule,
    MatTabsModule
  ],
  declarations: [
    // Components
    AppRootComponent,
    SpinnerComponent,
    SettingsComponent,
    AccountSettingComponent,
    AccountSettingInfoComponent,
    AccountNotificationsComponent,
    AccountSystemSettingComponent,
    AccountConstructionSettingComponent,
    LabProfileComponent,
    LabContactComponent,
    LabAvailabilityComponent,
    LabGeneralInfoComponent,
    LabNotificationsComponent,
    LabMediaComponent,
    LabPickUpAtClinicsComponent,
    MaterialListsComponent,
    AddMaterialListModalComponent,
    ConfigurationComponent,
    InvoiceClinicsComponent,
    InvoiceInfoComponent,
    InvoiceSettingsComponent,
    UploadButtonComponent,
    ChangePasswordComponent,
    ProfileComponent,
    SystemSettingLabComponent,
    AccountSettingLabComponent,
    FederatedLoginComponent,
    FederatedSelectOrganizationComponent,
    LanguageSelectorComponent,
    LabTechnicianComponent,
    LabTechnicianListItemComponent,
    LabTechnicianCardComponent,
    LabTechnicianCardItemComponent,
    DenthubHeaderComponent,
    LoginComponent,
    UsersCardComponent,
    UserCardItemComponent,
    MenuListComponent,
    UsersListItemComponent,
    InviteLabModalComponent,
    LabFilterComponent,
    ChangeDentistModalComponent,
    AddLabModalComponent,
    AddDentistModalComponent,
    ClinicFilterComponent,
    LabCardComponent,
    LabCardItemComponent,
    AddLabTechnicianModalComponent,
    ForgotPasswordComponent,
    SearchComponent,
    LabComponent,
    LabListItemComponent,
    AddClinicModalComponent,
    ClinicsCardComponent,
    ClinicCardItemComponent,
    UsersComponent,
    NotificationsComponent,
    OrderFilterComponent,
    ReportDescriptionModalComponent,
    ReportDataComponent,
    CustomerSettingsComponent,
    TextFieldComponent,
    TextAreaComponent,
    DropDownComponent,
    CheckBoxComponent,
    PermissionsComponent,
    ConnectToLabModalComponent,
    OrderConfirmComponent,
    ResetPasswordComponent,
    ContactInfoModalComponent,
    DentistInfoModalComponent,
    UserInfoModalComponent,
    ApproveOrderModalComponent,
    CertifyOrderModalComponent,
    ConfirmationModalComponent,
    CancelOrderModalComponent,
    RateOrderModalComponent,
    RateOrderContentComponent,
    AcceptOrderModalComponent,
    AddImageModalComponent,
    SampleTypeComponent,
    SubCategoriesComponent,
    SampleContentInfoComponent,
    AdditionalInfoComponent,
    AddRoleModalComponent,
    DeclineOrderModalComponent,
    ChatComponent,
    CareAdviceComponent,
    ClinicsComponent,
    ClinicsListItemComponent,
    OrderListItemComponent,
    OrderListComponent,
    ArchiveOrderModalComponents,
    OrderStatusCardComponent,
    OrderStatusViewComponent,
    ManageLabsComponent,
    ShowConnectedLabsItemComponent,
    MaterialPriceDiffModalComponent,
    MaterialsComponent,
    MaterialGroupModalComponent,
    AddOrderMaterialModalComponent,
    EditLabComponent,
    MainCategoryModalComponent,
    SubCategoryModalComponent,
    LabsAvailabilityComponent,
    DentistSearchFilterComponent,
    ArchiveOrderModalComponents,
    DeclineOrderModalComponent,
    AddActionModalComponent,
    ManageActionComponent,
    OrderInfoComponent,
    ThreeShapeInfoComponent,
    ThreeShapeRestorationsTableComponent,
    AddedMaterialsTableHeaderComponent,
    AddedActionsTableHeaderComponent,
    AddedActionsComponent,
    AddedMaterialsComponent,
    ThreeShapeAttachmentsComponent,
    ActionsDropdownComponent,
    AddMaterialModalComponent,
    SchedulePickUpFromTruckIconModalComponent,
    DataUploadModalComponent,
    PriceSpecModalComponent,
    PriceSpecListComponent,
    PatientComponent,
    AdditionalDentistComponent,
    ManageParticipantsComponent,
    PatientOrderListComponent,
    PatientOrderListItemComponent,
    MainCategoriesComponent,
    FileUploadComponent,
    ActionListsComponent,
    AddActionListModalComponent,
    CategoriesComponent,
    AddCategoryModalComponent,
    ActionsComponent,
    AddActionListActionModalComponent,
    AddSimpleOrderActionModalComponent,

    ConstructionSettingsComponent,
    ConstructionHeaderComponent,
    ConstructionActionListComponent,
    MandatoryConstructionSettingsComponent,
    OptionalConstructionSettingsComponent,
    ClaspMaterialComponent,
    OrthoColorComponent,
    ClaspTeethComponent,
    ClaspTypeComponent,
    CommentsComponent,
    ContactTypeComponent,
    DentureStepComponent,
    ExpansionScrewComponent,
    FacialLoopComponent,
    ImplantStepComponent,
    MaterialComponent,
    MetalDesignComponent,
    NumberOfUnitsComponent,
    OcclusionComponent,
    PonticDesignComponent,
    ProtrusionComponent,
    RetainmentComponent,
    SpringTeethComponent,
    SpringTypeComponent,
    StepComponent,
    TestingStepComponent,
    WarrantyComponent,
    WarrantyTypeModalComponent,
    ShadesAndColorsComponent,
    BtnListTypeSettingComponent,
    MultiselectSettingsComponent,

    ConstructionBlocksComponent,
    ConstructionsComponent,
    TeethStructureComponent,
    ActionNotesComponent,
    ThreeShapeUploadModalComponent,
    ThreeShapeScanViewComponent,
    OrderIdBarcodeComponent,
    DownloadSectionComponent,
    ClinicReceivedDeliveryModalComponent,
    LabWorkStartedModalComponent,
    LogisticsCustomerComponent,
    LogisticsCustomerListComponent,
    TransportClinicsComponents,
    TransportClinicsListComponent,
    AddTransportClinicComponent,
    OrderDetailsSubnavComponent,

    // Pipes & directives
    PersonalIdDirective,
    NumberOnlyDirective,
    EnterEventDirective,
    PercentageDirective,
    TwoDigitDecimalNumberDirective,
    PositiveNumberDirective,
    DecimalWithNegativeValueDirective,
    ScrollToBottomDirective,
    BankIdLoginComponent,
    FederatedOperationFrameComponent,
    PersonalNumberValidator,
    ProductionConfirmModalComponent,
    DeviationsComponent,
    ErrorModalComponent
  ],
  entryComponents: [
    PatientComponent,
    AdditionalDentistComponent,
    ManageParticipantsComponent,
    FederatedLoginComponent,
    FederatedSelectOrganizationComponent,
    SettingsComponent,
    ProfileComponent,
    ChangePasswordComponent,
    LanguageSelectorComponent,
    BankIdLoginComponent,
    LoginComponent,
    DenthubHeaderComponent,
    AddLabModalComponent,
    AddDentistModalComponent,
    LabTechnicianComponent,
    LabTechnicianCardComponent,
    AppRootComponent,
    ChangeDentistModalComponent,
    InviteLabModalComponent,
    UsersCardComponent,
    LabFilterComponent,
    ClinicFilterComponent,
    LabCardComponent,
    AddLabTechnicianModalComponent,
    UsersListItemComponent,
    LabComponent,
    SearchComponent,
    UsersComponent,
    MenuListComponent,
    ReportDescriptionModalComponent,
    ReportDataComponent,
    CustomerSettingsComponent,
    PermissionsComponent,
    OrderConfirmComponent,
    ResetPasswordComponent,
    ContactInfoModalComponent,
    DentistInfoModalComponent,
    UserInfoModalComponent,
    CancelOrderModalComponent,
    ApproveOrderModalComponent,
    CertifyOrderModalComponent,
    ConfirmationModalComponent,
    RateOrderModalComponent,
    AcceptOrderModalComponent,
    AddImageModalComponent,
    SampleTypeComponent,
    SubCategoriesComponent,
    SampleContentInfoComponent,
    AdditionalInfoComponent,
    DeclineOrderModalComponent,
    ChatComponent,
    CareAdviceComponent,
    AddRoleModalComponent,
    OrderFilterComponent,
    NotificationsComponent,
    ForgotPasswordComponent,
    AddClinicModalComponent,
    ClinicsComponent,
    ClinicsCardComponent,
    OrderListComponent,
    OrderStatusViewComponent,
    AddOrderMaterialModalComponent,
    ManageLabsComponent,
    EditLabComponent,
    MainCategoryModalComponent,
    SubCategoryModalComponent,
    LabsAvailabilityComponent,
    ConnectToLabModalComponent,
    MaterialPriceDiffModalComponent,
    ArchiveOrderModalComponents,
    DeclineOrderModalComponent,
    OrderInfoComponent,
    ThreeShapeInfoComponent,
    ThreeShapeRestorationsTableComponent,
    AddedMaterialsTableHeaderComponent,
    AddedActionsTableHeaderComponent,
    AddedActionsComponent,
    AddedMaterialsComponent,
    ThreeShapeAttachmentsComponent,
    AddActionModalComponent,
    ManageActionComponent,
    AddMaterialModalComponent,
    ActionsDropdownComponent,
    MaterialGroupModalComponent,
    SchedulePickUpFromTruckIconModalComponent,
    DataUploadModalComponent,
    PriceSpecModalComponent,
    PatientOrderListComponent,
    PatientOrderListItemComponent,

    ConstructionSettingsComponent,
    ConstructionHeaderComponent,
    ConstructionActionListComponent,
    MandatoryConstructionSettingsComponent,
    OptionalConstructionSettingsComponent,
    ClaspMaterialComponent,
    OrthoColorComponent,
    ClaspTeethComponent,
    ClaspTypeComponent,
    CommentsComponent,
    ContactTypeComponent,
    DentureStepComponent,
    ExpansionScrewComponent,
    FacialLoopComponent,
    ImplantStepComponent,
    MaterialComponent,
    MetalDesignComponent,
    NumberOfUnitsComponent,
    OcclusionComponent,
    PonticDesignComponent,
    ProtrusionComponent,
    RetainmentComponent,
    SpringTeethComponent,
    SpringTypeComponent,
    StepComponent,
    TestingStepComponent,
    WarrantyComponent,
    WarrantyTypeModalComponent,
    ShadesAndColorsComponent,
    BtnListTypeSettingComponent,
    MultiselectSettingsComponent,
    ConstructionBlocksComponent,
    MainCategoriesComponent,
    FileUploadComponent,
    ConstructionsComponent,
    TeethStructureComponent,
    ActionNotesComponent,
    AddSimpleOrderActionModalComponent,
    OrderIdBarcodeComponent,
    DownloadSectionComponent,
    ThreeShapeUploadModalComponent,
    ThreeShapeScanViewComponent,
    PriceSpecListComponent,
    ClinicReceivedDeliveryModalComponent,
    LabWorkStartedModalComponent,
    LogisticsCustomerComponent,
    TransportClinicsComponents,
    LabWorkStartedModalComponent,
    OrderDetailsSubnavComponent,
    ProductionConfirmModalComponent,
    DeviationsComponent,
    ErrorModalComponent
  ],
  providers: upgradedServiceProviders.concat([
    // 3rd party
    CookieService,

    // Denthub services
    ApiInterceptor,
    TokenService,
    UsersService,
    [
      {
        provide: APOLLO_OPTIONS,
        useFactory: (httpLink: HttpLink) => {
          const http = httpLink.create({
            uri: '/graphql'
          });

          let ws: any;
          ws = new WebSocketLink({
            uri: `${process.env.WS_URL}/graphql`,
            options: {
              reconnect: true,
              inactivityTimeout: 0,
              connectionParams: {
                headers: {
                  Authorization: Cookies.get('token') || null
                }
              }
            }
          });

          ws.subscriptionClient.on('connected', () => {
            console.log('connected');
          });

          ws.subscriptionClient.on('reconnected', () => {
            console.log('reconnected');
            Pubsub.publish('appReConnected');
          });

          ws.subscriptionClient.on('disconnected', () => {
            Pubsub.publish('appDisconnected');
          });

          const middleware = new ApolloLink((operation, forward) => {
            operation.setContext({
              headers: new HttpHeaders().set(
                'Authorization',
                Cookies.get('token') || null
              )
            });
            return forward(operation);
          });

          const splitLink = split(
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
              );
            },
            ws,
            http
          );

          const link = middleware.concat(splitLink);
          return {
            cache: new InMemoryCache(),
            link
          };
        },
        deps: [HttpLink]
      }
    ],
    [
      CacheService,
      {
        provide: APP_INITIALIZER,
        useFactory: (cs: CacheService) => () => cs.init(),
        deps: [CacheService],
        multi: true
      }
    ],
    UnArchiveOrderService,
    CurrentUserService,
    CategoryService,
    GetAllOrdersService,
    GetAllUsersService,
    LogService,
    OrganizationSettingService,
    PermissionService,
    FileUploadService,
    StatusService,
    TransportDateService,
    SessionService,
    LogoImageService,
    UserRoleService,
    OrganizationService,
    MaterialService,
    ReportsService,
    ActionService,
    RoleService,
    OrderService,
    InvoiceService,
    FederatedService,
    EventService,
    CurrentYearDayService,
    DenthubSettingsService,
    CustomerService,
    PrintService,
    TranslationsService,
    LocaleFormatterService,
    SubNavService,
    UnArchiveOrderService,
    FormatReportService,
    Json2CSVService,
    Json2ExcelService,
    MaterialListService,
    LogisticsService,
    PatientCategoryService,
    ActionListService,
    ImageAnnotationService,
    NewOrderListRedirectService,
    RegexService,
    CountryNameService,
    NewFrontendNavigateService,
    EscapedMessageService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: Window, useValue: window }
  ])
})
export class AppModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly upgrade: UpgradeModule
  ) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }

  ngDoBootstrap(): void {
    this.upgrade.bootstrap(document.documentElement, ['app']);
  }
}

try {
  platformBrowserDynamic().bootstrapModule(AppModule);
} catch (e) {
  console.error('ERROR BOOTSTRAPPING', e);
}
