import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

type Country = {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  organizationNumberPattern: string | null;
  organizationNumberFormat: string | null;
  postalCodePattern: string | null;
  postalCodeFormat: string | null;
};
@Injectable()
export class CountryNameService {
  private jsonPath = '../../../country.json';
  constructor(private http: HttpClient) { }
  async countryName(countryCode: string): Promise<string> {
    return this.http
      .get<Country[]>(this.jsonPath)
      .toPromise()
      .then((countries: Country[]) => {
      const country = countries.find(
          (country: Country) => country.alpha2Code === countryCode
      );
        return country ? country.name : '';
    });
  }

}
