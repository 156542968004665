import * as angular from 'angular';
import { LanguageCode } from './models/language.model';

export const API = {
  API_BASE: '/api/v1/',
  API_V2_BASE: '/api/v2/'
};

export const BOTH_JAW_TEETH = [
  18,
  17,
  16,
  15,
  14,
  13,
  12,
  11,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  48,
  47,
  46,
  45,
  44,
  43,
  42,
  41,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38
];

export const LOWER_JAW_TEETH = [
  48,
  47,
  46,
  45,
  44,
  43,
  42,
  41,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38
];
export const UPPER_JAW_TEETH = [
  18,
  17,
  16,
  15,
  14,
  13,
  12,
  11,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28
];

export const AVAILABLE_SETTINGS = [
  'material',
  'colorOrShade',
  'contactType',
  'dentureSteps',
  'design',
  'implantSteps',
  'occlusion',
  'ponticDesign',
  'retainment',
  'claspTeeth',
  'claspMaterial',
  'orthColor',
  'facialLoop',
  'claspType',
  'springType',
  'springTeeth',
  'expansionScrew',
  'protrusion',
  'testingSteps'
];

// @ts-ignore
export const WEBPACK_HASH = __hash__;
// @ts-ignore
export const WEBPACK_PATH = __publicpath__;

export const SHADES_AND_COLORS = [
  {
    name: 'Bioform',
    colors: [
      '51',
      '52',
      '53',
      '54',
      '55',
      '56',
      '59',
      '62',
      '63',
      '64',
      '65',
      '66',
      '67',
      '68',
      '69',
      '70',
      '77',
      '81',
      '82',
      '83',
      '84',
      '85',
      '91',
      '92',
      '93',
      '94',
      '95',
      '96'
    ]
  },
  {
    name: 'Vita Classic',
    colors: [
      'A1',
      'A2',
      'A3',
      'A3.5',
      'A4',
      'B1',
      'B2',
      'B3',
      'B4',
      'C1',
      'C2',
      'C3',
      'C4',
      'D2',
      'D3',
      'D4',
      'M1',
      'M2',
      'M3'
    ]
  },
  {
    name: 'Vita 3D Master',
    colors: [
      '1M1',
      '1M2',
      '2L1.5',
      '2L2.5',
      '2M1',
      '2M2',
      '2M3',
      '2R-1.5',
      '2R2.5',
      '3L1.5',
      '3L2.5',
      '3M1',
      '3M2',
      '3M3',
      '3R1.5',
      '3R2.5',
      '4L1.5',
      '4L2.5',
      '4M1',
      '4M2',
      '4M3',
      '4R1.5',
      '4R2.5',
      '5M1',
      '5M2',
      '5M3'
    ]
  },
  {
    name: 'Chromascope',
    colors: [
      '110',
      '120',
      '130',
      '140',
      '210',
      '220',
      '230',
      '240',
      '310',
      '320',
      '330',
      '340',
      '410',
      '420',
      '430',
      '440',
      '510',
      '520',
      '530',
      '540'
    ]
  },
  {
    name: 'Myersons',
    colors: [
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      '41',
      '58',
      '60',
      '62',
      '65',
      '69',
      '70',
      '71',
      '73',
      '74'
    ]
  },
  {
    name: 'Verident',
    colors: [
      'B1',
      'P1',
      'P2',
      'P3',
      'P4',
      'P5',
      'P6',
      'R1',
      'R2',
      'R3',
      'R4',
      'R5',
      'Y1',
      'Y2',
      'Y3',
      'Y4',
      'Y5',
      'Y6'
    ]
  },
  {
    name: 'SR Vivodent',
    colors: [
      'O1',
      '1A',
      '1C',
      '1D',
      '1E',
      '2A',
      '2B',
      '2C',
      '2E',
      '3A',
      '3C',
      '3E',
      '4A',
      '4B',
      '4C',
      '4D',
      '5B',
      '6B',
      '6C',
      '6D',
      '01'
    ]
  },
  {
      name: 'Kulzer Delara',
      colors: [
          'A1',
          'A2',
          'A3',
          'A3.5',
          'A4',
          'B1',
          'B2',
          'B3',
          'B4',
          'C1',
          'C2',
          'C3',
          'C4',
          'D2',
          'D3',
          'D4',
          'BL2',
          'BL3',
      ],
  },
];

export const DATE_FORMATS = {
  shortDate: 'L',
  shortTime: 'LT',
  short: 'L LT',
  medium: 'LL LTS',
  mediumDate: 'LL',
  longDate: 'LLL',
  fullDate: 'LLLL',
  mediumTime: 'LTS',
  DEFAULT: 'YYYY-MM-DD'
};

export const DEADLINES = [
  'No Deadline',
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00'
];

export const TRANSPORT_TIME = {
  time: '14',
  PICKUP: 11, //11 am
  DELIVERY: 20 // 8:00 pm
};

export const HOLIDAYS = [
  new Date('2022-01-01').setHours(0, 0, 0, 0),
  new Date('2022-01-06').setHours(0, 0, 0, 0),
  new Date('2022-01-07').setHours(0, 0, 0, 0),
  new Date('2022-04-15').setHours(0, 0, 0, 0),
  new Date('2022-04-17').setHours(0, 0, 0, 0),
  new Date('2022-04-18').setHours(0, 0, 0, 0),
  new Date('2022-05-01').setHours(0, 0, 0, 0),
  new Date('2022-05-26').setHours(0, 0, 0, 0),
  new Date('2022-06-05').setHours(0, 0, 0, 0),
  new Date('2022-06-06').setHours(0, 0, 0, 0),
  new Date('2022-06-24').setHours(0, 0, 0, 0),
  new Date('2022-06-25').setHours(0, 0, 0, 0),
  new Date('2022-11-05').setHours(0, 0, 0, 0),
  new Date('2022-12-24').setHours(0, 0, 0, 0),
  new Date('2022-12-25').setHours(0, 0, 0, 0),
  new Date('2022-12-26').setHours(0, 0, 0, 0),
  new Date('2022-12-31').setHours(0, 0, 0, 0),

  new Date('2023-01-01').setHours(0, 0, 0, 0),
  new Date('2023-01-06').setHours(0, 0, 0, 0),
  new Date('2023-04-07').setHours(0, 0, 0, 0),
  new Date('2023-04-09').setHours(0, 0, 0, 0),
  new Date('2023-04-10').setHours(0, 0, 0, 0),
  new Date('2023-05-01').setHours(0, 0, 0, 0),
  new Date('2023-05-18').setHours(0, 0, 0, 0),
  new Date('2023-05-28').setHours(0, 0, 0, 0),
  new Date('2023-06-06').setHours(0, 0, 0, 0),
  new Date('2023-06-24').setHours(0, 0, 0, 0),
  new Date('2023-11-04').setHours(0, 0, 0, 0),
  new Date('2023-12-25').setHours(0, 0, 0, 0),
  new Date('2023-12-26').setHours(0, 0, 0, 0),

  new Date('2024-01-01').setHours(0, 0, 0, 0)
];

export const DATE = {
  FORMAT: 'yyyy-MM-dd',
  FULL_DATE_TIME: 'yyyy-MM-dd HH:mm'
};

export const COMPONENTS = {
  USER_CARD: 'users-card.component',
  USER_LIST: 'users.component',
  EDIT_LAB: 'edit-lab.component'
};

export const CONSTANT = {
  FILE_SIZE: '800MB',
  LANGUAGES: [
    {
      name: 'Svenska',
      value: LanguageCode.SE,
      url: 'images/Sweden_flat.png'
    },
    {
      name: 'English',
      value: LanguageCode.EN,
      url: 'images/United-Kingdom_flat.png'
    }
  ],
  LOCALES: ['en-GB', 'en-US', 'sv-SE']
};

export const STATUS = {
  OK: 0,
  TIMEOUT: 1,
  IN_PROGRESS: 2,
  ERROR: -1,
  AUTH_FAILED: -2,
  PERM_DENIED: -3,
  NOT_FOUND: -4,
  DUPLICATE: -5,
  MISSING_PARAM: -6,
  INVALID_DATA: -7,
  ORGANIZATION_MIS_MATCH: -8,
  ZERO_PRICE: -9,
  ALREADY_SENT: -10,
  DUPLICATE_EMAIL: 409
};

export const DESIGNS = {
  TYPES: [
    {
      name: 'single crown',
      value: 'single_crown',
      active: false,
      translationValue: 'SINGLE_CROWN'
    },
    {
      name: 'bridge',
      value: 'bridge',
      active: false,
      translationValue: 'BRIDGE'
    },
    { name: 'post', value: 'post', active: false, translationValue: 'POST' },
    {
      name: 'implant crown',
      value: 'implant_crown',
      active: false,
      translationValue: 'IMPLANT_CROWN'
    },
    {
      name: 'implant bridge',
      value: 'implant_bridge',
      active: false,
      translationValue: 'IMPLANT_BRIDGE'
    },
    { name: 'other', value: 'other', active: true, translationValue: 'OTHER' }
  ],
  MATERIALS: [
    {
      name: 'E-Max',
      translationValue: 'E_MAX',
      isImplantBridge: false,
      isImplantCrown: false
    },
    {
      name: 'Empress',
      translationValue: 'EMPRESS',
      isImplantBridge: false,
      isImplantCrown: false
    },
    {
      name: 'Zirkonia',
      translationValue: 'ZIRKONIA',
      isImplantBridge: true,
      isImplantCrown: true
    },
    {
      name: 'Gold',
      translationValue: 'GOLD',
      isImplantBridge: false,
      isImplantCrown: false
    },
    {
      name: 'PFM-Gold',
      translationValue: 'PFM-GOLD',
      isImplantBridge: false,
      isImplantCrown: false
    },
    {
      name: 'PFM-Titan',
      translationValue: 'PFM-TITAN',
      isImplantBridge: true,
      isImplantCrown: true
    },
    {
      name: 'PFM-Cocr',
      translationValue: 'PFM-COCR',
      isImplantBridge: true,
      isImplantCrown: true
    },
    {
      name: 'Other',
      translationValue: 'OTHER',
      isImplantBridge: true,
      isImplantCrown: true
    }
    /*{name: 'Acrylic', translationValue: "ACRYLIC",isImplantBridge:false,isImplantCrown:false},
    {name: 'Titan', translationValue: "TITAN",isImplantBridge:false,isImplantCrown:false},
    {name: 'Composite', translationValue: "COMPOSITE",isImplantBridge:false,isImplantCrown:false},
    {name: 'Aluminum', translationValue: "ALUMINIUM",isImplantBridge:false,isImplantCrown:false},
    {name: 'Metal/Porcelain', translationValue: "METAL_PORCELAIN",isImplantBridge:true,isImplantCrown:false},
    {name: 'Metal/Acrylic', translationValue: "METAL_ACRYLIC",isImplantBridge:true,isImplantCrown:false},
    {name: 'MBP', translationValue: "MBP",isImplantBridge:false,isImplantCrown:true}*/
  ],
  METAL_MARGIN: [
    {
      name: 'No Metal Margin',
      translationValue: 'NO_METAL_MARGIN',
      imageUrl: 'images/no-Metal.png'
    },
    {
      name: 'Porcelain Margin',
      translationValue: 'PORCELAIN_MARGIN',
      imageUrl: 'images/Porcelain-Margin.png'
    },
    {
      name: 'Small Metal Margin',
      translationValue: 'SMALL_METAL_MARGIN',
      imageUrl: 'images/Small-metal.png'
    },
    {
      name: 'Lingual Metal Margin',
      translationValue: 'LINGUAL_METAL_MARGIN',
      imageUrl: 'images/Lingual-Metal.png'
    }
  ],
  OCCLUSION_TYPE: [
    { name: 'Hard', translationValue: 'HARD' },
    { name: 'Light', translationValue: 'LIGHT' },
    { name: 'None', translationValue: 'NONE' }
  ],
  CONTACT_TYPE: [
    { name: 'Hard', translationValue: 'HARD' },
    { name: 'Light', translationValue: 'LIGHT' },
    { name: 'None', translationValue: 'NONE' }
  ],
  PONTIC_DESIGN: [
    {
      name: 'Sanitary',
      translationValue: 'SANITARY',
      imageUrl: 'images/Sanitary.png'
    },
    {
      name: 'Bullet',
      translationValue: 'BULLET',
      imageUrl: 'images/Bullet.png'
    },
    {
      name: 'Mod. Ridge Lap',
      translationValue: 'MOD_RIDGE_LAP',
      imageUrl: 'images/Mod-ridge-lap.png'
    },
    {
      name: 'Full Ridge Lap',
      translationValue: 'FULL_RIDGE_LAP',
      imageUrl: 'images/Full-Ridge-Lap.png'
    },
    { name: 'Ovate', translationValue: 'OVATE', imageUrl: 'images/Ovate.png' }
  ],
  POST_MATERIALS: [
    { name: 'Titan', translationValue: 'TITAN' },
    { name: 'Porcelain', translationValue: 'PORCELAIN' },
    { name: 'Gold', translationValue: 'GOLD' },
    { name: 'Cobalt Chrome', translationValue: 'COBALT_CHROME' },
    { name: 'Other', translationValue: 'OTHER' }
  ]
};

export const REMOVABLE_DESIGNS = {
  TYPES: [
    {
      name: 'complete denture',
      value: 'complete_denture',
      active: false,
      translationValue: 'COMPLETE_DENTURE'
    },
    {
      name: 'partial denture',
      value: 'partial_denture',
      active: false,
      translationValue: 'PARTIAL_DENTURE'
    },
    {
      name: 'temporary denture',
      value: 'temporary_denture',
      active: false,
      translationValue: 'TEMPORARY_DENTURE'
    },
    {
      name: 'bitetray',
      value: 'bitetray',
      active: false,
      translationValue: 'BITETRAY'
    },
    {
      name: 'other removable',
      value: 'other_removable',
      active: true,
      translationValue: 'OTHER'
    }
  ],
  MATERIALS: [
    {
      name: 'Acrylic',
      translationValue: 'ACRYLIC',
      isPartial: false,
      isTemporary: true
    },
    {
      name: 'Valio',
      translationValue: 'VALIO',
      isPartial: false,
      isTemporary: true
    },
    {
      name: 'Soft',
      translationValue: 'SOFT',
      isPartial: false,
      isTemporary: false
    },
    {
      name: 'Hard',
      translationValue: 'HARD',
      isPartial: false,
      isTemporary: false
    },
    {
      name: 'CoCr',
      translationValue: 'COCR',
      isPartial: true,
      isTemporary: false
    },
    {
      name: 'Other',
      translationValue: 'OTHER',
      isPartial: true,
      isTemporary: true
    }
  ]
};

export const CONSTRUCTIONS = {
  COMPLETE_DENTURE: 'complete denture',
  PARTIAL_DENTURE: 'partial denture',
  TEMPORARY_DENTURE: 'temporary denture',
  BITETRAY: 'bitetray',
  SINGLE_CROWN: 'single crown',
  BRIDGE: 'bridge',
  IMPLANT_CROWN: 'implant crown',
  IMPLANT_BRIDGE: 'implant bridge',
  POST: 'post',
  OTHER: 'other',
  OTHER_REMOVABLE: 'other removable'
};

export const ORGANIZATION_DEFAULT_SETTINGS_OBJECT = {
  modules: {
    reports: false,
    order2: false,
    manage_actions: false
  }
};

export const DEFAULT_COLOR_CODE = {
  backgroundColorCode: '#ffffff',
  textColorCode: '#000000'
};

export const DEFAULT_SUBCATEGORY_COLOR_CODE = {
  subCategorybackgroundColorCode: '#dcdada'
};

export const PATIENT_CATEGORY = {
  ADULT1: 'adult1',
  ADULT2: 'adult2',
  CHILD: 'child'
};

export const ORDER_DETAIL_TYPE = {
  ORDER2: 'Order_2'
};

export const ROLE = {
  TECHNICIAN_ADMIN: 'TECHNICIAN_ADMIN',
  TECHNICIAN_USER: 'TECHNICIAN_USER',
  DENTIST_USER: 'DENTIST_USER',
  DENTIST_ADMIN: 'DENTIST_ADMIN'
};

export const ORDER_VERSION = {
  ORDER_1: 'Order_1',
  ORDER_2: 'Order_2'
};

export const ROLE_TYPE_CONSTANTS = ['DENTIST', 'TECHNICIAN', 'BOTH'];

export const CUSTOMER_SETTINGS_CONSTANTS = {
  TYPES: {
    STRING: 'String',
    NUMBER: 'Number',
    PASSWORD: 'Password',
    TEXT: 'Text',
    OBJECT: 'Object',
    BOOLEAN: 'Boolean',
    LIST: 'List'
  },
  ORGANIZATION_STATES: {
    CLINICS: 'clinics',
    LABS: 'labs'
  },
  ORDER_STATES: {
    NEW_ORDER: 'app.order-new',
    NEW_ORDER2: 'app.order-new-2'
  },
  ORDER_DETAIL_STATES: {
    ORDER_DETAIL: 'app.order-details',
    ORDER_DETAIL2: 'app.order-details-2'
  },
  STATE_MESSAGES: {
    CLINICS: 'BACK_TO_MANAGE_CLINICS',
    LABS: 'BACK_TO_MANAGE_LABS'
  },
  SETTINGS_NAMES: {
    CURRENCY: 'CURRENCY',
    ORDER_VERSION: 'ORDER_VERSION',
    DEFAULT_LOGIN_METHOD: 'DEFAULT_LOGIN_METHOD',
    EMAIL_EXT_USER_ASSIGNED_NOTIFICATION: 'EMAIL_EXT_USER_ASSIGNED_NOTIFICATION',
    API_USERNAME: 'API_USERNAME',
    API_PASSWORD: 'API_PASSWORD',
    DEFAULT_LANGUAGE: 'DEFAULT_LANGUAGE',
    CUSTOM_TRANSLATIONS: 'CUSTOM_TRANSLATIONS',
    PATIENT_CATEGORY: 'PATIENT_CATEGORY',
    PATIENT_CATEGORIES: 'PATIENT_CATEGORIES',
    CHOOSE_LAB_EXCEPTIONS: 'CHOOSE_LAB_EXCEPTIONS',
    CLASP_MATERIAL: 'CLASP_MATERIAL',
    ORTHODONTICS_COLORS: 'ORTHODONTICS_COLORS',
    FACIAL_LOOP: 'FACIAL_LOOP',
    CLASP_TYPES: 'CLASP_TYPES',
    SPRING_TYPES: 'SPRING_TYPES',
    METAL_DESIGN: 'METAL_DESIGN',
    TESTING_STEPS: 'TESTING_STEPS',
    PONTIC_DESIGN: 'PONTIC_DESIGN',
    OCCLUSION_TYPE: 'OCCLUSION_TYPE',
    CONTACT_TYPE: 'CONTACT_TYPE',
    INVOICING_MODULE: 'INVOICING_MODULE',
    ORDER_PREFILL: 'ORDER_PREFILL',
    ORDER_PREFILL_CLINIC_EXT_ID: 'ORDER_PREFILL_CLINIC_EXT_ID',
    RATING_REMARKS: 'RATING_REMARKS',
    APPROVED_STATUS: 'APPROVED_STATUS',
    IMPLANT_STEPS: 'IMPLANT_STEPS',
    DENTURE_STEPS: 'DENTURE_STEPS',
    EXPORT_PRODUCTION: 'EXPORT_PRODUCTION',
    REASON_FOR_EXCEPTION: 'REASON_FOR_EXCEPTION',
    RATING_COLUMN: 'RATING_COLUMN',
    RECENT_LAB_LIST: 'RECENT_LAB_LIST',
    PARTIAL_DENTURE_STEPS: 'PARTIAL_DENTURE_STEPS',
    SAMPLE_METHOD: 'SAMPLE_METHOD',
    SAMPLE_CONTENT: 'SAMPLE_CONTENT',
    AUTO_APPROVE: 'AUTO_APPROVE',
    MANDATORY_COMMENTS: 'MANDATORY_COMMENTS',
    WHITE_LABEL: 'WHITE_LABEL',
    PATIENT_GENDER: 'PATIENT_GENDER',
    SECRET_KEY: 'SECRET_KEY',
    COMPLETED_DRAFT: 'COMPLETED_DRAFT',
    DISALLOW_NON_DENTIST: 'DISALLOW_NON_DENTIST',
    CLINIC_RECEIVED_DELIVERY: 'CLINIC_RECEIVED_DELIVERY',
    EXTERNAL_LAB_USERS: 'EXTERNAL_LAB_USERS',
    LIMIT_INFO_EXTERNAL_USERS: 'LIMIT_INFO_EXTERNAL_USERS',
    IN_HOUSE_TRANSPORT: 'IN_HOUSE_TRANSPORT',
    INVOICING_TYPE: 'INVOICING_TYPE',
    INVOICING_DELIVERY: 'INVOICING_DELIVERY',
    LAB_WORKFLOW: 'LAB_WORKFLOW',
    THREE_SHAPE_INTEGRATION: 'THREE_SHAPE_INTEGRATION',
    MEDIT_INTEGRATION: 'MEDIT_INTEGRATION',
    SCAN_CASES: 'SCAN_CASES',
    CERTIFICATION: 'CERTIFICATION',
    USE_CALENDAR_DELIVERY_DAYS: 'USE_CALENDAR_DELIVERY_DAYS'
  },
  SAMPLE_METHODS: {
    PHYSICAL: 'PHYSICAL',
    DIGITAL: 'DIGITAL',
    NO_SAMPLE: 'NO_SAMPLE'
  },
  LANGUAGE_CODES: {
    ENGLISH: 'en',
    SWEDISH: 'se'
  },
  SETTINGS_VALUES: {
    SIMPLE: 'Simple',
    ACTION_BASED: 'Action-based'
  }
};

export const INVOICE_NUMBER_TYPES = {
  INCREMENTAL: 'INCREMENTAL'
};

export const INVOICE_DATE_FILTER = {
  OTHER: 'OTHER',
  '3_MONTH': '3_MONTH',
  '1_MONTH': '1_MONTH'
};

/* Order 2.0 constants */
export const ORDER_NEW_2_FIXED_CONSTRUCTIONS = {
  TYPES: [
    {
      name: 'single crown',
      value: 'single_crown',
      translationValue: 'SINGLE_CROWN'
    },
    { name: 'bridge', value: 'bridge', translationValue: 'BRIDGE' },
    { name: 'post', value: 'post', translationValue: 'POST' },
    {
      name: 'implant crown',
      value: 'implant_crown',
      translationValue: 'IMPLANT_CROWN'
    },
    {
      name: 'implant bridge',
      value: 'implant_bridge',
      translationValue: 'IMPLANT_BRIDGE'
    },
    { name: 'other', value: 'other', translationValue: 'OTHER' }
  ],
  MATERIALS: [
    {
      name: 'E-Max',
      translationValue: 'E_MAX',
      isImplantBridge: false,
      isImplantCrown: false
    },
    {
      name: 'Empress',
      translationValue: 'EMPRESS',
      isImplantBridge: false,
      isImplantCrown: false
    },
    {
      name: 'Zirkonia',
      translationValue: 'ZIRKONIA',
      isImplantBridge: true,
      isImplantCrown: true
    },
    {
      name: 'Gold',
      translationValue: 'GOLD',
      isImplantBridge: false,
      isImplantCrown: false
    },
    {
      name: 'PFM-Gold',
      translationValue: 'PFM-GOLD',
      isImplantBridge: false,
      isImplantCrown: false
    },
    {
      name: 'PFM-Titan',
      translationValue: 'PFM-TITAN',
      isImplantBridge: true,
      isImplantCrown: true
    },
    {
      name: 'PFM-Cocr',
      translationValue: 'PFM-COCR',
      isImplantBridge: true,
      isImplantCrown: true
    },
    {
      name: 'Other',
      translationValue: 'OTHER',
      isImplantBridge: true,
      isImplantCrown: true
    }
  ],
  METAL_MARGIN: [
    {
      name: 'No Metal Margin',
      translationValue: 'NO_METAL_MARGIN',
      imageUrl: 'images/no-Metal.png'
    },
    {
      name: 'Porcelain Margin',
      translationValue: 'PORCELAIN_MARGIN',
      imageUrl: 'images/Porcelain-Margin.png'
    },
    {
      name: 'Small Metal Margin',
      translationValue: 'SMALL_METAL_MARGIN',
      imageUrl: 'images/Small-metal.png'
    },
    {
      name: 'Lingual Metal Margin',
      translationValue: 'LINGUAL_METAL_MARGIN',
      imageUrl: 'images/Lingual-Metal.png'
    }
  ],
  OCCLUSION_TYPE: [
    { name: 'Hard', translationValue: 'HARD' },
    { name: 'Light', translationValue: 'LIGHT' },
    { name: 'None', translationValue: 'NONE' }
  ],
  CONTACT_TYPE: [
    { name: 'Hard', translationValue: 'HARD' },
    { name: 'Light', translationValue: 'LIGHT' },
    { name: 'None', translationValue: 'NONE' }
  ],
  PONTIC_DESIGN: [
    {
      name: 'Sanitary',
      translationValue: 'SANITARY',
      imageUrl: 'images/Sanitary.png'
    },
    {
      name: 'Bullet',
      translationValue: 'BULLET',
      imageUrl: 'images/Bullet.png'
    },
    {
      name: 'Mod. Ridge Lap',
      translationValue: 'MOD_RIDGE_LAP',
      imageUrl: 'images/Mod-ridge-lap.png'
    },
    {
      name: 'Full Ridge Lap',
      translationValue: 'FULL_RIDGE_LAP',
      imageUrl: 'images/Full-Ridge-Lap.png'
    },
    { name: 'Ovate', translationValue: 'OVATE', imageUrl: 'images/Ovate.png' }
  ],
  POST_MATERIALS: [
    { name: 'Titan', translationValue: 'TITAN' },
    { name: 'Porcelain', translationValue: 'PORCELAIN' },
    { name: 'Gold', translationValue: 'GOLD' },
    { name: 'Cobalt Chrome', translationValue: 'COBALT_CHROME' },
    { name: 'Other', translationValue: 'OTHER' }
  ]
};

export const ORDER_NEW_2_REMOVABLE_CONSTRUCTIONS = {
  TYPES: [
    {
      name: 'complete denture',
      value: 'complete_denture',
      translationValue: 'COMPLETE_DENTURE'
    },
    {
      name: 'partial denture',
      value: 'partial_denture',
      translationValue: 'PARTIAL_DENTURE'
    },
    {
      name: 'temporary denture',
      value: 'temporary_denture',
      translationValue: 'TEMPORARY_DENTURE'
    },
    { name: 'bitetray', value: 'bitetray', translationValue: 'BITETRAY' },
    {
      name: 'other removable',
      value: 'other_removable',
      translationValue: 'OTHER'
    }
  ],
  MATERIALS: [
    {
      name: 'Acrylic',
      translationValue: 'ACRYLIC',
      isPartial: false,
      isTemporary: true
    },
    {
      name: 'Valio',
      translationValue: 'VALIO',
      isPartial: false,
      isTemporary: true
    },
    {
      name: 'Soft',
      translationValue: 'SOFT',
      isPartial: false,
      isTemporary: false
    },
    {
      name: 'Hard',
      translationValue: 'HARD',
      isPartial: false,
      isTemporary: false
    },
    {
      name: 'CoCr',
      translationValue: 'COCR',
      isPartial: true,
      isTemporary: false
    },
    {
      name: 'Other',
      translationValue: 'OTHER',
      isPartial: true,
      isTemporary: true
    }
  ]
};

export const ORDER_NEW_2_REASON = {
  distance: 'DISTANCE',
  quality: 'QUALITY',
  'delivery-time': 'DELIVERY_TIME',
  'patient-preference': 'PATIENT_PREFERENCE',
  language: 'REASON_LANGUAGE'
};

export const ORDER_2_CONSTRUCTION = {
  TYPE: {
    SINGLE_TOOTH: 'Single tooth',
    RANGE: 'Range',
    UP_LOW_JAW: 'Up/low jaw'
  },
  RETAINMENT: ['Screwed', 'Cemented'],
  DESIGNS: [
    'No Metal Margin',
    'Porcelain Margin',
    'Small Metal Margin',
    'Lingual Metal Margin'
  ],
  PONTIC_DESIGNS: [
    'Sanitary',
    'Bullet',
    'Mod. Ridge Lap',
    'Full Ridge Lap',
    'Ovate'
  ],
  CONTACT_TYPE: ['Hard', 'Light', 'None'],
  ORTH_COLOR: ['Red', 'Blue', 'Green', 'Orange', 'Yellow', 'Purple', 'Pink'],
  CLASP_MATERIAL: ['Gold', 'Steel'],
  FACIAL_LOOP: ['Facial loop', 'Bass spring'],
  CLASP_TYPE: ['Adam', 'Ball', 'Arrow'],
  SPRING_TYPE: ['Adam', 'Expansion screw', 'Telescope screw']
};

export const RATE_ORDER_REASONS = {
  REASONS: [
    { name: 'estetics', translationValue: 'ESTETICS', value: false },
    { name: 'fitting', translationValue: 'FITTING', value: false },
    {
      name: 'serviceAndAvailability',
      translationValue: 'SERVICE_AND_AVAILABILITY',
      value: false
    },
    { name: 'occlusion', translationValue: 'OCCLUSION', value: false },
    {
      name: 'deviationFromDescription',
      translationValue: 'DEVIATION_FROM_DESCRIPTION',
      value: false
    },
    { name: 'deliveryTime', translationValue: 'DELIVERY_TIME', value: false },
    { name: 'other', translationValue: 'OTHER', value: false }
  ]
};

export const CLASP_TEETH = {
  TEETH: [
    { id: 11, label: '11', selected: false },
    { id: 12, label: '12', selected: false },
    { id: 13, label: '13', selected: false },
    { id: 14, label: '14', selected: false },
    { id: 15, label: '15', selected: false },
    { id: 16, label: '16', selected: false },
    { id: 17, label: '17', selected: false },
    { id: 18, label: '18', selected: false },
    { id: 21, label: '21', selected: false },
    { id: 22, label: '22', selected: false },
    { id: 23, label: '23', selected: false },
    { id: 24, label: '24', selected: false },
    { id: 25, label: '25', selected: false },
    { id: 26, label: '26', selected: false },
    { id: 27, label: '27', selected: false },
    { id: 28, label: '28', selected: false },
    { id: 31, label: '31', selected: false },
    { id: 32, label: '32', selected: false },
    { id: 33, label: '33', selected: false },
    { id: 34, label: '34', selected: false },
    { id: 35, label: '35', selected: false },
    { id: 36, label: '36', selected: false },
    { id: 37, label: '37', selected: false },
    { id: 38, label: '38', selected: false },
    { id: 41, label: '41', selected: false },
    { id: 42, label: '42', selected: false },
    { id: 43, label: '43', selected: false },
    { id: 44, label: '44', selected: false },
    { id: 45, label: '45', selected: false },
    { id: 46, label: '46', selected: false },
    { id: 47, label: '47', selected: false },
    { id: 48, label: '48', selected: false }
  ]
};

export const TOASTR_CONFIG = {
  autoDismiss: false,
  containerId: 'toast-container',
  maxOpened: 0,
  newestOnTop: true,
  positionClass: 'toast-bottom-right',
  preventDuplicates: false,
  preventOpenDuplicates: false,
  target: 'body'
};

export const ORDER_STATUSES = [
  {
    name: 'SENT_DENTIST',
    value: 'sent_by_dentist'
  },
  {
    name: 'RECEIVED_BY_LAB',
    value: 'accepted_by_lab'
  },
  {
    name: 'SENT_LAB',
    value: 'sent_by_lab'
  },
  {
    name: 'APPROVED_ORDER',
    value: 'order_approved'
  },
  {
    name: 'CANCELLED',
    value: 'cancelled'
  },
  {
    name: 'REJECTED',
    value: 'declined'
  }
];

export const GENDERS = ['MALE', 'FEMALE'];
