import { Component, Input, forwardRef, Inject } from '@angular/core';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../../../services/order-new/order-new.service';
import { GetAllOrdersService } from '../../../services/order-list/get-all-orders.service';
import { UnArchiveOrderService } from '../../../services/order-new/un-archive-order.service';
import { NewOrderListRedirectService } from '../../../services/new-order-list-redirect/new-order-list-redirect.service';
import { ORDER_VERSION } from '../../../app.constant';
import { Order } from '../../../models/order.model';
import template from './archive-order-modal.html';

@Component({
  selector: 'archive-order-modal',
  template: template
})
export class ArchiveOrderModalComponents {
  @Input() orderDetail: Order;

  constructor(
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private orderService: OrderService,
    private unArchiveOrderService: UnArchiveOrderService,
    private getAllOrdersService: GetAllOrdersService,
    private newOrderListRedirectService: NewOrderListRedirectService,
    private logService: LogService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  async onSucceeded(): Promise<void> {
    this.toastr.success(
      await this.translationsService.get('SUCCESSFULLY_UPDATED')
    );
    this.getAllOrdersService.clearData(); //to clear cached orders list data
    this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
    setTimeout(() => {
      this.newOrderListRedirectService.newOrderList();
    }, 500);
  }

  /**
   *  Archive order
   */
  archiveOrder(): Promise<void> {
    const updatedObject = this.orderDetail;
    updatedObject.sentMail = true;
    updatedObject.isArchive = true;
    const isSimpleOrder = this.orderDetail.type === ORDER_VERSION.ORDER_1;
    if (isSimpleOrder) {
      updatedObject.orderId = this.$state.params.orderId;
      return this.orderService
        .updateOrder(updatedObject)
        .then(() => {
          $('#archive-order').modal('hide');
          return this.onSucceeded();
        })
        .catch(async (err) => {
          this.logService.error(
            'archive-order-modal.component',
            'archiveOrder',
            (await this.translationsService.get('ERROR_IN_ARCHIVING_ORDER')) +
              ': ' +
              err
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_ARCHIVING_ORDER')
          );
        });
    } else {
      updatedObject.id = this.$state.params.orderId;
      return this.orderService
        .updateNewOrder(updatedObject)
        .then(() => {
          $('#archive-order').modal('hide');
          return this.onSucceeded();
        })
        .catch(async (err) => {
          this.logService.error(
            'archive-order-modal.component',
            'archiveOrder 1',
            (await this.translationsService.get('ERROR_IN_ARCHIVING_ORDER')) +
              ': ' +
              err
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_ARCHIVING_ORDER')
          );
        });
    }
  }

  /**
   *  Un Archive order
   */
  unArchiveOrder(): Promise<void> {
    return this.unArchiveOrderService
      .unArchiveOrder(this.$state.params.orderId, this.orderDetail)
      .then(async () => {
        $('#un-archive-order').modal('hide');
        return this.onSucceeded();
      })
      .catch(async (err) => {
        this.logService.error(
          'archive-order-modal.component',
          'unArchiveOrder',
          (await this.translationsService.get('ERROR_IN_ARCHIVING_ORDER')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_ARCHIVING_ORDER')
        );
      });
  }
}
