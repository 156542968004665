import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { CLASP_TEETH } from '../../../../../../../../app.constant';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './clasp-teeth.html';

@Component({
  selector: 'clasp-teeth',
  template
})
export class ClaspTeethComponent implements OnChanges {
  @Input() isMandatory: boolean;
  @Input() selectedClaspTeeth: { id: number }[] = [];
  showDropdown = false;
  teethList = cloneDeep(CLASP_TEETH.TEETH);

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedClaspTeeth?.currentValue?.length) {
      this.selectedClaspTeeth.forEach((selectedTooth) => {
        const teeth = this.teethList?.find((teeth) => teeth.id === selectedTooth.id);
        if (teeth) {
          teeth.selected = true;
        }
      });
    }
  }

  getTeethStr() {
    if (!this.selectedClaspTeeth) {
      return [];
    }
    return this.selectedClaspTeeth.map((teeth) => teeth.id);
  }

  teethSelected(event, teeth: { id: number; label: string; selected: boolean }) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.selectedClaspTeeth) {
      this.selectedClaspTeeth = [];
    }
    // If teeth is already selected
    if (teeth.selected) {
      teeth.selected = false;
      const index = this.selectedClaspTeeth.findIndex((t) => t.id === teeth.id);
      if (index >= 0) {
        this.selectedClaspTeeth.splice(index, 1);
      }
    } else {
      teeth.selected = true;
      this.selectedClaspTeeth.push({ id: teeth.id });
    }
    this.constructionSettingsService.setMultiSelectSetting(
      'claspTeeth',
      this.selectedClaspTeeth
    );
  }
}
