import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationsService } from '../../../../../../services/translations/translations.service';
import { LogService } from '../../../../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationService } from '../../../../../../services/clinic/organization.service';
import { Organization, ConnectedLab } from '../../../../../../models/organization.model';
import { NgxSpinnerService } from 'ngx-spinner';
import template from './three-shape-upload-modal.html';
import style from './three-shape-upload.less';

@Component({
  selector: 'three-shape-upload-modal',
  template: template,
  styles: [style]
})
export class ThreeShapeUploadModalComponent implements OnInit {
  threeShapeCases = [];
  readonly spinnerName = 'scan-spinner';

  constructor(
    private readonly toastr: ToastrService,
    private readonly translationsService: TranslationsService,
    private readonly spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.spinner.show(this.spinnerName);
    setTimeout(() => {
      this.threeShapeCases = [{
        date: '2021-04-21',
        time: '11:30',
        caseInfo: '3 scane, 2 builder',
        id: '4566ff-454jff-455jf-gdsfjf566'
      },{
        date: '2021-04-05',
        time: '14:33',
        caseInfo: '4 scan',
        id: '4566ff-454jff-455jf-gdsfjf566'
      },{
        date: '2021-03-27',
        time: '09:13',
        caseInfo: '2 scan, 1 bild',
        id: '4566ff-454jff-455jf-gdsfjf566'
      }];
      this.spinner.hide(this.spinnerName);
    }, 10000);
  }

  checkedScan() {
    console.log('threeShapeCases', this.threeShapeCases);
  }

  disableSaveButton() {

  }

  async threeShapeUpload() {
    this.toastr.show(
      `<i class='fa fa-cloud-upload' aria-hidden='true'></i> ${await this.translationsService.get('SHAPE_UPLOAD_INFO')}`,
      null,{
        enableHtml: true,
        closeButton: true,
        toastClass: 'ngx-toastr uploading-toastr',
        positionClass:'toast-top-right'
      });
  }

  closeModal() {
    $('#three-shape-upload-modal').modal('hide');
  }
}