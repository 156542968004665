(function () {
    'use strict';
    angular.module('app').factory('shadesColorService', shadesColorService);
    function shadesColorService() {
        var shades = [
            {
                "name": "Bioform",
                "colors": ["51", "52", "53", "54", "55", "56", "59", "62", "63", "64", "65", "66", "67", "68", "69", "70", "77", "81", "82", "83", "84", "85", "91", "92", "93", "94", "95", "96"]
            },
            {
                "name": "Vita Classic",
                "colors": ["A1", "A2", "A3", "A3.5", "A4", "B1", "B2", "B3", "B4", "C1", "C2", "C3", "C4", "D2", "D3", "D4", "M1", "M2", "M3"]
            },
            {
                "name": "Vita 3D Master",
                "colors": [
                    "1M1", "1M2", "2L1.5", "2L2.5", "2M1", "2M2", "2M3", "2R-1.5", "2R2.5", "3L1.5", "3L2.5", "3M1", "3M2",
                    "3M3", "3R1.5", "3R2.5", "4L1.5", "4L2.5", "4M1", "4M2", "4M3", "4R1.5", "4R2.5", "5M1", "5M2", "5M3"]
            },
            {
                "name": "Chromascope",
                "colors": ["110", "120", "130", "140", "210", "220", "230", "240", "310", "320", "330", "340", "410", "420",
                    "430", "440", "510", "520", "530", "540"]
            },
            {
                "name": "Myersons",
                "colors": ["C", "D", "E", "F", "G", "H", "I", "41", "58", "60", "62", "65", "69", "70", "71", "73", "74"]
            },
            {
                "name": "Verident",
                "colors": ["B1", "P1", "P2", "P3", "P4", "P5", "P6", "R1", "R2", "R3", "R4", "R5", "Y1", "Y2", "Y3", "Y4", "Y5", "Y6"]
            },
            {
                "name": "SR Vivodent",
                "colors": ["O1", "1A", "1C", "1D", "1E", "2A", "2B", "2C", "2E", "3A", "3C", "3E", "4A", "4B", "4C", "4D", "5B", "6B", "6C", "6D", "01"]
            },
            {
                name: 'Kulzer Delara',
                colors: [
                    'A1',
                    'A2',
                    'A3',
                    'A3.5',
                    'A4',
                    'B1',
                    'B2',
                    'B3',
                    'B4',
                    'C1',
                    'C2',
                    'C3',
                    'C4',
                    'D2',
                    'D3',
                    'D4',
                    'BL2',
                    'BL3',
                ],
            },
        ];

        return shades;
    }
})();

