
/**
 * Tooth Structure service
 */
(function () {
    'use strict';
    angular.module('app').service('toothStructureService', toothStructureService);
    toothStructureService.$inject = [ '$q' ];

    function toothStructureService($q) {
        return {
            checkIfAnyConstructionInRange: checkIfAnyConstructionInRange,
            checkIfClickInsideBridge: checkIfClickInsideBridge,
            checkIfInsideBridge: checkIfInsideBridge,
            isActiveSingleTeeth: isActiveSingleTeeth,
            isInMiddleOfSelectedBridge: isInMiddleOfSelectedBridge,
            isInMiddleOfAnyCreatedBridge: isInMiddleOfAnyCreatedBridge
        };

        function isConstructionInside(constructionObj,teethRow,startIndex,endIndex,constructionType, isBridge,isUpper) {
            var isInside = false;
            if (constructionObj && constructionObj[constructionType]) {
                var crowns = constructionObj[constructionType];
                for (var i = 0; i < crowns.length; i++) {
                    var crown = crowns[i];
                    if (isBridge) {
                        if (crown.teethRange.isUpper === isUpper) {
                            var startTooth = teethRow.indexOf(crown.teethRange.start);
                            var endTooth = teethRow.indexOf(crown.teethRange.end);
                            if ((startIndex <= startTooth && endIndex >= startTooth) || (startIndex <= endTooth && endIndex >= endTooth) || (startIndex >= startTooth && endIndex <= endTooth)) {
                                //it is in mid
                                isInside = true;
                            }
                        }
                    } else {
                        var singleCrownToothIndex = teethRow.indexOf(crown.teethNo);
                        if (singleCrownToothIndex !== -1 && startIndex <= singleCrownToothIndex && endIndex >= singleCrownToothIndex) {
                            //it is in mid
                            isInside = true;
                        }
                    }

                }
            }
            return isInside;
        }

        /**
         *Check if there is any sandwiched construction inside the range
         * @param start
         * @param end
         * @param isUpper
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param constructionObj
         * @param type
         * @param selectedConstructionType
         * @returns {boolean}
         */
        function checkIfAnyConstructionInRange(start, end, isUpper, abutmentTeethFirstRange, abutmentTeethSecondRange, constructionObj,type,selectedConstructionType) {
            if (selectedConstructionType === 'bitetray') {
                return false;
            } else {
                var startIndex, endIndex;
                var teethRow = (isUpper) ? abutmentTeethFirstRange : abutmentTeethSecondRange;
                startIndex = teethRow.indexOf(start);
                endIndex = teethRow.indexOf(end);
                //switch if end is before start
                if (startIndex !== -1 && endIndex !== -1 && startIndex > endIndex) {
                    var temp = startIndex;
                    startIndex = endIndex;
                    endIndex = temp;
                }

                return (type === 'fixed') ?
                    isFixedInside(constructionObj, teethRow, startIndex, endIndex, isUpper, selectedConstructionType) : isRemovableInside(constructionObj, teethRow, startIndex, endIndex, isUpper, selectedConstructionType);
            }
        }

        function isFixedInside(constructionObj, teethRow, startIndex, endIndex, isUpper, selectedConstructionType) {
            return (selectedConstructionType !== 'bitetray') ?
                (isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'singleCrowns', false, isUpper) ||
                    isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'implantCrowns', false, isUpper) ||
                    isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'others', false, isUpper) ||
                    isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'bridges', true, isUpper) ||
                    isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'implantBridges', true, isUpper))
                : false;
        }

        function isRemovableInside(constructionObj, teethRow, startIndex, endIndex, isUpper, selectedConstructionType) {
            return isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'partialDentures', false, isUpper) ||
                isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'temporaryDentures', false, isUpper) ||
                isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'otherRemovables', false, isUpper) ||
                isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'completeDentures', true, isUpper) ||
                (selectedConstructionType !== 'bridge' && selectedConstructionType !== 'implant bridge' && isConstructionInside(constructionObj, teethRow, startIndex, endIndex, 'bitetrays', true, isUpper));
        }

        /**
         * Check if particular is inside bridge, implant bridge, complete denture and bitetray
         * @param bridge
         * @param tooth
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param selectedTeethRange
         * @param teethRangeSelected
         * @returns {boolean}
         */
        function checkIfClickInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange, selectedTeethRange, teethRangeSelected) {
            if (checkIfInsideBridge (bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange)) {
                selectedTeethRange.start = bridge.teethRange.start;
                selectedTeethRange.end = bridge.teethRange.end;
                selectedTeethRange.isUpper = bridge.teethRange.isUpper;
                if (teethRangeSelected) {
                    teethRangeSelected(selectedTeethRange);
                }
                return true;
            } else {
                return false;
            }
        }

        /**
         * Check if particular is inside bridge/implant bridge
         * @param bridge
         * @param tooth
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @returns {boolean}
         */
        function checkIfInsideBridge(bridge, tooth, abutmentTeethFirstRange, abutmentTeethSecondRange) {
            if (bridge.teethRange) {
                var start, end, teethIndex;
                if (bridge.teethRange.isUpper) {
                    start = abutmentTeethFirstRange.indexOf(bridge.teethRange.start);
                    end = abutmentTeethFirstRange.indexOf(bridge.teethRange.end);
                    teethIndex = abutmentTeethFirstRange.indexOf(tooth);
                } else {
                    start = abutmentTeethSecondRange.indexOf(bridge.teethRange.start);
                    end = abutmentTeethSecondRange.indexOf(bridge.teethRange.end);
                    teethIndex = abutmentTeethSecondRange.indexOf(tooth);
                }
                return (start !== -1 && end !== -1 && start <= teethIndex && teethIndex <= end);
            }
        }

        /**
         * Check if there is a single teeth type construction on the given tooth
         * @param teeth
         * @param constructionObj
         * @param type
         * @returns {boolean}
         */
        function isActiveSingleTeeth(teeth, constructionObj,type) {
            //find if there is any active single crown
            var isCrown = false;
            if (constructionObj && constructionObj[type]) {
                constructionObj[type].forEach(function (crown) {
                    if (crown.teethNo === teeth) {
                        isCrown = true;
                    }
                });
            }
            return isCrown;
        }

        /**
         * Find if given tooth is in middle of the selected bridge
         * @param teeth
         * @param isUpperRow
         * @param selectedTeethRange
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @returns {boolean}
         */
        function isInMiddleOfSelectedBridge(teeth, isUpperRow, selectedTeethRange, abutmentTeethFirstRange, abutmentTeethSecondRange) {
            if (!selectedTeethRange.start || !selectedTeethRange.end) {
                return false;
            }
            var isMiddle = false;
            var start, end, teethIndex;
            if (isUpperRow) {
                start = abutmentTeethFirstRange.indexOf(selectedTeethRange.start);
                end = abutmentTeethFirstRange.indexOf(selectedTeethRange.end);
                teethIndex = abutmentTeethFirstRange.indexOf(teeth);
            } else {
                start = abutmentTeethSecondRange.indexOf(selectedTeethRange.start);
                end = abutmentTeethSecondRange.indexOf(selectedTeethRange.end);
                teethIndex = abutmentTeethSecondRange.indexOf(teeth);
            }
            //teeth index should be between start and end
            if (start < teethIndex && teethIndex < end) {
                isMiddle = true;
            }
            return isMiddle;
        }

        /**
         * Check if the tooth is in start or end or in middle of bridge
         * we return the class bases on condition null for outside,
         * if it is at start then we return bridge-selected-start or if it is at end then we return bridge-selected-end
         * @param teeth
         * @param selectedConstructionObj
         * @param abutmentTeethFirstRange
         * @param abutmentTeethSecondRange
         * @param type
         * @returns {string}
         */
        function isInMiddleOfAnyCreatedBridge(teeth, selectedConstructionObj, abutmentTeethFirstRange, abutmentTeethSecondRange,type) {
            var selectedClass = '';
            if (selectedConstructionObj && selectedConstructionObj[type]) {
                selectedConstructionObj[type].forEach(function (bridge) {
                    if (bridge.teethRange) {
                        var start, end, teethIndex;
                        if (bridge.teethRange.isUpper) {
                            start = abutmentTeethFirstRange.indexOf(bridge.teethRange.start);
                            end = abutmentTeethFirstRange.indexOf(bridge.teethRange.end);
                            teethIndex = abutmentTeethFirstRange.indexOf(teeth);
                        } else {
                            start = abutmentTeethSecondRange.indexOf(bridge.teethRange.start);
                            end = abutmentTeethSecondRange.indexOf(bridge.teethRange.end);
                            teethIndex = abutmentTeethSecondRange.indexOf(teeth);
                        }
                        if (start < teethIndex && teethIndex < end) {
                            selectedClass = 'bridge-selected';
                        } else if (start === teethIndex && teethIndex < end) {
                            selectedClass = 'bridge-selected-start';
                        } else if (start < teethIndex && teethIndex === end) {
                            selectedClass = 'bridge-selected-end';
                        }
                    }
                });
            }

            return selectedClass;
        }

    }
})
();