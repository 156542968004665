import { Input, forwardRef, Inject, Component, OnInit } from '@angular/core';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { CacheService } from '../../services/core/cache.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { PermissionService } from '../../services/core/permission.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { EventService } from '../../services/core/event.service';
import { UsersService } from '../../services/users/users.service';
import { User } from '../../models/user.model';
import { SessionService } from '../../services/core/session.service';
import { Organization } from '../../models/organization.model';
import template from './denthub-header.html';
import { UsersGraphqlService } from '../../graphql-services/users/users.graphql.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { NewOrderListRedirectService } from '../../services/new-order-list-redirect/new-order-list-redirect.service';

@Component({
  selector: 'denthub-header',
  template: template
})
export class DenthubHeaderComponent implements OnInit {
  @Input() currentUser: User;
  showBackButton: boolean = false;
  isLoginAsAnotherUser: boolean = false;
  lab: Organization;
  newOrderListUrl: string;

  constructor(
    private permissionService: PermissionService,
    private currentUserService: CurrentUserService,
    private eventService: EventService,
    private usersService: UsersService,
    public cacheService: CacheService,
    private translationsService: TranslationsService,
    private logService: LogService,
    private toastr: ToastrService,
    private usersGraphqlService: UsersGraphqlService,
    private readonly sessionService: SessionService,
    private readonly organizationService: OrganizationService,
    private readonly newOrderListRedirectService: NewOrderListRedirectService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.newOrderListUrl = this.newOrderListRedirectService.newOrderListUrl();

    // this broadcast we get when user login as another user.
    this.eventService.on('user', () => {
      this.isLoginAsAnotherUser = true;
      this.showBackButton = true;
    });

    this.usersGraphqlService.connectedToApp().subscribe(() => {
      console.log('This will never be executed.');
    });

    //For white label lab limited dentist
    if (
      this.currentUser?.organization?.isWhiteLabeled &&
      this.currentUser?.organization?.connectedLabs?.length
    ) {
      this.getLabLogoImage();
      this.renderColorStyles();
    }
  }
  isExternalTechnician(): boolean {
    return this.permissionService.isExternalTechnician(this.currentUser);
  }

  renderColorStyles() {
    const lab = this.currentUser.organization.connectedLabs[0].lab;
    if (lab) {
      const css = `
      .wl-text {color: ${lab.textCode} !important;}
      .wl-background {background: ${lab.hexCode} !important;}
      .wl-search-icon {color: #111 !important;}
      .wl-search-input {color: #111 !important; background: white !important;}
      .wl-search-input::placeholder {color: #444 !important;}
      .wl-search-container {background: white; border-radius: 5px; border: 1px ${lab.textCode} solid;}`;
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
    }
  }

  getUserType(): string {
    let type = '';
    if (this.currentUser) {
      if (this.permissionService.isSuperAdmin(this.currentUser)) {
        type = 'SUPER_ADMIN';
      } else if (this.currentUser.permissions) {
        type = this.currentUser.permissions.role;
      }
    }
    return type;
  }

  //For white label lab limited dentist
  getLabLogoImage() {
    const labId = localStorage.getItem('wl_lab');
    if (labId) {
      this.organizationService
        .getLabLogo({
          labId: labId
        })
        .then((result) => {
          this.lab = result.data;
        })
        .catch((err) => {
          this.logService.error('getLabLogoImage', 'ERROR_IN_GETTING_LABS', err);
        });
    }
  }

  reloadPage() {
    window.location.reload();
  }

  goToState(): void {
    if (!this.permissionService.isSuperAdmin(this.currentUser)) {
      this.showBackButton = true;
      this.newOrderListRedirectService.newOrderList();
    } else {
      this.$state.go('app.users');
    }
  }

  redirectToNewOrderList() {
    this.newOrderListRedirectService.newOrderList();
  }

  /**
   * If user is authenticated then get the user payload.
   * this function change user to selected user.
   */
  populateCurrentUser(): void {
    this.currentUserService
      .getUserData()
      .then((result) => {
        if (result) {
          this.currentUser = result;
          if (this.isLoginAsAnotherUser) {
            this.goToState();
          }
        } else {
          this.currentUserService.logout();
        }
      })
      .catch((err) => {
        this.currentUserService.logout();
        this.logService.error('header.component', 'currentUser', err);
      });
  }

  /**
   * Go back to super admin user.
   */
  backToSuperAdmin(): Promise<void> {
    return this.usersService
      .loginAsSuperAdmin()
      .then((result) => {
        if (result && result.user && result.token) {
          this.currentUserService.setUserAndTokenForSuperAdmin(
            result.token,
            result.user
          );
          this.showBackButton = false;
          this.isLoginAsAnotherUser = true;
          this.populateCurrentUser();
        }
      })
      .catch(async (err) => {
        this.logService.error(
          'header.component',
          'backToSuperAdmin',
          (await this.translationsService.get('ERROR_IN_LOGIN_AS_SUPER_ADMIN')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_LOGIN_AS_SUPER_ADMIN')
        );
      });
  }

  isSuperAdmin(user: User): boolean {
    return this.permissionService.isSuperAdmin(user);
  }

  goToAppState(state: string): void {
    const OrderState = this.sessionService.getItem('OrderState');
    if (OrderState === 'app.order-status-view') {
      this.$state.go('app.order-status-view');
      return;
    }
    this.$state.go(state);
  }

  goToUserSetting(state: string): void {
    this.$state.go(state);
  }

  userLogout(): void {
    const labId = localStorage.getItem('wl_lab');
    if (
      this.currentUser?.organization?.isWhiteLabeled &&
      this.currentUser?.organization?.connectedLabs?.length
    ) {
      this.currentUserService.logout(null, null, null, {
        labId: labId
      });
    } else {
      this.currentUserService.logout();
    }
  }
}
