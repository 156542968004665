import { Input, Output, Component, EventEmitter } from '@angular/core';
import { MaterialGroups, Material } from '../../../models/material-group.model';
import { MaterialService } from '../../../services/manage-actions/material-group.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import template from './material-group-modal.html';

@Component({
  selector: 'material-group-modal',
  template: template
})
export class MaterialGroupModalComponent {
  @Output() getAllMaterialGroups: EventEmitter<void> = new EventEmitter<void>();
  @Input() materialGroup: Partial<MaterialGroups>;
  @Input() groupMaterials: Material[];
  material: Material = {};

  constructor(
    private materialService: MaterialService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private logService: LogService
  ) {}

  // Close material group modal
  closeMaterialGroupModal(): void {
    this.materialGroup = {};
    this.groupMaterials = [];
    this.material = {};
    $('#material-group-modal').modal('hide');
  }

  // Add and update material group
  saveUpdateMaterialGroup(): Promise<void> {
    this.groupMaterials = this.groupMaterials.filter((item) => {
      return item.material !== '';
    });
    this.materialGroup.materials = this.groupMaterials;
    if (this.materialGroup._id) {
      return this.materialService
        .updateMaterialGroup(this.materialGroup)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('MATERIAL_UPDATED_SUCCESSFULLY')
          );
          this.refreshMaterialGroups();
        })
        .catch(async (err) => {
          const text = await this.translationsService.get(
            'ERROR_IN_UPDATING_MATERIAL'
          );
          this.logService.error(
            'material-group-modal.component',
            'saveUpdateMaterialGroup (updateMaterialGroup)',
            text + ': ' + err
          );
          this.toastr.error(text);
        });
    } else {
      this.groupMaterials[0] = this.material;
      return this.materialService
        .saveMaterialGroup(this.materialGroup)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('MATERIAL_CREATED_SUCCESSFULLY')
          );
          this.refreshMaterialGroups();
        })
        .catch(async (err) => {
          const text = await this.translationsService.get(
            'ERROR_IN_CREATING_MATERIAL'
          );
          this.logService.error(
            'material-group-modal.component',
            'saveUpdateMaterialGroup (saveMaterialGroup)',
            text + ': ' + err
          );
          this.toastr.error(text);
        });
    }
  }

  // Delete material group
  deleteMaterialGroup(): Promise<void> {
    return this.materialService
      .deleteMaterialGroup(this.materialGroup)
      .then(async () => {
        this.toastr.success(
          await this.translationsService.get('MATERIAL_DELETED_SUCCESSFULLY')
        );
        this.refreshMaterialGroups();
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_DELETING_MATERIAL'
        );
        this.logService.error(
          'material-group-modal.component',
          'deleteMaterialGroup',
          text + ': ' + err
        );
        this.toastr.error(text);
      });
  }

  addMaterial(): void {
    if (this.material && this.material.material) {
      this.groupMaterials[0] = this.material;
    }
    this.groupMaterials.push({});
  }

  deleteMaterial(index: number): void {
    this.groupMaterials.splice(index, 1);
  }

  refreshMaterialGroups(): void {
    this.closeMaterialGroupModal();
    this.getAllMaterialGroups.emit();
  }
}
