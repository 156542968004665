/* start: imports-loader */
import '../../../app.upgrade';
import '../../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-details-2-constructions.html'
/**
 * Order Details Component for the order details of any order.
 */
(function () {
  'use strict';
  angular.module('app').component('orderDetails2Constructions', {
    template: theTemplate,
    controller: orderDetails2ConstructionsController,
    controllerAs: 'vm',
    bindings: {
      orderDetail: '=',
      constructions: '=',
      constructionNames: '=',
      simpleConstructions: '=',
      histroy: '=',
      removedConstructions: '=',
      removedSimpleConstructions: '=',
      getClass: '=',
      isVisibleInPrint: '=',
      showPrice: '=',
      currentUser: '=',
      getOrderDetails: '=',
      addedActions: '=',
      actionQuantity: '=',
      showTeethConstructions: '=',
      showRemovedTeethConstructions: '=',
      checkedActions: '=',
      totalNoOfConstructions: '=',
      showSimpleTypeConstructions: '=',
      showRemovedSimpleTypeConstructions: '=',
      selectedOrderVersion: '<',
      currency: '<',
      isExternalTechnician: '<'
    }
  });

  // Order Details controller Dependencies should be inject here.
  orderDetails2ConstructionsController.$inject = [
    'permissionService',
    '$scope',
    'orderService',
    'toastr',
    '$filter',
    'CUSTOMER_SETTINGS_CONSTANTS',
    'organizationSettingService',
    'logService'
  ];

  // Controller Function for the order details component
  function orderDetails2ConstructionsController(
    permissionService,
    $scope,
    orderService,
    toastr,
    $filter,
    CUSTOMER_SETTINGS_CONSTANTS,
    organizationSettingService,
    logService
  ) {
    var vm = this;

    vm.material = {};
    vm.action = {};
    vm.showOptionMaterialAction = [];
    vm.showAddMaterialAction = [];
    vm.showDeleteButton = [];
    vm.checkedActions = [];
    vm.freeText = [];
    vm.constructionCount = 0;

    //characteristics settings name according customer settings for translation
    vm.orthoSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORTHODONTICS_COLORS;
    vm.claspMaterialSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_MATERIAL;
    vm.facialLoopSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.FACIAL_LOOP;
    vm.claspTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CLASP_TYPES;
    vm.springTypeSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SPRING_TYPES;
    vm.occlusionsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.OCCLUSION_TYPE;
    vm.contactTypesSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CONTACT_TYPE;
    vm.ponticDesignsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PONTIC_DESIGN;
    vm.metalDesignsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.METAL_DESIGN;
    vm.testingStepsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.TESTING_STEPS;
    vm.implantStepsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.IMPLANT_STEPS;
    vm.dentureStepsSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.DENTURE_STEPS;

    //characteristics settings value from customer settings
    vm.dentureSteps = organizationSettingService.getCustomerSetting(
      vm.dentureStepsSettingName
    );
    vm.implantSteps = organizationSettingService.getCustomerSetting(
      vm.implantStepsSettingName
    );
    vm.testingSteps = organizationSettingService.getCustomerSetting(
      vm.testingStepsSettingName
    );

    vm.getTranslationKeyConstructionName = getTranslationKeyConstructionName;
    vm.getStyle = getStyle;
    vm.showHideDetails = showHideDetails;
    vm.showHideSimpleOrderDetails = showHideSimpleOrderDetails;
    vm.addNewRow = addNewRow;
    vm.savePrice = savePrice;
    vm.editPrice = editPrice;
    vm.removeActionMaterial = removeActionMaterial;
    vm.isLabTechnician = isLabTechnician;
    vm.isDentist = isDentist;
    vm.getConstructionCount = getConstructionCount;
    vm.assignMaterialsAndActionsData = assignMaterialsAndActionsData;
    vm.hideAddedMaterialActions = hideAddedMaterialActions;
    vm.showHideAddedMaterialActionsHeader = showHideAddedMaterialActionsHeader;
    vm.saveDisable = saveDisable;
    vm.showDeleteAddedActionBtn = showDeleteAddedActionBtn;
    vm.actionQuantityChanged = actionQuantityChanged;
    vm.checkVatValidate = checkVatValidate;
    vm.labOrderMinReqTeethForUpperJaw = { '17': true, '16': true, '15': true, '14': true, '13': true, '12': true, '11': true, '21': true, '22': true, '23': true, '24': true, '25': true, '26': true, '27': true };
    vm.labOrderMinReqTeethForLowerJaw = { '47': true, '46': true, '45': true, '44': true, '43': true, '42': true, '41': true, '31': true, '32': true, '33': true, '34': true, '35': true, '36': true, '37': true };
    vm.upperJawTeeth = Object.assign({}, vm.labOrderMinReqTeethForUpperJaw, { '18': true, 28: true });
    vm.lowerJawTeeth = Object.assign({}, vm.labOrderMinReqTeethForLowerJaw, { '38': true, 48: true });
    vm.openAddMaterialDialog = openAddMaterialDialog;
    vm.openEditMaterialDialog = openEditMaterialDialog;
    vm.openConfirmDeleteMaterialDialog = openConfirmDeleteMaterialDialog;
    vm.openAddActionDialog = openAddActionDialog;
    vm.openEditActionDialog = openEditActionDialog;
    vm.openConfirmDeleteActionDialog = openConfirmDeleteActionDialog;

    $scope.$watch('vm.constructions', function () {
      vm.constructions && vm.constructions.forEach((construction) => {
        construction.teethList = [];
        if (construction.teethStructure) {
          let selectedUpperJawTeeth = [], selectedLowerJawTeeth = [];
          Object.entries(construction.teethStructure)
            .forEach((elem) => {
              const [key] = elem;
              if (vm.upperJawTeeth[key]) {
                selectedUpperJawTeeth.push(elem);
              } else if (vm.lowerJawTeeth[key]) {
                selectedLowerJawTeeth.push(elem);
              }
            })
          selectedUpperJawTeeth = Object.fromEntries(selectedUpperJawTeeth);
          selectedLowerJawTeeth = Object.fromEntries(selectedLowerJawTeeth);
          construction.isUpperJawSelected = Object.keys(vm.labOrderMinReqTeethForUpperJaw).every((teeth) => {
            return selectedUpperJawTeeth[teeth];
          });
          construction.isLowerJawSelected = Object.keys(vm.labOrderMinReqTeethForLowerJaw).every((teeth) => {
            return selectedLowerJawTeeth[teeth];
          });
          if (construction.isLowerJawSelected && !construction.isUpperJawSelected) {
            construction.teethList = Object.keys(selectedUpperJawTeeth);
          } else if (construction.isUpperJawSelected && !construction.isLowerJawSelected) {
            construction.teethList = Object.keys(selectedLowerJawTeeth);
          } else if (construction.isUpperJawSelected && construction.isLowerJawSelected) {
            construction.teethList = [];
          } else {
            construction.teethList = Object.keys(construction.teethStructure);
          }
        }
      });
    });

    function getConstructionCount() {
      return ++vm.constructionCount;
    }

    function isLabTechnician() {
      return permissionService.isLabTechnician(vm.currentUser);
    }

    function isDentist() {
      return permissionService.isDentist(vm.currentUser);
    }

    function getTranslationKeyConstructionName(type) {
      if (type) {
        var constructionName = type.split(' ').join('_');
        return constructionName.toUpperCase();
      }
    }

    function getConstructionIndex(constructionId) {
      return vm.constructions.findIndex(function (construc) {
        return construc._id === constructionId;
      });
    }

    function editPrice(constructionId) {
      var constructionIndex = getConstructionIndex(constructionId);
      updateStatusToSaved(constructionIndex, 'addedMaterials', true);
      updateActionStatusToSaved(constructionIndex, true);
      vm.showSaveButton = true;
      vm.disableSaveButton = true;
    }

    function getSelectedActions(constructionId, constructionIndex) {
      if (vm.constructions) {
        var construction = vm.constructions[constructionIndex];
        var newAddedActions = Object.keys(vm.checkedActions[constructionId]).reduce(
          function (res, actionId) {
            if (!vm.checkedActions[constructionId][actionId]) {
              return res;
            }
            var action = construction.actions.find(function (action) {
              return action._id === actionId;
            });
            if (!action) {
              return res;
            }
            res.push({
              actionId: actionId,
              vat: action.vat,
              header: action.settings.numberOfUnits.header,
              numberOfUnits: action.numberOfUnits,
              price: getPrice(actionId),
              notSaved: true
            });
            return res;
          },
          []
        );
        vm.addedActions[constructionIndex] = vm.addedActions[
          constructionIndex
        ].concat(newAddedActions);
        vm.addedActions[constructionIndex] = _.uniqBy(
          vm.addedActions[constructionIndex],
          'header'
        );
      }
    }

    function showDeleteAddedActionBtn(construction, actionId) {
      if (construction && actionId) {
        var action = construction.actions.find(function (action) {
          return action._id === actionId;
        });

        if (action) {
          return action.settings.numberOfUnits.mandatory;
        }
        return true;
      }
    }

    function removeActionMaterial(
      actionOrMaterials,
      constructionIndex,
      index,
      actionId
    ) {
      vm.disableSaveButton = false;
      var constructionId = vm.constructions[constructionIndex]._id;
      if (actionId) {
        vm.checkedActions[constructionId][actionId] = false;
      }
      vm.showSaveButton = true;
      actionOrMaterials.splice(index, 1);
    }

    function addNewRow(index, constructionId) {
      if (vm.constructions) {
        var constructionIndex = getConstructionIndex(constructionId);
        if (vm.freeText[index]) {
          let addedMaterials = vm.constructions[constructionIndex].addedMaterials;
          if (!addedMaterials) {
            addedMaterials = [];
          }
          addedMaterials.push({
            material: '',
            quantity: null,
            vat: null,
            price: null,
            notSaved: true
          });
          vm.freeText[index] = false;
        }
        getSelectedActions(constructionId, constructionIndex);
        vm.showOptionMaterialAction[index] = false;
        vm.showSaveButton = true;
        vm.disableSaveButton = true;
      }
    }

    function updateStatusToSaved(constructionIndex, key, status) {
      vm.constructions[constructionIndex][key].forEach(function (material) {
        material.notSaved = status;
      });
    }

    function updateActionStatusToSaved(constructionIndex, status) {
      vm.addedActions[constructionIndex].forEach(function (action) {
        action.notSaved = status;
      });
    }

    function getUnit(action, construction) {
      var quantity = 1;
      if (
        action.costPerTooth &&
        construction.teethDetails.toothNumber &&
        construction.teethDetails.toothNumber.length
      ) {
        quantity = construction.teethDetails.toothNumber.length;
      } else if (
        action.costPerTooth &&
        construction.teethDetails.teethRange &&
        construction.teethDetails.teethRange.start === 18 &&
        construction.teethDetails.teethRange.end === 38
      ) {
        quantity = 2;
      }
      var unit = {
        action: action._id,
        quantity: quantity,
        vat: action.vat,
        actionObj: {
          number: action.number,
          name: action.name
        }
      };
      var spec = vm.orderDetail.priceSpecification.find(function (priceSpec) {
        return priceSpec.action === action._id && !priceSpec.priceDiff;
      });
      if (spec) {
        unit.price = spec.price;
      }
      return unit;
    }

    function savePrice(constructionId) {
      if (vm.constructions && vm.orderDetail) {
        var constructionIndex = getConstructionIndex(constructionId);
        vm.constructions[constructionIndex].numberOfUnits =
          vm.addedActions[constructionIndex];
        updateStatusToSaved(constructionIndex, 'addedMaterials', false);
        updateActionStatusToSaved(constructionIndex, false);
        vm.showSaveButton = false;
        vm.orderDetail.newConstructions.constructions = vm.constructions;
        var priceDiff = vm.orderDetail.priceSpecification.filter(function (spec) {
          return spec.priceDiff;
        });

        var priceSpec = [];
        var units;
        vm.orderDetail.newConstructions.constructions.forEach(function (
          construction
        ) {
          //calculate priceSpec when construction don't have numberOfUnits
          if (!construction.numberOfUnits || !construction.numberOfUnits.length) {
            units = construction.actions.map(function (action) {
              return getUnit(action, construction);
            });
          } else {
            //calculate priceSpec when one construction have two actions and one action have numberOfUnits and another don't
            units = construction.actions.map(function (action) {
              var unit = construction.numberOfUnits.find(function (unit) {
                return unit.actionId === action._id;
              });
              if (unit) {
                //convert numberOfUnits value to integer if action have isInteger true in their settings
                if (
                  action.settings.numberOfUnits &&
                  action.settings.numberOfUnits.isInteger
                ) {
                  unit.numberOfUnits = parseInt(unit.numberOfUnits);
                }
                unit.quantity = unit.numberOfUnits;
                unit.action = action._id;
                unit.vat = action.vat;
                unit.actionObj = {
                  number: action.number,
                  name: action.name
                };
                // update priceSpecification vat in numberOfUnits
                if (vm.orderDetail.priceSpecification.length) {
                  var spec = vm.orderDetail.priceSpecification.find(function (
                    priceSpecRow
                  ) {
                    return (
                      priceSpecRow.action === unit.actionId &&
                      !priceSpecRow.priceDiff
                    );
                  });
                  if (spec) {
                    unit.price = spec.price;
                  }
                }
                var diff = priceDiff.find(function (diff) {
                  return diff.action === unit.actionId;
                });
                if (diff) {
                  diff.quantity = unit.numberOfUnits;
                }
                return unit;
              } else {
                return getUnit(action, construction);
              }
            });
          }

          priceSpec = priceSpec.concat(units);

          construction.addedMaterials = construction.addedMaterials.filter(function (
            material
          ) {
            return (
              material.material &&
              (material.price || material.price === 0) &&
              material.quantity
            );
          });
          construction.addedMaterials.forEach(function (addedMaterial) {
            if (addedMaterial && addedMaterial.vat) {
              addedMaterial.vat = addedMaterial.vat.toString();
              addedMaterial.vat =
                addedMaterial.vat.indexOf(',') >= 0
                  ? addedMaterial.vat.replace(',', '.')
                  : addedMaterial.vat;
            } else {
              addedMaterial.vat = 0;
            }
          });
          // added priceSpecification materials details
          priceSpec = priceSpec.concat(construction.addedMaterials);
        });
        priceSpec = priceSpec.concat(priceDiff);
        if (hasValidTotalPrice(priceSpec)) {
          vm.orderDetail.priceSpecification = priceSpec;
          return orderService
            .updateNewOrder(vm.orderDetail)
            .then(function (order) {
              toastr.success($filter('translate')('ORDER_UPDATED'));
              vm.getOrderDetails();
            })
            .catch(function (err) {
              logService.error(
                'order-details-2-constructions.component',
                'savePrice',
                $filter('translate')('ERROR_IN_UPDATING_ORDER') + ': ' + err
              );
              toastr.error($filter('translate')('ERROR_IN_UPDATING_ORDER'));
            });
        } else {
          toastr.error($filter('translate')('ORDER_PRICE_NEGATIVE'));
          vm.getOrderDetails();
        }
      }
    }

    function hasValidTotalPrice(priceSpec) {
      let total = 0;
      priceSpec.forEach((line) => {
        const amount = line.price * line.quantity;
        total += amount;
      });
      if (total <= 0) {
        return false;
      }
      return true;
    }

    /**
     * Prevent alphabetic field and allow comma(,) and dot(.) to enter in vat field
     */
    function checkVatValidate(parentIndex, index) {
      if (
        vm.constructions[parentIndex] &&
        vm.constructions[parentIndex].addedMaterials &&
        vm.constructions[parentIndex].addedMaterials[index]
      ) {
        var reg = new RegExp('^[0-9]*([0-9,.]{2,3})?$');
        if (!reg.test(vm.constructions[parentIndex].addedMaterials[index].vat)) {
          vm.constructions[parentIndex].addedMaterials[index].vat = vm.constructions[
            parentIndex
          ].addedMaterials[index].vat.substr(
            0,
            vm.constructions[parentIndex].addedMaterials[index].vat.length - 1
          );
        }
      }
    }

    function showHideDetails(variable, index) {
      variable[index] = !variable[index];
    }

    function showHideSimpleOrderDetails(variable, keyIndex, constructIndex) {
      variable[keyIndex][constructIndex] = !variable[keyIndex][constructIndex];
    }

    function getStyle(construction) {
      return {
        'border-bottom':
          construction &&
            construction.subCategory &&
            construction.subCategory.colorCode
            ? '2px solid ' + construction.subCategory.colorCode
            : '2px solid #000000'
      };
    }

    //Get price of saved action in order that had no. of units
    function getPrice(actionId) {
      var actions = vm.constructions.reduce(function (result, construction) {
        return result.concat(construction.actions);
      }, []);

      var action = actions.find(function (action) {
        return action._id === actionId;
      });

      //get lab from clinic connected labs.
      var lab = vm.orderDetail.clinic.connectedLabs.find(function (lab) {
        if (lab.lab._id) {
          return lab.lab._id === vm.orderDetail.lab._id;
        }
        return lab.lab === vm.orderDetail.lab._id;
      });

      //get all actions from lab priceList array.
      var priceActions = lab.priceList.reduce(function (result, priceList) {
        return result.concat(priceList.actions);
      }, []);

      //get price from matched lab priceList action price.
      var price = priceActions.find(function (priceAction) {
        return action && action._id === priceAction.action;
      });
      return price ? price.price : null;
    }

    //Assign value in actions and materials from event to solving slow input field problem
    function assignMaterialsAndActionsData(type, event, parentIndex, index) {
      if (event && event.target.value && type === 'addedMaterials') {
        vm.constructions[parentIndex].addedMaterials[index].quantity =
          event.target.value;
      }
      if (event && event.target.value && type === 'addedMaterialsPrice') {
        vm.constructions[parentIndex].addedMaterials[index].price =
          event.target.value;
      }
      if (event && event.target.value && type === 'addedMaterialsName') {
        vm.constructions[parentIndex].addedMaterials[index].material =
          event.target.value;
      }
      if (event && event.target.value && type === 'addedMaterialsVat') {
        vm.constructions[parentIndex].addedMaterials[index].vat = event.target.value;
      }
    }

    function actionQuantityChanged(parentIndex, innerIndex) {
      vm.addedActions[parentIndex][innerIndex].numberOfUnits =
        vm.actionQuantity[parentIndex][innerIndex];
    }

    // hide the added material and actions dropdown and save/edit button when order was approved/canceled/declined
    function hideAddedMaterialActions() {
      vm.checkOrderStatus =
        vm.orderDetail.status === 'order_approved' ||
        vm.orderDetail.rejectStatus === 'declined' ||
        vm.orderDetail.rejectStatus === 'cancelled';
      return !vm.checkOrderStatus;
    }

    // show/hide the added material and actions header
    function showHideAddedMaterialActionsHeader(
      addedMaterialLength,
      addedActionLength,
      addedConsActionLength
    ) {
      if (addedMaterialLength === 0 && addedActionLength === 0 && addedConsActionLength === 0) {
        vm.checkOrderStatus = true;
      }
      return !(
        vm.checkOrderStatus &&
        addedMaterialLength === 0 &&
        addedActionLength === 0 &&
        addedConsActionLength === 0
      );
    }

    function saveDisable(index) {
      var isActionNotFilled = false;
      var isMaterialNotFilled = false;
      if (vm.addedActions && vm.addedActions[index]) {
        isActionNotFilled = vm.addedActions[index].some(function (action) {
          return !action.numberOfUnits;
        });
      }
      if (vm.constructions[index] && vm.constructions[index].addedMaterials) {
        isMaterialNotFilled = vm.constructions[index].addedMaterials.some(function (
          material
        ) {
          return (
            !material.quantity ||
            !material.material ||
            (!material.price && material.price !== 0)
          );
        });
      }
      vm.disableSaveButton = isActionNotFilled || isMaterialNotFilled;
    }

    function openAddMaterialDialog(construction, constructionName) {
      const constructionId = construction._id;
      const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
      window.top.postMessage(
        {
          eventType: 'open-add-material-dialog',
          orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
          construction: {
            construction,
            constructionId,
            constructionName
          }
        },
        '*'
      );
    }

    function openEditMaterialDialog(
      material,
      constructionId,
      construction,
      constructionName
    ) {
      if (this.isExternalTechnician) {
        return;
      }
      if (
        vm.isLabTechnician() &&
        vm.hideAddedMaterialActions() &&
        vm.orderDetail.maxVersion === vm.selectedOrderVersion &&
        !vm.orderDetail.isProductionFinished &&
        vm.orderDetail.invoicingState === 'NOT_READY'
      ) {
        const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
        window.top.postMessage(
          {
            eventType: 'open-edit-material-dialog',
            orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
            construction: {
              material,
              construction,
              constructionId,
              constructionName
            }
          },
          '*'
        );
      }
    }

    function openConfirmDeleteMaterialDialog(materialId, constructionId, construction, constructionName) {
      const conId = construction._id;
      const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
      window.top.postMessage(
        {
          eventType: 'delete-added-material',
          orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
          construction: {
            materialId,
            construction,
            constructionId: conId,
            constructionName
          }
        },
        '*'
      );
    }

    function openAddActionDialog(construction, constructionName) {
      const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
      const constructionId = construction._id;
      window.top.postMessage(
        {
          eventType: 'open-add-action-dialog',
          orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
          construction: {
            construction,
            constructionId,
            constructionName
          }
        },
        '*'
      );
    }

    function openEditActionDialog(
      action,
      constructionId,
      construction,
      constructionName
    ) {
      if (this.isExternalTechnician) {
        return;
      }
      if (
        vm.isLabTechnician() &&
        vm.hideAddedMaterialActions() &&
        vm.orderDetail.maxVersion === vm.selectedOrderVersion &&
        !vm.orderDetail.isProductionFinished &&
        vm.orderDetail.invoicingState === 'NOT_READY'
      ) {
        const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
        window.top.postMessage(
          {
            eventType: 'open-edit-action-dialog',
            orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
            construction: {
              action,
              construction,
              constructionId,
              constructionName
            }
          },
          '*'
        );
      }
    }

    function openConfirmDeleteActionDialog(actionId, constructionId, construction, constructionName) {
      const conId = construction._id;
      const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
      window.top.postMessage(
        {
          eventType: 'delete-added-action',
          orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
          construction: {
            actionId,
            construction,
            constructionId: conId,
            constructionName
          }
        },
        '*'
      );
    }

  }
})();
