import { Component, Input } from '@angular/core';
import { Action } from '../../../../../../../models/action.model';
import template from './construction-action-list.html';

@Component({
  selector: 'construction-action-list',
  template
})
export class ConstructionActionListComponent {
  @Input() actions: Action[];
}
