import { ActionList, Organization } from '../../../models/organization.model';
import { Input, Component, OnInit } from '@angular/core';
import { ActionListService } from '../../../services/action-list/action-list.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationSettingService } from '../../../services/organization-setting/organization-setting.service';
import * as _ from 'lodash';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../app.constant';
import template from './action-lists.html';
import style from '../material-lists/material-lists.less';

@Component({
  selector: 'action-lists',
  template,
  styles: [style]
})
export class ActionListsComponent implements OnInit {
  @Input() lab: Organization;
  actionLists: ActionList[] = [];
  selectedActionList: ActionList;
  selectedActionListId: string;
  defaultActionList: boolean;
  selectedList: Partial<ActionList>;
  currency: string;
  readonly modalId = '#add-action-list-modal';

  constructor(
    private readonly actionListService: ActionListService,
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService
  ) {}

  ngOnInit() {
    this.actionLists = this.lab.actionLists;
    this.currency = this.organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CURRENCY
    );
  }

  openModal() {
    $(this.modalId).modal('show');
  }

  getActionList(selectedActionListId: string) {
    this.defaultActionList =
      this.lab && this.lab.defaultActionListId === selectedActionListId;
    this.selectedActionList = this.lab.actionLists.find(
      (list) => list._id === selectedActionListId
    );
  }

  editActionList() {
    this.selectedList = _.cloneDeep(this.selectedActionList);
    $(this.modalId).modal('show');
  }

  actionListChange(actionLists) {
    if (actionLists) {
      this.lab.actionLists = actionLists;
    }
    this.selectedActionList = this.lab.actionLists.find(
      (list) => list._id === this.selectedActionListId
    );
  }

  selectNewList() {
    if (this.lab.actionLists.length) {
      this.selectedActionList = this.lab.actionLists[ this.lab.actionLists.length - 1 ];
      this.selectedActionListId = this.selectedActionList._id;
    }
  }

  closeModal() {
    this.selectedList = {};
    $(this.modalId).modal('hide');
  }

  saveDefaultActionList(): Promise<void> {
    const data = {
      actionListId: this.defaultActionList
        ? this.selectedActionList._id
        : null
    };
    return this.actionListService
      .saveDefaultActionList(data)
      .then(async (result) => {
        this.toastr.success(
          await this.translationsService.get(
            'DEFAULT_ACTION_LIST_SAVE_SUCCESSFULLY'
          )
        );
        this.lab.defaultActionListId = result.data.defaultActionListId;
      })
      .catch(async (err) => {
        const text = await this.translationsService.get(
          'ERROR_IN_DEFAULT_ACTION_LIST'
        );
        this.logService.error(
          'action-lists.component',
          'saveDefaultActionList',
          `${text}: ${err}`
        );
        this.toastr.error(text);
      });
  }
}
