import { Component, forwardRef, Inject } from '@angular/core';
import { GetAllOrdersService } from '../../../services/order-list/get-all-orders.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { OrdersGraphqlService } from '../../../graphql-services/orders/orders.graphql.service';
import template from './lab-work-started-modal.html';

@Component({
  selector: 'lab-work-started-modal',
  template: template
})
export class LabWorkStartedModalComponent {
  constructor(
    private readonly ordersGraphqlService: OrdersGraphqlService,
    private readonly getAllOrdersService: GetAllOrdersService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService,
    @Inject(forwardRef(() => '$state')) private readonly $state: any
  ) {}

  async changeToLabWorkStarted() {
    try {
      const orderId = this.$state.params.orderId;
      const status = 'lab_work_started';
      await this.ordersGraphqlService.orderNewStatusChange(orderId, status);
      this.getAllOrdersService.clearData(); //to clear cached orders list data
      this.getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
      this.toastr.success(
        await this.translationsService.get('SUCCESSFULLY_UPDATED')
      );
      window.location.reload();
    } catch(error) {
      this.logService.error('lab-work-started-modal.component', 'changeToLabWorkStarted', error);
    }
  }
}