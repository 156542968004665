/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './bridge-crown.html'
/**
 * Directive for adding or editing bridge crown
 */
(function () {
    'use strict';
    angular.module('app').component('bridgeCrown', {
        template: theTemplate,
        controller: bridgeCrownController,
        controllerAs: 'vm',
        bindings: angular.extend({}, sessionStateBindings,{
            constructionSettings: '=',
            selectedTeethRange: '=',
            selectedTeeth: '=',
            addUpdateBridge: '=',
            removeBridge: '=',
            selectedBridgeType: '=',
            orderIdToModify: '=',
            bridgeData: '<',
            selectedConstructionObj: '=',//currently selected constructions objects
            tempBridgeData: "=",
            tempImplantBridgeData: "=",
            bridgeCommentsArray: '='
        })
    });
    bridgeCrownController.$inject = [ '$scope', 'userService', 'CUSTOMER_SETTINGS_CONSTANTS','CONSTRUCTIONS', 'DATE', 'organizationSettingService' ];
    /**
     * Controller function
     */
    function bridgeCrownController($scope, userService, CUSTOMER_SETTINGS_CONSTANTS,CONSTRUCTIONS, DATE, organizationSettingService) {
        var vm = this;
        vm.constructionName = {
            bridge: CONSTRUCTIONS.BRIDGE,
            implantBridge: CONSTRUCTIONS.IMPLANT_BRIDGE
        };

        vm.metalDesignSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.METAL_DESIGN;
        vm.occlusionSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.OCCLUSION_TYPE;
        vm.ponticDesignSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PONTIC_DESIGN;
        vm.contactTypeSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CONTACT_TYPE;
        vm.implantStepsSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.IMPLANT_STEPS;

        vm.$onInit = function () {
            if (vm.currentUser && vm.currentUser.constructionSetting) {
                vm.defaultMetalDesign = vm.currentUser.constructionSetting.metalDesign;
                vm.defaultOcclusionSetting = vm.currentUser.constructionSetting.occlusionContact;
                vm.defaultContactType = vm.currentUser.constructionSetting.contactType;
                vm.defaultPonticDesign = vm.currentUser.constructionSetting.ponticDesign;
            } else {
                vm.defaultMetalDesign = organizationSettingService.getPreSelectedValueOfSetting(vm.metalDesignSettingName);
                vm.defaultOcclusionSetting = organizationSettingService.getPreSelectedValueOfSetting(vm.occlusionSettingName);
                vm.defaultContactType = organizationSettingService.getPreSelectedValueOfSetting(vm.contactTypeSettingName);
                vm.defaultPonticDesign = organizationSettingService.getPreSelectedValueOfSetting(vm.ponticDesignSettingName);
            }
        };

        vm.implantSteps = organizationSettingService.getCustomerSetting(vm.implantStepsSettingName);

        if (!vm.bridgeData) {
            //if null initialize. though this is not expected
            vm.bridgeData = {};
        }
        vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;
        /**
         * Member functions
         */
        vm.setMaterial = setMaterial;
        vm.setBridgeMaterial = setBridgeMaterial;
        vm.setBridgePontic = setBridgePontic;
        vm.setTypeOcclusion = setTypeOcclusion;
        vm.removeConstruction = removeConstruction;
        vm.saveTeethConstruction = saveTeethConstruction;
        vm.setTypeContact = setTypeContact;

        /**
         * Remove construction on selected teeth
         */
        function removeConstruction() {
            // remove crown from existing structure
            if (vm.removeBridge) {
                vm.removeBridge(vm.selectedTeethRange);
            }
            vm.selectedTeethRange = {};
        }

        /**
         * select and de select
         */
        function setMaterial(material) {
            if (material) {
                if (material === vm.bridgeData.material) {
                    vm.bridgeData.material = '';
                } else {
                    if (vm.selectedBridgeType === 'bridge') {
                        vm.tempBridgeData.material = material;
                    } else {
                        vm.tempImplantBridgeData.material = material;
                    }
                    vm.bridgeData.material = material;
                }
            }
        }

        function setBridgeMaterial(material) {
            if (material) {
                if (material === vm.bridgeData.metal) {
                    vm.bridgeData.metal = '';
                } else {
                    if (vm.selectedBridgeType === 'bridge') {
                        vm.tempBridgeData.metal = material;
                    } else {
                        vm.tempImplantBridgeData.metal = material;
                    }
                    vm.bridgeData.metal = material;
                }
            }
        }

        function setBridgePontic(material) {
            if (material) {
                if (material === vm.bridgeData.pontic) {
                    vm.bridgeData.pontic = '';
                } else {
                    if (vm.selectedBridgeType === 'bridge') {
                        vm.tempBridgeData.pontic = material;
                    } else {
                        vm.tempImplantBridgeData.pontic = material;
                    }
                    vm.bridgeData.pontic = material;
                }
            }
        }

        function setTypeOcclusion(type) {
            if (type) {
                if (type === vm.bridgeData.occlusionType) {
                    vm.bridgeData.occlusionType = '';
                } else {
                    if (vm.selectedBridgeType === 'bridge') {
                        vm.tempBridgeData.occlusionType = type;
                    } else {
                        vm.tempImplantBridgeData.occlusionType = type;
                    }
                    vm.bridgeData.occlusionType = type;
                }
            }
        }

        function setTypeContact(type) {
            if (type) {
                if (type === vm.bridgeData.contactType) {
                    vm.bridgeData.contactType = '';
                } else {
                    if (vm.selectedBridgeType === 'bridge') {
                        vm.tempBridgeData.contactType = type;
                    } else {
                        vm.tempImplantBridgeData.contactType = type;
                    }
                    vm.bridgeData.contactType = type;
                }
            }
        }

        // Watch Selected Construction type object for the old construction data.
        $scope.$watch('vm.selectedConstructionObj',function() {
            // If the selected bridge type is bridge
            if (vm.selectedBridgeType === 'bridge') {
                // Check if old bridge construction is present or not
                if (vm.selectedConstructionObj && vm.selectedConstructionObj.bridges && vm.selectedConstructionObj.bridges.length > 0) {
                    var bridge = vm.selectedConstructionObj.bridges[vm.selectedConstructionObj.bridges.length - 1];
                    //get old construction
                    for (var b in vm.selectedConstructionObj.bridges) {
                        if (vm.selectedTeethRange.start === vm.selectedConstructionObj.bridges[b].teethRange.start && vm.selectedTeethRange.end === vm.selectedConstructionObj.bridges[b].teethRange.end) {
                            vm.bridgeData = {
                                material: vm.selectedConstructionObj.bridges[b].material,
                                allComments: vm.selectedConstructionObj.bridges[b].allComments,
                                shade: vm.selectedConstructionObj.bridges[b].shade,
                                color: vm.selectedConstructionObj.bridges[b].color,
                                colorSampling: vm.selectedConstructionObj.bridges[b].colorSampling,
                                metal: vm.selectedConstructionObj.bridges[b].metal,
                                occlusionType: vm.selectedConstructionObj.bridges[b].occlusionType,
                                contactType: vm.selectedConstructionObj.bridges[b].contactType,
                                pontic: vm.selectedConstructionObj.bridges[b].pontic,
                                createdAt: vm.selectedConstructionObj.bridges[b].createdAt,
                                type: 'bridge',
                                otherMaterial: vm.selectedConstructionObj.bridges[b].otherMaterial
                            };
                        }
                    }

                    if (!vm.bridgeData.material) {
                        vm.bridgeData = {
                            material: bridge.material,
                            allComments: bridge.allComments,
                            shade: bridge.shade,
                            color: bridge.color,
                            colorSampling: bridge.colorSampling,
                            metal: bridge.metal,
                            occlusionType: bridge.occlusionType,
                            contactType: bridge.contactType,
                            createdAt: bridge.createdAt,
                            pontic: bridge.pontic,
                            type: 'bridge',
                            otherMaterial: bridge.otherMaterial
                        };
                    }

                } else {
                    userService.getCurrentUser().then(function (result) {
                        var userProfile = result.data;
                        if (userProfile.constructionSetting) {
                            //get default construction
                            if (vm.tempBridgeData && Object.keys(vm.tempBridgeData).length > 0) {
                                vm.bridgeData = {
                                    material: vm.tempBridgeData.material,
                                    allComments: vm.tempBridgeData.allComments,
                                    shade: vm.tempBridgeData.shade,
                                    color: vm.tempBridgeData.color,
                                    colorSampling: vm.tempBridgeData.colorSampling,
                                    createdAt: vm.tempBridgeData.createdAt,
                                    metal: vm.tempBridgeData.metal ? vm.tempBridgeData.metal : userProfile.constructionSetting.metalDesign,
                                    occlusionType: vm.tempBridgeData.occlusionType ? vm.tempBridgeData.occlusionType : userProfile.constructionSetting.occlusionContact,
                                    contactType: vm.tempBridgeData.contactType ? vm.tempBridgeData.contactType : userProfile.constructionSetting.contactType,
                                    pontic: vm.tempBridgeData.pontic ? vm.tempBridgeData.pontic : userProfile.constructionSetting.ponticDesign,
                                    type: 'bridge',
                                    otherMaterial: vm.tempBridgeData.otherMaterial
                                };
                            } else {
                                vm.bridgeData = {
                                    metal: userProfile.constructionSetting.metalDesign,
                                    occlusionType: userProfile.constructionSetting.occlusionContact,
                                    contactType: userProfile.constructionSetting.contactType,
                                    pontic: userProfile.constructionSetting.ponticDesign,
                                    type: 'bridge',
                                    allComments: []
                                };
                            }

                        }
                    });
                }
            } else if (vm.selectedBridgeType === 'implant bridge') {
                //if we previously created any implant bridge then use that else create empty
                if (vm.selectedConstructionObj && vm.selectedConstructionObj.implantBridges && vm.selectedConstructionObj.implantBridges.length > 0) {
                    var implantBridge = vm.selectedConstructionObj.implantBridges[vm.selectedConstructionObj.implantBridges.length - 1];
                    //get old construction

                    for (var ib in vm.selectedConstructionObj.implantBridges) {
                        if (vm.selectedTeethRange.start === vm.selectedConstructionObj.implantBridges[ib].teethRange.start && vm.selectedTeethRange.end === vm.selectedConstructionObj.implantBridges[ib].teethRange.end) {
                            vm.bridgeData = {
                                material: vm.selectedConstructionObj.implantBridges[ib].material,
                                allComments: vm.selectedConstructionObj.implantBridges[ib].allComments,
                                shade: vm.selectedConstructionObj.implantBridges[ib].shade,
                                color: vm.selectedConstructionObj.implantBridges[ib].color,
                                colorSampling: vm.selectedConstructionObj.implantBridges[ib].colorSampling,
                                metal: vm.selectedConstructionObj.implantBridges[ib].metal,
                                occlusionType: vm.selectedConstructionObj.implantBridges[ib].occlusionType,
                                contactType: vm.selectedConstructionObj.implantBridges[ib].contactType,
                                pontic: vm.selectedConstructionObj.implantBridges[ib].pontic,
                                type: 'implant bridge',
                                retainment: vm.selectedConstructionObj.implantBridges[ib].retainment,
                                createdAt: vm.selectedConstructionObj.implantBridges[ib].createdAt,
                                step: vm.selectedConstructionObj.implantBridges[ib].step,
                                otherMaterial: vm.selectedConstructionObj.implantBridges[ib].otherMaterial
                            };
                        }
                    }

                    if (!vm.bridgeData.material) {
                        vm.bridgeData = {
                            material: implantBridge.material,
                            allComments: implantBridge.allComments,
                            shade: implantBridge.shade,
                            color: implantBridge.color,
                            colorSampling: implantBridge.colorSampling,
                            metal: implantBridge.metal,
                            occlusionType: implantBridge.occlusionType,
                            contactType: implantBridge.contactType,
                            pontic: implantBridge.pontic,
                            type: 'implant bridge',
                            retainment: implantBridge.retainment,
                            createdAt: implantBridge.createdAt,
                            step: implantBridge.step,
                            otherMaterial: implantBridge.otherMaterial
                        };
                    }

                } else {
                    userService.getCurrentUser().then(function (result) {
                        var userProfile = result.data;
                        if (userProfile.constructionSetting) {
                            //get default construction
                            if (vm.tempImplantBridgeData && Object.keys(vm.tempImplantBridgeData).length > 0) {
                                vm.bridgeData = {
                                    material: vm.tempImplantBridgeData.material,
                                    allComments: vm.tempImplantBridgeData.allComments,
                                    shade: vm.tempBridgeData.shade,
                                    color: vm.tempBridgeData.color,
                                    colorSampling: vm.tempBridgeData.colorSampling,
                                    metal: vm.tempImplantBridgeData.metal ? vm.tempImplantBridgeData.metal : userProfile.constructionSetting.metalDesign,
                                    occlusionType: vm.tempImplantBridgeData.occlusionType ? vm.tempImplantBridgeData.occlusionType : userProfile.constructionSetting.occlusionContact,
                                    contactType: vm.tempImplantBridgeData.contactType ? vm.tempImplantBridgeData.contactType : userProfile.constructionSetting.contactType,
                                    pontic: vm.tempImplantBridgeData.pontic ? vm.tempImplantBridgeData.pontic : userProfile.constructionSetting.ponticDesign,
                                    type: 'implant bridge',
                                    retainment: vm.tempImplantBridgeData.retainment,
                                    step: vm.tempImplantBridgeData.step,
                                    createdAt: vm.tempImplantBridgeData.createdAt,
                                    otherMaterial: vm.tempImplantBridgeData.otherMaterial
                                };
                            } else {
                                vm.bridgeData = {
                                    metal: userProfile.constructionSetting.metalDesign,
                                    occlusionType: userProfile.constructionSetting.occlusionContact,
                                    contactType: userProfile.constructionSetting.contactType,
                                    pontic: userProfile.constructionSetting.ponticDesign,
                                    type: 'implant bridge',
                                    allComments: []
                                };
                            }

                        }
                    });
                }
            }

        });

        /**
         * Save teeth construction
         */
        $scope.$watch('vm.bridgeData', function (newVal, oldval) {
            // As in simple orders we have fixed types only not a scope of making it dynamic
            if (!vm.bridgeData.metal) {
                if (vm.selectedBridgeType === 'bridge') {
                    vm.tempBridgeData.metal = vm.defaultMetalDesign;
                } else {
                    vm.tempImplantBridgeData.metal = vm.defaultMetalDesign;
                }
                vm.bridgeData.metal = vm.defaultMetalDesign;
            }

            if (!vm.bridgeData.occlusionType) {
                if (vm.selectedBridgeType === 'bridge') {
                    vm.tempBridgeData.occlusionType = vm.defaultOcclusionSetting;
                } else {
                    vm.tempImplantBridgeData.occlusionType = vm.defaultOcclusionSetting;
                }
                vm.bridgeData.occlusionType = vm.defaultOcclusionSetting;
            }

            if (!vm.bridgeData.contactType) {
                if (vm.selectedBridgeType === 'bridge') {
                    vm.tempBridgeData.contactType = vm.defaultContactType;
                } else {
                    vm.tempImplantBridgeData.contactType = vm.defaultContactType;
                }
                vm.bridgeData.contactType = vm.defaultContactType;
            }

            if (!vm.bridgeData.pontic) {
                if (vm.selectedBridgeType === 'bridge') {
                    vm.tempBridgeData.pontic = vm.defaultPonticDesign;
                } else {
                    vm.tempImplantBridgeData.pontic = vm.defaultPonticDesign;
                }
                vm.bridgeData.pontic = vm.defaultPonticDesign;
            }

            if ((((vm.bridgeData.material === 'Other' && vm.bridgeData.otherMaterial) || (vm.bridgeData.material && vm.bridgeData.material !== 'Other'))) &&
                (vm.bridgeData.colorSampling || (vm.bridgeData.shade && vm.bridgeData.color))) {
                //it is valid construction. lets save it
                vm.bridgeData.teethRange = vm.selectedTeethRange;
                if (!vm.bridgeData.type) {
                    vm.bridgeData.type = vm.selectedBridgeType;
                }
                if (vm.bridgeData.type === 'implant bridge') {
                    if (vm.bridgeData.retainment && vm.bridgeData.step) {
                        vm.addUpdateBridge(angular.copy(vm.bridgeData), vm.selectedBridgeType);
                    }
                } else {
                    vm.addUpdateBridge(angular.copy(vm.bridgeData), vm.selectedBridgeType);
                }
            }
            if (!vm.orderIdToModify && !vm.bridgeData.createdAt) {
                vm.bridgeData.createdAt = new Date().getTime();
            }
        }, true);

        function saveTeethConstruction(type) {
            //get whole data here. add validations as well. if he did not select all there show error
            vm.bridgeData.teethRange = vm.selectedTeethRange;
            //TO DO validations should be there if he selected all fields or not.
            vm.addUpdateBridge(angular.copy(vm.bridgeData), type);
            vm.selectedTeethRange = {};
        }
    }

})();