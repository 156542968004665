import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sePostalCodePipe'
})
export class SePostalCodePipe implements PipeTransform {
  private isValidWithoutHyphen = new RegExp(/^\d{5}$/);

  transform(value: string): string {
    const postalCode = value.trim();
    if (this.isValidWithoutHyphen.test(postalCode)) {
      return postalCode.substring(0, 3) + ' ' + postalCode.substring(3, 5);
    }
    return value;
  }
}
