import { Component, Input } from '@angular/core';
import { CLASP_TEETH } from '../../../../../../../../app.constant';
import { ConstructionSettingsService } from '../../../../../../services/construction-settings-service';
import template from './multiselect-settings.html';

@Component({
  selector: 'multiselect-settings',
  template
})
export class MultiselectSettingsComponent {
  @Input() key: string;
  @Input() placeholder: string;

  showDropdown = false;
  teethList: { id: number; label: string }[] = CLASP_TEETH.TEETH;
  @Input() selectedTeeth: { id: number }[] = [];

  constructor(
    private readonly constructionSettingsService: ConstructionSettingsService
  ) {}

  getTeethStr() {
    if (!this.selectedTeeth) {
      return [];
    }
    return this.selectedTeeth.map((teeth) => teeth.id);
  }

  teethSelected(isSelected: boolean, teeth: { id: number; label: string }) {
    if (!this.selectedTeeth) {
      this.selectedTeeth = [];
    }
    if (isSelected) {
      this.selectedTeeth.push({ id: teeth.id });
    } else {
      const index = this.selectedTeeth.findIndex((t) => t.id === teeth.id);
      if (index >= 0) {
        this.selectedTeeth.splice(index, 1);
      }
    }
    this.constructionSettingsService.setMultiSelectSetting(
      this.key,
      this.selectedTeeth
    );
  }
}
