import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { Injectable } from '@angular/core';
import { API } from '../../app.constant';
import { Action } from '../../models/action.model';
import { Status } from '../../models/status.model';

@Injectable()
export class ActionService extends ResourceService {
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
  }

  getAllActions(): Promise<{ data: Action[] }> {
    return this.get(API.API_BASE + 'actions', undefined, this.apiInterceptor);
  }

  getActionsByMainCategory(id: string): Promise<{ data: Action[] }> {
    const params = new HttpParams().set('id', id);

    return this.get(
      API.API_BASE + 'actions/categories/:id',
      params,
      this.apiInterceptor
    );
  }

  saveAction(action: Action): Promise<Status> {
    return this.post(
      API.API_BASE + 'actions',
      action,
      undefined,
      this.apiInterceptor
    );
  }

  updateAction(action: Action): Promise<Status> {
    const params = new HttpParams().set('id', action._id);

    return this.put(
      API.API_BASE + 'actions/:id',
      action,
      params,
      this.apiInterceptor
    );
  }

  deleteAction(id: string): Promise<Status> {
    const params = new HttpParams().set('id', id);

    return this.delete(API.API_BASE + 'actions/:id', params, this.apiInterceptor);
  }
}
