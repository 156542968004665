/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-details-2.html'
/**
 * Order Details Component for the order details of any order.
 */
(function () {
  'use strict';
  angular.module('app').component('orderDetails2', {
    template: theTemplate,
    controller: OrderDetails2Controller,
    controllerAs: 'vm',
    bindings: angular.extend({}, sessionStateBindings, {
      isVisibleInPrint: '='
    })
  });

  // Order Details controller Dependencies should be inject here.
  OrderDetails2Controller.$inject = [
    '$state',
    '$filter',
    'toastr',
    'logService',
    'orderService',
    'OrderDetails2Service',
    'DATE',
    'permissionService',
    'printService',
    'ORDER_DETAIL_TYPE',
    'organizationSettingService',
    'CUSTOMER_SETTINGS_CONSTANTS',
    '$scope',
    '$interval',
    'ORDER_VERSION',
    'getAllOrdersService',
    '$timeout',
    '$compile',
    'ordersGraphqlService',
    'logisticGraphqlService',
    'TRANSPORT_TIME'
  ];

  // Controller Function for the order details component
  function OrderDetails2Controller(
    $state,
    $filter,
    toastr,
    logService,
    orderService,
    OrderDetails2Service,
    DATE,
    permissionService,
    printService,
    ORDER_DETAIL_TYPE,
    organizationSettingService,
    CUSTOMER_SETTINGS_CONSTANTS,
    $scope,
    $interval,
    ORDER_VERSION,
    getAllOrdersService,
    $timeout,
    $compile,
    ordersGraphqlService,
    logisticGraphqlService,
    TRANSPORT_TIME
  ) {
    var vm = this;
    vm.mockData = [];
    vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;
    vm.currentDate = new Date().toLocaleDateString();
    vm.organization = {};
    vm.selectedPickUpData = {};
    //for not refresh while clicked on modify and resend
    vm.isRefresh = false;
    vm.isVisibleInPrint = false;
    vm.isOrderSummary = false;
    vm.showPrice = true;
    vm.selectedOrderVersion = '';
    vm.messages = [];
    vm.simpleConstructions = {};
    //for translated patient category
    vm.patientCategorySettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORIES;
    vm.ratingRemarksSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.RATING_REMARKS;
    vm.showPrintSpinner = true;
    vm.showLimitedInfoToExtTech = false;
    vm.sampleContentSettingName =
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT;
    vm.dentistNote = {};

    // methods
    vm.getOrderDetails = getOrderDetails;
    vm.addSubNavMenus = addSubNavMenus;
    vm.openContactModal = openContactModal;
    vm.getMessages = getMessages;
    // for old orders(version 1)
    vm.constructionNumber = constructionNumber;
    vm.checkFixedConstructions = checkFixedConstructions;
    vm.checkRemovableConstructions = checkRemovableConstructions;
    vm.getOrderVersion = getOrderVersion;
    vm.getClass = getClass;
    vm.saveLabHandleRequest = saveLabHandleRequest;
    vm.toggleLabFlag = toggleLabFlag;
    vm.showSampleContent = showSampleContent;
    vm.toggleLabHandleRequest = toggleLabHandleRequest;
    vm.setPickUpData = setPickUpData;
    vm.onPickupScheduled = onPickupScheduled;
    vm.closePickupModal = closePickupModal;
    vm.onLabHandle = onLabHandle;
    vm.onPickupUpdated = onPickupUpdated;
    vm.isExternalTechnician = isExternalTechnician;
    vm.certifyUnCertifyOrder = certifyUnCertifyOrder;
    vm.productionFinishedUnfinishedOrder = productionFinishedUnfinishedOrder;
    vm.setOrderDetails = setOrderDetails;
    vm.openAddMaterialDialog = openAddMaterialDialog;
    vm.openEditMaterialDialog = openEditMaterialDialog;
    vm.openConfirmDeleteMaterialDialog = openConfirmDeleteMaterialDialog;
    vm.openEditActionDialog = openEditActionDialog;
    vm.openConfirmDeleteActionDialog = openConfirmDeleteActionDialog;
    vm.hideAddedMaterialActions = hideAddedMaterialActions;
    vm.showScanCase = showScanCase;
    vm.getDentistNote = getDentistNote;
    vm.onOpenUnlinkDialog = onOpenUnlinkDialog;

    vm.order2Type = ORDER_DETAIL_TYPE.ORDER2;
    vm.isVersionSelected = false;
    vm.removedConstructions = [];
    vm.removedSimpleConstructions = [];

    vm.reactAppUrl = process.env.NEW_APP_URL;

    $scope.$on('$destroy', () => {
      window.removeEventListener('message', handleEventsFromNewFE);
    });

    function openAddMaterialDialog(construction, constructionName) {
      const constructionId = construction._id;
      const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
      window.top.postMessage(
        {
          eventType: 'open-add-material-dialog',
          orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
          construction: {
            construction,
            constructionId,
            constructionName
          }
        },
        '*'
      );
    }

    function showScanCase(scanCaseId) {
      window.top.postMessage(
        {
          eventType: 'open-scan-case-dialog',
          scanCaseId,
          orderData: {
            _id: vm.orderDetail._id
          }
        },
        '*'
      );
    }

    function openEditActionDialog(
      action,
      constructionId,
      construction,
      constructionName
    ) {
      if (this.isExternalTechnician) {
        return;
      }
      if (
        vm.isLabTechnician &&
        vm.hideAddedMaterialActions() &&
        vm.orderDetail.maxVersion === vm.selectedOrderVersion &&
        !vm.orderDetail.isProductionFinished &&
        vm.orderDetail.invoicingState === 'NOT_READY'
      ) {
        const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
        window.top.postMessage(
          {
            eventType: 'open-edit-action-dialog',
            orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
            construction: {
              action,
              construction,
              constructionId,
              constructionName
            }
          },
          '*'
        );
      }
    }

    function openConfirmDeleteActionDialog(actionId, constructionId, construction, constructionName) {
      const conId = construction._id;
      const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
      window.top.postMessage(
        {
          eventType: 'delete-added-action',
          orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
          construction: {
            actionId,
            construction,
            constructionId: conId,
            constructionName
          }
        },
        '*'
      );
    }

    function certifyUnCertifyOrder() {
      return ordersGraphqlService
        .certifyUnCertifyOrder(vm.orderId)
        .then(() => {
          toastr.success($filter('translate')('SUCCESSFULLY_UPDATED'));
          window.location.reload();
        })
        .catch((err) => {
          const text = $filter('translate')('ERROR_IN_CERTIFYING_ORDER');
          logService.error(
            'order-details-1.component',
            'certifyUnCertifyOrder',
            text + ': ' + err
          );
          toastr.error(text);
        });
    }

    function showSampleContent() {
      if (
        vm.orderDetail &&
        vm.orderDetail.deliveryDetails &&
        vm.orderDetail.deliveryDetails.sample &&
        vm.orderDetail.deliveryDetails.sample.content
      ) {
        return vm.orderDetail.deliveryDetails.sample.content.some(function (
          content
        ) {
          return content.value;
        });
      } else {
        return false;
      }
    }

    function getClass(field) {
      return {
        'red-color': false
      };
    }

    //Method to check there if there is fixed constructions or not
    function checkFixedConstructions() {
      if (
        !vm.orderDetail ||
        !vm.orderDetail.constructions ||
        !vm.orderDetail.constructions.abutmentTeeth
      ) {
        return false;
      }
      return (
        vm.orderDetail.constructions.abutmentTeeth.singleCrowns.length ||
        vm.orderDetail.constructions.abutmentTeeth.bridges.length ||
        vm.orderDetail.constructions.abutmentTeeth.implantBridges.length ||
        vm.orderDetail.constructions.abutmentTeeth.implantCrowns.length ||
        vm.orderDetail.constructions.abutmentTeeth.posts.length ||
        vm.orderDetail.constructions.abutmentTeeth.others.length
      );
    }

    function getOrderVersion(version) {
      vm.getOrderDetails(version);
    }

    /**
     * Format data so it's compatible will sample-method content implementation
     * */
    function getFormattedSampleContent(contents) {
      return (
        contents &&
        contents.map(function (content) {
          return {
            name: content,
            value: false
          };
        })
      );
    }

    //Method to check there if there is Removable constructions or not
    function checkRemovableConstructions() {
      if (!vm.orderDetail || !vm.orderDetail.constructions) {
        return false;
      }

      return (
        vm.orderDetail.constructions.abutmentTeeth.partialDentures.length ||
        vm.orderDetail.constructions.abutmentTeeth.completeDentures.length ||
        vm.orderDetail.constructions.abutmentTeeth.bitetrays.length ||
        vm.orderDetail.constructions.abutmentTeeth.temporaryDentures.length ||
        vm.orderDetail.constructions.abutmentTeeth.otherRemovables.length
      );
    }

    function getPdfZoomLevel() {
      let numOfConstructions = 0;
      if (vm.constructions) {
        numOfConstructions = vm.constructions.length;
      } else if (vm.simpleConstructions) {
        numOfConstructions = Object.keys(vm.simpleConstructions).reduce(
          (total, key) => total + vm.simpleConstructions[key].length,
          0
        );
      }
      if (numOfConstructions < 3) {
        return `body {
                    margin: 0px !important;
                    font-family: 'Roboto', sans-serif !important;
                    padding:20px 50px !important;
                    zoom: 100%;
                }`;
      } else if (numOfConstructions === 3) {
        return `body {
                    margin: 0px !important;
                    font-family: 'Roboto', sans-serif !important;
                    padding:20px 50px !important;
                    zoom: 90%;
                }`;
      } else if (numOfConstructions > 3) {
        return `body {
                    margin: 0px !important;
                    font-family: 'Roboto', sans-serif !important;
                    padding:20px 50px !important;
                    zoom: 70%;
                }`;
      }
    }

    /**
     * Method to add menus to sub nav bar
     */
    function addSubNavMenus(userStatus) {
      OrderDetails2Service.addSubMenu(vm.orderDetail, vm.currentUser, userStatus);
    }

    //Get price of saved action in order that had no. of units
    function getPriceAndVat(actionId) {
      var actions = vm.constructions.reduce(function (result, construction) {
        return result.concat(construction.actions);
      }, []);

      var action = actions.find(function (action) {
        return action._id === actionId;
      });

      //get lab from clinic connected labs.
      var lab = vm.orderDetail.clinic.connectedLabs.find(function (lab) {
        if (lab.lab._id) {
          return lab.lab._id === vm.orderDetail.lab._id;
        }
        return lab.lab === vm.orderDetail.lab._id;
      });

      //get all actions from lab priceList array.
      var priceActions = lab.priceList.reduce(function (result, priceList) {
        return result.concat(priceList.actions);
      }, []);

      //get price from matched lab priceList action price.
      var price = priceActions.find(function (priceAction) {
        return action && action._id === priceAction.action;
      });

      return {
        vat: action.vat,
        price: price ? price.price : null
      };
    }

    function isLabTechnician() {
      return permissionService.isLabTechnician(vm.currentUser);
    }

    function setAddedActions() {
      vm.showTeethConstructions = new Array(vm.constructions.length).fill(true);
      vm.showRemovedTeethConstructions = new Array(
        vm.removedConstructions.length
      ).fill(true);
      vm.constructions.forEach(function (construction, index) {
        vm.checkedActions[construction._id] = {};
        var addedActions = construction.numberOfUnits.map(function (unit) {
          vm.checkedActions[construction._id][unit.actionId] = true;
          const priceAndVat = getPriceAndVat(unit.actionId);
          return {
            actionId: unit.actionId,
            header: unit.header,
            numberOfUnits: unit.numberOfUnits,
            vat: priceAndVat.vat,
            price: priceAndVat.price,
            notSaved: false
          };
        });
        if (!vm.addedActions[index]) {
          vm.addedActions[index] = [];
        }
        vm.addedActions[index] = vm.addedActions[index].concat(addedActions);
        vm.addedActions[index] = _.uniqBy(vm.addedActions[index], 'header');
        vm.actionQuantity[index] = vm.addedActions[index].map(function (action) {
          return action.numberOfUnits;
        });
      });
    }

    function setSimpleTypeConstructions() {
      if (vm.orderDetail && vm.constructionNames && vm.removedSimpleConstructions) {
        vm.totalNoOfConstructions = vm.constructionNames.reduce(function (
          sum,
          name
        ) {
          return sum + vm.orderDetail.constructions.abutmentTeeth[name].length;
        },
          0);
        vm.showSimpleTypeConstructions = vm.constructionNames.map(function (name) {
          return new Array(
            vm.orderDetail.constructions.abutmentTeeth[name].length
          ).fill(true);
        });
        vm.showRemovedSimpleTypeConstructions = new Array(
          vm.removedSimpleConstructions.length
        ).fill(true);
      }
    }

    function getOrderDetails(version) {
      if (vm.isRefresh) {
        return false;
      }
      var obj = { orderId: vm.orderId };
      if (version) {
        obj.version = version;
      }
      vm.showRemovedTeethConstructions = [];
      vm.showRemovedSimpleTypeConstructions = [];
      vm.showTeethConstructions = [];
      vm.addedActions = [];
      vm.actionQuantity = [];
      vm.checkedActions = [];
      orderService
        .getOrder(obj)
        .then(function (result) {
          vm.orderDetail = result.data.order;
          if (!vm.orderDetail.deliveryDetails.sample.content.length > 0) {
            var sampleContent = organizationSettingService.getCustomerSetting(
              CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT
            );
            vm.orderDetail.sample.content = getFormattedSampleContent(sampleContent);
          }
          //for current comment not showing in textbox
          if (vm.orderDetail.sample && vm.orderDetail.sample.comments) {
            vm.orderDetail.tempSampleComment = vm.orderDetail.sample.comments;
            vm.orderDetail.sample.comments = '';
          }
          if (
            vm.orderDetail.newConstructions &&
            vm.orderDetail.newConstructions.constructions &&
            vm.orderDetail.newConstructions.constructions.length
          ) {
            vm.printableOrder = _.groupBy(
              vm.orderDetail.newConstructions.constructions,
              'category.name'
            );
            vm.categories = Object.keys(vm.printableOrder);
            vm.constructions = vm.orderDetail.constructions;
            vm.removedConstructions = vm.orderDetail.removedConstructions;
            setAddedActions();
          } else {
            vm.constructionNames = vm.orderDetail.constructionNames;
            vm.simpleConstructions = vm.orderDetail.simpleConstructions;
            vm.removedSimpleConstructions = vm.orderDetail.removedSimpleConstructions;
            setSimpleTypeConstructions();
            if (vm.orderDetail && vm.orderDetail.constructions) {
              vm.abutmentArr = Object.keys(
                vm.orderDetail.constructions.abutmentTeeth
              ).reduce(function (result, key) {
                return result.concat(
                  vm.orderDetail.constructions.abutmentTeeth[key]
                );
              }, []);
            }
          }

          /**
           * move files to last in singedUrl array
           */
          if (vm.orderDetail.signedUrl && vm.orderDetail.signedUrl.length) {
            vm.orderDetail.signedUrl.sort(function (signedUrl) {
              return signedUrl.contentType.split('/')[0] !== 'image' ? 1 : -1;
            });
          }
          setCustomerSettings();
          addSubNavMenus();
          vm.selectedOrderVersion = '1';
          vm.showPrintSpinner = false;
          vm.getMessages();
          vm.setPickUpData();
          if (vm.isLabTechnician) {
            vm.getDentistNote();
          }
        })
        .catch(function (err) {
          logService.error('order-details-2.component', 'getOrderDetails', err);
          toastr.error($filter('translate')('ERROR_IN_GETTING_ORDER'));
        });
    }

    // hide the added material and actions dropdown and save/edit button when order was approved/canceled/declined
    function hideAddedMaterialActions() {
      vm.checkOrderStatus =
        vm.orderDetail.status === 'order_approved' ||
        vm.orderDetail.rejectStatus === 'declined' ||
        vm.orderDetail.rejectStatus === 'cancelled';
      return !vm.checkOrderStatus;
    }

    function openEditMaterialDialog(
      material,
      constructionId,
      construction,
      constructionName
    ) {
      if (this.isExternalTechnician) {
        return;
      }
      if (
        vm.isLabTechnician &&
        vm.hideAddedMaterialActions() &&
        vm.orderDetail.maxVersion === vm.selectedOrderVersion &&
        !vm.orderDetail.isProductionFinished &&
        vm.orderDetail.invoicingState === 'NOT_READY'
      ) {
        const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
        window.top.postMessage(
          {
            eventType: 'open-edit-material-dialog',
            orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
            construction: {
              material,
              construction,
              constructionId,
              constructionName
            }
          },
          '*'
        );
      }
    }

    function openConfirmDeleteMaterialDialog(materialId, constructionId, construction, constructionName) {
      const conId = construction._id;
      const isActionBased = vm.orderDetail.type === 'Order_2' || vm.orderDetail.type === 'ACTION_BASED';
      window.top.postMessage(
        {
          eventType: 'delete-added-material',
          orderData: Object.assign(vm.orderDetail, { type: isActionBased ? 'ACTION_BASED' : 'SIMPLE_TYPE' }),
          construction: {
            materialId,
            construction,
            constructionId: conId,
            constructionName
          }
        },
        '*'
      );
    }

    function openContactModal(organization) {
      if (organization) {
        vm.organization = organization;
        $('#contact-info-modal').modal('show');
      }
    }

    /**
     * Format data so it's compatible will rate-order modal implementation
     * */
    function getFormattedRatingRemarks(reasons) {
      return (
        reasons &&
        reasons.map(function (reason) {
          /**
           * "reason.translationValue" means data is coming from database but is according to previous implementation
           * "reason.name" means data is coming from database according to new implementation
           * "reason" means data is not saved and is fetched from customer settings(in this case it will be a string)
           * */
          return {
            name: reason.translationValue || reason.name || reason,
            value: reason.value || false
          };
        })
      );
    }

    function getMessages() {
      orderService
        .getMessages(vm.orderId)
        .then(function (result) {
          if (result.data && result.data.messages && result.data.messages.length) {
            vm.messages = result.data.messages;
            vm.messages
              .filter(function (message) {
                return !message.type || message.type !== 'status';
              })
              .forEach(function (message) {
                if (vm.currentUser) {
                  var index = message.readBy.indexOf(vm.currentUser._id);
                  if (index === -1) {
                    message.status = 'NEW';
                  }
                  if (!permissionService.isSuperAdmin(vm.currentUser)) {
                    if (permissionService.isDentist(vm.currentUser)) {
                      message.sameOrganization = checkForSameOrganization(
                        message,
                        vm.orderDetail.deliveryDetails.pickUpLocation
                      );
                    } else if (isLabTechnician()) {
                      message.sameOrganization = checkForSameOrganization(
                        message,
                        vm.orderDetail.lab
                      );
                    }
                  }
                }
              });
            updateMessageStatus();
          }
        })
        .catch(function (err) {
          logService.error('order-details-2.component', 'getMessages', err);
          toastr.error($filter('translate')('ERROR_IN_GETTING_MESSAGES'));
        });
    }

    function updateMessageStatus() {
      orderService
        .changeMessageStatus(vm.orderId)
        .then(function (result) {
          // message status updated successfully, nothing happens here.
          getAllOrdersService.clearData(); //to clear cached orders list data
          getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
          window.top.postMessage(
            {
              eventType: 'refresh-notifications'
            },
            '*'
          );
        })
        .catch(function (err) {
          logService.error('order-details-2.component', 'updateMessageStatus', err);
          toastr.error($filter('translate')('ERROR_IN_UPDATE_MESSAGE'));
        });
    }

    /**
     * Check the message belongs to same organization or not
     * @param message
     * @param organization
     * @returns {boolean}
     */
    function checkForSameOrganization(message, organization) {
      return organization._id === message.by.organization;
    }

    // get customer settings data from service and set it to controller variables for further use
    function setCustomerSettings() {
      vm.currency = organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CURRENCY
      );
      vm.isPatientCategoryEnabled = organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.PATIENT_CATEGORY
      );
      var ratingRemarks = organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.RATING_REMARKS
      );
      if (vm.orderDetail) {
        vm.orderDetail.rating.reasons =
          !vm.orderDetail.rating.reasons || !vm.orderDetail.rating.reasons.length
            ? getFormattedRatingRemarks(ratingRemarks)
            : getFormattedRatingRemarks(vm.orderDetail.rating.reasons);
      }
    }

    vm.$onInit = function () {
      vm.showRemovedTeethConstructions = [];
      vm.showRemovedSimpleTypeConstructions = [];
      vm.showTeethConstructions = [];
      vm.isLabTechnician = isLabTechnician();
      vm.isDentist = permissionService.isDentist(vm.currentUser);
      vm.isExternalTechnician = permissionService.isExternalTechnician(
        vm.currentUser
      );
      vm.orderId = $state.params.orderId;
      vm.showLimitedInfoToExtTech = organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.LIMIT_INFO_EXTERNAL_USERS
      );
      vm.isActionBasedOrderVersion =
        organizationSettingService.getCustomerSetting(
          CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION
        ) === CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.ACTION_BASED;
      vm.getOrderDetails();
      window.top.postMessage(
        { eventType: 'get-deviations', orderId: vm.orderId },
        '*'
      );
      vm.interval = $interval(function () {
        vm.getMessages();
      }, 60 * 1000);
    };

    vm.$onDestroy = function () {
      $interval.cancel(vm.interval);
    };

    function checkIfAbutmentExists(jaw, abutments) {
      return Object.keys(abutments).some(function (key) {
        return jaw.indexOf(abutments[key].teethNo) !== -1;
      });
    }

    function constructionNumber(abutments, isUpperJaw) {
      if (isUpperJaw) {
        if (checkIfAbutmentExists(vm.upperJaw, abutments)) {
          return '1.';
        }
      } else {
        var returnValue = '1.';
        Object.keys(abutments).forEach(function (teeth) {
          if (vm.upperJaw.indexOf(abutments[teeth].teethNo) !== -1) {
            returnValue = '2.';
          } else if (parseInt(teeth) === abutments.length - 1) {
            if (checkIfAbutmentExists(vm.lowerJaw, abutments)) {
              returnValue = '1.';
            }
          }
        });
        return returnValue;
      }
    }

    /**
     * toggle lab handle request value when click on truck icon
     * @param isLabHandleRequest
     */
    function toggleLabHandleRequest(isLabHandleRequest) {
      if (
        vm.currentUser.organization &&
        vm.currentUser.organization.isTransportEnable &&
        !vm.orderDetail.deliveryDetails.isLabHaveOwnResource
      ) {
        if (
          vm.orderDetail.transport &&
          vm.orderDetail.transport.status &&
          vm.orderDetail.transport.status !== 'PLANNED'
        ) {
          return;
        }
        if (vm.orderDetail.transport && vm.orderDetail.transport._id) {
          vm.selectedPickUpData = {
            _id: vm.orderDetail.transport._id,
            clinic: vm.orderDetail.transport.clinic,
            clinicName: vm.orderDetail.deliveryDetails.pickUpLocation.name,
            clinicContact: vm.orderDetail.deliveryDetails.pickUpLocation.contact,
            comments: vm.orderDetail.transport.comments,
            orderId: vm.orderDetail._id,
            noOfRequestedPackages: vm.orderDetail.transport.noOfRequestedPackages,
            transportDate: new Date(vm.orderDetail.transport.transportDay),
            transportDay: vm.orderDetail.transport.transportDay,
            internalMessage: vm.orderDetail.transport.internalMessage,
            dentistName: vm.orderDetail.dentist
              ? vm.orderDetail.dentist.name
              : vm.orderDetail.created.by.name
          };
        }
        $('#schedule-pick-up-from-truck-icon-modal').modal('show');
        return;
      }
      vm.orderDetail.deliveryDetails.isLabHandleRequest = isLabHandleRequest;
      vm.orderDetail.deliveryDetails.isLabHaveOwnResource = false;
      saveLabHandleRequest();
    }

    function setPickUpData() {
      vm.selectedPickUpData = {
        clinic: vm.orderDetail.deliveryDetails.pickUpLocation._id,
        clinicName: vm.orderDetail.deliveryDetails.pickUpLocation.name,
        clinicContact: vm.orderDetail.deliveryDetails.pickUpLocation.contact,
        comments: null,
        orderId: vm.orderDetail._id,
        noOfRequestedPackages: 1,
        transportDay: null,
        transportDate: null,
        dentistName: vm.orderDetail.dentist
          ? vm.orderDetail.dentist.name
          : vm.orderDetail.created.by.name
      };
    }
    function onPickupScheduled(logistic) {
      vm.orderDetail.transport = Object.assign({}, logistic);
      vm.orderDetail.deliveryDetails.isLabHandleRequest = true;
    }
    function onPickupUpdated(logistic) {
      vm.orderDetail.transport = logistic;
      vm.setPickUpData();
      vm.orderDetail.deliveryDetails.isLabHandleRequest = false;
    }
    function closePickupModal() {
      $('#schedule-pick-up-from-truck-icon-modal').modal('hide');
      setPickUpData();
    }

    function onLabHandle() {
      vm.orderDetail.deliveryDetails.isLabHandleRequest = true;
      vm.orderDetail.deliveryDetails.isLabHaveOwnResource = true;
      saveLabHandleRequest();
    }

    /**
     * Save function of Lab handle request by lab
     */
    function saveLabHandleRequest() {
      var isSimpleOrder = vm.orderDetail.type === ORDER_VERSION.ORDER_1;
      vm.orderDetail.labHandleReq = true;
      if (isSimpleOrder) {
        vm.orderDetail.orderId = $state.params.orderId;
        orderService
          .updateOrder(vm.orderDetail)
          .then(function (result) {
            toastr.success($filter('translate')('SAVE_LAB_HANDLE_REQUEST'));
            getAllOrdersService.clearData(); //to clear cached orders list data
            getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
            vm.getOrderDetails();
          })
          .catch(function (err) {
            logService.error(
              'order-detail-2.component',
              'saveLabHandleRequest',
              err
            );
            toastr.error($filter('translate')('ERROR_IN_UPDATE_ORDER'));
          });
      } else {
        vm.orderDetail.id = $state.params.orderId;
        orderService
          .updateNewOrder(vm.orderDetail)
          .then(function (result) {
            toastr.success($filter('translate')('SAVE_LAB_HANDLE_REQUEST'));
            getAllOrdersService.clearData(); //to clear cached orders list data
            getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
            vm.getOrderDetails();
          })
          .catch(function (err) {
            logService.error(
              'order-detail-2.component',
              'saveLabHandleRequest 1',
              err
            );
            toastr.error($filter('translate')('ERROR_IN_UPDATE_ORDER'));
          });
      }
    }

    /**
     * method for toggle lab flag value
     */
    function toggleLabFlag() {
      if (!vm.orderDetail.labFlag) {
        orderService
          .setLabFlag(vm.orderId)
          .then(function (result) {
            toastr.success($filter('translate')('SUCCESSFULLY_UPDATED_LAB_FLAG'));
            vm.orderDetail.labFlag = result.data.labFlag;
            getAllOrdersService.clearData(); //to clear cached orders list data
            getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
          })
          .catch(function (err) {
            logService.error(
              'order-details-2.component',
              'toggleLabFlag---->setLabFlag',
              err
            );
            toastr.error($filter('translate')('ERROR_IN_UPDATE_ORDER'));
          });
      } else {
        orderService
          .removeLabFlag(vm.orderId)
          .then(function (result) {
            toastr.success($filter('translate')('SUCCESSFULLY_UPDATED_LAB_FLAG'));
            vm.orderDetail.labFlag = result.data.labFlag;
            getAllOrdersService.clearData(); //to clear cached orders list data
            getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
          })
          .catch(function (err) {
            logService.error(
              'order-details-2.component',
              'toggleLabFlag----->removeLabFlag',
              err
            );
            toastr.error($filter('translate')('ERROR_IN_UPDATE_ORDER'));
          });
      }
    }

    function isExternalTechnician(user) {
      return permissionService.isExternalTechnician(user);
    }

    function setOrderDetails(orderDetail) {
      vm.orderDetail = Object.assign({}, orderDetail);
    }

    function productionFinishedUnfinishedOrder() {
      if (!vm.orderDetail.isProductionFinished) {
        return ordersGraphqlService
          .markOrderProductionFinished(vm.orderId)
          .then(() => {
            toastr.success($filter('translate')('SUCCESSFULLY_UPDATED'));
            window.location.reload();
          })
          .catch((err) => {
            const text = $filter('translate')('ERROR_IN_PRODUCTION_ORDER');
            logService.error(
              'order-details-2.component',
              'productionFinishedUnfinishedOrder ---> finished',
              text + ': ' + err
            );
            toastr.error(text);
          });
      } else {
        return ordersGraphqlService
          .markOrderProductionNotFinished(vm.orderId)
          .then(() => {
            toastr.success($filter('translate')('SUCCESSFULLY_UPDATED'));
            window.location.reload();
          })
          .catch((err) => {
            const text = $filter('translate')('ERROR_IN_PRODUCTION_ORDER');
            logService.error(
              'order-details-2.component',
              'productionFinishedUnfinishedOrder ---> not_finished',
              text + ': ' + err
            );
            toastr.error(text);
          });
      }
    }

    //For getting data from new frontend
    window.addEventListener('message', handleEventsFromNewFE);

    function handleAttachScanCase(scanCase) {
      if (!vm.orderDetail.attachedCaseFiles || !vm.orderDetail.attachedCaseFiles.length) {
        vm.orderDetail.attachedCaseFiles = [scanCase];
        return;
      }
      const index = vm.orderDetail.attachedCaseFiles.findIndex(function (sCase) {
        return sCase._id === scanCase._id;
      });
      if (index === -1) {
        vm.orderDetail.attachedCaseFiles.push(scanCase);
      }
    }

    function handleUnlinkAttachScanCase(scanCaseId) {
      const index = vm.orderDetail.attachedCaseFiles.findIndex(function (sCase) {
        return sCase._id === scanCaseId;
      });
      if (index !== -1) {
        vm.orderDetail.attachedCaseFiles.splice(index, 1);
      }
    }

    function handleEventsFromNewFE(e) {
      if (
        e.data &&
        e.origin === vm.reactAppUrl
      ) {
        switch (e.data.eventType) {
          case 'deviations':
            vm.deviations = e.data.deviations;
            break;

          case 'scan-case-attached':
            if (e.data.scanCase) {
              handleAttachScanCase(e.data.scanCase);
            }
            break;

          case 'unlink-scan-case-attachment':
            handleUnlinkAttachScanCase(e.data.unlinkScanCaseAttachment);
            break;

          default:
            throw new Error('Unhandled event type: ' + e.data.eventType);
        }
      }
    }

    function getDentistNote() {
      const clientSettings = vm.orderDetail.lab?.clientSettings;
      const clientSetting = clientSettings?.find((setting) => {
        return setting.clinicId === vm.orderDetail.deliveryDetails?.destination?._id;
      });
      if (clientSetting?.dentistsNotes) {
        vm.dentistNote = clientSetting.dentistsNotes.find((note) => note.dentistId === vm.orderDetail.dentist._id);
      }
    }

    function onOpenUnlinkDialog (scanCaseId) {
      window.top.postMessage(
        {
          eventType: 'delete-scan-case-attachment',
          orderData: {
            _id: vm.orderDetail._id,
            scanCaseId: scanCaseId,
          }
        },
        '*'
      );
    }
  }
})();
