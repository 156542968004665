import { Injectable } from '@angular/core';

@Injectable()
export class EscapedMessageService {
  getEscapedMessage(message: string) {
    let newMessage = '';
    if (message) {
      const strLen = message.length;
      for (let i = 0; i < strLen; i++) {
        if (message.charCodeAt(i) === 60 || message.charCodeAt(i) === 62) {
          // if character is "<" or ">", escape it to avoid script injections
          newMessage += encodeURIComponent(message[i]);
        } else if (message.charCodeAt(i) === 10) {
          // if character is "\n"(enter), add a <br> tag to show it on the next line
          newMessage += '<br/>';
        } else {
          newMessage += message[i];
        }
      }
    }
    return newMessage;
  }
}
