import {
  Component,
  OnInit,
  Input,
  Inject,
  forwardRef,
  OnDestroy
} from '@angular/core';
import {
  TransportAppService,
  MappedTransportClinic
} from '../../services/transport-app.service';
import template from './scheduled-transports.html';
import { MatDialog } from '@angular/material/dialog';
import {
  EditTransportDialogComponent,
  EditTransportDialogData,
  EditTransportDialogResult
} from './edit-transport-dialog/edit-transport-dialog.component';
import { User } from '../../../../models/user.model';
import { Organization } from '../../../../models/organization.model';
import { LogService } from '../../../../services/core/log.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaDatesService } from '../../services/ta-dates.service';
import { Logistic } from '../../../../models/logistic.model';
import * as moment from 'moment';
import { Subscription, interval } from 'rxjs';
import { TranslationsService } from '../../../../services/translations/translations.service';

@Component({
  selector: 'scheduled-transports',
  template: template
})
export class ScheduledTransportsComponent implements OnInit, OnDestroy {
  @Input() currentUser: User;
  isLoading = true;
  mappedClinics: MappedTransportClinic[] = [];
  selectedDate = new Date();
  lab: Organization;
  allowEditPickup: boolean;
  allowEditDelivery: boolean;
  searchText: string;
  pollInterval: Subscription;

  constructor(
    public dialog: MatDialog,
    private readonly logService: LogService,
    private readonly snackBar: MatSnackBar,
    private readonly transportAppService: TransportAppService,
    private readonly taDatesService: TaDatesService,
    private readonly translationsService: TranslationsService,
    @Inject(forwardRef(() => '$stateParams')) private readonly $stateParams: any
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      if (this.currentUser?.organization?.type === 'lab') {
        this.lab = this.currentUser.organization;
        await this.onSelectDate(this.defaultSelectedDate());
        if (this.$stateParams.editTransportId) {
          this.openEditTransportOrderDialogFromQuery(
            this.$stateParams.editTransportId
          );
        }
        this.initPolling();
      }
    } catch (e) {
      this.logService.newError(e);
    }
  }

  searchTextChange(search: string) {
    this.searchText = search;
  }

  onEditTransportOrder(event) {
    this.openEditTransportOrderDialog(event.transportOrder, event.clinic);
  }

  async onSelectDate(date: Date) {
    this.isLoading = true;
    this.selectedDate = date;
    await this.load();
  }

  async load() {
    this.allowEditPickup = this.taDatesService.isPickupAllowed(
      moment(this.selectedDate)
    );
    this.allowEditDelivery = this.taDatesService.isDeliveryAllowed(
      moment(this.selectedDate)
    );
    this.mappedClinics = await this.transportAppService.loadTransports(
      this.selectedDate
    );
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.pollInterval.unsubscribe();
  }

  private defaultSelectedDate() {
    if (this.$stateParams.date) {
      return new Date(parseInt(this.$stateParams.date));
    }
    return new Date();
  }

  private openEditTransportOrderDialog(
    transportOrder: Logistic,
    clinic: Organization
  ) {
    const type = transportOrder.type;
    this.dialog
      .open(EditTransportDialogComponent, {
        autoFocus: false,
        // TODO: Auto height not working on iOS
        height: this.calcDialogHeight(transportOrder),
        maxHeight: '95vh',
        width: '600px',
        data: {
          transportOrder: transportOrder,
          clinic: clinic,
          lab: this.lab,
          type,
          allowEdit:
            type === 'PICKUP' ? this.allowEditPickup : this.allowEditDelivery
        } as EditTransportDialogData
      })
      .afterClosed()
      .subscribe((result: EditTransportDialogResult) => {
        this.handleEditDialogResult(result);
      });
  }

  private calcDialogHeight(transportOrder: Logistic): string {
    if (transportOrder.type === 'PICKUP') {
      return transportOrder.orders && transportOrder.orders.length > 0
        ? '730px'
        : '585px';
    }
    return '665px';
  }

  private openEditTransportOrderDialogFromQuery(editTransportId: string): void {
    for (const mappedClinc of this.mappedClinics) {
      const pickup = mappedClinc.pickup?.find(
        (transport) => transport._id === editTransportId
      );
      if (pickup) {
        this.openEditTransportOrderDialog(pickup, mappedClinc.clinic);
        return;
      } else if (mappedClinc.delivery?._id === editTransportId) {
        this.openEditTransportOrderDialog(mappedClinc.delivery, mappedClinc.clinic);
        return;
      }
    }
  }

  private async handleEditDialogResult(result: EditTransportDialogResult) {
    try {
      if (result) {
        if (result.action === 'REVOKE') {
          await this.transportAppService.revokeTransportOrder(result.transportOrder);
        } else {
          await this.transportAppService.updateTransportOrder(result.transportOrder);
        }
        await this.load();
      }
    } catch (e) {
      this.logService.newError(e);
      this.snackBar.open(
        await this.translationsService.get('SOMETHING_WENT_WRONG'),
        await this.translationsService.get('OK'),
        {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-warn'],
          horizontalPosition: 'right'
        }
      );
    }
  }

  private initPolling() {
    const oneMinute = 120 * 1000;
    this.pollInterval = interval(oneMinute)
      .pipe()
      .subscribe(() => {
        this.load();
      });
  }
}
