import { PipeTransform, Pipe } from '@angular/core';
import { Organization } from '../models/organization.model';

@Pipe({
  name: 'LabSearch'
})
export class LabSearchPipe implements PipeTransform {
  transform(labs: Organization[], search: string): Organization[] {
    if (!search) {
      return labs;
    }
    return labs.filter((lab) => {
      const hasOrganizationNumber =
        lab.organizationNumber && lab.organizationNumber.indexOf(search) !== -1;
      const hasLabName =
        lab.name &&
        lab.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
      return hasOrganizationNumber || hasLabName;
    });
  }
}
