import { Component, Input, OnInit } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../services/core/log.service';
import { PermissionService } from '../../services/core/permission.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { SessionService } from '../../services/core/session.service';
import { UserRoleService } from '../../services/roles/user-role.service';
import { User } from '../../models/user.model';

import template from './lab-technician-card.html';
import { Role } from '../../models/roles.model';

@Component({
  selector: 'lab-technician-card',
  template: template
})
export class LabTechnicianCardComponent implements OnInit {
  @Input() currentUser: User;
  labTechnicians: User[] = [];
  labTechnician: any = {};
  showSpinner: boolean;
  isSuperAdmin: boolean;
  selectedLabIds: string[] = [];
  roles: Role[] = [];
  constructor(
    private userService: UsersService,
    private permissionService: PermissionService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private subNavService: SubNavService,
    private sessionService: SessionService,
    private userRoleService: UserRoleService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.addMenu();
    this.isSuperAdmin = this.permissionService.isSuperAdmin(this.currentUser);
    this.setUserRolesExcept('DENTIST');
  }

  async addMenu(): Promise<void> {
    this.subNavService.addMenu({
      name: await this.translationsService.get('LIST_VIEW'),
      state: 'app.lab-technician',
      icon: 'fa-list-ul',
      isActive: false,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('CARD_VIEW'),
      state: 'app.lab-technician-card',
      icon: 'fa-th',
      isActive: true,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('ADD_LAB_TECHNICIAN'),
      icon: 'fa-plus',
      action: 'add-lab-technician-modal',
      isModel: true,
      isClass: true
    });
  }

  onFilterUpdate(selectedLabIds: string[]): void {
    this.selectedLabIds = selectedLabIds;
    if (this.selectedLabIds) {
      this.sessionService.setItem(
        'selectedLabIds',
        JSON.stringify(this.selectedLabIds)
      );
    }
    this.showSpinner = true;
    this.updateAllUsersForSuperAdmin();
  }

  updateLabTechnicians(): Promise<void> {
    if (this.currentUser) {
      return this.userService
        .getAllUsers({ organizationId: this.currentUser.organization._id })
        .then((result) => {
          if (result.data) {
            this.labTechnicians = result.data.users;
          }
        });
    }
  }

  // Function to get users
  updateAllUsersForSuperAdmin(): Promise<void> {
    if (this.currentUser) {
      const labIds = JSON.parse(this.sessionService.getItem('selectedLabIds'));
      if (labIds && labIds.length > 0) {
        this.selectedLabIds = labIds;
      }
      return this.userService
        .getAllUsersForSuperAdmin({
          type: 'lab_technician',
          labIds: this.selectedLabIds
        })
        .then((result) => {
          this.labTechnicians = result.data;
        });
    }
  }

  /**
   * On technician change
   * @param labTechnician
   */
  onLabTechnicianUpdate(labTechnician: User): void {
    if (labTechnician) {
      this.labTechnician = labTechnician;
      this.labTechnician.email = labTechnician.email;
      this.labTechnician.lab = labTechnician.organization._id;
    }

    $('#add-lab-technician-modal').modal('show');
  }

  PopulateUsers(): Promise<void> {
    if (this.isSuperAdmin) {
      return this.updateAllUsersForSuperAdmin();
    } else {
      return this.updateLabTechnicians();
    }
  }

  // on change
  onLabTechnicianChange(): void {
    this.labTechnicians = [];
    this.PopulateUsers();
  }

  setUserRolesExcept(roleType: string): Promise<void> {
    return this.userRoleService
      .getRolesExcept(roleType)
      .then((roles) => {
        this.roles = roles;
        this.PopulateUsers();
      })
      .catch(async (err) => {
        this.logService.error(
          'lab-technician-card.component',
          'setUserRolesExcept',
          (await this.translationsService.get('ERROR_IN_GETTING_ROLES')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_ROLES')
        );
      });
  }

  closeModal(): void {
    this.labTechnician = {
      name: {}
    };
    $('#add-lab-technician-modal').modal('hide');
  }
}
