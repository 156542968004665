import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import template from './logistics-customer.html';
import { SubNavService } from '../../../../services/sub-nav/sub-nav.service';
import { TranslationsService } from '../../../../services/translations/translations.service';
import { LogisticsCustomerGraphqlService } from '../../../../graphql-services/logistics-customer/logistics-customer.graphql.service';
import { ConnectedClinics } from '../../../../models/logistic.model';
import { TransportClinic } from '../../../../models/transport-clinic.model';
import { sortBy, cloneDeep, uniqBy } from 'lodash';
import { SpinnerComponent } from '../../../../newmodules/spinner/spinner.component';


@Component({
  selector: 'logistics-customer',
  template
})
export class LogisticsCustomerComponent implements OnInit, AfterViewInit {
  @ViewChild('spinner') spinner: SpinnerComponent;
  labsConnectedClinics: ConnectedClinics[];
  copyOfLabaConnectedClinics: ConnectedClinics[];
  transportClinics: TransportClinic[];
  labs = [];
  constructor(
    private readonly translationsService: TranslationsService,
    private readonly subNavService: SubNavService,
    private readonly logisticsCustomerGraphqlService: LogisticsCustomerGraphqlService,
  ) {}

  ngAfterViewInit(): void {
    this.spinner.show();
  }

  async ngOnInit(): Promise<void> {
    await this.addMenu();
    this.labsConnectedClinics = await this.logisticsCustomerGraphqlService.getLabsConnectedClinics();
    this.copyOfLabaConnectedClinics = cloneDeep(this.labsConnectedClinics);
    this.labs = this.copyOfLabaConnectedClinics.map((data) => {
      return { _id: data.labId, name: data.name };
    });
    this.labs = uniqBy(this.labs, '_id');
    this.transportClinics = await this.logisticsCustomerGraphqlService.getTransportClinics();
    this.transportClinics = sortBy(this.transportClinics, [(clinic) => {
      return clinic.name.toLowerCase() || '';
    }]);
    this.spinner.hide();
  }

  async addMenu(): Promise<void> {
    this.subNavService.resetList();
    this.subNavService.addMenu({
      name: await this.translationsService.get('CONNECT_CLINICS'),
      state: 'app.logistics-customer',
      icon: 'fa-share-alt',
      isActive: true,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('TRANSPORT_CLINICS'),
      state: 'app.transport-Clinics',
      icon: 'fa-ambulance',
      isActive: false,
      isScrollSpy: false
    });
  }

  onLabSelect(labId) {
    this.spinner.show();
    this.labsConnectedClinics = cloneDeep(this.copyOfLabaConnectedClinics);
    if (labId) {
      this.labsConnectedClinics = this.labsConnectedClinics.filter((connected) => {
        return connected.labId === labId;
      });
    }
    this.spinner.hide();
  }

  toggleList(isConnected) {
    this.spinner.show();
    if (isConnected) {
      this.labsConnectedClinics = this.labsConnectedClinics.filter((connected) => {
        return connected.isLocal;
      });
    } else {
      this.labsConnectedClinics = cloneDeep(this.copyOfLabaConnectedClinics);
    }
    this.spinner.hide();
  }
}
