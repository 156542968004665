import { Component, Input } from '@angular/core';
import { SubCategory } from '../../../../../../../../models/organization.model';
import template from './metal-design.html';

@Component({
  selector: 'metal-design',
  template
})
export class MetalDesignComponent {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedMetalDesign: string;
  @Input() metalDesigns: { name: string; imageUrl: string }[];
}
