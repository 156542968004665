import { Input, Output, EventEmitter, Component } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { PermissionService } from '../../services/core/permission.service';
import { GetAllUsersService } from '../../services/order-list/get-all-users.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { User } from '../../models/user.model';
import * as _ from 'lodash';

import template from './user-card-item.html';

@Component({
  selector: 'user-card-item',
  template: template
})
export class UserCardItemComponent {
  @Input() currentUser: User;
  @Input() user: User;
  @Input() index: number;
  @Output() onUserUpdate: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private userService: UsersService,
    private currentUserService: CurrentUserService,
    private toastr: ToastrService,
    private translationsService: TranslationsService,
    private logService: LogService,
    private permissionService: PermissionService,
    private getAllUsersService: GetAllUsersService
  ) {}

  // Method to change user status.
  toggleUserStatus(userId: string, userStatus: boolean): void {
    userStatus = !userStatus;
    const user = {
      id: userId,
      active: userStatus
    };
    this.userService
      .editUser(user)
      .then(async (result) => {
        this.user.active = userStatus;
        this.getAllUsersService.clearUsersData(); // to clear cached users data
        this.toastr.success(
          await this.translationsService.get('USER_STATUS_UPDATED')
        );
      })
      .catch(async (err) => {
        this.logService.error(
          'users-card-item.component',
          'toggleUserStatus',
          (await this.translationsService.get('ERROR_IN_UPDATE_USER')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_UPDATE_USER')
        );
      });
  }

  // Set a user
  async setUser(): Promise<void> {
    if (!this.user.active) {
      this.logService.error(
        'users-card-item.component',
        'setUser',
        await this.translationsService.get('USER_IS_INACTIVE')
      );
      this.toastr.error(await this.translationsService.get('USER_IS_INACTIVE'));
      return;
    }
    this.userService
      .loginAs({ id: this.user._id })
      .then((result) => {
        if (result && result.user && result.token) {
          this.currentUserService.login(
            result.token,
            result.settings,
            result.user
          );
        }
      })
      .catch(async (err) => {
        this.logService.error(
          'users-card-item.component',
          'setUser',
          (await this.translationsService.get('ERROR_IN_GETTING_APP_VERSION')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_APP_VERSION')
        );
      });
  }

  // on edit user
  editUser(): void {
    if (this.onUserUpdate) {
      this.onUserUpdate.emit(_.cloneDeep(this.user));
    }
  }

  isSuperAdmin(user: User): boolean {
    return this.permissionService.isSuperAdmin(user);
  }
}
