import * as _ from 'lodash';
import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { Organization } from '../../models/organization.model';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';

import template from './invoice-settings.html';
import style from './invoice-settings.less';

@Component({
  selector: 'invoice-settings',
  template: template,
  styles: [style]
})
export class InvoiceSettingsComponent implements OnInit {
  @Input() lab: Organization;
  @Output() toggleInvoicing: EventEmitter<any> = new EventEmitter<any>();
  invoicingSettingName: string =
    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.INVOICING_MODULE;
  isInvoicingEnabled: boolean = false;
  invoicingSettingIndex: number;
  invoiceTabSelected: string = 'invoiceInfo';

  constructor() {}

  ngOnInit(): void {
    const self = this;
    if (this.lab) {
      this.invoicingSettingIndex = this.lab.customerSettings.findIndex((setting) => {
        return setting.name === self.invoicingSettingName;
      });
      if (this.invoicingSettingIndex > -1) {
        this.isInvoicingEnabled =
          this.lab.customerSettings[this.invoicingSettingIndex].value === 'true' ||
          ((this.lab.customerSettings[this.invoicingSettingIndex]
            .value as any) as boolean) === true;
      }
    }

    if (_.isEmpty(this.lab.invoicing)) {
      this.lab.invoicing = {};
    }
  }

  toggleInvoice(event: any): void {
    this.isInvoicingEnabled = event;
    this.toggleInvoicing.emit(event);
  }
}
