import { Injectable } from '@angular/core';
import { OrderMessage, Order } from '../../models/order.model';

@Injectable()
export class GetAllOrdersService {
  private ordersData: { orders: Order[]; pageNo: number } = null;
  private unreadMessagesData: { data: OrderMessage[] } = null;

  setOrderData(orderData: Order[], pageNo: number): void {
    this.ordersData = { orders: orderData, pageNo: pageNo };
  }

  clearData(): void {
    this.ordersData = null;
  }

  setUnreadMessages(messageData: { data: OrderMessage[] }): void {
    this.unreadMessagesData = messageData;
  }

  clearUnreadMessagesData(): void {
    this.unreadMessagesData = null;
  }

  getOrderData(): { orders: Order[]; pageNo: number } {
    return this.ordersData;
  }

  getUnreadMessagesDataData(): { data: OrderMessage[] } {
    return this.unreadMessagesData;
  }
}
