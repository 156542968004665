/**
 * login user service service
 */
(function () {
    'use strict';
    angular.module('app').service('customerYearPrefixService', customerYearPrefixService);
    customerYearPrefixService.$inject = [ '$q', 'customerService', '$filter' ];
    function customerYearPrefixService($q, customerService, $filter) {
        return {
            customerYearPrefix: customerYearPrefix
        };

        function customerYearPrefix(pass, validPin, patient) {
            var deferred = $q.defer();

            var valid19 = '', valid20 = '';
            var presentYear = new Date().getFullYear().toString().substr(-2);

            var error = false;
            // check if number have alphabet then convert it to upper case
            if (pass && pass.match(/[a-z]/i)) {
                pass = pass.toUpperCase();
            }
            var input = pass;

            if (input && (input.length >= 12) && ((input.startsWith("19")) || (input.startsWith("20")))) {
                validPin = customerService.validatePersonalNumber(pass);
                patient.personalIdentity = $filter('identity')(pass);

                // Recall service to check again the number is valid or not, after filter
                validPin = customerService.validatePersonalNumber(patient.personalIdentity);
            } else {
                if (input && !(input.startsWith("19"))) {
                    if (input.length >= 2 && input.substring(0, 2).trim() > parseInt(presentYear)) {
                        valid19 = customerService.validatePersonalNumber(!input.startsWith("19") ? "19" + input : input);
                    }
                }
                if (input && !(input.startsWith("20"))) {
                    if (input.length >= 2 && input.substring(0, 2).trim() <= parseInt(presentYear)) {
                        valid20 = customerService.validatePersonalNumber(!input.startsWith("20") ? "20" + input : input);
                    }
                }
                if (valid19 && valid20) {
                    validPin = false;
                    error = true;
                }
                if (valid19 && !valid20) {
                    patient.personalIdentity = $filter('identity')("19" + pass);
                    // Recall service to check again the number is valid or not, after filter
                    validPin = customerService.validatePersonalNumber(patient.personalIdentity);
                }
                if (!valid19 && valid20) {
                    patient.personalIdentity = $filter('identity')("20" + pass);
                    // Recall service to check again the number is valid or not, after filter
                    validPin = customerService.validatePersonalNumber(patient.personalIdentity);
                }
                if (!valid19 && !valid20) {
                    validPin = false;
                }
            }

            if (error) {
                deferred.reject('error: ', error);
            } else {
                deferred.resolve(validPin);
            }
            return deferred.promise;

        }
    }
})();