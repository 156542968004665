import { Action, PriceList } from './action.model';
import { CustomerSetting } from './customer-setting.model';
import { LabClient } from './client-directory';
import { MaterialGroups } from './material-group.model';

interface OrgAddress {
  street?: string;
  city?: string;
  // TODO: Should this really be a number?
  postal_code?: number;
  country?: string;
}

export interface OrgContact {
  phone?: string;
  address?: OrgAddress;
  email?: string;
  site?: string;
}

interface OrgInvoice {
  street?: string;
  city?: string;
  postal_code?: string;
  costCenter?: string;
  email?: string;
}

interface PaymentDetails {
  postgiro?: string;
  bankgiro?: string;
  bicSwift?: string;
  iban?: string;
}

export enum InvoicePaymentMethod {
  COLLECTIVE = 'COLLECTIVE',
  COLLECTIVE_DENTIST = 'COLLECTIVE_DENTIST',
  PER_ORDER = 'PER_ORDER'
}

enum InvoiceNumberType {
  MANUAL = 'MANUAL',
  INCREMENTAL = 'INCREMENTAL',
  FORTNOX = 'FORTNOX',
  VISMA = 'VISMA'
}

interface InvoiceConfiguration {
  invoiceNumberType?: InvoiceNumberType;
  startNumber?: number;
  currentStartingNumber?: number;
  monthlyInvoiceDay?: number;
}

interface InvoiceClinic {
  clinicId?: string;
  paymentTerms?: number;
  method?: InvoicePaymentMethod;
  invoice?: boolean;
}

interface Invoicing {
  organizationNo?: string;
  vatNo?: string;
  invoiceAddress?: OrgInvoice;
  contactInfo?: OrgContact;
  paymentDetails?: PaymentDetails;
  configuration?: InvoiceConfiguration;
  clinics?: InvoiceClinic[];
}

interface SyncUserProperties {
  name: {
    firstName: string;
    lastName: string;
  };
  email: string;
}

interface SyncUser {
  id: string;
  properties: SyncUserProperties;
}

interface OrgUser {
  user: string;
  role: string;
}

interface Statistics {
  clinicId: string;
  numOrders: number;
  ratings: any;
}

interface OrgContactinfo {
  phone?: string;
  web?: string;
  email?: string;
}

interface OrgExternalInfo {
  externalId1?: string;
  externalId2?: string;
}

export interface MaterialGroup {
  _id: string;
  name: string;
  materials: [
    {
      _id?: string;
      material: string;
    }
  ];
  actionSet: string;
}

export interface SubCategory {
  _id?: string;
  name: string;
  selected?: boolean;
  colorCode: string;
  textColorCode: string;
  parentCategoryId: string;
}

export interface Category {
  _id?: string;
  name: string;
  colorCode: string;
  textColorCode: string;
  instructions: string;
  subCategories: SubCategory[];
}

export interface MaterialPriceDifference {
  materialGroupId?: string;
  materialGroupName?: string;
  materials: {
    material: string;
    materialName: string;
    priceDiff: number;
  }[];
  actionSet: string;
}

export interface ConnectedLab {
  lab: Organization;
  _id?: string;
  isDisabled: boolean;
  isRequireException: boolean;
  priceList: PriceList[];
  materialPriceDifference: MaterialPriceDifference[];
  connectedDate: number;
}
export interface Material {
  _id?: string;
  material?: string;
  code: string;
  description: string;
  price: number;
  vat: number;
  createdOn: string;
  batchNumber?: string;
  inactive?: boolean;
  inactiveDate?: number;
  autoReduction?: boolean;
  quantity?: number;
  discountedPrice?: number;
}

export interface ActionNew {
  _id?: string;
  categoryId?: string;
  code: string;
  description: string;
  price: number;
  vat: number;
  createdOn: string;
  inactive?: boolean;
  inactiveDate?: number;
  discountedPrice?: number;
  quantity?: number;
  action?: string;
  actionObj?: any;
  fullActionName?: string;
}

export interface MaterialList {
  _id: string;
  name?: string;
  description?: string;
  materials: Material[];
  generalDiscount?: number | string;
  materialListId?: string;
}

export interface CategoryNew {
  _id?: string;
  name?: string;
}

export interface ActionList {
  _id: string;
  name?: string;
  description?: string;
  categories: CategoryNew[];
  actions: ActionNew[];
  generalDiscount?: number | string;
  actionListId?: string;
  categoryId?: string;
}

export interface Hours {
  day: string;
  time: {
    from: number;
    to: number;
  };
  active: boolean;
}

export interface AssociatedMaterialList {
  _id: string;
  materialListId: string;
  generalDiscount: number | string;
  materials: Material[];
  lab: string;
}

export interface AssociatedActionList {
  _id: string;
  actionListId: string;
  generalDiscount: number | string;
  actions: ActionNew[];
  lab: string;
  categoryId: string;
  categories: CategoryNew[];
}

export interface Organization {
  _id?: string;
  name: string;
  countryCode: string;
  countryName?: string;
  organizationNumber: string;
  gln: string;
  type: string;
  local?: boolean;
  localLabOwner?: string;
  isNotPickUpAndDeliveryPlace: boolean;
  isPickUpClinic: boolean;
  relatedClinics?: any;
  deliveryInstructions?: string;
  identity: string;
  contact: OrgContact;
  invoice: OrgInvoice;
  contactInfo: OrgContactinfo;
  externalInfo: OrgExternalInfo;
  users: OrgUser[];
  parentClinicId: string;
  active: boolean;
  shortDescription: string;
  longDescription: string;
  defaultJobs: {
    name: string;
    deliveryDays: number;
    active: boolean;
  }[];
  currentJobs: {
    name: string;
    deliveryDays: number;
    active: boolean;
  }[];
  logoImage: string;
  staffImage: string;
  documents: {
    _id: string;
    document: any;
  }[];
  selectedDocs: {
    document: any;
  }[];
  signedDocuments: {
    document: any;
  }[];
  defaultHours: Hours[];
  openingHours: Hours[];
  isRemoved: boolean;
  holidayDays: number[];
  activeDates: any;
  unavailableDates: any;
  unavailableLabDates: number[];
  connectedLabs: ConnectedLab[];
  export: {
    enable: boolean;
    additionalDeliveryDays: number;
  };
  publishLab: boolean;
  emailNotifications: {
    type: string;
    otherEmail: string;
    newOrder: boolean;
    newMessage: boolean;
    orderModified: boolean;
    orderRated: boolean;
    orderCancelled: boolean;
    orderResend: boolean;
  };
  generalNotifications: {
    newOrder: boolean;
    newMessage: boolean;
    orderModified: boolean;
    orderResend: boolean;
  };
  created: {
    on: number;
    by: string;
  };
  updated: {
    on: number;
    by: string;
  };
  settings: any;
  syncUsers: SyncUser[];
  materialGroups: MaterialGroup[];
  categories: Category[];
  actions: Action[];
  customerSettings: CustomerSetting[];
  totalRatings: number;
  satisfiedRatings: number;
  statistics: Statistics[];
  invoicing: Invoicing;
  clinicInvoicing: {
    [key: string]: InvoiceClinic;
  };
  crediFlow: {
    partyID: number;
  };
  isvalidOpeningHoursTime: boolean;
  companyEmail: string;
  selected: boolean;
  defaultMaterialListId: string;
  materialLists: MaterialList[];
  defaultActionListId: string;
  actionLists: ActionList[];
  associatedMaterialList: AssociatedMaterialList[];
  labAssociatedMaterialListForClient: AssociatedMaterialList;
  associatedActionList: AssociatedActionList[];
  labAssociatedActionListForClient: AssociatedActionList;
  isTransportEnable?: boolean;
  hexCode?: string;
  textCode?: string;
  clinicName?: string;
  clientSettings: LabClient[];
}

export interface ActionSet {
  categories: Category[];
  actions: Action[],
  materialGroups: MaterialGroups[],
}
