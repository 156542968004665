import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

export enum PersonalNumberFormat {
  SHORT_DASH,
  FULL_DASH,
  SHORT_NODASH,
  FULL_NODASH
}

@Injectable()
export class CustomerService {
  isBirthNumberValid(
    birthdate: string,
    birthnumber: string,
    allowCoordinationNumber: boolean = true
  ): boolean {
    if (isNaN(Number(birthnumber))) {
      // If birthnumber is not parseable as a number, return valid only if 4 capital letters. Otherwise invalid.
      return allowCoordinationNumber && /^[A-Z]{4}$/.test(birthnumber);
    } else if (!/^[0-9]{4}$/.test(birthnumber)) {
      // If birthnumber is parseable as a number, make sure it is between 0000 to 9999
      return false;
    }

    const control = birthnumber.slice(4);

    const numbers = (birthdate + birthnumber)
      .split('')
      .map((s) => Number(s))
      .reverse();

    const checksum = numbers.reduce((result, n, i) => {
      const isEven = (i + 1) % 2 === 0;
      const sum = isEven ? n * 2 : n;
      return result + (sum > 9 ? sum - 9 : sum);
    }, 0);

    return checksum % 10 === 0;
  }

  getPersonalNumber(input: string): { birthdate: Moment; birthnumber: string } {
    let birthdate: Moment;
    let birthdateStr: string;
    let birthnumberStr: string;

    if (input.indexOf('-') > -1) {
      // XXXXXXXX-NNNN, XXXXXX-NNNN
      [birthdateStr, birthnumberStr] = input.split('-');
      birthdate = moment(
        birthdateStr,
        birthdateStr.length === 8 ? 'YYYYMMDD' : 'YYMMDD'
      );
    } else if (input.length === 10) {
      // XXXXXXNNNN
      birthdateStr = input.slice(0, 6);
      birthdate = moment(birthdateStr, 'YYMMDD');
      birthnumberStr = input.slice(6);
    } else if (input.length === 12) {
      // XXXXXXXXNNNN
      birthdateStr = input.slice(0, 8);
      birthdate = moment(birthdateStr, 'YYYYMMDD');
      birthnumberStr = input.slice(8);
    } else {
      throw new Error('Unsupported date format!');
    }

    return { birthdate, birthnumber: birthnumberStr };
  }

  validatePersonalNumber(
    input: string,
    allowCoordinationNumber: boolean = true
  ): boolean {
    // Check valid length & form
    if (!input) {
      return false;
    }

    try {
      const personalNumber = this.getPersonalNumber(input);
      return (
        personalNumber.birthdate.isValid() &&
        this.isBirthNumberValid(
          personalNumber.birthdate.format('YYMMDD'),
          personalNumber.birthnumber,
          allowCoordinationNumber
        )
      );
    } catch (error) {
      // console.error("Error validating personal number!", error);
      return false;
    }
  }

  formatPersonalNumber(input: string, format: PersonalNumberFormat): string {
    const personalNumber = this.getPersonalNumber(input);

    switch (format) {
      case PersonalNumberFormat.SHORT_DASH:
        return (
          personalNumber.birthdate.format('YYMMDD') +
          '-' +
          personalNumber.birthnumber
        );
      case PersonalNumberFormat.SHORT_NODASH:
        return (
          personalNumber.birthdate.format('YYMMDD') + personalNumber.birthnumber
        );
      case PersonalNumberFormat.FULL_DASH:
        return (
          personalNumber.birthdate.format('YYYYMMDD') +
          '-' +
          personalNumber.birthnumber
        );
      case PersonalNumberFormat.FULL_NODASH:
        return (
          personalNumber.birthdate.format('YYYYMMDD') +
          personalNumber.birthnumber
        );
    }
  }

  validateEmail(input: string): boolean {
    if (input) {
      const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegExp.test(String(input).toLowerCase());
    }
  }
}
