import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../models/user.model';
import { TranslationsService } from '../../services/translations/translations.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { SessionService } from '../../services/core/session.service';
import { LogService } from '../../services/core/log.service';
import { UsersService } from '../../services/users/users.service';
import { TokenService } from '../../services/core/token.service';

import template from './account-setting.html';
import style from './account-setting.less';
import { LanguageCode } from '../../models/language.model';
import { CustomerService } from '../../services/customer/customer.service';
import { StateService } from '@uirouter/angularjs';
import { NewFrontendNavigateService } from '../../services/new-frontend-navigate/new-frontend-navigate.service';

@Component({
  selector: 'account-setting',
  template: template,
  styles: [style]
})
export class AccountSettingComponent implements OnInit {
  @Input() currentUser: User;
  selectedSetting: string = 'account';
  previousLanguage: string;
  accountSettingLabValid: boolean = true;

  constructor(
    private toastr: ToastrService,
    private tokenService: TokenService,
    private usersService: UsersService,
    private logService: LogService,
    private sessionService: SessionService,
    private currentUserService: CurrentUserService,
    private translationsService: TranslationsService,
    private customerService: CustomerService,
    private navigateService: NewFrontendNavigateService,
    @Inject(forwardRef(() => '$state')) private readonly $state: StateService
  ) {}

  ngOnInit(): void {
    if (!this.currentUser.systemSetting) {
      this.currentUser.systemSetting = {};
    }
    if (!this.currentUser.constructionSetting) {
      this.currentUser.constructionSetting = {};
    }
    if (!this.currentUser.emailNotifications.type) {
      this.currentUser.emailNotifications.type = 'user';
    }

    if (
      this.currentUser.generalNotifications &&
      !this.currentUser.generalNotifications.orderNotReceived.days
    ) {
      this.currentUser.generalNotifications.orderNotReceived.days = 5;
    }

    if (this.$state.params.tab) {
      this.selectedSetting = this.$state.params.tab.split('?')[0];
    }
  }

  async updateUser(uploadEvent: any): Promise<void> {
    const self = this;
    const userId = this.tokenService.getPayload().user;

    this.currentUser.id = userId;

    if (uploadEvent && uploadEvent.fileId) {
      this.currentUser.profileImage = uploadEvent.fileId;
    }

    return this.usersService
      .editProfile(self.currentUser)
      .then(async (response) => {
        if (uploadEvent) {
          self.toastr.success(
            await self.translationsService.get('IMAGE_SUCCESSFULLY_UPLOADED')
          );
        } else {
          self.toastr.success(
            await self.translationsService.get('SUCCESSFULLY_UPDATED')
          );
        }
        if (self.currentUser.systemSetting.locale) {
          self.sessionService.setItem(
            'locale',
            self.currentUser.systemSetting.locale
          );
        }
        if (self.currentUser.systemSetting.language) {
          // if language is provided then use that else we will use default english
          self.translationsService.use(self.currentUser.systemSetting.language);
          self.previousLanguage = self.sessionService.getItem('language');
          self.sessionService.setItem(
            'language',
            self.currentUser.systemSetting.language
          );
        } else {
          // use default swedish
          self.translationsService.use(LanguageCode.SE);
          self.sessionService.setItem('language', LanguageCode.SE);
        }

        return this.currentUserService.updateCurrentUser();
      })
      .then((user) => {
        self.currentUser = user;
        if (self.previousLanguage !== self.currentUser.systemSetting.language) {
          window.location.reload();
        }
      })
      .catch(async (err) => {
        self.logService.error(
          'account-setting.component',
          'updateUser',
          (await self.translationsService.get('ERROR_IN_UPDATE_USER')) + ': ' + err
        );
        self.toastr.error(
          await self.translationsService.get('ERROR_IN_UPDATE_USER')
        );
      });
  }

  checkOrderReceivedDays(): boolean {
    if (
      this.currentUser &&
      this.currentUser.generalNotifications.orderNotReceived.active
    ) {
      if (
        this.currentUser.generalNotifications.orderNotReceived.days &&
        this.currentUser.generalNotifications.orderNotReceived.days > 0
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  // Checking other email field filled or not for dentist user
  isOtherTypeEmail(): boolean {
    if (
      this.currentUser &&
      this.currentUser.emailNotifications &&
      this.currentUser.emailNotifications.type &&
      this.currentUser.emailNotifications.type === 'other' &&
      !this.currentUser.emailNotifications.otherEmail
    ) {
      return true;
    }
    return false;
  }

  navigate(urlSegment: string) {
    this.navigateService.navigate(urlSegment);
  }
}
