import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import template from './three-shape-scan-view.html';
import style from './three-shape-scan-view.less';

@Component({
  selector: 'three-shape-scan-view',
  template: template,
  styles: [style]
})
export class ThreeShapeScanViewComponent  {

}