import {
  Input,
  forwardRef,
  Inject,
  Component,
  Output,
  EventEmitter
} from '@angular/core';
import template from './menu-list.html';
import { OrganizationSettingService } from '../../services/organization-setting/organization-setting.service';
import { PermissionService } from '../../services/core/permission.service';
import { CurrentUserService } from '../../services/users/current-user.service';
import { EventService } from '../../services/core/event.service';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { User } from '../../models/user.model';
import { SessionService } from '../../services/core/session.service';

@Component({
  selector: 'menu-list',
  template
})
export class MenuListComponent {
  @Output() menuOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() currentUser: User;
  currentUserPermissions: any = {};
  isMenuOpen = true;
  reactAppUrl = process.env.NEW_APP_URL;
  // TODO: Remove, only for feature toggle
  nodeEnv = process.env.NODE_ENV;
  constructor(
    public permissionService: PermissionService,
    private readonly currentUserService: CurrentUserService,
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly eventService: EventService,
    private readonly sessionService: SessionService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.eventService.on('userPermissionsSet', () => {
      this.currentUserPermissions = this.currentUserService.getPermissions();
    });

    this.currentUserPermissions = this.currentUserService.getPermissions();
  }

  goToState(state: string): void {
    this.$state.go(state);
  }

  goToNewOrder(user) {
    this.$state.go(this.getOrderState(user));
  }

  isActionBasedOrder(): boolean {
    return (
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.ACTION_BASED ===
      this.organizationSettingService.getCustomerSetting(
        CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION
      )
    );
  }

  isInvoicingEnabled(): boolean {
    return this.organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.INVOICING_MODULE
    );
  }

  isDentist(user: User): boolean {
    return this.permissionService.isDentist(user);
  }

  isLabTechnician(user: User): boolean {
    return this.permissionService.isLabTechnician(user);
  }

  isSuperAdmin(user: User): boolean {
    return this.permissionService.isSuperAdmin(user);
  }

  isExternalTechnician(user: User): boolean {
    return this.permissionService.isExternalTechnician(user);
  }

  menuChange(isMenuOpen) {
    this.menuOpened.emit(isMenuOpen);
  }

  private getOrderState(user) {
    if (this.permissionService.isSuperAdmin(user)) {
      return CUSTOMER_SETTINGS_CONSTANTS.ORDER_STATES.NEW_ORDER;
    }
    const orderVersionSetting = this.organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION
    );
    switch (orderVersionSetting) {
      case CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.SIMPLE:
        return CUSTOMER_SETTINGS_CONSTANTS.ORDER_STATES.NEW_ORDER;
      case CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_VALUES.ACTION_BASED:
        return CUSTOMER_SETTINGS_CONSTANTS.ORDER_STATES.NEW_ORDER2;
      default:
        return CUSTOMER_SETTINGS_CONSTANTS.ORDER_STATES.NEW_ORDER;
    }
  }
}
