import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FileItem } from 'ng2-file-upload';
import template from './file-upload.html';
import { UploadedFile } from '../../../../../../models/uploaded-file.model';
import { ImageAnnotationService } from '../../../../../../services/image-annotation/image-annotation.service';
import { TranslationsService } from '../../../../../../services/translations/translations.service';
import { LogService } from '../../../../../../services/core/log.service';
import { FileUploadService } from '../../../../../../services/file/file-upload.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'file-upload',
  template
})
export class FileUploadComponent {
  uploadingDocs: any[] = [];
  @Input() files: any[] = [];
  @Input() directory: string;
  @Output() filesUploaded: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private readonly imageAnnotationService: ImageAnnotationService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService,
    private readonly fileUploadService: FileUploadService,
    @Inject('fileService') private readonly aJsFileService: any
  ) {}

  docBeforeUpload(file: FileItem): void {
    this.uploadingDocs.push({
      progress: 10,
      document: {
        name: file.file.name
      }
    });
  }

  async docProgress(data: { file: FileItem; progress: number }): Promise<void> {
    try {
      const uploadingDocument = this.uploadingDocs.find(
        (doc) => doc.document.name === data.file.file.name
      );

      uploadingDocument.progress = data.progress;
    } catch (e) {
      console.error("Can't set file progress!", e);
    }
  }

  removeFile(id: string) {
    const indexToRemove = this.files.findIndex((d) => d.id === id);

    if (indexToRemove > -1) {
      this.files.splice(indexToRemove, 1);
    }

    this.filesUploaded.emit(this.files);
  }

  async docUploaded(file: UploadedFile, error: string): Promise<void> {
    if (error) {
      console.error('Error uploading document!', error);
      throw new Error('Error uploading document!');
    }

    const uploadDocIndexToRemove = this.uploadingDocs.findIndex(
      (uploadingDoc) => uploadingDoc.name === file.name
    );
    this.uploadingDocs.splice(uploadDocIndexToRemove);
    this.files.push(
      Object.assign(file.signedUrl, {
        isImage: /image/.test(file.contentType)
      })
    );
    this.filesUploaded.emit(this.files);
  }

  onAddDrawImageClick() {
    $('#add-image-modal').modal('show');
  }

  onAddDrawImage(addFile) {
    this.imageAnnotationService.showMarkerArea(
      addFile.elementId,
      async (err, dataUrl) => {
        $('#add-image-modal').modal('hide');
        if (dataUrl) {
          const fileObj = {
            name: addFile.filename,
            type: 'image/png',
            imageBinary: dataUrl
          };
          await this.uploadImage(fileObj, true);
        }
      }
    )
  }

  async editImageAndUpload(file) {
    try {
      this.imageAnnotationService.showMarkerArea(file.id, async (err, dataUrl) => {
        if (dataUrl) {
          fetch(dataUrl)
            .then((res) => res.blob())
            .then(async (fileBlob) => {
              const fileObj = this.blobToFile(fileBlob, file.name);
              await this.uploadImage(fileObj, false);
            });
        }
      });
    } catch (err) {
      this.toastr.error(
        await this.translationsService.get('ERROR_IN_UPLOAD_FILE')
      );
      this.logService.error('file-upload.component', 'editImageAndUpload', err);
    }
  }

  blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type
    });
  }

  uploadImage(file, isBase64) {
    return this.aJsFileService.uploadFile(file, this.directory, isBase64 ? isBase64 : undefined)
      .then((resp) => {
        if (resp?.data?.file) {
          this.files.push(
            Object.assign(resp.data.file.signedUrl, {
              isImage: /image/.test(resp.data.file.contentType)
            })
          );
          this.filesUploaded.emit(this.files);
        }
      });
  }
}
