import {
  Input,
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import template from './constructions.html';
import { User } from '../../../../../models/user.model';
import { Action } from '../../../../../models/action.model';
import { PermissionService } from '../../../../../services/core/permission.service';
import { OrganizationSettingService } from '../../../../../services/organization-setting/organization-setting.service';
import { ConstructionPageService } from '../../../services/construction-page-service';
import { Category, SubCategory } from '../../../../../models/organization.model';
import { clone, sortBy, uniq } from 'lodash';
import { ConstructionService } from '../../../services/construction-service';
import { OrderSections } from '../../../../../models/order.model';
import {
  CUSTOMER_SETTINGS_CONSTANTS,
  ORDER_2_CONSTRUCTION
} from '../../../../../app.constant';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventService } from '../../../../../services/core/event.service';
import style from './construction.less';
import { ConstructionSettingsService } from '../../../services/construction-settings-service';
import { OrdersGraphqlService } from '../../../../../graphql-services/orders/orders.graphql.service';
import { TranslationsService } from '../../../../../services/translations/translations.service';
import { EscapedMessageService } from '../../../../../services/escaped-message/escaped-message.service';
@Component({
  selector: 'constructions',
  template,
  styles: [style]
})
export class ConstructionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currentUser: User;
  @Input() orderStatus: string;
  @Input() orderIdToModify: string;
  @Input() orderDbId: string;
  @Input() directory: string;
  @Input() additionalInfo: string;
  @Input() allAdditionalInfo: {
    additionalInfo: string;
    version: string;
    updatedBy: string;
    createdAt: string;
  }[] = [];
  @Input() sections: OrderSections;
  @Input() allConstructions: any = [];
  @Output() sectionChanged: EventEmitter<OrderSections> = new EventEmitter<
    OrderSections
  >();
  @Output() constructionInfoChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onThreeShapeModalOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() documentsUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Input() categoryTree: Category[] = [];
  @Input() files: any[] = [];
  @Input() attachedCaseFiles: any[] = [];
  @Input() isThreeShapeEnabled: boolean = false;
  @Input() isMeditEnabled: boolean = false;
  @Input() actions: Action[] = [];
  @Output() onOpenUnlinkDialog: EventEmitter<string> = new EventEmitter<string>();
  @Input() isDiscountedPrice: boolean;
  @Input() warrantyType: string;
  @Input() isWarrantyOrder: boolean;
  savedFiles: any[] = [];
  subCategories: SubCategory[] = [];
  selectedSubCategory: SubCategory;
  selectedMainCategory: Category;
  actionsOptionsData: Action[] = [];
  selectedActions: Action[] = [];
  isLabUser = false;
  isDentist = false;
  hasTeethOption = false;
  showTeethStructure = false;
  showConstructionSettings = false;
  constructionType: string;
  clickedJawType: string[] = [];
  constructionSettings: any;
  isCommentsMandatory: any;
  isConsBlockOpen: boolean;
  isStepDateChanged: boolean = false;
  // high value means high priority
  teethPriority = {
    [ORDER_2_CONSTRUCTION.TYPE.SINGLE_TOOTH]: 3,
    [ORDER_2_CONSTRUCTION.TYPE.RANGE]: 2,
    [ORDER_2_CONSTRUCTION.TYPE.UP_LOW_JAW]: 1
  };
  currentTeethOption = '';

  mandatoryCommentsSettingName =
    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.MANDATORY_COMMENTS;

  constructor(
    private readonly constructionPageService: ConstructionPageService,
    private readonly constructionSettingService: ConstructionSettingsService,
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly permissionService: PermissionService,
    private readonly eventService: EventService,
    private readonly ordersGraphqlService: OrdersGraphqlService,
    private readonly translationsService: TranslationsService,
    private readonly snackBar: MatSnackBar,
    private readonly constructionService: ConstructionService,
    private readonly escapedMessageService: EscapedMessageService,
  ) {}

  ngOnDestroy(): void {
    this.reset();
    this.constructionSettingService.resetSettings();
  }

  ngOnChanges(): void {
    if (this.additionalInfo) {
      this.additionalInfo = this.additionalInfo.replace(new RegExp('<br/>', 'gi'), '\n');
    }
  }

  async ngOnInit() {
    this.isCommentsMandatory = this.organizationSettingService.getCustomerSetting(
      this.mandatoryCommentsSettingName
    );
    this.isLabUser = this.permissionService.isLabTechnician(this.currentUser);
    this.isDentist = this.permissionService.isDentist(this.currentUser);
    this.eventService.on('stepDateChanged', () => {
      this.isStepDateChanged = true;
    });
  }

  getActionOptionsData(selectedMainCategory, selectedSubCategory) {
    return this.actions.filter((action) => {
      return action.subCategory?.some((subCategory) => {
        return (
          action.mainCategory.name === selectedMainCategory.name &&
          subCategory.name === selectedSubCategory.name
        );
      });
    });
  }

  openThreeShapeModal() {
    this.onThreeShapeModalOpen.emit();
  }

  categorySelected(category: Category) {
    this.selectedMainCategory = category;
    this.constructionSettingService.resetSettings();
    this.resetAllBelowCategory();
    this.subCategories = category.subCategories;
  }

  getActionOptions(subCategory: SubCategory) {
    const actionsOptions = Object.assign(
      [],
      this.getActionOptionsData(
        this.constructionPageService.selectedMainCategory,
        subCategory
      )
    );
    return sortBy(actionsOptions, 'number');
  }

  filesUploaded(documents) {
    this.savedFiles = documents;
    this.documentsUploaded.emit(documents);
  }

  subCategorySelected(subCategory: SubCategory) {
    this.selectedSubCategory = subCategory;
    this.constructionSettingService.resetSettings();
    this.resetAllBelowSubCategory();
    this.actionsOptionsData = this.getActionOptions(subCategory);
  }

  actionSelected(selectedActions: Action[]) {
    const previousTeethOption = this.currentTeethOption;
    this.selectedActions = Object.assign([], selectedActions);
    this.currentTeethOption = this.constructionPageService.getToothOption(
      this.selectedActions
    );
    this.clickedJawType = [];
    if (
      this.teethPriority[this.currentTeethOption] >
        this.teethPriority[previousTeethOption] ||
      !selectedActions.length
    ) {
      this.constructionPageService.resetTeeth();
      this.constructionPageService.resetTeethRange();
    }
    this.showHideTeethStructure(selectedActions);
  }

  showHideTeethStructure(selectedActions) {
    if (
      this.constructionPageService.selectedMainCategory &&
      this.constructionPageService.selectedSubCategory
    ) {
      this.constructionService.deleteDummyConstructions();
    }
    if (!selectedActions.length) {
      this.showTeethStructure = false;
      this.showConstructionSettings = false;
      return;
    }
    this.hasTeethOption = selectedActions.some((action) => action.teethOption);
    this.showTeethStructure = this.hasTeethOption;
    if (!this.isConsBlockOpen) {
      this.showConstructionSettings = !this.hasTeethOption;
    }
    if (this.showConstructionSettings) {
      this.constructionService.addSelectedConstructions(
        this.constructionPageService.selectedMainCategory?.name,
        this.constructionPageService.selectedSubCategory?.name,
        { colorCode: this.constructionPageService.selectedSubCategory.colorCode }
      );
      this.constructionSettings = this.constructionService.getConstructionSettings(
        selectedActions
      );
    }
  }

  showHideConstructionSettings(isVisible: boolean) {
    this.showConstructionSettings = isVisible;
  }

  teethStructureSelected(data: any) {
    this.constructionType = data.constructionType;
    this.constructionSettings = this.constructionService.getConstructionSettings(
      this.constructionPageService.selectedActions
    );
  }

  pushToConstruction(constructionCategoryObj) {
    Object.keys(constructionCategoryObj).forEach((key) => {
      this.allConstructions.push(constructionCategoryObj[key]);
    });
    this.allConstructions = sortBy(
      this.allConstructions.reduce((result, element) => result.concat(element), []),
      'createdAt'
    );
  }

  addToConstructions(selectedConstructions) {
    Object.keys(selectedConstructions)
      .filter((key) => key !== 'type')
      .forEach((key) => {
        this.pushToConstruction(selectedConstructions[key]);
      });
  }

  constructionSaved(selectedConstructions) {
    this.allConstructions = [];
    this.addToConstructions(selectedConstructions);
    const formattedAdditionalInfo = this.escapedMessageService.getEscapedMessage(
      this.additionalInfo
    );
    this.constructionInfoChanged.emit({
      constructions: this.allConstructions,
      additionalInfo: formattedAdditionalInfo,
      // TODO: this is needed for a watch in delivery section,
      // when it is migrated, we can remove this
      selectedActions: this.selectedActions,
      isStepDateChanged: false
    });
    this.isConsBlockOpen = false;
    window.scrollTo(0, 0);
    this.reset();
  }

  constructionBlockOpened(construction) {
    this.reset();
    this.constructionType = construction.constructionType;
    this.clickedJawType = [];
    this.selectedMainCategory = construction.mainCategory;
    this.constructionPageService.selectedMainCategory = construction.mainCategory;
    this.subCategories = Object.assign([], construction.mainCategory.subCategories);
    const selectedSubCategory = this.subCategories.find(
      (subCategory) =>
        subCategory._id ===
        (construction.subCategory._id || construction.subCategory)
    );
    if (!selectedSubCategory) {
      return;
    }
    this.selectedSubCategory = selectedSubCategory;
    this.constructionPageService.selectedSubCategory = selectedSubCategory;
    this.actionsOptionsData = this.getActionOptions(selectedSubCategory);
    construction.actions.forEach((action) => {
      action.selected = true;
    });
    this.currentTeethOption = this.constructionPageService.getToothOption(
      construction.actions
    );
    this.constructionPageService.selectedActions = construction.actions;
    this.constructionSettings = this.constructionService.getConstructionSettings(
      construction.actions
    );
    if (construction.teethNo?.length) {
      this.constructionPageService.selectedTeeth = clone(construction.teethNo);
      this.constructionPageService.selectedTeethRange = {};
    } else if (construction.teethRange?.start && construction.teethRange?.end) {
      this.constructionPageService.selectedTeeth = [];
      this.constructionPageService.selectedTeethRange = {
        start: construction.teethRange.start,
        end: construction.teethRange.end
      };
    } else {
      // do nothing
    }
    this.isConsBlockOpen = true;
    this.hasTeethOption = construction.actions.some((action) => action.teethOption);
    this.showConstructionSettings = true;
    this.showTeethStructure = this.hasTeethOption;
    if (construction.teethRange?.start === 18) {
      this.clickedJawType.push('upper');
    }
    if (construction.teethRange?.end === 38) {
      this.clickedJawType.push('lower');
    }
  }

  constructionRemoved() {
    this.allConstructions = [];
    this.addToConstructions(this.constructionService.selectedConstructions);
    this.reset();
  }

  resetAllBelowCategory() {
    this.subCategories = [];
    this.constructionPageService.resetSubCategory();
    this.selectedSubCategory = null;
    this.resetAllBelowSubCategory();
  }

  resetAllBelowSubCategory() {
    this.constructionPageService.resetActions();
    this.selectedActions = [];
    this.constructionPageService.resetTeeth();
    this.constructionPageService.resetTeethRange();
    this.constructionType = '';
    this.isConsBlockOpen = false;
    this.showTeethStructure = false;
    this.showConstructionSettings = false;
  }

  reset() {
    this.currentTeethOption = '';
    this.constructionPageService.resetMainCategory();
    this.selectedMainCategory = null;
    this.resetAllBelowCategory();
  }

  previousPatient() {
    this.sections.showPatientSections = true;
    this.sections.showConstructionSections = false;
    this.reset();
    this.sectionChanged.emit(this.sections);
  }

  checkIfConstructionCreate() {
    return this.allConstructions.every((construct) => construct.createdAt);
  }

  checkForAdditionalInfo() {
    return (
      !this.isCommentsMandatory || (this.isCommentsMandatory && this.additionalInfo)
    );
  }

  getRankType(constructions) {
    const categoryArr = constructions.map(
      (construction) => construction.mainCategory._id
    );
    if (categoryArr.length === uniq(categoryArr).length) {
      return 'SIMPLE';
    }
    return 'CALCULATED';
  }

  createConstructionData(constructions) {
    const newConstructions = constructions.map((construction) => {
      // For some cases Converted numberOfUnits object to array.
      if (
        !construction.numberOfUnits?.length &&
        construction.numberOfUnits instanceof Object
      ) {
        construction.numberOfUnits = Object.keys(construction.numberOfUnits).map(
          (key) => {
            construction.numberOfUnits[key].actionId = key;
            return construction.numberOfUnits[key];
          }
        );
      }
      return {
        category: construction.mainCategory._id,
        subCategory: construction.subCategory._id || construction.subCategory,
        actions: construction.actions.map((action) => action._id),
        teethDetails: {
          isRange: !!(construction.teethRange && construction.teethRange.start),
          teethRange: construction.teethRange,
          toothNumber: construction.teethNo
        },
        addedMaterials: construction.addedMaterials || [],
        material: construction.material,
        color: construction.color,
        shade: construction.shade,
        colorSampling: construction.colorSampling,
        design: construction.design,
        contactType: construction.contactType,
        occlusion: construction.occlusion,
        expansionScrew: construction.expansionScrew,
        numberOfUnits: construction.numberOfUnits?.map((unit) => ({
          actionId: unit.actionId,
          header: unit.header,
          numberOfUnits: unit.numberOfUnits
        })),
        createdAt: construction.createdAt,
        warranty: construction.warranty || false,
        warrantyType: construction.warrantyType,
        dentureSteps: construction.dentureSteps,
        dentureDates: construction.dentureDates,
        dentureTimes: construction.dentureTimes,
        testingSteps: construction.testingSteps,
        testingDates: construction.testingDates,
        testingTimes: construction.testingTimes,
        implantSteps: construction.implantSteps,
        implantDates: construction.implantDates,
        implantTimes: construction.implantTimes,
        ponticDesign: construction.ponticDesign,
        retainment: construction.retainment,
        claspTeeth: construction.claspTeeth,
        claspMaterial: construction.claspMaterial,
        orthColor: construction.orthColor,
        facialLoop: construction.facialLoop,
        claspType: construction.claspType,
        springType: construction.springType,
        springTeeth: construction.springTeeth,
        protrusion: construction.protrusion,
        comment: construction.comment
      };
    });
    return {
      constructions: newConstructions
    };
  }

  createDataToSave(constructions, additionalInfo) {
    const formattedAdditionalInfo = this.escapedMessageService.getEscapedMessage(
      additionalInfo
    );
    return {
      newConstructions: this.createConstructionData(constructions),
      additionalInfo: formattedAdditionalInfo,
      rankType: this.getRankType(constructions),
      files: this.savedFiles.map((file) => ({ file: file.id }))
    };
  }

  async saveDraft() {
    if (!this.orderIdToModify) {
      const data = this.createDataToSave(this.allConstructions, this.additionalInfo);
      await this.ordersGraphqlService.saveConstructionsToDraft(this.orderDbId, data);
      this.snackBar.open(
        await this.translationsService.get('DRAFT_SAVED'),
        await this.translationsService.get('OK'),
        { duration: 5000, horizontalPosition: 'right' }
      );
    }
  }

  async nextLab() {
    if (
      this.allConstructions?.length > 0 &&
      this.checkIfConstructionCreate() &&
      this.checkForAdditionalInfo()
    ) {
      await this.saveDraft();
      this.sections.showPatientSections = false;
      this.sections.showConstructionSections = false;
      this.sections.showDeliverySections = true;
      const formattedAdditionalInfo = this.escapedMessageService.getEscapedMessage(
        this.additionalInfo
      );
      const data = {
        constructions: this.allConstructions,
        additionalInfo: formattedAdditionalInfo,
        // TODO: this is needed for a watch in delivery section,
        // when it is migrated, we can remove this
        selectedActions: this.selectedActions,
        isStepDateChanged: this.isStepDateChanged
      };
      this.constructionInfoChanged.emit(data);
      this.sectionChanged.emit(this.sections);
      this.reset();
      //scroll page on top
      window.scrollTo(0, 0);
    }
  }

  disableNextStep() {
    if (this.isCommentsMandatory && !this.additionalInfo) {
      return true;
    }
    if (this.allConstructions?.length) {
      return !this.checkIfConstructionCreate();
    }
    return true;
  }
}
