/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './reports.html'
(function () {
    'use strict';
    angular.module('app').component('reports', {
        template: theTemplate,
        controller: reportsController,
        controllerAs: 'vm',
        bindings: sessionStateBindings
    });

    reportsController.$inject = [ 'subNavService', '$scope', 'reportsService', 'formatReportService', 'toastr', 'DATE', '$filter', 'logService', 'categoryService', 'organizationService', 'permissionService', 'organizationSettingService', 'CUSTOMER_SETTINGS_CONSTANTS' ];

    function reportsController(subNavService, $scope, reportsService, formatReportService, toastr, DATE, $filter, logService, categoryService, organizationService, permissionService, organizationSettingService, CUSTOMER_SETTINGS_CONSTANTS) {
        var vm = this;
        subNavService.resetList();
        subNavService.addMenu({
            name: $filter('translate')('REPORTS'),
            state: 'app.reports',
            icon: 'fa-bar-chart',
            isActive: true,
            isScrollSpy: false
        });

        var currentDate = new Date();
        vm.defaultDateType = DATE.FORMAT;
        var DATE_SPAN = 4;
        var DAY_IN_MS = 24 * 3600 * 1000;
        var defaultStartDate = new Date().setMonth(currentDate.getMonth() - DATE_SPAN);
        var startDate = $filter('date')(defaultStartDate, vm.defaultDateType);
        vm.ceilDate = $filter('date')(currentDate, vm.defaultDateType);
        vm.floorDate = startDate;

        vm.reports = [];
        vm.reportData;
        vm.reportColumns;
        vm.showSpinner = false;
        vm.iterableReportData;
        vm.queryByReportId = '';
        vm.selectedReport;
        vm.reportInfo = {};
        vm.reportFilters = {};
        vm.statuses = [{
            name: "SENT_BY_DENTIST", selected: false, value: "sent_by_dentist"
        },{
            name: "ACCEPTED_BY_LAB", selected: false, value: "accepted_by_lab"
        },{
            name: "SENT_BY_LAB", selected: false, value: "sent_by_lab"
        },{
            name: "ORDER_APPROVED", selected: false, value: "order_approved"
        }];
        vm.reportInfo.dateFrom = startDate;
        vm.reportInfo.dateTo = vm.ceilDate;
        vm.createReportRequestData = {};
        vm.createReportRequestData.dateFrom = formatDateToUnixTimeInMs(startDate);
        vm.createReportRequestData.dateTo = formatDateToUnixTimeInMs(vm.ceilDate) + DAY_IN_MS;
        vm.orderVersion = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.ORDER_VERSION);
        vm.isWhiteLabelEnabled = organizationSettingService.getCustomerSetting(
          CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.WHITE_LABEL
        );

        // Methods for this controller
        vm.formatDateToUnixTimeInMs = formatDateToUnixTimeInMs;
        vm.formatCalendarDateString = formatCalendarDateString;
        vm.createReport = createReport;
        vm.selectReport = selectReport;
        vm.formatReport = formatReport;
        vm.getSelectedStatuses = getSelectedStatuses;
        vm.statusSelectionChanged = statusSelectionChanged;
        vm.getSelectedCategories = getSelectedCategories;
        vm.categorySelectionChanged = categorySelectionChanged;

        function statusSelectionChanged() {
            vm.selectedstatus = vm.statuses.filter(function (status) {
                return status.selected;
            }).map(function (status) {
                return status.value;
            });
        }

        function categorySelectionChanged() {
            vm.selectedCategories = vm.mainCategories.filter(category => category.selected)
                .map(category => category._id);
        }

        function getSelectedStatuses() {
            var status = vm.statuses.filter(function (status) {
                return status.selected;
            }).map(function (status) {
                return $filter('translate')(status.name);
            });

            return status.join(", ");
        }

        function getSelectedCategories() {
            const categories = vm.mainCategories.filter(category => category.selected)
                .map(category => `${$filter('translate')(category.name)} (${category.actionSet.title})`);

            return categories.join(", ");
        }

        vm.$onInit = function () {
            getReports();
            vm.permissions = vm.currentUser.permissions.permissions;
            vm.isLabTechnician = permissionService.isLabTechnician(vm.currentUser);
        };

        function getClinics() {
            organizationService.getDentistClinics({ id: vm.currentUser.organization._id }).then(function (result) {
                vm.clinics = result.data;
            }).catch(function (err) {
                logService.error('report.component', 'getClinics', $filter('translate')('ERROR_IN_GETTING_CLINICS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_CLINICS'));
            });
        }

        function getCategories() {
            categoryService.getAllMainCategories().then(function (response) {
                vm.mainCategories = response.data;
                if (!vm.isLabTechnician) {
                    getClinics();
                }
            }).catch(function (err) {
                toastr.error('Error in getting categories');
                logService.error('reports.component', 'getCategories', err);
            });
        }

        function getReports() {
            reportsService.getReports().then(function (result) {
                if (!result.data && result.message) {
                    logService.error('reports.component', 'getReports', result.message);
                    toastr.error(result.message);
                } else {
                    vm.reports = result.data;
                    getCategories();
                }
            }).catch(function (err) {
                toastr.error('Error in getting reports');
                logService.error('reports.component', 'getReports', err);
            });
        }

        function selectReport() {
            vm.reportData = null;
            vm.reportColumns = null;
            vm.iterableReportData = null;
            vm.selectedReport = vm.reports.find(function (elem) {
                return elem._id === vm.queryByReportId;
            });
            getReportDescriptionTemplate(vm.selectedReport.name, vm.currentUser.systemSetting.language);
            vm.reportFilters = {};
            vm.selectedstatus = [];
            vm.selectedCategories = [];
            vm.statuses.forEach(function (status) {
                status.selected = false;
            });
            vm.mainCategories.forEach(function (category) {
                category.selected = false;
            });
        }

        function getReportDescriptionTemplate(reportName, language) {
            var requestObject = {
                reportName: reportName,
                language: language
            };
            reportsService.getReportDescriptionTemplate(requestObject).then(function (result) {
                result = JSON.parse(result);
                vm.reportTemplate = result.data;
            }).catch(function (err) {
                logService.error('reports.component', 'getReportDescriptionTemplate', $filter('translate')('ERROR_IN_GETTING_REPORT_DESCRIPTION') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_REPORT_DESCRIPTION'));
            });
        }

        function createReport() {
            if (vm.createReportRequestData.dateTo && vm.createReportRequestData.dateFrom) {
                var requestObject = {
                    id: vm.selectedReport._id,
                    options: {
                        date: {
                            from: vm.createReportRequestData.dateFrom,
                            to: vm.createReportRequestData.dateTo,
                        },
                        clinics: []
                    }
                };
                if (vm.selectedstatus && vm.selectedstatus.length) {
                    requestObject.options.status = vm.selectedstatus;
                }
                if (vm.selectedCategories && vm.selectedCategories.length) {
                    requestObject.options.category = vm.selectedCategories;
                }
                if (vm.permissions['ALL_ORGANIZATION_REPORT_ACCESS'] && vm.selectedReport.filters.clinic) {
                    if (vm.reportFilters.clinic) {
                        requestObject.options.clinics = [ vm.reportFilters.clinic ];
                    } else {
                        requestObject.options.clinics = vm.clinics && vm.clinics.map(clinic => clinic._id);
                    }
                }
                if (vm.reportFilters.completedOrders) {
                    requestObject.options.completedOrders = vm.reportFilters.completedOrders;
                }
                if (vm.selectedReport.name === 'ECONOMY_DONE_ORDERS_PER_DENTIST') {
                    requestObject.options.approvedOrders = true;
                }
                vm.showSpinner = true;
                reportsService.createReport(requestObject).then(async function (result) {
                    if (!result.data && result.message) {
                        toastr.error(result.message);
                        vm.showSpinner = false;
                    } else {
                        vm.reportData = await vm.formatReport(result.data.reportData, result.data.columns);
                        vm.reportColumns = formatReportService.formatColumns(result.data.columns, vm.selectedReport.name);
                        vm.iterableReportData = formatReportService.formatIterableReport(vm.reportData, vm.reportColumns);
                        vm.showSpinner = false;
                    }
                }).catch(function (err) {
                    logService.error('reports.component', 'createReport', $filter('translate')('ERROR_IN_GETTING_REPORT') + ": " + err);
                    toastr.error($filter('translate')('ERROR_IN_GETTING_REPORT'));
                    vm.showSpinner = false;
                });
            }
        }

        function formatReport(reportData, columns) {
            switch (vm.selectedReport.name) {
            case 'NUMBER_OF_COMPLETED_ORDERS_PER_CLINIC':
            case 'NUMBER_OF_COMPLETED_ORDERS':
            case 'SUM_OF_ORDERS_PRICE_PER_DENTIST':
            case 'NUMBER_OF_ORDERS':
                return formatReportService.formatNumberOfOrdersReport(reportData, columns);
            case 'ECONOMY_DONE_ORDERS_PER_CLINIC':
            case 'ECONOMY_DONE_ORDERS_PER_DENTIST':
            case 'ECONOMY_INVOICED':
            case 'ECONOMY':
                return formatReportService.formatEconomyReport(reportData, columns);
            case 'LAB_RATING':
                return formatReportService.formatLabRatingReport(reportData, columns);
            case 'RANKING_ADHERENCE':
                return formatReportService.formatRankingAdherenceReport(reportData, columns);
            case 'RANKING_VIOLATIONS':
                return formatReportService.formatRankingViolationsReport(reportData, columns);
            case 'ORDER_RATINGS_FOR_CLINICS':
            case 'ORDER_RATINGS_FOR_LABS':
                return formatReportService.formatOrderRatingReport(reportData, columns);
            case 'RATING_PER_CLINIC':
                return formatReportService.formatRatingStatisticsReport(reportData, columns);
            case 'INVOICED_ORDERS':
                return formatReportService.formatRatingStatisticsReport(reportData, columns);
            case 'WHITE_LABEL_USER_LIST':
                return formatReportService.formatRatingStatisticsReport(reportData, columns);
            case 'SAMPLES_PER_DENTIST':
                return formatReportService.formatRatingStatisticsReport(reportData, columns);
            default:
                var error = 'Error formatting report';
                logService.error('reports.component', 'formatReport', error);
                toastr.error(error);
            }
        }

        function formatDateToUnixTimeInMs(dateString) {
            return moment(dateString).valueOf();
        }

        function formatCalendarDateString(unixDateInMs) {
            return moment(unixDateInMs).format('YYYY-MM-DD');
        }

        $scope.$watch('vm.reportInfo', function (newValue, oldValue) {
            if (newValue.dateFrom !== oldValue.dateFrom) {
                var dateInUnixTime = vm.formatDateToUnixTimeInMs(newValue.dateFrom);
                vm.createReportRequestData.dateFrom = dateInUnixTime;
                vm.floorDate = formatCalendarDateString(dateInUnixTime - DAY_IN_MS);

            } else if (newValue.dateTo !== oldValue.dateTo) {
                if (newValue.dateTo < vm.createReportRequestData.dateFrom || newValue.dateTo < newValue.dateFrom) {
                    toastr.error($filter('translate')('REPORT_TO_DATE_BOUND_ERROR'));
                    vm.reportInfo.dateTo = oldValue.dateTo;
                } else {
                    // Compensate the Datepickers starting time by adding a day to the "dateTo" field.
                    vm.createReportRequestData.dateTo = vm.formatDateToUnixTimeInMs(newValue.dateTo) + DAY_IN_MS;
                }
            }
        }, true);
    }
})();