/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './find-lab.html'
/**
 * Find Labs component
 */
(function () {
    'use strict';
    angular.module('app').component('findLab', {
        template: theTemplate,
        controller: FindLabController,
        controllerAs: 'vm',
        bindings: {
        }
    });
    // Find lab dependencies inject here
    FindLabController.$inject = [ '$state','searchLabTextService' ];

    // Controller function for find lab
    function FindLabController($state,searchLabTextService) {

        // Scope and other variables
        var vm = this;
        vm.searchText = '';
        vm.searchLabs = searchLabs;
        vm.showAllLabs = showAllLabs;

        function searchLabs() {
            if (vm.searchText) {
                searchLabTextService.setSearchLabText(vm.searchText);
            }
            $state.go('app.show-lab');
        }

        function showAllLabs() {
            searchLabTextService.setSearchLabText('');
            $state.go('app.show-lab');
        }
    }

})();
