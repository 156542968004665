import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { StateProvider, StateService } from '@uirouter/angularjs';
import { CurrentUserService } from '../../services/users/current-user.service';
import { LogService } from '../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { CacheService } from '../../services/core/cache.service';
import { Language } from '../../models/language.model';
import { OrganizationService } from '../../services/clinic/organization.service';
import { Organization } from '../../models/organization.model';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '../../services/core/token.service';
import { SessionService } from '../../services/core/session.service';
import { EventService } from '../../services/core/event.service';
import { NewOrderListRedirectService } from '../../services/new-order-list-redirect/new-order-list-redirect.service';
import federatedSelectOrganizationTemplate from './federated-select-organization.html';

@Component({
  selector: 'federated-select-organization',
  template: federatedSelectOrganizationTemplate
})
export class FederatedSelectOrganizationComponent implements OnInit {
  private error: string;
  private returnTo: string;
  private returnParams: string;
  private language?: Language;
  public organizations: {
    organization: { _id: string; name: string };
    token: string;
  }[] = [];

  static $inject: any = ['$state'];
  constructor(
    @Inject(forwardRef(() => '$state')) private $state: StateService,
    @Inject(forwardRef(() => '$location'))
    private $location: ng.ILocationService,
    public cacheService: CacheService,
    private logService: LogService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private tokenService: TokenService,
    private sessionService: SessionService,
    private organizationService: OrganizationService,
    private translationsService: TranslationsService,
    private eventService: EventService,
    private currentUserService: CurrentUserService,
    private newOrderListRedirectService: NewOrderListRedirectService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.returnTo = this.$state.params.return_to
        ? encodeURI(this.$state.params.return_to)
        : undefined;
      this.returnParams = this.$state.params.return_params
        ? encodeURI(this.$state.params.return_params)
        : undefined;

      const payloadCookie = this.cookieService.get('federated-payload');
      this.organizations = JSON.parse(payloadCookie || '[]');
      this.cookieService.delete('federated-payload');

      if (this.organizations.length === 1) {
        setTimeout(() => {
          this.tokenLogin(this.organizations[0].token);
        }, 2000);
      }
    } catch (err) {
      this.cookieService.delete('federated-payload');
      this.logService.error(
        'federated-login.component',
        'federatedLogin',
        'FEDERATED ERROR - ' + JSON.stringify(err.message ? err.message : err)
      );
      this.currentUserService.logout();
      this.toastr.error(await this.translationsService.get('FEDERATED_ERROR'));
    }
  }

  setLanguage(lang: Language): void {
    this.translationsService.use(lang.value);
  }

  setCurrentUser(token: string): void {
    this.currentUserService.login(token);
  }

  tokenLogin(token: string): void {
    this.setCurrentUser(token);
    if (this.returnTo && !this.returnTo.includes('login')) {
      const returnParams = this.returnParams
        ? JSON.parse(decodeURI(this.returnParams))
        : {};
      this.$location.path(this.returnTo).search(returnParams);
    } else {
      this.newOrderListRedirectService.newOrderList();
    }
  }

  redirectToLogin(): void {
    this.eventService.emit('logout');
  }
}
