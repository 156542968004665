import * as XRegExp from 'xregexp';
import { Injectable } from '@angular/core';

@Injectable()
export class RegexService {
  constructor() {}

  public nameRegExp(input: string): boolean {
    try {
      const unicodeWord = XRegExp('^[-.–\\p{L}]+(?:\\s+[-.–\\p{L}]+)*$');
      return unicodeWord.test(input);
    } catch (e) {
      console.error(e);
    }
  }
}