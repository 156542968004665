import { Input, Component } from '@angular/core';
import { Order } from '../../../models/order.model';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../app.constant';
import template from './rate-order-content.html';

@Component({
  selector: 'rate-order-content',
  template: template
})
export class RateOrderContentComponent {
  @Input() orderDetail: Order;
  ratingRemarksSettingName: string =
    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.RATING_REMARKS;

  constructor() {}

  clickOnReason(reason: any): void {
    this.orderDetail.rating.reasons
      .filter((res) => {
        return res.name === reason.name;
      })
      .forEach((res) => {
        res.value = !res.value;
      });
  }

  chooseRatingOption(option1: string, option2: string): void {
    this.orderDetail.rating[option1] = true;
    this.orderDetail.rating[option2] = false;
  }
}
