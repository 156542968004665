import { Input, Component } from '@angular/core';
import { Json2CSVService } from '../../services/json-2-csv/json-2-csv.service';
import { Json2ExcelService } from '../../services/json-2-excel/json-2-excel.service';
import { LogService } from '../../services/core/log.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { Report } from '../../models/report.model';
import template from './report-data.html';

@Component({
  selector: 'report-data',
  template: template
})
export class ReportDataComponent {
  @Input() reportData: any;
  @Input() reportColumns: any;
  @Input() selectedReport: Report;
  @Input() iterableReportData: any;
  private readonly newLineRegEx = new RegExp('\\n\\r|\\n|\\r', 'g');

  constructor(
    private readonly json2CSVService: Json2CSVService,
    private readonly json2ExcelService: Json2ExcelService,
    private readonly logService: LogService,
    private readonly translationsService: TranslationsService
  ) {}

  async exportToCSV() {
    const fields = [];
    for (const column of this.reportColumns) {
      fields.push({
        label: await this.translationsService.get(column.value),
        value: column.name
      });
    }
    try {
      this.reportData = this.reportData.map((data) => {
        if (data.comment) {
          data.comment = data.comment.replace(this.newLineRegEx, ' ');
        }
        return data;
      });
      const csvData = this.json2CSVService.convertJSON2CSV(this.reportData, fields);
      this.json2CSVService.exportCSVToFile(
        csvData,
        await this.translationsService.get(this.selectedReport.name)
      );
    } catch (err) {
      this.logService.error('reports-data.component', 'exportToCSV', err);
    }
  }

  async exportToExcel() {
    const fields = [];
    for (const column of this.reportColumns) {
      fields.push({
        label: await this.translationsService.get(column.value),
        value: column.name
      });
    }
    try {
      this.reportData = this.reportData.map((data) => {
        if (data.comment) {
          data.comment = data.comment.replace(this.newLineRegEx, ' ');
        }
        return data;
      });

      //Added translated column or key filed in report json
      let columns = {};
      fields.forEach((data) => {
        columns[data.value] = data.label
      });
      this.reportData = this.reportData.map((data) => {
        let formattedReport = {};
        Object.keys(data).forEach((key) => {
          formattedReport[columns[key]] = data[key]
        });
        return formattedReport;
      });

      this.json2ExcelService.exportAsExcelFile(
        this.reportData,
        await this.translationsService.get(this.selectedReport.name)
      );
    } catch (err) {
      this.logService.error('reports-data.component', 'exportToExcel', err);
    }
  }
}
