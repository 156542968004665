import { Component, Input, OnInit } from '@angular/core';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../../../../../../app.constant';
import { SubCategory } from '../../../../../../../../models/organization.model';
import { OrganizationSettingService } from '../../../../../../../../services/organization-setting/organization-setting.service';
import template from './contact-type.html';

@Component({
  selector: 'contact-type',
  template
})
export class ContactTypeComponent implements OnInit {
  @Input() isMandatory: boolean;
  @Input() selectedSubCategory: SubCategory;
  @Input() selectedContactType: string;

  contactTypesSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CONTACT_TYPE;
  contactTypes: string[];

  constructor(
    private readonly organizationSettingService: OrganizationSettingService
  ) {}

  ngOnInit(): void {
    this.contactTypes = this.organizationSettingService.getCustomerSetting(
      this.contactTypesSettingName
    );
  }
}
