import {
  Component,
  Output,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  EventEmitter
} from '@angular/core';
import { API } from '../../app.constant';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { TokenService } from '../../services/core/token.service';
import { UploadedFile } from '../../models/uploaded-file.model';
import template from './upload-button.html';

@Component({
  selector: 'upload-button',
  template: template
})
export class UploadButtonComponent implements OnInit {
  @ViewChild('fileinput') fileUploadElement: ElementRef;
  @Input() buttonClass: string;
  @Input() id: string;
  @Input() name: string;
  @Input() title: string;
  @Input() directory: string;
  @Input() accept: string;
  @Input() isMultiple = false;
  @Output() onProgress: EventEmitter<{
    file: FileItem;
    progress: number;
  }> = new EventEmitter<{ file: FileItem; progress: number }>();
  @Output() onFileOver: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onBeforeUpload: EventEmitter<FileItem> = new EventEmitter<
    FileItem
  >();
  @Output() onComplete: EventEmitter<UploadedFile> = new EventEmitter<
    UploadedFile
  >();
  @Output() onError: EventEmitter<string> = new EventEmitter<string>();
  uploader: FileUploader;
  hovering: boolean = false;

  constructor(private tokenService: TokenService) {}

  selectFile(): void {
    this.fileUploadElement.nativeElement.click();
  }

  ngOnInit(): void {
    const self = this;
    this.uploader = new FileUploader({
      url: API.API_BASE + 'file',
      additionalParameter: {
        directory: self.directory
      },
      headers: [{ name: 'Authorization', value: self.tokenService.getToken() }]
    });
    this.uploader.onProgressItem = this.onProgressItem.bind(this);
    this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);
    this.uploader.onCompleteItem = this.onCompleteItem.bind(this);
    this.uploader.onErrorItem = this.onErrorItem.bind(this);
  }

  onFileSelected(): void {
    if (this.directory && this.uploader?.options?.additionalParameter) {
      this.uploader.options.additionalParameter.directory = this.directory;
    }
    this.uploader.uploadAll();
  }

  onBeforeUploadItem(item: FileItem, response: string, status: number): void {
    this.onBeforeUpload.emit(item);
  }

  onCompleteItem(item: FileItem, response: string, status: number): void {
    const fileResponse: { message: string; file: UploadedFile } = JSON.parse(
      response
    );
    this.onComplete.emit(fileResponse.file);
  }

  onProgressItem(item: FileItem, progress: any): void {
    this.onProgress.emit({
      file: item,
      progress: progress
    });
  }

  onErrorItem(item: FileItem, error: any): void {
    this.onError.emit(error);
  }
}
