import { Input, Component } from '@angular/core';
import { Order } from '../../../models/order.model';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../app.constant';
import template from './sample-content-info.html';

@Component({
  selector: 'sample-content-info',
  template: template
})
export class SampleContentInfoComponent {
  @Input() orderDetail: Partial<Order> = {};
  sampleContentSettingName: string =
    CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT;

  showSampleContent(): boolean {
    if (
      this.orderDetail &&
      this.orderDetail.deliveryDetails &&
      this.orderDetail.deliveryDetails.sample &&
      this.orderDetail.deliveryDetails.sample.content
    ) {
      return this.orderDetail.deliveryDetails.sample.content.some((content) => {
        return content.value;
      });
    } else {
      return false;
    }
  }

  getClass(field: string): any {
    return {
      'red-color': false
    };
  }
}
