import { ResourceService } from '../core/resource.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiInterceptor } from '../../interceptors/api-interceptor';
import { Injectable } from '@angular/core';
import { API } from '../../app.constant';
import { FileUploader } from 'ng2-file-upload';
import { Status } from '../../models/status.model';

@Injectable()
export class FileUploadService extends ResourceService {
  public uploader: FileUploader;
  constructor(httpClient: HttpClient, private apiInterceptor: ApiInterceptor) {
    super(httpClient, '');
    this.uploader = new FileUploader({
      url: API.API_BASE + 'file'
      // disableMultipart: true,
      // formatDataFunction: (item) => {
      //     console.log(item, "item");
      // }
    });
  }

  removeFile(file: string, directory?: string): Promise<Status> {
    const params = new HttpParams().set('file', file);

    if (directory) {
      params.set('directory', directory);
    }

    return this.delete(API.API_BASE + 'file', params, this.apiInterceptor);
  }

  getS3FileUrl(): Promise<{ s3Url: string }> {
    return this.get(
      API.API_BASE + 's3-file-url',
      undefined,
      this.apiInterceptor
    );
  }
}
