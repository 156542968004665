import gql from 'graphql-tag';

export const getLabsConnectedClinicsTemplate = gql`
  query labsConnectedClinics {
    labsConnectedClinics {
      _id
      labId
      name
      clinicId
      clinicName
      clinicCountryCode
      clinicAddress {
        street
        city
        postal_code
        country
      }
      transportCLinicId
      transportCLinicName
      isLocal
    }
  }
`;

export const getTransportClinicsTemplate = gql`
  query transportClinics {
    transportClinics {
      _id
      name
      address
      zipCode
      city
      description
    }
  }
`;

export const addTransportClinicTemplate = gql`
  mutation addTransportClinic(
    $name: String!
    $address: String!
    $zipCode: Int!
    $city: String!
    $description: String!
  ) {
    addTransportClinic(
      name: $name
      address: $address
      zipCode: $zipCode
      city: $city
      description: $description
    ) {
      _id
      name
      address
      zipCode
      city
      description
    }
  }
`;

export const updateTransportClinicTemplate = gql`
  mutation updateTransportClinic(
    $_id: ID!
    $name: String!
    $address: String!
    $zipCode: Int!
    $city: String!
    $description: String!
  ) {
    updateTransportClinic(
      _id: $_id
      name: $name
      address: $address
      zipCode: $zipCode
      city: $city
      description: $description
    )
  }
`;

export const deleteTransportClinicTemplate = gql`
  mutation deleteTransportClinic($_id: ID!) {
    deleteTransportClinic(_id: $_id)
  }
`;

export const addConnectedTransportClinicTemplate = gql`
  mutation addConnectedTransportClinic(
    $labId: ID!
    $clinicId: ID!
    $transportClinicId: ID!
  ) {
    addConnectedTransportClinic(
      labId: $labId
      clinicId: $clinicId
      transportClinicId: $transportClinicId
    )
  }
`;