import template from './ta-transport-status.html';
import { Component, Input } from '@angular/core';
import { LogisticGraphqlService } from '../../../../graphql-services/logistics/logistics.graphql.service';
import { TransportStatusLogDialogComponent } from '../transport-status-log-dialog/transport-status-log-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ta-transport-status',
  template: template
})
export class TaTransportStatus {
  @Input() status: string;
  @Input() hasProblem: boolean;
  @Input() iconOnly: boolean;
  @Input() opterOrderId: string;
  isSpinner = false;
  private readonly statusIcons = {
    PLANNED: 'inventory',
    PICKED_UP: 'local_shipping',
    DELIVERED: 'check_circle',
    MISSED: 'error',
    MISSED_PICKUP: 'local_shipping',
    MISSED_DELIVERY: 'local_shipping'
  };

  constructor(
    private readonly logisticGraphqlService: LogisticGraphqlService,
    public dialog: MatDialog
  ) {}

  statusIcon() {
    return this.statusIcons[this.status];
  }

  async getTransportStatuses() {
    this.isSpinner = true;
    const transportStatuses = await this.logisticGraphqlService.getTransportStatuses(
      parseInt(this.opterOrderId)
    );
    this.isSpinner = false;
    this.dialog
      .open(TransportStatusLogDialogComponent, {
        autoFocus: false,
        height: '465px',
        maxHeight: '90vh',
        width: '900px',
        data: {
          transportStatuses,
          opterOrderId: this.opterOrderId
        }
      })
      .afterClosed();
  }
}
