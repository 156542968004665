import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ORDER_2_CONSTRUCTION } from '../../../app.constant';
import { Action } from '../../../models/action.model';
import { Category, SubCategory } from '../../../models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class ConstructionPageService {
  private _selectedMainCategory: Category;
  private _selectedSubCategory: SubCategory;
  private _selectedTeethRange: { end?: number; start?: number };
  private _selectedTeeth: string[];
  private _selectedActions: Action[] = [];

  get selectedMainCategory() {
    return cloneDeep(this._selectedMainCategory);
  }

  set selectedMainCategory(category: Category) {
    this._selectedMainCategory = cloneDeep(category);
  }

  resetMainCategory() {
    this._selectedMainCategory = null;
  }

  get selectedSubCategory() {
    return cloneDeep(this._selectedSubCategory);
  }

  set selectedSubCategory(selectedSubCategory: SubCategory) {
    this._selectedSubCategory = cloneDeep(selectedSubCategory);
  }

  resetSubCategory() {
    this._selectedSubCategory = null;
  }

  get selectedActions() {
    return cloneDeep(this._selectedActions);
  }

  set selectedActions(selectedActions: Action[]) {
    this._selectedActions = cloneDeep(selectedActions);
  }

  resetActions() {
    this._selectedActions = [];
  }

  get selectedTeethRange() {
    return cloneDeep(this._selectedTeethRange);
  }

  set selectedTeethRange(_selectedTeethRange: { end?: number; start?: number }) {
    this._selectedTeethRange = cloneDeep(_selectedTeethRange);
  }

  resetTeethRange() {
    this._selectedTeethRange = null;
  }

  get selectedTeeth() {
    return cloneDeep(this._selectedTeeth);
  }

  set selectedTeeth(_selectedTeeth: string[]) {
    this._selectedTeeth = cloneDeep(_selectedTeeth);
  }

  resetTeeth() {
    this._selectedTeeth = [];
  }

  getToothOption(selectedActions) {
    if (!selectedActions?.length) {
      return;
    }
    const allActionToothTypes = selectedActions.map((action) => action.teethOption);
    if (allActionToothTypes.indexOf(ORDER_2_CONSTRUCTION.TYPE.SINGLE_TOOTH) > -1) {
      return ORDER_2_CONSTRUCTION.TYPE.SINGLE_TOOTH;
    } else if (allActionToothTypes.indexOf(ORDER_2_CONSTRUCTION.TYPE.RANGE) > -1) {
      return ORDER_2_CONSTRUCTION.TYPE.RANGE;
    } else {
      return ORDER_2_CONSTRUCTION.TYPE.UP_LOW_JAW;
    }
  }
}
