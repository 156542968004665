import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';

@Injectable()
export class GetAllUsersService {
  private usersData: { users: User[]; pageNo: number } = null;

  getAllUsers(usersData: User[], pageNo: number): void {
    // TODO: How does this work? A get function should not set data.
    this.usersData = { users: usersData, pageNo: pageNo };
  }

  getUserData(): { users: User[]; pageNo: number } {
    return this.usersData;
  }

  clearUsersData(): void {
    this.usersData = null;
  }
}
