import { MaterialList, Organization } from '../../../models/organization.model';
import { Input, Component, OnInit } from '@angular/core';
import { MaterialListService } from '../../../services/material-list/material-list.service';
import { TranslationsService } from '../../../services/translations/translations.service';
import { LogService } from '../../../services/core/log.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationSettingService } from '../../../services/organization-setting/organization-setting.service';
import * as _ from 'lodash';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../../app.constant';
import template from './material-lists.html';
import style from './material-lists.less';

@Component({
  selector: 'material-lists',
  template: template,
  styles: [style]
})
export class MaterialListsComponent implements OnInit {
  @Input() lab: Organization;
  materialLists: MaterialList[] = [];
  selectedMaterialList: MaterialList;
  selectedMaterialListId: string;
  defaultMaterialList: boolean;
  selectedList: Partial<MaterialList>;
  currency: string;
  readonly modalId = '#add-material-list-modal';

  ngOnInit() {
    this.materialLists = this.lab.materialLists;
    this.currency = this.organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CURRENCY
    );
  }

  constructor(
    private readonly materialListService: MaterialListService,
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly translationsService: TranslationsService,
    private readonly toastr: ToastrService,
    private readonly logService: LogService
  ) {}

  openModal() {
    $(this.modalId).modal('show');
  }

  getMaterialList(selectedMaterialListId: string) {
    this.defaultMaterialList =
      this.lab && this.lab.defaultMaterialListId === selectedMaterialListId;
    this.selectedMaterialList = this.lab.materialLists.find(
      (list) => list._id === selectedMaterialListId
    );
  }

  editMaterialList() {
    this.selectedList = _.cloneDeep(this.selectedMaterialList);
    $(this.modalId).modal('show');
  }

  materialListChange(materialLists) {
    if (materialLists) {
      this.lab.materialLists = materialLists;
    }
    this.selectedMaterialList = this.lab.materialLists.find(
      (list) => list._id === this.selectedMaterialListId
    );
  }

  selectNewList() {
    if (this.lab.materialLists.length) {
      this.selectedMaterialList = this.lab.materialLists[ this.lab.materialLists.length - 1 ];
      this.selectedMaterialListId = this.selectedMaterialList._id;
    }
  }

  closeModal() {
    this.selectedList = {};
    $(this.modalId).modal('hide');
  }

  saveDefaultMaterialList(): Promise<void> {
    const data = {
      materialListId: this.defaultMaterialList
        ? this.selectedMaterialList._id
        : null
    };
    return this.materialListService
      .saveDefaultMaterialList(data)
      .then(async (result) => {
        this.toastr.success(
          await this.translationsService.get(
            'DEFAULT_MATERIAL_LIST_SAVE_SUCCESSFULLY'
          )
        );
        this.lab.defaultMaterialListId = result.data.defaultMaterialListId;
      })
      .catch(async (err) => {
        console.log('err', err);
        const text = await this.translationsService.get(
          'ERROR_IN_DEFAULT_MATERIAL_LIST'
        );
        this.logService.error(
          'material-lists.component',
          'saveDefaultMaterialList',
          `${text}: ${err}`
        );
        this.toastr.error(text);
      });
  }
}
