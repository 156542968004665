import { forwardRef, Inject, Injectable } from '@angular/core';
import { SessionService } from '../core/session.service';
import { PermissionService } from '../core/permission.service';
import { OrganizationSettingService } from '../organization-setting/organization-setting.service';
import { TranslationsService } from '../translations/translations.service';
import { GetAllOrdersService } from '../order-list/get-all-orders.service';
import { GetAllUsersService } from '../order-list/get-all-users.service';
import { User } from '../../models/user.model';
import { CUSTOMER_SETTINGS_CONSTANTS } from '../../app.constant';
import { UsersService } from './users.service';
import { TokenService } from '../core/token.service';
import { EventService } from '../core/event.service';
import { NewOrderListRedirectService } from '../new-order-list-redirect/new-order-list-redirect.service';
import * as Rollbar from 'rollbar';
import { RollbarService } from '../../rollbar';

@Injectable()
export class CurrentUserService {
  private userData: User;

  static $inject: any = ['$state'];
  constructor(
    @Inject(forwardRef(() => '$state')) private $state: any,
    private usersService: UsersService,
    private eventService: EventService,
    private tokenService: TokenService,
    private sessionService: SessionService,
    private permissionService: PermissionService,
    private organizationSettingService: OrganizationSettingService,
    private translationsService: TranslationsService,
    private getAllOrdersService: GetAllOrdersService,
    private getAllUsersService: GetAllUsersService,
    private newOrderListRedirectService: NewOrderListRedirectService,
    @Inject(RollbarService) private readonly rollbar: Rollbar
  ) {
    const self = this;
    eventService.on('user', () => {
      self.goToState();
    });

    eventService.on('logout', (event, redirectTo, redirectParams, federation) => {
      self.logout(redirectTo, redirectParams, federation);
    });
  }

  getLoggedOutState(federation: string): { state: string; params: any } {
    const loginMethod = this.organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.DEFAULT_LOGIN_METHOD
    );

    let stateObj: any = {
      state: 'login'
    };

    if (federation) {
      stateObj = {
        state: 'federated-login',
        params: {
          federation: federation
        }
      };
    } else if (loginMethod && loginMethod !== '') {
      stateObj = {
        state: 'logged-out',
        params: {
          federation: loginMethod
        }
      };
    }

    return stateObj;
  }

  goToLogin(
    stateObj?: any,
    redirectTo?: string,
    redirectParams?: any,
    extraParams = null
  ): void {
    if (redirectTo) {
      if (!stateObj || !stateObj.params) {
        stateObj.params = {};
      }

      if (redirectTo) {
        stateObj.params.return_to = redirectTo;
      }

      if (redirectParams) {
        stateObj.params.return_params = redirectParams;
      }
    }
    if (extraParams) {
      if (!stateObj || !stateObj.params) {
        stateObj.params = {};
      }
      Object.keys(extraParams).forEach((key) => {
        stateObj.params[key] = extraParams[key];
      });
    }
    if (stateObj && stateObj.state) {
      this.$state.go(stateObj.state, stateObj.params);
    } else {
      this.$state.go('login', stateObj ? stateObj.params : '');
    }
  }

  unsetUser(): void {
    if (this.userData && this.userData.systemSetting) {
      this.translationsService.refresh(this.userData.systemSetting.language);
    }
    this.tokenService.logout();
    this.resetUserData();
    this.sessionService.removeItem('language');
    this.sessionService.removeItem('locale');
    this.sessionService.removeItem('permissions');
    this.sessionService.removeSetting();
  }

  async login(token: string, organizationSettings?: any, user?: any): Promise<void> {
    this.userData = user;
    this.eventService.broadcast('userPermissionsSet');
    const currentUser = user;
    this.getAllUsersService.clearUsersData();

    // TODO: If both organization and user settings exist - what should we do? Merge or prioritize?
    if (organizationSettings) {
      this.sessionService.setSetting(organizationSettings);
    } else if (currentUser.organization.settings) {
      this.sessionService.setSetting(currentUser.organization.settings);
    }

    // set user customer setting data
    if (
      !this.permissionService.isSuperAdmin(currentUser) &&
      currentUser.organization
    ) {
      this.organizationSettingService.setCustomerSettings(
        currentUser.organization.customerSettings
      );
      this.setCustomTranslations(currentUser.systemSetting.language);
    }

    this.eventService.broadcast('user', { user: currentUser });
  }

  logout(
    redirectTo?: string,
    redirectParams?: any,
    federation?: string,
    extraParams?: any
  ): void {
    this.sessionService.clearLocalStorage();
    this.getAllOrdersService.clearData(); // to clear cached orders list data
    this.getAllOrdersService.clearUnreadMessagesData(); // to clear cached unread messages data
    this.getAllUsersService.clearUsersData(); // to clear cached users data
    this.unsetUser();
    const loggedOutState = this.getLoggedOutState(federation);

    this.goToLogin(loggedOutState, redirectTo, redirectParams, extraParams);
  }

  async setCustomTranslations(
    language: string,
    refresh: boolean = true
  ): Promise<void> {
    const customTranslations = this.organizationSettingService.getCustomerSetting(
      CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CUSTOM_TRANSLATIONS
    );
    await this.translationsService.setTranslations(
      customTranslations || {},
      language,
      refresh
    );
  }

  resetUserData(): void {
    this.userData = undefined;
  }

  setUserData(user: User): Promise<User> {
    // if language is found in response then set as default.
    if (user.systemSetting && user.systemSetting.language) {
      this.translationsService.use(user.systemSetting.language);
      this.sessionService.setItem('language', user.systemSetting.language);
    }

    // set locale
    if (user.systemSetting && user.systemSetting.locale) {
      this.sessionService.setItem('locale', user.systemSetting.locale);
    }

    // set organization settings
    if (user.organization && user.organization.settings) {
      this.sessionService.setSetting(user.organization.settings);
    }

    if (!this.permissionService.isSuperAdmin(user) && user.organization) {
      this.organizationSettingService.setCustomerSettings(
        user.organization.customerSettings
      );
      this.setCustomTranslations(user.systemSetting.language);
    }

    this.userData = user;
    this.eventService.broadcast('userPermissionsSet');
    return Promise.resolve(user);
  }

  updateCurrentUser(): Promise<User> {
    const self = this;
    return self.usersService
      .getCurrentUser()
      .then((result) => {
        self.setUserData(result.user);
        if (!self.permissionService.isSuperAdmin(self.userData)) {
          // eslint-disable-next-line max-len
          const customTranslations = self.organizationSettingService.getCustomerSetting(
            CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CUSTOM_TRANSLATIONS
          );
          self.translationsService.setTranslations(
            customTranslations,
            result.user.systemSetting.language
          );
        }
        return self.userData;
      })
      .catch((error) => {
        // logout user if failed to get user information.
        console.error('Error getting user information!', error);
        return undefined;
      });
  }

  goToState(): void {
    if (!this.permissionService.isSuperAdmin(this.userData)) {
      this.newOrderListRedirectService.newOrderList();
    } else {
      this.$state.go('app.users');
    }
  }

  getUserData(): Promise<User> {
    if (!this.tokenService.isAuthenticated()) {
      return Promise.reject({ err: 'User not Logged in' });
    } else {
      if (this.userData && this.userData.email && this.userData.organization) {
        // TODO: A bit hacky, but no other natural way to set this right now.
        this.rollbar.configure({ payload: { person: { id: this.userData.email } } });
        return Promise.resolve(this.userData);
      } else {
        return this.updateCurrentUser();
      }
    }
  }

  /**
   * Get all permissions of current user
   * @returns {any}
   */
  getPermissions(): { [key: string]: boolean } {
    return this.userData.permissions ? this.userData.permissions.permissions : {};
  }

  setUserAndTokenForSuperAdmin(token: string, user: User): void {
    this.setUserData(user);
    this.getAllUsersService.clearUsersData(); // clear users data in service
    this.eventService.broadcast('user', { user: user });
  }
}
